import React, { memo } from 'react';

const CheckedCheckedIcon = props => {
  // const { color = '#43425D' } = props;

  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="15" height="15" rx="2" fill="#43425D"/>
    <path d="M4 7L6.91667 10L11 6" stroke="#E9E9F0" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default memo(CheckedCheckedIcon);
