import React, {useEffect} from 'react';
import Dialog from '../Dialog';
import { inject, observer } from 'mobx-react';
import Button from '../Button';

import './style.scss';

const GroupNotFound = (props) => {
  const {
    groupsModel: {groupNotFound, setGroupNotFound, setLastGroup},
    LoaderControllerModel:{setIsGlobalLoaderNeed}
  } = props;
  const handleClose = () => {
    setGroupNotFound(false)
    setLastGroup(false)
  }

  useEffect(()=>{
    setIsGlobalLoaderNeed(false)
  },[])

  return (
    <div className = "group_not_found_wrapper">
      <Dialog
        className="group_not_found_dialog"
        closeColor="#43425D"
        defaultOpen={groupNotFound}
        onClose = {handleClose}
      >
        <div className="group_not_found_content">
            <div className="content">
              <h1>This group is private or no longer available</h1>
              <span>For private groups, please ask the moderator to join</span>
            </div>
            <Button violetGradien width100 label="OK" onClick={handleClose}/>
          </div>

      </Dialog>
    </div>
  );
}

export default inject('groupsModel', 'LoaderControllerModel')(observer(GroupNotFound));
