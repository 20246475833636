import React from 'react';
import { Redirect } from "react-router-dom";
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import MaskInput from '../../../components/MaskInput';
import Button from '../../../components/Button';

import TitleImage from '../../../res/img/forgot-password.png';

import { home, profile } from '../../../const/urlKeys';

import '../register.scss';

const SignupSchema = Yup.object().shape({
  c: Yup.number()
    .typeError('Phone must be a number')
    .required("Enter correcr code"),
  o: Yup.number()
    .typeError('Phone must be a number')
    .required("Enter correcr code"),
  d: Yup.number()
    .typeError('Phone must be a number')
    .required("Enter correcr code"),
  e: Yup.number()
    .typeError('Phone must be a number')
    .required('Enter correcr code'),
})

const ValidateCode = props => {
  const {
    userModel: { registerUser, registerData, getCode },
    authModel: { session_token }
  } = props;

  if (session_token && session_token !== 'undefined' ) {
    const pathname = localStorage.getItem('pathname') || null;

    if (pathname && pathname !== profile) {
      return <Redirect to={pathname} />
    }

    return <Redirect to={home} />
  }

  const goToNext = (key) => {
    const elem = document.getElementById(key);
    elem.focus();
    elem.setSelectionRange(0,0);
  }

  return(
    <Formik
      initialValues={{ c: '', o: '', d: '', e: '' }}
      validationSchema={SignupSchema}
      onSubmit={(values, actions) => {
        const { c, o, d, e } = values;
        const code = `${c}${o}${d}${e}`;
        registerUser({ setErrors: actions.setFieldError, code })
      }}
    >
      {formikProps => {
        const { isValid, values, errors, setFieldError } = formikProps;
        const { c, o, d, e } = values;
        const disabledButton = !(isValid && c !== "" && o !== "" && d !== "" && e !== "");
        const keys = Object.keys(values);
        return (
          <Form className={classNames("defaultForm", "verifyForm")}>
            <img src={TitleImage} alt="Title register" className="titleImage" />
            <div className="title">Verify Phone Number</div>
            <div className="line text">
              Enter 4 digits from SMS
            </div>
            <div className="line">
              {keys.map((name, index) => {
                return <MaskInput
                  name={name}
                  id={name}
                  className="verifyCodeInput"
                  mask={[/\d/]}
                  key={`input-${name}`}
                  goToNext={goToNext}
                  nextElem={keys[index+1]}
                />}
              )}
            </div>
            <div className="codeError">{errors.code}</div>
            <div className="lineSeparatop" />
            <Button type="submit" label="Verify" disabled={disabledButton} width100 violetGradien big60 />
            <div className="signInBlock multyLine">
              <span>Haven't received the code yet?</span>
              <div>
              <button
                  onClick={() => getCode(registerData.phone_number,registerData.email, setFieldError)}
                  className="resendCode"
                >
                  Resend Code
                </button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default inject('userModel', 'authModel')(observer(ValidateCode));
