import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { returnName } from '../../../units/func';
import ShareIcon from '../../../res/iconComp/ShareIcon';
import FollowIcon from '../../../res/icons/followIcon.svg'
import unFollowIcon from '../../../res/icons/unFollowIcon.svg'
import unSubscribeIcon from '../../../res/icons/unSubscribeIcon.svg'
import subscribeIcon from '../../../res/icons/subscribeIcon.svg'
import Dialog from '../../../components/Dialog';
import ReportGroupForm from '../../../components/ReportGroupForm';
import Followers from '../../../components/Followers/Followers';
import SharePopup from '../../../components/SharePopup/sharePopup';
import ReportButton from '../../../components/ReportButton';
import CurrentAvatar from '../../../components/CurrentAvatar';
import CustomModal from "../../../components/Modals/Modal";
import PackageBlock from '../../Profile/Components/Subscriptions/PackageBlock'
import './Profile.scss';
import classNames from "classnames";
import {
  ctInfluencer,
  InfluencerLabel,
  PrivateLabel,
  PublicLabel,
  statusActive,
  statusDeactivated
} from "../../../const/general";

const ProfileCard = (props) => {
  const {
    masterMindsModel: {
      masterMindProfile,
      followMasterMind,
      unfollowMasterMind,
      setMasterMindRating,
      masterMindProfileFullData,
      masterMindsProfileFollowers,
    },
    subscribePackageModel:{ subscriptionPackagesList },
    communityModel: { userCommunityList, userOrganization },
    userModel,
  } = props;

  const query =  new URLSearchParams(window.location.search);
  const [openReport, setOpenReport] = useState(false);
  const [isOpenModal, setModalOpen] = useState(query?.get('subscriptions'))

  const {
    id,
    location,
    followed,
    followers_count=0,
    description,
    my_rating,
    rating,
    image,
    community,
    group_type,
  } = masterMindProfile;

  const mmName = returnName(masterMindProfile);
  const mailData = {
    name: mmName,
    community,
    link: `${process.env.REACT_APP_REDIRECT}/user/${id}`,
    owner: mmName,
    type: group_type,
  };

  const showReport = () => setOpenReport(true);
  const hideReport = () => setOpenReport(false);
  const saveReport = () => hideReport();
  const toggleModal = () => setModalOpen(!isOpenModal)

  const getSubscribedIcon = () => {
    const subscribed = masterMindProfileFullData?.subscribed

    return <div className='followAction' onClick={toggleModal}>
      <img src={subscribed? unSubscribeIcon : subscribeIcon} alt=""/>
      <span className={classNames({subscribed: subscribed})}>{subscribed ? 'Subscribed': 'Subscribe'}</span>
    </div>
  }

  const getCommunityLabel = () => {
    const community = userOrganization.communities[0]
    if (community?.community_type !== ctInfluencer ) return ''
    return  <div className={'profile_card_influencerLabel'}>{community.private? PrivateLabel : PublicLabel } {InfluencerLabel}</div>
  }

  return (
    <div className="profile_card">
      <div className="profile_card_сontent_header_actions">
        <SharePopup url={`/user/${id}`} mailData={mailData}>
          <IconButton classes={{ root: 'profileActionsIcon' }}>
            <ShareIcon color="#7E7D8F" />
          </IconButton>
        </SharePopup>
        <ReportButton onClick={showReport} color="#7E7D8F" />
      </div>
      <div className="MuiAvatar-root MuiAvatar-circle master_mind_avatar">
        <CurrentAvatar alt={mmName} src={image && image.url} className="master_mind_avatar" />
      </div>
      <div className="profile_card_content">
        <div className="profile_card_content_title">{mmName}</div>
        <div className="profile_card_content_address">{location}</div>
        <div className="profile_card_content_position">
          {' '}
          <span>{userCommunityList.general.slice().map(c => c.title)?.join(', ')}</span> Coach
        </div>
        {userOrganization.count? getCommunityLabel() : null}
        {userModel?.user?.id !== id && (
            <div className='userActions'>
              <div className='followAction' onClick={() => (followed ? unfollowMasterMind(id) : followMasterMind(id))}>
                <img
                    className="logo"
                    alt="logo"
                    src={followed ? unFollowIcon : FollowIcon}
                />
                <span>{followed ? 'Unfollow' : 'Follow'}</span>
              </div>
              {!!masterMindProfileFullData?.subscription_packages_count && getSubscribedIcon()}

              <div className="profile_card_followers">
                <Followers folowers_amount={masterMindsProfileFollowers} style={{ marginTop: 22 }} />
              </div>
            </div>
        )}
        {description && <Divider />}
        {description && <div className="profile_card_content_about">{description} </div>}

      </div>
      <Dialog
        className="detailGroup"
        closeColor="#43425D"
        defaultOpen={openReport}
        onClose={hideReport}
      >
        <ReportGroupForm hideReport={hideReport} saveReport={saveReport} />
      </Dialog>
      <CustomModal customWidth='660px' isOpen={isOpenModal} handleClose={toggleModal}>
        <h1 className='subscribeTitle'>Subscribe</h1>
        <PackageBlock className='modalContent' classNameCard='modalHeight ' customWidth profileSubscription mmData={masterMindProfile} data={subscriptionPackagesList.subscription_packages.filter((pack) => (pack.status === statusActive || pack.status === statusDeactivated && pack.current)) }/>
      </CustomModal>
    </div>
  );
};

export default inject('masterMindsModel', 'userModel', 'subscribePackageModel', 'communityModel')(observer(ProfileCard));
