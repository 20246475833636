import React, { memo } from 'react';

const IndividualGroupIcon = props => {
//   const { color = '#43425D' } = props;

  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 4.98336C5.4495 4.98336 5.88891 4.84896 6.26266 4.59714C6.63641 4.34533 6.92771 3.98742 7.09973 3.56868C7.27174 3.14993 7.31675 2.68915 7.22906 2.24461C7.14136 1.80007 6.92491 1.39173 6.60706 1.07124C6.28921 0.750744 5.88425 0.532484 5.44339 0.444059C5.00252 0.355635 4.54555 0.401017 4.13026 0.574468C3.71498 0.747919 3.36003 1.04165 3.1103 1.41851C2.86056 1.79537 2.72727 2.23844 2.72727 2.69169C2.72631 2.99291 2.78444 3.29134 2.89832 3.56982C3.01219 3.84829 3.17956 4.10131 3.3908 4.3143C3.60203 4.5273 3.85296 4.69606 4.12913 4.81089C4.4053 4.92571 4.70127 4.98433 5 4.98336ZM5 6.81669C3.33636 6.81669 0 7.66003 0 9.33753V11.4H10V9.33753C10 7.66003 6.66364 6.81669 5 6.81669Z" fill="#FFCA00" />
    </svg>
  )
}

export default memo(IndividualGroupIcon);
