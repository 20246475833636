import { withNamespaces } from 'react-i18next';
import MainRouter from './router/MainRouter';

import './App.css';

function App() {
  return  MainRouter
}

export default withNamespaces()(App);
