import React, { memo } from 'react';
import { Link } from 'react-router-dom';

const LinkImg = ({ url, className, ...rest }) => {
  return(
    <Link to={url} className={className} >
      <img {...rest} />
    </Link>
  )
};

export default memo(LinkImg);
