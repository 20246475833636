import { observable, decorate, action } from 'mobx';
import axios from 'axios';
import { baseAPI } from '../const/settings';
import { authModel } from './auth';
import { userModel } from './user';

export class PostsModel {
  constructor() {
    this.posts = null;
  }

  setPosts = data => this.posts = data;

  updatePosts = (newPost) => {
    const oldPostsIndex = this.posts.posts.findIndex(post => post.id === newPost.id);
    this.posts.posts[oldPostsIndex] = newPost;
  };

  getPostsData = (id) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    let url = `${baseAPI}posts?[per_page]=1000`;
    if (id) {
      url = url + `&[user_id]=${id}`;
    }
    axios.get(url, headers)
      .then(response => {
        this.posts = response.data;
      })
      .catch(function (error) {
        // handle error
        console.log('error', error)
      })
      .finally()
  }

  likePost = id => {
    const updatePosts = this.updatePosts;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.post(`${baseAPI}posts/${id}/likes`, {}, headers)
      .then(response => {
        updatePosts(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log('error', error)
      })
      .finally()
  }

  unlikePost = id => {
    const updatePosts = this.updatePosts;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.delete(`${baseAPI}posts/${id}/likes`, headers)
      .then(response => {
        updatePosts(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log('error', error)
      })
      .finally()
  }

  createPostComment = (id, comment) =>{
    const updatePosts = this.updatePosts;
    let form = new FormData();
    form.append("text", comment)
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.post(`${baseAPI}posts/${id}/comments`, form, headers)
      .then(response => {
        updatePosts(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log('error', error)
      })
      .finally()
  }

  sharePost = (post_id, chad_id, user_id) => {
    let form = new FormData();
    form.append("chat_ids", chad_id);
    form.append("user_ids", user_id)
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.post(`${baseAPI}posts/${post_id}/comments`, form, headers)
      .then(response => {
      })
      .catch(function (error) {
        // handle error
        console.log('error', error)
      })
      .finally()
  }
}

decorate(PostsModel, {
  posts: observable,
  createPostComment: action,
})

export const postsModel = new PostsModel();
