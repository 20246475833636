import { observable, decorate, action, toJS } from 'mobx';
import axios from 'axios';

import { baseAPI } from '../const/settings';
import { authModel } from './auth';
import { questionsModel } from './questions';
import { groupsModel } from './groups';
import { masterMindsModel } from './masterminds';
import { cardsModel } from './cards';
import { subscribeModel } from './subsctibe';
import { answersModel } from './answers';
import { chatsModel } from './chats';
import { mmContextModel } from './mmcontext';
import { tagsModel } from './tags';
import {requestInterval, set_default_community} from '../const/general';
import { notificationsModel } from './notifications';
import { bankAccountsModel } from './bankAccounts';
import {subscribePackageModel} from "./subscriptionPackages";
import {communityModel} from './community'
import {supportModel} from "./support";

export class UserModel {
  constructor() {
    this.user = {};
    this.auth_user = {}
    this.showCheckCode = false;
    this.community = '';
  }

  setAuthUser = (data) => {
    this.auth_user = data
  }

  setCurrentUser = user => {
    this.user = user;
    // this.community = localStorage.getItem('user_community') || user.community;
    this.community =  user.community_id;
    groupsModel.changeFilterCommunity(this.community);
  }

  updateLocalUser = user => {
    this.user = user;
    this.community = user?.community_id;
    communityModel.getMyCommunityList({user})
    communityModel.getMyDistributionGroupsList({user})
    communityModel.getMyOwnCommunity()
    supportModel.getCommissions()
    if (user && user.community_id) {
      groupsModel.changeUserCommunity(user.community_id);
      mmContextModel.mmContextGroupsData(user?.id, authModel.session_token);
    }

    if (user && !user.community_id && !toJS(questionsModel.questions)) {
      questionsModel.getQuestions(authModel.session_token);
    }

    setInterval(() => {
      groupsModel.getGroupsData(authModel.session_token);
      groupsModel.getRecordsGroups(authModel.session_token);
    }, requestInterval);

    setInterval(() => {
      notificationsModel.getUnreadNotifications();
    }, 90000);
    notificationsModel.getUnreadNotifications();
    // groupsModel.getGroupsData(authModel.session_token);
    // groupsModel.getRecordsGroups(authModel.session_token);
    subscribePackageModel.getSubscribedPackagesList()
    tagsModel.getTags(authModel.session_token);
    masterMindsModel.getMasterMindsData(authModel.session_token);
    cardsModel.getCardsData();
    bankAccountsModel.getBankAccounts();
    subscribeModel.getSubscriptionsType();
    subscribeModel.getSubscriptions();
    chatsModel.getChats();
    // mediaModel.getMediaGroups(authModel.session_token);
  };

  updatecommunity = community => {
    if (this.community !== community) {
      const communities = toJS(groupsModel.state.filters.communities);

      const newCommunity = community;
      const newCommunityInFilter = communities.findIndex(comm => comm === newCommunity);
      if (newCommunityInFilter < 0) {
        groupsModel.changeUserCommunity(community);
      }

      this.community = community;

      answersModel.setAnswers({ community });
      groupsModel.groupTypeFilter(groupsModel.state.filters.type);
      groupsModel.clearSimpleId();
    }
  }

  isShowCheckCode = value => {
    this.showCheckCode = value;
  }

  getCurentUser = session_token => {
    const updateLocalUser = this.updateLocalUser;
    const headers = {
    "headers": {
      "accept": "application/json, text/plain, */*",
      "session-token": session_token
    }};
    axios.get(`${baseAPI}users/profile`, headers)
      .then(function (response) {
        // handle success
        updateLocalUser(response.data.current_user);
        communityModel.getMyCommunityList({token: session_token})
        communityModel.getMyOwnCommunity({token: session_token})
        communityModel.getMyDistributionGroupsList({token: session_token})
        // authModel.changeLoading(false)
      })
      .catch(function (error) {
        // handle error
        authModel.logout();
        authModel.changeLoading(false)
      })
      .finally(() => authModel.changeLoading(false))
  }

  updateCurentUser = data => {
    const updateLocalUser = this.updateLocalUser;
    const body = { ...this.user, ...data };
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.put(`${baseAPI}users/profile`, body, headers)
      .then(function (response) {
        // handle success
        updateLocalUser(response.data.current_user);
      })
      .catch(function (error) {
        // handle error
        authModel.logout();
      })
  }

  validUserData = data => {
    const { setErrors, ...rest } = data;
    const body = rest;

    const getCode = data => {
      this.registerData = data;
      this.getCode(data.phone_number, data.email, setErrors)
    };

    axios.post(`${baseAPI}users/validation`, body)
      .then(function (response) {
        getCode(body);
      })
      .catch(function (error) {
        if (error.response.data) {
          error.response.data.errors.forEach(errorItem => {
            if (errorItem.includes('phone')) {
              setErrors("phone", errorItem);
            }
            if (errorItem.includes('email')) {
              setErrors("email", errorItem);
            }
          })
        }
      })
  }

  getCode = (phone_number,email, setErrors) => {
    const body = { phone_number, email };

    const validCode = value => this.isShowCheckCode(value);

    axios.post(`${baseAPI}users/get_code`, body)
    .then(function (response) {
      validCode(true);
    })
    .catch(function (error) {
      if (error.response.data) {
        setErrors("phone", error.response.data.errors[0]);
      }
    })
  }

  registerUser = data => {
    const { setErrors, code } = data;
    const body = { code, ...this.registerData };

    const updateLocalUser = this.updateLocalUser;

    axios.post(`${baseAPI}users`, body)
      .then(function (response) {
        authModel.setSesionToken(response.data.session_token);
        updateLocalUser(response.data.current_user);
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          setErrors("code", 'The code is incorrect. Please check it once again');
          setErrors("c", 'The code is incorrect. Please check it once again');
          setErrors("o", 'The code is incorrect. Please check it once again');
          setErrors("d", 'The code is incorrect. Please check it once again');
          setErrors("e", 'The code is incorrect. Please check it once again');
        }
      })
  }

  forgotPassword = data => {
    const { phone_number, setErrors } = data;
    const body = { phone_number };

    const onSecusess = () => {
      this.resetData = data;
    }

    axios.post(`${baseAPI}users/forgot_password`, body)
    .then(function (response) {
      onSecusess();
    })
    .catch(function (error) {
      if (error.response.data) {
        setErrors("phone", error.response.data.errors[0]);
      }
    })
  }

  resetPassword = data => {
    const { setErrors, ...rest } = data;
    const body = rest;

    const result = (response) => {
      this.resetFinish = true;
    };

    axios.post(`${baseAPI}users/reset_password`, body)
      .then(function (response) {
        result(response);
      })
      .catch(function (error) {
        if (error.response.data) {
          error.response.data.errors.forEach(errorItem => {
            if (errorItem.includes('phone')) {
              setErrors("phone", errorItem);
            }
            if (errorItem.includes('code')) {
              setErrors("code", errorItem);
            }
            if (errorItem.includes('Wrong confirmation code')) {
              setErrors("code", 'The code is incorrect. Please check it once again');
            }
          })
        }
      })
  }

  isOwner = owner => {
    return owner?.id === this.user?.id;
  }
}

decorate(UserModel, {
  user: observable,
  auth_user:observable,
  showCheckCode: observable,
  registerData: observable,
  resetData: observable,
  resetFinish: observable,
  community: observable,
  updateLocalUser: action,
  getCurentUser: action
})

export const userModel = new UserModel();
