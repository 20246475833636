import React, {useMemo} from "react";
import MMFollowing from "../MMFollowing";
import NewContent from "../../../components/NewContent";
import {inject, observer} from "mobx-react";
import Daily from "../Daily";
import Empty from "../../../components/Empty";
import moment from "moment";
import {useRouteMatch} from "react-router";
import partition from 'lodash/partition'
import flatten from 'lodash/flatten'
import CommunitiesBlock from "../../../components/Communities";
import LoadingBlock from "../../../components/LoadingBlock";


const HomeDailyDashboard = props => {
  const {
    masterMindsModel: { masterMinds },
    groupsModel: { filteredGroups, groups},
    userModel: {user, community}
  } = props

  const sortedArr = useMemo(_=>{
    const splitedArr =
      partition(filteredGroups?.slice(), el => {
        return moment(el?.start_time).add(el.duration, 'minutes').unix() >= moment().unix()
      })
        .map(part => part.sort(function (curr, next) {
            if (moment(next?.start_time).add(next.duration, 'minutes').unix() < moment().unix()) {
              return moment(next?.start_time).unix() - moment(curr?.start_time).unix()
            } else {
              return (moment(next?.start_time).unix() - moment(curr?.start_time).unix()) * -1
            }
          }
        ))
    return splitedArr?.length && flatten(splitedArr) || []
  }, [filteredGroups])
  const match = useRouteMatch()

  return (
    <>
      <CommunitiesBlock/>
      {sortedArr?.length ? <Daily
        data={sortedArr}
        match={match}
        role={user?.role}
        key="daily"
        community={community}
        showMoreIncrement={4}
        initialMoreCount={4}
      /> :
        (groups.isLoading || groups.firstLoading) ?
          <LoadingBlock/>
          :
          <Empty />
      }
      <NewContent/>
      {masterMinds && masterMinds.count ? <MMFollowing key="follow"/> : ''}
    </>
  )

}

HomeDailyDashboard.propTypes={

}


export default inject('groupsModel', 'masterMindsModel', 'mediaModel',  'userModel')(observer(HomeDailyDashboard))