import React from 'react';
import { inject, observer } from 'mobx-react';

import RegisterForm from './Components/registerForm';
import ValidateCode from './Components/validateCodeForm';

import './register.scss';

const Register = props => {
  const { userModel: { showCheckCode } } = props;

  if (showCheckCode) return ( <ValidateCode /> );

  return ( <RegisterForm /> )
}

export default inject( 'userModel')(observer(Register));
