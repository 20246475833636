import React, { memo } from 'react';

const ProfileIcon = props => {
  const { color = '#A8A7BC' } = props;

  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8038 11.4371L9.2563 10.1635C9.01603 10.0432 8.8667 9.8016 8.8667 9.5328V8.6312C8.92777 8.55653 8.99203 8.47147 9.05843 8.3776C9.38883 7.91093 9.65363 7.39147 9.84617 6.8312C10.2224 6.65867 10.4667 6.28667 10.4667 5.86667V4.8C10.4667 4.5432 10.3707 4.2944 10.2 4.1V2.6816C10.215 2.53493 10.2736 1.66187 9.64217 0.9416C9.09443 0.3168 8.20563 0 7.00003 0C5.79443 0 4.90563 0.3168 4.3579 0.941333C3.72643 1.6616 3.7851 2.53493 3.80003 2.6816V4.1C3.62937 4.2944 3.53337 4.5432 3.53337 4.8V5.86667C3.53337 6.1912 3.68083 6.49387 3.93257 6.69573C4.17683 7.66293 4.68803 8.39173 4.8667 8.6256V9.508C4.8667 9.76613 4.7259 10.0029 4.4995 10.1267L2.12057 11.4243C1.34697 11.8464 0.866699 12.6555 0.866699 13.5365V14.4C0.866699 15.6656 4.8787 16 7.00003 16C9.12137 16 13.1334 15.6656 13.1334 14.4V13.5885C13.1334 12.6717 12.6238 11.8472 11.8038 11.4371Z" fill={color} />
    </svg>
  )
}

export default memo(ProfileIcon);

