import React, { useState, useEffect } from "react";
import PlaylistRecomendedHeader from './PlaylistRecomendedHeader';
import RecomendationItem from './RecomendationItem';

import "./style.scss";


const ContentRecomendations = (props) => {
  const {
    recomendations, 
    handleRecomendationItemClick, 
    playlist,
    activeTab, 
    setActiveTab,
    currentMediaId
  } = props;
  
  const playlist_items = playlist?.items ? playlist.items : [];
  const data = (playlist && activeTab !== "recomended") ? playlist_items : recomendations
  return (
      <div className="content_recommendations">
        {playlist ? 
          <PlaylistRecomendedHeader playlist = {playlist} active = {activeTab} setActive = {setActiveTab} />  :
          <h1 className="content_recommendations-title">Recommended</h1>}
        {data.map((item, key) => {
          return item.url && (
            <RecomendationItem 
              handleClick = {() => handleRecomendationItemClick(item)}
              recomendation = {item} 
              currentMediaId = {currentMediaId}
              />  
          )
        })} 
      </div>
    );
  
};

export default ContentRecomendations;
