import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    padding: '35px 20%',
    background: '#FF2D55',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: '60vh !important',
      padding: '0px 25px !important'
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10
  },
  title: {
    fontSize: 28,
    marginBottom: 20,
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
  },
  subTitle: {
    width: '73%',
    fontSize: 22,
    color: '#fff',
    fontWeight: 300,
    marginBottom: 20,
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
  }
}));

const MainButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    borderRadius: 4,
    width: '48%',
  }
})(Button);

const SubmitButton = withStyles({
  root: {
    color: '#FF2D55',
    boxShadow: 'none',
    background: '#fff',
    fontFamily: 'Red Hat Display',
    fontWeight: 'bold'
  }
})(MainButton);

const CancelButton = withStyles({
  root: {
    color: '#fff',
    boxShadow: 'none',
    border: '1px solid #fff',
    background: 'transparent',
    fontFamily: 'Red Hat Display',
    fontWeight: 'bold',
  }
})(MainButton);

const noOutlined = { outline: "none" }

const DeleteGroupForm = ({ deleteGroup, hideDelete, isOwner }) => {
  const classes = useStyles();

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <Typography className={classes.title}>
          Delete this group?
        </Typography>
        <Typography className={classes.subTitle}>
          If you delete a group, it {isOwner ? 'cannot' : 'can'} be restored.
        </Typography>
        <div className={classes.buttons}>
          <CancelButton style={noOutlined} onClick={hideDelete}>Cancel</CancelButton>
          <SubmitButton style={noOutlined} variant="contained" onClick={deleteGroup}>Yes, delete</SubmitButton>
        </div>
      </form>
    </>
  )
}

export default DeleteGroupForm