import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    padding: '35px 20%',
    background: '#000',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: '60vh !important',
      padding: '0px 25px !important'
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10
  },
  input: {
    fontSize: 16,
    color: '#fff',
    marginBottom: 40,
    borderColor: '#fff'
  },
  title: {
    fontSize: 28,
    marginBottom: 40,
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
  },
  subTitle: {
    fontSize: 20,
    color: '#fff',
    fontWeight: 300,
    marginBottom: 20,
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
  }
}));

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInputBase-input': {
      padding: '14.5px 14px',
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
})(TextField);

const SubmitButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    borderRadius: 4,
    color: '#000',
    width: '48%',
    boxShadow: 'none',
    background: '#fff',
    fontFamily: 'Red Hat Display',
    fontWeight: 'bold'
  }
})(Button);

const CancelButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    borderRadius: 4,
    color: '#fff',
    width: '48%',
    boxShadow: 'none',
    border: '1px solid #fff',
    background: 'transparent',
    fontFamily: 'Red Hat Display',
    fontWeight: 'bold'
  }
})(Button);

const noOutlined = { outline: "none" };
const maxReportLength = 120;

const ReportGroupForm = ({ saveReport, hideReport }) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  const isMessageValid = message.length !== 0 && message.length <= maxReportLength;

  const handleSave = () => {
    if (isMessageValid) {
      saveReport(message)
    }
  }

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <Typography className={classes.title}>
          Report Abuse
        </Typography>
        <Typography className={classes.subTitle}>
          Enter a reason
        </Typography>
        <StyledTextField
          label="Reason"
          variant="outlined"
          id="outlined-basic"
          value={message}
          onChange={e => e.target.value.length <= maxReportLength && setMessage(e.target.value)}
          className={classes.input}
          maxlength="120"
        />
        <div className={classes.buttons}>
          <CancelButton style={noOutlined} variant="outlined" onClick={hideReport}>Cancel</CancelButton>
          <SubmitButton style={noOutlined} variant="contained" onClick={handleSave}>Submit</SubmitButton>
        </div>
      </form>
    </>
  )
}

export default ReportGroupForm