import ToggleSaveBtn from "../Button/toggleSaveBtn";
import Dialog from "../Dialog";
import ContentDetails from "../../pages/ContentDetails";
import Button from "../Button";
import RecordItem from "../RecordItem";
import React, {useEffect, useState, useReducer} from "react";
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import CustomSneckbar from "../CustomSnackbar";
import GroupDetails from "../../pages/GroupDetails";
import {actionPay, actionSubscribe, contentType, groupType, statusActive, statusDeactivated} from "../../const/general";
import PackageBlock from "../../pages/Profile/Components/Subscriptions/PackageBlock";
import CustomModal from "../Modals/Modal";
import AttendGroup from "../../pages/AttendGroup/index";
import AttendGroupPack from "../../pages/AttendGroup/AttendGropPack"
import {findTiersTitleByName} from "../../units/func";
import axios from 'axios'
import MitingsDateList from "../MitingsDateList";
import ContentVideoPlayerPlaylist from "../ContentVideoPlayer/Playlist/"

import playlistGreyIcon from '../../res/icons/playlistGreyIcon.svg';


import "./style.scss";

const ContentDetailsPopup = props => {
  const {
    item:outerItem,
    tab,
    userModel: { isOwner, community, user },
    mediaModel: { setSelectRecord },
    groupsModel: {setParticipants, restoreGroup, removeGroup, buyGroup, resualtMessage, changeResultMessage, setLastGroup, getGroupParticipants, getSingleGroupData},
    subscribePackageModel:{subscriptionPackagesList, getSubscriptionPackagesList},
    LoaderControllerModel: {setIsGlobalLoaderNeed},
    communityModel: {communityList},
    playlistModel:{
      getMyPlaylists,
      showPlaylistIcon
    },
    reportState,
    setAttendOpen,
    handleClose,
    setCurrentGroupId = _ => {},
    currentGroupData = null,
    preview = true,
    renderType = contentType,
    itemPath = null,
    detailsPopupType = false
  } = props;
  const query =  new URLSearchParams(window.location.search);
  const [action] = useState(detailsPopupType || query.get('action'))

  const history = useHistory();
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false)
  const [needSubscriptionsBtn, setNeedSubscriptionsBtn] = useState(false)
  const [availableBySubscription, setAvailableBySubscription] = useState(false)
  const [canAskRefund, setCanAskRefund] = useState(true)
  const [showTextUnderAttend, setShowTextUnderAttend] = useState(false)
  const mobileViewEdge = 539;

  const [showPlaylist, setShowPlayList] = useState(false)


  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(_=>{
    setCurrentGroupId(outerItem)
    //if (item.price || (Array.isArray(item?.subscription_packages_ids) && item.subscription_packages_ids.length)){
      //console.log("tut ?")
    getSubscriptionPackagesList({user_id: item.owner.id})
    //}
    //getSubscriptionPackagesList({user_id: item.owner.id})
    return () => {
      changeResultMessage('')
    }
  },[])

  let item = currentGroupData || outerItem;
  useEffect(() => {
    const groupCancelToken = axios.CancelToken.source()
    getSingleGroupData(item.id, groupCancelToken )
    getGroupParticipants(item.id)
    setSubscribeModalOpen(false)
    return _ => groupCancelToken.cancel()

  },[subscriptionPackagesList])

  const openPaymentScreen = (needSubscribeBtn) => {
    if (!(item.available_for_subscribers && subscriptionPackagesList.subscription_packages.length  || !item.available_for_subscribers)) return false;

    if ([actionSubscribe, actionPay].includes(action)){
      needSubscribeBtn? setSubscribeModalOpen(true) : setAttendOpen(item)
    }

    window.requestAnimationFrame(() => setIsGlobalLoaderNeed(false))
  }
  useEffect(() => {
    const purchased = item.hasOwnProperty('purchase') && item.purchase
    const subscribed = item.hasOwnProperty('subscribed') && item.subscribed || item.hasOwnProperty('subscribed') && !item.subscribed && item.meetings_records?.every( (record) => record.record_present && record.record_access)
    let subsPacks = subscriptionPackagesList.subscription_packages
    const bought =(subscribed || purchased)
    const type = item.group_type || item.record_type
    let needSubscribeBtn = false
    let editedType = type
    if(type === "recurring_webinar"){
      editedType = "webinar"
    }else if(type === "recurring"){
      editedType = "master_mind"
    }
    let availableBySubscriptions =  subsPacks.some((pack) => {
      return pack.current && 
        pack.tiers.includes(editedType) && 
        (pack.communities.some(comm => comm == community)) && 
        (item?.subscription_packages_ids && item.subscription_packages_ids.length) && (
          (item?.communities ? item.communities.some(comm => pack.communities.includes(comm.id + "")):false) ||
          (item?.distribution_group ? item.distribution_group.communities.some(comm => pack.communities.includes(comm.id + "")): false)
        )
    }) 

    
    if (!availableBySubscriptions && subsPacks.length && !bought) {
      let allAvlbleComm = item?.communities ? item.communities : [];
      item?.distribution_group?.communities && item.distribution_group.communities.filter(i => allAvlbleComm.push(i))
      let itemCommunitiesId = allAvlbleComm.map(comm => comm.id)
      needSubscribeBtn = !availableBySubscriptions && !item.leaved &&
        (item?.subscription_packages_ids && item.subscription_packages_ids.length > 0) &&
        subscriptionPackagesList?.subscription_packages?.length &&
        subsPacks.some((pack) => pack.tiers.includes(editedType) && 
          itemCommunitiesId.some(comm => pack.communities.includes(comm + "")) && 
          pack.status !== statusDeactivated
        )
      setNeedSubscriptionsBtn(needSubscribeBtn)
      setAvailableBySubscription(!!availableBySubscriptions)
    } else if (Array.isArray(item?.subscription_packages_ids) && item.subscription_packages_ids.length && !bought){
      setNeedSubscriptionsBtn(!availableBySubscriptions)
      setAvailableBySubscription(availableBySubscriptions)
      setShowTextUnderAttend(availableBySubscriptions)
      setAttendOpen(false)      
    } else if (bought) {
      setNeedSubscriptionsBtn(false)
      setAvailableBySubscription(false)
      setSubscribeModalOpen(false)
      setCanAskRefund(["recurring_webinar", "recurring"].includes(type) ? !availableBySubscriptions : true)
    }
    openPaymentScreen(!!needSubscribeBtn)
  }, [subscriptionPackagesList, item])

  useEffect(() => {
    console.log("subscriptionPackagesList", JSON.parse(JSON.stringify(subscriptionPackagesList)))
  },[subscriptionPackagesList])

  const [openReport, setOpenReport] = reportState
  const is_owner = isOwner(item.owner);
  const toggleSubscribeModal = () => setSubscribeModalOpen(!subscribeModalOpen)

  const toggleSave = <ToggleSaveBtn
    item={item}
    restore={restoreGroup}
    remove={removeGroup}
    buy={buyGroup}
  />

  const renderPlaylistIcon = () => {
    const config = {
      src: playlistGreyIcon,
      onClick: (e) => {
        getMyPlaylists()
        setShowPlayList(true)
        e.stopPropagation();
      },
      alt: 'play_list',
      className: 'playlistGreyIcon',
    };
    return <img {...config} />;
  };

  const hideReport = () => {
    setOpenReport(false);
    setParticipants([]);
    setSelectRecord(null);
    if (!itemPath){
      const pathname = window.location.pathname.split('/').filter(part => part);
      const nextPath = pathname.length && `/${pathname[0]}/${pathname[1]||''}`
      const needPush = nextPath && nextPath !== window.location.pathname
      needPush && history.push(nextPath);
    }
  };

  const buyContentBtn = () => {
    if (availableBySubscription){
      return (
        <div className={'bySubscriptions'}>
          <Button
            label="SAVE"
            whiteGradient
            onClick={() => {
              buyGroup(item.id, null, null, null);
            }}
          />
          <p>
            this {findTiersTitleByName(item.group_type || item.record_type).toLowerCase()} is available as part
            of your subscription
          </p>
        </div>
      )
    } else {
      return (<Button
        orangeGradient={true}
        label={'Buy'}
        onClick={() => {
          needSubscriptionsBtn? setSubscribeModalOpen(true) : setAttendOpen(item);
        }}
      />)
    }
  }

  const groups = (<GroupDetails
    item={item}
    openDetails={true}
    itemPath={itemPath}
    openSubscriptions={toggleSubscribeModal}
    handleCloseDetails={() => {
      setLastGroup(false);
      setCurrentGroupId(null)
      handleClose(false)
    }}
    needSubscriptionsBtn={item.available_for_subscribers && !item.have_subscription}
    availableBySubscription={availableBySubscription}
    canAskRefund={canAskRefund}
    showTextUnderAttend = {showTextUnderAttend}
  >
    <>
      {item?.meetings_records?.length ? <div className="livestream_records">
        {item?.meetings_records?.map((record, key) => (
          <div className="record" key={key}>
            <RecordItem
              index={key}
              record={record}
              groupID={item.id}
              is_owner={item.is_owner}
              owner= {item?.owner}
              record_type={item.record_type}
              record_preview={item?.image}
              event_type={item.group_type}
              availableBySubscription={availableBySubscription}
              recordPrice={(item.price / item.meetings_count)}
              item = {item}
            />
          </div>
        ))}
      </div> : null}
      {resualtMessage && <CustomSneckbar
        message={resualtMessage}
        onClose={() => {
          changeResultMessage('');
          if (resualtMessage === 'Success' || resualtMessage === 'You Request send') { setAttendOpen(false)} }}
        severity={resualtMessage === 'Success' || resualtMessage === 'You Request send'? "success" : 'error'}
      />}
    </>
  </GroupDetails>)

  const subscriptionPopup = (
    <CustomModal customWidth='660px' isOpen={subscribeModalOpen} handleClose={toggleSubscribeModal}>  
      {/*<div className = "selectSubscriptionHeaderInfo">
        <span className = "selectSubscriptionHeaderInfoTitle">This group info:</span>
        <span className = "selectSubscriptionHeaderInfoGroup bullet">{item.private ? "private group" : "public group"}</span>
        <span className = "bullet">{item.meetings_count} meetings</span>
        <span><MitingsDateList item = {item}/></span>
  </div>*/}
      <h1 className='subscribeTitle'>{item.available_for_subscribers ? "Select Subscription" : "Select Package"}</h1>
      {item.available_for_subscribers && <div className={'subscriptions_subTitle'}>You can join by purchasing a subscription or making a single purchase.
        <br/>Please choose your purchasing preference below.</div>}
      {item.available_for_subscribers && <PackageBlock
        className='modalContent' classNameCard='modalHeight ' customWidth
        detailsSubscription={{
          type: item.record_type || item.group_type,
          community: communityList.communities.filter(comm => comm.id === community)
        }}
        mmData={item.owner}
        data={subscriptionPackagesList.subscription_packages.filter((pack) => (pack.status === statusActive || pack.status === statusDeactivated && pack.current))}
      />}
      {![0, ""].includes(item.price) && 
        <div className={'subscriptions_singlePurchase'}>
          {item.available_for_subscribers &&<div>- or -</div>}
          <Dialog openButton={<Button label={`Single purchase $ ${parseFloat(item.price/100).toFixed(2)}`} className = 'selectSubscriptionButton' violetGradien/>}>
            <AttendGroup item={item} handleClose={toggleSubscribeModal}/>
          </Dialog>
        </div>
      }
    {item.meetings_in_pack && item.pack_price && item.available_for_purchases && item.create_package &&
          <div className={'subscriptions_singlePurchase'}>
            <div>- or -</div>
            <Dialog openButton={<Button label={`${item.meetings_in_pack} meetings in Pack $ ${parseFloat(item.pack_price/100).toFixed(2)}`} className = 'selectSubscriptionButton' violetGradien/>}>
              <AttendGroupPack item={item} handleClose={toggleSubscribeModal}/>
            </Dialog>
          </div>
    }
    </CustomModal>
  )
  const content = (<ContentDetails
    report={openReport}
    item={item}
    tab={tab}
    is_owner={is_owner}
    openDetails={true}
    hideReport={hideReport}
    isReport={openReport}
    openSubscriptions={toggleSubscribeModal}
    needSubscriptionsBtn={needSubscriptionsBtn}
    availableBySubscription={availableBySubscription}
    toggleSave={showPlaylistIcon(item) && renderPlaylistIcon()} 

  >
    <>
      {showPlaylist ? 
        <div className='addToPlaylistcontainer'>
          <ContentVideoPlayerPlaylist 
            showPlaylist = {showPlaylist}
            handleClose = {() => setShowPlayList(false)}
            currentMediaIdViaProps = {item?.meetings_records?.length ? item?.meetings_records[0].id : null}
          />
        </div>: null
      }
      {item?.meetings_records?.length?  <div className="livestream_records">
        {item?.meetings_records?.map((record, key) => (
          <div className="record" key={key}>
            <RecordItem
              item = {item}
              index={key}
              record={record}
              groupID={item.id}
              is_owner={item.is_owner}
              owner= {item.owner}
              record_type={item.record_type}
              record_preview={item.image}
              event_type={item.group_type}
              availableBySubscription={availableBySubscription}
              recordPrice={
                item.meetings_count
                  ? item.price / item.meetings_count
                  : item.price / item.meetings_records.length
              }
            />
          </div>
        ))}
      </div> : null}
      {(!tab && !is_owner || tab == 'all') && 
        !item.purchase && item.group_type !== 'webinar' && 
        !item.blocked && item.price !== 0 && 
        !(item.available_for_subscribers && item.have_subscription) && (
        buyContentBtn()
      )}
      {(item.available_for_subscribers && item.have_subscription && item?.record_type) ? 
        <div className = "partOfSubscriptionMessage" style = {{background: "#FE1E92"}}>
          this {item.record_type.split("_")[0]} content is available as part of your subscription
        </div>: null
      }
    </>
    {resualtMessage && <CustomSneckbar
      message={resualtMessage}
      onClose={() => {
        changeResultMessage('');
        if (resualtMessage === 'Success' || resualtMessage === 'You Request send') { setAttendOpen(false)} }}
      severity={resualtMessage === 'Success' || resualtMessage === 'You Request send' ? "success" : 'error'}
    />}
  </ContentDetails>)

  return (
    <Dialog
      className="detailGroup"
      closeColor="#43425D"
      onClose={() => {
        setLastGroup(false);
        setCurrentGroupId(null)
        handleClose(false)
        hideReport();
      }}
      itemPath={itemPath}
      defaultOpen={preview}
    >
     <>
       {renderType === groupType? groups : content}
       {subscriptionPopup}
     </>
    </Dialog>
  );
}

export default inject('groupsModel', 'userModel', 'mediaModel', 'subscribePackageModel', 'LoaderControllerModel', 'communityModel', "playlistModel")(observer(ContentDetailsPopup))