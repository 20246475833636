import {gtMasterMind, gtRecurring, gtRecurringWebinar, gtWebinar} from "./general";

export const menuList1 = [
  {
    label: 'Show Creators',
    key: 'master_minds',
  }
];

export const menuList2 = [
  {
    label: 'Livestream',
    key: `${gtWebinar},${gtRecurringWebinar}`,
  },
  /*{
    label: 'Groups',
    key: 'support',
  },*/
  {
    label: 'Groups',
    key: `${gtMasterMind},${gtRecurring}`,
  },
];

export const menuList3 = [
  {
    label: 'Videos',
    key: 'video_record',
  },
  {
    label: 'Meditations',
    key: 'meditation',
  },
  {
    label: 'Podcasts',
    key: 'podcast',
  }
];
