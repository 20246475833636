import React , { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import HeadRoom from 'react-headroom';
import { stack as MenuStack } from 'react-burger-menu';
import ButtonBase from '@material-ui/core/ButtonBase';
import LinkImg from '../LinkImg/linkImg';
import CurrentAvatar from '../CurrentAvatar';
import Logo from '../../res/img/logo_logined.png';
import CloseRoundIcon from '../../res/iconComp/CloseRound';
import { disableBodyScroll, returnName } from '../../units/func';

import { home, profile } from '../../const/urlKeys'; // livestreams, collaborations, workshops
import './Header.scss';

const maxlimit = 20;

const FirstLoginHeader = props => {
  const history = useHistory();
  const { push } = history; // location
  const { user } = props; //updatecommunity, community

  const isMenuOpen = function(state) {
    disableBodyScroll(state.isOpen);
    return state.isOpen;
  };

  useEffect(()=>{
    // enable window scroll by default
    disableBodyScroll(false);
  }, [window.innerWidth]);
  
  const returnUser = (user) => {
    const userName = returnName(user);
    return (
      <>
        <CurrentAvatar
          alt={userName}
          src={user?.image ? user?.image?.thumbnail?.url : ''}
          className="headerAvatar"
        /> 
    
        <div className="userName">
          {userName && userName.split(' ').map(text => (
            text.length > maxlimit ? text.substring(0,maxlimit-3) + '... ' : text + ' '
          ))}
        </div> 
      </>
    )
  }

  return (
    <div className="sticky">
      <HeadRoom>
        <div className="header firstLogin logined">
          <LinkImg alt="logo" src={Logo} url={home} className="logoMain" />
          <div className="buttons">
            <ButtonBase className="avatarActionBlock" onClick={() => push(profile)}>
              {returnUser(user)}
            </ButtonBase>
          </div> 
          <MenuStack
            className="burger"
            right={true}
            width={'100%'}
            onStateChange={ isMenuOpen }
            customCrossIcon={<CloseRoundIcon />}
          >
            {window.innerWidth < 960 && 
              <ButtonBase className="userBlock" onClick={() => push(profile)}>
                {returnUser(user)}
              </ButtonBase>
            }
          </MenuStack>
        </div>   
      </HeadRoom>
    </div>
  );
}

export default FirstLoginHeader;
