import React, { memo } from 'react';

const VideoLabel = props => {
  // const { color = 'white' } = props;

  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8681 5.77021L13.7872 0.612766C13.4043 0.229787 12.8936 0 12.3319 0H2.04255C0.919149 0 0 0.919149 0 2.04255V21.9574C0 23.0809 0.919149 24 2.04255 24H17.4128C18.5362 24 19.4553 23.0809 19.4553 21.9574V7.2C19.4553 6.66383 19.2511 6.15319 18.8681 5.77021ZM14.3489 6.12766C13.7872 6.12766 13.3277 5.66809 13.3277 5.10638V1.58298L17.7957 6.12766H14.3489Z" fill="#7E7D8F"/>
      <path d="M10.5143 11.4766H5.03947C4.31737 11.4766 3.72656 12.0674 3.72656 12.7895V17.214C3.72656 17.9361 4.31737 18.5269 5.03947 18.5269H10.5143C11.2364 18.5269 11.8272 17.9361 11.8272 17.214V12.7895C11.8272 12.0542 11.2364 11.4766 10.5143 11.4766Z" fill="#F4F4F7"/>
      <path d="M14.7557 12.193C14.6769 12.2062 14.5982 12.2455 14.5325 12.2849L12.4844 13.4665V16.5125L14.5456 17.6941C14.9264 17.9173 15.399 17.786 15.6222 17.4053C15.6879 17.2871 15.7273 17.1558 15.7273 17.0114V12.9545C15.7273 12.4687 15.2677 12.0749 14.7557 12.193Z" fill="#F4F4F7"/>
    </svg>
  )
}

export default memo(VideoLabel);
