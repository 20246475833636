import { decorate, action, observable } from 'mobx';
import { socketAPI } from '../const/settings'; // baseAPI
import { groupsModel } from './groups';

export class GroupSocketModel {
  constructor() {
    this.socketUrl = socketAPI;
    this.groupSocket = null;
    this.riseAHandList = [];
    this.mutedUsers = [];
  }

  addRaseAHand = id => {
    this.riseAHandList.push(id);
  }

  removeRaseAHand = id => {
    /* eslint eqeqeq: 0 */
    this.riseAHandList = this.riseAHandList.filter(val => val != id);
  }

  addMutedUsers = id => {
    /* eslint eqeqeq: 0 */
    const current = groupsModel.groupParticipants.participants.filter(p => p.id == id)[0];
    /* eslint eqeqeq: 0 */
    const rest = groupsModel.groupParticipants.participants.filter(p => p.id != id);
    groupsModel.setParticipants({
      count: groupsModel.groupParticipants.participants.count,
      participants: [...rest, { ...current, muted: true }]
    });
    this.mutedUsers.push(id);
  }

  removeMutedUsers = id => {
    /* eslint eqeqeq: 0 */
    const current = groupsModel.groupParticipants.participants.filter(p => p.id == id)[0];
    /* eslint eqeqeq: 0 */
    const rest = groupsModel.groupParticipants.participants.filter(p => p.id != id);
    groupsModel.setParticipants({
      count: groupsModel.groupParticipants.participants.count,
      participants: [...rest, { ...current, muted: false }]
    });
    this.mutedUsers = this.mutedUsers.filter(val => val != id);
  }


  disconnectSocket = () => {
    this.groupSocket = null;
  }

  raiseHand = () => {
    this.sendEvent("RiseAHand");
  }

  removeHand = user_id => {
    if (user_id) {
      this.sendEvent("RemoveHand", user_id);
    } else {
      this.sendEvent("RemoveHand");
    }
  }

  startGroup = () => {
    this.sendEvent("StartGroup");
  }

  endGroup = () => {
    this.sendEvent("EndGroup");
  }

  muteAllUsers = () => {
    this.sendEvent("MuteAllUsers");
  }

  unMuteAllUsers = () => {
    this.sendEvent("UnMuteAllUsers");
  }

  participantDisconnected = user_id => {
    this.sendEvent("ParticipantDisconnected", user_id);
  }

  participantConnected = user_id => {
    this.sendEvent("ParticipantConnected", user_id);
  }

  muteUser = user_id => {
    this.sendEvent("MuteUser", user_id);
  }

  unmuteUser = user_id => {
    this.sendEvent("ResetMuteUser", user_id); // ResetMuteUser
  }

  removeFromGroup = user_id => {
    this.sendEvent('RemoveParticipant', user_id);
  }


  sendEvent = (event_type, user_id) => {
    this.groupSocket.send(JSON.stringify({
      command: 'message',
      data: JSON.stringify({
        event: event_type,
        action: 'speak',
        user_id: user_id
      }),
      identifier: JSON.stringify({ "channel": "GroupChannel" })
    }))
  }
}

decorate(GroupSocketModel, {
  // socketUrl: observable,
  // raiseHand: action,
  // connect: action,
  // riseAHandList: observable,
})

export const groupSocketModel = new GroupSocketModel();
