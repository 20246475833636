import React from 'react';
//components
import GroupOwner from '../../components/EventItem/eventOwner';
import EvenetDateTime from '../../components/EventItem/eventDateTime';
import ShareButton from '../../components/ShareButton';
import ReportButton from '../../components/ReportButton';
import DeleteButton from '../../components/DeleteButton';
import RestoreButton from '../../components/RestoreButton';

import './header.scss';

const ContentHeader = (props) => {
  const {
    tab,
    is_owner,
    item,
    showReport,
    removeGroup,
    restoreGroup,
    deleteGroup,
  } = props; // reportGroup
  const isUpcoming = item.status === 'upcoming';
  const isOwner = item.is_owner !== undefined ? item.is_owner : false;

  const renderButton = () => {
    const type = item.record_type ? 'record' : null;
    const handleDelete = () => {
      if (tab === 'my') {
        deleteGroup(item.id);
      }

      if (tab === 'saved') {
        removeGroup(item.id);
      }
    };

    // if (item.purchase && item.removed) {
    //   const buyed =
    //     item?.meetings_records && item?.meetings_records.length > 0
    //       ? item?.meetings_records.filter((meeting) => meeting.record_access)
    //       : [];
    //   if (!item.subscribed && buyed.length === 0 && !item.removed) {
    //     return null;
    //   }
    //   return <RestoreButton onClick={() => restoreGroup(item.id, type)} />;
    // } else {
    //   return <DeleteButton onClick={handleDelete} color="#7e7e8f" />;
    // }
  };

  return (
    <>
      <div className="attendGroupSocial">
        {/*<ShareButton item={item}/>*/}
        {/* {isUpcoming && <ReportButton onClick={reportGroup} />} */}
        {!isOwner && tab !== 'my' && <ReportButton onClick={showReport} color="#fff"/>}
      </div>
      <div className="imageWrapper">
        <img src={item.image.url} alt=""/>
        <div className="attendGroupInfo">
          {isUpcoming && <EvenetDateTime start_time={item.start_time || item.updated_at}/>}
          {(is_owner || item.subscribed || item.purchase) && renderButton()}
          {/* {!isOwner && tab !== 'my' && <ReportButton onClick={showReport} color="#7E7D8F" />} */}
        </div>
      </div>
    </>
  );
};

export default ContentHeader;
