import React, { useEffect, useState, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

import SearchPage from '../SearchPage';
import Button from '../../components/Button';
import EventItem from '../../components/EventItem';
import LSRGroup from '../../components/LsRecordGroup';

import RightPerson from '../../components/RightPerson';
import { menuList2, menuList3 } from '../../const/search';
import './page.scss';
import ContentDetailsPopup from "../../components/ContentDetailsPopup";
import Dialog from "../../components/Dialog";
import AttendGroup from "../AttendGroup";
import {contentType, groupType} from "../../const/general";

const Search = (props) => {
  const {
    // mmContextModel: { filteredMMCotextGroups },
    searchModel,
    className,
    match,
    role,
  } = props;
  const { search } = useParams();
  const [searchParams, setSearchParams] = useState([]);
  const [moreGroups, setMoreGroups] = useState(1);
  const [moreRecords, setMoreRecords] = useState(1);
  const [moreMasterMinds, setMoreMasterMinds] = useState(2);
  const [defualtOpen, setDefualtOpen] = useState(
    (match && match.params && match?.params?.id) || null
  );
  const [isAttendOpen, setAttendOpen] = useState(false);
  const [isDetailsOpen, setDetailsOpen] = useState(false)
  const [openReport, setOpenReport] = useState(false);
  const [beforeFirstApply, setSearchApplied] = useState(true)
  const [detailsPopupType, setDetailsPopupType] = useState(false)
  const [renderType, setRenderType] = useState(false)

  const applySearch = (params) => {
    setSearchApplied(false);
    setSearchParams(params);
  }

  const isMasterMinds = searchParams.includes('master_minds');
  const isRecords = searchParams.some((searchParam) =>
    menuList3.some((record) => record.key === searchParam)
  );
  const isGroups = searchParams.some((searchParam) =>
    menuList2.some((record) => record.key === searchParam)
  );

  useEffect(() => {
    !beforeFirstApply && handleSearch();
  }, [search, searchParams, moreGroups, moreMasterMinds, moreRecords]);

  const handleSearch = () => {
    const records = menuList3
      .filter(({ key }) => searchParams.includes(key))
      .map((item) => item.key)
      .join(',');
    const groupTypes = menuList2
      .filter(({ key }) => searchParams.includes(key))
      .map((item) => item.key)
      .join(',');

    if (isRecords) {
      searchModel.getRecordsData(moreRecords, records, search);
    }
    if (isGroups) {
      searchModel.getGroupsData(moreGroups, groupTypes, search);
    }
    if (!isRecords && !isGroups) {
      searchModel.getGroupsData(1, groupTypes);
      searchModel.getRecordsData(1, records, search);
    }
    if (isMasterMinds) {
      searchModel.getMasterMinds(moreMasterMinds, search);
    }
  };

  const groupsData = searchModel.searchGroups;
  const recordsData = searchModel.searchRecords;
  const masterMindsData = searchModel.searchMasterMinds;

  useEffect(_ => {
    if (isDetailsOpen){
      const id = isDetailsOpen.id;
      const newData = recordsData?.groups?.find(item => item.id === id)
      newData && setDetailsOpen(newData)
    }
  },[recordsData])

  const isRenderMMs = masterMindsData.hasOwnProperty('users') && masterMindsData.users.length > 0;
  const isRenderGroups =
    groupsData.hasOwnProperty('groups') &&
    groupsData.groups.length > 0 &&
    (isGroups || (!isRecords && !isGroups));
  const isRenderRecords =
    recordsData.hasOwnProperty('groups') &&
    recordsData.groups.length > 0 &&
    (isRecords || (!isRecords && !isGroups));

  const attendDialog = () => (
    <Dialog
      openButton={null}
      className="detailGroup"
      closeColor="#43425D"
      onClose={ _ => {
        setAttendOpen(false)
      }}
      defaultOpen={isAttendOpen}
    >
      <AttendGroup item={isAttendOpen} />
    </Dialog>
  )

  const renderMasterMinds = (_) => (
    <div className="dailyBlock search">
      <h3>Coaches</h3>
      <Divider />

      {isRenderMMs ? (
        masterMindsData.users.map((user, i) => (
          <RightPerson
            key={user?.id}
            person={user}
            connectButtons
            onFollow={handleSearch}
            isLastItem={masterMindsData?.users?.length === i + 1}
          />
        ))
      ) : (
        <div className="search_no_content">Not found</div>
      )}
      {moreMasterMinds < masterMindsData.count && (
        <div className="moreButtonBlock">
          <Button
            grey
            big60
            width100
            label="Show More"
            className="moreButton"
            onClick={() => setMoreMasterMinds(moreMasterMinds + 10)}
          />
        </div>
      )}
    </div>
  );

  const renderGroups = (_) => (
    <div className="dailyBlock search">
      <div className="listBlock listBlock">
      <h3>Groups</h3>
      <Divider />

      {isRenderGroups ? (
        groupsData.groups.map((item, index) => {
          if (index < moreGroups || defualtOpen == item.id) {
            return (
              <Fragment key={item.id}>
                <EventItem
                  refresh={handleSearch}
                  item={item}
                  key={item.id}
                  role={role}
                  defualtOpen={defualtOpen}
                  setDefualtOpen={setDefualtOpen}
                  handleOpenDialog={({type} = {})=>{
                    setRenderType(groupType)
                    setDetailsPopupType(type)
                    setDetailsOpen(item)
                  }}
                />
              </Fragment>
            );
          }
        })
      ) : (
        <div className={'search_no_content'}>Not found</div>
      )}
      {moreGroups < groupsData.count && (
        <div className="moreButtonBlock">
          <Button
            label="Show More"
            big60
            grey
            width100
            className="moreButton"
            onClick={() => setMoreGroups(moreGroups + 5)}
          />
        </div>
      )}
      </div>
    </div>
  );

  const renderRecords = (_) => (
    <div className="dailyBlock search">
      <div className="listBlock listBlock">
      <h3>Content</h3>
      <Divider />

      {isRenderRecords ? (
        recordsData.groups.map((item, index) => {
          if (index < moreRecords || defualtOpen == item.id) {
            return (
              <div className="groupLsRecords" key={item.id}>
                <LSRGroup id={match?.params?.id}
                          item={item}
                          restore={() => {}}
                          handleOpenDialog={_=>{
                            setRenderType(contentType)
                            setDetailsOpen(item)
                          }}
                          showReport={_ => {
                            setRenderType(contentType)
                            setDetailsOpen(item)
                            setOpenReport(true)
                          }}
                />
              </div>
            );
          }
        })
      ) : (
        <div className={'search_no_content'}>Not found</div>
      )}
      {moreRecords < recordsData.count && (
        <div className="moreButtonBlock">
          <Button
            label="Show More"
            big60
            grey
            width100
            className="moreButton"
            onClick={() => setMoreRecords(moreRecords + 5)}
          />
        </div>
      )}
      </div>
    </div>

      );

  return (
    <SearchPage
      className={classNames('mainPage', className)}
      applySearch={applySearch}
      searchParams={searchParams}
    >
      {isMasterMinds && renderMasterMinds()}
      {(isGroups || isRecords) && (
        <>
            {isGroups && renderGroups()}
            {isRecords && renderRecords()}
        </>

      )}
      {isAttendOpen && (attendDialog())}
      {isDetailsOpen && (<ContentDetailsPopup
        item={isDetailsOpen}
        handleClose={setDetailsOpen}
        setAttendOpen={setAttendOpen}
        reportState={[openReport, setOpenReport]}
        preview={true}
        renderType={renderType}
        detailsPopupType={detailsPopupType}
      />)}
    </SearchPage>
  );
};

export default inject('searchModel')(observer(Search));
