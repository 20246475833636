import React, {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import Button from '../../../../components/Button';

import './filters.scss'
import greyIcon from '../../../../res/icons/greyMenuDots.svg'
import triangleUp from  '../../../../res/icons/triangleUp.svg'
import triangleDown from  '../../../../res/icons/triangleDown.svg'
import CurrentAvatar from "../../../../components/CurrentAvatar";
import CheckedIcon from "../../../../res/iconComp/CheckedIcon";
import {select_all_community, set_community_from_memory, set_default_community} from "../../../../const/general";
import partition from 'lodash/partition'
import flatten from 'lodash/flatten'
import moment from "moment";

const CommunityFilter = props => {
  const {
    groupsModel: {
      state: { filters },
      changeFilterCommunity,
    },
    userModel: {community},
    communityModel: { communityList, memorizeFilterSelectedCommunity, filterSelectedCommunity },
    selectAllCommunity = false,
    action,
    filterByButtons = false
  } = props;

  useEffect(_=>{
    selectAllCommunity && handleClick(select_all_community)
    !selectAllCommunity && filterSelectedCommunity.length && handleClick(set_community_from_memory)
    return _ => {
      if (!selectAllCommunity) {
        memorizeFilterSelectedCommunity(filters.communities);
      }
      handleClick(set_default_community)
    }
  },[communityList]);

  const [opened, setOpened] = useState(false);
  const [firstSelected, setFirstSelected] = useState(communityList?.mappedCommunities[community]);
  const [additionalFiltersCount, setAdditionalCommunitiesCount] = useState(0);

  useEffect(() => {
    const firstSelectedId = communityList.selected.slice().sort(function (p, n) {
      if (n === community) return 1;
      return -1
    }).find( (c) => filters.communities.includes(c))
    firstSelectedId && setFirstSelected(communityList.mappedCommunities[firstSelectedId])
    !firstSelected && setFirstSelected(communityList?.mappedCommunities[community] || firstSelectedId)

    setAdditionalCommunitiesCount(filters.communities.length - 1)

  },[filters.communities, community, communityList])

  const handleClick = (id) => {
    // communityList.mappedCommunities[id].selected? unselectCommunity({id}): selectCommunity({id})
    switch (action) {
      case 'recordsGroups':
        changeFilterCommunity(id, 'recordsGroups', true);
        break;
      case 'contentFilter':
        changeFilterCommunity(id, 'contentFilter', true);
        break;

      case 'mediaFilter':
        changeFilterCommunity(id, 'mediaFilter', true);
        break;
      default:
        changeFilterCommunity(id, 'group', true);
        break;
    }
  }
  const toggleOpen = () => setOpened(!opened)

  const sortedCommunities = useMemo(() => {
    const all = communityList.selected.includes(community)? communityList.selected.slice() : communityList.selected.concat([community])
    const unsorted = all.map((id)=> communityList.mappedCommunities[id]);
    const sorted = partition(unsorted, (comm) => !!comm?.selected_at)
      .map((arr) => {
        return arr.sort( (next, prev) => {
          if ( prev.selected_at ) return moment(next.selected_at).unix() - moment(prev.selected_at).unix()
          return  moment(next.updated_at).unix() - moment(prev.updated_at).unix()
        }).reverse()
      });

    return flatten(sorted).reverse().sort((next, prev) => prev.id === community? 1 : -1)
  }, [communityList.selected, community]);

  return !filterByButtons ?
    <div className={'communityFilter'}>
      <div className={'communityFilter_head'} onClick={toggleOpen}>
        <div className={'communityFilter_background'}>
          <div className={'communityFilter_backgroundIcon'}><img src={greyIcon}/></div>
          <div className={'communityFilter_label'}>Community</div>
        </div>
        <div className={classNames('communityFilter_main mainCommunity', {opened})}>
          {!opened && <>
            <div className={"mainCommunity_logo"}>
              <CurrentAvatar alt={firstSelected?.title} src={firstSelected?.image?.thumbnail?.url} className='personInfo-avatar' />
            </div>
            <div className={'mainCommunity_title'}>{firstSelected?.title || ''} {additionalFiltersCount? `+ ${additionalFiltersCount}` : null}</div>
          </>}
          <div className={'mainCommunity_arrow'} >{opened? <img src={triangleUp}/>: <img src={triangleDown}/>}</div>
        </div>

      </div>
      {opened && <div className={'communityFilter_listWrapper'}>
        {sortedCommunities.map( (item) => {
          // const item = communityList.mappedCommunities[id]
          const id = item.id;
          const selected = filters.communities.includes(id)
          return (
            <div className={classNames("selectCommunity", {selected})} onClick={()=>handleClick(id)} key={id}>
              <div className={'selectCommunity_logo'}>
                <CurrentAvatar alt={item?.title} src={item?.image?.thumbnail?.url} className='personInfo-avatar' />
                {selected && <div className={'check'}>
                  <CheckedIcon width={16} height={16}/>
                </div>}
              </div>
              <div className={'community_name'}>{item?.title}</div>
            </div>
          )
        })}
      </div>}
    </div>:
    <div className="communityFilterByButtons">
      {sortedCommunities.map( (item) => {
        // const item = communityList.mappedCommunities[id]
        const id = item?.id;
        const selected = filters.communities.includes(id)
        return (
          <div 
            className={`communityNameButton ${selected && "selectedByButtonCommunity"}`}
            onClick={()=>handleClick(id)} 
            key={id}
          >
            {item?.title}
          </div>
        )
      })}
  </div>
    
}

export default inject('groupsModel', "communityModel", 'userModel')(observer(CommunityFilter));