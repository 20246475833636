import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Formik, Form } from "formik";
import {
  GroupCoachingTag,
  GroupGroupTag,
  GroupLiveStreamTag,
  gtMasterMind,
  gtRecurring, gtRecurringWebinar,
  gtSupport,
  gtWebinar
} from "../../const/general";
import Dialog from "../Dialog";
import CustomInput from "../Input/customInput";
import create_group_icons from "../../res/icons/create_group_icons";
import moment from "moment";
import CustomCalendar from "../UI/CustomCalendar";
import CustomTimePicker from "../UI/CustomTimePicker";
import {observer, inject} from "mobx-react";
import info from '../../res/icons/info_message.svg'
import classNames from "classnames";


const RESCHEDULE  = 'reschedule'
const MEETING  = 'meeting'
const WHOLE_GROUP  = 'wholeGroup'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    padding: '35px 0',
    background: '#000',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: '60vh !important',
      padding: '0px 25px !important',
    },
  },
  mainWrapper: {
    padding: '0 85px'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    columnGap: '20px',
    marginTop: 30,
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '20px',
    marginTop: 10,
  },
  input: {
    fontSize: 16,
    marginBottom: 40,
    backgroundColor: '#202020',
    color: '#E9E9F0',
    borderRadius: '4px',
    width: '100%'
  },
  inputWithIcon: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  scheduleInput: {
    width: "100%",
    fontSize: 16,
    borderRadius: '4px',
    backgroundColor: '#202020',
    color: '#E9E9F0',
    padding: "10px",
    paddingLeft: "36px",
    '& .MuiInputBase-input':{
      color: '#E9E9F0',
    }
  },
  timeWrapper:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
    gap: "20px",
    '& .title':{
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "100%",
      color: "#43425D"
    }
  },
  inputIcon:{
    position: "absolute",
    left: "10px",
    zIndex: 1
  },
  title: {
    fontSize: 28,
    marginBottom: 30,
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
  },
  subTitle: {
    fontSize: 20,
    color: '#fff',
    fontWeight: 300,
    marginBottom: 20,
    fontFamily: 'Red Hat Display',
  },

  scheduleWrapper:{
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },

  scheduleInputWrapper: {
    display: "flex",
    flexDirection: "column",
    color: '#FFFFFF',
    fontFamily: 'Red Hat Text',
    position: "relative"
  },
  scheduleTitle: {
    fontSize: '20px',
    marginBottom: '10px'
  },
  errorBanner: {
    display: "flex",
    backgroundColor: '#202020',
    padding: '15px 85px',
    color: '#FFFFFF',
    flexDirection:'row',
    textAlign: "center",
    gap: '15px',
    marginBottom: '20px'
  },
  infoIcon: {
    width: '24px',
    height: '24px'
  },
  errorRespText: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Red Hat Display",
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '21px',
    alignItems: "center",
  },
  invalidTime: {
    color: '#DD4B39'
  },
  scheduleList:{
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
    textAlign: "left"
  }
}));

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInputBase-input': {
      padding: '14.5px 14px',
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  },
})(TextField);

const SubmitButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    borderRadius: 4,
    color: '#000',
    width: '100%',
    boxShadow: 'none',
    background: '#fff',
    fontFamily: 'Red Hat Display',
    fontWeight: 'bold',
    maxWidth: "50%",
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#434343',
      color: '#000000'
    }
  },
})(Button);

const CancelButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    borderRadius: 4,
    color: '#fff',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #fff',
    background: 'transparent',
    fontFamily: 'Red Hat Display',
    fontWeight: 'bold',
    maxWidth: "50%"
  },
})(Button);
const ActionButton = withStyles({
  root: {
    fontSize: 16,
    padding: 10,
    lineHeight: '19px',
    borderRadius: 4,
    color: '#000000',
    width: "100%",
    boxShadow: 'none',
    background: '#FFFFFF',
    fontFamily: 'Red Hat Display',
    fontWeight: 'bold',
    '&:hover':{
      background: '#FFFFFF',
    }
  },
})(Button);

const noOutlined = { outline: 'none' };
const maxReportLength = 120;
const minReportLength = 10;

const CancelGroupForm = ( props ) => {
  const {
    hideCancel, item , successCancelClose,
    groupsModel: {cancelGroup, cancelNextMeeting, resheduleNextMeeting},
    meetingsModel: {getMySchedule, mySchedule, clearMySchedule}
  } = props;
  const classes = useStyles();
  const [action, setAction] = useState('');
  const [invalidTime, setInvalidTime] = useState(false);

  const IsoTime = (values) => {
    const format = 'dd, DD.MM.YY hh:mm a'
    return moment(`${values.day} ${values.time}`, format).toISOString()
  }
  useEffect(() => {
    return () => {
      clearMySchedule()
    }
  },[])
  useEffect(() => {
    if (action === RESCHEDULE){
      getScheduleForDate(moment(item.start_time))
    }
  },[action])

  const getScheduleForDate = (time) => {
    const from = moment(time).set({
      hour: 0,
      minute: 0,
      second: 0
    }).toISOString()
    const to = moment(time).set({
      hour: 23,
      minute: 59,
      second: 59
    }).toISOString()
    getMySchedule({from, to})
  }

  const getScheduleTime = () => {
    const timeFormat = "HH:mm",
      dayFormat = 'MMM DD'
     return mySchedule.meetings.map(({start_time, duration}) => {
       const start = moment(start_time).format(timeFormat)
       const startDay = moment(start_time).format(dayFormat)
       const end = moment(start_time).add(duration, 'minute').format(timeFormat)
       const endDay = moment(start_time).add(duration, 'minute').format(dayFormat)
       return <div>
         {start} {startDay === endDay? null: `on ${startDay}`} - {end} on {endDay}
       </div>
    })
  }

  const validations = ( values, props) => {
    const errors = {}
    let invalidTimeSelected = false;

    if (!values.message){
      errors.message = "Field can't be empty"
    } else if (values.message.length < minReportLength){
      errors.message = `Minimum ${minReportLength} characters!`
    } else if (values.message.length > maxReportLength){
      errors.message = `Maximum ${maxReportLength} characters!`
    }

    if (action === RESCHEDULE) {
      const selectTime = IsoTime(values)
      const initTime = item.start_time
      const currentTime = moment().set({second: 0}).toISOString()
      const t_diff = moment(selectTime).diff(moment(currentTime), "minute")
      if (t_diff <=0) {
        errors.time = "Please, select valid time"
      } else if (selectTime === initTime){
        errors.time = "Please, select other time"
      }

      if (mySchedule.count){
        mySchedule.meetings.forEach(({start_time, duration}) => {
          if (start_time === item.start_time) return;
          const endTime = moment(start_time).add(duration, 'minute')
          const startIsBetween = moment(selectTime).isBetween(moment(start_time), moment(endTime))
          const endIsBetween = moment(selectTime).add(item.duration, 'minute').isBetween(moment(start_time), moment(endTime))
          if (startIsBetween || endIsBetween){
            errors.time = "Please, select other time"
            invalidTimeSelected = true
          }
        })
      }
    }
    setInvalidTime(invalidTimeSelected)
    return errors
  }

  const label = () => {
    switch (item.group_type) {
      case gtWebinar:
      case gtRecurringWebinar:
        return GroupLiveStreamTag.toLowerCase()
      case gtSupport:
        return GroupGroupTag.toLowerCase()
      case gtRecurring:
      case gtMasterMind:
        return  GroupCoachingTag.toLowerCase()
      default:
        return 'group'
    }
  }

  const actionLabel = () => {
    switch (action) {
      case MEETING:
        return 'Cancel upcoming meeitng'
      case RESCHEDULE:
        return `Reschedule upcoming ${label()}`
      default:
        return `Cancel ${label()}`
    }
  }

  const initialValues = {
    message: '',
    time: moment(item.start_time).format('hh:mm a'),
    day: moment(item.start_time).format('dd, DD.MM.YY')
  }
  const submitCallback = (values, actions) => {
    const errorCb = (resp) => {
      console.log('resp', resp.data?.errors[0]);
    }
    const successCb = () => {
      successCancelClose(action === WHOLE_GROUP)
    }
    if (action === WHOLE_GROUP ) {
      cancelGroup({
        id: item.id,
        reason: values.message,
        errorCb,
        successCb
      });
    }
    if (action === MEETING){
      cancelNextMeeting({
        id: item.id,
        reason: values.message,
        errorCb,
        successCb
      })
    }
    if (action === RESCHEDULE){
      const ISOTime = IsoTime(values)
      resheduleNextMeeting({
        id: item.id,
        reason: values.message,
        start_time: ISOTime,
        errorCb,
        successCb
      })
    }

  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submitCallback}
        enableReinitialize
        isInitialValid={false}
        validate={validations}
       >
        {(formData) => {
          const {values, setFieldValue, isValid, errors} = formData;
          return (
            <Form className={classes.root}>
              <Typography className={classes.title}>{actionLabel()}</Typography>
              {!!mySchedule.count &&
              <div className={classes.errorBanner}>
                <>
                  <div className={classes.infoIcon}><img src={info}/></div>
                  <div className={classNames(classes.errorRespText, {[classes.invalidTime]: invalidTime})}>
                    <div>You already have a group scheduled for</div>
                    <div className={classes.scheduleList}>{getScheduleTime()}</div>
                  </div>
                </>
              </div>
              }

                  <div className={classes.mainWrapper}>
                {action ?
                  <>
                    <Typography className={classes.subTitle}>Enter a reason</Typography>
                    <StyledTextField
                      placeholder="Reason"
                      variant="outlined"
                      id="outlined-basic"
                      value={values.message}
                      onChange={(e) => {
                        setFieldValue("message", e.target.value)
                      }}
                      className={classes.input}
                      error={!!errors.message}
                      helperText={errors.message}
                    />
                    {action === 'reschedule' && <div className={classes.scheduleWrapper}>
                      <div className={classes.scheduleInputWrapper}>
                        <div className={classes.scheduleTitle}>New Date</div>
                        <Dialog
                          openButton={
                            <div className={classes.inputWithIcon}>
                              <img src={create_group_icons.white.date} className={classes.inputIcon}/>
                              <CustomInput
                                id={"date"}
                                className={classes.scheduleInput}
                                value={values.day}
                                disableUnderline={true}
                                onChange={()=>{}} // to refuse manual change
                              />
                            </div>
                          }
                          key={"date"}
                        >
                          <CustomCalendar
                            value={values.day}
                            title={'New Date'}
                            onSelect={(e) => {
                              setFieldValue('day', moment(e, 'DD-MM-YYYY').format('dd, DD.MM.YY'))
                              getScheduleForDate(moment(e, 'DD-MM-YYYY'))
                            }}
                          />
                        </Dialog>
                      </div>
                      <div className={classes.scheduleInputWrapper}>
                        <div className={classes.scheduleTitle}>New Time</div>
                        <Dialog
                          openButton={
                            <div className={classes.inputWithIcon}>
                              <img src={create_group_icons.white.clock} className={classes.inputIcon}/>
                              <CustomInput
                                id={"time"}
                                className={classes.scheduleInput}
                                value={values.time}
                                disableUnderline={true}
                                onChange={()=>{}} // to refuse manual change
                              />
                            </div>
                          }
                          key={"time"}
                        >
                          <CustomTimePicker
                            classes={classes.timeWrapper}
                            value={values.time}
                            onClick={e => {
                              setFieldValue('time', e)
                            }}
                          />
                        </Dialog>

                      </div>

                    </div>}


                  </>:<>
                    <div className={classes.actionButtons}>
                      <ActionButton onClick={() => {setAction(WHOLE_GROUP)}}>Cancel whole group</ActionButton>
                      <ActionButton onClick={() => {setAction(MEETING)}}>Cancel only upcoming meeting</ActionButton>
                      <ActionButton onClick={() => {setAction(RESCHEDULE)}}>Reschedule upcoming meeting</ActionButton>
                    </div>
                  </>}
                <div className={classes.buttons}>
                  <CancelButton style={noOutlined} variant="outlined" onClick={hideCancel}>
                    Don’t Cancel
                  </CancelButton>
                  {action && <SubmitButton type={'submit'} style={noOutlined} variant="contained" disabled={!isValid}>
                    Complete
                  </SubmitButton>
                  }
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export default inject('groupsModel', 'meetingsModel')(observer(CancelGroupForm));
