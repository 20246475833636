import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '../../../res/iconComp/CloseIcon';

import PlaylistCheckbox from './PlaylistCheckbox';
import AddNewPlaylist from './AddNewPlaylist';

import { inject, observer } from 'mobx-react';

import './style.scss';

const ContentVideoPlayerPlaylist = (props) => {
  const {
    showPlaylist,
    handleClose,
    currentMediaIdViaProps = null,
    playlistModel: {
      myPlaylists,
      createPlaylist,
      updatePlaylistItems,
      currentMedia,
    },
  } = props; 

  const isInPlaylist = (playlist) => {
    if (!playlist?.items) return false;
    if(currentMediaIdViaProps && Array.isArray(currentMediaIdViaProps)){
      return playlist?.items.find(i => currentMediaIdViaProps.some(videoId => videoId === i.meeting_id))
    }else{
      return playlist?.items.find((i) => currentMediaIdViaProps ? (i.meeting_id === currentMediaIdViaProps): (i.meeting_id === currentMedia.id));
    }
  };

  //console.log("currentMedia", JSON.parse(JSON.stringify(currentMedia)))
  return showPlaylist ? (
    <div
      className="playlist_wrapper"
      onClick={(e) => {
        // do not close modal if anything inside modal content is clicked
        e.stopPropagation();
      }}
    >
      <div className="playlist">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close_button"
        >
          <CloseIcon color="#FFFFFF" />
        </IconButton>
        <span className="playlist_header">Save To</span>
        <div className="playlist_items">
          {myPlaylists.map((playlist) => {
            isInPlaylist(playlist);
            const isChecked = isInPlaylist(playlist);
            return (
              <div
                key={playlist.id}
                className="playlist_item"
                onClick={() => updatePlaylistItems(playlist, currentMediaIdViaProps)}
              >
                <PlaylistCheckbox checked={isChecked} />
                <div className="playlist_item-title">{playlist.title}</div>
              </div>
            );
          })}
        </div>

        <div className="playlist_add_new">
          <AddNewPlaylist handleSubmit={createPlaylist} />
        </div>
      </div>
    </div>
  ) : null;
};

export default inject('playlistModel')(observer(ContentVideoPlayerPlaylist));
