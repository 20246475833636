import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';

const Textarea = props => {
  const { name = "test", type = "text", className, placeholder = "test", customChange } = props;
  return (
    <Field name={name}>
      {({
          field: { name, value, onChange, onBlur },
          form: { touched, errors, isValid },
          meta,
        }) => {
          return(
            <TextField 
              error={meta.touched && !!errors[name]}
              helperText={meta.touched && errors[name]}
              placeholder={placeholder}
              value={value}
              onChange={customChange || onChange}
              onBlur={onBlur}
              name={name}
              type={type}
              className={className}
              id={name}
              key={name}
              multiline
              rows={2}
            />
          )
        }
      }
    </Field>
  );
};

export default Textarea;
