import { observable, decorate, action } from 'mobx';
import axios from 'axios';
import {baseAPI} from "../const/settings";
import {authModel} from "./auth";
import {groupsModel} from "./groups";
import {userModel} from "./user";
import {ctGeneral} from "../const/general";
import {mediaModel} from "./media";
import {HelpersControllerModel} from "./helpers";
// import { getHeaders } from "../units/func";

const currBaseUrl = 'communities'
const getHeaders = (token) => ({
  "accept": "application/json, text/plain, */*",
  "session-token": token || authModel.session_token
})
export class CommunityModel {
  constructor() {
    this.communityList = {
      communities:[],
      count: 0,
      mappedCommunities: {},
      general: [],
      selected: []
    }
    this.userCommunityList = {
      communities:[],
      count: 0,
      mappedCommunities: {},
      general: [],
      userId: null
    }
    this.selectCommunityData = {}
    this.communityMembersList = {
      users: [],
      count: 0
    }
    this.communityModeratorsList = {
      users: [],
      count: 0
    }
    this.myOrganizationID = null
    this.myOwnCommunity = {}
    this.userOrganization = {
      communities:[],
      count: 0
    }
    this.filterSelectedCommunity = [];
    this.distribution_groups = [];
    this.distribution_group_data = {};
    this.communityCategoriesList = [];
  }

  setCommunityList = data => {
    if (!data) return false;

    const mappedCommunities = {}
    data.communities.forEach( community => mappedCommunities[community.id] = community)
    this.communityList = {
      communities: data.communities.sort( (p, n) => (p.id - n.id)),
      count: data.count,
      mappedCommunities: mappedCommunities,
      general: data.communities.filter( c => c.community_type === ctGeneral).sort( (p, n) => (p.id - n.id)),
      selected: data.communities.filter( c => c.selected).map(c => c.id)
    }
    // groupsModel.state.filters.communities = this.communityList.selected
  };

  setCommunityCategoriesList = data => {
    this.communityCategoriesList = [...data.categories];
  }

  setDistributionGroups = data => {
    if (!data) return false;
    this.distribution_groups = {
      distribution_groups: data.distribution_groups.sort( (p, n) => (p.id - n.id)),
      count: data.count
    }
    // groupsModel.state.filters.communities = this.communityList.selected
  };

  setMyOwnCommunity = data => {
    if (!data || !data.count) return false;
    this.myOwnCommunity = data.communities[0]
    this.myOrganizationID = data.communities[0].id
  }

  clearMyOwnCommunity = () =>{
    this.myOwnCommunity = {}
    this.myOrganizationID = null
  }

  getCommunityNameById = id => this.communityList.mappedCommunities[id]?.title;
  getCommunityDataById = id => this.communityList.mappedCommunities[id];
  getCommunitiesTitlesString = (item) => {
    let communityObjects = []
    let communitiesId = []
    if(item?.communities){

      item.communities.map(comm => !communitiesId.includes(comm.id) && communitiesId.push(comm.id) && communityObjects.push(comm))
    }
   
    item?.distribution_group && communityObjects.push(item?.distribution_group);
    return communityObjects.reduce((total, next) => {
      return `${total} ${next.title || ''}`
    },'') 
  }


  setUserCommunityList  = (data, id) => {
    if (!data) return false;

    const mappedCommunities = {}
    data.communities.forEach( community => mappedCommunities[community.id] = community)
    this.userCommunityList = {
      communities: data.communities,
      count: data.count,
      mappedCommunities: mappedCommunities,
      general: data.communities.filter( c => c.community_type === ctGeneral).sort( (p, n) => (p.id - n.id)),
      userId: id
    }
  };

  setSelectedCommunityDetails = data => this.selectCommunityData = data;
  setDistributionGroupDetails = data => this.distribution_group_data = data;


  setCommunityMembersList = data => this.communityMembersList = data;
  setCommunityModeratorsList = data => this.communityModeratorsList = data;

  setUserOrganization = data => this.userOrganization = data;
  clearUserOrganization = () => this.userOrganization = {
    communities:[],
    count: 0
  }

  memorizeFilterSelectedCommunity = communityArr => this.filterSelectedCommunity = communityArr
  clearMemorizedFilters = _ => this.filterSelectedCommunity = []

  /* requests */

  getMyCommunityList = ( props = {} ) => {
    const token = props.token || authModel.session_token;
    const user = props.user || userModel.user
    const params = {
      per_page: 100
    }
    const setCommunityList = this.setCommunityList
    axios.get(`${baseAPI}${currBaseUrl}`, {
      headers: getHeaders(token),
      params
    }).then(function (response) {
      setCommunityList(response.data)
      groupsModel.getGroupsData()
      mediaModel.getMediaGroups()
      groupsModel.getRecordsGroups()
      !groupsModel.state.filters.communities.length && user.community_id && groupsModel.changeUserCommunity(user.community_id);
      HelpersControllerModel.callRedirect(user, communityModel.communityList)
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  getCommunityCategoriesList = () => {
    const token = authModel.session_token;
    const setCommunityCategoriesList = this.setCommunityCategoriesList;

    axios.get(`${baseAPI}/community_categories`, {
      headers: getHeaders(token)
    }).then(function (response) {
      setCommunityCategoriesList(response.data);
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  getMyDistributionGroupsList = ( props = {} ) => {
    const token = props.token || authModel.session_token;
    const user = props.user || userModel.user
    const params = {
      per_page: 100
    }
    const setDistributionGroups = this.setDistributionGroups;
    axios.get(`${baseAPI}/distribution_groups`, {
      headers: getHeaders(token),
      params
    }).then(function (response) {
      setDistributionGroups(response.data);
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  getMyOwnCommunity = ( props = {} ) => {
    const token = props.token || authModel.session_token;
    const setMyOwnCommunity = this.setMyOwnCommunity
    const clearMyOwnCommunity = this.clearMyOwnCommunity

    this.clearMemorizedFilters()
    axios.get(`${baseAPI}${currBaseUrl}`, {
      headers: getHeaders(token),
      params: {
        owner : true
      }
    }).then(function (response) {
      setMyOwnCommunity(response.data)
    }).catch(function (error) {
      clearMyOwnCommunity()
      console.error('error', error);
    })

  }

  getUserCommunityList = (props = {} ) => {
    const {user_id} = props;
    const params = {user_id}
    const setUserCommunityList = this.setUserCommunityList
    const setUserOrganization = this.setUserOrganization
    const clearUserOrganization = this.clearUserOrganization

    axios.get(`${baseAPI}${currBaseUrl}`, {
      headers: getHeaders(),
      params
    }).then(function (response) {
      console.log('response', response)
      setUserCommunityList(response.data, user_id)
    }).catch(function (error) {
      console.error('error', error);
    })

    axios.get(`${baseAPI}${currBaseUrl}`, {
      headers: getHeaders(),
      params: {
        ...params,
        owner: true
      }
    }).then(function (response) {
      console.log('response owner', response)
      setUserOrganization(response.data)
    }).catch(function (error) {
      console.error('error', error);
      clearUserOrganization()
    })

  }

  getCommunityDetails = ( props = {}) => {
    const {id} = props
    if (!id) {
      return console.error( 'Need community id')
    }
    const setSelectedCommunityDetails = this.setSelectedCommunityDetails

    axios.get(`${baseAPI}${currBaseUrl}/${id}`, {
      headers: getHeaders(),
    }).then(function (response) {
      console.log('getCommunityDetails response', response)
      setSelectedCommunityDetails(response.data)
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  getDistributionGroupDetails = ( props = {}) => {
    const {id} = props
    if (!id) {
      return console.error( 'Need distribution group id')
    }
    const setDistributionGroupDetails = this.setDistributionGroupDetails

    axios.get(`${baseAPI}/distribution_groups/${id}`, {
      headers: getHeaders(),
    }).then(function (response) {
      console.log('getDistributionGroupDetails response', response)
      setDistributionGroupDetails(response.data)
    }).catch(function (error) {
      console.error('error', error);
    })
  }


  inviteUsersToCommunity = (props = {}) => {
    const {id, users_ids} = props

    if (!users_ids || !users_ids.length) {
      return console.error(' users_ids can not be empty and must be array')
    }
    const getModeratorsList = this.getModeratorsList
    const getMembersList = this.getMembersList
    axios.post(`${baseAPI}${currBaseUrl}/${id}/invite`, {users_ids},{
      headers: getHeaders()
    }).then(function (response) {
      console.log('inviteUserToCommunity response', response)
      getModeratorsList({id})
      getMembersList({id})
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  removeModeratorFromCommunity = (props = {}) => {
    const {id, users_ids} = props

    if (!users_ids || !users_ids.length) {
      return console.error(' users_ids can not be empty and must be array')
    }
    const getModeratorsList = this.getModeratorsList

    axios.delete(`${baseAPI}${currBaseUrl}/${id}/remove`, {
      headers: getHeaders(),
      params: {users_ids}
    }).then(function (response) {
      console.log('removeModeratorFromCommunity response', response)
      getModeratorsList({id})
    }).catch(function (error) {
      console.error('error', error);
    })
  }
  removeMemberFromCommunity = (props = {}) => {
    const {id, users_ids} = props

    if (!users_ids || !users_ids.length) {
      return console.error(' users_ids can not be empty and must be array')
    }
    const getMembersList = this.getMembersList;
    axios.delete(`${baseAPI}${currBaseUrl}/${id}/remove_members`, {
      headers: getHeaders(),
      params: {users_ids}
    }).then(function (response) {
      console.log('removeMemberFromCommunity response', response)
      getMembersList({id})
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  setCommunityAsCurrent = (props = {}) => {
    const {id} = props

    if (!id) {
      return console.error('community id can not be empty')
    }
    const getMyCommunityList = this.getMyCommunityList

    axios.post(`${baseAPI}${currBaseUrl}/${id}/current`,{}, {
      headers: getHeaders()
    }).then(function (response) {
      console.log('setCommunityAsCurrent response', response)
      userModel.getCurentUser(authModel.session_token)
      getMyCommunityList()
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  selectCommunity = (ids) => {

    if (!ids?.length) {
      return console.error('community id can not be empty')
    }
    const getMyCommunityList = this.getMyCommunityList
    let formdata = new FormData();
    for (let i = 0; i < ids.length; i++) {
      formdata.append(`communities[]`, ids[i]);
    }
    axios.post(`${baseAPI}${currBaseUrl}/select`, formdata, {
      headers: getHeaders()
    })
      .then(function (response) {
      console.log('selectCommunity response', response)
      getMyCommunityList()
    }).catch(function (error) {
      console.error('error', error);
    })
  }


  unselectCommunity = (ids) => {

    if (!ids?.length) {
      return console.error('community id can not be empty')
    }
    const getMyCommunityList = this.getMyCommunityList
    let communities = new FormData();
    for (let i = 0; i < ids.length; i++) {
      communities.append(`communities[]`, ids[i]);
    }
    console.log("ids", ids[0]);
    axios.delete(`${baseAPI}${currBaseUrl}/unselect`,{
      headers: getHeaders(authModel.session_token),
      params : {communities: ids}
    })
    .then(function (response) {
      console.log('unselectCommunity response', response)
      getMyCommunityList()
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  

  getMembersList = (props = {}) => {
    const {id, page, per_page = 100000, sort_column, sort_type, full_name} = props

    if (!id) { return console.error('community id can not be empty')}

    const params = { page, per_page, sort_column, sort_type, full_name }

    const setCommunityMembersList = this.setCommunityMembersList;

    axios.get(`${baseAPI}${currBaseUrl}/${id}/members`, {
      headers: getHeaders(),
      params
    }).then(function (response) {
      console.log('getMembersList response', response)
      setCommunityMembersList(response.data)
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  getModeratorsList = (props = {}) => {
    const {id, page, per_page = 100000, sort_column, sort_type, full_name} = props

    if (!id) { return console.error('community id can not be empty')}

    const params = { page, per_page, sort_column, sort_type, full_name }

    const setCommunityModeratorsList = this.setCommunityModeratorsList;

    axios.get(`${baseAPI}${currBaseUrl}/${id}/moderators`, {
      headers: getHeaders(),
      params
    }).then(function (response) {
      setCommunityModeratorsList(response.data)
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  sendRequestToJoinToCommunity = (props = {}) => {
    const {id} = props;
    if (!id) { return console.error('community id can not be empty')}

    const getMyCommunityList = this.getMyCommunityList
    axios.post(`${baseAPI}${currBaseUrl}/${id}/request`, {},{
      headers: getHeaders()
    }).then(function (response) {
      console.log('sendRequestToJoinToCommunity response', response)
      getMyCommunityList()
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  acceptRequestToJoinToCommunity = ( props = {}) => {
    const {id, user_id } = props;
    if (!id) { return console.error('community id can not be empty')}

    const params = { user_id }
    const getMembersList = this.getMembersList

    axios.post(`${baseAPI}${currBaseUrl}/${id}/accept`, params,{
      headers: getHeaders(),
    }).then(function (response) {
      console.log('acceptRequestToJoinToCommunity response', response)
      getMembersList({id})
    }).catch(function (error) {
      console.error('error', error);
    })
  }

  declineRequestToJoinToCommunity = ( props = {}) => {
    const {id, user_id } = props;
    if (!id) { return console.error('community id can not be empty')}

    const params = { user_id }
    const getMembersList = this.getMembersList

    axios.delete(`${baseAPI}${currBaseUrl}/${id}/decline`, {
      headers: getHeaders(),
      params
    }).then(function (response) {
      console.log('acceptRequestToJoinToCommunity response', response)
      getMembersList({id})
    }).catch(function (error) {
      console.error('error', error);
    })
  }

}

decorate(CommunityModel, {
  getMyCommunityList: action,
  getMyDistributionGroupsList: action,
  getUserCommunityList: action,
  getCommunityNameById: action,
  getCommunityDataById: action,
  getCommunityDetails: action,
  getDistributionGroupDetails: action,
  inviteUsersToCommunity: action,
  removeModeratorFromCommunity: action,
  removeMemberFromCommunity: action,
  setCommunityAsCurrent: action,
  selectCommunity: action,
  unselectCommunity: action,
  getMembersList: action,
  getModeratorsList: action,
  sendRequestToJoinToCommunity: action,
  acceptRequestToJoinToCommunity: action,
  declineRequestToJoinToCommunity: action,
  clearUserOrganization: action,
  memorizeFilterSelectedCommunity: action,
  getCommunitiesTitlesString: action,
  communityList: observable,
  userCommunityList: observable,
  communityMembersList: observable,
  communityModeratorsList: observable,
  myOrganizationID: observable,
  myOwnCommunity: observable,
  userOrganization: observable,
  distribution_group_data: observable,
  distribution_groups: observable
})

export const communityModel = new CommunityModel();
