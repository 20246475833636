import React, { memo } from 'react';

const CheckedIcon = props => {
  const { color = '#43425D', innerColor = "white", width = 24, height = 24} = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill={color}/>
    <path d="M7.2002 12.0001L10.4002 15.3601L16.8002 8.64014" stroke={innerColor} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default memo(CheckedIcon);
