export const communityList = [{
        id: "recovery",
        name: 'Recovery',
    },
    {
        id: "family",
        name: 'Family',
    },
    {
        id: "spiritual",
        name: 'Spiritual',
    },
    {
        id: "lifestyle",
        name: 'Lifestyle',
    },
    {
        id: "industry",
        name: 'Industry',
    },
    {
        id: "success",
        name: 'Success',
    },
    {
        id: "transpersonal",
        name: 'Transpersonal',
    }
];

export const communityTypesLabels = {
  general: 'Public',
  organization: 'Organizations',
  influencer: "Creators",
  distributionGroups: "Distributed Groups"
}


export const communitiesStr = 'recovery,family,spiritual,lifestyle,industry,success,transpersonal';