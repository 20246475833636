import React, { memo } from 'react';

const CheckedEmptyIcon = props => {
  // const { color = '#43425D' } = props;

  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="14" height="14" rx="1.5" stroke="#E9E9F0"/>
    </svg>
  )
}

export default memo(CheckedEmptyIcon);
