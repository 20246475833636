import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Field } from 'formik';

const Input = props => {
  const {
    name, type = "text", className, placeholder, isShrink, isTextArea,
    tag, customValue, isDisabled, rows = 4, rowsMax = 4, label, ...rest
  } = props;

  return (
    <Field name={name}>
      {({field: { name, value, onChange, onBlur },
          form: { touched, errors, isValid },
          meta,
        }) => {
          return(
            <TextField
              name={name}
              disabledArrows
              disabled={isDisabled}
              error={meta.touched && !!errors[name]}
              helperText={meta.touched && errors[name]}
              label={placeholder || label}
              multiline={isTextArea}
              value={value}
              rows={isTextArea && rows}
              rowsMax={isTextArea && rowsMax}
              onChange={onChange}
              onBlur={onBlur}
              InputProps={{
                disableUnderline: true,
                endAdornment: <InputAdornment className='customInputTag' position="start">{tag}</InputAdornment>
              }}
              InputLabelProps={{
                shrink: isShrink,
              }}
              type={type}
              className={className}
              id={name}
              key={name}
              {...rest}
            />
          )
        }
      }
    </Field>
  );
};

export default Input;
