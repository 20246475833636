import React from 'react';
import { inject, observer } from 'mobx-react';
import ImgBuilder from '../../res/builders/ImgBuilder';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from "react-router-dom";
import { createVideoContent, createMediaContent, createAudioContent } from '../../const/urlKeys';
import StripeVerification from '../../pages/StripeVerification';
import Message from '../../pages/StripeVerification/message';
import Dialog from '../../components/Dialog';
import ContentUploadingModalContent from '../ContentUploadingModalContent';
import { video_record, meditation, podcast } from '../../const/general';

import "./style.scss"

const AddVideoContent = props => { 
  const history = useHistory();
  const { 
    userModel: { user },
    record_type,
    fileUploadingModel: {filesToUpload, isUploading},
    communityModel: { communityList }
  } = props;
  
  const canCreateContent = user?.payouts_enabled && communityList.communities.some( c => c.moderator);
  const stripeVerificationPending = user?.stripe_required_fields_filled && !user?.payouts_enabled && user?.stripe_fields_needed.length === 0;

  const dialogContent = () => {
    switch(true){
      case stripeVerificationPending: return <Message />;
      default: return <StripeVerification />
    }   
  }
  const createLink = () => {
    switch (record_type) {
      case video_record:
        history.push(createVideoContent);
        break;

      case meditation:
        history.push(createMediaContent);
        break;

      case podcast:
        history.push(createAudioContent);
        break;
    
      default:
        break;
    }
  }

  const buttonTitle = () => {
    switch (record_type) {
      case video_record:
        return 'Add Video';

      case meditation:
        return 'Add Meditation';

      case podcast:
        return 'Add Podcast';
    
      default:
        return 'Add Content';
    }
  }


  const content = (isRedirect) => {
    return (
      <div onClick = {() => isRedirect ? createLink() : {}}> {/* here was className="open_upload_video" */}
          {/*<img src={ImgBuilder.addVideoIcon} alt="addVideoIcon"/>*/}
          <div className="open_uploda_video_text">{buttonTitle()}</div>
      </div>
  )}

  const contentIsUploading= () => {
    return (
      <div className="open_upload_video">
          <CircularProgress />
          <div className="open_uploda_video_text">Content is uploading. Click to show more.</div>
      </div>
    )}

    if(isUploading){
      return (
        <div className="upload_video_modal">
          <Dialog 
          openButton={contentIsUploading()}
          closeColor="#43425D"
          closeIcon = {false}
          defaultOpen = {isUploading}
          >
            <ContentUploadingModalContent />
          </Dialog>
        </div>
      )
    }

  return ( 
    <> 
    {!(user && user?.stripe_required_fields_filled && user?.payouts_enabled) || !canCreateContent ? 
      <div className="withdrawnBlock">
        <Dialog 
        openButton={content(false)}
        className="withdrawDialog"
        >
          {dialogContent()}
        </Dialog>
      </div> :  content(true)
      }
    </>
  );
};

export default inject('userModel', 'fileUploadingModel', 'communityModel')(observer(AddVideoContent));
