import {decorate, observable, action} from 'mobx';
import axios from 'axios';
import uniq from 'lodash/uniq'

import {baseAPI} from '../const/settings';
import {authModel} from './auth';
import {findIndexInArrOfObj} from "../units/func";
import {errorMessage, successMessage} from "../const/general";
import { communityModel } from './community';

const currBaseUrl = 'subscription_packages'

const getHeaders = () => ({
  "accept": "application/json, text/plain, */*",
  "session-token": authModel.session_token
})
export class SubscribePackagesModel {
  constructor() {
    this.subscriptionPackagesList = {
      subscription_packages: [],
      count: 0
    }
    this.subscribedPackagesList = {
      subscription_packages: [],
      count: 0
    }
    this.singleSubscriptionPackageDetails = {}
    this.packageCreateState = null;
    this.requestResultMessage = '';
    this.subscribedMMIdsArr = []
  }

  /* actions */

  startCreatePackage = () => {
    this.packageCreateState = 'start'
  }
  endCreatePackage = () => {
    this.packageCreateState = 'success'
  }
  clearCreatePackageState = () => {
    this.packageCreateState = null;
  }

  setRequestResultMessage = (message) => {
    const setRequestResultMessage =this.setRequestResultMessage
    this.requestResultMessage = message
    if (message){
      setTimeout(setRequestResultMessage, 2000)
    }
  }

  setSubscriptionsPackagesList = (data) => {
    if (!data) return false;
    this.subscriptionPackagesList = {
      subscription_packages: data.subscription_packages,
      count: data.count
    }
  }

  setSubscribedPackagesList = (data) => {
    if (!data) return false;
    this.subscribedPackagesList = {
      subscription_packages: data.subscription_packages,
      count: data.count
    }
  }

  clearSubscriptionsPackagesList = () => {
    this.subscriptionPackagesList = {
      subscription_packages: [],
      count: 0
    }
  }

  setSingleSubscriptionPackageDetails = (data) => {
    if (!data) return false;
    this.singleSubscriptionPackageDetails = data;
    this.updateSubscriptionsPackageInList(data)
  }

  updateSubscriptionsPackageInList = (data) => {
    if (!data) return false;
    let index = findIndexInArrOfObj(this.subscriptionPackagesList.subscription_packages, data.id)
    if (!~index) return false;
    Object.assign(this.subscriptionPackagesList.subscription_packages, {[index]: data})
    this.subscriptionPackagesList = Object.assign({}, this.subscriptionPackagesList,)
  }

  saveSubscribedMMIds = (data) => {
    let arr = []
    if (!data) return false;
    data.subscription_packages.forEach((pack) => {
      arr.push(pack?.owner?.id)
    })

    this.subscribedMMIdsArr = uniq(arr)
  }


  /* requests */

  getSubscriptionPackagesList = ({user_id = null} = {}) => {
    const setSubscriptionsPackagesList = this.setSubscriptionsPackagesList;
    const clearSubscriptionsPackagesList = this.clearSubscriptionsPackagesList;

    const params = {
      user_id
    }

    axios.get(`${baseAPI}${currBaseUrl}`, {
      params,
      headers:getHeaders()
    }).then(function (response) {
      console.log('response', response)
      setSubscriptionsPackagesList(response.data)
    }).catch(function (error) {
      console.error('error', error);
      clearSubscriptionsPackagesList()
    })

  }

  getSubscribedPackagesList = ({user_id = null} = {}) => {
    const setSubscribedPackagesList = this.setSubscribedPackagesList;
    const saveSubscribedMMIds = this.saveSubscribedMMIds;
    const params = {
      user_id
    }

    axios.get(`${baseAPI}${currBaseUrl}/subscribed`, {
      params,
      headers:getHeaders()
    }).then(function (response) {
      !user_id && saveSubscribedMMIds(response.data)
      setSubscribedPackagesList(response.data)
    }).catch(function (error) {
      console.error('getSubscribedPackagesList error', error);
    })
  }

  getSubscriptionPackageDetails = ({id}) => {
    const setSingleSubscriptionPackageDetails = this.setSingleSubscriptionPackageDetails;
    if (typeof id === 'undefined') {
      console.error('need id')
      return false
    }

    axios.get(`${baseAPI}${currBaseUrl}/${id}`, {
      headers: getHeaders()
    }).then(function (response) {
      console.log('subscription_packages/${id} response', response)
      setSingleSubscriptionPackageDetails(response.data)
    }).catch(function (error) {
      console.log('subscription_packages/${id} error', error);
    })
  }

  buildForm = data =>{
    let form = new FormData();
    Object.keys(data).map(key =>{
      if(key === "communities"){
        const arr = data[key];
        //let distGroups = communityModel.distribution_groups.distribution_groups.filter(grp => grp.id === parseInt(data["distribution_group_id"]))
        //distGroups.map(grp => grp.communities.map(comm => !arr.includes(comm.id + "") && arr.push(comm.id + "")))
        for (let i = 0; i < arr.length; i++) {
          form.append(`${key}[]`, arr[i]);
        }
      }else if(key === "tiers"){
        const arr = data[key];
        for (let i = 0; i < arr.length; i++) {
          form.append(`${key}[]`, arr[i]);
        }
      }else{
        form.append(key, data[key])
      }
    })
    
    return form
  }

  createSubscriptionPackage = ({name, description, price, communities, tiers, distribution_group_id}) => {
    const setSingleSubscriptionPackageDetails = this.setSingleSubscriptionPackageDetails;
    const getSubscriptionPackagesList = this.getSubscriptionPackagesList;
    const endCreatePackage = this.endCreatePackage;
    const clearCreatePackageState = this.clearCreatePackageState;
    const setRequestResultMessage = this.setRequestResultMessage;
    this.startCreatePackage()
    const params = {
      name,
      description,
      price,
      communities,
      tiers,
      distribution_group_id
    }
    const editedCommunity = this.buildForm(params);

    axios.post(`${baseAPI}${currBaseUrl}`, editedCommunity, {headers: getHeaders()})
      .then(function (response) {
        console.log('createSubscriptionPackage response', response);
        getSubscriptionPackagesList()
        setSingleSubscriptionPackageDetails(response.data)
        endCreatePackage()
      }).catch(function (error) {
      setRequestResultMessage(error?.response?.data?.errors[0] || "Error")
      clearCreatePackageState()
    })
  }

  updateSubscriptionPackage = ({name, description, price, communities, tiers,distribution_group_id, id}) => {
    const setSingleSubscriptionPackageDetails = this.setSingleSubscriptionPackageDetails;
    const endCreatePackage = this.endCreatePackage;
    const clearCreatePackageState = this.clearCreatePackageState;
    this.startCreatePackage()

    const params = {
      name,
      description,
      price,
      communities,
      distribution_group_id,
      tiers
    }

    const editedCommunity = this.buildForm(params);

    axios.put(`${baseAPI}${currBaseUrl}/${id}`, editedCommunity, {headers: getHeaders()})
      .then(function (response) {
        console.log('updateSubscriptionPackage response', response);
        let data = response.data
        setSingleSubscriptionPackageDetails(data)
        endCreatePackage()
      }).catch(function (error) {
      console.log('updateSubscriptionPackage error', error)
      clearCreatePackageState()
    })
  }

  buySubscriptionPackage = ({id, payment_source_id, payment_source_type, user_id}) => {
    const setRequestResultMessage = this.setRequestResultMessage;
    const getSubscriptionPackagesList = this.getSubscriptionPackagesList;
    const getSubscribedPackagesList = this.getSubscribedPackagesList;
    const params = {
      payment_source_id,
      payment_source_type
    }
    axios.post(`${baseAPI}${currBaseUrl}/${id}/buy`, params, {headers: getHeaders()})
      .then(function (response) {
        getSubscriptionPackagesList({user_id})
        getSubscribedPackagesList()
        setRequestResultMessage(successMessage)
      })
      .catch(function (error) {
        setRequestResultMessage(error.response.data ? error.response.data.errors[0] : 'Error')
      })
  }

  activateSubscriptionPackage = ({id}) => {
    const getSubscriptionPackageDetails = this.getSubscriptionPackageDetails
    axios.post(`${baseAPI}${currBaseUrl}/${id}/activate`, {}, {headers: getHeaders()})
      .then(function (response) {
        getSubscriptionPackageDetails({id})
        console.log('activateSubscriptionPackage resp', response)
      })
      .catch(function (error) {
        console.log('activateSubscriptionPackage err', error)
      })
  }

  deactivateSubscriptionPackage = ({id}) => {
    const getSubscriptionPackageDetails = this.getSubscriptionPackageDetails

    axios.delete(`${baseAPI}${currBaseUrl}/${id}/deactivate`, {headers: getHeaders()})
      .then(function (response) {
        getSubscriptionPackageDetails({id})
        console.log('deactivateSubscriptionPackage resp', response)
      })
      .catch(function (error) {
        console.log('deactivateSubscriptionPackage err', error)
      })
  }

  cancelSubscriptionPackage = ({id, user_id}) => {
    const getSubscriptionPackageDetails = this.getSubscriptionPackageDetails;
    const setRequestResultMessage = this.setRequestResultMessage;

    axios.delete(`${baseAPI}${currBaseUrl}/${user_id}/cancel`, {headers: getHeaders()})
      .then(function (response) {
        getSubscriptionPackageDetails({id})
        setRequestResultMessage(successMessage)
        console.log('cancelSubscriptionPackage resp', response)
      })
      .catch(function (error) {
        setRequestResultMessage(errorMessage)
        console.log('cancelSubscriptionPackage err', error)
      })
  }

  destroySubscriptionPackage = ({id}) => {
    const getSubscriptionPackagesList = this.getSubscriptionPackagesList
    axios.delete(`${baseAPI}${currBaseUrl}/${id}`, {headers: getHeaders()})
      .then(function (response) {
        console.log('destroySubscriptionPackage response', response);
        getSubscriptionPackagesList()
      }).catch(function (error) {
      console.log('destroySubscriptionPackage error', error);
    })
  }

}

decorate(SubscribePackagesModel, {
  subscriptionPackagesList: observable,
  subscribedPackagesList: observable,
  singleSubscriptionPackageDetails: observable,
  packageCreateState: observable,
  requestResultMessage: observable

})

export const subscribePackageModel = new SubscribePackagesModel();
