import React, { memo } from 'react';
// import Header from '../../components/Header';
// import Footer from '../../components/Footer/Footer';

// import Tablet from './Tablet/Tablet';
// import Desc from './Desc/Desc';
// import Phones from './Phones/Phones';
import Contacts from './Contacts/NewContacts';
// import Questions from './Questions/Questions';
// import Download from './Download/Download';
import NewTop from './NewTop';

import './Home.scss';

const Home = () => {

  return (
    <div className="home-wrapper">
      {/* <Header /> */}
      {/* <Tablet /> */}
      {/* <Desc />
      <Phones /> */}
      <NewTop />
      <Contacts />
      {/* <Questions /> */}
      {/* <Download /> */}
      {/* <Footer /> */}
    </div>
  )
}

export default memo(Home);
