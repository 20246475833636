import React from "react";
import CurrentAvatar from "../CurrentAvatar";
import {TRANSLATIONS} from "../../const/text";
import './styles.scss'
import AddPMIcon from "../../res/iconComp/AddPMIcon";
import {observer, inject} from "mobx-react";
import {useHistory} from "react-router";
import {user as userUrl} from '../../const/urlKeys';

const GroupOwnerFollowBtn = ({groupOwnerData, masterMindsModel, userModel}) => {
  const {followed, display_name, full_name, id, image, location} = groupOwnerData;
  const {followMasterMind} = masterMindsModel;
  const {user} = userModel;
  const actionText = followed ? TRANSLATIONS.followed : TRANSLATIONS.click_to_follow
  const isCurrentUser = user.id === id
  const history = useHistory();

  const handleFollow = () => followed? history.push({
    pathname: `${userUrl}/${id}`
  }) : followMasterMind(id)
  return (
    <div className={'groupOwnerBtn'} style={{minWidth:"110px"}} onClick={handleFollow}>
      <div className={'groupOwnerBtn_avatarWrapper'}>
        <div className={'groupOwnerBtn_avatar'}>
          <CurrentAvatar alt={full_name} src={image?.thumbnail?.url} className="avatar"/>
        </div>
        {/*{!followed && !isCurrentUser &&*/}
        {/*<div className={'groupOwnerBtn_followIcon'}><AddPMIcon width={16} height={16}/></div>}*/}
      </div>
      <div className={'groupOwnerBtn_content'}>
        <div className={'groupOwnerBtn_name'}>{display_name || full_name}</div>
        <div className={'groupOwnerBtn_location'}>{location}</div>
        {!isCurrentUser && <div className={'groupOwnerBtn_action'}>{actionText}</div>}
      </div>
    </div>
  )

}

export default inject('masterMindsModel', 'userModel')(observer(GroupOwnerFollowBtn))