import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';

import NumberFormatCustom from './numberFormat';

const Price = props => {
  const { name, type = "text", className, inActivePrice, placeholder, ...rest } = props;
  
  return (
    <Field name={name}>
      {({
          field: { name, value, onChange, onBlur },
          form: { touched, errors, isValid },
          meta,
        }) => {
          return(
            <TextField 
              error={meta.touched && !!errors[name]}
              helperText={meta.touched && errors[name]}
              label={placeholder}
              value={value && parseFloat(value).toFixed(2)}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              type={type}
              className={className}
              id={name}
              key={name}
              {...rest}
            />
          )
        }
      }
    </Field>
  );
};

export default Price;
