import React, { useState, useEffect } from "react";
import moment from "moment";
import premium_icon from "../../../res/icons/premium_icon.svg";
import "./style.scss";

const RecomendationItem = (props) => {
  const {
    className = "",
    recomendation: {
      url,
      duration,
      theme,
      owner,
      views_count,
      sharings_count,
      created_at,
      content_type,
      file_type,
      premium,
      subscribed,
      image,
      id,
      group_id,
    },
    handleClick,
    currentMediaId
  } = props;
  const isVimeo = url?.includes("vimeo");
  const isAudio = file_type === "audio";

  const getContentInfoType = () => {
    switch (content_type) {
      case "webinar":
        return "livestream record";
      case "video_record":
        return "video";
      case "podcast":
        return "podcast";
      case "meditation":
        return "meditation";
      default:
        return "unknown";
    }
  };

  const info = {
    webinar: {
      url: "livestream",
      type_label: "livestream record",
    },
    video_record: {
      url: "videos",
      type_label: "video",
    },
    podcast: {
      url: "podcasts",
      type_label: "podcast",
    },
    meditation: {
      url: "meditations",
      type_label: "meditation",
    },
  };

  const openPremiumContentDialog = () => {
    const url = `${process.env.REACT_APP_REDIRECT}/content/${info[content_type].url}/${group_id}`;
    window.open(url);
  };

  return (
    <div
      key={id}
      className={`recommendation ${className} ${currentMediaId === id && "currentMediaId"}`}
      onClick={(premium && !subscribed) ? openPremiumContentDialog : handleClick}
    >
      <div className="recommendation_preview">
        <video
          className="recommendation_preview_video"
          poster={isAudio || isVimeo ? image.url : null}
        >
          <source src={url} type="video/mp4" />
        </video>
        {premium && !subscribed && (
          <div className="premium_content">
            <img src={premium_icon} alt="premium_icon" />
            <span className="recommendation_preview_price">
              PREMIUM CONTENT
            </span>
          </div>
        )}
        <span className="recommendation_preview_duration">
          {new Date(duration * 1000).toISOString().substr(11, 8)}
        </span>
      </div>

      <div className="recommendation_info">
        <span className="recommendation_info_title">{theme}</span>
        <span className="recommendation_info_owner">{owner?.full_name}</span>
        <span className="recommendation_info_meta">
          {views_count} views • {sharings_count} shared •{" "}
          {moment(created_at).fromNow()}
        </span>
        <span className="recommendation_info_type">
          {info[content_type]?.type_label}
        </span>
      </div>
    </div>
  );
};

export default RecomendationItem;
