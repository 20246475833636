import React from 'react';

const PlayVideoCloseIcon = () => { // {disable}
  // const color = disable ? "#A8A7BC": "#43425D";
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#43425D"/>
      <path d="M15.6432 11.152L10.53 7.95625C9.86395 7.53997 9 8.01881 9 8.80425V15.1958C9 15.9812 9.86395 16.46 10.53 16.0438L15.6432 12.848C16.2699 12.4563 16.2699 11.5437 15.6432 11.152Z" fill="white"/>
      <path d="M23.3861 19.9404C23.2801 19.8303 23.1513 19.7753 22.9997 19.7753H22.818V18.6426C22.818 17.9191 22.5681 17.2978 22.0681 16.7787C21.5681 16.2596 20.9697 16 20.2727 16C19.5756 16 18.9771 16.2596 18.4772 16.7787C17.9772 17.2978 17.7272 17.919 17.7272 18.6426V19.7753H17.5454C17.394 19.7753 17.2652 19.8303 17.1591 19.9404C17.053 20.0504 17 20.1842 17 20.3416V23.7392C17 23.8965 17.053 24.0302 17.1591 24.1404C17.2652 24.2504 17.394 24.3055 17.5454 24.3055H22.9999C23.1515 24.3055 23.2802 24.2505 23.3863 24.1404C23.4923 24.0302 23.5454 23.8965 23.5454 23.7392V20.3415C23.5454 20.1842 23.4923 20.0505 23.3861 19.9404ZM21.7272 19.7753H18.8181V18.6426C18.8181 18.2258 18.9602 17.8699 19.2443 17.575C19.5284 17.2801 19.8712 17.1326 20.2727 17.1326C20.6743 17.1326 21.017 17.28 21.3011 17.575C21.5851 17.8699 21.7272 18.2258 21.7272 18.6426V19.7753Z" fill="#BFBEC7"/>
    </svg>

  )
}

export default PlayVideoCloseIcon;
