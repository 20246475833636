import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Field } from 'formik';

function TextMaskCustom(props) {
  const { inputRef, mask, placeholderChar, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onClick={e => {
        e.target.setSelectionRange(0,0);
      }}
      mask={mask}
      placeholderChar={placeholderChar}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const MaskInput = props => {
  const { name = "test", className, placeholder, nextElem, goToNext } = props;

  const inputProps = {
    mask: [/\d/],
    placeholderChar: '\u2000'
  };

  return (
    <Field name={name}>
      {(props) => {
        const {
          field: { name, value, onChange },
          form: { touched, errors, isValid, setFieldValue },
          meta,
        } = props;
        return(
          <FormControl className={className} error={meta.touched && !!errors[name]}>
            <InputLabel htmlFor="formatted-text-mask-input">{placeholder}</InputLabel>
            <Input
              value={value}
              onChange={e => {
                onChange(e);
                nextElem && goToNext(nextElem);
              }}
              name={name}
              id={name}
              inputComponent={TextMaskCustom}
              inputProps={inputProps}
              goToNext={goToNext}
              onFocus={() => setFieldValue(name, '')}
            />
          </FormControl>
        )
      }}
    </Field>
  );
};

export default MaskInput;
