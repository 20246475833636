import React from "react";
import {Redirect, Route} from "react-router";

function CustomRedirect({from, to, computedMatch, location }){

  const replaceTemplateToWithValues = (newTo) => {
    Object.entries(computedMatch.params).forEach(([key, value]) => {
      const replacedKey = `:${key}`
      const keyIndex = newTo.indexOf(replacedKey)
      if (~keyIndex){
        newTo = newTo.replace(replacedKey, value)
      }
    })
    return newTo
  }

  let newTo = replaceTemplateToWithValues(to);
  const newSearchIndex = newTo.indexOf('?')
  const purePath = newTo.slice(0, newSearchIndex)
  const newSearch = new URLSearchParams(location.search)
  const oldSearch = new URLSearchParams(newTo.slice(newSearchIndex))

  for (let [key, value] of oldSearch.entries()){
    newSearch.set(key, value)
  }

  return <Redirect to={{
    pathname: purePath,
    search: newSearch.toString(),
    state: {...location.state, action: newSearch.get('action') }
  }}/>
}

export default CustomRedirect