import React from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import KeyIcon from '../../../res/icons/key.svg';
import SecurityIcon from '../../../res/icons/security.svg';
import TitleImage from '../../../res/img/forgot.png';
import PasswordInput from '../../../components/Input/passwordInput';

import '../forgot.scss';

const SignupSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code can't be blank")
    .matches(/^[0-9]{4}$/, 'Must be exactly 4 digits'),
  password: Yup.string()
    .required("Password can't be blank")
    .matches(/^(?=(.*[a-zA-Z]){1,})(?=(.*[\d]){1,}).{6,48}$/, 'Wrong password'), // The password should contain at least 1 number and 1 letter, from 6 till 48 symbols.

  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match"),
})

const ResetForm = props => {
  const { userModel } = props;
  const { resetPassword, forgotPassword, resetData } = userModel;

  return (
    <Formik
      initialValues={{  code: '', password: '', password_confirmation: '' }}
      validationSchema={SignupSchema}
      onSubmit={(values, actions) => {
        resetPassword({ setErrors: actions.setFieldError, phone_number: resetData.phone_number, ...values});
      }}
    >
      {formikProps => {
        const { setFieldValue, isValid, values: { code, password, password_confirmation } } = formikProps;
        const disabledButton = !(isValid && code !== "" && password !== "" && password_confirmation !== "");
        return (
          <Form className="defaultForm forgotForm resetForm">
            <img src={TitleImage} alt="Title forgot password" className="titleImage" />
            <div className="title">Forgot Password</div>
            <div className="subTitle">Enter the 4-digit code sent to you at {resetData.phone_number}</div>
            <div className="line">
              <img src={SecurityIcon} alt="key icon" />
              <Input name="code" id="code" className="simpleInput" placeholder="Security code" />
            </div>
            <div className="signInBlock multyLine">
              <span>Haven't received the code yet?</span>
              <div>
                <button
                  onClick={() => forgotPassword({ setErrors: setFieldValue, phone_number: resetData.phone_number })}
                  className="resendCode"
                >
                  Resend Code
                </button>
              </div>
            </div>
            <div className="line">
              <img src={KeyIcon} alt="key icon" />
              <PasswordInput name="password" id="password" className="simpleInput" placeholder="New password" />
            </div>
            <div className="line end">
              <span />
              <PasswordInput name="password_confirmation" id="password_confirmation" className="simpleInput" placeholder="Confirm Password" />
            </div>
            <Button type="submit" label="Save New Password" disabled={disabledButton} width100 violetGradien big60 />
          </Form>
        )
      }}
    </Formik>
  )
}

export default inject('userModel')(observer(ResetForm));

