import React from 'react';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import DatePicker from './index';

import { Field } from 'formik';

const CustomDatePicker = props => {
  const { name, className = '', ...rest } = props;
  
  return (
    <Field name={name}>
      {({
          field: { name, value, onChange, onBlur },
          form: { touched, errors, isValid },
          meta,
        }) => {
          return(
            <FormControl error={meta.touched && !!errors[name]} className={className}>
              <DatePicker date={value.length === 0 ? '' : moment(value).toDate()} {...rest} className="customPickerBlock" />
              {meta.touched && !!errors[name] && <div style={{ border: '1px solid #f44336', width: '100%' }} />}
              <FormHelperText>{meta.touched && errors[name]}</FormHelperText>
            </FormControl>
          )
        }
      }
    </Field>
  );
};

export default CustomDatePicker;
