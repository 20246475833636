import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import PageNotFound from '../pages/NotFound/PageNotFound.jsx'
import SimplePage from '../pages/SimplePage';
import Register from '../pages/Register';
import Forgot from '../pages/Forgot';
import Twitter from '../pages/Twitter';
import Profile from '../pages/Profile';
import NotificationsPage from '../pages/Notifications';
import CreateGroup from '../pages/CreateGroup';
import CreateVideoContent from '../pages/CreateVIdeoContent'
import PrivatePolicy from '../pages/PrivatePolicy';
import TermsConditions from '../pages/TermsConditions';
import CookiePolicy from '../pages/CookiePolicy';
import MasterMindProfileMain from '../pages/MasterMindProfile/MasterMindProfileMain';
import LsRecords from '../pages/LsRecords';
import VideosPage from '../pages/Videos';
import PlaylistsPage from '../pages/Playlists';
import Search from '../pages/Search'
import ChooseCommunity from "../pages/ChooseCommunity";
import AllCreators from "../pages/AllCreators";
import OtherPage from "../pages/Other";
import PrivateRoute from '../components/PrivateRoute';
import { isIOS } from 'react-device-detect';
import {video_record, meditation, podcast, mySubscriptions, myOrganization} from '../const/general';

import {
  home,
  calendar,
  terms_conditions,
  privacy_policy,
  contact_support,
  register,
  forgot_password,
  twitter,
  profile,
  createVideoContent,
  videos,
  cookie_policy,
  groupByLink,
  daily,
  user,
  collaborations,
  livestreams,
  workshops,
  groups_create,
  stripeVerify,
  records,
  meditations,
  podcasts,
  createMediaContent,
  createAudioContent,
  notifications,
  search,
  masterMindProfile,
  subscribe,
  allCreators,
  channel,
  subscriptions,
  chooseCommunity,
  playlists,
  myOrganizationPage, externalChooseCommunity, otherPage, MMGetStarted
} from '../const/urlKeys';
import CustomRedirect from "../components/CustomRedirect";
import VariableRedirect from "../components/VariableRoute";
// import SubscriptionsPackages from "../pages/Profile/Components/Subscriptions/SubscriptionsPackages";


const MainRouter = (
  <Router>
    <Switch>
      <PrivateRoute exact path={home} component={routeProps => (<Dashboard {...routeProps} />)} />
      <Route exact path={subscribe} render={routeProps => (<Dashboard {...routeProps} />)} />
      <Route exact path={calendar} render={routeProps => (<Dashboard {...routeProps} />)} />
      <Route exact path={otherPage} render={routeProps => (<OtherPage {...routeProps} />)} />

      <Route exact path={terms_conditions} component={() => <SimplePage><TermsConditions /></SimplePage>}/>
      <Route exact path={privacy_policy} component={() => <SimplePage><PrivatePolicy /></SimplePage>}/>
      <Route exact path={cookie_policy} component={() => <SimplePage><CookiePolicy /></SimplePage>}/>
      <Route exact path={contact_support} component={() => <SimplePage content="contact support" />}/>
      <Route exact path={register} component={() => <SimplePage><Register /></SimplePage>}/>
      <Route exact path={forgot_password} component={() => <SimplePage><Forgot /></SimplePage>}/>
      <Route exact path={twitter} render={routeProps => <SimplePage><Twitter {...routeProps} /></SimplePage>} />

      <PrivateRoute path={allCreators} component={routeProps => (<AllCreators {...routeProps} />)} />

      <PrivateRoute path={profile} component={routeProps => (<Profile {...routeProps} />)} />
      <PrivateRoute path={subscriptions} component={routeProps => {
        const props = Object.assign({}, routeProps, {location: { state:{ openPage: mySubscriptions}}})
        return (<Profile {...props} />)
      }} />

      <PrivateRoute path={myOrganizationPage} component={routeProps => {
        const props = Object.assign({}, routeProps, {location: { state:{ openPage: myOrganization}}})
        return (<Profile {...props} />)
      }} />

      <PrivateRoute
        exact
        path={groups_create}
        component={routeProps => (<SimplePage><CreateGroup {...routeProps} /></SimplePage>)}
      />
      <PrivateRoute
        exact
        path={stripeVerify}
        component={routeProps => (<SimplePage className="darkPage"><CreateGroup {...routeProps} /></SimplePage>)}
      />

      <PrivateRoute path={`${search}/:search`} component={routeProps => <Search {...routeProps} />} />
      <PrivateRoute path={`${search}`} component={routeProps => <Search {...routeProps} />} />
      <PrivateRoute path={`${groupByLink}/:id`} component={routeProps => (<Dashboard {...routeProps} />)} />
      <PrivateRoute path={groupByLink} component={routeProps => (<Dashboard {...routeProps} />)} />

      <PrivateRoute path={daily} component={routeProps => (<Dashboard {...routeProps} />)} />
      <PrivateRoute path={livestreams} component={routeProps => (<Dashboard {...routeProps} />)} />
      <PrivateRoute path={collaborations} component={routeProps => (<Dashboard {...routeProps} />)} />
      <PrivateRoute path={workshops} component={routeProps => (<Dashboard {...routeProps} />)} />

      <PrivateRoute path={`${user}/:id`} component={routeProps => (<MasterMindProfileMain {...routeProps} />)} />
      <PrivateRoute path={user} component={routeProps => (<Dashboard {...routeProps} />)} />
      <PrivateRoute path={`${masterMindProfile}/:id`} component={routeProps => (<MasterMindProfileMain {...routeProps} />)} />

      <CustomRedirect from={`${records}/:id`} to={`${records}?id=:id`}/>
      <PrivateRoute path={`${records}/:id`} component={routeProps => (<LsRecords {...routeProps} />)} />
      <PrivateRoute path={records} component={routeProps => (<LsRecords {...routeProps} />)} />

      <CustomRedirect from={`${videos}/:id`} to={`${videos}?id=:id`}/>
      <PrivateRoute path={`${videos}/:id`} component={routeProps => (<VideosPage {...routeProps} record_type={video_record} />)} />
      <PrivateRoute path={videos} component={routeProps => (<VideosPage {...routeProps} record_type={video_record} />)} />

      <CustomRedirect from={`${meditations}/:id`} to={`${meditations}?id=:id`}/>
      <PrivateRoute path={`${meditations}/:id`} component={routeProps => (<VideosPage {...routeProps} record_type={meditation} />)} />
      <PrivateRoute path={meditations} component={routeProps => (<VideosPage {...routeProps} record_type={meditation} />)} />

      <CustomRedirect from={`${podcasts}/:id`} to={`${podcasts}?id=:id`}/>
      <PrivateRoute path={`${podcasts}/:id`} component={routeProps => (<VideosPage {...routeProps} record_type={podcast} />)} />
      <PrivateRoute path={podcasts} component={routeProps => (<VideosPage {...routeProps} record_type={podcast} />)} />
      <PrivateRoute path={notifications} component={routeProps => (<NotificationsPage {...routeProps} />)} />
      <PrivateRoute path={playlists} component={routeProps => (<PlaylistsPage {...routeProps} />)} />
      <PrivateRoute
        path={createVideoContent}
        component={routeProps => (<CreateVideoContent {...routeProps} record_type={video_record} />)}
      />
      <PrivateRoute
        path={createMediaContent}
        component={routeProps => (<CreateVideoContent {...routeProps} record_type={meditation} />)}
      />
      <PrivateRoute
        path={createAudioContent}
        component={routeProps => (<CreateVideoContent {...routeProps} record_type={podcast} />)}
      />

      <CustomRedirect from={`${externalChooseCommunity}/:id`} to={`${chooseCommunity}?invite=:id`}/>
      <CustomRedirect from={`${chooseCommunity}/:id`} to={`${chooseCommunity}?invite=:id`}/>
      <PrivateRoute
        path={chooseCommunity}
        component={ChooseCommunity}
      />
      <VariableRedirect from={`${MMGetStarted}/:id`}/>

      <Route path={`${channel}/:channelId`} component={() => {
        const path = isIOS? "https://apps.apple.com/ua/app/ascendvideo/id1496739998": "https://play.google.com/store/apps/details?id=com.doneit.ascend";
        window.location.href = path;
        return null;
      }}/>
      <Route path="*" component={PageNotFound} />
    </Switch>
  </Router>
);

export default MainRouter;
