import { observable, decorate, action } from 'mobx';
import axios from 'axios';

import { baseAPI } from '../const/settings';

export class QuestionsModel {
  constructor() {
    this.questions = null;
  }

  stateQuestions = data => this.questions = data;
  
  getQuestions = session_token => {
    const stateQuestions = data => this.stateQuestions(data);
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": session_token
      }};
    axios.get(`${baseAPI}questions`, headers)
      .then(function (response) {
        // handle success
        
        stateQuestions(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }
}

decorate(QuestionsModel, {
  questions: observable,
  getQuestions: action,
})

export const questionsModel = new QuestionsModel();
