import React, {useEffect} from 'react';

import './first.scss';
import ChooseCommunityBlock from "../ChooseCommunity/ChooseCommunityBlock";
import { inject, observer } from 'mobx-react';

const FirstLogin = (props) => {
  const {communityModel:{
    getMyCommunityList,
    getMyDistributionGroupsList
  }} = props;

  useEffect(() => {
    getMyCommunityList()
    getMyDistributionGroupsList()
  },[])

  return (
    <div className="greyBackground">
        <ChooseCommunityBlock firstLogin={true}/>
    </div>
  );
};

export default inject('communityModel')(observer(FirstLogin));
