import React, { memo } from 'react';
import ShieldIcon from '../../res/icons/shield.svg';
import Button from '../Button';

import { terms_conditions, privacy_policy, cookie_policy } from '../../const/urlKeys';

const Policy = ({ setAccept }) => (
	<div className="policy">
		<img className="logo" alt="logo" src={ShieldIcon} />
		<div className="text">
			By using our site, you acknowledge that you have read and understand our &nbsp;
			<a href={cookie_policy}>Cookie Policy</a>, &nbsp;
			<a href={privacy_policy}>Privacy Policy</a>, and our &nbsp;
			<a href={terms_conditions}>Terms of Service</a>.
		</div>
		<Button onClick={() => setAccept()} label="Accept" whiteGradient/>
	</div>
);

export default memo(Policy);
