import React from 'react';

const PlayVideoIcon = ({disable, ...rest}) => {
  const {width = 24,
    height = 24} = rest
  const color = disable ? "#A8A7BC": "#43425D";
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill = "none"  xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill={color}/>
      <path d="M15.6432 11.152L10.53 7.95625C9.86395 7.53997 9 8.01881 9 8.80425V15.1958C9 15.9812 9.86395 16.46 10.53 16.0438L15.6432 12.848C16.2699 12.4563 16.2699 11.5437 15.6432 11.152Z" fill="white"/>
    </svg>
  )
}

export default PlayVideoIcon;
