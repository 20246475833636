import React, { memo } from 'react';

const CalendarTimeIcon = props => {
  // const { color = 'white' } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4 1.59151H13.6V0.795756C13.6 0.318302 13.28 0 12.8 0C12.32 0 12 0.318302 12 0.795756V1.59151H4V0.795756C4 0.318302 3.68 0 3.2 0C2.72 0 2.4 0.318302 2.4 0.795756V1.59151H0.8C0.4 1.59151 0 1.90981 0 2.38727V13.5279C0 14.0053 0.4 14.3236 0.8 14.3236H5.68C5.12 13.3687 4.8 12.2546 4.8 11.1406C4.8 7.63926 7.68 4.77454 11.2 4.77454C12.72 4.77454 14.08 5.33156 15.2 6.2069V2.38727C15.2 1.98939 14.8 1.59151 14.4 1.59151Z" fill="#7E7D8F"/>
      <path d="M11.1999 6.36597C8.5599 6.36597 6.3999 8.51451 6.3999 11.1405C6.3999 13.7665 8.5599 15.9151 11.1999 15.9151C13.8399 15.9151 15.9999 13.7665 15.9999 11.1405C15.9999 8.51451 13.8399 6.36597 11.1999 6.36597ZM12.7999 11.9363H11.1999C10.7199 11.9363 10.3999 11.618 10.3999 11.1405V8.75324C10.3999 8.27578 10.7199 7.95748 11.1999 7.95748C11.6799 7.95748 11.9999 8.27578 11.9999 8.75324V10.3448H12.7999C13.2799 10.3448 13.5999 10.6631 13.5999 11.1405C13.5999 11.618 13.2799 11.9363 12.7999 11.9363Z" fill="#7E7D8F"/>
    </svg>
  )
}

export default memo(CalendarTimeIcon);
