import React from 'react';
import moment from 'moment';
// import styled from '@react-pdf/styled-components';
import { Page, Text, View, Document, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';
import Logo from '../../../res/img/logo.png';
import IOS from '../../../res/img/App_Store_btn.png';
import Android from '../../../res/img/GooglePlay_btn.png';

import {returnName} from '../../../units/func';
import {supportModel} from "../../../models/support";

Font.register({
  family: 'Roboto',
  src: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'
});
// Font.register({ family: 'Red Hat Display', src: "https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap" });
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: '55px 0 0 48px',
  },
  divider: {
    border: '1px solid #E0E4E9',
    width: '100%',
    height: 1,
    backgroundColor: '#E0E4E9',
    margin: '10px 0',
  },
  table: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  fieldTitle: {
    // fontFamily: 'Red Hat Display',
    fontSize: 11,
    color: '#757575',
    width: 35,
    height: 15,
  },
  fieldData: {
    width: 500,
    color: '#333333',
    fontSize: 11,
    height: 15,
  },
  logoBlock: {
    display: 'flax',
    justifyContent: 'center',
    margin: '15px 150px',
  },
  logo: {
    width: 195,
    height: 50,
  },
  data: {
    // fontFamily: 'Roboto',
    fontSize: 16,
    color: '#A5A5A5',
    margin: '15px 50px',
  },
  amountTable: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: 48,
    marginLeft: -48,
  },
  firstTitle: {
    width: 315,
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333333',
    marginLeft: 60,
    alignSelf: 'flex-end',
    marginRight: 'auto',
  },
  secondTitle: {
    width: 43,
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333333',
    marginLeft: 5,
    textAlign: 'right',
  },
  therdTitle: {
    width: 43,
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333333',
    marginLeft: 30,
    textAlign: 'right',
  },
  firstText: {
    width: 315,
    // fontFamily: 'Roboto',
    fontSize: 14,
    color: '#757575',
    marginLeft: 60,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
  },
  secondText: {
    width: 43,
    // fontFamily: 'Roboto',
    fontSize: 14,
    color: '#757575',
    textAlign: 'right',
  },
  therdText: {
    width: 43,
    // fontFamily: 'Roboto',
    fontSize: 14,
    color: '#757575',
    marginLeft: 30,
    textAlign: 'right',
  },
  taxCredit: {
    width: 150,
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: 33,
    marginBottom: 32,
  },
  taxTitle: {
    width: 40,
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    color: '#333333',
    textAlign: 'right',
    marginRight: 15,
  },
  creditTitle: {
    width: 100,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    color: '#333333',
    marginLeft: 15,
    textAlign: 'right',
  },
  taxData: {
    width: 40,
    textAlign: 'right',
    // fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '16px',
    color: '#757575',
    marginRight: 15,
  },
  creditData: {
    width: 60,
    marginLeft: 15,
    paddingBottom: 15,
    textAlign: 'right',
    // fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '16px',
    color: '#757575',
  },
  totalTitle: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    color: '#333333',
    textAlign: 'right',
  },
  totalData: {
    width: '100%',
    // fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '16px',
    color: '#757575',
    textAlign: 'right',
  },
  textDetails: {
    // fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 12,
    color: '#333333',
  },
  middleText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  details: {
    display: 'flex',
    marginBottom: 18,
    fontSize: 13,
    lineHeight: '15px',
    color: '#333333',
    flexDirection: 'row',
    marginRight: 35,
  },
  detailsFrom: {
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    width: 240,
    marginLeft: 40,
  },
  contact: {
    width: 200,
  },
  support: {
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '14px',
    color: '#333333',
    marginTop: 18,
    marginBottom: 8,
  },
  address: {
    // fontFamily: 'Roboto',
    fontSize: 12,
    color: '#757575',
    marginTop: 8,
    marginBottom: 53,
  },
  linkBlock: {
    marginBottom: 25,
    marginTop: 53,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row'
  },
  link: {
    margin: 4,
  },
  image: {
    width: 121,
    height: 36,
  },
  difFonts: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
  coursesBtn: {
    backgroundColor: '#EA5356',
    borderRadius: 2,
    width: 96,
    height: 27,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
  },
  courser: {
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '14px',
    color: '#FFFFFF',
    // color: '#FFFFFF',
  }
});

const MyDoc = props => {
  const { item } = props;
  const price = item.amount ? item.amount : item.group?.price;
  const stripeFee = item.amount ? supportModel.getStripeFeeForGroup(price) : 0;
  const userName = returnName(item.user);
  const AscendFee = supportModel.commissions.group_fee

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Order Confirmation for {moment(item.start_time).format('MMMM DD, YYYY')}</Text>
          {/*April 2, 2018*/}
          <View style={styles.divider} />
          <View style={styles.table}>
            <Text style={styles.fieldTitle}>From:</Text>
            <Text style={styles.fieldData}>Ascend (customersuccess@ascend2u.com)</Text>
            <Text style={styles.fieldTitle}>To:</Text>
            <Text style={styles.fieldData}>{`${userName} (${item.user?.email})`}</Text>
            <Text style={styles.fieldTitle}>Date:</Text>
            <Text style={styles.fieldData}>{moment().format('dddd, MMMM DD, YYYY, hh:mma ')}</Text>
            {/*Monday, Aprel 2, 2019, 09:37PM*/}
          </View>
          <View style={styles.divider} />
          <View style={styles.logoBlock}>
            <Image object-fit="fill" src={Logo} alt="images" style={styles.logo} />
          </View>
          <Text style={styles.data}>Your Order Confirmation from {moment(item.start_time).format('MMMM DD, YYYY')}</Text>
          <View style={styles.amountTable}>
            <Text style={styles.firstTitle}>DESCRIPTION</Text>
            <Text style={styles.secondTitle}>PRICE</Text>
            {/* <Text style={styles.therdTitle}>YOUR PRICE</Text> */}
            <View style={styles.firstText}>
              <Text>
                {
                  item.meeting_id ? 
                  `${item?.group.name} - ${item?.group.meetings_records.length > 0 && 
                      item?.group.meetings_records.filter(meeting => meeting.id === item.meeting_id)[0]?.theme}` 
                  : item?.group.name
                }
              </Text>
              {/* {item?.group.status !== "ended" && <Link>
                <View style={styles.coursesBtn}>
                  <Text style={styles.courser}>Start Course</Text>
                </View>
              </Link>} */}
            </View>
            <Text style={styles.secondText}>
              {`$${(price / 100).toFixed(2)}`}
            </Text>
            {/* <Text style={styles.therdText}>$19.99</Text> */}
            <View style={styles.taxCredit}>
              {item.amount ? <>
                <Text style={styles.creditTitle}>STRIPE FEE</Text>
                <Text style={styles.creditData}>  {`$${(stripeFee / 100).toFixed(2)}`} </Text>
              </>
                : null}
              <Text style={styles.creditTitle}>SERVICE FEE</Text>
              <Text style={styles.creditData}>{`$${((price * AscendFee) / 100).toFixed(2)}`}</Text>
              <View style={styles.divider} />
              <Text style={styles.totalTitle}>Total</Text>
              <Text style={styles.totalData}>
                {`$${(((price * (1 + AscendFee)) + stripeFee) / 100).toFixed(2)}`}
              </Text>
            </View>
          </View>
          <View style={styles.details}>
            <View style={styles.detailsFrom}>
              <View style={styles.difFonts}>
                <Text style={styles.fromUserTitle}>Purchased by: </Text>
                <Text style={styles.fromUserData}>{userName}</Text>
              </View>
              <Text style={styles.fromCompany}>Sold by: Ascend</Text>
            </View>
            <Text style={styles.contact}>
              Questions? Please contact us at: <Link src="mailto:customersuccess@ascend2u.com">customersuccess@ascend2u.com</Link>
            </Text>
          </View>
        </View>
        <View style={styles.middleText}>
          {/* <Text style={styles.textDetails}>
            Want to know when Udemy courses are on sale? Click here to receive our promotional emails.
          </Text>
          <Text style={styles.support}>SUPPORT</Text>
          <Text style={styles.address}>
            Udemy.com 600 Harrison Street, 3rd Floor San Francisco, CA 94107 USA
          </Text> */}
          <View style={styles.linkBlock}>
            <Link src="https://apps.apple.com/ua/app/ascendvideo/id1496739998" style={styles.link} >
              <Image object-fit="fill" src={IOS} alt="images" style={styles.image} />
            </Link>
            <Link src="https://play.google.com/store/apps/details?id=com.doneit.ascend" style={styles.link} >
              <Image object-fit="fill" src={Android} alt="images" style={styles.image} />
            </Link>
          </View>
        </View>
      </Page>
    </Document>
  )
};

export default MyDoc;
