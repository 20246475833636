import React, {useEffect, useRef, useState} from 'react';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';
import ToggleSaveBtn from "../Button/toggleSaveBtn";
import startCase from 'lodash/startCase';
import GroupOwner from '../EventItem/eventOwner';
import ShareButton from '../ShareButton';
import ReportButton from '../ReportButton';
// import RestoreButton from '../RestoreButton';
import DeleteButton from '../DeleteButton';
import LsRecordGroupItems from './LsRecordGroupItems';
import {getLsRecordsTotalViews, getRecordFileType} from '../../units/func'
import ContentVideoPlayerPlaylist from "../ContentVideoPlayer/Playlist/"

import '../EventItem/eventItem.scss';
import './lsRecordGroup.scss';
import {IconButton} from "@material-ui/core";
import HeartIcon from "../../res/iconComp/HeartIcon";
import playlistGreyIcon from '../../res/icons/playlistGreyIcon.svg';
import GroupOwnerFollowBtn from "../GroupOwnerFollowBtn";
import ImgBuilder from '../../res/builders/ImgBuilder';

const LSRGroup = (props) => {
  const {
    item,
    tab,
    handleOpenDialog,
    showReport,
    groupsModel: {removeGroup, deleteGroup, buyGroup, restoreGroup, shareGroup, likeGroup, unlikeGroup},
    is_owner,
    defaultOpen,
    defaultHide = false,
    userModel: {isOwner},
    communityModel: {getCommunitiesTitlesString},
    playlistModel:{
      getMyPlaylists,
      showPlaylistIcon
    },
    postWidth = null,
    showLink,
  } = props;

  const [showPlaylist, setShowPlayList] = useState(false)

  const open = defaultOpen == item.id;

  //console.log("item", JSON.parse(JSON.stringify(item)))

  const elRef = useRef(null);
  const ScrollViewTo = (_) => {
    if (!open) return;
    handleOpenDialog(item)
    let topOffset =
      elRef.current.scrollHeight > window.innerHeight
        ? elRef.current.offsetTop
        : elRef.current.offsetTop - (window.innerHeight - elRef.current.scrollHeight) / 2;
    window.scrollTo({
      top: topOffset,
      left: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    const timeout = window.requestAnimationFrame(ScrollViewTo);
    return _ => {
      window.cancelAnimationFrame(timeout)
    }
  }, [open]);

  const owner = isOwner(item.owner);
  const renderItemTitle = () => (
    <div className="imageBlock imageWrapper"
         onClick={_ => handleOpenDialog(item)}
    >
      <img src={item?.image?.url} alt={item?.title}/>
      <div className="imageBlock_info">
        <div className="row">
          <div className={classNames('title', 'lsrgTitle')}>
            <div className="second">{startCase(getCommunitiesTitlesString(item))}</div>
            {/*<div className="iconButtonsBlock" onClick={ e => e.stopPropagation()}>*/}
            {/*  <ShareButton item={item} position="right" />*/}
            {/*</div>*/}
          </div>
        </div>

        <div className="imageBackgroundClickListener" onClick={() => handleOpen(item)}/>
      </div>
    </div>
  );

  const renderButton = () => {
    const type = item.record_type ? 'record' : null;
    const buyed =
      item?.meetings_records && item?.meetings_records.length > 0
        ? item?.meetings_records.filter((meeting) => meeting.record_access)
        : [];


    switch (true) {
      case owner:
        return <DeleteButton onClick={() => deleteGroup(item.id)} color="#7e7e8f"/>;

      case !owner && item.removed:
      case item.subscribed:
        return null
      // return toggleSave
      // return <RestoreButton onClick={() => restoreGroup(item.id, type)} />;

      case !item.subscribed && buyed.length === 0 && !item.removed:
        return null;

      case item.subscribed:
        return null //<DeleteButton onClick={() => removeGroup(item.id)} color="#7e7e8f" />;

      default:
        return null; //<DeleteButton onClick={() => removeGroup(item.id)} color='#7e7e8f' />
    }
  };

  const handleOpen = item => handleOpenDialog(item);

  const renderNumberOfFiles = () => {
    if (!item.hasOwnProperty('meetings_records')) return null;
    let result = '';
    const isLivestream = !item.hasOwnProperty('record_type');

    if (isLivestream) {
      let meeting = item?.meetings_count > 1 ? 'Meetings' : 'Meeting';

      result = item?.meetings_count
        ? `${item?.past_meetings_count}/${item?.meetings_count} ${meeting}`
        : `${item?.meetings_records?.length}/${item?.meetings_records?.length} ${meeting}`;
    } else {
      let fileTypesCounter = {};
      for (const record of item?.meetings_records) {
        const fileType = getRecordFileType(record);
        fileTypesCounter[fileType] = fileTypesCounter[fileType]
          ? fileTypesCounter[fileType] + 1
          : 1;
      }
      Object.keys(fileTypesCounter).map((fileType) => {
        const count = fileTypesCounter[fileType];
        const title = count > 1 ? fileType + 's' : fileType;
        result += `${count} ${title}`;
      });
    }
    return result;
  };

  // ascend.video://group/${item.id}/report_abuse


  const toggleSave = <ToggleSaveBtn
    item={item}
    restore={restoreGroup}
    setDefualtOpen={handleOpenDialog}
    remove={removeGroup}
    buy={buyGroup}
  /> 

  const renderPlaylistIcon = () => {
    const config = {
      src: playlistGreyIcon,
      onClick: (e) => {
        getMyPlaylists()
        setShowPlayList(true)
        e.stopPropagation();
      },
      alt: 'play_list',
      className: 'playlistGreyIcon',
    };
    return <img {...config} />;
  };

  const handleShareGroup = () => {
    shareGroup({
      group_id: item.id,
    })
  }
  const handleLikeGroup = () => likeGroup({
    group_id: item.id,
  })
  const handleUnlikeGroup = () => unlikeGroup({
    group_id: item.id,
  })

  const groupContent = (defaultHide, handleOpen) => {
    return (
      <div className={classNames('contentBlock', 'LSRGroupData')}>
        <GroupOwnerFollowBtn groupOwnerData={item.owner}/>

        <div className="lsrgTitle">
          <div>
            <p key="name">{item.name}</p>
            <div className="lsrgTitle-info">
              <p key="number" style={{color: '#7E7D8F'}}>{renderNumberOfFiles()}</p>
              <p key="views" style={{color: '#7E7D8F'}}>• {getLsRecordsTotalViews(item.meetings_records)} views</p>
            </div>
          </div>
          <div className="actionsButtons">
            {renderButton()}
            {!isOwner && tab !== 'my' && <ReportButton onClick={_ => showReport(item)} color="#7E7D8F"/>}
          </div>
        </div>
        <div className={'group_content_btns_wrapper'} style = {{
          //display:`${postWidth < 300 ? "flex" : "unse"}`, 
          flexDirection:`column-reverse`,
          alignItems:"flex-start"
        }}
          
        >
          <LsRecordGroupItems
            item={item}
            showButton={true}
            is_owner={owner}
            defaultHide={defaultHide}
            handleOpen={handleOpen}
          />
          <div className={"group_content_socialBtns"}>
            <div className={'button-wrapper like'}>
              <IconButton
                className="like"
                onClick={item.liked ? handleUnlikeGroup : handleLikeGroup}
              >
                <HeartIcon color={item.liked ? '#FF2D55' : "#A1A0AE"}/>
              </IconButton>
              {item.likes_count}
            </div>
            <div className={'button-wrapper share'}>
              <ShareButton
                item={item}
                position="right"
                color={'#7E7D8F'}
                showByClick={true}
                onShareAction={handleShareGroup}
              />
              {item.external_sharings_count}
            </div>
            {showPlaylistIcon(item) ? renderPlaylistIcon() : null}
          </div>
        </div>

      </div>
    );
  };
  return (
    <div className="itemBlock" key={item.id} ref={elRef} style = {{maxWidth: `${postWidth < 451 ? (postWidth + "px") : "450px"}`}}>
      {item?.available_for_subscribers && item?.have_subscription && <img src ={ImgBuilder.subscribe_pink} className = "subscribePink" />}
      {renderItemTitle()}
      {groupContent(defaultHide, handleOpen)}
      {showPlaylist ? 
        <div className='addToPlaylistcontainer'>
          <ContentVideoPlayerPlaylist 
            showPlaylist = {showPlaylist}
            handleClose = {() => setShowPlayList(false)}
            currentMediaIdViaProps = {item?.meetings_records?.length ? item?.meetings_records.map(item => item.id) : null}
          />
        </div>: null}
    </div>
  );
};

export default inject('groupsModel', 'userModel', 'communityModel', 'playlistModel')(observer(LSRGroup));
