import {
  chatChannel, gtIndividual,
  gtMasterMind,
  gtRecurring,
  gtRecurringWebinar,
  gtWebinar,
  meditation,
  podcast,
  video_record
} from "./general";

export const tiersList = [
  {label: 'Group', name: 'master_mind'},
  {label: 'Videos',name:'video_record'},
  {label: 'Livestream',name:'webinar'},
  {label: 'Podcasts',name:'podcast'},
  {label: 'Meditations',name:'meditation'},
  {label: 'Chat Channels', name: 'chat_channel'},
  // {label: 'Group', name: 'support'},
  // {label: 'Private coaching', name: 'individual'},
  // {label: 'Recurring',name:'recurring'},
  ]
export const tiersNamesArray = ['master_mind', 'video_record', 'webinar', 'podcast', 'meditation', 'chat_channel'];

export const AllTiers = {
  name: {
    [gtMasterMind]: "Group",
    [gtRecurring]:  "Group",
    [gtIndividual]:  "Group",
    [gtWebinar] : 'Livestream',
    [gtRecurringWebinar] : 'Livestream',
    [video_record]: "Videos",
    [podcast]: "Podcasts",
    [meditation]: "Meditations",
    [chatChannel]: 'Chat Channels'
  }
}
