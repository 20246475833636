import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AlertIcon from '../../res/iconComp/AlertIcon';

const ReportButton = props => {
  const { color, onClick } = props;

  return (
    <IconButton
      onClick={onClick}
      classes={{ root: "attendIcon" }}
    >
      <AlertIcon color={color} />
    </IconButton>
  )
}

export default ReportButton;
