import {observable, decorate, action} from 'mobx'; // action
import axios from 'axios';

import { baseAPI } from '../const/settings';

import { authModel } from './auth';
import { groupsModel } from './groups';
import { userModel } from './user';
import { mmContextModel } from './mmcontext';

export class MeetingsModel {
  constructor() {
    this.lastGroupID = null;
    this.lastMettingID = null;
    this.videoUrlData = "";
    this.mySchedule = {
      meetings: [],
      count: 0
    }
  }

  setLastData = (lastGroupID, lastMettingID) => {
    this.lastGroupID = lastGroupID;
    this.lastMettingID = lastMettingID;
  }

  setMySchedule = data => this.mySchedule = data
  clearMySchedule = data => this.mySchedule = {
    meetings: [],
    count: 0
  }

  buyRecord = (id, pay_id, type, promo = null) => {
    const setLastData = this.setLastData;
    if (groupsModel.canBuy) {
      groupsModel.setCanBuy(false);
      const changeResultMessage = groupsModel.changeResultMessage;
      const getRecordsGroups = groupsModel.getRecordsGroups;
      const getGroupsData = groupsModel.getGroupsData;
      const mmContextGroupsData = mmContextModel.mmContextGroupsData;
      const headers = {
        "headers": {
          "accept": "application/json, text/plain, */*",
          "session-token": authModel.session_token
        }
      };

      const deta = {
        payment_source_id: pay_id,
        payment_source_type: type,
        promo_code: promo,
      }

      axios.post(`${baseAPI}meetings/${id}/buy`, deta, headers)
        .then(function (response) {
          changeResultMessage('Success');
          getGroupsData(authModel.session_token);
          mmContextGroupsData(userModel?.user?.id);
          getRecordsGroups(authModel.session_token);
        })
        .catch(function (error) {
          changeResultMessage(error.response.data ? error.response.data.errors[0] : 'Error');
          
          setLastData(null, null);
        }).finally(() => {
          groupsModel.setCanBuy(true);
        })
    } else {
      setLastData(null, null);
    }
  }

  setMeettingDuration = (id, duration) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };

    axios.post(`${baseAPI}meetings/${id}/url`, { duration }, headers)
      .then(function (response) {
        // responsetiming
      })
      .catch(function (error) {
        // error
      })
  }

  setMeettingTiming = (id, time) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };

    axios.post(`${baseAPI}meetings/${id}/timing`, { time }, headers)
      .then(function (response) {
        // responsetiming
      })
      .catch(function (error) {
        // error
        console.log('error', error);
      })
  }

  getMySchedule = ({from, to}) => {
    if (!from || !to){
      return console.error("Need bought of 'from' and 'to' params")
    }
    const params = {
      from, to
    }

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      },
      params
    };

    const setMySchedule = this.setMySchedule
    const clearMySchedule = this.clearMySchedule

    axios.get(`${baseAPI}meetings/my_shedule`, headers)
      .then(function (response) {
        setMySchedule(response.data)
      })
      .catch(function (error) {
        clearMySchedule()
        console.log('error', error);
      })
  }


  incrementMeetingViewsCounter = (id) => {
    let url = `${baseAPI}meetings/${id}/views_increment`;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(url, {}, headers).then(response => {
        console.log(response)
      }).catch(function (error) {
        console.log('error', error)
      }).finally(() => {
      })  
  }
  getVideoUrlData = (video_id) => {

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      }
    };
  
    axios.get(`${baseAPI}meetings/${video_id}/get_video_url`, headers)
      .then(response => {;
        this.videoUrlData = response.data;
        this.videoUrlData["id"] = video_id;
      })
      .catch(function (error) {
        // handle error
        console.log('error', error)
      })
  } 
}



decorate(MeetingsModel, {
  lastGroupID: observable,
  lastMettingID: observable,
  getMySchedule: action,
  clearMySchedule: action,
  mySchedule: observable,
  incrementMeetingViewsCounter: action,
  getVideoUrlData: action,
  videoUrlData: observable
})

export const meetingsModel = new MeetingsModel();
