import React, {useEffect, useRef, useState} from 'react';
import {inject, observer} from "mobx-react";
import startCase from 'lodash/startCase';
import EventButton from './eventButton';
import EvenetDateTime from './eventDateTime';
import GroupTypeLabel from './groupType';
import subscriptionsIcon from '../../res/img/icons/subscriptionsContent.svg'
import './eventItem.scss';
import {actionSubscribe} from "../../const/general";
import CalendarTimeIcon from '../../res/iconComp/CalendarTime';
import GroupOwnerFollowBtn from "../GroupOwnerFollowBtn";
import ImgBuilder from '../../res/builders/ImgBuilder';
import inPersonIcon from '../../res/icons/inPerson.svg';
import onlineIcon from '../../res/icons/online.svg';
import privateLivestreamIcon from "../../res/icons/privet_livestream.svg";
import moment from "moment";
import calendarDate from "../../res/icons/calendarDate.svg";

const EventItem = (props) => {
  const { item,
    defualtOpen,
    index,
    refresh = () => {},
    setGroupNotFound = (bool) =>{},
    handleOpenDialog = () => {},
    subscribePackageModel:{subscribedPackagesList},
    communityModel: { getCommunitiesTitlesString },
    postWidth= null,
    postHeight = null
  } = props;
  /* eslint eqeqeq: 0 */
  const open = defualtOpen == item.id;
  const checkAvailabilityBySubscriptions = () => {
    return item.available_for_subscribers && !!subscribedPackagesList.subscription_packages.find( (pack) => {
      return item.owner.id === pack.owner.id && item?.communities && item.communities.includes(String(pack.community_id)) 
      && pack?.tiers && pack.tiers.includes(item.group_type)
    })
  }
  const [availableBySubscription, setAvailableBySubscription] = useState(checkAvailabilityBySubscriptions())
  const elRef = useRef(null);
  const ScrollViewTo = (_) => {
    if (!open) return;
    handleOpenDialog()
    setGroupNotFound(false);
    let topOffset =
      elRef.current.scrollHeight > window.innerHeight
        ? elRef.current.offsetTop
        : elRef.current.offsetTop - (window.innerHeight - elRef.current.scrollHeight) / 2;
    window.scrollTo({
      top: topOffset,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const timeout = window.requestAnimationFrame(ScrollViewTo);
    return _ => {
      window.cancelAnimationFrame(timeout)
    }
  }, [index, open]);

  useEffect(() => {
    setAvailableBySubscription(checkAvailabilityBySubscriptions())
  }, [subscribedPackagesList.subscription_packages])

  const handleOpenAttendWithSubscription = () => {
    handleOpenDialog({type: actionSubscribe})
  }

  const renderItemTitle = (
  <>
    {!!item.price && availableBySubscription && <div className={'subscriptionsIcon'}><img src={subscriptionsIcon}/></div>}
    {(item.price > 0 && !availableBySubscription && !item.subscribed) &&
        <img src = {ImgBuilder.subscription_premium_text} className = "imageBlock_premium"/>
    }

    <div className="imageBlock imageWrapper" onClick={handleOpenDialog}>

      <img src={item?.image?.url} alt={item?.title}/>
      <div className="imageBlock_info">
        <div className="title">
          <div style={{display: "flex"}}>
            {
              (item.group_type === "webinar" || item.group_type === "recurring_webinar")
              && item.private ?
                  <div className="groupType groupType webinar privateLivestream">
                    <img src={privateLivestreamIcon} alt="privateLivestreamIcon"/>
                    <div>Private Livestream</div>
                  </div>
                  : <GroupTypeLabel
              group_type={item.group_type}
              owner={item.owner}
              isPrivate={item.private}
              className="first"
              isClosed={item.price > 0}
              group={item}
            />}
            {item.group_type != 'recurring_webinar' && item.group_type != 'webinar' &&
              <div className="second inPersonWrapper">
                {item.in_person ?
                  <div className="inPerson">
                    <img className="inPerson_img" src={inPersonIcon} alt="In Person"/>
                    <div className="inPerson_text">In Person</div>
                  </div> :
                  <div className="inPerson">
                    <img className="inPerson_img" src={onlineIcon} alt="Online"/>
                    <div className="inPerson_text">Online</div>
                  </div>
                }
              </div>
            }
            {(item.group_type === 'recurring_webinar' || item.group_type === 'webinar') &&
                <div className="second inPersonWrapper">
                    <div className="inPerson">
                       <img className="inPerson_img" src={onlineIcon} alt="Online"/>
                       <div className="inPerson_text">Online</div>
                    </div>
                </div>
            }
          </div>
          <div className="second">{startCase(getCommunitiesTitlesString(item))}</div>
        </div>
        <div className="row">
          <EvenetDateTime start_time={item.start_time} />
        </div>
      </div>
    </div>
  </>
  );

  const renderItemInfo = (
    <div className="detailsBlock">
      {item.tag ? <div className="eventTag">{item.tag.title}</div> : null}
      <div className="eventName">{item.name}</div>
    </div>
  );

  const checkLastItem = (string, arr, index, day) => {
    let newString = '';
    if(index === arr.length - 1) {
      const startDate = item.schedule_info[0].start_date + ' ' + item.schedule_info[0].start_time;
      const endDate = item.schedule_info[0].end_date + ' ' + item.schedule_info[0].end_time;
      const startTime = moment.utc(startDate, 'YYYY-MM-DD hh:mm').local().format('h:mm A');
      const endTime = moment.utc(endDate, 'YYYY-MM-DD hh:mm').local().format('h:mm A');

      newString = string + day + ' ' + startTime + " - " + endTime;
    } else  {
      newString = string + day + ', ';
    }
    return newString;
  };

  const getDayOfWeek = (arr) => {
    let string = '';
    arr.forEach((item, index) => {
      if(item === "0") {
        string = checkLastItem(string, arr, index, 'Sun');
      } else if(item === "1") {
        string = checkLastItem(string, arr, index, 'Mon');
      } else if(item === "2") {
        string = checkLastItem(string, arr, index, 'Tue');
      } else if(item === "3") {
        string = checkLastItem(string, arr, index, 'Wed');
      } else if(item === "4") {
        string = checkLastItem(string, arr, index, 'Thu');
      } else if(item === "5") {
        string = checkLastItem(string, arr, index, 'Fri');
      } else if(item === "6") {
        string = checkLastItem(string, arr, index, 'Sat');
      }
    });
    return string;
  };

  const getDayOfMonth = (arr, startDate) => {
    const date = moment(startDate, 'YYYY-MM-DD').format('MM.YYYY');
    let string = '';

    arr.forEach((item, index) => {
      const day = moment(item + "." + date, 'DD.MM.YYYY').format("Do");
      string = checkLastItem(string, arr, index, day);
    });
    return string;
  };

  const endDateArr = [];
  const startDateArr = [];
  let repeatsDayOfWeek = '';

  const sortWeeklyRepeats = (startDay, itemScheduleInfoRepeats) => {
    const newArr = itemScheduleInfoRepeats.filter(item => Number(item) !== Number(startDay));
    const result = [String(startDay)];

    newArr.sort((a,b) => {
      return a - b;
    })

    newArr.forEach(item => {
      if(Number(item > Number(startDay))) {
        result.push(item);
      }
    });

    newArr.forEach(item => {
      if(Number(item < Number(startDay))) {
        result.push(item);
      }
    });

    return result;
  };

  item.schedule_info && item.schedule_info.forEach(el => {
    endDateArr.push(el.end_date);
    startDateArr.push(el.start_date);
  });

    endDateArr.sort((a, b) => moment(b, 'YYYY-MM-DD').unix() - moment(a, 'YYYY-MM-DD').unix());
    startDateArr.sort((a, b) => moment(a, 'YYYY-MM-DD').unix() - moment(b, 'YYYY-MM-DD').unix());

      if(item.group_type !== "webinar" && item.group_type !== "recurring_webinar" && item?.schedule_info) {
        if(item.schedule_info[0].type === "specific_date") {
            let string = '';
            const day = moment(item.schedule_info[0].start_date, 'YYYY-MM-DD').format( 'ddd' );
            repeatsDayOfWeek = checkLastItem(string, [day], 0, day);
          } else if (item.schedule_info[0].type === "weekly") {
            const startDay = moment(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z").weekday();
            const itemScheduleInfoRepeats = item.schedule_info[0].repeats;
            const newArrWeeklyRepeats = sortWeeklyRepeats(startDay, itemScheduleInfoRepeats);
            repeatsDayOfWeek = getDayOfWeek(newArrWeeklyRepeats);
          } else if (item.schedule_info[0].type === "monthly") {
            const startDay = moment(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z").format('DD');
            const newArrMonthlyRepeats = sortWeeklyRepeats(startDay, item.schedule_info[0].repeats);
            repeatsDayOfWeek = getDayOfMonth(newArrMonthlyRepeats, item.schedule_info[0].start_date);
          }
      }

  return (
    <div className="itemBlock" key={item.id} ref={elRef} 
      style = {{
        maxWidth:`${postWidth}px`,
        minWidth:`${postWidth}px`,

        height:`${postHeight ? postHeight-30 : ""}px`
      }}
    >
      {renderItemTitle}
      <div className="contentBlock">
        {renderItemInfo}
          {item &&
          <div className = "eventItemBottom" 
            style={{
              display:"flex", 
              width: "100%",
              //flexDirection: `${postWidth < 350 ? "column" : "row"}`,
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div className={'detailsBlock_owner'} style ={{flexGrow:1, marginRight: "10px", display:"flex", justifyContent:"space-between"}}>
              <GroupOwnerFollowBtn groupOwnerData={item.owner}/>
              {repeatsDayOfWeek.length > 0 ? <div className="detailsTime" style={{display:"flex", marginLeft:"40px"}}>
                    <div style={{width:"16px"}}><CalendarTimeIcon /></div>&nbsp;&nbsp;
                    <div className="detailsTimes" style={{marginLeft:"0"}}>
                      <div>{repeatsDayOfWeek}</div>
                    </div>
                  </div> :
                  <div className="detailsTime" style={{marginLeft:"0", display:"flex", alignItems:"flex-start"}}>
                    <img src={calendarDate} alt="calendar"/>&nbsp;&nbsp;
                    {startDateArr.length > 0 && endDateArr.length > 0 ?
                        <div className="detailsTimes" style={{marginLeft:"0"}}>
                          <div>
                            {moment(startDateArr[0]).format('DD MMM YYYY')}
                            &nbsp;-&nbsp;
                            {moment(endDateArr[0]).format('DD MMM YYYY')}
                          </div>
                        </div> : item.meetings_records?.length > 0 ?
                        <div className="detailsTimes" style={{marginLeft:"0"}}>
                          {item.meetings_records?.length > 0 &&
                              <div>
                                {moment.utc(item.meetings_records.sort((a, b) => b.start_time - a.start_time)[0].start_time).local().format('DD MMM YYYY')}
                                &nbsp;-&nbsp;
                                {moment.utc(item.meetings_records.sort((a, b) => b.start_time - a.start_time)[item.meetings_records.length - 1].start_time).local().format('DD MMM YYYY')}
                              </div>}
                        </div> :
                            <div className="detailsTimes" style={{marginLeft:"0"}}>
                              {!!item.legacy_fields &&
                                  <div>
                                    {moment.utc(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z").local().format('ddd')}
                                    &nbsp;
                                    {moment.utc(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z").local().format('h:mm A')}
                                    &nbsp;-&nbsp;
                                    {moment.utc(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z")
                                        .add(item.legacy_fields?.duration, 'minutes').local().format('h:mm A')}
                                  </div>}
                            </div>
                    }
                  </div>
              }
            </div>
            <div style={{display:"flex"}}>
              <EventButton
                  item={item}
                  refresh={refresh}
                  availableBySubscription={availableBySubscription}
                  isPreview={true}
                  openSubscription={handleOpenAttendWithSubscription}
                  handleOpenDialog = {() => handleOpenDialog()}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default inject( 'subscribePackageModel', 'communityModel')(observer(EventItem));
