import { observable, decorate, action } from 'mobx';
import axios from 'axios';
import { authModel } from './auth';
import { baseAPI } from '../const/settings';

export class ChatsModel {
  constructor() {
    this.chats = null;
    this.mmChannels = null;
  }

   setChats = data => this.chats = data;
   setMMChannels = data => this.mmChannels = data;

  getChats = () => {
    const setChats = data => this.setChats(data);
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.get(`${baseAPI}chats`, headers)
      .then(function (response) {
        setChats(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      })
  }

  getMMChannels = (user_id) => {
    const setMMChannels = data => this.setMMChannels(data);
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.get(`${baseAPI}chats?chat_type=channel&owner_id=${user_id}&all_channels=true`, headers)
      .then(function (response) {
        setMMChannels(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      })
  }

  
}

decorate(ChatsModel, {
   chats: observable,
   getMMChannels: action,
   mmChannels : observable
})

export const chatsModel = new ChatsModel();
