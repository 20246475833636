import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import classNames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import CustomVideoPlayer from '../CustomVideoPlayer';
import MyPlayer from '../MyPlayer';
import PlayVideoIcon from '../../res/iconComp/PlayVideoIcon';
import PlayVideoCloseIcon from '../../res/iconComp/PlayVideoCloseIcon';
import AudioLabel from '../../res/iconComp/AudioLabel';
import VideoLabel from '../../res/iconComp/VideoLabel';
import BuyVideo from '../BuyVideo';
import './style.scss';
import { audio, video } from '../../const/fileSettings';
import {video_record, gtWebinar, gtRecurringWebinar} from '../../const/general';

const RecordItem = props => {
  const { 
    record, 
    owner,
    is_owner, 
    recordPrice, 
    index,
    groupID,
    record_preview,
    availableBySubscription = false,
    meetingsModel: { buyRecord, setMeettingTiming, setLastData, lastMettingID, getVideoUrlData},
    userModel: {user},
    record_type,
    event_type,
  } = props;
  
  // const isLivestreamContentPage = window.location.pathname.includes(records) || window.location.pathname === home;
  const isLiveStreamContent = event_type === gtWebinar || event_type === gtRecurringWebinar;
  const recordStartDate = (start_time) =>
    <div className="date">
      {moment.utc(start_time).local().format("DD MMM YYYY [at] hh:mm a")}
    </div>

  const recordTime = (minutes) => {
    let formatedMinutes = minutes
    if(record.play_time){
      formatedMinutes = parseInt(record.play_time)/60
    }
    //return moment.utc().startOf('day').add({formatedMinutes}).format('HH:mm');
    return new Date(formatedMinutes * 1000).toISOString().substr(11, 8);
  }

  const recordLabel = ({ record_access, record_present, start_time }) => {
    switch (true) {
      // case (!record.ended):
      //   return null;
      case (is_owner || recordPrice === 0):
        return recordStartDate(start_time);
      case (record_present && !record_access):
          return  recordStartDate(start_time);
          // return (
          //  // AW-1675
          //   <BuyVideo record={record} recordPrice={recordPrice} groupID={groupID}>
          //     <div className="buy">{`Buy for $ ${(recordPrice / 100).toFixed(2)}`}</div>
          //   </BuyVideo>
          // );
      default: return recordStartDate(start_time);
    }
  }

  const haveAccessToRecordLiveStreams = () => {
    if(is_owner){
      return true
    }else if((props?.item?.status === "ended")){
      if(!props?.item.available_for_subscribers && !props?.item.price){
        return true
      }else if(!!props?.item.price && (props?.item.purchase || (props?.item.available_for_subscribers && props?.item.have_subscription))){
        return true
      }else if(props?.item.available_for_subscribers && props?.item.have_subscription){
        return true
      }else if(props?.item.invited){
        return true
      }
      return false
    }else{
      return false
    }
  }

  const playVideo = (data) => {
    const isVideoPresent = data?.record_present || !data.hasOwnProperty('record_present') || data?.video_file;

    switch (true) {
      case (!isVideoPresent):
      case (!data?.ended && data.hasOwnProperty('ended')):
        return null;
      //case (isVideoPresent && !isLiveStreamContent && props?.item?.available_for_subscribers && !props?.item?.have_subscription):
      case (isVideoPresent && isLiveStreamContent && !haveAccessToRecordLiveStreams()
        && user.id !== owner.id && (props.item.price ? !props.item.purchase : true)):
        return (
          <div className="record_item_play" >
            <PlayVideoCloseIcon disable={true} />
            <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
          </div>
        );

      case (isVideoPresent &&  !isLiveStreamContent && props?.item?.available_for_subscribers && !props?.item?.have_subscription 
        && user.id !== owner.id && (props.item.price ? !props.item.purchase : true)):
        return (
          <div className="record_item_play" >
            <PlayVideoCloseIcon disable={true} />
            <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
          </div>
        );
      
      case (isVideoPresent &&  !isLiveStreamContent && !props?.item?.available_for_subscribers && user.id !== owner.id && props.item.price && !props.item.purchase):
        return (
          <div className="record_item_play" >
            <PlayVideoCloseIcon disable={true} />
            <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
          </div>
        );
      
      case (isVideoPresent && !isLiveStreamContent && (!props?.item?.available_for_subscribers || props?.item?.have_subscription)):
        return (
          <MyPlayer record_info={{...data, url: data.file, image: record_preview}} owner={owner} onClose={() => setLastData(null, null)}>
            <div className="record_item_play" >
              <PlayVideoIcon disable={false} />
              <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
            </div>
          </MyPlayer>
        );
        
      case (!!data?.file):
        return (
          <MyPlayer record_info={{...data, url: data.file, image: record_preview}} owner={owner} onClose={() => setLastData(null, null)}>
            <div className="record_item_play" >
              <PlayVideoIcon disable={false} />
              <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
            </div>
          </MyPlayer>
        );

      case (is_owner || isVideoPresent && data?.record_access):
        return (
          <MyPlayer owner={owner} record_info={{...data, url: data.file ? data.file : data.url, image: record_preview}} onClose={() => setLastData(null, null)}>
            <div className={classNames("record_item_play", "record_item_play_button")}>
              <PlayVideoIcon disable={false} />
              <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
            </div>
          </MyPlayer>
        );
      
      case (isVideoPresent && !data?.record_access && recordPrice === 0):
        return (
          <div
            className={classNames("record_item_play", "record_item_play_button")}
            onClick={() => {
              buyRecord(record.id, null, 'card', null);
              setLastData(groupID, record.id);
            }}
          >
            <PlayVideoIcon disable={false} />
            <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
          </div>
        );
      
      case (isVideoPresent && !data?.record_access && recordPrice !== 0 && isLiveStreamContent && !availableBySubscription):
        return (
          <BuyVideo record={record} recordPrice={recordPrice} groupID={groupID}>
            <div
              className={classNames("record_item_play", "record_item_play_button")}
            >
              <PlayVideoCloseIcon />
              <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
            </div>
          </BuyVideo>
        );
      case (isVideoPresent && !data?.record_access && recordPrice !== 0 && isLiveStreamContent && availableBySubscription):
      case !data?.file:
      
      case (isVideoPresent && !data?.record_access && !isLiveStreamContent):  
        return (
            <div className="record_item_play" >
              <PlayVideoCloseIcon disable={true} />
              <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
            </div>
          );
        
      default:
        return (
          <div className="record_item_play" >
            <PlayVideoIcon disable={true} />
            <div className="record_item_play_total_time">{recordTime(data.duration)}</div>
          </div>
        );
    }
  }

  const progress = record.duration ? (record.time || 0) * 100 / record.duration : 100;

  const iconLabel = () => {
    if (!!record.file) {
      const fileArr = record.file.split('.');
      if (audio.includes(`.${fileArr[fileArr.length - 1].toLowerCase()}`) && record_type !== video_record) {
        return <AudioLabel />
      }
      if (video.includes(`.${fileArr[fileArr.length - 1].toLowerCase()}`) && record_type === video_record) {
        return <VideoLabel />
      }
    }
    return <VideoLabel />
  }

  const renderPlay = (className = null) => (
    <div className={classNames("record_item_data", className)}>
      <div className="record_item_info">
        <div className="record_item_number">{index + 1}</div>
        <div className="record_item_info_title">{record.theme}</div>
      </div>
      {playVideo(record)}
    </div>
  )

  return (
    <div className="record_item">
      {iconLabel()}
      {/* {renderPlay('mobile_play')} */}
      <div className="record_item_block">
        {renderPlay()}
        <div className="record_item_status">
          <div className="record_item_info_progress">
            <LinearProgress variant="determinate" value={progress} />
          </div>
          <div className="record_item_info_label">{recordLabel(record)}</div>
        </div>
      </div>
    </div>
  )
}

export default inject('meetingsModel', 'userModel')(observer(RecordItem));