import React from 'react';
// import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import MainPage from '../MainPage';
import CreateVideoContent from '../../components/CreateVideoContent/CreateVideoContent';
import MessagesGlobal from '../../components/MessagesGlobal';

import './style.scss';

const CreateVideo = props => {
  const { 
    userModel: { user }, record_type
    // match 
  } = props;
  return (
    <MainPage className = "white" key={user?.id} hideMenu>
      <div className="createVideoContentPage">
        <CreateVideoContent record_type={record_type} />
      </div>
      <MessagesGlobal />
    </MainPage>

  )
}

export default inject('userModel')(observer(CreateVideo));
