import React from 'react';
import classNames from 'classnames';
import indexOf from 'lodash/indexOf';
import { inject, observer } from 'mobx-react';
import Button from '../../../../components/Button';

const TagsFilter = props => {
  const {
    groupsModel: {
      state: { filters },
      changeFilterTags,
      // tags
    },
    tagsModel: {
      tags
    }
  } = props;

  return (
    <div className="group tags">
      {tags !== null && tags.map((tag,key) => (
        <Button
          key={key}
          label={tag.title} // Group
          className={classNames('privateButton', { active: indexOf(filters.tags, tag.id) >= 0 })}
          name="private"
          onClick={() => changeFilterTags(tag.id)}
        />
      ))}
    </div>
  )
}

export default inject('groupsModel', 'tagsModel')(observer(TagsFilter));
