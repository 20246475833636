import React, { useEffect } from "react";
import ViewPlaylist from '../ViewPlaylist';
import EditPlaylist from '../EditPlaylist';
import MyPlayer from '../../MyPlayer';
import { getPlaylistMeta } from '../../../units/func';
import PlayVideoIcon from "../../../res/iconComp/PlayVideoIcon";
import ViewPlaylistIcon from "../../../res/icons/delete_playlist.svg";
import EditPlaylistIcon from "../../../res/icons/edit _playlist.svg";
import DeletePlaylistIcon from "../../../res/icons/view_playlist.svg";

import "./styles.scss";

const PlaylistItem = (props) => {
  const { playlist, handleDelete, handleUpdate, handleDeleteItem, handleUpdatePlaylistItems, postWidth = null} = props;
  const playlistItems = playlist.items ? playlist.items : [];
  const meta = getPlaylistMeta(playlistItems);
  
  return (
    <div 
      className={"playlist_list_item"} 
      style = {{
        maxWidth: `${postWidth < 451 ? (postWidth + "px") : "450px"}`,
      }}
    >
      <div className={"playlist_list_item_image"}>
        <div className={"playlist_list_item_name"}></div>
        {(playlistItems[0] && playlistItems[0].image.url) ? <img src={playlistItems[0].image.url}/> : <div className = "playlist_list_item_img_empty" />}
      </div>

      <div className={"playlist_list_item_description"}>
        <div className={"playlist_list_item_text"}>
          {<div className={"playlist_list_item_title"}>{playlist.title}</div>}
          {<div className={"playlist_list_item_location"}>{meta}</div>}
        </div>
      </div>
      
      <div className={"playlist_list_item_actions"}>
        <ViewPlaylist 
          playlist = {playlist} 
          playlistMeta = {meta} 
          handleDelete = {handleDelete}
          handleDeleteItem = {handleDeleteItem}
          handleSubmit = {handleUpdate}
          handleUpdatePlaylistItems = {handleUpdatePlaylistItems}>
            <div className={"playlist_list_item_actions_btn"}>
              <img src={DeletePlaylistIcon} alt="DeletePlaylistIcon" />
              View
            </div>
        </ViewPlaylist>
        <EditPlaylist 
          playlist = {playlist} 
          handleSubmit = {handleUpdate} 
          handleDeleteItem = {handleDeleteItem} 
          handleUpdatePlaylistItems = {handleUpdatePlaylistItems}>
          <div className={"playlist_list_item_actions_btn"}>
            <img src={EditPlaylistIcon} alt="DeletePlaylistIcon" />
            Edit
          </div>
        </EditPlaylist>
        { playlist.playlist_type === "custom" &&
            <div className={"playlist_list_item_actions_btn"} onClick={handleDelete}>
              <img src={ViewPlaylistIcon} alt="ViewPlaylistIcon" />
              Delete
            </div>
        }

        {playlistItems.length > 0 && 
          <MyPlayer playlist = {playlist} record_info={playlistItems[0]} owner={playlistItems[0].owner}>
            <div className={"playlist_list_item_play"}>
              <PlayVideoIcon width={32} height={32} />
            </div>
          </MyPlayer>
        }
      </div>
    </div>
  );
};

export default PlaylistItem;
