import React, {useEffect, useState, useReducer} from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import UploadVideos from '../UploadVideos/UploadVideos';
import UploadImage from '../UploadImage';
import ImgBuilder from '../../res/builders/ImgBuilder';
import InputWithIcon from '../Input/inputWithIcon';
import Button from '../Button';
import CustomInput from '../Input/customInput';
import Price from '../Input/price';
import CustomSelect from '../../components/Select/select';
import CustomSelectMultiple from "../Select/selectMultiple";
import Dialog from '../../components/Dialog';
import StripeVerification from '../../pages/StripeVerification';
import create_group_icons from '../../res/icons/create_group_icons';

import {video_record, meditation, podcast} from '../../const/general';

import './style.scss';
import audioBack from "../../res/img/audioBack.svg";
import moment from "moment";
import Input from "../Input";
import {getPriceRegExp} from "../../res/validations";
import {getCorrectPrice} from "../../units/func";
import FormSwitcherDarkViolet from "../UI/Switch/FormSwitcherDarkViolet";


const createValidationSchema = (isVideo, filesCount) => {
  const fileError = isVideo
    ? 'Something went wrong. The video size can’t be more than 10 GB in a .mp4, .mov format'
    : 'Something went wrong. The file size can’t be more than 10 GB in a .mp4, .mov .mp3 .alac .flac .wav .m4a format';
  return Yup.object().shape({
    name: Yup.string()
      .required('Please write group name')
      .min(2, 'Please, enter the Group Name correctly. (minimum is 2 characters)')
      .max(64, 'Please, enter the Group Name correctly. (maximum is 64 characters)'),
    description: Yup.string()
      .min(2, 'Description is too short (minimum is 2 characters)')
      .max(500, 'Please, enter the Group Description correctly (maximum is 500 characters)')
      .required('Please write group desciption'),
    price: Yup.string()
      .when(['available_for_purchases'], ( available_for_purchases = false, schema)=>{
        return  Yup.string().matches(getPriceRegExp(1, false), `Set price $ 1.00 or more, and less $ XX XXX.XX ` )
      })
      .when('available_for_purchases', {
        is: true,
        then: Yup.string()
          .required('Please write group price')
      }),
    image: Yup.object()
      .required(
        'Something went wrong. The image size can’t be more than 30 Mb in a format .png, .jpg'
      )
      .nullable(),
    community_id: Yup.string()
     .required('Please select a community'),
    files: Yup.array().of(
      Yup.object().shape({
        meta: Yup.object().shape({
          name: Yup.string()
            .strict(true)
            .trim()
            .required('Please write video name')
            .min(2,'Please, enter the Name correctly. (minimum is 2 characters)')
            .max(64,'Please, enter the Name correctly. (maximum is 64 characters)'),
        })
      })
    )
      .required(fileError),
  });
};

const editValidationSchema = (fileError) => {
  return Yup.object().shape({
    name: Yup.string()
      .required('Please write group name')
      .min(2,'Please, enter the Group Name correctly. (minimum is 2 characters)')
      .max(64,'Please, enter the Group Name correctly. (maximum is 64 characters)'),
    description: Yup.string()
      .min(2,'Description is too short (minimum is 2 characters)')
      .max(500,'Please, enter the Group Description correctly (maximum is 500 characters)')
      .required('Please write group desciption'),
    files: Yup.array().of(
      Yup.object().shape({
        theme: Yup.string()
          .required('Please write video name')
          .min(2,'Please, enter the Group Name correctly. (minimum is 2 characters)')
          .max(64,'Please, enter the Group Name correctly. (maximum is 64 characters)'),
      })
    )
      .required(fileError)
  })

}

const CreateVideoContent = props => {
  const history = useHistory();
  const location = useLocation()
  const [sizeError, setSizeError] = useState(null);
  const [abilityEditPrice, setAbiblityEditPrice] = useState(false);
  const isEdit = location?.state?.action === 'edit'
  const {
    mediaModel: { createVideoRecordGroup, uploadProgress, selectRecord, setSelectRecord, updateVideoRecordGroup}, // setCreateStatus, createStatus, loading
    userModel: { user, community }, // community
    subscribePackageModel:{subscriptionPackagesList, getSubscriptionPackagesList},
    communityModel: {getCommunityNameById, communityList, distribution_groups},
    groupsModel:{getSingleGroupData, lastGroup},
    record_type
  } = props;
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [openStripeDialog, setOpenStripeDialog] = useState(false);
  const [formSwitcherPurchaseValue, setFormSwitcherPurchaseValue] = useState(!!selectRecord?.price || false)
  const [formSwitcherSubscribersValue, setFormSwitcherSubscribersValue] = useState(selectRecord ? selectRecord?.available_for_subscribers: false)
  const [canBuyBySubscribe, setCanBuyBySubscribe] = useState(false);
  const [distributionGroupSelected, setDistributionGroupSelected] = useState(false)
  const [touchedSubscribersSwitcher, setTouchedSubscribersSwitcher] = useState(false)
  const [valuesState, setValuesState] = useState({})

  const getSelectedCommunity = () => {
    let community = "";
    if (selectRecord && selectRecord.community){
      community =  currentCommunityList.find(community => {
        return selectRecord.community_id === community.id
      })
    } else {
      community =  currentCommunityList.find(community => {
        return user.community_id === community.id
      })
    }
    return community && community
  }
  const currentCommunityList = [];
  const allCommunities = communityList.selected.includes(user.community_id)? communityList.selected.slice() : communityList.selected.concat([user.community_id])
  allCommunities.forEach( (id) => {
    const comItem = communityList.mappedCommunities[id]
    comItem && comItem.moderator && currentCommunityList.push(comItem);
  })
  const [selectedCommunity, setSelectedCommunity] = useState(getSelectedCommunity()?.id )

  useEffect(_ => {
    getSubscriptionPackagesList()
    if(isEdit){
      getSingleGroupData(selectRecord.id)
    }
    return _ => setSelectRecord(null)
  },[])

  useEffect(()=>{
    if(isEdit && lastGroup){
      setAbiblityEditPrice(lastGroup.participants_count);
      forceUpdate()
    }
  },[lastGroup])

  useEffect(() => {
    console.log("canBuyBySubscribe", canBuyBySubscribe)
  }, [canBuyBySubscribe])

  useEffect(() => {
    selectedCommunity && console.log("selectedCommunity", JSON.parse(JSON.stringify(selectedCommunity)))
    let selectedCommunityArray = selectedCommunity && (Array.isArray(selectedCommunity) ? selectedCommunity : [selectedCommunity])
    const filter_by_community = ((Array.isArray(selectedCommunityArray) && selectedCommunityArray.length) || (valuesState?.distribution_group_id)) ? 
      subscriptionPackagesList?.subscription_packages?.filter((pack) => pack.communities.some(comm => 
        (selectedCommunityArray.includes(comm)) || 
        (distribution_groups?.distribution_groups && 
          valuesState?.distribution_group_id && 
          distribution_groups?.distribution_groups.filter(grp => valuesState?.distribution_group_id === grp.id)[0].communities.some(distComm => distComm.id + "" === comm)
        )
      )) : 
      [];
    const hasTiers = filter_by_community.some( (pack) => pack?.tiers?.includes(record_type))
    setCanBuyBySubscribe(hasTiers)
  },[subscriptionPackagesList, selectedCommunity, valuesState])
  
  if (!communityList.count) return <CircularProgress className="loader" />


  const renderTitle = () => {
    switch (record_type) {
      case video_record:
        return `${isEdit? "Edit" : 'Create'} Video Content`;

      case meditation:
        return `${isEdit? "Edit" : 'Create'} Meditation Content`;

      case podcast:
        return `${isEdit? "Edit" : 'Create'} Podcast Content`;

      default:
        break;
    }
  };


  const initValues = {
    image: selectRecord?.image?.url || null,
    name: selectRecord?.name || "",
    description: selectRecord?.description || "",
    price: selectRecord? parseFloat(selectRecord.price/100).toFixed(2) : user.payouts_enabled ? "" : 0,
    files: selectRecord?.meetings_records || [],
    community_id: getSelectedCommunity()?.id,
    available_for_purchases: !!selectRecord?.price|| false,
    available_for_subscribers: selectRecord?.available_for_subscribers || false
  }


  const renderExistingVideos = ({setFiles, files}) => (
    <div className="upload_video">
      {files.map(meeting =>(
        <div className="upload_video_item uploaded" key={meeting.id}>
          <div className="time">{moment.utc(moment.duration(meeting?.duration, "seconds").asMilliseconds()).format("HH:mm:ss")}</div>
          <video
            className="uploaded_video_item_preview"
            style={{ backgroundImage: `url(${audioBack})`}}
            src={meeting.file}
          >
          </video>
          <div className="video_uploading_meta_data" >
            <Input
              name='files.name'
              value={meeting?.theme}
              onChange = {(e) => {
                return setFiles(files.map(item => {
                  return item.id === meeting.id ?  {...item, theme: e.target.value}: item
                }))
              }}
              className="videoName"
              error={(meeting?.theme.length < 2 || meeting?.theme.length > 32)}
              placeholder="Add name"
            />
            <div className="error">
              {(meeting?.theme.length < 2 || meeting?.theme.length > 32) &&  // item.meta?.error &&
              "Please, enter the Group Name correctly. (minimum is 2 and maximum is 64 characters)"}
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <div className={`upload_video_content${isEdit? ' edit': ''}`}>
      <div className="upload_video_content_title">{renderTitle()}</div>
      <Formik
        initialValues={initValues}
        validationSchema={isEdit?
          editValidationSchema():
          createValidationSchema(record_type === video_record, 1)
        }
        onSubmit={(values, actions) => {
          const { setFieldError } = actions;
          if (isEdit) {
            let updatedPrice = 0;
            if (!values.available_for_purchases){ 
              updatedPrice = 0 
            }else{
              updatedPrice = getCorrectPrice(values.price)
            }
            updateVideoRecordGroup({
              communities:values.communities,
              name: values.name,
              description: values.description,
              price:updatedPrice,
              setFieldError,
              id: selectRecord.id,
              files: values.files.map(item => ({id: item.id, theme: item.theme})),
              available_for_subscribers: values.available_for_subscribers,
              distribution_group_id: values?.distribution_group?.id 
            })
            history.goBack();
          } else {
            const correctPrice = getCorrectPrice(values.price)

            createVideoRecordGroup({
            ...values,
            price: correctPrice,
            setFieldError,
              record_type
          });
          setSizeError(null);
          history.goBack();
          }
        }}
        enableReinitialize
      >
        {(formData) => {
          const { values, setFieldValue, isValid } = formData;
          setValuesState(values)
          let moderatorListData = [];
              JSON.parse(JSON.stringify(communityList))["communities"].map((value) => (
                value.moderator && moderatorListData.push({
                value: "" + value.id,
                label: value.title,
              })));
          return (
            <div className="upload_video_content_form">
              <Form>
                <div className="upload_video_content_data">
                  <div className="upload_video_content_data_fields">
                    <InputWithIcon className="width320" icon={ImgBuilder.contentIcon}>
                      <CustomInput
                        placeholder="Content Name"
                        name="name"
                        id="content"
                        className={classNames('simpleInput', 'width320')}
                      />
                    </InputWithIcon>
                    <div className={`switcher_wrapper MuiFormLabel-root ${abilityEditPrice ? 'switcher_disabled' : ''}`}>
                      <FormSwitcherDarkViolet
                        checked={formSwitcherPurchaseValue}
                        disabled={abilityEditPrice}
                        onChange={(e) => {
                        const newState = e.target.checked
                        setFormSwitcherPurchaseValue(newState)
                        //setFormSwitcherSubscribersValue(newState)
                        setFieldValue("available_for_purchases", newState)
                      }
                      }/>
                      <span>Available for purchases</span>
                    </div>
                    {!user?.payouts_enabled && (
                      <div className="verifiedBlock">
                        Your account has not been Stripe verified. To post paid content,
                        please,&nbsp;
                        <span onClick={() => setOpenStripeDialog(true)}>verified</span>.
                        <Dialog
                          // openButton={<Button label="Verify" small width100 className="withdrawButton" />}
                          className="withdrawDialog"
                          defaultOpen={openStripeDialog}
                          onClose={() => setOpenStripeDialog(false)}
                        >
                          <StripeVerification />
                        </Dialog>
                      </div>
                    )}
                    {formSwitcherPurchaseValue && <InputWithIcon icon={ImgBuilder.coinsIcon} className="width320">
                      <Price
                        value={values.price}
                        placeholder="Price (USD)"
                        name="price"
                        id="price"
                        className={classNames('simpleInput', 'width320')}
                        disabled={abilityEditPrice}
                      />
                    </InputWithIcon>}
                    <div>
                      <div className={`switcher_wrapper MuiFormLabel-root ${lastGroup?.participants_count ? 'switcher_disabled' : ''}`}>
                        <FormSwitcherDarkViolet checked={formSwitcherSubscribersValue} onChange={(e) => {
                          const newState2 = e.target.checked
                          setFormSwitcherSubscribersValue(newState2)
                          setFieldValue("available_for_subscribers", newState2)
                          }}
                          disabled = {lastGroup?.participants_count}
                        />
                        <span>Available for subscribers</span>
                        {!canBuyBySubscribe && <div className = "subscribersSwitcherOverlay" onClick={() => {setTouchedSubscribersSwitcher(true)}}/>}
                      </div>
                      {(!canBuyBySubscribe && touchedSubscribersSwitcher) ?
                        <p className = "error MuiFormHelperText-root Mui-error MuiFormHelperText-filled subscribersSwitcherError" style ={{maxWidth: "320px"}}>
                          In order to create Subscription Content, you should have a package in the selected community
                        </p>
                        : null
                      }
                    </div>
                    <InputWithIcon icon={ImgBuilder.menudots_darker_blue} className="width320">
                      <CustomSelectMultiple
                        action = {isEdit ? "edit" : false}
                        label = "Community"
                        name="community_id"
                        data={moderatorListData}
                        className={classNames('simpleInput', 'width320', 'select')}
                        customChange={(tab) => {
                          setSelectedCommunity(tab)
                          setFieldValue('community_id', tab)
                        }}
                        distribution_groups = {distribution_groups?.distribution_groups ? distribution_groups?.distribution_groups : false}
                       // defValue = {isEdit ? selectRecord?.communities : [{id:community}]}
                        defDistGroupId = {selectRecord?.distribution_group?.id }
                        setFieldValue = {setFieldValue}
                        disabled={!!selectRecord}
                        setDistributionGroupSelected = {setDistributionGroupSelected}
                        setSelectedCommunities = {setSelectedCommunity}
                      />
                    </InputWithIcon>
                    <InputWithIcon icon={ImgBuilder.contentDesc} className="width320">
                      <CustomInput
                        placeholder="Content Description"
                        name="description"
                        id="content_desc"
                        className={classNames('simpleInput', 'width320')}
                      />
                    </InputWithIcon>    
                  </div>
                  <div className="uploda_video_content_data_cover">
                    <UploadImage
                      image={values.image}
                      disabled={!!selectRecord}
                      image_url={values.image}
                      setImage={(e) => setFieldValue('image', e)} />
                    <div className="error">
                      {' '}
                      <ErrorMessage name="image" />
                    </div>
                  </div>
                </div>
                {isEdit?
                  <div className="upload_videos">{renderExistingVideos({
                    files: values.files,
                    setFiles: (e) => setFieldValue("files", e)
                  })}
                  </div> :
                <div className="upload_videos">
                  <UploadVideos
                    files={values.files}
                    setFiles={(e) => setFieldValue('files', e)}
                    loadProgress={isValid ? uploadProgress : null}
                    error={sizeError}
                    setError={(e) => setSizeError(e)}
                    isValid={isValid}
                    record_type={record_type}
                  />
                  <div className="error">
                    {sizeError ? sizeError : <ErrorMessage name="files" />}
                  </div>
                </div>
                }
                <div className="upload_video_content_buttons">
                  {
                    isEdit?
                      <Button type="submit" label="Update" violetGradien disabled={!isValid} />:
                      <Button type="submit" label="Create" violetGradien disabled={!isValid || sizeError} />
                  }
                  <Button label="Cancel" className="upload_video_content_cancel" onClick={() => history.goBack()} />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default inject('mediaModel', 'userModel', "subscribePackageModel", 'communityModel', 'groupsModel' )(observer(CreateVideoContent));
