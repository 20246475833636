import React, { memo } from 'react';
import './Followers.scss';

const Followers = ({ folowers_amount, style }) => {
 
const getRoundedAmount = () => {
  return folowers_amount <=999 ? folowers_amount : `${Math.floor(folowers_amount/1000)}k`;
}

  return (
    <div className= "followers_amount_wrapper">
      <div className="text">
          {getRoundedAmount()} followers
      </div>
    </div>
  )
};

export default memo(Followers);
