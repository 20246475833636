import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const CustomButtonGroup = props => {
  const { buttonsList, active, changeActive, withDistributionGroups } = props;
  const returnButton = key => <Button
    classes={{ 
      root: active === key ? 'activeButton' : 'groupButton', 
      label: active === key ? 'activeButtonText' : 'buttonText',
    }}
    onClick={() => changeActive(key)}
    key={key}
  >
    {key}
  </Button>
  
  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group" className={`buttonGroup ${buttonsList.length === 4 && "buttonGroupFour"}`}>
      {buttonsList.map(key => returnButton(key))}
    </ButtonGroup>
  )
}

export default CustomButtonGroup;
