import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import CurrentAvatar from '../CurrentAvatar';
import CloseRoundIcon from '../../res/iconComp/CloseRound';
import ArrowBackIcon from '../../res/iconComp/ArrowBack';

import { returnName } from '../../units/func';
import './attendees.scss';

const AttendeesList = props => {
  const {
    hideAttendees,
    groupId,
    groupsModel: { getGroupParticipants, setParticipants, groupParticipants, getSingleGroupData, lastGroup, setLastGroup },
    inviteMembersModel: { deleteInvites },
  } = props;

  useEffect(() => {
    if (groupParticipants?.id != groupId) {
      getGroupParticipants(groupId);
    }
  }, [groupParticipants]);

  useEffect(() => {
    if (!lastGroup || lastGroup?.id != groupId) {
      getSingleGroupData(groupId);
    }
  }, [lastGroup]);

  const closeList = () => {
    setParticipants([]);
    setLastGroup(null);
    hideAttendees();
  }

  const guests = [...groupParticipants?.participants.filter(user => user.speaker), ...lastGroup?.invites.filter(user => user.speaker)];
  const members = [...groupParticipants?.participants.filter(user => !user.speaker), ...lastGroup?.invites.filter(user => !user.speaker)];

  const renderUser = user => {
    const userName = returnName(user);
    return (
      <div className="userItem" key={user?.id}>
        <CurrentAvatar 
          alt={userName}
          src={user?.image?.url}
          className='attendeesAvatar'
        />
        <div className="title">{userName ? userName : user?.email}</div>
        {user.hasOwnProperty('registered') && (
          <div className="removeInvite" onClick={() => deleteInvites(groupId, [user?.id])}>
            <CloseRoundIcon />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="attendeesListBlock">
      <div className="attendeesListClose" onClick={closeList}>
        <ArrowBackIcon />&nbsp;&nbsp;&nbsp;Attendees
      </div>
      <div className="list">
         <div style={{fontWeight:"bolder"}}>Guests:</div>
        {guests.length && guests.map(user => renderUser(user))}
        <div style={{marginTop:"20px", fontWeight:"bolder"}}>Members:</div>
        {members.length && members.map(user => renderUser(user))}
      </div>
    </div>
  );
}

export default inject('inviteMembersModel', 'groupsModel', )(observer(AttendeesList));
