import React, { useState, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Calendar from 'react-calendar';
import moment from 'moment';

import CalendarSimpleIcon from '../../res/iconComp/CalendarSimpleIcon';

import './datePicker.scss';
import 'react-calendar/dist/Calendar.css';

const formats = [
  "DD-MM-YYYY", "MM-DD-YYYY", 'YYYY-MM-DD', 'YYYY-DD-MM',
  "DD MM YYYY", "MM DD YYYY", 'YYYY MM DD', 'YYYY DD MM',
  "DD/MM/YYYY", "MM/DD/YYYY", 'YYYY/MM/DD', 'YYYY/DD/MM',
  "DD.MM.YYYY", "MM.DD.YYYY", 'YYYY.MM.DD', 'YYYY.DD.MM',
  "DD,MM,YYYY", "MM,DD,YYYY", 'YYYY,MM,DD', 'YYYY,DD,MM',
  "DD-MMM-YYYY", "MMM-DD-YYYY", 'YYYY-MMM-DD', 'YYYY-DD-MMM',
  "DD MMM YYYY", "MMM DD YYYY", 'YYYY MMM DD', 'YYYY DD MMM',
  "DD/MMM/YYYY", "MMM/DD/YYYY", 'YYYY/MMM/DD', 'YYYY/DD/MMM',
  "DD.MMM.YYYY", "MMM.DD.YYYY", 'YYYY.MMM.DD', 'YYYY.DD.MMM',
  "DD,MMM,YYYY", "MMM,DD,YYYY", 'YYYY,MMM,DD', 'YYYY,DD,MMM',
  "MMMM-DD-YYYY", "DD-MMMM-YYYY", 'YYYY-MMMM-DD', 'YYYY-DD-MMMM', 
  "MMMM DD YYYY", "DD MMMM YYYY", 'YYYY MMMM DD', 'YYYY DD MMMM',
  "MMMM/DD/YYYY", "DD/MMMM/YYYY", 'YYYY/MMMM/DD', 'YYYY/DD/MMMM',
  "MMMM.DD.YYYY", "DD.MMMM.YYYY", 'YYYY.MMMM.DD', 'YYYY.DD.MMMM',
  "MMMM,DD,YYYY", "DD,MMMM,YYYY", 'YYYY,MMMM,DD', 'YYYY,DD,MMMM',
  "MM-DD-YY", "DD-MM-YY", 'YY-MM-DD', 'YY-DD-MM',
  "MM DD YY", "DD MM YY", 'YY MM DD', 'YY DD MM',
  "MM/DD/YY", "DD/MM/YY", 'YY/MM/DD', 'YY/DD/MM',
  "MM.DD.YY", "DD.MM.YY", 'YY.MM.DD', 'YY.DD.MM',
  "MM,DD,YY", "DD,MM,YY", 'YY,MM,DD', 'YY,DD,MM',
  "MMM-DD-YY", "DD-MMM-YY", 'YY-MMM-DD', 'YY-DD-MMM',
  "MMM DD YY", "DD MMM YY", 'YY MMM DD', 'YY DD MMM',
  "MMM/DD/YY", "DD/MMM/YY", 'YY/MMM/DD', 'YY/DD/MMM',
  "MMM.DD.YY", "DD.MMM.YY", 'YY.MMM.DD', 'YY.DD.MMM',
  "MMM,DD,YY", "DD,MMM,YY", 'YY,MMM,DD', 'YY,DD,MMM',
  "MMMM-DD-YY", "DD-MMMM-YY", 'YY-MMMM-DD', 'YY-DD-MMMM',
  "MMMM DD YY", "DD MMMM YY", 'YY MMMM DD', 'YY DD MMMM',
  "MMMM/DD/YY", "DD/MMMM/YY", 'YY/MMMM/DD', 'YY/DD/MMMM',
  "MMMM.DD.YY", "DD.MMMM.YY", 'YY.MMMM.DD', 'YY.DD.MMMM',
  "MMMM,DD,YY", "DD,MMMM,YY", 'YY,MMMM,DD', 'YY,DD,MMMM',
];

const DatePicker = props => {
  const { 
    label, 
    onChange,
    onInput,
    date, // = moment().toDate() 
    format = "DD MMM YYYY",
    showIcon = true,
    className = '',
    minDate,
    maxDate,
    ...rest 
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const defaultValue = date ? moment(date).format(format) : 'DD.MM.YYYY';
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const defaultValue = date ? moment(date).format(format) : 'DD.MM.YYYY';
    setValue(defaultValue);
  }, [date]);

  const handleClick = (event) => {
    if (!!onInput) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleBlur = event => {
    const currentValue = moment(event.target.value, formats).toDate();
    if (currentValue !== 'Invalid Date') {
      if (currentValue <= maxDate && currentValue >= minDate ) {
        onChange(currentValue);
      } else {
        setValue(moment(date).format(format));
      }
    } else {
      setValue('DD.MM.YYYY');
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="datePickerComponent">
      {showIcon && <CalendarSimpleIcon />}
      <FormControl classes={{ root: className }}>
        <InputLabel htmlFor="date-picker" className={showIcon ? 'withIcon' : 'withOutIcon'}>{label}</InputLabel>
        <Input
          type='text'
          value={value}
          onClick={handleClick}
          classes={{ root: showIcon ? 'withIcon' : 'withOutIcon' }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="open calendar dialog"
                onClick={handleClick}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </InputAdornment>
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormControl>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Calendar
          onChange={date => {
            onChange(date);
            handleClose();
          }}
          value={date}
          className="popapCalendar"
          minDate={minDate}
          maxDate={maxDate}
          {...rest}
        />
      </Popover>
    </div>
  )
}

export default DatePicker;
