import React, { memo } from 'react';

const SelectedIcon = props => {
  const { color = '#43425D' } = props;

  return (
    <div style = {{border: '1px solid #43425D', borderRadius: '50%', width: '15px', height: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style = {{backgroundColor: "#43425D", borderRadius: '50%', width: '9px', height: '9px'}}></div>
    </div>
  )
}

export default memo(SelectedIcon);
