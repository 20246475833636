import React, { memo } from 'react';
import Dialog from '../Dialog';
import BuyVideoContent from './BuyVideoContent';

import './buyVideo.scss';

const BuyVideo = ({ children, ...rest }) => {
  return (
    <div className = "buy_video_wrapper">
      <Dialog
        className="buy_video_dialog"
        closeColor="#43425D"
        openButton={children}
      >
        <BuyVideoContent {...rest}/>
      </Dialog>
    </div>
  );
}

export default memo(BuyVideo);
