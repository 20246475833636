import React, { cloneElement } from 'react';
import classNames from 'classnames';

import "./input.scss"

const InputWithIcon = props => {
  const { icon, className = '', children, onClick = () => {} } = props;

  return (
    <div className={classNames('input_with_icon', className)} onClick={onClick}>
      <img src={icon} alt={icon}/>
      {children}
  </div>
  );
};

export default InputWithIcon;
