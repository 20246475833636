import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import Button from '../../Button';

import './style.scss';

const defaultRange = {from: 1, to: 12}
const MeetingsCount = props => {
  const { value: count, setCount, title, className, close, minMeetings, range = defaultRange } = props;
  const [newCount, setNewCount] = useState(count);
  const [list, setList] = useState([])
  useEffect(() => {
    const newList = []
    let i = range.from
    while (i <= range.to){
      newList.push(i++)
    }
    setList(newList)
  }, [range])

  const renderCountList = () => {
    return list.map(val => (
      <Button
        className={classNames("countItem", { active: val == newCount })}
        onClick={() => setNewCount(val)}
        key={`number${val}`}
        disabled={minMeetings > val && val != newCount}
        label={val}
      />
      //   {val}
      // </Button>
    ))
  };

  return (
    <div className={className}>
      <div className="dialogTitle">{title}</div>
      <div className="countBlock">
        {renderCountList()}
        <Button violetGradien width100 label="OK" onClick={() => { setCount(newCount); close(); }} />
      </div>
    </div>
  )
}

export default MeetingsCount;
