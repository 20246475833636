import React, {memo, useState} from 'react';
import classNames from "classnames";

//components
import Notifications from '../Notifications/notifications';
import SharePopupBuilder from "./sharePopupContent/sharePopupBuilder";

//style
import './sharePopup.scss';

const SharePopup = props => {
	const { children, showByClick = false, ...rest } = props;
	const [showNotification, setShowNotification] = useState(false)
	const [notificationType, setNotificationType] = useState("");
	const [show, setShow] = useState(false)

	const toggleNotification= (type) => {
		setNotificationType(type);
		setShowNotification(!showNotification);
		if (type === 'linkCopied') {
			setTimeout(() =>
				setShowNotification(false), 3000
			)
		}
	}

  const handleShow = (e) => {
	  e.stopPropagation()
    showByClick && setShow(true)
  }
  const handleHide = () => {
	  setShow(false)
  }

	return (
		<div className="share-popup-wrapper">
			<div
				className={showByClick? classNames('share-popup-children-wrapper_click', {show}): "share-popup-children-wrapper"}
        onClick={handleShow}
			>
				{children}
				<SharePopupBuilder
					toggleNotification={toggleNotification}
					key={props.url}
          handleHide={show && handleHide}
					{...rest}
				/>
			</div>
			{showNotification && <Notifications
        		id={notificationType}
				toggleNotification={(type) => toggleNotification(type)}
				showNotification={showNotification}
			/>}
		</div>
	)
};

export default memo(SharePopup);
