import React, {useEffect, useRef, useState} from 'react';
import classNames from "classnames/bind";

import SubscriptionsPackagesCard from "./SubscriptionsPackagesCard";
import {SubscriptionsPackagesCardAdd} from "./SubscriptionsPackagesCardAdd";

import './subscriptionsPackages.scss';
import CustomModal from "../../../../components/Modals/Modal";
import BuySubscriptionsPackagePopup from "./BuySubscriptionsPackagePopup";
import CustomSneckbar from "../../../../components/CustomSnackbar";
import {inject, observer} from "mobx-react";
import UnsubscribePopup from "../../../../components/UnsubscribePopup";
import ChangeSubscriptionPopup from "../../../../components/ChangeSubscriptionPopup";
import {maxSubscriptionsCount} from "../../../../const/general";

const PackageBlock = props => {
    const {data, subscriptionPage, customWidth, className, classNameCard, mmData,
      detailsSubscription=false,
      subscribePackageModel:{requestResultMessage},
    } = props;
    const cardsBlockRef = useRef(null)
    const mainClassName = classNames('subscriptionsPackagesCardList row', className);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
    const [confirmedChange, setConfirmedChange] = useState(false)
    const [additionalPrice, setAdditionalPrice] = useState(0)
    
    const [customStyleForCard, setCustomStyleForCard] = useState({flex: "1 0 33%"})

    const handleOpenModal = (id) => {
      if (!id){
        setAdditionalPrice(0)
        setConfirmedChange(false)
      }
      setIsOpenModal(id)
    }
    const handleOpenCancelModal = (id) => setIsOpenCancelModal(id)
    const current = data.find( (pack) => pack.current)
    
    useEffect(() => {
      if(cardsBlockRef?.current && cardsBlockRef?.current.children.length){
        let heightArr = [...cardsBlockRef?.current.children].map(e =>e.clientHeight)
        let copyCustomStyleForCard = {...customStyleForCard}
        copyCustomStyleForCard.minHeight = Math.max(...heightArr)
        copyCustomStyleForCard.maxWidth = cardsBlockRef?.current.children[0].clientWidth
        setCustomStyleForCard(copyCustomStyleForCard)
      }
    },[cardsBlockRef, data])

  return (
        <div className={mainClassName} ref = {cardsBlockRef}>
            {data.map(card => (
                <SubscriptionsPackagesCard
                    key={card.id}
                    className={classNameCard}
                    customWidth={customWidth}
                    subscriptionPage={subscriptionPage}
                    toggleBuySubscriptionPopup={handleOpenModal}
                    toggleCancelSubscriptionPopup={handleOpenCancelModal}
                    detailsSubscription={detailsSubscription}
                    {...card}
                    customFlexStyle = {customStyleForCard}
                />
            ))}
            {subscriptionPage && data?.length < maxSubscriptionsCount && <SubscriptionsPackagesCardAdd className='pageHeight' customFlexStyle = {customStyleForCard}/>}
          <CustomModal isOpen={!!isOpenModal} handleClose={handleOpenModal} closeColor={'#FE1E92'} customWidth={current && !confirmedChange? "660px": null}>
            {current && !confirmedChange? <ChangeSubscriptionPopup
                setConfirmedChange={setConfirmedChange}
                close={handleOpenModal}
                packageData={isOpenModal}
                current={current}
                setAdditionalPrice={setAdditionalPrice}
                mmData={mmData}/>
              :
              <BuySubscriptionsPackagePopup
                close={handleOpenModal}
                packageData={isOpenModal}
                mmData={mmData}
                additionalPrice={additionalPrice}
              />}
          </CustomModal>
          <CustomModal isOpen={!!isOpenCancelModal} handleClose={handleOpenCancelModal} customWidth={"660px"}>
            <UnsubscribePopup close={handleOpenCancelModal} packageData={isOpenCancelModal} mmData={mmData} />
          </CustomModal>
          {requestResultMessage && <CustomSneckbar
            message={requestResultMessage}
            onClose={() => {
            }}
            severity={requestResultMessage === 'Success' ? "success" : 'error'}
          />}
        </div>
    )
}

export default inject("subscribePackageModel")(observer(PackageBlock));


