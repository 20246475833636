import { observable, decorate, toJS } from 'mobx';
import axios from 'axios';
import { baseAPI, socketAPI } from '../const/settings';
import { authModel } from './auth';

export class EnablexModel {
  constructor() {}

  getGroupCredential = group => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    if (!localStorage.getItem('socketAPI')) {
      localStorage.setItem('socketAPI', socketAPI);
    }
    if (!localStorage.getItem('baseAPI')) {
      localStorage.setItem('baseAPI', baseAPI);
    }

    axios.post(`${baseAPI}groups/${group.id}/credentials`, '', headers)
        .then(function (response) {
          if (response.data.access_token !== null) {
            window.location.href = `https://meet.ascend.video?token=${response.data.access_token}`
          }
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }
}

decorate(EnablexModel, {
  showMasterMindMenu: observable
})

export const enablexModel = new EnablexModel();
