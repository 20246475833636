import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { timeToStartMetting } from '../../units/func';

import { groups_create } from '../../const/urlKeys';
import {
  tabMy,
  gtSupport,
  gtMasterMind,
  gtWebinar,
  actionDuplicate,
  actionEdit, gtRecurring, gtRecurringWebinar
} from '../../const/general';
import colors from '../../const/colors'

const useStyles = makeStyles((btnsQuantity) => ({
  root: {
    display: 'grid',
    alignItems: 'center',
    // justifyContent: 'space-around',
    gridTemplateColumns:`repeat(auto-fit, minmax(23%, 1fr))`,
    columnGap: '5px',
    background: '#E9E9F0',
    padding: '20px 10px !important',
    width: '100%',
    position: 'relative',
    height: '80px',
    marginTop: 10,
    marginBottom: 10
  },
}));

const MainButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 15,
    padding: '6px 5px',
    border: "none",
    outline: "none",
    lineHeight: 1.5,
    borderRadius: 4,
    height: '100%'
  }
})(Button)

const RedButton = withStyles({
  root: {
    color: '#fff',
    boxShadow: '0px 2px 5px rgba(255, 45, 85, 0.5)',
    background: 'linear-gradient(2.31deg, #FF2D55 1.96%, #F77890 102.09%)'
  }
})(MainButton);

const GreyButton = withStyles({
  root: {
    color: '#fff',
    boxShadow: '0px 2px 5px rgba(168, 167, 188, 0.5)',
    background: 'linear-gradient(2.31deg, #9DA4B3 1.96%, #BAC0CF 102.09%)',
  },
})(MainButton);

const BlueButton = withStyles({
  root: {
    color: '#fff',
    boxShadow: '0px 2px 5px rgba(0, 122, 255, 0.5)',
    background: 'linear-gradient(2.31deg, #007AFF 1.96%, #4C9FFA 102.09%)',
  },
})(MainButton);

const GreenButton = withStyles({
  root: {
    color: '#fff',
    boxShadow: '0px 2px 5px rgba(138, 219, 195, 0.5)',
    background: 'linear-gradient(2.31deg, #6BB7A0 1.96%, #8ADBC3 102.09%)',
  },
})(MainButton);

const WhiteButton = withStyles({
  root: {
    color: '#8ADBC3',
    boxShadow: '0px 2px 5px rgba(138, 219, 195, 0.5)',
    background: '#fff',
  },
})(MainButton);

const BlackButton = withStyles({
  root: {
    color: '#fff',
    boxShadow: '0px 2px 5px #000000',
    background: 'linear-gradient(2.31deg, #000000 1.96%, #605A5B 102.09%)',
  },
})(MainButton);

const GroupActions = (props) => {
  const classes = useStyles();
  const { group, showClosed, showDelete, showCancel, setEditGroup, tab } = props;
  const isMobile = window.innerWidth <= 425
  let timeToEvent = timeToStartMetting(group?.start_time);
  const btnStyle = {
    border: "none",
    outline: "none",
  };

  const groupTypeGroup = group.group_type === gtSupport;
  const groupTypeCoaching = group.group_type === gtMasterMind;
  const groupTypeLiveStream = group.group_type === gtWebinar;
  const groupTypeRecurringLiveStream = group.group_type === gtRecurringWebinar;
  const groupTypeRecurring = group.group_type === gtRecurring;

  const noAttendees = group.participants_count === 0
  const hasAttendees = group.participants_count !== 0

  const groupEnded = group.status === "canceled" || group.status === "ended"
  const groupStarted = group.status === "started"
  const groupsUpcoming = group.status === "upcoming" || group.status === "active"
  const gruopGlobalStarted = group.status === "started" || group.past_meetings_count > 0

  const meetingRecords = group.hasOwnProperty('meetings_records')
  const betweenMeetings = meetingRecords ? !group.meetings_records.every(meeting => meeting.ended) : group.past_meetings_count && group.past_meetings_count < group.meetings_count

  const renderClose = (width = 'auto') => {
    const status = group.private ? 'Opened' : 'Closed';
    const label = !isMobile ? `Make ${status}` : status;
    btnStyle.width = width;
    if (group.closed) return  null;

    if (group.private) {
      return (
        <WhiteButton style={btnStyle} onClick={showClosed}>
          {label}
        </WhiteButton>
      )
    }
    return (
      <GreenButton style={btnStyle} onClick={showClosed}>
        {label}
      </GreenButton>
    )
  }

  const renderDuplicate = (width = 'auto') => {

    return (
      <Link
        style={{'height': '100%',
          'display': 'flex',
          'justifyContent': 'center',
          'textDecoration': 'none'
        }}
        to={location => {
          return ({ ...location, pathname: groups_create, state: { action: actionDuplicate } })
        }}
        onClick={_=> setEditGroup(group)}
      >
        <GreyButton style={{...btnStyle, width}}>Duplicate</GreyButton>
      </Link>
    )
  }

  const renderEdit = (width = 'auto') => {

    return (
      <Link
        style={{'height': '100%',
          'display': 'flex',
          'justifyContent': 'center',
          'textDecoration': 'none'
        }}
        to={location => {
          return ({ ...location, pathname: groups_create, state: { action: actionEdit } })
        }}
        onClick={_=> setEditGroup(group)}
      >
        <BlueButton style={{...btnStyle, width}}>Edit</BlueButton>
      </Link>
    )
  }

  const renderCancel = (width = 'auto') => {
    // btnStyle.width = width
    return <BlackButton style={btnStyle} onClick={showCancel}>Cancel</BlackButton>
  }

  const renderDelete = (width = 'auto') => {
    // btnStyle.width = width
    return <RedButton style={btnStyle} onClick={showDelete}>Delete</RedButton>
  }

  if (tab == tabMy) return null

  if (groupEnded) {
    return (
      <div className={classes.root}>
        {renderDuplicate(140)}
      </div>
    )
  }

  if ((groupTypeCoaching || groupTypeRecurring) && gruopGlobalStarted) { // gruopGlobalStarted groupStarted
    const btnWidth = '100%'//'auto';

    return (
      <div className={classes.root}>
        {groupsUpcoming && renderCancel(btnWidth)}
        {renderDuplicate(btnWidth)}
        {renderEdit(btnWidth)}
        {renderClose(btnWidth)}
      </div>
    )
  }

  if ((groupTypeGroup || groupTypeLiveStream || groupTypeRecurringLiveStream) && gruopGlobalStarted && !betweenMeetings) { // gruopGlobalStarted groupStarted
    return (
      <div className={classes.root}>
        {renderDuplicate()}
      </div>
    )
  }

  if ((groupTypeGroup) && betweenMeetings) {
    const btnWidth = '100%'//'auto';

    return (
      <div className={classes.root}>
        {renderCancel(btnWidth)}
        {renderDuplicate(btnWidth)}
        {renderEdit(btnWidth)}
      </div>
    )
  }

 
  if ((groupTypeLiveStream || groupTypeCoaching || groupTypeRecurringLiveStream) && betweenMeetings && gruopGlobalStarted && noAttendees ) {
    const btnWidth = '100%'//'auto';

    return (
      <div className={classes.root}>
        {groupsUpcoming && renderCancel(btnWidth)}
        {renderDuplicate(btnWidth)}
        {renderEdit(btnWidth)}
      </div>
    )
  }

  if ((groupTypeCoaching || groupTypeRecurring) && noAttendees) { // && !gruopGlobalStarted
    const btnWidth = '100%'//'auto';
    return (
      <div className={classes.root}>
        {renderDelete(btnWidth)}
        {renderDuplicate(btnWidth)}
        {(group.status === 'upcoming' || group.status === 'active') && renderEdit(btnWidth)}
        {renderClose(btnWidth)}
      </div>
    )
  }

  if ((groupTypeCoaching || groupTypeRecurring ) && hasAttendees) { // && !gruopGlobalStarted
    const btnWidth = '100%'//'auto';
    return (
      <div className={classes.root}>
        {groupsUpcoming && renderCancel(btnWidth)}
        {renderDuplicate(btnWidth)}
        {(group.status === 'upcoming' || group.status === 'active') && renderEdit(btnWidth)}
        {renderClose(btnWidth)}
      </div>
    )
  }

  if ((groupTypeGroup || groupTypeLiveStream || groupTypeRecurring || groupTypeRecurringLiveStream)  && !gruopGlobalStarted && noAttendees) { // && !gruopGlobalStarted
    const btnWidth = '100%'//'auto';

    return (
      <div className={classes.root}>
        {renderDelete(btnWidth)}
        {renderDuplicate(btnWidth)}
        {renderEdit(btnWidth)}
      </div>
    )
  }

  if ((groupTypeGroup || groupTypeLiveStream || groupTypeRecurring || groupTypeRecurringLiveStream)
    && hasAttendees) { // && !gruopGlobalStarted
    const btnWidth = '100%'//'auto';

    return (
      <div className={classes.root}>
        {groupsUpcoming && renderCancel(btnWidth)}
        {renderDuplicate(btnWidth)}
        {renderEdit(btnWidth)}
      </div>
    )
  }

  return null
}

export default GroupActions;
