import React from "react";
import TimePicker from "../../TimePicker";
import CustomButton from "../../Button";

const CustomTimePicker = (props) => {
  const {
    classes = '',
    value,
    close = () => {},
    onClick = () => {}} = props
  return (
    <div className={classes}>
      <div className={'title'}>New Time</div>
      <TimePicker
        value={value}
        onClick={onClick}
        name="time"
      />
      <CustomButton
        violetGradien
        label="OK"
        onClick={close}
      />
    </div>
  )
}

export default CustomTimePicker