import React, { useEffect, useState, useRef } from "react";
import CustomButtonGroup from "../../components/UI/ButtonGroups";
import "./styles.scss";
import IconButton from "@material-ui/core/IconButton";
import SearchSimple from "../../res/icons/search.svg";
import ClearAll from "../../res/icons/clearAll.svg";
import GeneralItem from "./GeneralItem";
import OrganizationsItem from "./OrganizationsItem";
import InfluencersItem from "./InfluencersItem";
import DistributionGroupItem from "./DistributionGroupItem";
import CustomModal from "../../components/Modals/Modal";
import JoinRequestPopup from "../../components/JoinRequestPopup";
import { inject, observer } from "mobx-react";
import { ctGeneral, ctInfluencer, ctOrganization, ctDistributionGroups} from "../../const/general";
import Button from "../../components/Button";
import moment from "moment";
import { externalChooseCommunity } from "../../const/urlKeys";
import { communityTypesLabels } from "../../const/communityList";
import ChooseCommunityColumn from "./ChooseCommunityColumn";
import CategoryFilter from "./CategoryFilter";
import Dialog from "../../components/Dialog";
import classNames from "classnames";
import privateIcon from "../../res/icons/private.svg";
import ShareCommunityBtn from "../../components/ShareButton/shareCommunityBtn";
import categoryNameIcon from "../../res/icons/categoryName.svg";
import membersIcon from "../../res/icons/members.svg";
const img = "https://icon-library.com/images/user-group-icon/user-group-icon-14.jpg";

const ChooseCommunityBlock = (props) => {
  const {
    firstLogin = false,
    invitedId: inviteTo,
    communityModel: {
      communityList,
      selectCommunity,
      unselectCommunity,
      sendRequestToJoinToCommunity,
      distribution_groups,
      getCommunityCategoriesList,
      communityCategoriesList,
    },
    userModel: { community, user },
    answersModel: { setAnswers },
  } = props;
  const currentUserId = user?.id;
  var buttonsList = [];

  if(distribution_groups?.distribution_groups?.length){
    buttonsList = [
      communityTypesLabels.general,
      communityTypesLabels.organization,
      communityTypesLabels.influencer,
      communityTypesLabels.distributionGroups
    ];
  }else{
    buttonsList = [
      communityTypesLabels.general,
      communityTypesLabels.organization,
      communityTypesLabels.influencer
    ];
  }

  const [communityType, seCommunityType] = useState(ctGeneral);
  const [viewBlock, setViewBlock] = useState(buttonsList[0]);
  const [filter, setFilter] = useState("");
  const [displayedList, setDisplayedList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [invitedId, setInvitedId] = useState(inviteTo);
  const [distGroupActive, setDistGroupActive] = useState({});
  const ref = useRef(null);
  const [displayedArr, setDisplayedArr] = useState([]);
  const [countColumn, setCountColumn] = useState();
  const [width, setWidth] = useState();
  const [isAttendOpen, setAttendOpen] = useState(true);

  const handleResize = () => {
    if (ref && displayedList?.length) {
      const screenWidth = ref.current.clientWidth;
      setWidth(screenWidth);
      let size;

      if(screenWidth >= 1600) {
        size = 4;
      } else if(screenWidth < 1600 && screenWidth >= 1200) {
        size = 3;
      } else if(screenWidth < 1200 && screenWidth >= 800) {
        size = 2;
      } else  if(screenWidth < 800) {
        size = 1;
      }
      setCountColumn(size);
      getSubarray(displayedList, size);
    }
  }

  useEffect(() => {
    getCommunityCategoriesList();
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [displayedList]);

  const getSubarray = (arr, size) => {
    const result = [];
    const copyArr = [...arr];
    const everyNth = (ar, n) => ar.filter((e, i) => i % n === 0);

    for (let i = 0; i < size; i++) {
      result.push(everyNth(copyArr, size));
      copyArr.shift();
    }
    console.log(result);
    setDisplayedArr(result);
  };

  useEffect(() => {
    !inviteTo && setViewBlock(communityTypesLabels.general);
    setInvitedId(inviteTo);

    return () => {
      localStorage.removeItem("community_invite_id");
    };
  }, [inviteTo]);

  useEffect(() => {
    if (!invitedId) return;
    const community = communityList.mappedCommunities[invitedId];
    community && setViewBlock(communityTypesLabels[community.community_type]);
  }, [communityList, invitedId]);

  useEffect(() => {
    switch (viewBlock) {
      case communityTypesLabels.general:
        seCommunityType(ctGeneral);
        break;
      case communityTypesLabels.organization:
        seCommunityType(ctOrganization);
        break;
      case communityTypesLabels.influencer:
        seCommunityType(ctInfluencer);
        break;
      case communityTypesLabels.distributionGroups:
        seCommunityType(ctDistributionGroups);
        break;
    }
  }, [viewBlock]);

  useEffect(() => {
    setDisplayedList(getDisplayedList());
  }, [communityList, communityType]);

  const getDisplayedList = () => {
    let list = communityList.communities.filter(
        (c) => c.community_type === communityType
    );
    if(communityType === "distribution_group"){
      list = distribution_groups.distribution_groups
    }

    return list.filter((item) => item.title.toLowerCase().includes(filter.toLowerCase()));
  }

  const setSelecteddistGroups = () =>{
    if(distribution_groups?.distribution_groups){
      let firstrenderDistGroupActive = {}
      let distGrps = distribution_groups.distribution_groups;
      distGrps.map(grp => firstrenderDistGroupActive[grp.id] = !grp.communities.some(comm => !communityList.selected.includes(comm.id)));
      setDistGroupActive(firstrenderDistGroupActive)
    }
  }

  useEffect(() => {
    setSelecteddistGroups()
  }, [])
  const sendRequestPopup = (item) => {
    setIsOpenModal(item);
  };

  const handleSendRequestToJoinCommunity = ({ id }) => {
    sendRequestToJoinToCommunity({ id });
  };

  const filterCategory = (category) => {
    if(category.toLowerCase() === "filter communities by categories") {
      setDisplayedList(getDisplayedList());
    } else {
      const filterArr = getDisplayedList().filter(item => item.category?.name.toLowerCase() === category.toLowerCase());
      setDisplayedList(!filterArr.length ? [] : filterArr);
    }
  }

  // console.log('displayedList: ',displayedList)

  let aa = displayedList.filter(item =>  Number(item.id) === Number(invitedId))
  console.log(aa);



  const toggleSelected = (item) => {
    const id = item.id;
    if (item.private && item.hasOwnProperty("requested") && !item.requested) {
      return sendRequestToJoinToCommunity({ id });
    } else if (
        item.private &&
        item.hasOwnProperty("requested") &&
        item.requested
    ) {
      console.log("You request not accepted yet");
      return false;
    }
    if(ctDistributionGroups === communityType){
      let communitiesIds = item.communities.map(comm => comm.id);
      let currentDistGroupActive = checkIfSelected(item);

      if(currentDistGroupActive){
        unselectCommunity(communitiesIds);
        let copyDistGroupActive = {...distGroupActive}
        copyDistGroupActive[item.id] = false;
        setDistGroupActive(copyDistGroupActive);
      }else{
        selectCommunity(communitiesIds)
        let copyDistGroupActive = {...distGroupActive}
        copyDistGroupActive[item.id] = true;
        setDistGroupActive(copyDistGroupActive);
      }

    }else{
      if(item.selected || item.id === community){
        unselectCommunity([id]);
        checkIfSelectedDist(item, "delete");
      }else{
        selectCommunity([id]);
        checkIfSelectedDist(item, "add");
      }

    }
  };

  const clearFilter = () => {
    setFilter("");
  };

  const currentItem = (item) => {
    switch (communityType) {
      case ctGeneral:
        return (
            <GeneralItem
                item={item}
                sendRequest={handleSendRequestToJoinCommunity}
            />
        );
      case ctOrganization:
        return (
            <OrganizationsItem
                sendRequest={handleSendRequestToJoinCommunity}
                item={item}
            />
        );
      case ctInfluencer:
        return (
            <InfluencersItem
                sendRequest={handleSendRequestToJoinCommunity}
                item={item}
            />
        );
      case ctDistributionGroups:
        return (
            <DistributionGroupItem
                item = {item}
                communityList = {communityList}
            />
        )
        break
    }
  };

  const handleSetMainCommunity = (e) => {
    e.stopPropagation();
    const communities = communityList.selected
        .map((c) => communityList.mappedCommunities[c])
        .filter((c) => !!c.selected)
        .sort((next, prev) =>
            moment(next.selected_at).unix() - moment(prev.selected_at).unix()
                ? 1
                : -1
        );
    const mainCommunity = communities.length && communities[0].id;

    mainCommunity &&
    setAnswers({
      community_id: mainCommunity,
    });
  };

  const handleChangeTab = (data) => {
    setViewBlock(data);
    setInvitedId(null);
    localStorage.removeItem("community_invite_id");
  };

  const checkIfSelectedDist = (item, action) => {
    let distGrps = distribution_groups.distribution_groups;
    if(!item?.communities && action === "delete"){
      let result = {...distGroupActive};
      distGrps.map(grp => {
        if(result[grp.id]){
          result[grp.id] = !grp.communities.some(comm => item.id === comm.id)
        }
      });
      setDistGroupActive(result);
    }else if(!item?.communities && action === "add"){
      let result = {...distGroupActive};
      distGrps.map(grp => {
        result[grp.id] = !grp.communities.some(comm => !(communityList.selected.includes(comm.id) || comm.id === item.id || comm.id === community))
      });
      setDistGroupActive(result);
    }
  }
  const checkIfSelected = (item) =>{
    if(item?.communities){
      return !Object.keys(distGroupActive).length ?
          (communityList?.selected ? !item.communities.some(comm => !(communityList.selected.includes(parseInt(comm)) || parseInt(comm) === community)): false) :
          distGroupActive[item.id]
    }else{
      return item.selected || item.id === community;
    }
  }

  const addInfo = (item) => {
    if(item.private && !item.moderator && !item.selected && item.hasOwnProperty('requested') && !item.requested && !item.accepted ) {
      return  <div className="privateInfo">
        It's private organization. You need admin accept to join.
      </div>
    }

    if(item.private && !item.moderator && !item.selected && !item.hasOwnProperty('requested') && !item.hasOwnProperty('accepted') ) {
      return  <div className="privateInfo">
        It's private organization. You need admin accept to join.
      </div>
    }

    if(item.private && !item.moderator && !item.selected && item.hasOwnProperty('requested') && item.requested && !item.accepted ) {
      return  <div className="privateInfo">
        It's private organization. You need admin accept to join.
      </div>
    }
  };

  const changeBtn = (item) => {
    if(!item.private && item.selected ) {
      return <div className="btn btnLeave">Leave</div>
    }

    if(!item.private && !item.selected ) {
      return <div className="btn btnJoin">Join</div>
    }

    if(item.private && !item.selected && currentUserId === item.user_id) {
      return <div className="btn btnJoin">Join</div>
    }

    if(item.private && item.selected && currentUserId === item.user_id) {
      return <div className="btn btnLeave">Leave</div>
    }

    if(item.private && item.selected ) {
      return <div className="btn btnLeave">Leave</div>
    }

    if(item.private && !item.moderator && !item.selected && item.accepted) {
      return <div className="btn btnJoin">Join</div>
    }

    if(item.private && item.moderator && !item.selected) {
      return <div className="btn btnJoin">Join</div>
    }

    if(item.private && !item.moderator && !item.selected && item.hasOwnProperty('requested') && !item.requested && !item.accepted ) {
      return <div className="btn btnRequestAccess">Request Access</div>
    }

    if(item.private && !item.moderator && !item.selected && !item.hasOwnProperty('requested') && !item.hasOwnProperty('accepted') ) {
      return <div className="btn btnRequestAccess">Request Access</div>
    }

    if(item.private && !item.moderator && !item.selected && item.hasOwnProperty('requested') && item.requested && !item.accepted ) {
      return <div className="btn btnRequestSent">Request Sent</div>
    }
  }

  const handleBackdropClick = (event) => {
    //these fail to keep the modal open
    event.stopPropagation();
    return false;
  };

  const handleClose = (event, reason) => {
    console.log('reason: ', reason, event);
    if (reason === 'backdropClick') {
      console.log(reason);
    } else {
      setAttendOpen(true);
    }
  };


  return (
      <>
        <div className={"chooseCommunity"}>
          {/*<div style={{display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>*/}
          <div className={"chooseCommunity_title"}>Communities</div>
          {/*  <div className={"chooseCommunity_search"}>*/}
          {/*    <input*/}
          {/*      value={filter}*/}
          {/*      onChange={(e) => {*/}
          {/*        setFilter(e.target.value);*/}
          {/*      }}*/}
          {/*      placeholder={"Search"}*/}
          {/*    />*/}
          {/*    <IconButton*/}
          {/*      type="submit"*/}
          {/*      className="searchButton"*/}
          {/*      aria-label="search"*/}
          {/*    >*/}
          {/*      <img src={SearchSimple} alt="search" />*/}
          {/*    </IconButton>*/}
          {/*    {filter && (*/}
          {/*      <IconButton*/}
          {/*        type="submit"*/}
          {/*        className="clearAllButton"*/}
          {/*        aria-label="clearAll"*/}
          {/*        onClick={clearFilter}*/}
          {/*      >*/}
          {/*        <img src={ClearAll} alt="clearAll" />*/}
          {/*      </IconButton>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className={"chooseCommunity_btns"}>*/}
          {/*  <CustomButtonGroup*/}
          {/*      buttonsList={buttonsList}*/}
          {/*      active={viewBlock}*/}
          {/*      changeActive={handleChangeTab}*/}
          {/*      withDistributionGroups = {distribution_groups?.distribution_groups?.length ? true : false}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<CategoryFilter*/}
          {/*    width={width}*/}
          {/*    communityCategoriesList={communityCategoriesList}*/}
          {/*    countColumn={countColumn}*/}
          {/*    getDisplayedList={getDisplayedList}*/}
          {/*    filter={filter}*/}
          {/*    communityList={communityList}*/}
          {/*    communityType={communityType}*/}
          {/*    viewBlock={viewBlock}*/}
          {/*    filterCategory={filterCategory}/>*/}
          <div ref={ref} className={"chooseCommunity_list communityList"}>
            {/*{ !displayedList.length ?*/}
            {/*    <div style={{margin:"0 30px 30px"}}>List is empty!</div> :*/}
            <div style={{width:"100%", display:"flex"}}>
              {displayedArr.map((arr, index) =>
                  <ChooseCommunityColumn
                      key={index}
                      invitedId={invitedId}
                      arr={arr}
                      communityType={communityType}
                      externalChooseCommunity={externalChooseCommunity}
                      toggleSelected={toggleSelected}
                      changeBtn={changeBtn}
                      addInfo={addInfo}
                      countColumn={countColumn}
                  />
              )}

              {isAttendOpen && <Dialog
                  openButton={null}
                  className="ChooseCommunityColumn no-click-backdrop"
                  closeColor="#43425D"
                  onClose={handleClose}
                  defaultOpen={isAttendOpen}
                  disableEscapeKeyDown={true}
                  disableBackdropClick={true}
                  // onBackdropClick={handleBackdropClick}
              >
                <div>
                  {aa.map(item => {
                        const invited = Number(item.id) === Number(invitedId);

                        return (
                            <div key={item.id}
                                 className={classNames("communityList_item", {invited})}
                                 style={{padding: '20px'}}>
                              {/*<div className={invited ? "invitedBlock" : "invitedBlock hidden"}>*/}
                              {/*  <div className="invitedBlock_line">you're invited</div>*/}
                              {/*  <div className="invitedBlock_triangle"></div>*/}
                              {/*</div>*/}
                              <div className={item.selected ?
                                  classNames("communityList_itemSelected") : classNames("communityList_itemNotSelected", {invited})}>
                                <div className="cardTopBlock">
                                  <div className="imgWrapper">
                                    <img className="img"
                                         src={item.image?.thumbnail?.url || img} alt="icon"/>
                                  </div>
                                  <div style={{width: "100%"}}>
                                    <div className="infoBlock">
                                      <div className="infoBlock_title">
                                        {item.private && <img src={privateIcon} alt="private"/>}
                                        <div className="infoBlockText">{item.title}</div>
                                      </div>
                                      {/*<div>*/}
                                      {/*  {communityType !== "distribution_group" &&*/}
                                      {/*      <div className="communityList_itemShare">*/}
                                      {/*        {(*/}
                                      {/*            <div>*/}
                                      {/*              <ShareCommunityBtn*/}
                                      {/*                  item={item}*/}
                                      {/*                  inviteUrl={`${externalChooseCommunity}/${item.id}`}*/}
                                      {/*                  color={"#7E7D8F"}*/}
                                      {/*                  position={"right"}*/}
                                      {/*              />*/}
                                      {/*            </div>*/}
                                      {/*        )}*/}
                                      {/*      </div>*/}
                                      {/*  }*/}
                                      {/*</div>*/}
                                    </div>
                                    <div className="membersBlock">
                                      {item.category?.name && <div className="flex">
                                        <img src={categoryNameIcon} alt="categoryName"/>
                                        <div className="membersBlockText">{item.category?.name}</div>
                                      </div>}
                                      <div className="flex">
                                        <img src={membersIcon} alt="usersCount"/>
                                        <div className="membersBlockText">{item.users_count} members</div>
                                      </div>
                                    </div>
                                    <div onClick={() => {toggleSelected(item)}}>
                                      {changeBtn(item)}
                                    </div>
                                  </div>
                                </div>
                                <div className="cardBottomBlock">
                                  <div>
                                    {item?.description}
                                  </div>
                                  {addInfo(item)}
                                </div>
                              </div>
                            </div>
                        );
                      }
                  )}
                </div>
              </Dialog> }

            </div>
            {/*}*/}
          </div>

          {firstLogin && (
              <div className={"firstLoginBtn"}>
                <Button
                    label="Complete"
                    disabled={!communityList.selected.length}
                    violetGradien
                    onClick={handleSetMainCommunity}
                />
              </div>
          )}
        </div>
        <CustomModal isOpen={!!isOpenModal} handleClose={sendRequestPopup}>
          <JoinRequestPopup
              item={isOpenModal}
              type={communityType}
              handleClose={sendRequestPopup}
          />
        </CustomModal>
      </>
  );
};

export default inject(
    "communityModel",
    "userModel",
    "answersModel"
)(observer(ChooseCommunityBlock));
