import React from 'react';
import { inject, observer } from 'mobx-react';
import { Markup } from 'interweave';

import CircularProgress from '@material-ui/core/CircularProgress';

const TermsConditions = props => {
  const { pageModel: { terms_and_conditions } } = props;

  if (!terms_and_conditions) return <CircularProgress className="loader" />

  return (
    <div className="simplePage">
      <div className="title">Terms & Conditions</div>
      <Markup content={terms_and_conditions.content} />
    </div>
  )
}

export default inject('pageModel')(observer(TermsConditions));
