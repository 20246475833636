import React, {useState} from 'react';
import classNames from "classnames/bind";

import PlusBGIcon from '../../../../res/img/icons/arrow-bg.png';
import PlusIcon from '../../../../res/img/icons/plus.png';
import CustomModal from "../../../../components/Modals/Modal";
import CreatePackageModal from "../../../../components/Modals/CreatePackageModal";

import './subscriptionsPackages.scss';

export const SubscriptionsPackagesCardAdd = (props) =>{
    const {
        customFlexStyle = {}
    } = props
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleActionModal = () => {
        setIsOpenModal(!isOpenModal);
    };
    const mainClassName = classNames('subscriptionsPackagesCard',/* props.className, */'addPackagesCard');

    return(
        <div className="col-md-6" style={customFlexStyle}>
            <div className={mainClassName} onClick={handleActionModal}>
                <div className="d-flex align-items-center justify-content-center" style = {{height: "100%"}}>
                    <button className="buttonAdd text-center" >
                        <img src={PlusBGIcon} alt="plus background icon" className="plusIconBg"/>
                        <br/>
                        <img src={PlusIcon} alt="plus icon" className="plusIcon"/>

                        <p className='titleAddBtn'>
                            Add Package
                        </p>
                    </button>
                    <CustomModal
                        isOpen={isOpenModal}
                        handleClose={handleActionModal}
                        closeColor={'#FFFFFF'}
                    >
                        <CreatePackageModal handleClose={handleActionModal}/>
                    </CustomModal>
                </div>
            </div>
        </div>
    )
}
