// import { observable, decorate, action } from 'mobx';
import axios from 'axios';

// import { baseAPI } from '../const/settings';

import { meetingsModel } from './meetings';

export class VimeoModel {
  getVimeoData = (id, url) => {
    const headers = {
      "headers": {
        "Authorization": "Bearer 9b98f9db41b7121d506e3238d9cb9629"
      }
    };
    axios.get(`https://api.vimeo.com/videos/${url}`, headers)
      .then(function (response) {
        // setDuration
        meetingsModel.setMeettingDuration(id, response.data.duration);
        // stateQuestions(response.data);
      })
      .catch(function (error) {
        // handle error vimeo
      })
  }
}

// decorate(QuestionsModel, {
//   questions: observable,
//   getQuestions: action,
// })

export const vimeoModel = new VimeoModel();
