import React, {useEffect, useState, useReducer, useRef} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { inject, observer } from "mobx-react";


import './style.scss';
import { set } from 'lodash-es';

const SelectCommunityCheckbox = props => {

  const {data, showDrop, customChange, visibleDrop, className, placeholder, setFieldValue, initCommunitiesData, disabled, setDistGrpChosen,
    subscribePackageModel: {subscriptionPackagesList},
    userModel: {user},
    communityModel: {distribution_groups}
  } = props;
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [disabledInput, setDisabledInput] = useState(false)
  const inputRef = useRef(null);
  useEffect(() =>{
    inputRef.current.children[0].autocomplete = "off";
  }, [inputRef])

  useEffect(()=>{
    if(initCommunitiesData){
      let initComms = data.filter(comm => initCommunitiesData.communities.some(commId => comm.value === commId) ||
      (initCommunitiesData.distribution_group_id && comm.value.split("_").length === 3 && comm.value.split("_")[2] === (initCommunitiesData.distribution_group_id) + ""));
      setSelectedCommunities(initComms)
    }
    if(disabled){
      setDisabledInput(disabled)
    }
  }, [])

  useEffect(()=>{
    setInputValue(editValue());
  },[selectedCommunities])


  const handleChange = (item) =>{
    let result = selectedCommunities.filter(comm => comm.value !== item.value)
    selectedCommunities.length === result.length && result.push(item);
    setSelectedCommunities(result);
    let filteredResult = result.filter(comm => !comm?.distribution_group);
    let formCommunity = filteredResult.map(comm => comm.value);
    var distGroupId = null;
    if(result.length !== filteredResult.length){
      result.map(grp => grp.distribution_group && (distGroupId = grp.value.split("_")[2]))
      //setFieldValue("distribution_group_id", parseInt(distGroupId)) 
      //setDistGrpChosen(true)

      forceUpdate()
    }else{
      //setFieldValue("distribution_group_id", null)
      distGroupId = null;
      //setDistGrpChosen(false)

      forceUpdate()
    }
    customChange([formCommunity, distGroupId]);
  }

  const getDataWithDistGroups = (communities) => {
    var result = communities;
    //console.log("data", data)
    if(distribution_groups?.distribution_groups){
      let formatedDistGrps = distribution_groups?.distribution_groups.map(distGrp => !result.some(grp => grp.value === `distribution_group_${distGrp.id}`) &&
        result.unshift({value:`distribution_group_${distGrp.id}`, label: distGrp.title, distribution_group: true}));            
    }
    return result;
  }

  const editValue = () => {
    
    let value = selectedCommunities.map(comm => comm.label);
    let commTitles = [];
    value.map((comm, id) =>{
        if(selectedCommunities.some(item => item.label === comm)){
            if(id === 0){
                commTitles.push((comm));
            }else{
                commTitles.push((" " + comm));
            }
        }
    })
    
    let commValueFinal = [];
    let genString = ""
    commTitles.map((title, id)=>{
        if(id){
            let tempGenString = genString + title;
            if(tempGenString.length > 27){
                let countOfNotdisplayedComms = commTitles.length - id
                commValueFinal.push(" +" + countOfNotdisplayedComms);
                commValueFinal.push(0);
            }else{
                commValueFinal.push(title)
            }
        }else{
            commValueFinal.push(title)
        }
        genString += title
    })
    let editedValue = [];
    let key = 0;
    while(key < commValueFinal.length && commValueFinal[key] !== 0){
        editedValue.push(commValueFinal[key]);
        key += 1;
    }
    return editedValue;
  }

  let editedData = getDataWithDistGroups(data);

  return (
    <>
    <FormControl className = "simpleInput">
    <div className = "selectCommunityCheckbox_label">Communities</div>
    <Input
        //id = "dropdown-input"
        type = "text"
        autocomplete="off"              
        value = {inputValue}
        onClick = {() => !disabled && showDrop() }   
        className = {`${className} selectCommunityCheckbox_input`}
        ref = {inputRef}
        disabled = {disabled}
    />
    {visibleDrop && 
        <div className = "selectCommunityCheckbox_dropContainer">
        <List>
            {editedData.map(item => (
            <ListItem key={item.id} className = "selectCommunityCheckbox_item">
                <ListItemIcon>
                <Checkbox
                    size="medium"
                    checked={selectedCommunities.some(comm => item.value === comm.value)}
                    onChange={() => handleChange(item)}
                    disabled = {!selectedCommunities.some(comm => comm.value === item.value) && 
                      item?.distribution_group && 
                      selectedCommunities.some(comm => comm?.distribution_group)}
                />
                </ListItemIcon>
                <ListItemText primary={item.label} />
            </ListItem>
            ))}
        </List>
        </div>
    }
    </FormControl>
    </>
  )
}

export default inject("subscribePackageModel", "userModel", "communityModel")(observer (SelectCommunityCheckbox));
