import React, { memo } from 'react';
import content_blue from './content_blue.svg';
import date_blue from './date_blue.svg';
import ping_blue from './ping_blue.svg';
import calendar_blue from './calendar_blue.svg';
import hand_blue from './hand_blue.svg';
import tag_blue from './tag_blue.svg';
import align_blue from './align_blue.svg';
import stopwatch_blue from './stopwatch_blue.svg';
import coins_blue from './coins_blue.svg';
import content_purple from './content_purple.svg';
import date_purple from './date_purple.svg';
import ping_purple from './ping_purple.svg';
import calendar_purple from './calendar_purple.svg'
import hand_purple from './hand_purple.svg';
import stopwatch_purple from './stopwatch_purple.svg';
import align_purple from './align_purple.svg';
import coins_purple from './coins_purple.svg';
import tag_purple from './tag_purple.svg';
import purple_tickets from './purple_tickets.svg';
import stopwatch_red from './stopwatch_red.svg';
import coins_red from './coins_red.svg';
import content_red from './content_red.svg';
import date_red from './date_red.svg';
import calendar_red from './calendar_red.svg';
import hand_red from './hand_red.svg';
import align_red from './align_red.svg';
import tag_red from './tag_red.svg';
import remove_red from './remove_red.svg';
import red_tickets from './red_tickets.svg';
import add_purple from './add_purple.svg';
import fire_red from './fire_red.svg';
import white_calendarWithTime from './schedule_white.svg';
import white_schedule_calendar from './white_schedule_calendar.svg';
import white_tickets from './white_tickets.svg';
import white_coins from './coins_white.svg';
import white_date from './date_white.svg';
import white_clock from './clockPicker.svg'
import white_calendar from './calendar_white.svg'
import menudots_red from './menudots_red.svg';
import menudots_purple from './menudots_purple.svg';
import menudots_blue from './menudots_blue.svg';
import subscription_package from './subscription_package.svg';
import location from './location.svg';
import category from './category.svg';
import members from  './memberslimit_purple.svg';

const create_group_icons = {
  blue: {
    content: content_blue,
    date: date_blue,
    ping: ping_blue,
    calendar: calendar_blue,
    hand: hand_blue,
    tag: tag_blue,
    align: align_blue,
    stopwatch: stopwatch_blue,
    coins: coins_blue,
    menudots: menudots_blue
  },
  red: {
    stopwatch: stopwatch_red,
    coins: coins_red,
    content: content_red,
    date: date_red,
    calendar: calendar_red,
    hand: hand_red,
    align: align_red,
    tag: tag_red,
    fire: fire_red,
    menudots: menudots_red,
    tickets: red_tickets,
  },
  purple: {
    content: content_purple,
    date: date_purple,
    ping: ping_purple,
    calendar: calendar_purple,
    hand: hand_purple,
    tag: tag_purple,
    align: align_purple,
    stopwatch: stopwatch_purple,
    coins: coins_purple,
    add: add_purple,
    remove: remove_red,
    menudots: menudots_purple,
    location: location,
    category: category,
    tickets: purple_tickets,
    members: members,
  },

  white:{
    date: white_date,
    calendarWithClock: white_calendarWithTime,
    calendar: white_calendar,
    schedule: white_schedule_calendar,
    tickets: white_tickets,
    coins: white_coins,
    clock: white_clock,
    firePercent: subscription_package
  }
  
}

export default create_group_icons
