import React from 'react';
import { inject, observer } from 'mobx-react';

const HeaderSimple = props => {
  const { label} = props;

  return (
    <div className="titleBlock workshops">
      <h3>{label}</h3>
    </div>
  )
}

export default inject('groupsModel')(observer(HeaderSimple));
