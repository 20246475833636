import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import CustomSneckbar from '../../components/CustomSnackbar';

import SubscribeBackground from '../../res/icons/subscribe.svg';
import { profile } from '../../const/urlKeys';

import './subscribe.scss';

const SucceseSubbscribe = props => {
  const { close, subscribeModel: { resualtMessage, changeResultMessage } } = props;

  return (
    <div className="subscribeBlock succeseBlock" style={{ backgroundImage: `url(${SubscribeBackground})` }}>
      <div className="subscribeTopBlock">
        <div className="subscribeTitle">
          <div className="blueLine" />
          <div className="pingLine" />
          <div className="subscribeTitleText">Subscription<br />Successfully Issued</div>
        </div>
        <div className="subscribeText">
          After the trial period ends, funds will be withdrawn from your card automatically.<br />
          <br />You can cancel your subscription at any time in the <Link to={{ pathname: profile, state: { openPage: 'payment' }}}>payment settings</Link>.
        </div>
      </div>
      <CustomSneckbar 
        message={resualtMessage}
        onClose={() => { 
          changeResultMessage('');
          if (resualtMessage === 'Success') { close(); } }}
        severity={resualtMessage === 'Success' ? "success" : 'error'}
      />
    </div>
  )
}

export default inject('subscribeModel')(observer(SucceseSubbscribe));
