import React from 'react';
import moment from 'moment';


const MitingsDateList = ({item}) =>{
  const toRealTime = (daysArr, time) =>{
    const daysMoment = daysArr.map( day => moment().zone(0)
      .set({
        day: day,
        hour:parseInt(time.split(':')[0]) ,
        minute:parseInt(time.split(':')[1]) ,
      }).utcOffset(moment().utcOffset())
    )
    return {
      time: moment(daysMoment[0]).format('hh:mm a')
    }
  }

  const sortedByDays = timeData => Object.entries(timeData).sort((curr, next) => {
    let currDay = curr[1].sort()
    let nextDay = next[1].sort()
    return currDay && nextDay && currDay[0] - nextDay[0]
  })

	const mitingsDateList = () => {
    const date = moment(item.start_time);
    if (!((item.wdays || item.week_diffs) && item.start_time)) {
      const days = date.format('ddd');
      const time = date.format('hh:mm a');
      return (
        <div key={`${time}-`}>
          {`${days}, ${time}`}
        </div>
      );
    }

    if (item.week_diffs){
      const schedule = {}
      item.week_diffs.forEach((minDiff, key) => {
        const momentTime = moment()
          .weekday(1)
          .zone(0)
          .set({
            hour: 0,
            minute: 0,
            second: 0
          })
          .set({minutes: minDiff})
          .utcOffset(moment().utcOffset())
        const time = momentTime.format("hh:mm a") || "01:00 am"
        const day = momentTime.format('ddd')
        if (schedule[time]){
          schedule[time].push(day)
        } else {
          schedule[time] = [day]
        }
      })

      return sortedByDays(schedule).map(([time, day]) => {
        const days = schedule[time].sort().reverse().map(wday => `${moment().day(wday).format('ddd')} `);
        return (
          <div key={`${time}-`}>
            {`${days}, ${time}`}
          </div>
        );
      })
    } else if (item.times) {
      let timeData = {};
      item.times.forEach((time, index) => {
        const wday = item.wdays[index];

        if (index < item.meetings_count) {
          if (timeData[time]) {
            timeData[time].push(wday)
          } else {
            timeData = { ...timeData, [time]: [wday] }
          }
        }
      });


      return sortedByDays(timeData).map(([time, day]) => {
        const days = timeData[time].sort().map(wday => `${moment().day(wday).format('ddd')} `);
        const realData = toRealTime(timeData[time].sort(), time)
        return (
          <div key={`${time}-`}>
            {`${days}, ${realData.time}`}
          </div>
        );
      });
    } else if (item.wdays){
      const days = item.wdays?.map(wday => `${moment().day(wday).format('ddd')} `);
      const realData = toRealTime(item.wdays, date.zone(0).format('HH:mm'))
      return (
        <div key={`${realData.time}-`}>
          {`${days}, ${realData.time}`}
        </div>
      );
    }
  }
	return(mitingsDateList())
}

export default MitingsDateList;