import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import Button from "../Button";
import moment from "moment";
import NewContentItem from "./NewContentItem";
import { inject, observer } from "mobx-react";
import ContentDetailsPopup from "../ContentDetailsPopup";
import Dialog from "../Dialog";
import AttendGroup from "../../pages/AttendGroup";
import MyPlayer from "../MyPlayer";
import CustomVideoPlayer from "../CustomVideoPlayer";
import partition from 'lodash/partition'
import {getRecordFileType} from "../../units/func";

const NewContent = (props) => {
  const {
    mediaModel: { filteredMediaGroups },
    groupsModel: { filteredRecordsGroups, setCanChangeGroupNotFound },
    meetingsModel: { lastMettingID, setLastData },
    initialCount = 4,
    incrementCount = 4,
  } = props;

  const [displayedCount, setDisplayedCount] = useState(initialCount);
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [isAttendOpen, setAttendOpen] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [playData, setPlayData] = useState(false);

  useEffect(() => {
    setCanChangeGroupNotFound(!isDetailsOpen);
  }, [isDetailsOpen]);

  useEffect(() => {
    return () => {
      setCanChangeGroupNotFound(true);
    };
  }, []);
  const attendDialog = (item) => {
    return (
      <Dialog
        openButton={null}
        className="detailGroup"
        closeColor="#43425D"
        onClose={(_) => {
          setAttendOpen(false);
        }}
        defaultOpen={isAttendOpen}
      >
        <AttendGroup item={item} />
      </Dialog>
    );
  };

  const handleDisplayMore = (_) => {
    setDisplayedCount((prev) => {
      return prev + incrementCount;
    });
  };
  const availableToDisplayContent = useMemo(()=>{
    const concatedGroups = [...filteredMediaGroups, ...filteredRecordsGroups];

    if (isDetailsOpen) {
      const id = isDetailsOpen.id;
      const updatedItem = concatedGroups.find((item) => item.id === id);
      updatedItem && setDetailsOpen(updatedItem);
    }

    const parts = partition(concatedGroups,(group) => {
      return (
        moment(group.created_at).unix() >=
        moment()
          .set({
            hour: 0,
            minute: 0,
            second: 0
          })
          .unix()
      );
    }).map((arr) => {
        return arr.sort((curr, next) => {
          return (
            moment(curr.created_at).unix() - moment(next.created_at).unix()
          );
        }).reverse()
      }
    )

    const additionalContent = parts[0].length < 10 ? parts[1].slice(0, 10 - parts[0].length) : []
    return [...parts[0], ...additionalContent]
  },[filteredMediaGroups, filteredRecordsGroups])

  const checkShowMoreBtn = (_) => displayedCount < availableToDisplayContent.length;
  const [needShowMore, setNeedShowMore] = useState(checkShowMoreBtn());

  /*const itemsForDisplay = useMemo(
    (_) => {
      setNeedShowMore(checkShowMoreBtn());
      return availableToDisplayContent.slice(0, displayedCount);
    },
    [availableToDisplayContent, displayedCount]
  );*/
  const itemsForDisplay = availableToDisplayContent;
  const playRecord = (item) => {
    const data = item?.meetings_records[0];

    switch (true) {
      case !!data?.file:
        return (
          <CustomVideoPlayer
            url={data.file}
            fileType={getRecordFileType(data)}
            record_preview={item?.image?.url}
            onClose={() => {
              setLastData(null, null);
              setPlayData(null);
            }}
            autoplay
          />
        );
      case !!data.url:
        return (
          <MyPlayer
            record_info={{...data, image: item.image}}
            owner={item?.owner}
            onClose={() => {
              setLastData(null, null);
              setPlayData(null);
            }}
            autoplay={data.id === lastMettingID}
          />
        );
      default:
        return null;
    }
  };

  return itemsForDisplay?.length ? (
    <div className={"new_content"}>
      <h3 className="new_content_title">Followed Content</h3>
      <div className="new_content_wrapper">
        <div className="new_content_items">
          {itemsForDisplay.map((group) => {
            return (
              <NewContentItem
                key={group.id}
                className="new_content_item"
                content={group}
                handleOpenDialog={(_) => setDetailsOpen(group)}
                playSingleRecordHandler={(record) => {
                  setPlayData(record);
                  setLastData(record.id, record.meetings_records[0].id);
                }}
              />
            );
          })}
        </div>
      </div>
      {/*needShowMore && (
        <div className="new_content_bnts">
          <Button
            label="Show More"
            grey
            className="moreButton"
            onClick={handleDisplayMore}
          />
        </div>
      )*/}
      {isDetailsOpen && (
        <ContentDetailsPopup
          item={isDetailsOpen}
          handleClose={setDetailsOpen}
          setAttendOpen={setAttendOpen}
          reportState={[openReport, setOpenReport]}
        />
      )}
      {isAttendOpen && attendDialog(isAttendOpen)}
      {playData && playRecord(playData)}
    </div>
  ) : null;
};

NewContent.propTypes = {};

export default inject(
  "groupsModel",
  "mediaModel",
  "meetingsModel"
)(observer(NewContent));
