import React, { memo } from 'react';

const ShareIcon = props => {
  const { color = 'white' , width = 15, height = 16} = props;

  return (
    <svg width={width} height={height} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1667 2.66663C14.1667 3.86328 13.1968 4.83337 12.0001 4.83337C10.8035 4.83337 9.8335 3.86328 9.8335 2.66663C9.8335 1.47009 10.8035 0.5 12.0001 0.5C13.1968 0.5 14.1667 1.47009 14.1667 2.66663Z" fill={color}/>
      <path d="M12.0001 5.33337C10.5294 5.33337 9.3335 4.13733 9.3335 2.66663C9.3335 1.19604 10.5294 0 12.0001 0C13.4708 0 14.6667 1.19604 14.6667 2.66663C14.6667 4.13733 13.4708 5.33337 12.0001 5.33337ZM12.0001 1C11.0808 1 10.3335 1.74805 10.3335 2.66663C10.3335 3.58533 11.0808 4.33337 12.0001 4.33337C12.9194 4.33337 13.6667 3.58533 13.6667 2.66663C13.6667 1.74805 12.9194 1 12.0001 1Z" fill={color}/>
      <path d="M14.1667 13.3333C14.1667 14.5298 13.1968 15.4999 12.0001 15.4999C10.8035 15.4999 9.8335 14.5298 9.8335 13.3333C9.8335 12.1366 10.8035 11.1665 12.0001 11.1665C13.1968 11.1665 14.1667 12.1366 14.1667 13.3333Z" fill={color}/>
      <path d="M12.0001 15.9999C10.5294 15.9999 9.3335 14.8038 9.3335 13.3333C9.3335 11.8625 10.5294 10.6665 12.0001 10.6665C13.4708 10.6665 14.6667 11.8625 14.6667 13.3333C14.6667 14.8038 13.4708 15.9999 12.0001 15.9999ZM12.0001 11.6665C11.0808 11.6665 10.3335 12.4146 10.3335 13.3333C10.3335 14.2518 11.0808 14.9999 12.0001 14.9999C12.9194 14.9999 13.6667 14.2518 13.6667 13.3333C13.6667 12.4146 12.9194 11.6665 12.0001 11.6665Z" fill={color}/>
      <path d="M4.83337 8.00012C4.83337 9.19678 3.86328 10.1667 2.66663 10.1667C1.47009 10.1667 0.5 9.19678 0.5 8.00012C0.5 6.80347 1.47009 5.8335 2.66663 5.8335C3.86328 5.8335 4.83337 6.80347 4.83337 8.00012Z" fill={color}/>
      <path d="M2.66663 10.6667C1.19604 10.6667 0 9.47083 0 8.00012C0 6.52942 1.19604 5.3335 2.66663 5.3335C4.13733 5.3335 5.33337 6.52942 5.33337 8.00012C5.33337 9.47083 4.13733 10.6667 2.66663 10.6667ZM2.66663 6.3335C1.74731 6.3335 1 7.08142 1 8.00012C1 8.91882 1.74731 9.66675 2.66663 9.66675C3.58606 9.66675 4.33337 8.91882 4.33337 8.00012C4.33337 7.08142 3.58606 6.3335 2.66663 6.3335Z" fill={color}/>
      <path d="M4.24057 7.67994C4.00852 7.67994 3.78318 7.55921 3.6605 7.34327C3.47849 7.02393 3.59055 6.61658 3.90989 6.43385L10.0958 2.90723C10.4151 2.72388 10.8225 2.83594 11.0052 3.1565C11.1872 3.47584 11.0752 3.88319 10.7558 4.06593L4.5698 7.59254C4.46579 7.65186 4.35251 7.67994 4.24057 7.67994Z" fill={color}/>
      <path d="M10.4261 13.1801C10.3141 13.1801 10.2008 13.152 10.0968 13.0927L3.91077 9.56604C3.59143 9.38403 3.4795 8.97668 3.6615 8.65662C3.84278 8.33667 4.25074 8.224 4.5708 8.40735L10.7568 11.934C11.0762 12.116 11.1881 12.5233 11.0061 12.8434C10.8828 13.0593 10.6575 13.1801 10.4261 13.1801Z" fill={color}/>
    </svg>
  )
}

export default memo(ShareIcon);
