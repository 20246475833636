import React from 'react';

import LoginForm from '../../Login/Components/LoginForm';
import LinkStores from '../../../components/LinkStores/linkStores';
import Iphone from '../../../res/img/iPhone11.png';

const NewTop = () => {
  return (
    <div className="homeTopBlock">
      <div className="loginBlock">
        <LoginForm />
      </div>
      <div className="appBlock">
        <img src={Iphone} alt="phone" className="phoneImg" />
        <div className="appShare">
          <h1 className="title">Download our App</h1> 
          <LinkStores name="home" /></div>
      </div>

    </div>
  );
}

export default NewTop;
