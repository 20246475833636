import React, {useRef, useState} from "react";
import gb from '../../../../res/img/subscribe_background_violet.svg'
import CurrentAvatar from "../../../../components/CurrentAvatar";
import './subscriptionsPackages.scss'
import Divider from "@material-ui/core/Divider";
import CardList from "../../../../components/PaymentMethods/Cards";
import {inject, observer} from "mobx-react";
import AddPaymentMethod from "../../../../components/AddPaymentMethod";
import Button from "../../../../components/Button";
import {returnName} from "../../../../units/func";


const BuySubscriptionsPackagePopup = (props) => {
  const {
    close = () =>{},
    packageData,
    additionalPrice = 0,
    mmData:{
      display_name,
      location,
      image,
      id:user_id
    },
    cardsModel: { defaultCard, cards },
    subscribePackageModel: {buySubscriptionPackage},
    supportModel: {getStripeFeeForBuyPackages, commissions: {package_fee: AscendPackageFee}}
  } = props
  const [cardId, setCardId] = useState(defaultCard?.id || (cards?.count && cards?.cards[0].id) || null);

  const buy = (id, cardId, type = 'card') => {
    const params ={
      id,
      payment_source_id: cardId,
      payment_source_type: type,
      user_id
    }
    buySubscriptionPackage(params);
    close()
  }

  const MMName = returnName(props?.mmData)
  const price = additionalPrice || packageData.price
  const stripeFee = getStripeFeeForBuyPackages(price);
  const ascendFee = price * AscendPackageFee
  let totalPrice = price/100;

  return <div className={'buySubscriptionPopup'}>
    <div className={'buySubscriptionPopup_header'}>
      <img src={gb} alt=""/>
      <div className={'buySubscriptionPopup_headerWrapper'}>
        <div className={'buySubscriptionPopup_title'}>SUBSCRIBE NOW</div>
        <div className={'buySubscriptionPopup_owner'}>
          <CurrentAvatar
            alt={MMName}
            src={image?.url}
            className=''/>
            <div className="buySubscriptionPopup_ownerName">{MMName}</div>
            <div className="buySubscriptionPopup_ownerLocation">{location}</div>
        </div>
      </div>
    </div>
    <div className={'buySubscriptionPopup_payment'}>
      <div className={'buySubscriptionPopup_paymentTitle'}>Select Payment Method</div>
      <div className={'buySubscriptionPopup_cardList'}>
        <Divider />
        {cardId && <CardList onClick={setCardId} cardId={cardId} />}
      </div>
      <AddPaymentMethod title = "Add Payment Method"  />
      <div className="buySubscriptionPopup_paymentInfo">Payment Information</div>
      <div className="buySubscriptionPopup_paymentData">
        <div className="amount">
          Amount <p>$ <span>{totalPrice.toFixed(2)}</span></p>
        </div>
        <div className="packageName">
          Package Name <span className={'ellipsis'}>{packageData.name}</span>
        </div>
        <div className="packageName">
          Ascend Fee <span className={'ellipsis'}>$ {(ascendFee/100).toFixed(2)}</span>
        </div>

        <div className="serviceFee">
          Stripe Fee <span>$ {(stripeFee/100).toFixed(2)}</span>
        </div>
      </div>
      <div className="buySubscriptionPopup_paymentTotal">
        Net Total
        <span>$ <b>{(totalPrice + (ascendFee + stripeFee)/100).toFixed(2)}</b></span>
      </div>
      <div className="buySubscriptionPopup_buttons">
        <Button label="Cancel" className="cancel" onClick={()=>{close()}} />
        <Button
          label="Buy"
          className="pay"
          onClick={() => buy(packageData.id, cardId, 'card')}
          disabled={!cardId}
        />
      </div>
    </div>
  </div>

}

export default inject('cardsModel', "subscribePackageModel", 'supportModel')(observer(BuySubscriptionsPackagePopup))