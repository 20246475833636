import React from 'react';
import Dialog from '../Dialog';
import ReactPlayer from 'react-player';

import './style.scss';

const CustomVideoPlayer = ({
  children,
  url,
  fileType,
  record_preview,
  ...rest
}) => {
  const { autoplay = false, onClose = (_) => {} } = rest;

  const handleFixImageSize = (e) => {
    const el = document.querySelector('.ascend_audio_preview'),
      currWidth = el.naturalWidth,
      currHeight = el.naturalHeight;

    let addedClass = '';
    switch (true) {
      case currHeight > currWidth:
        addedClass = 'portrait';
        break;
      case currHeight < currWidth:
        addedClass = 'landscape';
    }
    addedClass && el.classList.add(addedClass);
  };

  const audioStyle = {
    backgroundImage: `url(${record_preview})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '16px',
    height: '50vh',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
  };

  return (
    <Dialog
      openButton={children}
      open={true}
      className="video_player_dialog"
      closeColor="#FFFFFF"
      close={() => {}}
      onClose={onClose}
      defaultOpen={autoplay}
      onEntering={(_) => {
        fileType?.includes('audio') && handleFixImageSize();
      }}
    >
      <div
        className="ascend_video_player"
        style={{ border: '1px solid white' }}
      >
        {fileType?.includes('audio') && (
          <img
            className="ascend_audio_preview"
            src={record_preview}
            alt="thumbnail"
          />
        )}
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          controls={true}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
                disablepictureinpicture: 'true',
              },
            },
          }}
          playIcon={<button>Play</button>}
          playing
        />
      </div>
    </Dialog>
  );
};

export default CustomVideoPlayer;
