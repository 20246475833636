import React, { memo } from 'react';

const MyOrganizationIcon = props => {
  const { color = '#A8A7BC' } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3333 3.33341C14.0697 3.33341 14.6667 2.73646 14.6667 2.00008C14.6667 1.2637 14.0697 0.666748 13.3333 0.666748C12.597 0.666748 12 1.2637 12 2.00008C12 2.73646 12.597 3.33341 13.3333 3.33341Z" fill={color}/>
      <path d="M2.66659 3.33341C3.40297 3.33341 3.99992 2.73646 3.99992 2.00008C3.99992 1.2637 3.40297 0.666748 2.66659 0.666748C1.93021 0.666748 1.33325 1.2637 1.33325 2.00008C1.33325 2.73646 1.93021 3.33341 2.66659 3.33341Z" fill={color}/>
      <path d="M8 4C9.10457 4 10 3.10457 10 2C10 0.895431 9.10457 0 8 0C6.89543 0 6 0.895431 6 2C6 3.10457 6.89543 4 8 4Z" fill={color}/>
      <path d="M0.5 6.66667H1.66667V13C1.66667 14.1027 2.564 15 3.66667 15H6.33333V15.5C6.33333 15.776 6.55733 16 6.83333 16H9.16667C9.44267 16 9.66667 15.776 9.66667 15.5V15H12.3333C13.436 15 14.3333 14.1027 14.3333 13V6.66667H15.5C15.7733 6.66667 16 6.44 16 6.16667V5.83333C16 4.82 15.18 4 14.1667 4H12.5C12.0667 4 11.6733 4.14667 11.36 4.39333C12.2 4.86 12.8067 5.68667 12.96 6.66667H13V13C13 13.3673 12.7007 13.6667 12.3333 13.6667H9.66667V13.1667C9.66667 12.8907 9.44267 12.6667 9.16667 12.6667H8.66667V8.66667H11.1667C11.4427 8.66667 11.6667 8.44267 11.6667 8.16667V7.16667C11.6667 6.15533 10.8447 5.33333 9.83333 5.33333H6.16667C5.15533 5.33333 4.33333 6.15533 4.33333 7.16667V8.16667C4.33333 8.44267 4.55733 8.66667 4.83333 8.66667H7.33333V12.6667H6.83333C6.55733 12.6667 6.33333 12.8907 6.33333 13.1667V13.6667H3.66667C3.29933 13.6667 3 13.3673 3 13V6.66667H3.04C3.19333 5.68667 3.8 4.86 4.64 4.39333C4.32667 4.14667 3.93333 4 3.5 4H1.83333C0.82 4 0 4.82 0 5.83333V6.16667C0 6.44 0.226667 6.66667 0.5 6.66667Z" fill={color}/>
    </svg>


  )
}

export default memo(MyOrganizationIcon);

