import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import clsx from 'clsx';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CurrentAvatar from '../CurrentAvatar';
import Button from '../Button';

import ClearButton from './ClearButton';
import { returnName } from '../../units/func';
import './invite.scss';

const reqEmail = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/g;

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    background:' #F4F4F7',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  button: {
    border: 0,
    background: 'none',
  }
}));

const SelectInvite = (props) => {
  const {
    inviteMembersModel: { members, geMembers, setMembers },
    userModel:{user:owner},
    onClick, invites, close,
  } = props;
  const [selected, setSelected] = useState(invites);
  const classes = useStyles();
  const [value, setvalue] = useState('');
  const [isEmail, setIsEmail] = useState(false);

  const handleChange = (event) => {
    geMembers('full_name', event?.target?.value);
    setvalue(event?.target?.value);
    setIsEmail(reqEmail.test(value));
  };

  const memberClick = (item) => {
    const filteredArray = selected.filter(member => member.email != item.email);

    if (filteredArray.length == selected.length) {
      setSelected([...selected, item ]);
    } else {
      setSelected([...filteredArray]);
    }
  };

  const inviteNewMember = () => {
    setSelected([...selected, { email: value } ]);
    setvalue('');
    setIsEmail(false);
  }

  const hangleMemberClick = user => {
    !invites.find(member => member.id === user.id && member.participant ) && user.id  && memberClick(user)
  }

  return (
    <div className="inviteSerchBlock dailyBlock">
      <div className="title">Invite Members</div>
      <FormControl className={clsx(classes.textField)} variant="outlined">
        <div className="inputBlock">
          <input value={value} onChange={handleChange} />
          {value && <ClearButton onClick={() => {setvalue(''); setIsEmail(false);}} item='' />}
        </div>
      </FormControl>
      <div className="membersList listBlockUpcoming" style={{padding:"0 3px 0 0"}}>
        <div className='listBlockUpcomingWrapper memberListScroll'>
          {members?.users.map(user => {
            if(user.id === owner.id) return null;
            const active = selected.filter(member => member?.id == user?.id).length > 0;
            const userName = returnName(user);
            return (
              <div className={classNames("user", { active })} style={{width:"100%"}} onClick={() => hangleMemberClick(user)} key={user.email}>
                <CurrentAvatar src={user?.image?.url} alt={userName} className="avatar"/>
                <div className="name" style={{wordBreak: 'break-word', paddingRight:"10px"}}>{userName}</div>
                <CheckCircleIcon className="icon" />
              </div>
            )
          })}
          {!members?.count && isEmail && (
            <div className="description">
              <span>{value}</span><br />is not yet registered in the application,<br />
              or uses a different email.<br /><br/>Want to invite this user to the app?
            </div>
          )}
        </div>
      </div>
      {!members?.count && isEmail ? 
        <Button violetGradien width100 label="Invite" onClick={inviteNewMember} /> :
        <Button violetGradien width100 label="OK" 
          onClick={() => { 
            close(); 
            setMembers(null); 
            onClick([...selected])
          }} />
      }
    </div>
  )
};

export default inject('inviteMembersModel', 'userModel')(observer(SelectInvite));
