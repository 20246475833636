
import React, { memo } from 'react';

const LinkLinkIcon = props => {
  // const { color = 'white' } = props;

  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5076 1.4854L6.31757 5.6754C4.89004 7.11473 4.45003 9.2627 5.19673 11.1474L5.83416 10.51C5.98083 10.3637 6.13507 10.2255 6.2962 10.0955C6.00531 8.85096 6.37922 7.5445 7.28461 6.64222L11.4746 2.45222C12.9208 1.00577 15.2658 1.00577 16.7122 2.45222C18.1585 3.89844 18.1585 6.24339 16.7122 7.68984L12.5222 11.8798C11.4065 12.9987 9.70278 13.2847 8.28304 12.5912C8.21672 12.6461 8.15239 12.7042 8.09052 12.7661L7.26525 13.5914C9.26477 14.8019 11.8318 14.4948 13.4893 12.8467L17.6793 8.65666C19.6594 6.67628 19.6594 3.46556 17.6791 1.48517C15.6987 -0.495207 12.488 -0.494984 10.5076 1.4854Z" fill="#598E7E"/>
      <path d="M14.133 13.4912C13.9866 13.6379 13.8326 13.7768 13.6717 13.9072C13.9621 15.1516 13.588 16.4574 12.683 17.3592L8.49302 21.5492C7.04657 22.9957 4.70162 22.9957 3.25539 21.5492C1.80894 20.103 1.80894 17.758 3.25539 16.3118L7.4454 12.1218C8.56201 11.0043 10.2646 10.7185 11.6848 11.4103C11.7509 11.3553 11.8152 11.2972 11.8771 11.2353L12.7024 10.4101C10.7024 9.20153 8.13669 9.50844 6.47836 11.1548L2.28835 15.3445C0.309075 17.3252 0.30952 20.535 2.28946 22.5147C4.2694 24.4946 7.47923 24.4951 9.45962 22.516L13.6496 18.326C15.0774 16.8867 15.5174 14.7385 14.7707 12.8538L14.133 13.4912Z" fill="#598E7E"/>
    </svg>
  )
}

export default memo(LinkLinkIcon);
