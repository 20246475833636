import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const DoneButton = (props) => {

  return (
    <button className="clearButton" type="button">
      <CheckCircleIcon style={{ color: '#598E7E' }} />
    </button>
  )
};

export default DoneButton;