import React from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import NumberFormatCustom from '../../../components/Input/numberFormat';

import PhoneIcon from '../../../res/icons/phone.svg';
import TitleImage from '../../../res/img/forgot.png';

import '../forgot.scss';

const SignupSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Phone Number can't be blank")
    .matches(/^[0-9]{8,}$/, 'Phone number must contain at least 10 digits'), // The number is invalid. Please check it once again
  code: Yup.string()
    .required('Code not selected'),
})

const ForgotForm = props => {
  const { countryModel, userModel } = props;
  const { phonesCode, currentCode } = countryModel;
  const { forgotPassword } = userModel;

  return (
    <Formik
      initialValues={{ phone: '', code: currentCode || '' }}
      validationSchema={SignupSchema}
      onSubmit={(values, actions) => {
        const { code, phone, } = values;
        const phone_number = `+${code}${phone}`;
        forgotPassword({ setErrors: actions.setFieldError, phone_number });
      }}
      enableReinitialize
    >
      {formikProps => {
        const { setFieldValue, isValid, values: { code, phone } } = formikProps;
        const disabledButton = !(isValid && code !== "" && phone !== "");
        return (
          <Form className="defaultForm forgotForm">
            <img src={TitleImage} alt="Title forgot password" className="titleImage" />
            <div className="title">Forgot Password</div>
            <div className="line end">
              <img src={PhoneIcon} alt="phone icon" />
              <Select name="code" data={phonesCode} className="codeBlock" setFieldValue={setFieldValue}/>
              <Input
                name="phone"
                id="phone"
                className="phoneInput"
                placeholder="Phone Number"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>
            <Button type="submit" label="Send Code" disabled={disabledButton} width100 violetGradien big60 />
          </Form>
        )
      }}
    </Formik>
  )
}

export default inject('countryModel', 'userModel')(observer(ForgotForm));

