import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ProfileIcon from '../../../res/iconComp/ProfileIcon';
import URLValetIcon from '../../../res/img/icons/valetIcon.png';
import URLValetIconActive from '../../../res/img/icons/valetIcon-active.png';

import '../profile.scss';
import {myOrganization, MyProfile, mySubscriptions, paymentPortal, userRole} from "../../../const/general";
import SubscriptionsIcon from "../../../res/iconComp/SubsctiptionsIcon";
import {userModel} from "../../../models/user";
import MyOrganizationIcon from "../../../res/iconComp/MyOrganizationIcon";

const RightProfileMobile = props => {
  const {
    authModel: { logout },
    userModel: { user },
    communityModel: {myOrganizationID},
    onClick, viewProfile } = props;

  const getSummaryTitle = () => {
    switch (viewProfile) {
      case MyProfile:
       return <div><ProfileIcon color='#43425D' />&nbsp;&nbsp;My Profile</div>;
      case paymentPortal:
        return <div><img src={URLValetIconActive} alt="payment icon" />&nbsp;&nbsp;Payment Portal</div>;
      case mySubscriptions:
        return <div><SubscriptionsIcon color={viewProfile === mySubscriptions ? '#43425D' : '#A8A7BC'} />&nbsp;&nbsp;Subscriptions</div>
      case myOrganization:
        return <div><MyOrganizationIcon color={viewProfile === myOrganization ? '#43425D' : '#A8A7BC'} />&nbsp;&nbsp;My Organization</div>

    }
  }
  return (
    <Accordion classes={{ root: "mobilePaymentMenu" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {getSummaryTitle()}
      </AccordionSummary>
      <AccordionDetails classes={{ root: "mobilePaymentOpenMenu" }}>
        <List className="rightActionList">
          {viewProfile !== MyProfile && <ListItem button key="profileLink" onClick={() => onClick(MyProfile)}
                                                  className={classNames({active: viewProfile})}>
            <ListItemIcon>
              <ProfileIcon color={viewProfile === MyProfile ? '#43425D' : '#A8A7BC'}/>
            </ListItemIcon>
            <ListItemText primary="My Profile"/>
          </ListItem>
          }
          {viewProfile !== paymentPortal && <ListItem button key="paymentLink" onClick={() => onClick(paymentPortal)}
                                                      className={classNames({active: !viewProfile})}>
            <ListItemIcon>
              {viewProfile ?
                <img src={URLValetIcon} alt="payment icon"/> :
                <img src={URLValetIconActive} alt="payment icon"/>
              }
            </ListItemIcon>
            <ListItemText primary="Payment Portal"/>
          </ListItem>
          }
          {viewProfile !== mySubscriptions && user?.role !== userRole &&
          <ListItem button key="subscribtionsLink" onClick={() => onClick(mySubscriptions)}
                    className={classNames({active: false})}>
            <ListItemIcon>
              <SubscriptionsIcon color={viewProfile === mySubscriptions ? '#43425D' : '#A8A7BC'} />
            </ListItemIcon>
            <ListItemText primary="Subscriptions"/>
          </ListItem>
          }
          {viewProfile !== myOrganization && user?.role !== userRole && myOrganizationID &&
          <ListItem button key="myOrganizationLink" onClick={() => onClick(myOrganization)}
                    className={classNames({active: false})}>
            <ListItemIcon>
              <MyOrganizationIcon color={viewProfile === myOrganization ? '#43425D' : '#A8A7BC'} />
            </ListItemIcon>
            <ListItemText primary="My Organization"/>
          </ListItem>
          }
        </List>
        <Divider />
        <List className="rightActionList rightActionLogout">
          <ListItem button key="profileLink" onClick={() => logout()}>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
    // <div className="rightBlockProfile">

    // </div>
  )
}

export default inject('authModel', 'userModel', 'communityModel')(observer(RightProfileMobile));
