import {action, decorate, observable} from "mobx";
import {authModel} from "./auth";
import axios from 'axios'
import {baseAPI} from "../const/settings";

export class SupportModel {
  constructor() {
    this.commissions = {}
  }
  baseUrl = 'support'
  STRIPE_FEE = 2.9
  CONST_STRIPE_FEE = 30


  setCommissions = (data) => {
    const coefficients = {}
    Object.entries(data).forEach(([key, value]) => {
      coefficients[key] = Number(this.percentToСoefficient(value))
    })
    this.commissions = coefficients;
  }


  getCommissionByType = (type) => {
    switch (type) {
      case 'PAYMENT':
        return this.commissions.group_fee;
      default:
        return  this.commissions.group_out_fee

    }
  }

  getFeeByType = (type, price) => {
    switch (type) {
      case 'PAYMENT':
        return this.getStripeFeeForGroup(price)
      default:
        return this.getStripeFeeForGroupOut(price)
    }
  }

  percentToСoefficient = (perc) => parseFloat(perc)/100

  //0.08
  getStripeFeeForGroup = (price) => {
    return (price * (1+this.commissions.group_fee) + this.CONST_STRIPE_FEE) / 97.1 * this.STRIPE_FEE + this.CONST_STRIPE_FEE
  }

  //0.03
  getStripeFeeForGroupOut = (price) => {
    return (price * (1+this.commissions.group_out_fee) + this.CONST_STRIPE_FEE) / 97.1 * this.STRIPE_FEE + this.CONST_STRIPE_FEE
  }

  //0.025
  getStripeFeeForBuyPackages = (price) => {
    return (price * (1+this.commissions.package_fee) + this.CONST_STRIPE_FEE) / 97.1 * this.STRIPE_FEE + this.CONST_STRIPE_FEE
  }

  getCommissions = () => {
    const config = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    }

    axios.get(`${baseAPI}${this.baseUrl}/project_commissions`, config)
      .then((response) => {
        console.log('response', response);
        this.setCommissions(response.data)
      })
      .catch((error) => {
        console.log('getCommissions error', error)
      })
  }
}
decorate(SupportModel, {
  commissions: observable,
  getStripeFeeForGroup: action,
  getStripeFeeForBuyPackages: action,
  getStripeFeeForGroupOut: action,
  getCommissionByType: action,
  getFeeByType: action
})

export const supportModel = new SupportModel()