import React from 'react';

const DeleteItem = props => {
  const { color = "#7E7D8F" } = props;

  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 14C1 15.1025 1.89696 16 2.99999 16H11C12.103 16 13 15.1025 13 14V4H1V14Z" fill={color} />
      <path d="M8.99999 0.999992V0H4.99999V0.999992H0V2.99998H14V0.999992H8.99999Z" fill={color} />
    </svg>
  )
}

export default DeleteItem;
