import React, {memo, useEffect, useState} from 'react';
import ReactDom from 'react-dom'

//components
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '../../res/iconComp/CloseIcon';

//helper
import notificationHelper from './notificationHelper';

import './notifications.scss'

const Notifications = ({ id, toggleNotification, showNotification }) => {
    const [node, setNode] = useState(_=>{
        const container = document.createElement('div');
        container.setAttribute('id', 'notification_portal_wrapp')
        document.body.appendChild(container)
        return container
    })
    function onDestroy (){
        document.body.removeChild(node)
        node.remove()
    }
    useEffect(_ => {
        return _ => {
            onDestroy()
        }
    },[])


    const notificationData =  notificationHelper[id];

    const handleClose = () => {
        toggleNotification()
    }

    if(!notificationData){
        return null
    }

    const child = _ => {
        return <div className={`notification-main-wrapper ${showNotification ? "" : "hide"}`}>
            <div className="notification-content">
                <div className="notification-details">
                    <img src={notificationData.img} alt="notificatiob-img" />
                    <span className="notification-details-text">{notificationData.text}</span>
                </div>

                <div className="notification-close">
                    <IconButton aria-label="close" onClick={handleClose} className="close-notification">
                        <CloseIcon color={"#E9E9F0"} />
                    </IconButton>
                </div>
            </div>

        </div>
    }

    return (
          ReactDom.createPortal(child(), node)
    );
};

export default memo(Notifications)
