import React, { memo } from 'react';

const CloseImageIcon = props => {
  // const { color = '#43425D', className } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#43425D"/>
      <path d="M17 8.00714L15.9929 7L12 10.9929L8.00714 7L7 8.00714L10.9929 12L7 15.9929L8.00714 17L12 13.0071L15.9929 17L17 15.9929L13.0071 12L17 8.00714Z" fill="white"/>
    </svg>
  )
}

export default memo(CloseImageIcon);
