import React from "react";
import Button from "../Button";
import './styles.scss'
import {observer, inject} from "mobx-react";

const JoinRequestPopup = (props) => {
  const {
    item:{title, id},
    communityModel:{sendRequestToJoinToCommunity},
    handleClose = () => {},
    type
  } = props

  const sendRequest = () => {
    sendRequestToJoinToCommunity({id})
    handleClose()
  }

  return (
    <div className={"joinRequest"}>
      <div className="joinRequest_title">
        Join to "{title}"
      </div>
      <div className="joinRequest_text">
        You need to send request before select {type} to display.
      </div>
      <div className="joinRequest_btn">
        <Button violetGradien className="joinRequest_sendBtn" label={'Send Request'} onClick={sendRequest}/>
      </div>


    </div>
  )
}

export default inject('communityModel')(observer(JoinRequestPopup))