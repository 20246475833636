import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { inject, observer } from "mobx-react";
import { Formik, Form } from "formik";
import { Field } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { addBankAccountValidations } from "../../res/validations";
import * as Yup from "yup";
import CustomInput from "../../components/Input/customInput";
import CustomSelect from "../../components/MuiSelect";

import {
  contries,
  IBANCoutries,
  onlyBankAccountCountries,
  sortRoutingNumberCountries,
  currenciesList,
  getStripeCurrencyForCountry,
} from "../../const/counties";

import CustomSneckbar from "../CustomSnackbar";
import Button from "../Button";
import "./style.scss";

const accountHolderTypes = {
  individual: {
    value: "individual",
    label: "Individual",
  },
  company: {
    value: "company",
    label: "Company",
  },
};
const BankAccountForm = (props) => {
  const {
    close,
    bankAccountsModel: { createBankAccount, resultMessage, setResultMessage },
    userModel: { user },
  } = props;
  const stripe = useStripe();
  const isIBANCountry = IBANCoutries.includes(user.country);
  const isOnlyBanAccountCountrie = onlyBankAccountCountries.includes(
    user.country
  );
  const isSortRoutingNumber = sortRoutingNumberCountries.includes(user.country);
  const routingNumberTitle = isSortRoutingNumber ? "Sort" : "Routing Number";

  const showRoutingNumber = !(isIBANCountry || isOnlyBanAccountCountrie);


  //reset all previous errors when add form is opening
  useEffect(() => {
    setResultMessage("");
  }, []);

  const handleSubmit = (data) => {
    if (!showRoutingNumber) {
      delete data.routing_number;
    }
    stripe.createToken("bank_account", data).then((result) => {
      if (result?.error) {
        setResultMessage(result?.error.message);
        return;
      }
      const token = result.token.id;
      createBankAccount(token);
    });
  };

  return (
    <Formik
      initialValues={{
        country: user.country,
        currency: getStripeCurrencyForCountry(user.country),
        account_holder_name: "",
        account_holder_type: "",
        routing_number: "",
        account_number: "",
      }}
      validationSchema={Yup.object(
        addBankAccountValidations(showRoutingNumber)
      )}
      onSubmit={handleSubmit}
      isInitialValid={false}
      className="bankForm"
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;

        return (
          <Form className="bankForm_content">
            <div key="country" className="fieldBlock">
              <div className="fieldLabel">Country or region</div>
              <CustomInput
                name="currency"
                id="currency"
                className="simpleInput"
                value={contries[values.country]}
                disabled
              />
            </div>

            <div key="currency" className="fieldBlock">
              <div className="fieldLabel">Currency</div>
              <CustomSelect
                data={currenciesList}
                className="simpleInput"
                value={values.currency}
                handleChange={(e) => setFieldValue("currency", e)}
              />
            </div>

            <div key="account_holder_name" className="fieldBlock">
              <div className="fieldLabel">Account Holder Name</div>
              <CustomInput
                name="account_holder_name"
                id="account_holder_name"
                className="simpleInput"
              />
            </div>

            <div key="account_holder_type" className="fieldBlock">
              <div className="fieldLabel">Account Holder Type</div>
              <CustomSelect
                data={Object.keys(accountHolderTypes).map((i) => {
                  return {
                    value: accountHolderTypes[i].value,
                    label: accountHolderTypes[i].label,
                  };
                })}
                className="simpleInput"
                value={accountHolderTypes[values.account_holder_type]?.label}
                handleChange={(e) => setFieldValue("account_holder_type", e)}
              />
            </div>

            {showRoutingNumber && (
              <div key="routing_number" className="fieldBlock">
                <div className="fieldLabel">{routingNumberTitle}</div>
                <CustomInput
                  name="routing_number"
                  id="routing_number"
                  className="simpleInput"
                />
              </div>
            )}

            <div key="account_number" className="fieldBlock">
              <div className="fieldLabel">
                {isIBANCountry ? "IBAN" : "Account Number"}
              </div>
              <CustomInput
                name="account_number"
                id="account_number"
                className="simpleInput"
              />
            </div>
            <Button
              type="submit"
              label="Save"
              disabled={false}
              width100
              violetGradien
              big60
            />
            <CustomSneckbar
              message={resultMessage}
              onClose={() => {
                setResultMessage("");
                if (resultMessage.includes("Success")) {
                  close && close();
                }
              }}
              severity={resultMessage.includes("Success") ? "success" : "error"}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default inject(
  "bankAccountsModel",
  "userModel"
)(observer(BankAccountForm));
