import React, {useEffect, useState} from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../../Input'
import CustomCheckbox from '../../Checkbox'
import Button from "../../Button";

import './styles.scss';
import CustomSelect from "../../Select/select";
import CustomSelectMultiple from "../../Select/selectMultiple";
import {tiersList, tiersNamesArray} from '../../../const/tiers'
import {inject, observer} from "mobx-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import {getPriceRegExp} from "../../../res/validations";
import CustomSneckbar from "../../CustomSnackbar";
import {gtMasterMind, gtRecurring, gtRecurringWebinar, gtWebinar} from "../../../const/general";
import SelectCommunityCheckbox from '../SelectCommunityCheckbox';
import {communityModel} from "../../../models/community";


const CreatePackage = (props) => {
  const {
    handleClose = () => {},
    userModel:{user},
    subscribePackageModel:{createSubscriptionPackage, packageCreateState, clearCreatePackageState, requestResultMessage},
    communityModel: {communityList, distribution_groups},
  } = props
  const [tiers, setTiers] = useState(0);
  const [processing, setProcessing] = useState(false)
  const [visibleDrop, setVisibleDrop] = useState(false)
  const [distGrpChosen, setDistGrpChosen] = useState(true)

  const showDrop = () =>{
    visibleDrop ? setVisibleDrop(false) : setVisibleDrop(true)
  }

  useEffect(function(){
    setProcessing(packageCreateState === 'start')
    if(packageCreateState === 'success'){
      clearCreatePackageState()
      handleClose()
    }
  },[packageCreateState])

  /*useEffect(()=>{
    console.log("distGrpChosen", distGrpChosen)
  },[distGrpChosen])*/

  const currentCommunityList = [];
  const allCommunities = Object.keys(communityList.mappedCommunities).includes(user.community_id + "")? Object.keys(communityList.mappedCommunities).slice() : Object.keys(communityList.mappedCommunities).concat([user.community_id])
  allCommunities.forEach( (id) => {
    const comItem = communityList.mappedCommunities[id]
    comItem && comItem.moderator && currentCommunityList.push({
      value: comItem.id + "",
      label: comItem.title,
    });
  })
  let hope = true;
  const createValidationSchema = () => {
        return Yup.object().shape({
            name: Yup.string()
                .required('Incorrect Name')
                .min(2, 'Please, enter the Group Name correctly. (minimum is 2 characters)')
                .max(64, 'Please, enter the Group Name correctly. (maximum is 64 characters)'),
            description: Yup.string()
                .min(2, 'Description is too short (minimum is 2 characters)')
                .max(500, 'Please, enter the Group Description correctly (maximum is 500 characters)')
                .required('Please write group desciption'),
            price: Yup.string()
              .required("Please write group price")
              .matches(getPriceRegExp(1, false ), `Set price $ 1.00 or more, and less $ X XXX.XX` ),
            
            //distribution_group_id: Yup.string(),

            /*communities: Yup.string().when("distribution_group_id",{
              is:fieldIsValid,
              then: Yup.string(),
              otherwise: Yup.string().required("Please select communities"),
            })
              //.required("Please select communities"),
            */
            /*communities: Yup.mixed()
            .when('isArray', {
              is: x => !x.length && (x[0].length || x[1]),
              then: Yup.array().of(Yup.string()),
              otherwise: Yup.string(),
             }).required("Please select communities")*/
        });
    };

    const renderCheckbox = (setFieldValue) => {
        return (
            <div className='tiersList'>
                {tiersList.map(({name, label}) =>
                    <div className='tiersList-item' key={name}>
                        <CustomCheckbox
                            setFieldValue={setFieldValue}
                            name={name}
                            placeholder={label}
                        />
                    </div>)}
            </div>
        )
    }

    const handleChangeCheckbox = (values) => {
        const currentSelectedCheckboxes = tiersNamesArray.reduce((res, item) => values[item] ? res + 1 : res, 0);
        setTiers(currentSelectedCheckboxes);
    }

    const handleSubmit = (values) => {
      const selectedTiers = tiersNamesArray.reduce((res, item) => {
          if (values[item]){
            res.push(item)
            if (item === gtWebinar) res.push(gtRecurringWebinar)
            if (item === gtMasterMind) res.push(gtRecurring)
          }
          return res
        },[])
        const params = {
          name: values.name,
          description: values.description,
          price: values.price*100,
          communities: values.communities[0],
          tiers: selectedTiers,
          distribution_group_id: values.communities[1]
        }
      createSubscriptionPackage(params)
    }

    let init = {
      name: '',
      communities: [],
      price: 0,
      description: ''
    }

  tiersList.forEach((tiers) => {
    init[tiers.name] = false;
  })
  const customValidate = (values) =>{
    const errors = {}

    if(values.communities.length === 0 || !values.communities[0].length && !values.communities[1]){
      errors.communities = "Please select communities"
    }
    return errors
  }

  return (
        <div className="createPackage" onClick={(e) => {e.stopPropagation()}}>
            <div className="createPackageHeader">
                <p className="title">
                    Create Package
                </p>
            </div>
            <div className="createPackageForm">
                <Formik
                    initialValues={init}
                    isInitialValid={false}
                    onSubmit={handleSubmit}
                    validationSchema={createValidationSchema}
                    validate = {customValidate}
                    >
                    {({ setFieldValue, values,isValid }) => (
                        <Form className="p-0">
                            {handleChangeCheckbox(values)}
                            <CustomInput style={{marginBottom: '30px'}} className="formInput" name="name" label="Name" isShrink={true} inputProps={{
                              maxLength: 48
                            }}/>
                            {/*<CustomSelect style={{marginBottom: '30px', width: "100%"}} className={'select'} name="community" label={"Community"} isShrink={true}
                              customChange={(tab) => setFieldValue("community", tab)}
                              data={currentCommunityList}
                          />
                            <CustomSelectMultiple
                              style={{marginBottom: '30px', 
                              width: "100%"}} 
                              className={'select'} 
                              label={"Community"} 
                              isShrink={true}
                              customChange={(tab) => setFieldValue("communities", tab)}
                              data={currentCommunityList}
                              name="communities"
                              
                              distribution_groups = {distribution_groups?.distribution_groups ? distribution_groups?.distribution_groups : false}
                              defValue = {false}
                              defDistGroupId = {false}
                              setFieldValue = {setFieldValue}
                              
                              disabled={false}
                            />*/}
                            <SelectCommunityCheckbox
                              customChange={(tab) => setFieldValue("communities", tab)}
                              data = {currentCommunityList}
                              showDrop = {showDrop}
                              visibleDrop = {visibleDrop}
                              style={{marginBottom: '30px'}} 
                              className="formInput"
                              placeholder = "Community"
                              setFieldValue = {setFieldValue}
                              setDistGrpChosen = {setDistGrpChosen}
                            />
                            <CustomInput style={{marginBottom: '30px'}} className="formInput" name="price" label="Price" isShrink={true} tag='USD' type="number" />
                            <CustomInput style={{marginBottom: '30px'}} className="formInput" name="description" label="Description" isShrink={true} isTextArea inputProps={{
                              maxLength: 500
                            }}/>
                            <div className='tiersTitleWrap'>
                                <span className='tiersTitle'>Tiers</span>
                                <span className='tiersLabel'>{tiers} of {tiersList.length}</span>
                            </div>
                            {renderCheckbox(setFieldValue)}
                            {processing? <CircularProgress/>: <Button type="submit" label="Save" disabled={!isValid} className={classNames("createPackageButton", {unactive: !isValid})} />}
                        </Form>
                    )}
                </Formik>
                {visibleDrop && <div className = "selectCommunityCheckbox_overlay" onClick = {showDrop}></div>}

            </div>
          {requestResultMessage && <CustomSneckbar
            message={requestResultMessage}
            onClose={() => {
            }}
            severity={requestResultMessage === 'Success' ? "success" : 'error'}
          />}
        </div>
    );
}

export default inject('userModel', 'subscribePackageModel', 'communityModel')(observer(CreatePackage))


