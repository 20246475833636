import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import CustomCheckbox  from '../../../components/Checkbox';
import Dialog from '../../../components/Dialog';
import LoginForm from '../../Login/Components/LoginForm';
import PasswordInput from '../../../components/Input/passwordInput';
import NumberFormatCustom from '../../../components/Input/numberFormat';

import PhoneIcon from '../../../res/icons/phone.svg';
import KeyIcon from '../../../res/icons/key.svg';
import ProfileIcon from '../../../res/icons/profile.svg';
import EmailIcon from '../../../res/icons/email.svg';
import TitleImage from '../../../res/img/forgot-password.png';
import ImgBuilder from '../../../res/builders/ImgBuilder';

import CloseRoundIcon from '../../../res/iconComp/CloseRound';

import { privacy_policy, terms_conditions } from '../../../const/urlKeys';

import '../register.scss';

const SignupSchema = Yup.object().shape({
  full_name: Yup.string()
    .required("Full name can't be blank")
    .min(4, 'Please, enter your Full Name correctly (minimum is 4 characters)')
    .max(48, 'Please, enter your Full Name correctly (maximum is 48 characters)'),
  email: Yup.string()
    .required("Email can't be blank")
    .email('The email is incorrect. Please, make sure you entered correct data'),
  phone: Yup.string()
    .required("Phone Number can't be blank")
    .matches(/^[0-9]{8,}$/, 'Phone number must contain at least 10 digits'), // The number is invalid. Please check it once again
  code: Yup.string()
    .required('Code not selected'),
  password: Yup.string()
    .required("Password can't be blank")
    .matches(/^(?=(.*[a-zA-Z]){1,})(?=(.*[\d]){1,}).{6,48}$/, 'Wrong password'), // The password should contain at least 1 number and 1 letter, from 6 till 48 symbols.
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match"),
  agree: Yup.mixed()
    .oneOf([true],'Plase confirm policy'),
})

const RegisterForm = props => {
  const { countryModel, userModel } = props;
  const { phonesCode, currentCode } = countryModel;
  const { validUserData } = userModel;

  const placeholder = () => (
    <>
      I agree with <Link to={terms_conditions} className="link" target="_blank" >Terms and Conditions</Link> & <Link to={privacy_policy} className="link" target="_blank">Privacy Policy</Link>
    </>
  );

  const initData = { phone: '', code: currentCode || '', password: '', full_name: '', email: '', password_confirmation: '', agree: false };

  return (
    <Formik
      initialValues={initData}
      validationSchema={SignupSchema}
      onSubmit={(values, actions) => {
        const { code, phone, ...rest } = values;
        const phone_number = `+${code}${phone}`;
        validUserData({ setErrors: actions.setFieldError, phone_number, ...rest});
      }}
      enableReinitialize
    >
      {formikProps => {
        const { setFieldValue, isValid, values: { code, phone, password, full_name, email, password_confirmation } } = formikProps;
        const disabledButton = !(isValid && code !== "" && phone !== "" && password !== "" && 
          full_name !== "" && email !== "" && password_confirmation !== "");
        return (
          <Form className="defaultForm registerForm">
            <img src={TitleImage} alt="Title register" className="titleImage" />
            <div className="title">Sign Up</div>
            <div className="line borderBottomLogin">
              <img src={ProfileIcon} alt="profile icon" />
              <Input name="full_name" id="full_name" className="simpleInput" placeholder="Full Name or Alias" />
              <img src = {ImgBuilder.infoButton} className='loginInfoButton'/>
              <img src = {ImgBuilder.textInfoButton} className='hoverLoginInfoButton'/>
            </div>
            <div className="line borderBottomLogin">
              <img src={EmailIcon} alt="key icon" />
              <Input name="email" id="email" className="simpleInput" placeholder="Email" />
            </div>
            <div className="line">
              <img src={PhoneIcon} alt="phone icon" />
              <Select name="code" data={phonesCode} className="codeBlock" setFieldValue={setFieldValue}/>
              <Input
                name="phone"
                id="phone"
                className="phoneInput"
                placeholder="Phone Number"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>
            <div className="line">
              <img src={KeyIcon} alt="key icon" />
              <PasswordInput
                name="password"
                id="reg_password"
                className="simpleInput"
                placeholder="Password" 
              />
            </div>
            <div className="line">
              <span />
              <PasswordInput
                name="password_confirmation"
                id="password_confirmation"
                className="simpleInput"
                placeholder="Confirm Password" 
              />
            </div>
            <div className="line">
              <CustomCheckbox 
                name="agree"
                setFieldValue={setFieldValue}
                className="agreeBlock"
                placeholder={placeholder()}
              />
            </div>
            <Button type="submit" label="Continue" disabled={disabledButton} width100 violetGradien big60 />
            <div className="signInBlock oneLine">
              Already a member?&nbsp;
              <Dialog 
                openButton={<span className="signin">Sign In</span>}
                closeIcon={window.innerWidth < 720 && <CloseRoundIcon />}
              >
                <LoginForm />
              </Dialog>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default inject('countryModel', 'userModel')(observer(RegisterForm));

