import React, { memo } from 'react';

const AddPMIcon = props => {
  const {width = 24, height = 24} = props
  // const { color = 'white' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#598E7E" />
      <path d="M10 6.10526C10 4.94256 10.8373 4 12 4C13.1627 4 14 4.94256 14 6.10526V12V17.8947C14 19.0574 13.1627 20 12 20C10.8373 20 10 19.0574 10 17.8947V6.10526Z" fill="white" />
      <path d="M6.10526 14C4.94256 14 4 13.1627 4 12C4 10.8373 4.94256 10 6.10526 10H17.8947C19.0574 10 20 10.8373 20 12C20 13.1627 19.0574 14 17.8947 14H6.10526Z" fill="white" />
    </svg>
  )
}

export default memo(AddPMIcon);
