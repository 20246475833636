import ImgBuilder from '../../res/builders/ImgBuilder';


const notificationHelper = {
    linkCopied : {
        text: "Link copied to clipboard",
        img : ImgBuilder.success
    },
    mailerNotFound : {
        text: "Email client was not found",
        img : ImgBuilder.share
    }
}

export default notificationHelper;