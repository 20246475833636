import { observable, decorate, action } from "mobx";
import axios from "axios";

import { baseAPI } from "../const/settings";

import { authModel } from "./auth";
import { cardsModel } from "./cards";

const getRequestHeaders = (token) => {
  return {
    headers: {
      accept: "application/json, text/plain, */*",
      "session-token": token,
    },
  };
};

export class BankAccountsModel {
  constructor() {
    this.resultMessage = "";
    this.bankAccounts = [];
  }

  setResultMessage = (text) => (this.resultMessage = text);
  setBankAccounts = (data) => (this.bankAccounts = data);

  getBankAccounts = () => {
    const setResultMessage = this.setResultMessage;
    const setBankAccounts = this.setBankAccounts;
    const headers = getRequestHeaders(authModel.session_token);
    axios
      .get(`${baseAPI}bank_accounts`, headers)
      .then(function (response) {
        setBankAccounts(response.data.bank_accounts);
      })
      .catch(function (error) {
        setResultMessage(
          error.response.data ? error.response.data.errors[0] : "Error"
        );
      });
  };

  createBankAccount = (token) => {
    const setResultMessage = this.setResultMessage;
    const getBankAccounts = this.getBankAccounts;
    const headers = getRequestHeaders(authModel.session_token);

    axios
      .post(`${baseAPI}bank_accounts`, { token: token }, headers)
      .then(function (response) {
        setResultMessage("Success");
        getBankAccounts();
      })
      .catch(function (error) {
        setResultMessage(
          error.response.data ? error.response.data.errors[0] : "Error"
        );
      });
  };

  deleteBankAccount = (id) => {
    const setResultMessage = this.setResultMessage;
    const getBankAccounts = this.getBankAccounts;
    const headers = getRequestHeaders(authModel.session_token);

    axios
      .delete(`${baseAPI}bank_accounts/${id}`, headers)
      .then(function (response) {
        setResultMessage("Success");
        getBankAccounts();
      })
      .catch(function (error) {
        setResultMessage(
          error.response.data ? error.response.data.errors[0] : "Error"
        );
      });
  };


  setAsDefault = (id, type = "payout") => {
    const getBankAccounts = this.getBankAccounts;
    const getCards = cardsModel.getCardsData;
    const headers = getRequestHeaders(authModel.session_token);
    const url = type === "payout" ? "default_payout" : "";
  
    axios
      .post(`${baseAPI}bank_accounts/${id}/${url}`, null, headers)
      .then(function (response) {
        getBankAccounts();
        getCards();
      })
      .catch(function (error) {
        console.log("error", error);
      })
      .finally();
  };
}



decorate(BankAccountsModel, {
  resultMessage: observable,
  bankAccounts: observable,
  createBankAccount: action,
  getBankAccounts: action,
  deleteBankAccount: action,
  setAsDefault: action
});

export const bankAccountsModel = new BankAccountsModel();
