import React, { useEffect, useMemo, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { inject, observer } from "mobx-react";
import moment from "moment";
import InviteGuests from "../InviteGuests";
import InviteMembers from "../InviteMembers";
import UploadImage from "../UploadImage";
import NewThemesSubscriptionGroup from "../ThemesSubscriptionGroup/NewThemesSubscriptionGroup";
import InputWithIcon from "../Input/inputWithIcon";
import Button from "../Button";
import CustomInput from "../Input/customInput";
import CustomSelect from "../Select/select";
import CustomSelectMultiple from "../Select/selectMultiple";
import CustomSelectMultipleCategory from "../Select/selectMultipleCategory";
import Price from "../Input/price";
import Dialog from "../Dialog";
import MeetingsCount from "../UI/MeetingsCount";
import CustomizedSwitche from "../UI/Switch";
import TagSelect from "../TagSelect";
import DropdownCheckboxes from "../DropdownCheckboxes";
import CustomField from "../UI/CustomField";
import RenderThemeInputs from "../UI/RenderThemeInputs";
import MultipleSchedules from "../UI/MultipleSchedules";
import StripeVerification from "../../pages/StripeVerification";
import { authModel } from "../../models/auth";
import {
  scheduleStrToObj,
  getCorrectPrice, daysCountInPeriod,
} from "../../units/func"; // goBack
import {
  gtSupport,
  gtMasterMind,
  gtWebinar,
  actionEdit,
  actionDuplicate,
  gtRecurring, gtRecurringWebinar,
} from "../../const/general";
import {dats3Letters, wdays} from "../../const/calendar";
import { groupCreateValidations } from "../../res/validations";
import { fieldsData } from "./groupsFieldsData";
import { home } from "../../const/urlKeys";
import "./style.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import CustomFormSwitcher from "../UI/Switch/CustomFormSwitcher";
import difference from 'lodash/difference'
import CreateGroupSchedule from "../CreateGroupSchedule";

const titleText = {
  support: {
    private:
        "Public group sessions are viewable by all users, 1-100 members can join.",
    public: "Private group sessions are by invite only, 1-100 members can join.",
    privateLabel: "Public Group",
    publicLabel: "Private Group",
    themeColor: "#2E9DC0",
    themeSubColor: "#DADADA",
    themeRecurringOn: "#2E9DC0"
  },
  master_mind: {
    private:
        "Public Group sessions are viewable by all users, 1-100 members can join.",
    public:
        "Private Group sessions are by invite only, 1-100 members can join.",
    privateLabel: "Public Group",
    publicLabel: "Private Group",
    themeColor: "#4E519F",
    themeSubColor: "#DADADA",
    themeRecurringOn: "#43425D"
  },
  recurring: {
    private:
        "Public Group sessions are viewable by all users, 1-100 members can join.",
    public:
        "Private Group sessions are by invite only, 1-100 members can join.",
    privateLabel: "Public Group",
    publicLabel: "Private Group",
    themeColor: "#4E519F",
    themeSubColor: "#DADADA",
    themeRecurringOn: "#43425D"
  },
  webinar: {
    private:
        "Public Livestream sessions are viewable by all users.",
    public:
        "Private Livestream sessions are by invite only.",
    privateLabel: "Public Livestream",
    publicLabel: "Private Livestream",
    themeColor: "#f44336",
    themeSubColor: "#DADADA",
    themeRecurringOn: "#941029"
  },
  recurring_webinar: {
    private:
        "Public Livestream sessions are viewable by all users.",
    public:
        "Private Livestream sessions are by invite only.",
    privateLabel: "Public Livestream",
    publicLabel: "Private Livestream",
    themeColor: "#f44336",
    themeSubColor: "#DADADA",
    themeRecurringOn: "#941029"
  },
};

const GroupForm = (props) => {
  const history = useHistory();

  const {
    tagsModel: { tags, getTags},
    subscribePackageModel: {
      subscriptionPackagesList,
      getSubscriptionPackagesList,
    },
    userModel: { community: mainCommunity, user },
    groupsModel: {
      createGroup,
      editGroup,
      createGroupStatus,
      setCreateGroupStatus,
      updateGroup,
      setNeedOpenPopupAfterCreateGroup,
      lastGroup,
      groupParticipants,
      getCategoriesList,
      categoryList,
    },
    communityModel: {
      communityList,
      distribution_groups
    },
    groupType,
    action,
  } = props;
  const [pending, setPending] = useState(false);
  const actualGroupType = groupType || editGroup?.group_type;
  const [minMeetings, setMinMeetings] = useState(1);
  const [schedules, setSchedules] = useState([null]);
  const [webinarsArr, setWebinarsArr] = useState( []);
  const [canBuyBySubscribe, setCanBuyBySubscribe] = useState(false);
  const [canBuyBySubscribeAll, setCanBuyBySubscribeAll] = useState(false);
  const [switcherWasActivate, setSwitcherWasActivate] = useState(false);
  const [community, setCommunity] = useState(mainCommunity);
  const [startedEditing, setStartedEditing] = useState(false);
  const [selectedCommunities, setSelectedCommunities] = useState("");

  const [selectedTags, setSelectedTags] = useState([])
  const [selectedTagsCount, setSelectedTagsCount] = useState({})
  const [notVeryfiedMMShowBuyMessage, setNotVeryfiedMMShowBuyMessage] = useState(false);
  const [formSwitcherInPersonValue, setFormSwitcherInPersonValue] = useState(editGroup?.in_person && (action === actionEdit || action === actionDuplicate) ? editGroup.in_person : false);
  const [themesCount, setThemesCount] = useState(1);
  const [selectedMonths, setSelectedMonths] = useState(0);
  const [starttimeFromCalendar, setStarttimeFromCalendar] = useState("");
  const [distributionGroupSelected, setDistributionGroupSelected] = useState(false);
  const [allCommunityValues, setAllCommunityValues] = useState([])
  const [disableSubscriptionGroup, setDisableSubscriptionGroup] = useState(false)
  const [errorsYupState, setErrorsYupState] = useState({})
  const [formDataValuesState, setFormDataValuesState] = useState({});
  const [location, setLocation] = useState(editGroup?.location && (action === actionEdit || action === actionDuplicate) ? editGroup.location :'');
  const [fieldCategory, setFieldCategory] = useState(
      (editGroup?.category_id || editGroup?.category) && (action === actionEdit || action === actionDuplicate) ?
          (editGroup.category_id?.id || editGroup.category?.id) : categoryList.length && categoryList[0].id);

  const [isNewThemes, setIsNewThemes] = useState(true);

  const [formSwitcherValue, setFormSwitcherValue] = useState(() =>
      action === actionEdit || action === actionDuplicate
          ? editGroup?.group_type === gtRecurring || editGroup?.group_type === gtRecurringWebinar
          : false
  );
  const [formSwitcherPurchaseValue, setFormSwitcherPurchaseValue] = useState(
      ((action === actionEdit || action === actionDuplicate) && editGroup?.price > 0) ? true : false
  );
  const [formSwitcherCreatePackage, setFormSwitcherCreatePackage] = useState(
      ((action === actionEdit || action === actionDuplicate) &&  editGroup?.pack_price && editGroup?.meetings_in_pack
          && editGroup?.price > 0) ? true : false
  );
  const [formSwitcherSubscribersValue, setFormSwitcherSubscribersValue] = useState(false); //editGroup ? editGroup?.available_for_subscribers : true
  //custom errors states
  const [errorsStateTag, setErrorsStateTag] = useState(null);
  const [errorsStatePack, setErrorsStatePack] = useState(null);
  const [errorStateThemesSubGroup, setErrorStateThemesSubGroup] = useState(null)
  //end custom errors states
  const [showSubscriptionSwitcherError,setShowSubscriptionSwitcherError] = useState(false);
  const [visibleDrop, setVisibleDrop] = useState(false);
  const [editDrop, setEditDrop] = useState(false);
  const [editedData, setEditedData] = useState(canBuyBySubscribe);
  const [subscriptionPackEditGroup, setSubscriptionPackEditGroup] = useState(false);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const participantsGuestsArr = groupParticipants && groupParticipants.participants && groupParticipants.participants.length && groupParticipants.participants.filter(item => item.speaker);
  const participantsMembersArr = groupParticipants && groupParticipants.participants && groupParticipants.participants.length && groupParticipants.participants.filter(item => !item.speaker);
  const [membersList, setMembersList] = useState( []);
  const [guestList, setGuestList] = useState( []);
  const [removedGuestsList, setRemovedGuestsList] = useState([]);
  const [removedMembersList, setRemovedMembersList] = useState([]);
  const getRandomArbitrary = (index) => {
    return (Math.random() * (10000 - 1) + 1).toString() + index;
  }

  useEffect(() => {
    if(!!editGroup && (editGroup.length > 0 || Object.keys(editGroup).length > 0) && editGroup?.invites?.length > 0)  {
      setMembersList(
          [...editGroup?.invites?.filter(user => {
            if(groupParticipants && participantsMembersArr && participantsMembersArr.length) {
              groupParticipants.participants.forEach(item => {
                if (item.email !== user.email && !user.speaker) return user;
              })
            } else {
              return !user.speaker;
            }
          }),
            ...groupParticipants?.participants?.filter(user => !user.speaker)]);

      setGuestList(
          [...editGroup?.invites?.filter(user => {
            if(groupParticipants && participantsGuestsArr && participantsGuestsArr.length) {
              groupParticipants.participants.forEach(item => {
                if (item.email !== user.email && user.speaker) return user;
              })
            } else {
              return user.speaker;
            }
          }),
            ...groupParticipants?.participants?.filter(user => user.speaker)]);
    };
  }, [editGroup, groupParticipants]);

  const generateMeetingsData = (schedule_info, meetings_records) => {
    const meetingData = [];
    const scheduleData = [];
    const metObj = {};

    !!meetings_records && meetings_records.forEach(i => {
      const startTime = moment.utc(i.start_time).format('YYYY-MM-DD HH:mm');
      metObj[startTime] = i;
    });

    schedule_info.forEach((item, index) => {
      const dateFormat = 'YYYY-MM-DD HH:mm';
      const startDate = moment(`${item.start_date} ${item.start_time}`, dateFormat);
      const endDate = moment(`${item.end_date} ${item.end_time}`, dateFormat);
      const id = getRandomArbitrary(index);

      if(item.type === 'specific_date'){
        meetingData.push({
          ...metObj[`${item.start_date} ${item.start_time}`],
          id
        });
        scheduleData.push({
          ...item,
          id
        })
      }

      if(item.type !== 'specific_date'){
        let date = startDate.clone();

        meetingData.push({
          ...metObj[date.format(dateFormat)],
          id
        });

        while (date.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')){
          date = date.clone().add(1, 'day');

          if(!!metObj[date.format(dateFormat)]){
            meetingData.push({
              ...metObj[date.format(dateFormat)],
              id
            });
          }
        }

        scheduleData.push({
          ...item,
          id
        })
      }
    });

    return {
      meetings: meetingData,
      scheduleInfo: scheduleData
    }
  };

  const generateNewMeetingsData = (schedule) => {
    const meetingData = [];
    const startTime = schedule[0].start_date + ' ' + schedule[0].start_time;
    const endTime = schedule[0].end_date + ' ' + schedule[0].end_time;

    if(schedule[0].type === "specific_date") {
      meetingData.push({
        start_time: moment(startTime, 'YYYY-MM-DD HH:mm').toISOString(),
        theme:  moment(startTime, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY'),
        duration: moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'), 'minutes')
      })
    } else if (schedule[0].type === "weekly") {
      let newArr = [];
      schedule[0].repeats.forEach(day => {
        const start = moment(startTime, 'YYYY-MM-DD HH:mm'),
            end = moment(endTime, 'YYYY-MM-DD HH:mm');

        const result = [];
        const current = start.clone();

        while (current.day(7 + Number(day)).isBefore(end)) {
          result.push(current.clone());
        }
        newArr = [...newArr, ...result.map(m => m.format('YYYY-MM-DD'))];
      })

      newArr.forEach(el => {
        const elStartTime = el + ' ' + schedule[0].start_time
        meetingData.push({
          start_time: moment(elStartTime, 'YYYY-MM-DD HH:mm').toISOString(),
          theme:  moment(elStartTime, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY'),
          duration: moment(schedule[0].end_time, 'HH:mm').diff(moment(schedule[0].start_time, 'HH:mm'), 'minutes')
        })
      })
    } else if (schedule[0].type === "monthly"){
      let date = moment(schedule[0].start_date + ' ' + schedule[0].start_time, 'YYYY-MM-DD HH:mm');
      while (date.format('YYYY-MM-DD') !== moment(schedule[0].end_date, 'YYYY-MM-DD').format('YYYY-MM-DD')) {
        const dayOfDate = date.date().toString();
        const isDayMatch = !!schedule[0].repeats.find(day => dayOfDate === day);

        if(isDayMatch){
          meetingData.push({
            start_time: moment(date, 'YYYY-MM-DD HH:mm').toISOString(),
            theme:  moment(date, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY'),
            duration: moment(schedule[0].end_time, 'HH:mm').diff(moment(schedule[0].start_time, 'HH:mm'), 'minutes')
          })
        }
        date = date.clone().add(1, 'day');
      }

      const dayOfDate = date.date().toString();
      const isDayMatch = !!schedule[0].repeats.find(day => dayOfDate === day);

      if(isDayMatch){
        meetingData.push({
          start_time: moment(date, 'YYYY-MM-DD HH:mm').toISOString(),
          theme:  moment(date, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY'),
          duration: moment(schedule[0].end_time, 'HH:mm').diff(moment(schedule[0].start_time, 'HH:mm'), 'minutes')
        })
      }
    }

    return meetingData;
  };

  useEffect(() => {
    const doc = document.getElementById("launcher-icon-container");
    if(doc){
      doc.hidden = true;
    }
  }, []);

  useEffect(() => {
    getCategoriesList();
  }, []);

  useEffect(() => {
    if(editGroup && editGroup.available_for_subscribers){
      setFormSwitcherValue(editGroup.available_for_subscribers);
    }
  }, [editGroup])

  useEffect(() => {
    const getRandomArbitrary = (index) => {
      return (Math.random() * (10000 - 1) + 1).toString() + index;
    }

    if(editGroup && editGroup.meetings_records && editGroup.schedule_info){
      const {meetings, scheduleInfo} = generateMeetingsData(editGroup.schedule_info, editGroup.meetings_records);

      const webinars = [...meetings.map(i => ({
        startTime: moment.utc(i.start_time).local(),
        endTime: moment.utc(i.start_time).add(i.duration ?
            i.duration : editGroup.legacy_fields ? editGroup.legacy_fields.duration : i.duration, 'minutes').local(),
        time: moment.utc(i.start_time).local(),
        title: i.theme,
        touched: true,
        id: i.id
      }))];

      const serverSchedules = scheduleInfo.map((item) => {
        const schedule = {
          active: true,
          id: item.id,
          startTime: moment.utc(`${item.start_date} ${item.start_time}`, 'YYYY-MM-DD HH:mm').local(),
          endTime: moment.utc(`${item.end_date} ${item.end_time}`, 'YYYY-MM-DD HH:mm').local(),
          type: item.type,
          initialData: {
            id: item.id,
            date: moment(item.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            dateEnd: moment(item.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            startTime: moment.utc(`${item.start_date} ${item.start_time}`, 'YYYY-MM-DD HH:mm').local(),
            endTime: moment.utc(`${item.end_date} ${item.end_time}`, 'YYYY-MM-DD HH:mm').local()
          }
        }

        if(item.type === 'weekly'){
          schedule.initialData.weekly = {};
          schedule.initialData.weeklyArr = item.repeats.map(i => ({day: wdays[i], active: true}));
        }

        if(item.type === 'monthly'){
          schedule.initialData.monthly = {};
          schedule.initialData.monthlyArr = item.repeats.map(i => ({day: parseInt(i), active: true}));
        }

        return schedule;
      });

      setSchedules(serverSchedules)
      setWebinarsArr(webinars);
    }

    if(editGroup && !editGroup.meetings_records && editGroup.schedule_info){
      const serverSchedules = editGroup.schedule_info.map((item) => {
        const schedule = {
          active: true,
          id: getRandomArbitrary(1),
          startTime: moment.utc(`${item.start_date} ${item.start_time}`, 'YYYY-MM-DD HH:mm').local(),
          endTime: moment.utc(`${item.end_date} ${item.end_time}`, 'YYYY-MM-DD HH:mm').local(),
          type: item.type,
          initialData: {
            id: getRandomArbitrary(1),
            date: moment(item.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            dateEnd: moment(item.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            startTime: moment.utc(`${item.start_date} ${item.start_time}`, 'YYYY-MM-DD HH:mm').local(),
            endTime: moment.utc(`${item.end_date} ${item.end_time}`, 'YYYY-MM-DD HH:mm').local()
          }
        }

        if(item.type === 'weekly'){
          schedule.initialData.weekly = {};
          schedule.initialData.weeklyArr = item.repeats.map(i => ({day: wdays[i], active: true}));
        }

        if(item.type === 'monthly'){
          schedule.initialData.monthly = {};
          schedule.initialData.monthlyArr = item.repeats.map(i => ({day: parseInt(i), active: true}));
        }

        return schedule;
      });

      setSchedules(serverSchedules);
    }

    if(editGroup && editGroup.legacy_fields){
      const id = getRandomArbitrary(0);
      const startTime = moment.utc(editGroup.start_time).local();
      const endTime = startTime.clone()
          .add(editGroup.legacy_fields.duration, 'minutes');
      const endDate = endTime.clone().add(editGroup.legacy_fields.months, 'months');
      const schedule = {
        active: true,
        id,
        startTime: startTime.clone(),
        endTime: endTime.clone(),
        type: 'weekly',
        initialData: {
          id,
          date: startTime.clone().format('DD/MM/YYYY'),
          dateEnd: endDate.format('DD/MM/YYYY'),
          startTime: startTime.clone(),
          endTime: endDate.clone()
        }
      }

      const startOfStartTimeWeek = startTime.startOf('week');
      schedule.initialData.weekly = {};
      schedule.initialData.weeklyArr = editGroup.legacy_fields.week_diffs.map(i =>
          ({day: wdays[startOfStartTimeWeek.clone().add(i, 'minutes').day()], active: true}));

      setSchedules([schedule]);
    }
  }, [editGroup]);

  const showDrop = () =>{
    if(editedData){
      editDrop ? setEditDrop(false) : setEditDrop(true)
    }else{
      visibleDrop ? setVisibleDrop(false) : setVisibleDrop(true)
    }
    forceUpdate()
  }

  const updateSwitcherValueByName = (name, value, setFieldValue) => {
    switch (name) {
      case "recurring":
        setFormSwitcherValue(value);
        break;
      case "available_for_purchases":
        setFormSwitcherPurchaseValue(value);
        break;
      case "in_person":
        setFormSwitcherInPersonValue(value);
        break;
      case "available_for_subscribers":
        setFormSwitcherSubscribersValue(value);
        break;
      case "create_package":
        return setFormSwitcherCreatePackage(value);
        break;
    }

  };

  const getSwitcherValueByName = (name) => {
    switch (name) {
      case "recurring":
        return formSwitcherValue;
      case "available_for_purchases":
        return formSwitcherPurchaseValue;
      case "available_for_subscribers":
        return formSwitcherSubscribersValue;
      case "in_person":
        return formSwitcherInPersonValue;
      case "create_package":
        return formSwitcherCreatePackage;
    }
  };

  if ((action === actionEdit || action === actionDuplicate) && editGroup?.community_id && editGroup.community_id !== community){
    setCommunity(editGroup.community_id)
  }

  useEffect(() => {
    getSubscriptionPackagesList(user.id);

  }, [getSubscriptionPackagesList, user.id]);



  useEffect(() => {
    let distGroupsId = allCommunityValues.filter(longId => longId.includes("distribution_groups")).map(distId => distId.split("_")[2])
    let communitiesId = allCommunityValues.filter(commId => !commId.includes("distribution_groups"))

    let hasTiers = subscriptionPackagesList?.subscription_packages?.filter(
        (pack) => pack?.tiers?.includes(actualGroupType) && (pack.status !== "deactivated")
    )

    let hasDistGroups= distGroupsId.length ?
        subscriptionPackagesList?.subscription_packages?.some(
            (pack) => parseInt(distGroupsId[0]) === pack?.distribution_group_id
        ):
        false

    let hasCommunities= communitiesId.length ?
        subscriptionPackagesList?.subscription_packages?.some(
            (pack) => pack?.communities && pack.communities.some(commId => communitiesId.includes(commId) && hasTiers.some(tiersComm => tiersComm.id == pack.id))
        ):
        false

    if(!hasTiers.length){
      hasTiers = false
    }
    if(hasTiers && (hasDistGroups || hasCommunities)){
      setDisableSubscriptionGroup(false)
      setShowSubscriptionSwitcherError(false)
    }else{
      setDisableSubscriptionGroup(true)
    }

    setCanBuyBySubscribeAll(hasTiers)
    if((editGroup?.subscription_packages_ids && editGroup?.subscription_packages_ids.length) || !editGroup){
      !allCommunityValues.length && setFormSwitcherValue(false)
    }
    forceUpdate()
  }, [subscriptionPackagesList, allCommunityValues, editGroup, actualGroupType]);

  useEffect(() => {
    editGroup && editGroup.group_type.includes("recurring")  && setFormSwitcherValue(true)
  }, [editGroup])

  useEffect(()=>{
    if(selectedCommunities){
      getTags(authModel.session_token, selectedCommunities);
      setErrorsStatePack(null);
      const selectedCommunitiesArray = selectedCommunities.split(',');
      var result = []
      if(canBuyBySubscribeAll){
        result = canBuyBySubscribeAll.filter(pack => pack.communities.some(commun => selectedCommunitiesArray.includes(commun)));
      }
      if(result.length){
        setCanBuyBySubscribe(result);
        setEditedData(result)
      }else{
        if((editGroup?.subscription_packages_ids && editGroup?.subscription_packages_ids.length) || !editGroup){
          setErrorsStatePack("There are no subscription packages for selected communities");
        }
        setCanBuyBySubscribe(null);
        setEditedData(null)
      }

    }else if(editGroup?.communities){
      let communitiesForTags = "";
      editGroup.communities.map((comm,ind) => {if(ind != 0){
        communitiesForTags = communitiesForTags + ", " + comm.id
      }else{
        communitiesForTags += comm.id + ""
      }})
      getTags(authModel.session_token, communitiesForTags);
    }
  },[canBuyBySubscribeAll, editGroup, getTags, selectedCommunities, errorsYupState])

  useEffect(() => {
    setMinMeetings(schedules.length);
  }, [schedules]);

  useEffect(()=>{
    selectedMonths && starttimeFromCalendar &&
    setThemesCount(daysCountInPeriod(moment(starttimeFromCalendar),moment(starttimeFromCalendar).add(selectedMonths, 'months'), [1]));
  },[selectedMonths, starttimeFromCalendar])

  if (createGroupStatus == "ended") {
    setCreateGroupStatus(false);
    setNeedOpenPopupAfterCreateGroup(true);
    document.getElementById("launcher-icon-container").hidden = false;
    action === actionEdit ? history.goBack() : history.push(home);
  }

  const renderTitle = () => {
    let titleAction = "Create";

    if (action === actionEdit) {
      titleAction = "Edit";
    } else if (action === actionDuplicate) {
      titleAction = "Duplicate";
    }

    switch (actualGroupType) {
      case gtSupport:
        return `${titleAction} Group`;

      case gtWebinar:
      case gtRecurringWebinar:
        return `${titleAction} Livestream`;

      case gtMasterMind:
      case gtRecurring:
        return `${titleAction} Group`;

      default:
        return `${titleAction} Group`;
    }
  };

  const renderDialogContent = (setFieldValue, key, oneDayMode, errors) => {
    switch (key) {

      case "tags":
        return(
            <CustomField name={key} key={key} display = {false}>
              <TagSelect
                  communityList = {communityList}
                  selectedCommunities = {selectedCommunities}
                  setSelectedCommunities = {setSelectedCommunities}
                  customChange={(tab) => setFieldValue("tags", tab)}
                  setSelectedTags = {setSelectedTags}
                  selectedTags = {selectedTags}
                  selectedTagsCount = {selectedTagsCount}
                  setSelectedTagsCount = {setSelectedTagsCount}
                  editGroup = {!startedEditing && editGroup}
                  setStartedEditing = {setStartedEditing}
                  action = {action}
              />
            </CustomField>
        );
      case "meetings_count":
        return (
            <CustomField name={key} key={key}>
              <MeetingsCount
                  setCount={(e) => setFieldValue("meetings_count", e)}
                  title="Number of Meetings"
                  className="dialogMeeting"
                  minMeetings={minMeetings}
                  count={1}
              />
            </CustomField>
        );

      default:
        break;
    }
  };

  const renderFormField = (fields, setFieldValue , errors, formData) => {
    formData && setFormDataValuesState(formData.values)
    if(formData?.values?.tags && formData?.values?.communities?.length){
      let intCommunities = [];
      formData.values.communities.map(community => intCommunities.push(parseInt(community?.id ? community.id : community)));
      let tagsCommunitiesId = []
      tags.map(tag => formData.values.tags.some(tagData => tagData.id === tag.id) &&
          tagsCommunitiesId.push(parseInt(tag.communities[0])))
      intCommunities.map(id => {if(!tagsCommunitiesId.includes(id)){
        setErrorsStateTag("Please select tags for all communities");
      }else{
        setErrorsStateTag(null);
      }})
    }else if(formData?.values?.subscription_packages_ids){
      if(formData?.values?.communities){
        if(!subscriptionPackagesList){
          setErrorsStatePack("There are no subscription packages for selected communities")
        }
      }
    }else if(formData?.values?.communities?.length && !formData?.values?.tags && (action !== actionEdit || action !== actionDuplicate)){
      setErrorsStateTag("Please select tags for all communities");
    }
    else{
      if(errors === null){
        setErrorsStateTag(null);
        setErrorsStatePack(null);
      }else if(errors === " "){
        setErrorsStateTag("Please select tags for all communities");
      }
    }
    return (
        fields &&
        fields.length > 0 &&
        fields.map((field, index) => {
          const { fieldType, icon, data, withoutWrappedClass, ...rest} = field;

          switch (fieldType) {
            case "multi-schedule":
              return (
                  <>
                    {formDataValuesState["schedule"] === "" ? <div className="requiredNotification">required</div> : null}
                    <MultipleSchedules
                        editGroup={!!editGroup}
                        onSelect={(e) => {
                          const reccuring = e.weekly || e.monthly;
                          setTimeout(() => setFieldValue("start_date", e.date), 5)
                          if (actualGroupType === gtWebinar || actualGroupType === gtRecurringWebinar) {
                            setFieldValue(
                                "schedule",
                                "Fake data in order to remove error trigger"
                            );

                            setSchedules(data => {
                              const day = reccuring ? {...reccuring[0], endTime: e.endTime } : e;
                              const isUpdating = schedules[0] ? !!schedules.find(s => (s && s.id) === day.id) : false;
                              const time = day.startTime;

                              return isUpdating ? [...data.filter(i => !!i).map(i => i.id === day.id ?
                                      {...day, time: time.format("hh:mm a"), day: time.day(), initialData: {...e}} : i)]
                                  : [...data.filter(i => !!i), {...day, time: time.format("hh:mm a"), day: time.day(), initialData: {...e}}];
                            })

                            if(!reccuring){
                              setThemesCount((count) => count + 1);
                              setWebinarsArr(data => {
                                return [...data.filter(s => s.id !== e.id), {...e, day: e.startTime.day(), time: e.startTime}]
                              });
                            }

                            if(e.weekly){
                              setThemesCount((count) => count + e.weekly.length);
                              setWebinarsArr(data => {
                                return [...data.filter(s => s.id !== e.id), ...e.weekly]
                              });
                            }

                            if (e.monthly){
                              setThemesCount((count) => count + e.monthly.length);
                              setWebinarsArr(data => {
                                return [...data.filter(s => s.id !== e.id), ...e.monthly]
                              });
                            }
                          }
                        }}
                        rest={rest}
                        icon={icon}
                        schedules={schedules}
                        webinars={webinarsArr}
                        setWebinarsArr={setWebinarsArr}
                        setSchedules={(data) => setSchedules(data)}
                    />
                  </>
              );

            case "schedule":
              return (
                  <>
                    {formDataValuesState["schedule"] === "" ? <div className="requiredNotification">required</div> : null}
                    <CreateGroupSchedule
                        editGroup={!!editGroup}
                        onSelect={(e) => {
                          const reccuring = e.weekly || e.monthly;
                          setTimeout(() => {
                            setFieldValue("schedule", e.date);
                            setFieldValue("start_date", e.date);
                          }, 5);
                          setFieldValue(
                              "schedule",
                              "Fake data in order to remove error trigger"
                          );

                          setSchedules(data => {
                            const day = reccuring ? {...reccuring[0], endTime: e.endTime } : e;
                            const isUpdating = schedules[0] ? !!schedules.find(s => (s && s.id) === day.id) : false;
                            const time = day.startTime;

                            return isUpdating ? [...data.filter(i => !!i).map(i => i.id === day.id ?
                                    {...day, time: time.format("hh:mm a"), day: time.day(), initialData: {...e}} : i)]
                                : [...data.filter(i => !!i), {...day, time: time.format("hh:mm a"), day: time.day(), initialData: {...e}}];
                          })

                          if(!reccuring){
                            setThemesCount((count) => count + 1);
                            setWebinarsArr(data => {
                              return [...data.filter(s => s.id !== e.id), {...e, day: e.startTime.day(), time: e.startTime}]
                            });
                          }

                          if(e.weekly){
                            setThemesCount((count) => count + e.weekly.length);
                            setWebinarsArr(data => {
                              return [...data.filter(s => s.id !== e.id), ...e.weekly]
                            });
                          }

                          if (e.monthly){
                            setThemesCount((count) => count + e.monthly.length);
                            setWebinarsArr(data => {
                              return [...data.filter(s => s.id !== e.id), ...e.monthly]
                            });
                          }
                        }}
                        rest={rest}
                        icon={icon}
                        schedule={schedules[0]}
                        webinars={webinarsArr}
                        setWebinarsArr={setWebinarsArr}
                        setSchedules={(data) => setSchedules(data)}
                    />
                  </>);

            case "multi-themes":
              return (
                  <RenderThemeInputs
                      isEdit={action === actionEdit || action === actionDuplicate}
                      amountOfSchedules={schedules.length}
                      rest={rest}
                      key={fieldType + index}
                  />
              );

            case "simple_dependent":
              let inActivePrice = false;
              if(action === actionEdit || action === actionDuplicate){
                if(editGroup.price > 0 && editGroup.participants_count > 0){
                  inActivePrice = true
                }
              }
              return (
                  <>
                    {(formSwitcherPurchaseValue || formSwitcherValue) && (
                        <div className={formDataValuesState["price"] === "" && "selectSimpleRequiredContainer"}>
                          {formDataValuesState["price"] === "" ? <div className="requiredNotification">required</div> : null}
                          <InputWithIcon
                              className={classNames({ width320: !withoutWrappedClass })}
                              icon={icon}
                              key={rest.name}
                          >
                            <CustomInput id={rest.name} {...rest} disabled = {inActivePrice}/>
                          </InputWithIcon>
                        </div>
                    )}
                  </>
              );

            case "simple_dependent_price":
              let inActivePrice1 = false;
              if(action === actionEdit || action === actionDuplicate){
                if(editGroup.price > 0 && editGroup.participants_count > 0){
                  inActivePrice1 = true
                }
              }
              return (
                  <>
                    {
                      <div className={formDataValuesState["price"] === "" && "selectSimpleRequiredContainer"}>
                        {!formDataValuesState[rest.name]? <div className="requiredNotification">required</div> : null}
                        <InputWithIcon
                            className="input_with_icon wrapper"
                            icon={icon}
                            key={rest.name}
                        >
                          <CustomInput
                              id={rest.name} {...rest} disabled = {inActivePrice1}/>
                        </InputWithIcon>
                      </div>
                    }
                  </>
              );

            case "simple":
              return (
                  <div className={formDataValuesState[rest.name] === "" && "selectSimpleRequiredContainer"}>
                    {formDataValuesState[rest.name] === "" ? <div className="requiredNotification">required</div> : null}
                    <InputWithIcon
                        className={classNames({ width320: !withoutWrappedClass })}
                        icon={icon}
                        key={rest.name}
                    >
                      <CustomInput id={rest.name} {...rest} />
                    </InputWithIcon>
                  </div>
              );

            case "simple_recurring_purchase":
              let inActivePriceRec = false;
              if(action === actionEdit || action === actionDuplicate){
                if(editGroup.price > 0 && editGroup.participants_count > 0){
                  inActivePriceRec = true
                }
              }
              return (
                  <>
                    {formSwitcherValue && (
                        <div className={[""].includes(formDataValuesState[rest.name]) && "selectSimpleRequiredContainer"}>
                          {formDataValuesState[rest.name] == 0 ? <div className="requiredNotification">required</div> : null}
                          <InputWithIcon
                              className={classNames({ width320: !withoutWrappedClass })}
                              icon={icon}
                              key={rest.name}
                          >
                            <CustomInput id={rest.name} {...rest} disabled = {inActivePriceRec}/>
                          </InputWithIcon>
                        </div>
                    )
                    }
                  </>
              );

            case "simple_recurring_purchase_price":
              let inActivePriceRec132 = false;
              if(action === actionEdit || action === actionDuplicate){
                if(editGroup.pack_price > 0 && editGroup.participants_count > 0){
                  inActivePriceRec132 = true
                }
              }
              return (
                  <>
                    {formSwitcherCreatePackage && (
                        <div className={[""].includes(formDataValuesState[rest.name]) && "selectSimpleRequiredContainer"}>
                          {!formDataValuesState[rest.name] ? <div className="requiredNotification">required</div> : null}
                          <InputWithIcon
                              className="input_with_icon wrapper"
                              icon={icon}
                              key={rest.name}
                          >
                            <CustomInput id={rest.name} {...rest} disabled = {inActivePriceRec132}/>
                          </InputWithIcon>
                        </div>
                    )
                    }
                  </>
              );

            case "simple_recurring_purchase_meetings":
              let inActivePriceRec2 = false;
              if(action === actionEdit || action === actionDuplicate){
                if(editGroup.price > 0 && editGroup.participants_count > 0){
                  inActivePriceRec2 = true
                }
              }
              return (
                  <>
                    {formSwitcherCreatePackage && (
                        <div className={[""].includes(formDataValuesState[rest.name]) && "selectSimpleRequiredContainer"}>
                          {!formDataValuesState[rest.name] ? <div className="requiredNotification">required</div> : null}
                          <InputWithIcon
                              className="input_with_icon wrapper"
                              icon={icon}
                              key={rest.name}
                          >
                            <CustomInput id={rest.name} {...rest} disabled = {inActivePriceRec2}/>
                          </InputWithIcon>
                        </div>
                    )
                    }
                  </>
              );

            case "simple_recurring_purchase_inPerson":
              return (
                  <>
                    {formSwitcherInPersonValue && (
                        <div className={formDataValuesState["location"] === "" && "selectSimpleRequiredContainer"}>
                          {formDataValuesState["location"] === "" || formDataValuesState["location"] === undefined ? <div className="requiredNotification">required</div> : null}
                          <InputWithIcon
                              className={classNames({ width320: !withoutWrappedClass })}
                              icon={icon}
                              key={rest.name}
                          >
                            <CustomInput id={rest.name} {...rest} onBlur={e => {setLocation(e.target.value)}}/>
                          </InputWithIcon>
                        </div>
                    )}
                  </>
              );

            case "simple_membersList_inPerson":
              return (
                  <>
                    {formSwitcherInPersonValue &&
                        <div className={formDataValuesState["member_limit"] === "" && "selectSimpleRequiredContainer"}>
                          {!formDataValuesState[rest.name]? <div className="requiredNotification">required</div> : null}
                          <InputWithIcon
                              icon={icon}
                              key={rest.name}
                          >
                            <CustomInput
                                id={rest.name} {...rest}/>
                          </InputWithIcon>
                        </div>
                    }
                  </>
              );

            case 'select-month':
              const minMonth = 1,
                  maxMonth = 24
              const newList = []
              if(editGroup?.months && editGroup?.months < minMonth) {
                newList.push(editGroup.months)
              }
              let i = minMonth
              while (i <= maxMonth){
                newList.push(i++)
              }
              return (
                  <div className={Object.keys(errorsYupState).includes(rest.name) && "selectSimpleRequiredContainer"}>
                    {Object.keys(errorsYupState).includes(rest.name) ? <div className="requiredNotification">required</div> : null}
                    <InputWithIcon
                        className={classNames({ width320: !withoutWrappedClass })}
                        icon={icon}
                        key={rest.name}
                    >
                      <CustomSelect
                          customChange={(tab) => {setFieldValue(rest.name, tab); setSelectedMonths(tab)}}
                          data={
                            newList
                                ? newList.map((value) => ({
                                  value: value,
                                  label: `${value} month`,
                                }))
                                : []
                          }
                          id={rest.name}
                          {...rest}
                      />
                    </InputWithIcon>
                  </div>
              )

            case "select-simple":
              return (
                  <div className={formData.values.duration === "" && "selectSimpleRequiredContainer"}>
                    {formData.values.duration === "" ? <div className="requiredNotification">required</div> : null}
                    <InputWithIcon
                        className={classNames({ width320: !withoutWrappedClass })}
                        icon={icon}
                        key={rest.name}
                    >
                      <CustomSelect
                          customChange={(tab) => setFieldValue(rest.name, tab)}
                          data={data.map((value) => ({
                            value: value.value,
                            label: value.title,
                          }))}
                          id={rest.name}
                          {...rest}
                      />
                    </InputWithIcon>
                  </div>
              );

            case "simple-simple-inPerson":
              let defValue = categoryList.length && categoryList[0];
              if((editGroup?.category_id || editGroup?.category) && (action === actionEdit || action === actionDuplicate)) {
                defValue = editGroup.category_id?.id || editGroup?.category?.id;
              }
              return (
                  <>
                    { formSwitcherInPersonValue &&
                        <div className="selectSimpleRequiredContainer">
                          <InputWithIcon
                              className={classNames({width320: !withoutWrappedClass})}
                              icon={icon}
                              key={rest.name}
                          >
                            <CustomSelectMultipleCategory
                                customChange={(tab) => {
                                  setFieldValue(rest.name, tab);
                                  setFieldCategory(tab);
                                }}
                                data={categoryList?.map((item) => ({
                                  value: item.id,
                                  label: item.title,
                                }))}
                                id={rest.name}
                                {...rest}
                                defValue = {defValue}
                                label={rest.placeholder}
                            />
                          </InputWithIcon>
                        </div>
                    }
                  </>
              );

            case "dropdown-checkboxes":
              const extendedShowDrop = () => {
                if(selectedCommunities){
                  if(canBuyBySubscribe || (action === actionEdit || action === actionDuplicate)){
                    return showDrop();
                  }
                  else{
                    setErrorsStatePack("There are no subscription packages for selected communities")
                  }
                }else{
                  if(!subscriptionPackEditGroup){
                    return setErrorsStatePack("Please select communities first")
                  }
                }
              }
              return (
                  <div className={(errorsStatePack || !formDataValuesState?.subscription_packages_ids) && "selectSimpleRequiredContainer"}>
                    {(errorsStatePack || !formDataValuesState?.subscription_packages_ids) && <div className="requiredNotification">required</div>}
                    <InputWithIcon
                        className="input_with_icon wrapper10"
                        icon={icon}
                        key={rest.name}
                    >
                      <DropdownCheckboxes
                          customChange={(tab) => setFieldValue(rest.name, tab)}
                          active = {false}
                          visibleDrop = {(selectedCommunities && canBuyBySubscribe) ? visibleDrop : false}
                          setVisibleDrop = {setVisibleDrop}
                          showDrop = {extendedShowDrop}
                          data = {canBuyBySubscribe}
                          editGroup = {editGroup}
                          setSubscriptionPackEditGroup = {setSubscriptionPackEditGroup}
                          actualGroupType = {actualGroupType}
                          selectedCommunities = {selectedCommunities}
                          setErrorsStatePack = {setErrorsStatePack}
                          editDrop = {editDrop}
                          setEditDrop = {setEditDrop}
                          editedData = {editedData}
                          setEditedData = {setEditedData}
                          {...rest}
                      />
                    </InputWithIcon>
                  </div>
              );

            case "select-multiple":
              let moderatorListData = [];
              JSON.parse(JSON.stringify(communityList))["communities"].map((value) => {

                value.moderator && moderatorListData.push({
                  value: "" + value.id,
                  label: value.title,
                });
              });

              var editGroupCommunities = JSON.parse(JSON.stringify(editGroup))?.communities;
              if(editGroup?.distribution_group){
                let editGroupTagsCommIds = editGroup?.tags ? editGroup?.tags.map(tg => tg.community_id) : false;

                if(editGroupTagsCommIds){
                  (editGroupCommunities = JSON.parse(JSON.stringify(editGroup)).communities.filter(comm => editGroupTagsCommIds.includes(comm.id)))
                } else {
                  editGroupCommunities = []
                }
              }
              return (
                  <div className={(formDataValuesState["communities"] === "" || (Array.isArray(formDataValuesState["communities"])
                      && formDataValuesState["communities"].length === 0)) && "selectSimpleRequiredContainer"}>
                    {(formDataValuesState["communities"] === "" || (Array.isArray(formDataValuesState["communities"]) && formDataValuesState["communities"].length === 0)) ?
                        <div className="requiredNotification">required</div> : null}
                    <InputWithIcon
                        className={classNames({ width320: !withoutWrappedClass })}
                        icon={icon}
                        key={rest.name}
                    >
                      <CustomSelectMultiple
                          customChange={(tab) => {
                            setFieldValue(rest.name, tab);
                            let strngCommunities = "";
                            tab.map((community, ind) => {
                              strngCommunities += community;
                              ind != (tab.length-1) && (strngCommunities += ",")
                            })
                            setSelectedCommunities(strngCommunities)}}
                          setFieldValue = {setFieldValue}
                          data = {moderatorListData}
                          distribution_groups = {distribution_groups?.distribution_groups ? distribution_groups?.distribution_groups : false}
                          id={rest.name}
                          defValue = {((action === "edit" || action === actionDuplicate) && editGroup) ?
                              editGroupCommunities : (moderatorListData.some(comm => comm.value == community) ? [{id:community}] :
                                      false
                              )}
                          defDistGroupId = {action === "edit" && editGroup && JSON.parse(JSON.stringify(editGroup))?.distribution_group?.id}
                          distributionGroupSelected = {distributionGroupSelected}
                          setDistributionGroupSelected = {setDistributionGroupSelected}
                          setAllCommunityValues = {setAllCommunityValues}
                          action = {action}
                          setSelectedCommunities = {setSelectedCommunities}
                          {...rest}
                      />
                    </InputWithIcon>
                  </div>
              );

            case "price":
              return (
                  <InputWithIcon
                      className={classNames({ width320: !withoutWrappedClass })}
                      icon={icon}
                      key={rest.name}
                  >
                    <Price id={rest.name} {...rest} />
                  </InputWithIcon>
              );

            case "dialog":
              if (rest.name === 'start_date' && (action === actionEdit || action === actionDuplicate) && editGroup && ( editGroup.group_type === gtMasterMind || editGroup.group_type === gtRecurring || editGroup.group_type === gtSupport) && editGroup?.past_meetings_count){
                return (<InputWithIcon
                    className={classNames('disabled' ,{ width320: !withoutWrappedClass })}
                    icon={icon}
                    disabled={true}
                >
                  <CustomInput id={rest.name} {...rest}/>
                </InputWithIcon>)
              }
              let setTagsDisplay = () =>{
                if((action === actionEdit || action === actionDuplicate) && !selectedCommunities.length){
                  if(editGroup?.communities){

                    return false
                  }
                  else{

                    return true
                  }
                }else{

                  return distributionGroupSelected && !selectedCommunities.length
                }
              }
              const displayStyle = () => {
                return rest.name === "schedule" ? "inherit" : "flex"
              }
              return (
                  <>
                    {(Object.keys(errorsYupState).includes(rest.name) && !["tags", "start_date", "schedule"].includes(rest.name)) ||
                    (rest.name === "tags" && (formDataValuesState["tags"] === "" || (Array.isArray(formDataValuesState) && formDataValuesState["tags"].length === 0))) ||
                    (["schedule", "start_date"].includes(rest.name) && formDataValuesState[rest.name] === "") ?
                        <div className="requiredNotification">required</div> :
                        null
                    }
                    <Dialog
                        openButton={
                          <div style = {{display:`${!(rest.name === "tags"  && setTagsDisplay()) ? `${displayStyle()}` : "none"}`}}>
                            <InputWithIcon
                                className={classNames({ width320: !withoutWrappedClass}, rest.name === "start_date" && formSwitcherValue === true && "input_with_icon100")}
                                icon={icon}
                                disabled={!!editGroup && rest.name === 'start_date'}

                            >
                              <CustomInput id={rest.name} {...rest} selectedCommunities = {selectedCommunities} editGroup = {editGroup}/>
                              {errorsStateTag && rest.name === "tags" ? <p className = "error tags_error MuiFormHelperText-root Mui-error MuiFormHelperText-filled">{errorsStateTag}</p> : null}
                            </InputWithIcon>
                          </div>
                        }
                        className="payment"
                        key={rest.name}
                    >
                      {renderDialogContent(setFieldValue, field.name)}
                    </Dialog>
                  </>
              );

            case "simple_switcher":

              if(!(user?.stripe_required_fields_filled && user?.payouts_enabled)){

                return (
                    <div className={`switcher_wrapper MuiFormLabel-root ${notVeryfiedMMShowBuyMessage && "notVeryfiedMMShowBuyMessage"}`}>
                      <div style = {{"display" : "flex", "alignItems" : "center"}}>
                        <CustomFormSwitcher
                            styles={{
                              switch: {
                                backgroundColor: formSwitcherValue? titleText[actualGroupType].themeRecurringOn: titleText[actualGroupType].themeColor
                              },
                              track: {
                                backgroundColor: titleText[actualGroupType].themeSubColor
                              }
                            }}
                            disabled = {notVeryfiedMMShowBuyMessage}
                            onClick={(e) => {setNotVeryfiedMMShowBuyMessage(true)}}
                        />
                        <span>{rest.placeholder}</span>
                      </div>
                      {notVeryfiedMMShowBuyMessage &&
                          <div className = "notVeryfiedMMShowBuyMessageContainer">
                      <span>
                        To create paid groups, you must first be verified.
                      </span>
                            <Dialog
                                className="withdrawDialog"
                                openButton={(
                                    <Button
                                        label={"Verify"}
                                        className='creteGroupButton master_mind'
                                        button
                                        violetGradien
                                        smaller100
                                    />
                                )}>
                              <StripeVerification />
                            </Dialog>
                          </div>
                      }
                    </div>

                );
              }
              return (
                  <div className={"switcher_wrapper MuiFormLabel-root"}>
                    <CustomFormSwitcher
                        styles={{
                          switch: {
                            backgroundColor: formSwitcherValue? titleText[actualGroupType].themeColor: titleText[actualGroupType].themeColor
                          },
                          track: {
                            backgroundColor: titleText[actualGroupType].themeSubColor
                          }
                        }}
                        checked={getSwitcherValueByName(rest.name)}
                        onChange={(e) => {
                          const newState = e.target.checked;
                          updateSwitcherValueByName(
                              rest.name,
                              newState,
                              setFieldValue
                          );
                          setTimeout(() => {
                            setFieldValue(rest.name, newState);
                          }, 4); // валидация запускапется по изменению значения. Схема меняется по setFormSwitcherValue (асинхронная).
                        }}
                    />
                    <span>{rest.placeholder}</span>
                  </div>
              );

            case "simple_switcher_inPerson":
              return (<div className={"switcher_wrapper MuiFormLabel-root"}>
                    <CustomFormSwitcher
                        styles={{
                          switch: {
                            backgroundColor: formSwitcherValue? titleText[actualGroupType].themeRecurringOn: titleText[actualGroupType].themeColor
                          },
                          track: {
                            backgroundColor: titleText[actualGroupType].themeSubColor
                          }
                        }}
                        checked={getSwitcherValueByName(rest.name)}
                        onChange={(e) => {
                          const newState = e.target.checked;
                          updateSwitcherValueByName(
                              rest.name,
                              newState,
                              setFieldValue
                          );
                          setTimeout(() => {
                            setFieldValue(rest.name, newState);
                          }, 4); // валидация запускапется по изменению значения. Схема меняется по setFormSwitcherValue (асинхронная).
                        }}
                    />
                    <span>{rest.placeholder}</span>
                  </div>
              );

            case "subscribers_switcher":
              return (
                  <>
                    {canBuyBySubscribe && formSwitcherPurchaseValue && (
                        <div className={"switcher_wrapper MuiFormLabel-root"}>
                          <CustomFormSwitcher
                              styles={{
                                switch: {
                                  backgroundColor: formSwitcherValue? titleText[actualGroupType].themeRecurringOn: titleText[actualGroupType].themeColor
                                },
                                track: {
                                  backgroundColor: titleText[actualGroupType].themeSubColor
                                }
                              }}
                              checked={getSwitcherValueByName(rest.name)}
                              onChange={(e) => {
                                const newState = e.target.checked;
                                updateSwitcherValueByName(rest.name, newState);
                                setTimeout(() => {
                                  setFieldValue(rest.name, newState);
                                }, 4); // валидация запускапется по изменению значения. Схема меняется по setFormSwitcherValue (асинхронная).
                              }}
                          />
                          <span>{rest.placeholder}</span>
                        </div>
                    )}
                  </>
              );
            case "switcher":
              return (
                  <>
                    <div
                        key={rest.name}
                        className={classNames(`${rest.name}_wrapper`, "width320", rest.wrapperColor, {
                          recurring_active: formSwitcherValue,
                        })}
                        style={{marginTop:"10px", paddingBottom:"0"}}
                    >
                      <div className={rest.className}>
                        <CustomFormSwitcher
                            styles={{
                              switch: {
                                backgroundColor: titleText[actualGroupType].themeRecurringOn
                              },
                              track: {
                                backgroundColor: titleText[actualGroupType].themeSubColor
                              }
                            }}
                            checked={getSwitcherValueByName(rest.name)}
                            onChange={(e) => {
                              const newState = e.target.checked;
                              updateSwitcherValueByName(rest.name, newState);
                              setTimeout(() => {
                                setFieldValue(rest.name, newState);
                                setFieldValue('available_for_subscribers', newState);
                              }, 4); // валидация запускапется по изменению значения. Схема меняется по setFormSwitcherValue (асинхронная).
                            }}
                            disabled = {(rest.name === "recurring" && disableSubscriptionGroup)}
                        />
                        <div>{rest.placeholder}</div>
                        {(["recurring", "webinar"].includes(rest.name) && disableSubscriptionGroup) ?
                            <div className = "recurringSwitchOverlay"
                                 onClick = {() => setFormSwitcherValue(prevState => !prevState)}>
                            </div> : null}
                      </div>
                      {formSwitcherValue && (
                          <div className={`${rest.name}_text`}>{rest.text}</div>
                      )}
                      {(showSubscriptionSwitcherError) ?
                          <p className = "error MuiFormHelperText-root Mui-error MuiFormHelperText-filled recurringSwitchOverlayMarginBottom">
                            In order to create Subscription Liveastream group, you should have a package in the selected community
                          </p>
                          : null}

                      {renderFormField(
                          rest.includesTabs[formSwitcherValue],
                          setFieldValue
                      )}
                    </div>
                  </>
              );

            case "switcherSinglePurchasePrice":
              return (
                  <>

                    <div
                        key={rest.name}
                        className={formSwitcherPurchaseValue ? "wrapperSinglePurchasePrice" : "wrapperSinglePurchasePriceDisabled"}
                        style={{borderColor: rest.wrapperColor}}
                    >
                      <div className={formSwitcherPurchaseValue ? "recurringWhite" : rest.className}>
                        <CustomFormSwitcher
                            styles={{
                              switch: {
                                backgroundColor: titleText[actualGroupType].themeColor
                              },
                              track: {
                                backgroundColor: titleText[actualGroupType].themeSubColor
                              }
                            }}
                            checked={getSwitcherValueByName(rest.name)}
                            onChange={(e) => {
                              const newState = e.target.checked;
                              updateSwitcherValueByName(rest.name, newState);
                              setTimeout(() => {
                                setFieldValue(rest.name, newState);
                              }, 4); // валидация запускапется по изменению значения. Схема меняется по setFormSwitcherValue (асинхронная).
                            }}
                            disabled = {(rest.name === "recurring" && disableSubscriptionGroup)}
                        />
                        <div>{rest.placeholder}</div>
                        {(["recurring", "webinar"].includes(rest.name) && disableSubscriptionGroup) ? <div className = "recurringSwitchOverlay" onClick = {() => {setShowSubscriptionSwitcherError(true)}}></div> : null}
                      </div>
                      {renderFormField(
                          rest.includesTabs[formSwitcherPurchaseValue],
                          setFieldValue
                      )}
                    </div>
                  </>
              );

            default:
              break;
          }
        })
    );
  };

  const validationSchema = useMemo(() => {
    let validationObject = new Object(null);

    fieldsData &&
    fieldsData[actualGroupType] &&
    fieldsData[actualGroupType].length > 0 &&
    fieldsData[actualGroupType].forEach((field) => {
      if (field.includesTabs) {
        if(field.name !== 'available_for_purchases'){
          field.includesTabs[formSwitcherValue].forEach((innerField) => {
            validationObject[innerField.name] =
                groupCreateValidations[innerField.name];
          });
        } else {
          field.includesTabs[formSwitcherPurchaseValue].forEach((innerField) => {

            if(innerField.name === 'price'){
              validationObject[innerField.name] =
                  groupCreateValidations[innerField.name];
            } else if (formSwitcherCreatePackage
                && (innerField.name === 'meetings_in_pack' || innerField.name === 'pack_price')){
              validationObject[innerField.name] =
                  groupCreateValidations[innerField.name];
            }
          });
        }
      } else {
        validationObject[field.name] = groupCreateValidations[field.name];
      }
    });
    validationObject["image"] = groupCreateValidations["image"];

    return Yup.object().shape(validationObject);
  }, [actualGroupType, formSwitcherValue, formSwitcherPurchaseValue, formSwitcherCreatePackage]);

  let initialValues = {};

  const initValuesCallback = (field) => {
    switch (field.type) {
      case "image":
        initialValues[field.name] = null;
        break;
      case "meetings_count":
        initialValues[field.name] = 0;
        break;
      case "includesFields":
        initialValues[field.name] = false;
        for (let key in field.includesTabs) {
          field.includesTabs[key].forEach(initValuesCallback);
        }
        break;
      default:
        initialValues[field.name] = "";
        break;
    }
  };


  if ((action === actionEdit || action === actionDuplicate) && editGroup) {
    const groupStartTime = moment(editGroup?.start_time);
    const start_date = groupStartTime.isBefore(moment())
        ? moment().format("DD/MM/YYYY")
        : groupStartTime.format("DD/MM/YYYY");
    const h = groupStartTime.isBefore(moment())
        ? moment().format("hh:mm a")
        : groupStartTime.format("hh:mm a");

    let days;
    if (editGroup.week_diffs){
      days = editGroup.week_diffs
          .slice()
          .map((minDiff) => {
            const d = moment()
                .weekday(1)
                .zone(0)
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0
                })
                .set({minutes: minDiff})
                .utcOffset(moment().utcOffset())
                .weekday()
            return dats3Letters[d]
          })
          .join(", ");
    } else if(editGroup.wdays){
      days = editGroup.wdays
          .slice()
          .map((dayInd) => dats3Letters[dayInd])
          .join(", ");
    }

    const schedule = `${days} ${h}`;

    const membersParticipants =
        groupParticipants?.participants?.map((member) => {
          member["participant"] = true;
          return member;
        }) || [];
    const membersInvites = lastGroup?.invites || [];

    const getPublicType = () => {
      if (editGroup?.group_type === gtRecurring || editGroup?.group_type === gtMasterMind){
        return editGroup?.closed
      } else {
        return editGroup?.private;
      }
    };
    const getTagTitles = (tags) => {
      let result = "";
      tags.map((tag, ind) => {
        if(ind === 0){
          result += tag.title
        }else{
          result = result + ", " + tag.title
        }
      })
      return result;
    };


    initialValues = (actualGroupType === "recurring_webinar" || actualGroupType === "webinar") ?
        {
          ...editGroup,
          remote_image_url: editGroup?.image?.url,
          image: editGroup?.image?.url,
          tags: editGroup?.tags,
          start_date,
          schedule,
          recurring:  actualGroupType === gtRecurring || actualGroupType === gtRecurringWebinar,
          available_for_purchases: editGroup.available_for_purchases,
          members: [...membersParticipants, ...membersInvites],
          public: getPublicType(),
          create_package: editGroup?.create_package,
          price: editGroup?.price == 0 ? "" : editGroup?.price === null ? "" : editGroup?.price / 100,
          pack_price: editGroup?.pack_price == 0 ? "" : editGroup?.pack_price === null ? "" : editGroup?.pack_price / 100,
          meetings_in_pack: editGroup?.meetings_in_pack === 0 ? "" : editGroup?.meetings_in_pack === null ? "" : editGroup?.meetings_in_pack,
        } : {
          ...editGroup,
          remote_image_url: editGroup?.image?.url,
          image: editGroup?.image?.url,
          tags: editGroup?.tags,
          start_date,
          schedule,
          recurring:  actualGroupType === gtRecurring || actualGroupType === gtRecurringWebinar,
          available_for_purchases: editGroup.available_for_purchases,
          members: [...membersParticipants, ...membersInvites],
          public: getPublicType(),
          create_package: editGroup?.create_package,
          price: editGroup?.price == 0 ? "" : editGroup?.price === null ? "" : editGroup?.price / 100,
          pack_price: editGroup?.pack_price == 0 ? "" : editGroup?.pack_price === null ? "" : editGroup?.pack_price / 100,
          meetings_in_pack: editGroup?.meetings_in_pack === 0 ? "" : editGroup?.meetings_in_pack === null ? "" : editGroup?.meetings_in_pack,
          location: location,
        }

    if (editGroup?.group_type === gtRecurring) {
      initialValues["recurring"] = true;
    }
  } else {
    initialValues = {
      image: null,
      members: [],
      public: false,
      lobby: false,
      meetings_count: 0,
      available_for_subscribers: false,
      themes: ''
    };
    fieldsData &&
    fieldsData[actualGroupType] &&
    fieldsData[actualGroupType].length > 0 &&
    fieldsData[actualGroupType].forEach(initValuesCallback);
    initialValues["meetings_count"] = 0;
  }

  const getStartTime = (date, startTime) => {
    const day_month_year_arr = date.split("/").map((i) => parseInt(i));
    let offset = new Date(day_month_year_arr[2],
        day_month_year_arr[1] - 1,
        day_month_year_arr[0],
        startTime.split(":")[0],
        startTime.split(":")[1],
        0, 0
    ).getTimezoneOffset()/60
    let dayOffset = parseInt(startTime.split(":")[0]) - offset
    const selectDate = moment().set({
      'year': day_month_year_arr[2],
      'month': day_month_year_arr[1] - 1,
      'date': day_month_year_arr[0] + (dayOffset > 23 ? -1 : 0) + (dayOffset < 0 ? 1 : 0)
    }).utcOffset(0).format("YYYY-MM-DDT00:00:00.000") + "Z";
    const ISOString = moment(selectDate).toISOString();
    return ISOString.replace(ISOString.substring(11, 16), startTime);
  };

  const getUTCTime = (time) => {
    const date = moment(time, ["h:mm a"]).format();
    return moment.utc(moment(date)).format("HH:mm");
  };
  const submitCallback = (values, actions) => {
    const { schedule, remote_image_url, start_date, ...rest } = values;
    const { setFieldError } = actions;
    const getResponseCb = function () {
      setPending(false);
      setFieldError(...arguments);
    };

    const data = scheduleStrToObj(schedule)

    let isPrivate = switcherWasActivate ? values.public :
        editGroup? editGroup?.private : values.public;

    const remote_image_url_data = remote_image_url
        ? { remote_image_url }
        : {};
    let group_type = "";

    switch (actualGroupType) {
      case gtRecurring:
      case gtMasterMind:
        group_type = formSwitcherValue?  gtRecurring : gtMasterMind;
        break;
      case gtRecurringWebinar:
      case gtWebinar:
        group_type = formSwitcherValue?  gtRecurringWebinar : gtWebinar;
        break;
      case gtSupport:
        group_type = gtSupport;
        break;
      default:
        group_type = actualGroupType;
    }

    let closed = false;

    switch(group_type){
      case gtRecurring:
      case gtMasterMind:
        closed = values.public;
        break;
    }

    const startTime = schedules && schedules[0].startTime.clone().toISOString();

    delete rest.public;
    if (formSwitcherValue) {
      rest.meetings_count =  daysCountInPeriod(moment(startTime), moment(startTime).add(rest.months, 'months'), data.days)
      rest.meetings_in_pack = Number(rest.meetings_in_pack);
    } else {
      delete rest.months;
    }

    const correctPrice = getCorrectPrice(rest.price);
    const pack_price = getCorrectPrice(rest.pack_price);
    const meetingCount = rest.meetings_in_pack;

    let formData = (actualGroupType === "recurring_webinar" || actualGroupType === "webinar") ?
        {
          ...rest,
          ...remote_image_url_data,
          group_type,
          closed,
          price: correctPrice,
          pack_price,
          private: isPrivate,
          start_time: startTime,
          meetings_in_pack: meetingCount,
          available_for_purchases: true,
        } :
        formSwitcherInPersonValue ? {
              ...rest,
              ...remote_image_url_data,
              group_type,
              closed,
              price: correctPrice,
              pack_price,
              private: isPrivate,
              start_time: startTime,
              in_person: formSwitcherInPersonValue,
              location: location,
              category_id: fieldCategory === 0 ? categoryList[0].id : fieldCategory,
              meetings_in_pack: meetingCount,
              available_for_purchases: true,
            } :
            {...rest,
              ...remote_image_url_data,
              group_type,
              closed,
              price: correctPrice,
              pack_price,
              private: isPrivate,
              start_time: startTime,
              in_person: formSwitcherInPersonValue,
              meetings_in_pack: meetingCount,
              available_for_purchases: true,
            };

    if(action === actionEdit || action === actionDuplicate) {
      let newData = {}
      Object.keys(formData).forEach((key)=>{
        if (editGroup[key]){
          if (key === 'image'){
            return JSON.stringify(formData[key]) !== JSON.stringify(editGroup[key].url) && (newData[key] = formData[key] )
          } else if (Number(formData.price) !== 0) {
            newData.price = formData.price;
          } else if( formData.in_person  && !['recurring_webinar', 'webinar'].includes(editGroup.group_type)) {
            newData.in_person = formData.in_person;
            newData.location = formData.location;
            newData.category_id = formData.category_id;
          } else if(!!formData.price !== !!editGroup.price) {
            newData.available_for_purchases = formData.available_for_purchases;
            if(formData.price > 0) {
              newData.price = formData.price;
            }

            if(!!formData.price) {
              newData.create_package = false;
            }
          }  else if(formData.price > 0 && editGroup.price > 0) {
            if(formData.price > 0) {
              newData.price = formData.price;
              if(formSwitcherCreatePackage) {
                newData.pack_price = formData.pack_price;
                newData.meetings_in_pack = formData.meetings_in_pack;
              } else {
                newData.create_package = false;
              }
            }
          } else if( !formData.in_person && editGroup.in_person && !['recurring_webinar', 'webinar'].includes(editGroup.group_type)) {
            newData.in_person = formData.in_person;
          }
          formData[key] !== editGroup[key] && (newData[key] = formData[key] )
        } else if (key === 'members') {
          newData.members = {
            toAdd: difference(formData.members, formData.invites).filter((member) => !member.participant),
            toRemove: difference(formData.invites, formData.members)
          }
        } else if (['price','meetings_in_pack', 'distribution_group_id', 'months', 'private', 'closed', 'available_for_purchases'].includes(key)){
          newData[key] = formData[key]
        } else if(['subscription_packages', 'subscription_packages_ids'].includes(key)){
          newData['subscription_packages_ids'] = formData[key]
        } else if(key === 'recurring_themes' && ['recurring_webinar', 'webinar'].includes(editGroup.group_type)){
          newData['recurring_themes'] = formData[key]
        }
      })

      if(Number(formData.price) === 0 || !formSwitcherPurchaseValue) {
        newData.create_package = false;
        newData.price = 0;
        delete newData.pack_price;
        delete newData.meetings_in_pack;
      }

      if(!formSwitcherCreatePackage) {
        delete newData.pack_price;
        delete newData.meetings_in_pack;
      }

      if(formSwitcherCreatePackage && formData.price > 0 && formSwitcherPurchaseValue){
        newData.create_package = formSwitcherCreatePackage;
        newData.pack_price = formData.pack_price;
        newData.meetings_in_pack = formData.meetings_in_pack;
      }

      formData = newData
    }

    if(schedules){

      const getScheduleType = (item) => {
        if(item.initialData.monthly){
          return "monthly"
        }

        if(item.initialData.weekly){
          return "weekly";
        }

        return "specific_date";
      }

      const getRepeats = (item) => {
        const type = getScheduleType(item);

        if(type === "specific_date"){
          return [];
        }

        if(type === "weekly"){
          return item.initialData.weeklyArr.map(i => wdays.indexOf(i.day));
        }

        if(type === "monthly"){
          return item.initialData.monthlyArr.map(i => i.day);
        }
      };

      const scheduleInfo = schedules.map(item => ({
        "start_date": item.startTime.clone().format("YYYY-MM-DD"),
        "end_date": item.endTime.clone().format("YYYY-MM-DD"),
        "type": getScheduleType(item),
        "start_time": item.startTime.clone().utc().format("HH:mm"),
        "end_time": item.endTime.clone().utc().format("HH:mm"),
        "repeats": getRepeats(item),
      }));

      formData = {
        ...formData,
        start_time: schedules[0].startTime.toISOString(),
        schedule_info: scheduleInfo
      }
    }

    if(webinarsArr.length){
      const meetingsData = webinarsArr.map(i => ({
        "start_time": i.startTime.clone().utc().toISOString(),
        "theme": i.title ? i.title : i.startTime.clone().format('DD.MM.YYYY'),
        "duration": i.endTime.clone().diff(i.startTime.clone(), 'minutes')
      }));
      formData = {
        ...formData,
        start_time: meetingsData[0].start_time,
        meetings_data: meetingsData
      }
    }

    if(action === actionDuplicate &&
        formDataValuesState.group_type !== gtRecurringWebinar &&
        formDataValuesState.group_type !== gtWebinar){
    }

    setPending(true);

    if (action === actionEdit) {
      delete formData.group_type;

      let inviteGuestsArr = [];
      let inviteMembersArr = [];
      inviteGuestsArr = guestList.filter(guest => guest.speaker === undefined);
      inviteMembersArr = membersList.filter(guest => guest.speaker === undefined);
      const participants = [...JSON.parse(JSON.stringify(inviteGuestsArr.map(user => {return {email: user.email, speaker:true}}))),
        ...JSON.parse(JSON.stringify(inviteMembersArr.map(user => {return {email: user.email, speaker:false}})))];
      formData.members = participants;
      formData.participants = participants;
      formData.available_for_purchases = true;

      if(formData.subscription_packages_ids?.length > 0 ) {
        formData.available_for_subscribers = true;
      }

      if(Number(formData.meetings_count) === 0) {
        delete formData.meetings_count;
      }
      updateGroup(editGroup.id, formData, getResponseCb, [...removedGuestsList, ...removedMembersList]);
    } else {
      if(formData.meetings_in_pack === 0) {
        delete formData.meetings_in_pack;
      }

      if(formData.pack_price === 0) {
        delete formData.pack_price;
      }

      if(formData.create_package === '') {
        delete formData.create_package;
      }

      if(formData.meetings_count === 0) {
        delete formData.meetings_count;
      }

      if (formData.group_type === 'recurring_webinar' || formData.group_type === 'webinar') {
        delete formData.communities;
      }

      let inviteGuestsArr = [];
      let inviteMembersArr = [];

      inviteGuestsArr = guestList.map(guest => {
        return {...guest, speaker: true}
      });

      inviteMembersArr = membersList.map(guest => {
        return {...guest, speaker: false}
      });

      const participants = [...JSON.parse(JSON.stringify(inviteGuestsArr)), ...JSON.parse(JSON.stringify(inviteMembersArr))];
      formData.members = participants;
      formData.participants = participants.map(user => {return {email: user.email, speaker:user.speaker}});
      formData.available_for_purchases = true;

      if(action === actionDuplicate) {

        if(!formData.hasOwnProperty('name')) {
          formData.name = formDataValuesState.name;
        }

        if(!formData.hasOwnProperty('group_type')) {
          formData.group_type = formDataValuesState.group_type;
        }

        if(!formData.hasOwnProperty('tags')) {
          formData.tags = formDataValuesState.tags;
        }

        if(!formData.hasOwnProperty('image')) {
          formData.image = formDataValuesState.image;
        }

        if(!formData.description) {
          formData.description = formDataValuesState.description;
        }

        if(!formData.subscription_packages_ids && formDataValuesState?.subscription_packages_ids?.length > 0) {
          formData.subscription_packages_ids = formDataValuesState.subscription_packages_ids;
        }

        if(!formData.private) {
          formData.private = formDataValuesState.private;
        }

        if (!formData.meetings_data) {
          formData.meetings_data = generateNewMeetingsData(formDataValuesState.schedule_info);
        }

        if(!!formData.in_person) {
          formData.member_limit = formDataValuesState.member_limit;
        }

        createGroup(formData, getResponseCb);
      } else {
        createGroup(formData, getResponseCb);
      }
    }
  };
  const customValidate = formData => {
    const errors = {}

    if(formData.tags && formData.communities){
      let intCommunities = [];
      formData.communities.map(community => community?.id ? intCommunities.push(community.id) : intCommunities.push(parseInt(community)));
      let tagsCommunitiesId = [];
      tags.map(tag => formData.tags.some(newTag => newTag.id === tag.id) && tagsCommunitiesId.push(parseInt(tag.communities[0])))
      intCommunities.map(id => {if(!tagsCommunitiesId.includes(id)){
        errors.tags = " ";
      }else{
        setErrorsStateTag(null);
        forceUpdate()
      }})
    }

    if(formData.tags === ''){
      errors.tags = " ";
    }

    if(formData.available_for_subscribers === true && (formData.subscription_packages_ids === '' || !formData.subscription_packages_ids?.length)){
      errors.subscription_packages_ids = 'Select subscription package'
    }

    return errors
  }

  return (
      <div className="create_group_content">
        <div className="upload_video_content_title">{renderTitle()}</div>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitCallback}
            isInitialValid={action === actionEdit || action === actionDuplicate}
            validate = {customValidate}
        >
          {(formData) => {
            const { values, setFieldValue, isValid, errors } = formData; // errors, touched,
            if((values.meetings_count !== webinarsArr.length) && webinarsArr.length){
              setFieldValue('meetings_count', webinarsArr.length);
            }

            setErrorsYupState(errors);
            return (
                <div className="upload_video_content_form">
                  <Form>
                    <CustomizedSwitche
                        color={titleText[actualGroupType]?.themeColor}
                        falseLabel={titleText[actualGroupType]?.privateLabel}
                        trueLabel={titleText[actualGroupType]?.publicLabel}
                        name="public"
                        onSwitch={(e) => {
                          setSwitcherWasActivate(true);
                          setFieldValue("public", e);
                        }}
                        alpha={actualGroupType == gtMasterMind}
                    />
                    <div>{formSwitcherInPersonValue && values?.public && "Private Group Sessions are by invite only."}</div>
                    <div>{formSwitcherInPersonValue && !values?.public && "Public Group Sessions are viewed by all users."}</div>
                    <div>{!formSwitcherInPersonValue && values?.public && titleText[actualGroupType]?.public}</div>
                    <div>{!formSwitcherInPersonValue && !values?.public && titleText[actualGroupType]?.private}</div>
                    <div style={{marginBottom:"30px"}}></div>
                    <div className="upload_video_content_data">
                      <div className="upload_video_content_data_fields">
                        {renderFormField(
                            fieldsData[actualGroupType],
                            setFieldValue,
                            errors?.tags,
                            formData
                        )}
                      </div>
                      <div className={`uploda_video_content_data_cover ${!values.image && "requiredNotificationContainer"}`}>
                        <UploadImage
                            color={titleText[actualGroupType]?.themeColor}
                            title="Upload Group Image"
                            image={values.image}
                            setImage={(e) => {
                              setFieldValue("image", e);
                              setFieldValue("remote_image_url", null);
                            }}
                            image_url={values.remote_image_url}
                        />
                        <div className="error image">
                          <ErrorMessage name="image" />
                        </div>
                        {/*{(actualGroupType === "webinar" || actualGroupType === "recurring_webinar") &&*/}
                            <div className="uploda_video_content_data_invite">
                              <InviteGuests
                                  color={titleText[actualGroupType]?.themeColor}
                                  invites={guestList}
                                  action={action}
                                  onClick={(data) => setGuestList(data)}
                                  removedGuestsList={removedGuestsList}
                                  setRemovedGuestsList={setRemovedGuestsList}
                                  selectedCommunities={selectedCommunities}
                              />
                            </div>
                        {/* }*/}
                        {(actualGroupType === "webinar" || actualGroupType === "recurring_webinar") && formData.values.public &&
                            <div className="uploda_video_content_data_invite">
                              <InviteMembers
                                  color={titleText[actualGroupType]?.themeColor}
                                  invites={membersList}
                                  action={action}
                                  onClick={(data) => setMembersList(data)}
                                  removedMembersList={removedMembersList}
                                  setRemovedMembersList={setRemovedMembersList}
                                  selectedCommunities={selectedCommunities}
                              />
                            </div>
                        }
                        {(actualGroupType !== "webinar" && actualGroupType !== "recurring_webinar") &&
                            <div className="uploda_video_content_data_invite">
                              <InviteMembers
                                  color={titleText[actualGroupType]?.themeColor}
                                  invites={membersList}
                                  action={action}
                                  onClick={(data) => setMembersList(data)}
                                  removedMembersList={removedMembersList}
                                  setRemovedMembersList={setRemovedMembersList}
                                  selectedCommunities={selectedCommunities}
                              />
                            </div>
                        }
                        {(actualGroupType === "webinar" || actualGroupType === "recurring_webinar") && isNewThemes &&
                            <NewThemesSubscriptionGroup
                                isEdit={action === actionEdit || action === actionDuplicate}
                                amountOfSchedules={themesCount}
                                minMeetings = {themesCount}
                                rest={{name:"themes"}}
                                color={titleText[actualGroupType]?.themeColor}
                                setWebinarsArr={setWebinarsArr}
                                webinarsArr={webinarsArr}
                                schedules = {schedules}
                                starttimeFromCalendar = {starttimeFromCalendar}
                                editGroup = {true}
                                setErrorStateThemesSubGroup = {setErrorStateThemesSubGroup}
                            />}


                        <div className={'vinod'}>
                          <CustomizedSwitche
                              color={titleText[actualGroupType]?.themeColor}
                              falseLabel={'Lobby Disabled'}
                              trueLabel={'Lobby Enabled'}
                              name="lobby"
                              onSwitch={(e) => {
                                setSwitcherWasActivate(true);
                                setFieldValue("lobby", e);
                              }}
                              alpha={actualGroupType == gtMasterMind}
                          />
                        </div>
                      </div>
                    </div>
                    {pending && <CircularProgress />}
                    <div className="upload_video_content_buttons">
                      <Button
                          type="submit"
                          label={action == actionEdit ? "Update" : "Create"}
                          violetGradien
                          disabled={!isValid && errorsStatePack?.length && errorStateThemesSubGroup}
                      />
                      <Button
                          label="Cancel"
                          className="upload_video_content_cancel"
                          onClick={() => history.goBack()}
                          // disabled={!!createGroupStatus}
                      />
                    </div>
                  </Form>
                </div>
            );
          }}
        </Formik>
        {(editedData ? editDrop : visibleDrop) && <div className = "dropdownCheckboxes_overlay" onClick = {showDrop}></div>}
      </div>
  );
};

export default inject(
    "userModel",
    "tagsModel",
    "groupsModel",
    "fileModel",
    "subscribePackageModel",
    'communityModel',
    'authModel'
)(observer(GroupForm));
