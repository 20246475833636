import React, { useState } from "react";
import Dialog from "../Dialog";
import AddPMIcon from "../../res/iconComp/AddPMIcon";
import WadningIcon from "../../res/iconComp/WarningIcon";
import Checkbox from "@material-ui/core/Checkbox";
import SelectedIcon from "../../res/iconComp/SelectedIcon";
import UnselectedIcon from "../../res/iconComp/UnselectedIcon";

import ConectStripe from "../CardForm/ConectStripe";
import CardForm from "../CardForm";
import BankAccountForm from "../BankAccountForm";
import Divider from "@material-ui/core/Divider";

import "./addPayment.scss";

const AddPaymentMethod = (props) => {
  const { title, variant} = props;

  return (
    <>
      <Dialog
        openButton={
          <div className="addPaymentMetod">
            <AddPMIcon />
            <div className="title_block">{title}</div>
          </div>
        }
        className="payment"
      >
        <AddPaymentMethodForm title={title} variant={variant} />
      </Dialog>
      <Divider />
    </>
  );
};

export default AddPaymentMethod;


export const AddPaymentMethodForm = ({ title = "Add Payment Method", variant, close }) => {
  const [type, setType] = useState("card");

  return (
    <div className="add_payment_method_modal_content">
      {title && <h2 className="title">{title}</h2>}
      <div className="select_type">
        <div className="select_type_item">
          <Checkbox
            icon={<UnselectedIcon />}
            checkedIcon={<SelectedIcon />}
            checked={type === "card"}
            onClick={() => setType("card")}
          />
          <span>{variant === 'payout' && 'Debit'} Card</span>
        </div>

        <div className="select_type_item">
          <Checkbox
            icon={<UnselectedIcon />}
            checkedIcon={<SelectedIcon />}
            checked={type === "bank"}
            onClick={() => setType("bank")}
          />
          <span>Bank Account</span>
        </div>
      </div>
      <ConectStripe>
        {type === "card" ? (
          <CardForm variant={variant} cvc label={false} close={close} />
        ) : (
          <BankAccountForm close={close} />
        )}
      </ConectStripe>
    </div>
  );
};

