import React, { memo } from 'react';
import classNames from 'classnames';
import "./button.scss"

const Button = props => {
  const {
    label,
    onClick,
    disabled = false,
    className = '',
    type = "button",
    active = true,
    width100= false,
    width180 = false,
    big60 = false,
    blue = false,
    violetGradien = false, 
    whiteGradient = false,
    green = false,
    small = false,
    smaller130 = false,
    smaller110 = false,
    grey = false,
    greyGradient = false,
    yellowGradient = false,
    blueGradient = false,
    orangeGradient  = false,
    lightBlueGradient = false,
    redGradient = false,
    whiteViolet = false,
    ...rest
  } = props;

  // console.log('label => ', label, "className", classNames(
  //     'button',
  //     { lightBlueGradient, orangeGradient, greyGradient, violetGradien, width100, width180, whiteGradient, big60, green, small, smaller130,
  //       smaller110, grey, yellowGradient, blueGradient, blue, redGradient },
  //     { disactive: disabled }
  // ));
  return (
    <div className={classNames('button-wrapper', className, { width100 })}>
      <button
        onClick={active && onClick}
        className={
          classNames(
            'button',
            { lightBlueGradient, orangeGradient, greyGradient, violetGradien, width100, width180, whiteGradient, whiteViolet, big60, green, small, smaller130,
              smaller110, grey, yellowGradient, blueGradient, blue, redGradient },
            { disactive: disabled }
          )
        }
        type={type}
        disabled={disabled}
        {...rest}
      >
        {label}
      </button>
    </div>
  )
}

export default memo(Button);
