import React from 'react';
import { Field } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const CustomSelect = props => {
  const { data, value, handleChange, className,} = props;

  return (
    <Field name="country" >
    {({field: { name, onBlur }, form: { errors}, meta}) => {
      return (
        <FormControl error={meta.touched && !!errors[name]} className={className}>
          <Select
            labelId="select-label"
            id="select"
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            renderValue={(v) => value}
            onBlur={onBlur}
            MenuProps={{ className: "codeSelect" }}
          >
            {data.map((item) => (
              <MenuItem
                value={item.value}
                key={`${item.value}-${item.label}`}
                className="codeItem"
              >
                <div className="itemLabel">{item.label}</div>
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{meta.touched && errors[name]}</FormHelperText>
        </FormControl>
      );
    }}
  </Field>
  )
};

export default CustomSelect;
