import React from "react";
import CurrentAvatar from "../../../../components/CurrentAvatar";
import moment from "moment";
import './subsctibedCardItem.scss'
import {returnName} from "../../../../units/func";


const SubscribedCardItem = (props) => {
  const {
    item:{
      price,
      owner,
      active_to,
      created_at,
      name,
      payment_source
    },
    item,
    subscribeSettings=(i)=>{}
  } = props;

  const MMName = returnName(owner),
    image = owner?.image?.url,
    time = moment(created_at).format('HH:mm:ss.sssZ'),
    date = moment(`${active_to}T${time}`).format('DD MMM YYYY hh:mm a')

  const handleToggleSubscribeSettings = (e) => {
    e.preventDefault()
    subscribeSettings(item)
  }

  return <div className={'subscribedCard'}>
    <div className="subscribedCard_header">
      <div className="subscribedCard_ownerData">
        <div className="subscribedCard_ownerAvatar">
          <CurrentAvatar
            alt={MMName}
            src={image}
          />
        </div>
        <div className={'subscribedCard_ownerWrapper'}>
          <span>package of</span>
          <div className="subscribedCard_ownerName">{MMName}</div>
        </div>
      </div>
      <div className="subscribedCard_settingsBtn"><button onClick={handleToggleSubscribeSettings}>Subscription Settings</button></div>
    </div>
    <div className="subscribedCard_packageName">
      "{name}"
    </div>
    <div className="subscribedCard_packageData">
      <span>{date} </span> | <span> {payment_source.brand} - {payment_source.last4}</span>
    </div>
    <div className="subscribedCard_bottom">
      <span className="subscribedCard_period">Monthly Subscription</span>
      <span className={'bold'}>${(price/100).toFixed(2)}</span>
    </div>
  </div>
}

export default SubscribedCardItem