import { observable, decorate } from "mobx";
import { countryModel } from "./country";
import { authModel } from "./auth";
import { userModel } from "./user";
import { questionsModel } from "./questions";
import { answersModel } from "./answers";
import { groupsModel } from "./groups";
import { masterMindsModel } from "./masterminds";
import { cardsModel } from "./cards";
import { purchasesModel } from "./purchases";
import { subscribeModel } from "./subsctibe";
import { transactionsModel } from "./transactions";
import { pageModel } from "./page";
import { chatsModel } from "./chats";
import { postsModel } from "./post";
import { meetingsModel } from "./meetings";
import { mmContextModel } from "./mmcontext";
import { mediaModel } from "./media";
import { messagesModel } from "./messages";
import { enablexModel } from "./enablex";
import { groupSocketModel } from "./groupSocket";
import { tagsModel } from "./tags";
import { fileModel } from "./file";
import { inviteMembersModel } from "./inviteMembers";
import { notificationsModel } from "./notifications";
import { searchModel } from "./search";
import { fileUploadingModel } from "./fileUploading";
import { bankAccountsModel } from "./bankAccounts";
import { subscribePackageModel } from './subscriptionPackages';
import { communityModel } from './community';
import { playlistModel } from './playlists';
import { LoaderControllerModel } from './loader'
import { HelpersControllerModel } from './helpers';
import { supportModel } from './support';

export class RootModel {
  countryModel = countryModel;
  authModel = authModel;
  userModel = userModel;
  questionsModel = questionsModel;
  answersModel = answersModel;
  groupsModel = groupsModel;
  masterMindsModel = masterMindsModel;
  cardsModel = cardsModel;
  purchasesModel = purchasesModel;
  subscribeModel = subscribeModel;
  transactionsModel = transactionsModel;
  pageModel = pageModel;
  chatsModel = chatsModel;
  postsModel = postsModel;
  meetingsModel = meetingsModel;
  mmContextModel = mmContextModel;
  mediaModel = mediaModel;
  messagesModel = messagesModel;
  enablexModel = enablexModel;
  groupSocketModel = groupSocketModel;
  tagsModel = tagsModel;
  fileModel = fileModel;
  inviteMembersModel = inviteMembersModel;
  notificationsModel = notificationsModel;
  searchModel = searchModel;
  fileUploadingModel = fileUploadingModel;
  bankAccountsModel = bankAccountsModel;
  subscribePackageModel = subscribePackageModel;
  communityModel = communityModel;
  playlistModel = playlistModel;
  LoaderControllerModel = LoaderControllerModel;
  HelpersControllerModel = HelpersControllerModel;
  supportModel = supportModel;
}

decorate(RootModel, {
  countryModel: observable,
  authModel: observable,
  userModel: observable,
  questionsModel: observable,
  answersModel: observable,
  groupsModel: observable,
  masterMindsModel: observable,
  cardsModel: observable,
  purchasesModel: observable,
  subscribeModel: observable,
  transactionsModel: observable,
  pageModel: observable,
  chatsModel: observable,
  postsModel: observable,
  meetingsModel: observable,
  mmContextModel: observable,
  mediaModel: observable,
  messagesModel: observable,
  enablexModel: observable,
  groupSocketModel: observable,
  tagsModel: observable,
  fileModel: observable,
  inviteMembersModel: observable,
  notificationsModel: observable,
  searchModel: observable,
  fileUploadingModel: observable,
  bankAccountsModel: observable,
  subscribePackageModel: observable,
  communityModel: observable,
  playlistModel: observable,
  LoaderControllerModel: observable,
  HelpersControllerModel: observable,
  supportModel: observable,
});

export const rootModel = new RootModel();
