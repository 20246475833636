import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import useDidMount from "@rooks/use-did-mount"
import Button from '../../../components/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';

import { menuList1, menuList2, menuList3 } from '../../../const/search';

import './menu.scss'

const BuildMenu = ({ data, selected, handleChange }) => (
  <List>
    {data.map(item => (
      <ListItem key={item.key}>
        <ListItemIcon>
          <Checkbox
            size="medium"
            color="default"
            checked={selected.includes(item.key)}
            onChange={() => handleChange(item.key)}
          />
        </ListItemIcon>
        <ListItemText primary={item.label} />
      </ListItem>
    ))}
  </List>
)

const MainMenu = ({ applySearch }) => {
  const [search, setSearch] = useState([])

  useDidMount(() => {
    const firstMenuList = menuList1.map(({ key }) => key)
    const secondMenuList = menuList2.map(({ key }) => key)
    const thirdMenuList = menuList3.map(({ key }) => key)
    const defaultParams = [...firstMenuList, ...secondMenuList, ...thirdMenuList]
    setSearch(defaultParams)
    applySearch(defaultParams)
  })

  const handleChange = (value) => {
    if (search.includes(value)) {
      const array = [...search];
      const index = array.indexOf(value)
      if (index !== -1) {
        array.splice(index, 1);
        setSearch(array);
      }
    } else {
      setSearch([...search, value])
    }
  }

  const handleApply = () => applySearch(search)

  return (
    <nav className="navMenuStyles">
      <div>
        <BuildMenu data={menuList1} selected={search} handleChange={handleChange} />
        <Divider className="dividerStyles" />
        <BuildMenu data={menuList2} selected={search} handleChange={handleChange} />
      </div>
      <Divider className="dividerStyles" />
      <BuildMenu data={menuList3} selected={search} handleChange={handleChange} />
      <div className="searchButtonStyles">
        <Button
          width180
          blueGradient
          label="Apply"
          onClick={handleApply}
        />
      </div>
    </nav>
  );
}

export default inject('userModel', 'subscribeModel')(observer(MainMenu));
