import React, {useState, useEffect, useMemo} from 'react';
import "./style.scss";

const Weekly = props => {
    const {
        day,
        month,
        year,
        dayEndDate,
        monthEndDate,
        yearEndDate,
        isUpdating,
        weeklyArr,
        setWeeklyArr,
    } = props;

    const wdays = useMemo(() => ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"], []);
    const [weekDays, setWeekDays] = useState([]);

    useEffect(() => {
        setWeekDays([...getActiveWeekDays()]);
    }, [day, month, year, dayEndDate, monthEndDate, yearEndDate])

    useEffect(() => {
        if(!isUpdating){
            return;
        }

        const days = wdays.map(item => {
            const updateDay = weeklyArr.find(i => item === i.day);

            if(updateDay){
                return updateDay;
            } else {
                return { day: item, disable: false, active: false };
            }
        });

        setWeekDays(days);

    }, [isUpdating, wdays]);

    function getActiveWeekDays () {
        const date = new Date(+year, month-1, +day);

        if(yearEndDate - year !== 0 || monthEndDate - month !== 0 || dayEndDate - day >= 7) {
            const newArr = wdays.map(item => {
                if(item === wdays[date.getDay()]) {
                    return { day: item, disable: true, active: true };
                } else {
                    return { day: item, disable: false, active: false };
                }
            });

            if(!isUpdating){
                setWeeklyArr(newArr.filter(item => item.active));
            }
            return newArr;
        }

        if(dayEndDate - day < 7) {
            const arrDays = [];

               for (let i = day; i <= +dayEndDate; i++) {
                   const currentDate = new Date(+year, month - 1, +i);

                   if (wdays[date.getDay()] === wdays[currentDate.getDay()]) {
                       arrDays.push({ day: wdays[currentDate.getDay()], disable: true, active: true });
                   } else {
                       arrDays.push({ day: wdays[currentDate.getDay()], disable: false, active: false });
                   }
               }

         const newArr = wdays.map(item => {
            const el  = arrDays.find(el => el.day === item);

              if(el) {
                  return el;
              } else {
                  return { day:item, disable:true, active:false };
              }
            });

            if(!isUpdating){
                setWeeklyArr(newArr.filter(item => item.active));
            }
            return newArr;
        }
    }

    function addActiveDay (event) {
       const notActive = weekDays.find(item => event.currentTarget.innerHTML === item.day && item.disable);
       if(notActive) return;

        const Active = weekDays.map(item => {
            if(event.currentTarget.innerHTML === item.day) {
                if(item.active) {
                    item.active = false;
                } else {
                    item.active = true;
                }
            }

            return item;
        });
        setWeeklyArr(Active.filter(item => item.active));
        setWeekDays([...Active])
    }

    return <div className="weekly">
        {weekDays.map((item, index) => (
            <div
                className={
                item.active && item.disable ? "weeklyDay weeklyDayActiveStatic" :
                item.active && !item.disable ? "weeklyDay weeklyDayActive" :
                item.disable ? "weeklyDay weeklyDayDisabled"  : "weeklyDay" }
                key={item+index}
                onClick={(event) => addActiveDay(event)}>
                {item.day}
            </div>

        ))}
    </div>
}

export default Weekly;
