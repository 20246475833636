import React, {useEffect, useMemo, useState} from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import "./styles.scss"

import {
  mmRole,
  tabTitleAll,
  tabTitleSaved,
  tabMy,
  tabSaved,
  myLivestreams,
  tabTitleMy,
  tabAll,
  tabTitleUpload,
  titleLiveStreams
} from '../../const/general';
import CustomButtonGroup from '../UI/ButtonGroups';
import AddVideoContent from '../AddVideoContent/AddVideoContent';
import CommunityFilter from '../../pages/Dashboard/Daily/filters/CommunityFilter';
import sortBy from "lodash/sortBy";
import moment from "moment";
import {checkCommunityIncludeInFilters} from "../../units/func";

const FilterContent = (props) => {

  const {
     data, userId, role, 
     type, title, groupsModel, 
     children, exeptions = null,
     fileUploadingModel: {isUploading}
    
    } = props
  function getMyTabCorrectTitle (title){
    return `${tabTitleMy} ${title}`
  }
  const [viewBlock, setViewBlock] = useState(!isUploading ? tabTitleAll : `${getMyTabCorrectTitle(title)}`);
  const buttonsList = role !== mmRole ? [tabTitleAll] : [tabTitleAll, `${getMyTabCorrectTitle(title)}`];
  const filters = toJS(groupsModel.state.filters.communities);

  const [currentGroupId, setCurrentGroupId] = useState(null)
  const [currentGroupData, setCurrentGroupData] = useState(null)
  let tab;
  let filtered;

  const filteredContentData = useMemo(_ => {
    return sortBy(data, group => {
      return moment(group.start_time || group.created_at).unix()
    }).reverse()
  }, [data])

  useEffect(_ => {
    if (currentGroupId) {
      setCurrentGroupData(_=> data.find( item => item.id === currentGroupId.id &&
        item.group_type === currentGroupId.group_type &&
        item.record_type === currentGroupId.record_type
      ))
    } else {
      setCurrentGroupData(null)
    }
  }, [data, currentGroupId])

  switch (viewBlock) {
    case tabTitleAll:
      // all records
      tab = tabAll;
      filtered = filteredContentData.filter(item => {
        const isType = (item?.record_type === type || item?.group_type === type);
        const isOwner = item?.owner?.id == userId;
        const isRemoved = item?.removed && item.hasOwnProperty('removed');
        const isBuy = item.subscribed || item.purchase;
        return (checkCommunityIncludeInFilters(item, filters) && !isOwner && isType)
      });
      break;
    case tabTitleSaved:
      // saved records
      tab = tabSaved;
      filtered = filteredContentData.filter(item => {
        const isType = (item.record_type === type || item.group_type === type);
        const isRemoved = item.removed;
        const isBuy = item.subscribed || item.purchase;
        return (!isRemoved && checkCommunityIncludeInFilters(item, filters) && isBuy && isType)});
      break;
    default:
      // my records
      tab = tabMy;
      filtered = filteredContentData.filter(item => {
        const isType = (item.record_type === type || item.group_type === type);
        const isOwner = item.owner?.id == userId;
        return ( isOwner && isType) // !item.removed &&
      });
      break;
  }

  if (exeptions?.id) {
    filtered.push(exeptions);
  }

  return (
    <>
      {children !== undefined && (
        <>
          <div className="titleBlock workshops lsrecordsList">
            <h3>{title}</h3>
            <CustomButtonGroup buttonsList={buttonsList} active={viewBlock} changeActive={setViewBlock} />
            <div className="filterBlock">
              {/*<CommunityFilter action='recordsGroups' />*/}
            </div>
            {/*(viewBlock.split(' ')[0] === tabTitleUpload) && (
              <div style={{ marginTop: 10, width: '100%' }}>
                <AddVideoContent record_type={type} />
              </div>
            )*/}
          </div>
          {React.cloneElement(children, { data: filtered, userId: userId, tab , setCurrentGroupId, currentGroupData })}
        </>
      )}
    </>
  );
}

export default inject('groupsModel', 'fileUploadingModel')(observer(FilterContent))
