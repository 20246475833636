import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import AlertIcon from '../../../res/iconComp/AlertIcon';
import Divider from '@material-ui/core/Divider';
import Button from '../../../components/Button';
import CurrentAvatar from '../../../components/CurrentAvatar';
import FbImageLibrary from 'react-fb-image-grid';
import YouTube from 'react-youtube';
import HeartIcon from '../../../res/iconComp/HeartIcon';
import ShareArrowIcon from '../../../res/iconComp/ShareArrowIcon'; 
import SendComment from '../../../res/iconComp/SendComment';

import { redirectToAPP, returnName } from '../../../units/func';

import './posts.scss';

const Posts = props => {
  const { 
    posts,
    postsModel: { unlikePost, likePost },
    userModel: { user }
  } = props;

  const opts = {
    height: '255',
    width: '415',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const getPostDate = (date) => {
    let yearsDiff = moment(new Date()).diff(moment(date), "years");
    let dateFormat = yearsDiff >= 1 ? "MMMM DD, yyyy [at] h:mm A" : "MMMM DD [at] h:mm A"; // if post date was more then year ago => display date with year;
      return moment(date).calendar( null, {
        sameDay:  '[Today at] h:mm A',            
        sameElse: function () {
          return dateFormat;
        }
    });
  }

  return (
    <div className="postsBlock">
      {posts.posts.map(post => {
        const ownerName = returnName(post.owner);
        return (
          <div className="postBlock" key={post.id} >
            <div className="postHeader">
              <CurrentAvatar
                alt={ownerName}
                src={post.owner.image && post.owner.image.thumbnail.url}
                className="avatar"
              />
              <div className="usetData">
                <div className="userTitle">{ownerName}</div>
                <div className="postDate">{getPostDate(post.updated_at)}</div>
              </div>
              {/*user.id !== post.owner.id && 
                <div className="disBlock">
                  <IconButton
                    onClick={() => redirectToAPP(`ascend.video://post/${post.id}/report_abuse`)}
                    classes={{ root: "profileActionsIcon" }}
                  >
                    <AlertIcon color="#7E7D8F" />
                  </IconButton>
                </div>
              */}
            </div>
          {/* i think this is not need <div className="postTitle">Proident ut consequat</div> */}
            <div className="postDesc">{post.description}</div>
            {post.attachments.length ? 
              <FbImageLibrary 
                images={post.attachments.map(img => img.url)}
                countFrom={post.attachments.length < 5 ? post.attachments.length : 5}
                className="madiaBlock"
              /> : null
            }
            {post.description && post.description.includes('://youtu.be/') && 
              <YouTube
                videoId={post.description.split('youtu.be/')[1].split(' ')[0]}
                opts={opts}
                onReady={_onReady}
                className="madiaBlock"
              />
            }
            <div className="socialBlock">
              <Button
                className="like"
                onClick={post.is_liked_me ? () => unlikePost(post.id) : () => likePost(post.id)}
                label={<><HeartIcon color={post.is_liked_me ? '#FF2D55' : "#A1A0AE"}/>&nbsp;&nbsp;Like</>}
              />
              {/*<div
                className="share"
                onClick={() => redirectToAPP(`ascend.video://post/${post.id}/share`)}
              >
                <ShareArrowIcon color="#A1A0AE" />&nbsp;&nbsp;Share
              </div>*/}
              <div className="liked">{`${post.likes_count} likes, ${post.comments_count} comments`}</div>
            </div>
            <Divider />
            {/*<form onClick={() => redirectToAPP(`ascend.video://post/${post.id}/feed`, `ascend.video://feed`)}>
              <input placeholder="Enter your comment here" disabled={true} />
              <IconButton onClick={() => { }} classes={{ root: "profileActionsIcon" }}>
                <SendComment color="rgba(67, 66, 93, 0.3)" />
              </IconButton>
          </form>*/}
          </div>
        )
      })}
    </div>
  )
}

export default inject('postsModel', 'userModel')(observer(Posts));
