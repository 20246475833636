export const wdays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const dats3Letters = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const durationsData = [
  { title: '1 hour', value: 60, },
  // { title: '1h 15min', value: 75, },
  // { title: '1h 30min', value: 90, },
  // { title: '1h 45min', value: 105, },
  { title: '2 hours', value: 120, },
  { title: '3 hours', value: 180, },
  { title: '4 hours', value: 240, },
  { title: '5 hours', value: 300, },
  { title: '6 hours', value: 360, },
  { title: '7 hours', value: 420, },
  { title: '8 hours', value: 480, },
];

export const durationsCoachingData = [
  { title: '1 hour', value: 60, },
  // { title: '1h 15min', value: 75, },
  // { title: '1h 30min', value: 90, },
  // { title: '1h 45min', value: 105, },
  { title: '2 hours', value: 120, },
  { title: '3 hours', value: 180, },
  { title: '4 hours', value: 240, },
  { title: '5 hours', value: 300, },
  { title: '6 hours', value: 360, },
  { title: '7 hours', value: 420, },
  { title: '8 hours', value: 480, },
];
