import React, { useEffect, useMemo, useState } from 'react';
import { useRef } from 'react';
import ContentVideoPlayerHeader from './Header';
import Player from './Player';
import ContentVideoPlayerControls from './Controls';
import { neatTimeFull } from '../../units/func';

import './style.scss';

const ContentVideoPlayer = ({
  record_info,
  handlePlayNext,
  handlePlayPrevious,
  isPlaylist,
  showPlaylist = false,
  updateShowPlaylist = () => {},
}) => {
  const owner = record_info.owner;
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [time, setTime] = useState('00:00');
  const [isFullScreen, setFullScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volumeHovered, setVolumeHovered] = useState(false);
  const [disabledOnMouseLeaveEvent, toggleDisabledMouseLeaveEvent] =
    useState(false);
  const [lastVolume, setLastVolume] = useState(1);

  const [singleClickTimer, updateSingleClickTimer] = useState(null);
  const [showOverlay, tooggleShowOverlay] = useState(true);
  const isAudio = record_info?.file_type === 'audio';
  let mouseMoveTimer;
  const videoRef = useRef();
  const playerRef = useRef();

  useEffect(() => {
    toogleInteractionTimer(true);
    return () => {
      toogleInteractionTimer(false);
    };
  }, [isFullScreen]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
      videoRef.current.play();
      setIsPlaying(true);
    }
  }, [record_info.url]);

  const tooglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleReplay = () => {
    if (isPlaylist) {
      handlePlayNext();
    } else {
      videoRef.current.play();
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const updateProgress = () => {
    const video = videoRef.current;
    setProgress((video.currentTime / video.duration) * 100);

    if (video.currentTime) {
      setTime(
        `${neatTimeFull(video.currentTime)} / ${neatTimeFull(video.duration)}`,
      );
    }
  };

  const rewindProgress = (progress) => {
    const video = videoRef.current;
    setProgress(progress);
    video.currentTime = (progress / 100) * video.duration;
  };

  function launchIntoFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }
  function exitFullscreen() {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      document.exitFullscreen();
    }
  }

  function toggleFullscreen() {
    isFullScreen ? exitFullscreen() : launchIntoFullscreen(playerRef.current);
    setFullScreen((p) => !p);
  }

  function toggleMute() {
    const video = videoRef.current;
    if (!isMuted) {
      setLastVolume(video.volume);
      video.volume = 0;
    } else {
      video.volume = lastVolume;
    }
    setIsMuted((p) => !p);
  }

  function changeVolume(volume) {
    const video = videoRef.current;
    video.volume = volume / 100;
    setLastVolume(volume / 100);
    setIsMuted(false);
  }

  function handlePlayerClick(e) {
    const clickCount = e.detail;
    startInteractionTimer();
    if (clickCount === 1) {
      updateSingleClickTimer(
        setTimeout(function () {
          tooglePlay();
          clearTimeout(singleClickTimer);
        }, 400),
      );
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      toggleFullscreen();
    }
  }

  function startInteractionTimer() {
    stopInteractionTimer();
    mouseMoveTimer = setTimeout(function () {
      tooggleShowOverlay(false);
    }, 3000);
  }

  function stopInteractionTimer() {
    clearTimeout(mouseMoveTimer);
    tooggleShowOverlay(true);
  }

  function toogleInteractionTimer(isEnable) {
    if (isEnable) {
      document.addEventListener('mousemove', startInteractionTimer);
    } else {
      document.removeEventListener('mousemove', startInteractionTimer);
    }
  }

  const controlProps = {
    videoRef: videoRef,
    tooglePlay: tooglePlay,
    isPlaying: isPlaying,
    rewindProgress: rewindProgress,
    progress: progress,
    volumeHovered: volumeHovered,
    setVolumeHovered: setVolumeHovered,
    disabledOnMouseLeaveEvent: disabledOnMouseLeaveEvent,
    toggleDisabledMouseLeaveEvent: toggleDisabledMouseLeaveEvent,
    time: time,
    toggleMute: toggleMute,
    isMuted: isMuted,
    changeVolume: changeVolume,
    toggleFullscreen: toggleFullscreen,
    isFullScreen: isFullScreen,
    toogleShowPlaylist: updateShowPlaylist,
    showPlaylist: showPlaylist,
    playNext: handlePlayNext,
    playPrevious: handlePlayPrevious,
    isPlaylist: isPlaylist,
  };

  const playerProps = {
    videoRef: videoRef,
    videoUrl: record_info.url,
    updateProgress: updateProgress,
    handlePlayerClick: handlePlayerClick,
    handleMediaEnded: handleReplay,
    audioPreview: isAudio ? record_info?.image?.url : null,
    isPlaying: isPlaying,
    isFullScreen: isFullScreen,
    record_info: record_info
  };

  const headerProps = {
    isFullScreen: isFullScreen,
    theme: record_info.theme,
    full_name: owner.full_name,
    image: owner.image,
    ownerLocation: owner?.location,
  };

  return (
    <div
      className={`player-container ${
        showOverlay || showPlaylist ? '' : 'hide_mouse'
      }`}
      ref={playerRef}
    >
      {(showOverlay || showPlaylist) && (
        <ContentVideoPlayerHeader {...headerProps} />
      )}
      <Player {...playerProps} />
      {(showOverlay || showPlaylist) && (
        <ContentVideoPlayerControls {...controlProps} />
      )}
    </div>
  );
};

export default ContentVideoPlayer;
