import { observable, decorate } from 'mobx';

export class MessagesModel {
  constructor() {
    this.showMessages = false;
    this.messages = '';
    this.status = true;
  }

  setShowMessages = value => {
    this.showMessages = value
  };
  setMessages = messages => this.messages = messages;
  setStatus = status => this.status = status;
}

decorate(MessagesModel, {
  showMessages: observable,
  messages: observable,
  status: observable,
})

export const messagesModel = new MessagesModel();
