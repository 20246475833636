import React, { memo } from 'react';

const MasterCardIcon = props => {
//   const { color = '#43425D' } = props;

  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9941 7.99995C14.9941 12.1426 11.6382 15.4992 7.49707 15.4992C3.35591 15.4992 0 12.1418 0 7.99995C0 3.85806 3.35673 0.500671 7.49707 0.500671C11.6374 0.500671 14.9941 3.85806 14.9941 7.99995Z" fill="#E2574C" />
    <path d="M16.5008 0.500671C14.8195 0.500671 13.2716 1.06088 12.0207 1.99607L12.0267 1.99681C12.2727 2.23451 12.5442 2.40181 12.7534 2.67546L11.1935 2.70022C10.9491 2.94697 10.7264 3.21544 10.5164 3.49443H13.2671C13.4763 3.74562 13.6698 3.96392 13.8453 4.24135H10.0177C9.87744 4.48284 9.74773 4.73107 9.63447 4.98901H14.2811C14.4025 5.24621 14.5113 5.44048 14.6036 5.71273H9.35926C9.27603 5.97149 9.2048 6.23617 9.15076 6.50612H14.8233C14.8788 6.76562 14.9215 7.00555 14.9507 7.25008H9.03824C9.01348 7.49683 9.00073 7.74728 9.00073 8.00004H14.9934C14.9934 8.26554 14.9747 8.51147 14.9477 8.75H9.03824C9.06374 9.00424 9.10124 9.25395 9.15076 9.49996H14.8142C14.7557 9.74293 14.6882 9.98738 14.6035 10.2409H9.34199C9.42144 10.4973 9.51825 10.7463 9.62395 10.9893H14.2811C14.1521 11.2623 14.0058 11.4805 13.8446 11.7362H10.0043C10.1558 11.9987 10.323 12.2491 10.5044 12.4891L13.2672 12.5303C13.0317 12.813 12.7295 12.9833 12.4542 13.2308C12.4692 13.2428 12.014 13.2293 11.1178 13.2151C12.4812 14.622 14.3868 15.4993 16.5008 15.4993C20.6427 15.4993 24 12.1419 24 8.00004C24 3.85822 20.6434 0.500671 16.5008 0.500671Z" fill="#F4B459" />
    </svg>
  )
}

export default memo(MasterCardIcon);
