import React from "react";
import checked_icon from '../../../../res/icons/play_list_checked.svg'
import "./style.scss";

const PlaylistCheckbox= ({checked, handleClick}) => {
 

  return (
      <div className={`play_list-checkbox ${checked ? 'active' : null}`} onClick = {handleClick}>
          {checked && <img src={checked_icon} alt="play_list_checkbox"/> }
      </div>
  )
};

export default PlaylistCheckbox;
