import React, { useEffect } from 'react';
import classNames from 'classnames';
import indexOf from 'lodash/indexOf';
import Button from '../../Button';

import { wdays } from '../../../const/calendar';

const WeekDays = props => {
  const {
    className,
    onClick,
    activeDays,
    defaultDay,
    id,
    oneDayMode,
    disable
  } = props;

  const isActive = (index) => {
    return oneDayMode ? indexOf(activeDays, index) >= 0 && activeDays[id] === index : indexOf(activeDays, index) >= 0;
  }

  const isDisabled = (index) => {
    return oneDayMode ? (activeDays.includes(index) || disable) && index !== activeDays[id] : false;
  }
  return <>
    {wdays.map((label, index) => (
      <Button
        label={label}
        key={label}
        small
        className={classNames(className, { active: isActive(index)})}
        name="wdays"
        disabled = {isDisabled(index)}
        onClick={() => defaultDay !== index && onClick(index)}
      />
    ))}
  </>
}

export default WeekDays;
