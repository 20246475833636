import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";

import CustomSneckbar from "../../components/CustomSnackbar";
import CustomSelect from "../../components/Select/select";
import Button from "../../components/Button";
import CustomInput from "../../components/Input/customInput";
import CustomDatePicker from "../../components/DatePicker/customPicker";
import CustomImagePicker from "../../components/ImagePicker";
import NumberFormatCustom from "../../components/Input/numberFormat";
import PhoneFormatCustom from "../../components/Input/phoneFormat";

import AddPaymentMethod from "../../components/AddPaymentMethod";

import "./stripeVerification.scss";

import contries from "../../const/counties";

const currentContries = Object.keys(contries)
  .map((value) => ({ value, label: contries[value] }))
  .sort(function (a, b) {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  });

const defualtList = [
  // 'birth',
  // 'document', 'document_back', 'personal_id_number', 'ssn_last_4' 'country', 'address_line_2'
  "first_name",
  "last_name",
  "phone",
  "email",
  "birth",
  "personal_id_number",
  "city",
  "state",
  "address_line_1",
  "zip_code",
];

const fieldList = [
  {
    key: "document",
    type: "image",
    name: "ID Card Front",
    error: "ID Card Front",
    mask: "imageNotRequired",
  },
  {
    key: "document_back",
    type: "image",
    name: "ID Card Back",
    error: "ID Card Back",
  },
  {
    key: "first_name",
    type: "text",
    name: "First name",
    error: "First name",
    mask: "name",
  },
  {
    key: "last_name",
    type: "text",
    name: "Last name",
    error: "Last name",
    mask: "name",
  },
  {
    key: "phone",
    type: "text",
    name: "Phone Number",
    error: "Phone Number",
    mask: "phone",
    prefix: "+",
  },
  {
    key: "email",
    type: "email",
    name: "Email",
    error: "Email",
    mask: "email",
  },
  {
    key: "birth",
    type: "date",
    name: "Date of Birth",
    error: "Date of Birth",
  },
  {
    key: "personal_id_number",
    type: "text",
    name: "Full SSN",
    error: "ssn",
    mask: "ssn",
  },
  {
    key: "ssn_last_4",
    type: "text",
    name: "Last Four Digits of Your SSN",
    error: "Last Four Digits of Your SSN",
    mask: "ssn4",
  },
  {
    key: "country",
    type: "select",
    name: "Country",
    error: "Country",
  },
  {
    key: "city",
    type: "text",
    name: "City",
    error: "City",
    mask: "city",
  },
  {
    key: "state",
    type: "text",
    name: "State/Province",
    error: "State/Province",
    mask: "city",
  },
  {
    key: "address_line1",
    type: "text",
    name: "Address Line 1",
    error: "Address Line 1",
    mask: "name",
  },
  {
    key: "address_line2",
    type: "text",
    name: "Address Line 2",
    error: "Address Line 2",
    mask: "addressNotRequired",
  },
  {
    key: "address_line_1",
    type: "text",
    name: "Address Line 1",
    error: "Address Line 1",
    mask: "name",
  },
  {
    key: "address_line_2",
    type: "text",
    name: "Address Line 2",
    error: "Address Line 2",
    mask: "addressNotRequired",
  },
  {
    key: "zip_code",
    type: "number",
    name: "ZIP/postal Code",
    error: "ZIP/postal Code",
    mask: "zip",
  },
];

//eslint-disable-next-line
const regName = /^([a-zA-Z]+[\'\,\.\-]?[a-zA-Z ]*)+$/g;
//eslint-disable-next-line
const regPhone = /^[+]?\d{1,12}$/g;
const regSSN4 = /^[+]?\d{4}$/g;
const regNumber = /^[+]?\d*$/g;
const regCity = /^[a-zA-Z\- ]{1,48}$/g;
const regZIP = /^[A-Z0-9][A-Z0-9\- ]{1,10}[A-Z0-9]$/g;
const regAddress = /^[\w\W]{2,48}$/g;
const regSSN = /^[+]?\d{8,16}$/g;
//eslint-disable-next-line
const reqEmail = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/g;

const StripeVerification = (props) => {
  const {
    close,
    cardsModel: { stripeVerify, cards, resualtMessage, changeResultMessage },
    bankAccountsModel: { bankAccounts },
    userModel: { user, getCurentUser },
    authModel: { session_token },
  } = props;

  const validCard =
    cards?.count > 0 &&
    cards?.cards.filter((card) => card.variant === "payout").length > 0;
  const bankAccountAdded =bankAccounts?.length > 0;
  const [isValidCartOrBankAccount, setIsValidCartOrBankAccount] = useState(false);

  useEffect(() => {
    if(validCard) {
      setIsValidCartOrBankAccount(validCard);
    } else if (bankAccountAdded) {
      setIsValidCartOrBankAccount(bankAccountAdded);
    } else {
      setIsValidCartOrBankAccount(false);
    }
  }, [validCard, bankAccountAdded]);

  useEffect(() => {
    getCurentUser(session_token);
  }, []);

  const stripe_fields_needed = user?.stripe_required_fields_filled
    ? user.stripe_fields_needed
    : defualtList;
  // const stripe_fields_needed = defualtList;

  const currentFieldList = stripe_fields_needed.length
    ? fieldList.filter((field) => stripe_fields_needed.includes(field.key))
    : fieldList;

  let fieldYup = {};
  let initialValues = {};

  currentFieldList.forEach((field) => {
    let current = Yup.string().required(`${field.error} can't be blank`);
    switch (field.mask) {
      case "text":
        current = Yup.string()
          .required(`${field.error} can't be blank`)
          .matches(regName, `Please, enter correct data.`);
        break;
      case "number":
        current = Yup.string()
          .required(`${field.error} can't be blank`)
          .matches(regNumber, `${field.error} incorrect.`);
        break;

      case "phone":
        current = Yup.string()
          .required(`${field.error} can't be blank`)
          .matches(regPhone, `Please, enter correct data.`);
        break;

      case "city":
        current = Yup.string()
          .required(`${field.error} can't be blank`)
          .matches(regCity, `Please, enter correct data.`);
        break;

      case "name":
        current = Yup.string()
          .required(`${field.error} can't be blank`)
          .matches(regAddress, `Please, enter correct data.`);
        break;

      case "ssn4":
        current = Yup.string()
          .required(`${field.error} can't be blank`)
          .matches(regSSN4, `Please, enter correct data.`);
        break;

      case "ssn":
        current = Yup.string()
          .required(`${field.error} can't be blank`)
          .matches(regSSN, `Please, enter correct data.`);
        break;

      case "addressNotRequired":
        current = Yup.string().matches(
          regAddress,
          `Please, enter correct data.`
        );
        break;

      case "zip":
        current = Yup.string()
          .required(`${field.error} can't be blank`)
          .matches(regZIP, `Please, enter correct data.`);
        break;

      case "email":
        current = Yup.string()
          .required(`${field.error} can't be blank`)
          .matches(reqEmail, `Please, enter correct email.`);
        break;

      default:
        current = Yup.string().required(`${field.error} can't be blank`);
        break;
    }
    fieldYup = { ...fieldYup, [field.key]: current };
    if (field.mask === "email") {
      initialValues = { ...initialValues, [field.key]: user.email || "" };
    } else {
      initialValues = { ...initialValues, [field.key]: "" };
    }
  });

  const SignupSchema = Yup.object().shape(fieldYup);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          // if (validCard) {
          const { setErrors } = actions;
          let formData = new FormData();
          Object.keys(values).forEach((key) => {
            switch (key) {
              case "birth":
                formData.append(key, moment(values[key]).format("YYYY-MM-DD"));
                break;

              case "address_line1":
                formData.append("address_line_1", values[key]);
                break;

              case "address_line1":
                formData.append("address_line_1", values[key]);
                break;

              default:
                formData.append(key, values[key]);
                break;
            }
            // if (key === 'birth') {
            //   formData.append(key, moment(values[key]).format('YYYY-MM-DD'));
            // } else {
            //   formData.append(key, values[key]);
            // }
          });
          stripeVerify({ formData, setErrors });
          // }
        }}
        isInitialValid={false}
        className="SVBock"
      >
        {(formikProps) => {
          const { setFieldValue, isValid, values } = formikProps;

          let fields = [];
          currentFieldList.forEach((field) => {
            const onChange = (value) => {
              setFieldValue(field.key, value);
            };
            switch (true) {
              case field.type === "date":
                fields.push(
                  <div key={field.key} className="fieldBlock">
                    <div className="fieldLabel">{field.name}</div>
                    <CustomDatePicker
                      name={field.key}
                      format="DD.MM.YYYY"
                      showIcon={false}
                      onChange={(value) => onChange(value)}
                      className="simpleInput"
                      showDefault={false}
                      maxDate={moment().subtract(18, "years").toDate()}
                      defaultActiveStartDate={moment()
                        .subtract(18, "years")
                        .toDate()}
                      onInput={(value) => onChange(value)}
                    />
                  </div>
                );
                break;

              case field.type === "select":
                fields.push(
                  <div key={field.key} className="fieldBlock">
                    <div className="fieldLabel">{field.name}</div>
                    <CustomSelect
                      name={field.key}
                      data={currentContries}
                      className="simpleInput"
                      customChange={(value) => onChange(value)}
                    />
                  </div>
                );
                break;

              case field.type === "image":
                fields.push(
                  <div key={field.key} className="fieldImageBlock">
                    <CustomImagePicker
                      name={field.key}
                      customChange={(value) => onChange(value)}
                      text={field.name}
                      key={field.key}
                    />
                  </div>
                );
                break;

              case field.mask === "email":
                fields.push(
                  <div key={field.key} className="fieldBlock">
                    <div className="fieldLabel">{field.name}</div>
                    <CustomInput
                      name={field.key}
                      id={field.key}
                      className="simpleInput"
                    />
                  </div>
                );
                break;

              case field.prefix === "+":
                fields.push(
                  <div key={field.key} className="fieldBlock">
                    <div className="fieldLabel">{field.name}</div>
                    <CustomInput
                      name={field.key}
                      id={field.key}
                      className="simpleInput"
                      inputComponent={PhoneFormatCustom}
                    />
                  </div>
                );
                break;

              case field.mask === "number":
                fields.push(
                  <div key={field.key} className="fieldBlock">
                    <div className="fieldLabel">{field.name}</div>
                    <CustomInput
                      name={field.key}
                      id={field.key}
                      className="simpleInput"
                      inputComponent={NumberFormatCustom}
                    />
                  </div>
                );
                break;

              default:
                fields.push(
                  <div key={field.key} className="fieldBlock">
                    <div className="fieldLabel">{field.name}</div>
                    <CustomInput
                      name={field.key}
                      id={field.key}
                      className="simpleInput"
                    />
                  </div>
                );
                break;
            }
          });

          return (
            <Form className="SVBlock">
              <div className="SVTitle">Verify Account</div>
              <div className="SVsubText">
                Please verify your account in order to be able to withdraw
                funds.{" "}
                <span>The verification process can take several hours.</span>
              </div>
              <div className="SVsubText">
                By registering your account, you agree to our{" "}
                <a href="https://stripe.com/legal">Services Agreement</a> and
                the{" "}
                <a
                  target="_blank"
                  href="https://stripe.com/connect-account/legal"
                  rel="noopener noreferrer"
                >
                  Stripe Connected Account Agreement
                </a>
                .
              </div>
              {!(validCard || bankAccountAdded) && (
                <AddPaymentMethod variant="payout" title="Add Payout Method" />
              )}
              {fields}
              <Button
                type="submit"
                label="Save"
                disabled={!isValid || !isValidCartOrBankAccount}
                width100
                violetGradien
                big60
              />
            </Form>
          );
        }}
      </Formik>
      <CustomSneckbar
        message={resualtMessage}
        onClose={() => {
          changeResultMessage("");
          if (resualtMessage === "You Request send") {
            close();
          }
        }}
        severity={resualtMessage === "You Request send" ? "success" : "error"}
      />
    </>
  );
};

export default inject(
  "userModel",
  "cardsModel",
  "authModel",
  "bankAccountsModel"
)(observer(StripeVerification));
