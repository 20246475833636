import React, { useState } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
// import { isAndroid, isIOS } from 'react-device-detect';

import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '../../components/Button';
import MMCreateIcon from '../../res/iconComp/MMCreateIcon';
import LiveCreateIcon from '../../res/iconComp/LiveCreateIcon';
import SupportCreateIcon from '../../res/iconComp/SupportCreateIcon';
import Dialog from '../../components/Dialog';
import StripeVerification from '../StripeVerification';
import Message from '../StripeVerification/message';
import GroupForm from '../../components/CreateGroup';

import { mmRole } from '../../const/general';

import { gtSupport, gtMasterMind, gtWebinar } from '../../const/general';
import { stripeVerify } from '../../const/urlKeys';

// import { redirectToAPP } from '../../units/func'; // createAGroup, getLabel

import './createGroup.scss';

const data = [{
  type: gtWebinar,
  title: 'Livestream',
  text: 'This livestream groups is an online meeting or presentation held via the Internet in real time',
  buttonText: 'Create Livestream'
},/* {
  type: gtSupport,
  title: 'Groups',
  text: 'Provide an opportunity for people to share personal experiences, feelings and helps people who are going through or have gone through similar experiences',
  buttonText: 'Create Group'
}, */{
  type: gtMasterMind,
  title: 'Groups',
  text: 'Offer a combination of brainstorming, education, peer accountability and support in a group setting to sharpen your business and personal skills',
  buttonText: 'Create Group'
}];

const CreateGroup = props => {
  const {
    userModel: { user },
    location: { state },
    communityModel: { communityList },
    match
  } = props;
  const [groupType, setGroupType] = useState(null);

  if (!user?.role) return <CircularProgress className="loader" />
  
  if (user?.role !== mmRole || groupType || state?.action) {
    const type = user?.role === mmRole ? groupType : gtSupport;
    return <GroupForm groupType={type} action={state?.action} />
  }

  const canCreateGroup = user?.payouts_enabled && communityList.general?.some( c => c.moderator);
  const stripeVerificationPending = user?.stripe_required_fields_filled && !user?.payouts_enabled && user?.stripe_fields_needed.length === 0;

  const dialogContent = () => {
    switch(true){
      case stripeVerificationPending: return <Message />;
      default: return <StripeVerification />
    }   
  }

  return (
    <div className="createGroupPage">
      {data.map(group => {
        const renderButton = (key = true) => {
          return (
            <Button
              label={group.buttonText}
              className={classNames('creteGroupButton', group.type)}
              width100
              big60
              onClick={() => key && setGroupType(group.type)}
            />
          );
        }

        return(
          <div className="createGroupBlock" key={group.type}>
            {group.type === gtWebinar && <LiveCreateIcon />}
            {group.type === gtSupport && <SupportCreateIcon />}
            {group.type === gtMasterMind && <MMCreateIcon />}
            <div className={classNames('createGroupTitle', group.type)}>{group.title}</div>
            <div className="createGroupText">{group.text}</div>
            {/*group.type !== gtSupport && !(user.stripe_required_fields_filled && user.payouts_enabled) || !canCreateGroup ? <>
              <Divider />
              <div className="withdrawnBlock">
                <Dialog 
                  openButton={renderButton(false)}
                  className="withdrawDialog"
                >
                  {dialogContent()}
                </Dialog>
              </div>
            </> : renderButton(true)*/}
            {renderButton(true)}
          </div>
        )
      })}
      <div className="withdrawnBlock">
        <Dialog 
          className="withdrawDialog"
          defaultOpen={match.path === stripeVerify}
        >
          {dialogContent()}
        </Dialog>
      </div>
    </div>
  )
}

export default inject('userModel', 'communityModel')(observer(CreateGroup));
