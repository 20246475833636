import { observable, decorate } from 'mobx';

export class FileModel {
  constructor() {
	  this.codes = null;
  }

  getImage = (url) => {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
    .then(res => console.log('res', res))
    .catch(err => {
      console.log('Request failed', err);
    })
  };
}

decorate(FileModel, {
	codes: observable,
})

export const fileModel = new FileModel();
