import HomeIcon from '../res/icons/home.svg';
import VideoIcon from '../res/icons/video.svg';
import MasterIcon from '../res/icons/master.svg'; 
import GroupIcon from '../res/icons/group.svg';
import PlanIcon from '../res/icons/plan.svg';
import StepsIcon from '../res/icons/steps.svg';
import ListsIcon from '../res/icons/lists.svg';
import ContentIcon from '../res/icons/content.svg';
import BooksIcon from '../res/icons/book.svg';
import AudioBooksIcon from '../res/icons/audioBook.svg';
import VideoFolderIcon from '../res/icons/videoFolder.svg';
import FlowerIcon from '../res/icons/flower.svg';
import ImageFolderIcon from '../res/icons/imageFolder.svg';
import SearchMobileIcon from '../res/icons/search-header-mobile.svg';
// import PlaylistMobileIcon from '../res/icons/playlist-header-mobile.svg';
// import PlayMobileIcon from '../res/icons/play-header-mobile.svg';
// import NotificatonMobileIcon from '../res/icons/notification-header-mobile.svg';
// import ChatMobileIcon from '../res/icons/chat-header-mobile.svg';
import MeditationIcon from '../res/icons/meditation.svg';
import PodcastIcon from '../res/icons/podcast.svg';
import PlaylistIcon from '../res/icons/playlist_icon_nav.svg';

import { home, livestreams, collaborations, workshops, records, videos, meditations, podcasts, playlists } from './urlKeys'

export const menuList1 = [
  {
    label: 'Daily',
    link: home,
    icon: HomeIcon,
    key: 'daily',
  },
  {
    label: 'Live Streams',
    link: livestreams,
    icon: VideoIcon,
    key: 'webinars',
  },
  {
    label: 'Groups',
    link: workshops,
    icon: VideoIcon,
    key: 'masterMind',
  }
];

// export const menuList2 = [{
//   label: 'MasterMinds',
//   link: '/mm',
//   icon: MasterIcon,
//   key: 'MasterMind-one',
// },
// {
//   label: 'MasterMinds Following',
//   link: '/mmf',
//   icon: GroupIcon,
//   key: 'masterMindsFollowing',
// }];

export const menuList3 = [{
  label: 'Ascension Plan',
  link: home,
  icon: PlanIcon,
  key: 'ascensionPlan',
},
{
  label: 'Spiritual Action Steps',
  link: home,
  submenu: [{
    label: 'test'
  }],
  icon: StepsIcon,
  key: 'spiritualActionsSteps',
},
{
  label: 'Goals Lists',
  link: home,
  submenu: [{
    label: 'test'
  }],
  icon: ListsIcon,
  key: 'goalsLists',
}];

export const menuList4 = [{
  label: 'All Content',
  link: home,
  icon: ContentIcon,
  key: 'allContent',
},
{
  label: 'Books',
  link: home,
  icon: BooksIcon,
  key: 'books',
},
{
  label: 'Audio Books',
  link: home,
  icon: AudioBooksIcon,
  key: 'audioBooks'
},
{
  label: 'Video Courses',
  link: home,
  icon: VideoFolderIcon,
  key: "videoCourses"
},
{
  label: 'Meditations',
  link: home,
  icon: FlowerIcon,
  key: 'meditations'
},
{
  label: 'Images',
  link: home,
  icon: ImageFolderIcon,
  key: 'images'
}];

export const menuListMobile = [
  {
    label: 'Search',
    link: home,
    icon: SearchMobileIcon,
    key: 'search',
  },
  // {
  //   label: 'Playlist',
  //   link: home,
  //   icon: PlaylistMobileIcon,
  //   key: 'playlist',
  // },
  // {
  //   label: 'Pause',
  //   link: home,
  //   icon: PlayMobileIcon,
  //   key: 'pause',
  // },
  // {
  //   label: 'Notifications',
  //   link: home,
  //   icon: NotificatonMobileIcon,
  //   key: 'notifications',
  //   badge: true,
  // },
  // {
  //   label: 'Messages',
  //   link: home,
  //   icon: ChatMobileIcon,
  //   key: 'messages',
  //   badge: true,
  // }
];

export const recordsMenu = [
  {
    label: 'Livestreams',
    link: records,
    icon: VideoIcon,
    key: "livestreamRecords"
  },
  {
    label: 'Videos',
    link: videos,
    icon: VideoFolderIcon,
    key: "videos"
  },
  {
    label: 'Meditations',
    link: meditations,
    icon: FlowerIcon,
    key: "meditations"
  },
  {
    label: 'Podcasts',
    link: podcasts,
    icon: PodcastIcon,
    key: "podcasts"
  },
  {
    label: 'Playlists',
    link: playlists,
    icon: PlaylistIcon,
    key: "playlists"
  },
  // {
  //   label: 'Courses',
  //   link: 'https://course.ascend.video/',
  //   icon: ContentIcon,
  //   key: 'courses',
  //   linkType: 'outside',
  // }
];
