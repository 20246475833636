import React, {useState, useEffect, useReducer} from "react";
import Button from "../Button";
import { inject, observer } from "mobx-react";
import { authModel } from "../../models/auth";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';


import './style.scss';


const TagSelect = ({communityList, customChange, close, editGroup, setStartedEditing, action,
    selectedCommunities, setSelectedCommunities,
    selectedTags, setSelectedTags,
    selectedTagsCount, setSelectedTagsCount,
    groupsModel: {setEditGroup},
    tagsModel: { tags, getTags}, userModel : {community}})=>{
    const [communityData, setCommunityData] = useState({});
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [selectedCommunity, setSelectedCommunity] = useState("");
    var cSelectedTags = null;
    var cSelectedTagsCount = null;
    if(action === "edit" && editGroup?.tags){
        cSelectedTags = JSON.parse(JSON.stringify(editGroup.tags));
    }else{
        cSelectedTags = selectedTags;
        cSelectedTagsCount = selectedTagsCount;
    }

    const [selectedTagsTemp, setSelectedTagsTemp] = useState(cSelectedTags);
    const [selectedTagsCountTemp, setSelectedTagsCountTemp] = useState(cSelectedTagsCount)
    const [activeCommunity, setActiveCommunity] = useState(null)

    const checkIfInArrayById = (arr, id) => {
        let result = false;
        arr.map(val => {if(val.id === id){result = true}})
        return result
    }
    const handleChange = (value) => {
        if (checkIfInArrayById(selectedTagsTemp, value.id)) {
            const array = [...selectedTagsTemp];
            var index = -1;
            array.map((tag, ind) => {if(tag.id === value.id){index = ind}})
            if (index !== -1) {
                array.splice(index, 1);
                setSelectedTagsTemp(array);
            }
        } else {
            setSelectedTagsTemp([...selectedTagsTemp, JSON.parse(JSON.stringify(value))])
        }
    }

    useEffect(()=>{
        const selectedComminitiesName = {};
        let activeCommunityId = "";
        let copySelectedCommunities = selectedCommunities
        if(selectedCommunities){

            copySelectedCommunities = selectedCommunities;
        }else if(editGroup?.communities){
            copySelectedCommunities = editGroup.communities.map(comm => comm.id);
        }
        communityList.communities.map((communityMap, index) => {
            let copySelectedTagsCountTemp = {...selectedTagsCountTemp}     
            
            copySelectedTagsCountTemp[communityMap.title] = 0;
            selectedTagsTemp.map(tag => {
                if(tag.community_id === communityMap.id || (tag?.communities && tag.communities.includes(communityMap.id + ""))){
                    copySelectedTagsCountTemp[communityMap.title] +=1
                }
            })
            setSelectedTagsCountTemp(copySelectedTagsCountTemp);
            
            if (copySelectedCommunities.includes(communityMap.id)) {     
                if(index === 0){
                    selectedComminitiesName[communityMap.title] = [true, copySelectedTagsCountTemp[communityMap.title] ? 
                        copySelectedTagsCountTemp[communityMap.title] : 
                        0, 
                    communityMap.id, []];  
                    setActiveCommunity(communityMap.id);
                    setSelectedCommunity(communityMap.title)
                }
                else{
                    selectedComminitiesName[communityMap.title] = [false, copySelectedTagsCountTemp[communityMap.title] ? 
                        copySelectedTagsCountTemp[communityMap.title] : 
                        0, 
                    communityMap.id, []];

                }
            }
            
        })
        updateActiveStatus(selectedCommunity);
        setCommunityData(selectedComminitiesName);
    },[])
    
    useEffect(()=>{
        if(communityData[selectedCommunity]){
            let copyCommunityData = communityData;
            copyCommunityData[selectedCommunity][1] = 0;
            copyCommunityData[selectedCommunity][3] = [];
            tags.map(tag => {if(checkIfInArrayById(selectedTagsTemp,tag.id)){    
                if(parseInt(tag.communities[0]) === activeCommunity){
                    copyCommunityData[selectedCommunity][1] += 1;
                    copyCommunityData[selectedCommunity][3].push(tag.title);
                }
            }})
            setCommunityData(copyCommunityData);

            let copySelectedTagsCount = {...selectedTagsCountTemp};
            copySelectedTagsCount[selectedCommunity] = copyCommunityData[selectedCommunity][1];
            setSelectedTagsCountTemp(copySelectedTagsCount);
            forceUpdate();
        }
    },[selectedTagsTemp])

    const updateActiveStatus = (key) => {
        let copyCommunityData = communityData;
        let activeCommunityId = "";
        Object.keys(copyCommunityData).map(communityName =>{
            if(communityName === key){
                copyCommunityData[communityName][0] = true;
                setActiveCommunity(copyCommunityData[communityName][2]);
                setSelectedCommunity(communityName)
            }else{
                copyCommunityData[communityName][0] = false
            }
        })
        forceUpdate();
    }

    const handleTagTitles = () =>{
        customChange(selectedTagsTemp);
        setSelectedTags(selectedTagsTemp);
        setStartedEditing(true);
        setSelectedTagsCount(selectedTagsCountTemp);
        if(editGroup && !selectedCommunities){
            let strngCommunities = "";
            editGroup.communities.map((community, ind) => {
                strngCommunities += community.id; 
                ind != (editGroup.communities.length-1) && (strngCommunities += ",");
            })
            setSelectedCommunities(strngCommunities);
        }
        close();
    }
    const tagCount = (commId) =>{
        let result = 0;
        selectedTagsTemp.map(tg => tg.communities)
    }
    return <div className = "tagSelectContainer">
        <div className = "tagSelectContent">
            <div className = "title">Select Tags</div>
            {(Object.keys(communityData).length != 0 || editGroup?.communities) && ( <>
                <div className = "smaller_title">Communities</div>
                <div className = "selectedCommunities">
                    {Object.keys(communityData).map((communityName, index) => (
                        <div className = {`selectedCommunitiesItem ${communityData[communityName][0] === true && "active"}`} onClick = {()=>updateActiveStatus(communityName)}>
                            <div className = "selectedCommunitiesItemName">{communityName}</div>
                            <div className = {`selectedCommunitiesItemTags ${communityData[communityName][1] === 0 && "zeroTags"}`}>{communityData[communityName][1]}</div>
                        </div>
                    ))}
                </div>
                <div className = "smaller_title">Tags</div>
                <List>
                    {tags.map(item => (item.communities.includes(activeCommunity + "") && !item.distribution_group_id &&
                    <ListItem key={item.id}>
                        <ListItemIcon>
                        <Checkbox
                            size="medium"
                            color="#43425D"
                            checked={checkIfInArrayById(selectedTagsTemp,item.id)}
                            onChange={() => handleChange(item)}
                        />
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItem>
                    ))}
                </List>
                <Button violetGradien width100 label="OK" onClick={() => handleTagTitles()} />
            </>)}
            {Object.keys(communityData).length === 0 && !editGroup?.communities && <div className = "smaller_title" style = {{"textAlign":"center"}}>Please select communities</div>}
        </div>
    </div>
}

export default inject(
    "tagsModel",
    'userModel', 
    'groupsModel'
  )(observer(TagSelect));

