import {collaborations, livestreams, workshops} from "../../../const/urlKeys";
import MailBuilder from "../../../res/builders/MailBuilder";
import {getLabel} from "../../../units/func";
import {gtRecurringWebinar} from "../../../const/general";

export const contentMailToText = (mailData) => {
  let path = null;
  switch (mailData.type) {
    case 'webinar':
    case gtRecurringWebinar:
      path = livestreams;
      break;

    case 'support':
      path = collaborations;
      break;

    default:
      path = workshops;
      break;
  }

  return `mailto:?subject=${MailBuilder.inviteToGroup.getSubject(mailData.name, getLabel(path))}
  &body=${MailBuilder.inviteToGroup.getBody(getLabel(path), "" , mailData.link , mailData.owner)}`; // mailData.community,
}