import * as Yup from "yup";
import {scheduleStrToObj, daysCountInPeriod} from "../units/func";
import moment from "moment";

export const regPrice = /^((0(\.0+)?)|(([1-9]\d{0,4}?)([.,]\d{1,2})?))$/g;
export const getPriceRegExp = (minPrice = 0, isZeroAllow=true, maxBeforeDot = 4, ) => {
  return new RegExp(`^((${isZeroAllow? 0 : minPrice}(\\.0+)?)|((([${String(minPrice)[0]}-9]\\d{${String(minPrice).length > 1? 1 : 0},1})|([1-9]\\d{1,${maxBeforeDot-1}}?))([.,]\\d{1,2})?))$`,'g')
}
const timeFormat = 'DD-MM-YYYY hh:mm a'

const text_field_max = 64;
const text_field_min = 2;
const description_field_max = 500;
const description_field_min = 2;
const minMonthDuration = 6;
const maxMonthDuration = 24;

export const maxMeetingsCount = 365;
export const maxMeetingsInPackCount = 10;

export const groupCreateValidations = {
  name: Yup.string()
    .required("Please write group name")
    .min(
      text_field_min,
      `Please, enter the Group Name correctly. (minimum is ${text_field_min} characters)`
    )
    .max(
      text_field_max,
      `Please, enter the Group Name correctly. (maximum is ${text_field_max} characters)`
    ),
  months: Yup.number()
    .required("Please select duration in month count"),
    // .min(minMonthDuration, `Please, select minimum  ${minMonthDuration} month`)
    // .max(maxMonthDuration, `Please, select maximum  ${maxMonthDuration} month`),
  description: Yup.string()
    .min(
      description_field_min,
      `Description is too short (minimum is ${description_field_min} characters)`
    )
    .max(
      description_field_max,
      `Please, enter the Group Description correctly (maximum is ${description_field_max} characters)`
    )
    .required("Please write group desciption"),
  price: Yup.string()
    .when(['recurring', 'meetings_in_pack', 'meetings_count', 'available_for_purchases'], (recurring = false, meetings_in_pack=1, meetings_count=1, available_for_purchases = false, schema)=>{
      let minPrice = parseInt(meetings_count)
      const isZeroAllow = !available_for_purchases
      if (recurring && available_for_purchases){
        minPrice = Math.max(meetings_in_pack, 1) || 0
      }
      const errorText  = isZeroAllow? `$ 0 or more than $ ${parseInt(meetings_count).toFixed(2)}` : ` $ ${recurring? minPrice.toFixed(2): parseInt(meetings_count).toFixed(2)} or more `;
      return  Yup.string().matches(getPriceRegExp(minPrice, isZeroAllow), `Set price ${errorText}, and less X XXX.XX ` )
    })
    .when('available_for_purchases', {
      is: true,
      then: Yup.string()
        .required('Please write pack price')
    })
  ,
  pack_price: Yup.string()
      .when(['recurring', 'meetings_in_pack', 'meetings_count', 'available_for_purchases'], (recurring = false, meetings_in_pack=1, meetings_count=1, available_for_purchases = false, schema)=>{
        let minPrice = parseInt(meetings_in_pack)
        const isZeroAllow = !available_for_purchases
        if (recurring && available_for_purchases){
          minPrice = Math.max(meetings_in_pack, 1) || 0
        }
        const errorText  = isZeroAllow? `$${parseInt(meetings_count).toFixed(2)}` : ` $${recurring? minPrice: parseInt(meetings_in_pack)}`;
        return  Yup.string().matches(getPriceRegExp(minPrice, isZeroAllow),
            `The price of the meeting records can’t be less than ${errorText}. Please change the Livestream 
            price or the number of meetings so that when you divide them, the total of one entry is more than $1.` )
      })
      .when('available_for_purchases', {
        is: true,
        then: Yup.string()
            .required('Please write group price')
      })
  ,
  subscription_packages_ids: Yup.array()
    .min(1, "The error message if length === 0 | 1")
  ,
  remote_image_url: Yup.string(),
  image: Yup.string()
    .when("remote_image_url", {
      is: (remote_image_url) => remote_image_url?.length == 0,
      then: Yup.object()
        .required(
          "Something went wrong. The image size can’t be more than 30 Mb in a format .png, .jpg"
        )
        .nullable(),
    })
    .required("required")
    .nullable(),
  meetings_count: Yup.number()
    .required("Please select meetings count")
    .integer("Please select correct meetings count")
    .min(
      0,
      `Please select correct meetings count. Set from 1 to ${maxMeetingsCount}`
    )
    .max(
      maxMeetingsCount,
      `Please select correct meetings count. Set from 1 to ${maxMeetingsCount}`
    ),
  meetings_in_pack: Yup.number()
    .when(["available_for_purchases"], (available_for_purchases, schema) => {
      return available_for_purchases? schema.required("Please select meetings in pack count") : schema
    })
    .when(['months', 'schedule', 'start_date', 'available_for_purchases', 'meetings_count'], (months=1, schedule, start_date, available_for_purchases, meetings_count, schema) => {
      if (!available_for_purchases){
        return schema
      }

      const scheduleObj = scheduleStrToObj((schedule || ""));
      const start = `${start_date} ${scheduleObj.time}`
      const totalDays = daysCountInPeriod(moment(start, timeFormat), moment(start, timeFormat).add(months, 'months'), scheduleObj.days)
      const maxCount = Math.min(31, totalDays)
      return schema.min(1, `Please select correct meetings in pack count. Set from 1 to ${meetings_count}`);
    })
    .when(['months', 'schedule', 'start_date', 'available_for_purchases', 'meetings_count'], (months=1, schedule, start_date, available_for_purchases, meetings_count, schema) => {
      if (!available_for_purchases){
        return schema
      }

      const scheduleObj = scheduleStrToObj((schedule || ""));
      const start = `${start_date} ${scheduleObj.time}`
      const totalDays = daysCountInPeriod(moment(start, timeFormat), moment(start, timeFormat).add(months, 'months'), scheduleObj.days)
      const maxCount = Math.min(31, totalDays)
      return schema.max(meetings_count, `Please select correct meetings in pack count. Set from 1 to ${meetings_count}`);
    })
    ,
  //tags: Yup.number().required("Please chose group tag"),
  meeting_format: Yup.string()
    .required("Please write your meetting format")
    .min(
      2,
      "Please, enter the meetting format correctly. (minimum is 2 characters)"
    )
    .max(
      64,
      "Please, enter the meetting format correctly. (maximum is 64 characters)"
    ),
  schedule: Yup.string().required("Please select days and time"),
  start_date: Yup.string().required("Please select start day"),
  location: Yup.string()
      .when('in_person', {
        is: true,
        then: Yup.string()
            .required('This field is required')
      })
      .min(
          2,
          "Please, enter the Location correctly. (minimum is 2 characters)"
      )
      .max(
          64,
          "Please, enter the Location correctly. (maximum is 64 characters)"
      ),
    member_limit: Yup.string()
        .when('in_person', {
            is: true,
            then: Yup.string()
                .required('This field is required')
                .test('member_limit', 'Member limit can’t be 0', (data, parent) => {
                    const val = parseInt(data);
                    if(!isNaN(val)){
                        return val > 0
                    }

                    return true;
                })
                .test('member_limit', 'Please, enter the Members limit correctly. (min - 1)', (data) => {
                    const val = parseInt(data);
                    if(!isNaN(val)){
                        return val >= 1
                    }

                    return false;
                })
        })
};

export const addBankAccountValidations = (requireRoutingNumber) => {
  return {
    country: Yup.string().required("This field is required"),
    currency: Yup.string().required("This field is required"),
    account_holder_name: Yup.string().required("This field is required"),
    account_holder_type: Yup.string().required("This field is required"),
    routing_number: requireRoutingNumber && Yup.string().required("This field is required"),
    account_number: Yup.string().required("This field is required"),
  };
};
