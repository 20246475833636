import { observable, decorate, action, toJS } from 'mobx';
import axios from 'axios';
import { baseAPI } from '../const/settings';
import { authModel } from './auth';
import { userModel } from './user';
import {mediaModel} from "./media";
import {groupsModel} from "./groups";

export class MasterMindsModel {
  constructor() {
    this.masterMinds = null;
    this.randomMinds = null;
    this.sortedMasterMinds = null;
    this.masterMindProfile = null;
    this.masterMindProfileFullData= null;
    this.currentMMID = null;
    this.masterMindsProfileFollowers = null;
  }

  setMMID = id => this.currentMMID = id;

  setMasterMinds = data => this.masterMinds = data;
  
  getRandomIndex = length => Math.floor(Math.random() * length);

  setMasterMindProfile = (profile_id) => {
    const array = toJS(this.masterMinds) ? toJS(this.masterMinds).users : [];
    const newMM = array.find(masterMind => masterMind.id === profile_id);
    if (newMM) {
      this.masterMindProfile = newMM;
    }
  }

  randomItems = (count) => {
    const array = this.masterMinds ? 
      this.masterMinds.users
        .filter(mmuser => mmuser?.id !== userModel.user?.id && mmuser?.id !== this.currentMMID) 
      : [];
    let randomArrayIndex = [];
    let max = count;
    if (max > array.length) {
      max = array.length;
    }
    while (randomArrayIndex.length < max) {
      const currentIndex = this.getRandomIndex(array.length);
      if (!randomArrayIndex.includes(currentIndex)) {
        randomArrayIndex.push(currentIndex);
      }
    }
    this.randomMinds = randomArrayIndex.map(i => array[i]);
  }

  getMasterMindsData = session_token => {
    // try {
      const headers = {
        "headers": {
          "accept": "application/json, text/plain, */*",
          "session-token": session_token
        }};
      axios.get(`${baseAPI}users?[per_page]=1000`, headers)
        .then(response => {
          this.masterMinds = response.data;
          this.sortedMasterMinds = this.masterMinds && this.masterMinds.count && 
            this.masterMinds.users.slice().sort(function (a, b) {
              return b.rating - a.rating;
            });
        })
        .catch(function (error) {
          console.log('error', error)
        })
        .finally()
  }

  getMasterMindFullProfile = (id) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.get(`${baseAPI}users/${id}`, headers)
      .then(response => {
        this.masterMindProfileFullData = response.data;
        this.masterMindProfile = response.data;
        this.masterMindsProfileFollowers = response.data.followers_count;
      }).catch(function (error) {
        // handle error
        console.log('error', error)
      })
  }

  followMasterMind = (id, onFollow = () => {}) => {
    const getMasterMindsData = this.getMasterMindsData;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.post(`${baseAPI}users/${id}/follow`, {}, headers)
      .then(response => {
        getMasterMindsData(authModel.session_token);
        mediaModel.getMediaGroups()
        groupsModel.getGroupsData()
        onFollow();
      })
      .catch(function (error) {
        // handle error
        console.log('error', error)
      })
      .finally()
  }
  

  setMasterMindRating = (id , value) => {
    const getMasterMindsData = this.getMasterMindsData;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.post(`${baseAPI}users/${id}/rating`, {value : value}, headers)
      .then(response => {
        getMasterMindsData(authModel.session_token);
      })
      .catch(function (error) {
        // handle error
        console.log('error', error)
      })
      .finally()
  }

  unfollowMasterMind = id => {
    const getMasterMindsData = this.getMasterMindsData;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.delete(`${baseAPI}users/${id}/unfollow`, headers)
      .then(response => {
        getMasterMindsData(authModel.session_token);
        mediaModel.getMediaGroups()
        groupsModel.getGroupsData()
      })
      .catch(function (error) {
        // handle error
        console.log('error', error)
      })
      .finally()
  }
}

decorate(MasterMindsModel, {
  masterMinds: observable,
  randomMinds: observable,
  sortedMasterMinds: observable,
  getMasterMindsData: action,
  randomItems: action,
  setMasterMindProfile: action,
  masterMindProfile: observable,
  setMasterMindRating: action,
  getMasterMindFullProfile: action ,
  masterMindProfileFullData: observable
  
})

export const masterMindsModel = new MasterMindsModel();
