import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ImgBuilder from '../../res/builders/ImgBuilder';
import categoryNameIcon from './../../res/icons/categoryName.svg';

const DropDown = (props) => {
  const {
    tabs,
    active,
    setActive,
    categoryFilter,
    tabInfo,
    tabLabel = (label) => label,
    dropDownToogleSize = 1921,
  } = props;
  const [showDropDown, setShowDropDonw] = useState(
    window.innerWidth < dropDownToogleSize,
  );
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    function handleResize() {
      setShowDropDonw(window.innerWidth < dropDownToogleSize);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [dropDownToogleSize]);

  const dropDownModeAndHideList = () => {
    return showDropDown && !showList;
  };

  const isFirstOption = (item) => {
    return tabs.indexOf(item) === 0;
  };

  const showThisItem = (item) => {
    if (!isFirstOption(item) && dropDownModeAndHideList()) {
      return false;
    }
    return true;
  };

  const getTabLabel = (tab) => {
    if (dropDownModeAndHideList()) {
      return tabLabel(active);
    }
    return tabLabel(tab);
  };

  const getTabCount = (tab) => {
    if (tab === active || dropDownModeAndHideList()) {
      return tabInfo.find(
        (item) => item.tab.toLowerCase() === active.toLowerCase(),
      ).count;
    } else {
      return tab.count;
    }
  };

  const handleSelect = (tab) => {
    setActive(tab);
    setShowList((prev) => !prev);
  };

  const handleClick = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
    setShowList((prevState) => !prevState);
  };

  return (
    <>
      {!categoryFilter
        ? tabs.map((tab) => (
            <div
              key={tab}
              style={showThisItem(tab) ? null : { display: 'none' }}
              className={classNames('tabButton', {
                active: tab === active || dropDownModeAndHideList(),
              })}
              onClick={
                dropDownModeAndHideList() ? null : () => handleSelect(tab)
              }
            >
              <span>{getTabLabel(tab)}</span>

              {isFirstOption(tab) && showDropDown && (
                <img
                  src={showList ? ImgBuilder.arrowUp : ImgBuilder.arrowDown}
                  onClick={() => setShowList((prev) => !prev)}
                  alt="arrow"
                />
              )}
            </div>
          ))
        : tabInfo.map((tab) => (
            <div
              key={tab.tab}
              style={showThisItem(tab.tab) ? null : { display: 'none' }}
              className={classNames('tabButton', {
                active: tab.tab === active || dropDownModeAndHideList(),
              })}
              onClick={() => handleClick(tab.tab)}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginRight: '10px',
                }}
              >
                <div>
                  <img src={categoryNameIcon} style={{ marginRight: '10px' }} />
                  <span>{getTabLabel(tab.tab)}</span>
                </div>
                <div>{getTabCount(tab)}</div>
              </div>
              {isFirstOption(tab.tab) && showDropDown ? (
                <img
                  src={showList ? ImgBuilder.arrowUp : ImgBuilder.arrowDown}
                  alt="arrow"
                />
              ) : (
                <div style={{ width: '20px', height: '2px' }}></div>
              )}
            </div>
          ))}
    </>
  );
};

export default DropDown;
