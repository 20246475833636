import React, {memo, useEffect, useState} from 'react';
import classNames from 'classnames';
import savedIcon from  '../../res/icons/saveBtn.svg'
import unsavedIcon from  '../../res/icons/ubsaveBtn.svg'
import IconButton from '@material-ui/core/IconButton';

import "./button.scss"


const ToggleSaveBtn = props => {
  const {
    item,
    buy = _ =>{},
    restore = (id, type) => {},
    remove = () => {}
  } = props;
  const getSaveState = _ => item.hasOwnProperty('purchase')? item.purchase && !item.removed : item.subscribed && !item.removed
  const [saved, toggleSaved] =  useState(getSaveState());

  useEffect(_=>{
    toggleSaved(getSaveState())
  },[item])

  const handleToggleSave = _ => {
    toggleSaved( prev => !prev);
    if (saved) {
      remove(item.id);
    } else if (item.removed){
      const type = item.record_type ? 'record' : null;
      restore(item.id, type)
    } else {
      buy(item.id, null, null);
    }
  }

  const title = saved ? "Unsave": 'Save'
  return(
    <div
      className={classNames(
        "toggleSaveBtn",
        {saved: saved}
      )}
      onClick={handleToggleSave}
    >
      <span className={'toggleSaveBtn_title'}>{title}</span>
      <IconButton>
        <img className={'toggleSaveBtn_img'} src={saved? unsavedIcon: savedIcon} alt={`${title} icon`}/>
      </IconButton>

    </div>
  )
}

export default memo(ToggleSaveBtn)
