import React, { useState } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';

import GroupHeader from '../AttendGroup/GroupHeader';
import BannedMessage from '../../components/BannedMessage';
import CloseGroupForm from '../../components/CloseGroupForm';
import ReportGroupForm from '../../components/ReportGroupForm';
import DeleteGroupForm from '../../components/DeleteGroupForm';
import CancelGroupForm from '../../components/CancelGroupForm';
import GroupActions from '../../components/GroupActions';
import GroupTypeLabel from '../../components/EventItem/groupType';
import Button from '../../components/Button';
import GroupRefund from './groupRefund';
import CalendarTimeIcon from '../../res/iconComp/CalendarTime';
import AtendeesIcon from '../../res/iconComp/Atendees';
import EventButton from '../../components/EventItem/eventButton';
import AttendeesList from '../../components/AttendeesList';
import {timeToStartMetting} from '../../units/func';
import {gtRecurring, gtRecurringWebinar, recurringGroupTitleTag, recurringWebinarTitleTag} from '../../const/general';

import './details.scss';
import ViewSubscriptionBtn from "../../components/ViewSubscriptionsBtn";
import GroupOwner from "../../components/EventItem/eventOwner";
import classNames from "classnames";
import inPersonIcon from '../../res/icons/inPerson.svg';
import privateLivestreamIcon from  '../../res/icons/privet_livestream.svg';
import calendarDate from  '../../res/icons/calendarDate.svg';

const ATTENDEES = 'Attendees',
    NO_ATTENDEES = 'No attendees yet';

const GroupDetails = props => {
  const {
    openSubscriptions = () => {},
    needSubscriptionsBtn=false,
    availableBySubscription = false,
    children,
    itemPath,
    canAskRefund = true,
    item, close, handleCloseDetails = () => {}, report = false,
    refresh = () => {},
    groupsModel: { setEditGroup, reportGroup, deleteGroup, removeGroup, closeGroup, openGroup, changeResultMessage, groupParticipants },
    userModel: { isOwner, user },
    showTextUnderAttend = false,
  } = props;
  const [openClose, setOpenClosed] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openAttendees, setOpenAttendees] = useState(false);
  const  isShopping = true;
  const groupClosed = item.private;
  const timeToEvent = timeToStartMetting(item?.start_time);
  const isRecurringNotStartUse = (item.group_type !== gtRecurring || item.group_type !== gtRecurringWebinar) && !item.past_meetings_count || (item.group_type !== gtRecurring || item.group_type !== gtRecurringWebinar) && item.recurring_info?.available_meetings_count === item.recurring_info?.meetings_count
  const showReport = () => setOpenReport(true);
  const hideReport = () => {
    if (report) {
      close();
    } else {
      setOpenReport(false);
    }
  }

  const showDelete = () => setOpenDelete(true);
  const hideDelete = () => setOpenDelete(false);

  const showClosed = () => setOpenClosed(true);
  const hideClosed = () => setOpenClosed(false);

  const showCancel = () => setOpenCancel(true);
  const hideCancel = () => setOpenCancel(false);

  const showAttendees = () => setOpenAttendees(true);
  const hideAttendees = () => setOpenAttendees(false);

  const isFullUpcomingEvent = item?.meetings_records?.every(meeting => !meeting.ended) || moment(item.start_time) > moment()

  const saveReport = async (content) => {
    setOpenReport(false);
    reportGroup(item.id, content);
  }

  const handleDeleteGroup = () => {
    setOpenDelete(false);
    if (isOwner(item.owner)) {
      deleteGroup(item.id);
    } else {
      removeGroup(item.id);
    }
    handleCloseDetails()
    if (close !== undefined) {
      close();
    }
  }

  const handleCloseGroup = () => {
    setOpenClosed(false);
    closeGroup(item.id);
    if (close !== undefined) {
      close();
    }
  }

  const handleOpenGroup = () => {
    setOpenClosed(false);
    openGroup(item.id);
    if (close !== undefined) {
      close();
    }
  }

  const toggleGroup = () => groupClosed ? handleOpenGroup() : handleCloseGroup();

  const successCancelClose = (needCloseModal) => {
    setOpenClosed(false);
    if (!needCloseModal) return hideCancel();
    handleCloseDetails()
    if (close !== undefined) {
      close();
    }
  }

  const eventButtons = () => {
    if(!isOwner(item.owner) && needSubscriptionsBtn && !item.subscribed && item.invited !== 'speaker' &&
        (item.available_for_subscribers || item.subscription_packages_ids.length > 0)) {
      return <ViewSubscriptionBtn group={item} openSubscriptions={openSubscriptions}/>;
    } else if(item.blocked) {
      return <BannedMessage item={item} />
    } else if ((!needSubscriptionsBtn || (item.invited !== 'none' && item.group_type === "recurring_webinar" && item.price))
        && (!item.available_for_purchases || (!item.price && item.available_for_purchases) ||
            (!item.pack_price && !item.meetings_in_pack && item.create_package)) && (!isOwner(item.owner)) ) {
      return <EventButton  availableBySubscription={availableBySubscription}
                           item={item} place="dialog"
                           refresh = {refresh}
                           onClick = {(needSubscriptionsBtn && !item.leaved && !(item.invited && item.group_type === "recurring_webinar" && item.price))? openSubscriptions: close}
                           itemPath={itemPath}
                           needSubscriptionsBtn={needSubscriptionsBtn}
                           showTextUnderAttend = {showTextUnderAttend}
      />
    } else if ((!needSubscriptionsBtn && item.available_for_purchases && item.available_for_subscribers && item.create_package)
        || ((!needSubscriptionsBtn && !item.subscribed && item.leaved)
            && (item.available_for_subscribers || item.available_for_purchases || item.create_package))) {
      return <EventButton  availableBySubscription={availableBySubscription}
                    item={item} place="dialog"
                    refresh = {refresh}
                    onClick = {(needSubscriptionsBtn && !item.leaved && !(item.invited !== 'none' && item.group_type === "recurring_webinar" && item.price))? openSubscriptions: close}
                    itemPath={itemPath}
                    needSubscriptionsBtn={needSubscriptionsBtn}
                    showTextUnderAttend = {showTextUnderAttend}
      />
    } else if (!needSubscriptionsBtn && (item.subscribed || isOwner(item.owner)) && (item.available_for_subscribers || item.available_for_purchases || item.create_package)) {
      return <EventButton  availableBySubscription={availableBySubscription}
                           item={item} place="dialog"
                           refresh = {refresh}
                           onClick = {(needSubscriptionsBtn && !item.leaved && !(item.invited !== 'none' && item.group_type === "recurring_webinar" && item.price))? openSubscriptions: close}
                           itemPath={itemPath}
                           needSubscriptionsBtn={needSubscriptionsBtn}
                           showTextUnderAttend = {showTextUnderAttend}
      />
    } else if (needSubscriptionsBtn && item.available_for_subscribers && item.invited === 'speaker') {
      return <EventButton  availableBySubscription={availableBySubscription}
                           item={item} place="dialog"
                           refresh = {refresh}
                           onClick = {close}
                           itemPath={itemPath}
                           needSubscriptionsBtn={needSubscriptionsBtn}
                           showTextUnderAttend = {showTextUnderAttend}
      />
    }else if (needSubscriptionsBtn && item.available_for_subscribers) {
      return <EventButton  availableBySubscription={availableBySubscription}
                           item={item} place="dialog"
                           refresh = {refresh}
                           onClick = {(needSubscriptionsBtn && !item.leaved && !(item.invited !== 'none' && item.group_type === "recurring_webinar" && item.price))? openSubscriptions: close}
                           itemPath={itemPath}
                           needSubscriptionsBtn={needSubscriptionsBtn}
                           showTextUnderAttend = {showTextUnderAttend}
      />
    } else if ((!needSubscriptionsBtn || (item.invited !== 'none' && item.group_type === "recurring_webinar")) &&
        item.available_for_purchases && item.create_package &&
        user.id !== item.owner.id && !item.subscribed && !item.leaved) {
      return <Button label="Attend" violetGradien onClick={() => openSubscriptions()}/>
    } else if (!needSubscriptionsBtn && isOwner(item.owner)) {
      return <EventButton  availableBySubscription={availableBySubscription}
                           item={item} place="dialog"
                           refresh = {refresh}
                           onClick = {(needSubscriptionsBtn && !item.leaved && !(item.invited && item.group_type === "recurring_webinar" && item.price))? openSubscriptions: close}
                           itemPath={itemPath}
                           needSubscriptionsBtn={needSubscriptionsBtn}
                           showTextUnderAttend = {showTextUnderAttend}
      />
    } else if (item.available_for_purchases && item.price) {
      return <EventButton  availableBySubscription={availableBySubscription}
                           item={item} place="dialog"
                           refresh = {refresh}
                           onClick = {(needSubscriptionsBtn && !item.leaved && !(item.invited && item.group_type === "recurring_webinar" && item.price))? openSubscriptions: close}
                           itemPath={itemPath}
                           needSubscriptionsBtn={needSubscriptionsBtn}
                           showTextUnderAttend = {showTextUnderAttend}
      />
    }
  }

  const displayedTags = item?.tags ? item.tags.map(tag => tag.title) : []
  item?.distribution_group && displayedTags.push(item.distribution_group.title)
  const meetingsLabel = (count) => count > 1 ? 'Meetings' : 'Meeting',
      attendeesLabes = item.participants_count? <><span>{item.participants_count}</span>&nbsp;&nbsp;{ATTENDEES}</>: NO_ATTENDEES

  const meetingsPriceWithDetails = () => {
        if (item.group_type !== gtRecurring && item.group_type !== gtRecurringWebinar) return <><span>$ {(item.price / 100).toFixed(2)}</span></>;
        const left = item.recurring_info?.available_meetings_count;
    return item.subscribed? ` ${left}/${item.recurring_info?.meetings_count || item.meetings_count} ${meetingsLabel(left).toLowerCase()} left` :<><span>$ {(item.price / 100).toFixed(2)}</span></>
  }

  const redirectToGoogleMaps = (location) => {
    const url = 'https://www.google.com/maps/search/?api=1&query=' + location;
    window.open(url , '_blank');
  }
 
  if(item.id === 2997){
    console.log("refund",
      item.subscribed && 
      item.price > 0
    )
  }
  
  const checkLastItem = (string, arr, index, day) => {
    let newString = '';
    if(index === arr.length - 1) {
      const startDate = item.schedule_info[0].start_date + ' ' + item.schedule_info[0].start_time;
      const endDate = item.schedule_info[0].end_date + ' ' + item.schedule_info[0].end_time;
      const startTime = moment.utc(startDate, 'YYYY-MM-DD hh:mm').local().format('h:mm A');
      const endTime = moment.utc(endDate, 'YYYY-MM-DD hh:mm').local().format('h:mm A');

      newString = string + day + ' ' + startTime + " - " + endTime;
    } else  {
      newString = string + day + ', ';
    }
    return newString;
  };

  const getDayOfWeek = (arr) => {
    let string = '';
    arr.forEach((item, index) => {
      if(item === "0") {
        string = checkLastItem(string, arr, index, 'Sun');
      } else if(item === "1") {
        string = checkLastItem(string, arr, index, 'Mon');
      } else if(item === "2") {
        string = checkLastItem(string, arr, index, 'Tue');
      } else if(item === "3") {
        string = checkLastItem(string, arr, index, 'Wed');
      } else if(item === "4") {
        string = checkLastItem(string, arr, index, 'Thu');
      } else if(item === "5") {
        string = checkLastItem(string, arr, index, 'Fri');
      } else if(item === "6") {
        string = checkLastItem(string, arr, index, 'Sat');
      }
    });
    return string;
  };

  const getDayOfMonth = (arr, startDate) => {
     const date = moment(startDate, 'YYYY-MM-DD').format('MM.YYYY');
     let string = '';

    arr.forEach((item, index) => {
      const day = moment(item + "." + date, 'DD.MM.YYYY').format("Do");
      string = checkLastItem(string, arr, index, day);
    });
    return string;
  };

  const endDateArr = [];
  const startDateArr = [];
  let repeatsDayOfWeek = '';

  const sortWeeklyRepeats = (startDay, itemScheduleInfoRepeats) => {
    const newArr = itemScheduleInfoRepeats.filter(item => Number(item) !== Number(startDay));
    const result = [String(startDay)];

    newArr.sort((a,b) => {
      return a - b;
    })

    newArr.forEach(item => {
      if(Number(item > Number(startDay))) {
        result.push(item);
      }
    });

    newArr.forEach(item => {
      if(Number(item < Number(startDay))) {
        result.push(item);
      }
    });

    return result;
  };
  console.log("item", JSON.parse(JSON.stringify(item)))
  item.schedule_info && item.schedule_info.forEach(el => {
    endDateArr.push(el.end_date);
    startDateArr.push(el.start_date);
  });

  endDateArr.sort((a, b) => moment(b, 'YYYY-MM-DD').unix() - moment(a, 'YYYY-MM-DD').unix());
  startDateArr.sort((a, b) => moment(a, 'YYYY-MM-DD').unix() - moment(b, 'YYYY-MM-DD').unix());

  if(item.group_type !== "webinar" && item.group_type !== "recurring_webinar") {
    if(item.schedule_info !== null && item.schedule_info[0].type === "specific_date") {
      let string = '';
      const day = moment(item.schedule_info[0].start_date, 'YYYY-MM-DD').format( 'ddd' );
      repeatsDayOfWeek = checkLastItem(string, [day], 0, day);
    } else if (item.schedule_info !== null && item.schedule_info[0].type === "weekly") {
      const startDay = moment(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z").weekday();
      const itemScheduleInfoRepeats = item.schedule_info[0].repeats;
      const newArrWeeklyRepeats = sortWeeklyRepeats(startDay, itemScheduleInfoRepeats);
      repeatsDayOfWeek = getDayOfWeek(newArrWeeklyRepeats);
    } else if (item.schedule_info !== null && item.schedule_info[0].type === "monthly") {
      const startDay = moment(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z").format('DD');
      const newArrMonthlyRepeats = sortWeeklyRepeats(startDay, item.schedule_info[0].repeats);
      repeatsDayOfWeek = getDayOfMonth(newArrMonthlyRepeats, item.schedule_info[0].start_date);
    }
  }

  const renderTitle = () => (
    <div className="detailsBlock">
      <div className="detailsBlock_name">{item.name}</div>
      <div className={'detailsBlock_owner'}>
        <GroupOwner owner={item.owner} showLink={true} />
        <div className="detailsPrice">
          {item.meetings_count > 1 &&
              <div className="detailsMeetings">
                <span>{item.past_meetings_count}/{item.meetings_count}</span>&nbsp;{meetingsLabel(item.meetings_count)}
              </div>
          }
          <div className="detailsProce" style={{marginLeft:"0"}}>{item.price && item.available_for_purchases ? <div>{meetingsPriceWithDetails()}</div> : 'FREE'}</div>
        </div>
      </div>
      <div className="detailsDateTime" style={{flexDirection:"column"}}>
        {item.in_person && <div className="detailsTime" style={{marginLeft:"0", marginBottom:"15px"}}>
          <img src={inPersonIcon} alt="inPerson"/>&nbsp;&nbsp;
          <div className="detailsTimes">
            <span>{item.location}</span>&nbsp;&nbsp;
            <span className="detailsLocation" onClick={() => redirectToGoogleMaps(item.location)}>
              show on map
            </span>
          </div>
        </div>}
        <div className="detailsTime" style={{marginLeft:"0"}}>
          <img src={calendarDate} alt="calendar"/>&nbsp;&nbsp;
          {startDateArr.length > 0 && endDateArr.length > 0 ?
              <div className="detailsTimes" style={{marginLeft:"0"}}>
                <div>
                  {moment(startDateArr[0]).format('DD MMM YYYY')}
                  &nbsp;-&nbsp;
                  {moment(endDateArr[0]).format('DD MMM YYYY')}
                </div>
              </div> : item.meetings_records?.length > 0 ?
                  <div className="detailsTimes" style={{marginLeft:"0"}}>
                    {item.meetings_records?.length > 0 &&
                        <div>
                          {moment.utc(item.meetings_records.sort((a, b) => b.start_time - a.start_time)[0].start_time).local().format('DD MMM YYYY')}
                          &nbsp;-&nbsp;
                          {moment.utc(item.meetings_records.sort((a, b) => b.start_time - a.start_time)[item.meetings_records.length - 1].start_time).local().format('DD MMM YYYY')}
                        </div>}
                  </div> :
                  <div className="detailsTimes" style={{marginLeft:"0"}}>
                    {!!item.legacy_fields &&
                        <div>
                          {moment.utc(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z").local().format('ddd')}
                          &nbsp;
                          {moment.utc(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z").local().format('h:mm A')}
                          &nbsp;-&nbsp;
                          {moment.utc(item.first_meeting_date, "YYYY-MM-DD hh:mm:ss A Z")
                              .add(item.legacy_fields?.duration, 'minutes').local().format('h:mm A')}
                        </div>}
                  </div>
          }
        </div>
        {(item.group_type !== "webinar" && item.group_type !== "recurring_webinar") && <div style={{height:"15px"}}></div>}
        {(item.group_type === "webinar" || item.group_type === "recurring_webinar") ?
        <div></div> :
            <div>
              {!!repeatsDayOfWeek && <div className="detailsTime" style={{marginLeft:"0"}}>
                <CalendarTimeIcon />&nbsp;&nbsp;
                <div className="detailsTimes" style={{marginLeft:"0"}}>
                  <div>{repeatsDayOfWeek}</div>
                </div>
            </div>}
            </div>
        }
      </div>
      <div className="groupTileTag">
        {
          (item.group_type === "webinar" || item.group_type === "recurring_webinar")
            && item.private ?
            <div className="groupType groupType webinar privateLivestream">
              <img src={privateLivestreamIcon} alt="privateLivestreamIcon"/>
              <div>Private Livestream</div>
            </div>
            : <GroupTypeLabel
                group_type={item.group_type}
                owner={item.owner}
                isPrivate={item.private}
                className="groupType"
                isClosed={item.price > 0}
                group={item}
                id={item.id}
            />
        }
        {item.in_person && <div className="groupType groupCategory">{item.category?.title || item.category_id?.title}</div>}
        {item.group_type === gtRecurring ?
          <div className={classNames("whiteGroupTag", {[item.group_type]: true})}>{recurringGroupTitleTag}</div> : item.tag && <div className="groupTag">{item.tag.title}</div>
        }
        {item.group_type === gtRecurringWebinar ?
          <div className={classNames("whiteGroupTag", {[item.group_type]: true})}>{recurringWebinarTitleTag}</div> : item.tag && <div className="groupTag">{item.tag.title}</div>
        }
      </div>
      <div className="groupTileTag multipleTagsStyle">
        {displayedTags.map((tag, key) =>(<div className="groupTag" key = {key}>{tag}</div>))}
      </div>
      {item?.description && <div className="detailsDesc">{`${item?.description?.replace("/ig", "\n")}`}</div>}
      {eventButtons()}
      {isOwner(item.owner) ? (
        <GroupActions
          group={item}
          showDelete={showDelete}
          showCancel={showCancel}
          showClosed={showClosed}
          setEditGroup={setEditGroup}
        /> 
      ): null}
      {(item.subscribed && item.invited !== "speaker" && item.invited !== "none" &&
        item.price > 0 && 
        item.amount && 
        groupParticipants?.participants && 
        !groupParticipants.participants.some(participant => participant.id === user.id && participant.speaker) && 
        isFullUpcomingEvent && timeToStartMetting(item?.start_time) > 10 && 
        isRecurringNotStartUse && 
        canAskRefund) ? (
        <Button
          label="Request a refund"
          big60
          className="refundButton"
          onClick={() => {
            changeResultMessage('');
            setOpenRefund(true)
          }}
        />
      ): null}
      {children}
      <div className="detailsAttends">
        <div className="titleAttendees">
          <AtendeesIcon />&nbsp;&nbsp;{attendeesLabes}
        </div>
        {isOwner(item.owner) && <div className="actionAttendees" onClick={showAttendees}>
          View
        </div>}
      </div>
    </div>
  )

  const renderContent = () => {
    switch (true) {
      case (report || openReport):
          console.log(11)
        return <ReportGroupForm hideReport={hideReport} saveReport={saveReport} />;

      case (openDelete):
          console.log(112)
        return <DeleteGroupForm hideDelete={hideDelete} deleteGroup={handleDeleteGroup} isOwner={isOwner(item.owner)} />;

      case (openClose):
          console.log(113)
        return <CloseGroupForm hideClosed={hideClosed} toggleGroup={toggleGroup} groupClosed={groupClosed} />;

      case (openAttendees):
          console.log(114)
        return <AttendeesList hideAttendees={hideAttendees} groupId={item.id} />;

      case (openRefund):
          console.log(115)
        return (
          <div className="detailsBlock">
            <GroupRefund group_id={item.id} close={() => setOpenRefund(false)} />
          </div>
        );

      case (openCancel):
          console.log(116)
        return <CancelGroupForm hideCancel={hideCancel} successCancelClose={successCancelClose} item={item}/>;

      default:
          console.log(117)
        return renderTitle();
    }
  }

  return (
    <div id={'groupDetailsBlock'} className="groupDetailsBlock" style={{position:"relative"}}>
      <div className='shadow'></div>
      <GroupHeader item={item} reportGroup={showReport} removeGroup={showDelete} />
      {renderContent()}
      {/* {(report || openReport) && <ReportGroupForm hideReport={hideReport} saveReport={saveReport} />}
      {openCancel && <CancelGroupForm hideCancel={hideCancel} cancelGroup={handleCancelGroup} />}
      {openDelete && <DeleteGroupForm hideDelete={hideDelete} deleteGroup={handleDeleteGroup} isOwner={isOwner(item.owner)} />}
      {openClose && <CloseGroupForm hideClosed={hideClosed} toggleGroup={toggleGroup} groupClosed={groupClosed} />}
      {openAttendees && <AttendeesList hideAttendees={hideAttendees} groupId={item.id} />}
      {openRefund && <GroupRefund group_id={item.id} close={() => setOpenRefund(false)} />}
      {!report && !openReport && !openDelete && !openClose && !openCancel && !openAttendees && !openRefund && renderTitle()} */}
    </div>
  )
}

export default inject('groupsModel', 'userModel')(observer(GroupDetails));
