import React from 'react'
import create_group_icons from '../../../res/icons/create_group_icons';

const ActionIcon = ({ onClick, type, offset, className = "" }) => (
  <img
    alt={type}
    onClick={onClick}
    src={create_group_icons.purple[type]}
    style={{ cursor: 'pointer', position: 'relative', ...offset }}
    className={className}
  />
)

export default ActionIcon