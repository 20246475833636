import React, {useEffect, useState} from 'react';
import { inject, observer } from 'mobx-react';
import RightPerson from '../RightPerson';

import './whofollow.scss';
import { Divider } from '@material-ui/core';

const WhoFolloow = (props) => {
  const {
    title = 'Who to Follow',
    count = 2,
    masterMindsModel: { randomMinds, randomItems, masterMinds },
  } = props;
  const [renderedMMs, setRenderedMMs ] = useState([])

  useEffect(
    (_) => {
        masterMinds && randomItems(count);
    },
    [masterMinds]
  );



  useEffect(() => {
    randomMinds?.length &&  setRenderedMMs(randomMinds)
  },[randomMinds])



  return (
    <>
      {renderedMMs?.length? <div className="rightBox follow">
        <h2 className='rightBox-title'>{title}</h2>
        <Divider />

        {renderedMMs.map((user, i) => (
          <RightPerson
            person={user}
            key={user?.id}
            connectButtons
            showViewProfile={false}
            bottomButton
            isLastItem={i + 1 === renderedMMs?.length}
          />
        ))}
      </div> : null}
    </>
  );
};

export default inject('masterMindsModel')(observer(WhoFolloow));
