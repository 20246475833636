import {withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const AntSwitchBlue = withStyles((theme) => ({
  root: {
    width: 30,
    height: 18,
    padding: 0,
    display: 'flex',
    margin: `0 15px`,
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#4E519F',
        borderColor: '#4E519F',
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default AntSwitchBlue