import React from 'react';
import { inject, observer } from 'mobx-react';

import CustomSneckbar from '../CustomSnackbar';

import './messageGlobal.scss';

const MessagesGlobal = props => {
  const {
    messagesModel: {
      messages, status, setShowMessages, setMessages, setStatus, showMessages
    }
  } = props;
  
  return (
    <CustomSneckbar 
      message={messages}
      showMessages={showMessages}
      onClose={() => {
        setMessages('');
        setShowMessages(false);
        setStatus(false);
      }}
      severity={status ? "success" : 'error'}
      duration={3500} // 5500
    />
  )
}

export default inject('messagesModel')(observer(MessagesGlobal));
