import React, { memo } from 'react';

const PromoUncheckIcon = props => {
  // const { color = 'white' } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="1.5" fill="white" stroke="#E9E9F0"/>
    </svg>
  )
}

export default memo(PromoUncheckIcon);
