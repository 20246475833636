import React from "react";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import BankImg from "../../res/img/bank.png";
import DeleteImg from "../../res/icons/delete_grey.svg";
import Dialog from "../Dialog";
import MessageDelete from "./Message";

import "./style.scss";

const BankAccountsList = (props) => {
  const {
    bankAccountsModel: {
      bankAccounts,
      deleteBankAccount,
      resultMessage,
      setResultMessage,
      setAsDefault,
    },
  } = props;

  return bankAccounts?.map((bankAccount) => {
    const isDefalutPayoutBankAccount = bankAccount.default_payout;

    return (
      <div className="payment_method_item_wrapper" key={bankAccount.id}>
        <div className="payment_method_item">
          <div className="payment_method_item_img_info">
            <div className="payment_method_item_image">
              <img src={BankImg} alt="Bank" />
            </div>

            <div className="payment_item_info">
              <span>{bankAccount.account_holder_name}</span>
              <div className="payment_item_sub_info">
                <span>{bankAccount.country}</span>
                <div className="dot" />
                <span>{bankAccount.currency}</span>
                <div className="dot" />
                <span>...{bankAccount.last4}</span>
              </div>
            </div>
          </div>

         {!isDefalutPayoutBankAccount  &&<Dialog
            openButton={<img className="delete_icon" src={DeleteImg} />}
            className="login"
          >
            <MessageDelete
              onDelete={() => deleteBankAccount(bankAccount.id)}
              resualtMessage={resultMessage}
              changeResultMessage={setResultMessage}
            />
          </Dialog>}
        </div>
        <div className="payment_method_default_actions">
          <div
            className={classNames(
              "payment_method_default_action",
              isDefalutPayoutBankAccount && "active"
            )}
            onClick={() => setAsDefault(bankAccount.id, "payout")}
          >
            Default Payout Method
          </div>
        </div>
      </div>
    );
  });
};

export default inject("bankAccountsModel")(observer(BankAccountsList));
