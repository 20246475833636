import classNames from 'classnames';
import create_group_icons from '../../res/icons/create_group_icons';
import { meetingFormats } from '../../const/groupsData';

export const fieldsData = {
  recurring: [
    {
      name: 'name',
      icon: create_group_icons.purple.content,
      placeholder: "Group Name",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
    },
    {
      name: 'recurring',
      placeholder: "Available for Subscribers",
      fieldType: 'switcher',
      type: 'includesFields',
      className: classNames('recurring', 'width320'),
      wrapperColor: 'violet',
      text: 'This option allows you to create a group that will last for a very long period. Members buy a package of slots to participate and can join whenever they want as long as they have slots.\n',
      includesTabs: {
        true: [
          {
            name: 'subscription_packages_ids',
            icon: create_group_icons.white.firePercent,
            placeholder: "Subscription Packages",
            className: classNames('simpleInput', 'width280'),
            fieldType: 'dropdown-checkboxes',
          },
        ],
        false:[]
      }
    },
    {
      name: 'available_for_purchases',
      placeholder: "Single Purchase Price",
      fieldType: 'switcherSinglePurchasePrice',
      type: 'includesFields',
      className: classNames('recurring', 'width320'),
      wrapperColor: 'purple',
      includesTabs: {
        true: [
          {
            icon: create_group_icons.purple.coins,
            fieldType: 'simple_dependent_price',
            placeholder: "Price (USD)",
            name: "price",
            className: classNames('simpleInput'),
          },
          {
            fieldType: 'simple_switcher',
            placeholder: "Create Package",
            name: "create_package",
            className: classNames('simpleInput'),
          },
          {
            icon: create_group_icons.purple.tickets,
            fieldType: 'simple_recurring_purchase_meetings',
            placeholder: "Meetings in Pack",
            name: "meetings_in_pack",
            className: classNames('simpleInput'),
            withoutWrappedClass: true,
          },
          {
            icon: create_group_icons.purple.coins,
            fieldType: 'simple_recurring_purchase_price',
            placeholder: "Pack Price",
            name: "pack_price",
            className: classNames('simpleInput'),
            withoutWrappedClass: true,
          },
        ],
        false:[]
      }
    },
    {
      icon: create_group_icons.purple.calendar,
      fieldType: 'schedule',
      placeholder: "Choose Schedule",
      name: "schedule",
      className: classNames('simpleInput', 'disableLikeActive'),
      disabled: true,
    },
    {
      fieldType: 'select-multiple',
      icon: create_group_icons.purple.menudots,
      className: classNames('simpleInput', "width320", 'select'),
      name: "communities",
      placeholder: "Community",
      label: "Community",

    },
    {
      icon: create_group_icons.purple.tag,
      fieldType: 'dialog',
      placeholder: "Tags",
      name: "tags",
      className: classNames('simpleInput', 'width320', 'disableLikeActive'),
      disabled: true,
    },
    {
      name: 'description',
      icon: create_group_icons.purple.align,
      placeholder: "Group Description",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
      multiline: true,
    },
    {
      fieldType: 'simple_switcher_inPerson',
      placeholder: "In-person",
      name: "in_person",
      className: classNames('simpleInput'),
    },
    {
      name: 'location',
      icon: create_group_icons.purple.location,
      placeholder: "Location",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple_recurring_purchase_inPerson',
      multiline: true,
    },
    {
      fieldType: 'simple-simple-inPerson',
      icon: create_group_icons.purple.category,
      className: classNames('simpleInput', "width320", 'select'),
      name: "category",
      placeholder: "Category",
      label: "Event",
    },
  ],
  master_mind: [
    {
      name: 'name',
      icon: create_group_icons.purple.content,
      placeholder: "Group Name",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
    },
    {
      name: 'recurring',
      placeholder: "Available for Subscribers",
      fieldType: 'switcher',
      type: 'includesFields',
      className: classNames('recurring', 'width320'),
      wrapperColor: 'violet',
      text: 'This option allows you to create a group that will last for a very long period. Members buy a package of slots to participate and can join whenever they want as long as they have slots.\n',
      includesTabs: {
        true: [
          {
            name: 'subscription_packages_ids',
            icon: create_group_icons.white.firePercent,
            placeholder: "Subscription Packages",
            className: classNames('simpleInput', 'width280'),
            fieldType: 'dropdown-checkboxes',
          },
        ],
        false:[]
      }
    },
    {
      name: 'available_for_purchases',
      placeholder: "Single Purchase Price",
      fieldType: 'switcherSinglePurchasePrice',
      type: 'includesFields',
      className: classNames('recurring', 'width320'),
      wrapperColor: 'purple',
      includesTabs: {
        true: [
          {
            icon: create_group_icons.purple.coins,
            fieldType: 'simple_dependent_price',
            placeholder: "Price (USD)",
            name: "price",
            className: classNames('simpleInput'),
          },
          {
            fieldType: 'simple_switcher',
            placeholder: "Create Package",
            name: "create_package",
            className: classNames('simpleInput'),
          },
          {
            icon: create_group_icons.purple.tickets,
            fieldType: 'simple_recurring_purchase_meetings',
            placeholder: "Meetings in Pack",
            name: "meetings_in_pack",
            className: classNames('simpleInput'),
            withoutWrappedClass: true,
          },
          {
            icon: create_group_icons.purple.coins,
            fieldType: 'simple_recurring_purchase_price',
            placeholder: "Pack Price",
            name: "pack_price",
            className: classNames('simpleInput'),
            withoutWrappedClass: true,
          },
        ],
        false:[]
      }
    },
    {
      icon: create_group_icons.purple.calendar,
      fieldType: "schedule",
      placeholder: "Choose Schedule",
      name: "schedule",
      className: classNames('simpleInput', 'disableLikeActive'),
      disabled: true,
    },
    {
      fieldType: 'select-multiple',
      icon: create_group_icons.purple.menudots,
      className: classNames('simpleInput', "width320", 'select'),
      name: "communities",
      placeholder: "Community",
      label: "Community",

    },
    {
      icon: create_group_icons.purple.tag,
      fieldType: 'dialog',
      placeholder: "Tags",
      name: "tags",
      className: classNames('simpleInput', 'width320', 'disableLikeActive'),
      disabled: true,
    },
    {
      name: 'description',
      icon: create_group_icons.purple.align,
      placeholder: "Group Description",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
      multiline: true,
    },
    {
      fieldType: 'simple_switcher_inPerson',
      placeholder: "In-person",
      name: "in_person",
      className: classNames('simpleInput'),
    },
    {
      name: 'location',
      icon: create_group_icons.purple.location,
      placeholder: "Location",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple_recurring_purchase_inPerson',
      multiline: true,
    },
    {
      fieldType: 'simple-simple-inPerson',
      icon: create_group_icons.purple.category,
      className: classNames('simpleInput', "width320", 'select'),
      name: "category_id",
      placeholder: "Category",
      label: "Event",
    },
    {
      fieldType: 'simple_membersList_inPerson',
      icon: create_group_icons.purple.members,
      className: classNames('simpleInput'),
      name: "member_limit",
      placeholder: "Members Limit",
      label: "Members Limit",
    },
  ],
  webinar: [
    {
      name: 'name',
      icon: create_group_icons.red.content,
      placeholder: "Group Name",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
    },
    {
      name: 'recurring',
      placeholder: "Available for Subscribers",
      fieldType: 'switcher',
      type: 'includesFields',
      className: classNames('recurring', 'width320'),
      wrapperColor: 'red',
      text: 'This option allows you to create a group that will last for a very long period. Members buy a package of slots to participate and can join whenever they want as long as they have slots.\n',
      includesTabs: {
        true: [
          {
            name: 'subscription_packages_ids',
            icon: create_group_icons.white.firePercent,
            placeholder: "Subscription Packages",
            className: classNames('simpleInput', 'width280'),
            fieldType: 'dropdown-checkboxes',
          },
        ],
        false:[]
      }
    },
    {
      name: 'available_for_purchases',
      placeholder: "Single Purchase Price",
      fieldType: 'switcherSinglePurchasePrice',
      type: 'includesFields',
      className: classNames('recurring', 'width320'),
      wrapperColor: 'red',
      includesTabs: {
        true: [
          {
            icon: create_group_icons.red.coins,
            fieldType: 'simple_dependent_price',
            placeholder: "Price (USD)",
            name: "price",
            className: classNames('simpleInput'),
          },
          {
            fieldType: 'simple_switcher',
            placeholder: "Create Package",
            name: "create_package",
            className: classNames('simpleInput'),
          },
          {
            icon: create_group_icons.red.tickets,
            fieldType: 'simple_recurring_purchase_meetings',
            placeholder: "Meetings in Pack",
            name: "meetings_in_pack",
            className: classNames('simpleInput'),
            withoutWrappedClass: true,
          },
          {
            icon: create_group_icons.red.coins,
            fieldType: 'simple_recurring_purchase_price',
            placeholder: "Pack Price",
            name: "pack_price",
            className: classNames('simpleInput'),
            withoutWrappedClass: true,
          },
        ],
        false:[]
      }
    },
    {
      icon: create_group_icons.red.calendar,
      fieldType: 'multi-schedule',
      placeholder: "Choose Schedule",
      name: "schedule",
      className: classNames('simpleInput', 'width320', 'disableLikeActive'),
      disabled: true,
    },
    {
      fieldType: 'select-multiple',
      icon: create_group_icons.red.menudots,
      className: classNames('simpleInput', "width320", 'select'),
      name: "communities",
      placeholder: "Community",
      label: "Community",
    },
    {
      icon: create_group_icons.red.tag,
      fieldType: 'dialog',
      placeholder: "Tags",
      name: "tags",
      className: classNames('simpleInput', 'width320', 'disableLikeActive'),
      disabled: true,
    },
    {
      name: 'description',
      icon: create_group_icons.red.align,
      placeholder: "Group Description",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
      multiline: true,
    }
  ],
  recurring_webinar: [
    {
      name: 'name',
      icon: create_group_icons.red.content,
      placeholder: "Group Name",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
    },
    {
      name: 'recurring',
      placeholder: "Available for Subscribers",
      fieldType: 'switcher',
      type: 'includesFields',
      className: classNames('recurring', 'width320'),
      wrapperColor: 'red',
      text: 'This option allows you to create a group that will last for a very long period. Members buy a package of slots to participate and can join whenever they want as long as they have slots.\n',
      includesTabs: {
        true: [
          {
            name: 'subscription_packages_ids',
            icon: create_group_icons.white.firePercent,
            placeholder: "Subscription Packages",
            className: classNames('simpleInput', 'width280'),
            fieldType: 'dropdown-checkboxes',
          },
        ],
        false:[]
      }
    },
    {
      name: 'available_for_purchases',
      placeholder: "Single Purchase Price",
      fieldType: 'switcherSinglePurchasePrice',
      type: 'includesFields',
      className: classNames('recurring', 'width320'),
      wrapperColor: 'red',
      includesTabs: {
        true: [
          {
            icon: create_group_icons.red.coins,
            fieldType: 'simple_dependent_price',
            placeholder: "Price (USD)",
            name: "price",
            className: classNames('simpleInput'),
          },
          {
            fieldType: 'simple_switcher',
            placeholder: "Create Package",
            name: "create_package",
            className: classNames('simpleInput'),
          },
          {
            icon: create_group_icons.red.tickets,
            fieldType: 'simple_recurring_purchase_meetings',
            placeholder: "Meetings in Pack",
            name: "meetings_in_pack",
            className: classNames('simpleInput'),
            withoutWrappedClass: true,
          },
          {
            icon: create_group_icons.red.coins,
            fieldType: 'simple_recurring_purchase_price',
            placeholder: "Pack Price",
            name: "pack_price",
            className: classNames('simpleInput'),
            withoutWrappedClass: true,
          },
        ],
        false:[]
      }
    },
    {
      icon: create_group_icons.red.calendar,
      fieldType: 'multi-schedule',
      placeholder: "Choose Schedule",
      name: "schedule",
      className: classNames('simpleInput', 'width320', 'disableLikeActive'),
      disabled: true,
    },
    {
      fieldType: 'select-multiple',
      icon: create_group_icons.red.menudots,
      className: classNames('simpleInput', "width320", 'select'),
      name: "communities",
      placeholder: "Community",
      label: "Community",
    },
    {
      icon: create_group_icons.red.tag,
      fieldType: 'dialog',
      placeholder: "Tags",
      name: "tags",
      className: classNames('simpleInput', 'width320', 'disableLikeActive'),
      disabled: true,
    },
    {
      name: 'description',
      icon: create_group_icons.red.align,
      placeholder: "Group Description",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
      multiline: true,
    }
  ],
  support: [
    {
      name: 'name',
      icon: create_group_icons.blue.content,
      placeholder: "Group Name",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
    },
    {
      fieldType: 'select-simple',
      icon: create_group_icons.blue.ping,
      className: classNames('simpleInput', "width320", 'select'),
      name: "meeting_format",
      placeholder: "Meetting Format",
      label: "Meetting Format",
      data: meetingFormats,
    },
    {
      icon: create_group_icons.blue.calendar,
      fieldType: 'dialog',
      placeholder: "Choose Schedule",
      name: "schedule",
      className: classNames('simpleInput', 'width320', 'disableLikeActive'),
      disabled: true,
    },
    {
      fieldType: 'select-multiple',
      icon: create_group_icons.blue.menudots,
      className: classNames('simpleInput', "width320", 'select'),
      name: "communities",
      placeholder: "Community",
      label: "Community",

    },
    {
      icon: create_group_icons.blue.tag,
      fieldType: 'dialog',
      placeholder: "Tags",
      name: "tags",
      className: classNames('simpleInput', 'width320', 'disableLikeActive'),
      disabled: true,
    },
    {
      name: 'description',
      icon: create_group_icons.blue.align,
      placeholder: "Group Description",
      className: classNames('simpleInput', "width320"),
      fieldType: 'simple',
      multiline: true,
    },
  ],
};
