import React, { memo } from 'react';
import { inject, observer } from "mobx-react";

import Oops from '../../res/icons/oops.svg';

import './emptey.scss';

const Empty = ({userModel:{user}}) => {
  return <div className="emptyBlock">
    <div className="empty">
      {user?.role === "master_mind" && (
        <>
          <div className = "title">Start creating!</div>
          <div className = "desc">Create your own livestream or group. Add courses and content. Become a popular and sought-after creator!</div>
        </>
      )}
      {user.role === "user" && (
        <>
          <div className = "title">Follow Creators</div>
          <div className = "desc">to automatically be fed their upcoming Groups, Livestreams and Content to your Daily Feed</div>
          <div className = "green_title">All Creators</div>
          <div className = "desc">or browse our Livestream or Group feeds to see upcoming events to Attend</div>

        </>
      )}
    </div>
  </div>

};

export default inject("userModel") (observer(Empty));
