import React, { memo } from 'react';

const LiveCreateIcon = props => {
//   const { color = '#43425D' } = props;

  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M54 0H6C2.7 0 0 2.7 0 6V60L12 48H54C57.3 48 60 45.3 60 42V6C60 2.7 57.3 0 54 0ZM48 36L36 26.4V36H12V12H36V21.6L48 12V36Z" fill="#DD4B39" />
    </svg>
  )
}

export default memo(LiveCreateIcon);
