import React, { memo } from 'react';

const SupportCreateIcon = props => {
//   const { color = '#43425D' } = props;

  return (
    <svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.9876 0C11.1331 0 0.714844 10.4183 0.714844 23.2728V34.6705V39.2859V49.2144C0.714844 51.7731 2.78381 53.8421 5.34247 53.8421H5.41593C7.97459 53.8421 10.0436 51.7731 10.0436 49.2144V43.9135C10.0436 41.3548 12.1125 39.2859 14.6712 39.2859H14.7446C17.3033 39.2859 19.3723 41.3548 19.3723 43.9135V55.3724C19.3723 57.931 21.4412 60 23.9999 60H24.0733C26.632 60 28.701 57.931 28.701 55.3724V43.9135C28.701 41.3548 30.7699 39.2859 33.3286 39.2859C35.8873 39.2859 37.9562 41.3548 37.9562 43.9135V48.8227C37.9562 51.3813 40.0252 53.4503 42.5838 53.4503H42.6573C45.216 53.4503 47.2849 51.3813 47.2849 48.8227V37.8168V34.6705V23.2728C47.2604 10.4183 36.8422 0 23.9876 0ZM23.9876 31.8302C18.1725 31.8302 13.4592 27.1169 13.4592 21.3018C13.4592 15.4866 18.1725 10.7733 23.9876 10.7733C29.8028 10.7733 34.5161 15.4866 34.5161 21.3018C34.5161 27.1169 29.8028 31.8302 23.9876 31.8302Z" fill="#2E9DC0" />
    <path d="M28.0155 18.8533C27.183 17.4944 25.7017 16.5762 23.9878 16.5762C21.3801 16.5762 19.2622 18.6941 19.2622 21.3017C19.2622 23.9094 21.3801 26.0273 23.9878 26.0273C25.7017 26.0273 27.183 25.1091 28.0155 23.7502C26.9382 23.4442 26.1547 22.4648 26.1547 21.2895C26.1424 20.1387 26.9382 19.1593 28.0155 18.8533Z" fill="#2E9DC0" />
    </svg>
  )
}

export default memo(SupportCreateIcon);
