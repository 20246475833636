import React from 'react';
import { inject, observer } from 'mobx-react';
// import isEmpty from 'lodash/isEmpty';
import FirstLoginHeader from './FirstLoginHeader';
import UnloginHeader from './UnloginHeader';
import LoginHeader from './LoginHeader';

import './Header.scss';

const Header = props => {
  const { authModel, userModel } = props;
  const { session_token, logout } = authModel;
  const { user, updatecommunity, community } = userModel;

  if (session_token && !community) return (
    <FirstLoginHeader
      user={user}
      onLogout={logout}
      updatecommunity={updatecommunity}
      community={community}
      key="firstLogin" 
    />
    );
  if (session_token) return ( // && !isEmpty(user)
    <LoginHeader
      user={user}
      onLogout={logout}
      updatecommunity={updatecommunity}
      community={community}
      key="loginUser"
    />
  );
  
  return <UnloginHeader key="unloginUser"/>
}

export default inject('authModel', 'userModel')(observer(Header));
