import React, { useState } from 'react';
import { Field } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useEffect } from 'react';
import axios from 'axios';

const CustomSelect = props => {
  const { name = "test", data, showCode = true, setFieldValue, className, label = 'Code'} = props;

  const [countryCode, setCountryCode] = useState("");
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        if(countryCode === ""){
          setCountryCode( data.country_calling_code.substring(1));
          setFieldValue('code', data.country_calling_code.substring(1))
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if(name === "code"){
      getGeoInfo();
    } 
  }, []);


  return (
    <Field name={name}>
      {({
          field: { name, value, onChange, onBlur },
          form: { touched, errors, isValid },
          meta,
        }) => {
          let currentValue = value;
          if (showCode) {
            currentValue = value ? `+${value}` : '';
          }
         
          return (
            <FormControl error={meta.touched && !!errors[name]} className={className}>
              <InputLabel id="select-label">{label}</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                value={currentValue}
                onChange={e => setFieldValue('code', e.target.value)}
                renderValue={(value) => value}
                onBlur={onBlur}
                MenuProps={{ className: "codeSelect" }}
              >
                {data.map(item => (
                  <MenuItem value={item.value} key={`${item.value}-${item.label}`} className="codeItem">
                    {showCode && <div className="showCode">{item.value}</div>}
                    <div className="itemLabel">{item.label}</div>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{meta.touched && errors[name]}</FormHelperText>
            </FormControl>
          );
        }
      }
    </Field>
  )
};

export default CustomSelect;
