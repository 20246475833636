import React, { memo } from 'react';

const WadningIcon = props => {
  const { color = 'white' } = props;

  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
      <path d="M9.80009 7.28635L6.29667 1.21814C5.72443 0.215697 4.27737 0.215697 3.70719 1.21814L0.20171 7.28635C-0.370529 8.28879 0.341682 9.53413 1.49645 9.53413H8.493C9.64777 9.53413 10.3723 8.27644 9.80009 7.28635ZM4.99987 8.18793C4.69523 8.18793 4.44204 7.93475 4.44204 7.6301C4.44204 7.32546 4.69523 7.07227 4.99987 7.07227C5.30452 7.07227 5.5577 7.32546 5.54535 7.64451C5.55976 7.93475 5.29217 8.18793 4.99987 8.18793ZM5.5083 4.58159C5.4836 5.01386 5.45684 5.44407 5.43214 5.87633C5.41979 6.01631 5.41979 6.14393 5.41979 6.28184C5.40744 6.51033 5.22836 6.68735 4.99987 6.68735C4.77139 6.68735 4.59436 6.52268 4.57995 6.29419C4.5429 5.62109 4.50379 4.96034 4.46674 4.28724C4.45439 4.11022 4.44204 3.93113 4.42763 3.75411C4.42763 3.46181 4.59231 3.22098 4.8599 3.14482C5.12749 3.08101 5.39303 3.20863 5.5083 3.46181C5.54741 3.55033 5.55976 3.63884 5.55976 3.74176C5.54741 4.02376 5.52065 4.30371 5.5083 4.58159Z" fill="#FF2D55"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="10" height="10" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default memo(WadningIcon);
