import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import IconButton from "@material-ui/core/IconButton";
import './styles.scss'
import {returnName} from "../../units/func";
import classNames from "classnames";
import CurrentAvatar from "../CurrentAvatar";
import CheckedIcon from "../../res/iconComp/CheckedIcon";
import ClearAll from "../../res/icons/clearAll.svg";
import Button from "../Button";

const InviteModerators = ( props ) => {
  const {
    moderators = [],
    setModerators = (_) => {},
    removeModerator = (_) => {},
    handleClose = () => {},
    inviteMembersModel: {usersList, getUsers},
    userModel:{user:owner},
  } = props;
  const [value, setValue] = useState('');


  useEffect(() => {
    getUsers(value)
  },[])

  const handleChange = (event) => {
    getUsers({'full_name': event?.target?.value});
    setValue(event?.target?.value);
  };
  const handleMemberClick = (user, current) => current? removeModerator(user) : setModerators(user)

  return (
    <div className={'addModerators'}>
    <div className="addModerators_title"> Add Moderators</div>
      <div className="addModerators_search">
        <input value={value} onChange={handleChange} maxLength={48}/>
        {value &&
        <IconButton type="submit" className="clearAllButton" aria-label="clearAll" onClick={(e) => {e.stopPropagation(); setValue('')}}>
          <img src={ClearAll} alt="X" />
        </IconButton>
        }
      </div>
      <div className="addModerators_moderatorsList moderatorsList">
        {usersList?.users.map((user) => {
          if(user.id === owner.id) return null;
          const active = moderators.filter(member => member?.id == user?.id).length > 0;
          const userName = returnName(user);
          return (
            <div className={classNames("moderatorsList_user", { active })} onClick={() => handleMemberClick(user, active)} key={user.id}>
              <CurrentAvatar src={user?.image?.url} alt={userName} className="moderatorsList_avatar"/>
              <div className="moderatorsList_name">{userName}</div>
              <div className="moderatorsList_icon">
                <CheckedIcon color={active? "#FFFFFF" :'#DADADA'} innerColor={active? '#43425D': '#FFFFFF'}/>
              </div>
            </div>
          )
        })}
      </div>
      <div className="addModerators_okBtn">
        <Button
          type="submit"
          label="Ok"
          disabled={false}
          width100
          violetGradien
          onClick={() => {handleClose()}}
        />
      </div>
    </div>
  )
}

export default inject('inviteMembersModel', 'userModel')(observer(InviteModerators))