import React, { memo } from 'react';

const SendComment = props => {
  const { color = '#43425D' } = props;

  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.00761904 13.7143L16 6.85714L0.00761904 0L0 5.33333L11.4286 6.85714L0 8.38095L0.00761904 13.7143Z" fill={color} />
    </svg>
  )
}

export default memo(SendComment);