import React, {useCallback, useEffect} from 'react'
import moment from 'moment'
import { useFormikContext } from 'formik';
import InputWithIcon from '../Input/inputWithIcon';
import CustomInput from '../Input/customInput';

import SheduleSelect from '../Shedule';
import Dialog from "../Dialog";
import CustomField from "../UI/CustomField";

const CreateGroupSchedule = ({ rest, icon, schedule, setSchedules, onSelect, webinars, editGroup }) => {
    const { values, setFieldValue } = useFormikContext()

    const getPlaceholder = useCallback((scheduleItem) => {
        if((!scheduleItem || scheduleItem.type === 'specific_date') && !editGroup){
            return undefined;
        }

        if(scheduleItem && scheduleItem.initialData.weekly){
            return `RE: Weekly, from ${scheduleItem.startTime.format('hh:mm a')} to ${scheduleItem.endTime.format('hh:mm a')}`;
        }

        if(scheduleItem && scheduleItem.initialData.monthly){
            return `RE: Monthly, from ${scheduleItem.startTime.format('hh:mm a')} to ${scheduleItem.endTime.format('hh:mm a')}`;
        }

        if(scheduleItem) {
            return `${moment(scheduleItem.startTime).format('dddd')},  ${scheduleItem.startTime.format('DD MMMM YYYY')}`;
        }
    },[]);

    const updateSchedule = (value, key) => {
        setSchedules([value]);
    }

    const getRandomArbitrary = (index) => {
        return (Math.random() * (10000 - 1) + 1).toString() + index;
    }

    const startTime = schedule?.startTime;
    const endTime = schedule?.endTime;
    const dateTitle =  `${startTime ? startTime.format('hh:mm a') : ''} - ${endTime  ? endTime.format('hh:mm a') : ''}`;

        return (
            <div style={{ display: 'flex', alignContent: 'center' }}>
                <Dialog
                    openButton={
                        <InputWithIcon className={'input_with_icon100'} icon={icon} >
                            <CustomInput id={rest.name} value={dateTitle !== ' - ' ? dateTitle : ""} {...rest} customPlaceholder={getPlaceholder(schedule)} />
                        </InputWithIcon>
                    }
                    className="payment"
                    key={rest.name}
                >
                    <CustomField name="schedule" key="schedule" onSelect={(value) => {
                        const reccuring = value.weekly || value.monthly;
                        const id = ( schedule && schedule.id) || getRandomArbitrary(1);

                        if(reccuring){
                            if(value.weekly){
                                value.weekly = value.weekly.map(item => ({...item, id}));
                            }

                            if(value.monthly){
                                value.monthly = value.monthly.map(item => ({...item, id}));
                            }
                        }

                        value.id = id;
                        onSelect(value);
                    }}>
                        <SheduleSelect
                            onSelect={(value) => {
                                setFieldValue('schedule', 'Fake data in order to remove error trigger');
                                updateSchedule(value, 1);
                            }}
                            webinars={webinars}
                            scheduleItem={schedule}
                            editGroup={editGroup}
                            selected =  {[schedule]}
                            oneDayMode = {true}
                            id = {1}
                        />
                    </CustomField>
                </Dialog>
            </div>
        )
}

export default CreateGroupSchedule