import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import {allCreators, home} from '../../../const/urlKeys';

import './mmfollowing.scss';
import CurrentAvatar from "../../../components/CurrentAvatar";
import {returnName} from "../../../units/func";
import ButtonBase from '@material-ui/core/ButtonBase';
import {statusEnded} from "../../../const/general";

const MMFollowing = props => {
  const { 
    userModel: { user },
    searchModel: { searchMasterMinds,getMasterMinds },
    masterMindsModel: { sortedMasterMinds }, max = 10000
  } = props;

  useEffect(() => {
    getMasterMinds(10000);
  },[user.community_id])

  const [masterMinds, setMasterMinds] = useState();
  useEffect(_=>{
    setMasterMinds( _=> sortedMasterMinds? sortedMasterMinds.filter( mm => mm.followed && mm.id !== user.id && mm.community_id === user.community_id)?.slice(0, max) : null)
  },[sortedMasterMinds, max])

  if (!searchMasterMinds?.users?.length) return  null

  return (
    <div className="mMFollowing">
      <div className="titleBlock">
        <h3>Following</h3>
        <Link to={allCreators} className="greenLink">All Coaches</Link>
      </div>
      <div className={'mMFollowing_creators_wrapper'}>
        <div className={'mMFollowing_creators'}>
          {searchMasterMinds.users.length && searchMasterMinds.users
            .map((person, index) => {
              if (index < max) {
                return <ButtonBase key={person?.id} className={'creator buttonsCarousel'}>
                  <Link to={`/user/${person?.id}`}>
                    <CurrentAvatar alt={returnName(person)} src={person?.image?.thumbnail?.url} className='personInfo-avatar' />
                    <div className={'creator_name'}>{person?.full_name}</div>
                  </Link>
                </ButtonBase>
              }
            })
          }
        </div>
      </div>
    </div>
  )
}

export default inject('masterMindsModel', 'userModel', 'searchModel')(observer(MMFollowing));
