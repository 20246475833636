import React, { useState } from "react";
import CustomInput from "../../../Input/customInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import add_icon from "../../../../res/icons/play_list_add.svg";
import name_icon from "../../../../res/icons/content_white.svg";

import "./style.scss";

const AddNewPlaylist = ({handleSubmit}) => {
  const [addNewMode, toggleAddNewMode] = useState(false);

  const addButton = (
    <div className="play_list-add_btn" onClick={() => toggleAddNewMode(p => !p)}>
      <img src={add_icon} alt="play_list_add-new_icon" />
      <div className="play_list-add_btn-title">Add new playlist</div>
    </div>
  );

  const addNewForm = (
    <div className="play_list-add_form">
      <Formik
        initialValues={{
          title: "",
          playlist_type: "custom",
          items: []
        }}
        onSubmit={(values) => {
          if(values.title.length > 0){
            handleSubmit(values, () => toggleAddNewMode(false));
          }else{
            toggleAddNewMode(false);
          }
        }}
        validationSchema = {Yup.object().shape({
          title: Yup.string()
            .required("Please, enter the Playlist Name correctly")  
            .min(2, 'Minimum 2 characters')
        })}
        enableReinitialize
      >
        {(formData) => {
          const { values, setFieldValue, isValid, errors, handleSubmit } = formData;
          return (
            <Form>
              <div className="play_list-add_form-name_field">
                <img src={name_icon} className="playlist_name_field-icon"/>
                <CustomInput 
                  inputProps={{ maxLength: 64 }}
                  placeholder = "Playlist name" 
                  id="title"
                  name="title" 
                  className="playlist_name_field_input" 
                  value={values.title} />
              </div>
              <div className="play_list-add_form-save_btn" onClick = {handleSubmit}>Create</div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <div className="play_list-add">{addNewMode ? addNewForm : addButton}</div>
  );
};

export default AddNewPlaylist;
