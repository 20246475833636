import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

import Divider from '@material-ui/core/Divider';
import CardList from '../../components/PaymentMethods/Cards';
import Button from '../../components/Button';
import AddPaymentMethod from '../../components/AddPaymentMethod';

import SubscribeBackground from '../../res/icons/subscribe.svg';

import './subscribe.scss';

const Subscribe = props => {
  const {
    cardsModel: { cards, defaultCard },
    subscribeModel: { buySubscriptions, setSubscribeStatus, subscribeTypes, subscriptions },

    close
  } = props;

  const [cardId, setCardId] = useState(defaultCard?.id || (cards?.count && cards?.cards[0].id) || null);

  const onClose = () => {
    setSubscribeStatus(true);
    buySubscriptions(cardId, 'card');
    close();
  };

  return (
    <div className="subscribeBlock" style={{ backgroundImage: `url(${SubscribeBackground})` }}>
      <div className="subscribeTopBlock">
        <div className="subscribeTitle">
          <div className="blueLine" />
          <div className="pingLine" />
          <div className="subscribeTitleText">Subscribe NOW</div>
        </div>
        <div className="subscribeSubTitle">to create support groups</div>
        {(subscribeTypes?.subscriptions.length > 0 && subscriptions?.count === 0) && 
        <div className="subscribeText">
          {subscribeTypes.subscriptions[0].duration}-days free trial
          <div className="subscribeTextSecond">and than&nbsp;<span>
            $ {(subscribeTypes.subscriptions[1].price/100).toFixed(2)}</span> /mo
          </div>
        </div>}
      </div>
      <div className="subscribeGroupCardList">
        <Divider />
        {cardId && <CardList onClick={setCardId} cardId={cardId} />}
      </div>
      <div className="subscribeGroupAddPM">
        <Divider />
        <AddPaymentMethod />
      </div>
      <Button className="subscribeButton" label="SUBSCRIBE" onClick={onClose} violetGradien disabled={!cardId} />
    </div>
  )
}

export default inject('cardsModel', 'subscribeModel')(observer(Subscribe));

