import React from 'react';
import { inject, observer } from 'mobx-react';
import Button from '../../../components/Button';
import CurrentAvatar from '../../../components/CurrentAvatar';

import { redirectToAPP, returnName } from '../../../units/func';

// import img from './download.jpg';
import './Channels.scss';
const Channels = ({channels}) => {
  return (
    <div className="mm_channels">
      {channels.conversations.map(({ id, title, description, owner, image , members_count, unread_messages_count }, key) => {
        const ownerName = returnName(owner);
        return (
          <div className="mm_channels_channel">
            <div className="MuiAvatar-root MuiAvatar-circle channel_picture">
              <CurrentAvatar
                alt={ownerName}
                src={image && image.url}
                className="MuiAvatar-img channel_picture"
              />
            </div>
            <div className="mm_channels_channel_content">
              <div className="mm_channels_channel_title">{title}</div>
              <div className="mm_channels_channel_type">PUBLIC CHAT</div>
              <div className="mm_channels_channel_owner">
                <div className="MuiAvatar-root MuiAvatar-circle chanel_owner_picture">
                  <CurrentAvatar
                    alt={ownerName}
                    src={owner.image && owner.image.thumbnail.url}
                    className="MuiAvatar-img chanel_owner_picture"
                  />
                </div>
                <div className="chanel_owner_name">{ownerName}</div>
              </div>

              <div className="absolute_position">
                <div className="mm_channels_channel_desc">{description}</div>
                <div className="mm_channels_channel_info">
                  <div className="mm_channels_channel_info_item">{members_count} members</div>
                  <div className="mm_channels_channel_info_item">{unread_messages_count} messages</div>
                </div>
                <Button
                    label="Join Chat"
                    className = "join_chat"
                    violetGradien
                    smaller130
                    onClick={() => redirectToAPP(`ascend.video://channel/${id}`)} // need add id
                  />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default inject()(observer(Channels));
