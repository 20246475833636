import React, {useEffect, useState} from 'react';
import VideosGroups from '../../Videos/videos';
import { video_record, meditation, podcast, titleLiveStreams } from '../../../const/general';
import './style.scss';
import LiveStreamsIcon from "./../../../res/icons/livestreams.svg";
import VideosIcon from "./../../../res/icons/videos.svg";
import MeditationsIcon from "./../../../res/icons/meditations.svg";
import PodcastsIcon from "./../../../res/icons/podcasts.svg";
import {inject, observer} from "mobx-react";

const Content = (props) => {
  const { contentGroups,
    mediaGroups,
    userId = false,
    currentGroupData,
    groupsModel: { state: { filters }},
    setCurrentGroupId} = props;
  const videosGroupsData = (type) => mediaGroups.filter((group) => group.record_type === type);
  const [livestream, setLivestream] = useState(true);
  const [videos, setVideos] = useState(true);
  const [meditations, setMeditations] = useState(true);
  const [podcasts, setPodcasts] = useState(true);
  const [info, setInfo] = useState([]);

  const options = [
    { value: 'Livestream', label: 'Livestreams', img: LiveStreamsIcon, active: livestream},
    { value: 'Videos', label: 'Videos', img:  VideosIcon, active: videos},
    { value: 'Meditations', label: 'Meditations', img: MeditationsIcon, active: meditations},
    { value: 'Podcasts', label: 'Podcasts', img: PodcastsIcon, active: podcasts },
  ];

  useEffect(() => {
    const filterOptions = options.filter(item => item.active);
    const activeOptions = filterOptions.map(item => item.value);

    if(activeOptions.length === 4) {
      setInfo([...contentGroups, ...mediaGroups])
    }

    if(activeOptions.length === 3) {
      const str = activeOptions.join("")

      if(str === "LivestreamVideosMeditations") {
        setInfo([...contentGroups, ...videosGroupsData(video_record), ...videosGroupsData(meditation)]);
      }

      if(str === "LivestreamMeditationsPodcasts") {
        setInfo([...contentGroups, ...videosGroupsData(meditation), ...videosGroupsData(podcast)]);
      }

      if(str === "VideosMeditationsPodcasts") {
        setInfo([...videosGroupsData(video_record), ...videosGroupsData(meditation), ...videosGroupsData(podcast)]);
      }
    }

    if(activeOptions.length === 2) {
      const str = activeOptions.join("");

      if(str === "LivestreamVideos") {
        setInfo([...contentGroups, ...videosGroupsData(video_record)]);
      }

      if(str === "LivestreamMeditations") {
        setInfo([...contentGroups, ...videosGroupsData(meditation)]);
      }

      if(str === "LivestreamPodcasts") {
        setInfo([...contentGroups, ...videosGroupsData(podcast)]);
      }

      if(str === "VideosMeditations") {
        setInfo([...videosGroupsData(video_record), ...videosGroupsData(meditation)]);
      }

      if(str === "VideosPodcasts") {
        setInfo([...videosGroupsData(video_record), ...videosGroupsData(podcast)]);
      }

      if(str === "MeditationsPodcasts") {
        setInfo([...videosGroupsData(meditation), ...videosGroupsData(podcast)]);
      }
    }

    if(activeOptions.length === 1) {
      if(activeOptions[0] === "Livestream") {
        setInfo([...contentGroups]);
      }

      if(activeOptions[0] === "Videos") {
        setInfo([...videosGroupsData(video_record)]);
      }

      if(activeOptions[0] === "Meditations") {
        setInfo([...videosGroupsData(meditation)]);
      }

      if(activeOptions[0] === "Podcasts") {
        setInfo([...videosGroupsData(podcast)]);
      }
    }

    if(activeOptions.length === 0) {
      setInfo([]);
    }
  }, [livestream, videos, meditations, podcasts, mediaGroups, contentGroups])

  const changeActiveItem = (event) => {
    if(event.currentTarget.innerHTML === 'Livestream') {
      setLivestream(prevState => !prevState);
    }

    if(event.currentTarget.innerHTML === 'Videos') {
      setVideos(prevState => !prevState);
    }

    if(event.currentTarget.innerHTML === 'Meditations') {
      setMeditations(prevState => !prevState);
    }

    if(event.currentTarget.innerHTML === 'Podcasts') {
      setPodcasts(prevState => !prevState);
    }
  }

  const [contentGroupsArr, setContentGroupsArr] = useState(info);

  useEffect(() => {
    const arr = [];
    if(info.length > 0) {
      info.forEach(item => {
        item.communities.forEach(el => {
          if (filters.communities.includes(el.id)) {
            arr.push(item);
          }
        });
      })
      const unique = [...new Set(arr)];
      setContentGroupsArr(unique)
    } else {
      setContentGroupsArr([]);
    }
  }, [filters.communities, info]);

  return (
        <div className="dailyBlock">
          <div className="titleBlock workshops lsrecordsList">
            <div className="categoryMenu">
              {options.map((item, index) =>
                  <div className={item.active ? "categoryMenuItem categoryMenuItemActive": 'categoryMenuItem'}
                       key={index+item.value}>
                    <img src={item.img} alt={item.value} />
                    <div onClick={(event) => changeActiveItem(event)}>{item.value}</div>
                  </div>
              )}
            </div>
            {contentGroupsArr?.length ?
                <VideosGroups
                    data={contentGroupsArr}
                    key="videos"
                    restore={true}
                    userId={userId}
                    setCurrentGroupId={setCurrentGroupId}
                    currentGroupData={currentGroupData}
                />
                :
                <div className="emptyBlock">
                  <div className="empty">
                    <div className="title">{`Currently no all are available`}</div>
                  </div>
                </div>
            }
      </div>
        </div>
  )};

export default inject('groupsModel')(observer(Content));
