import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Field } from 'formik';

import CheckedEmptyIcon from '../../res/iconComp/CheckboxEmptey';
import CheckedCheckedIcon from '../../res/iconComp/CheckboxChecked';

const CheckboxEditTiers = props => {
  const { name = "test", setFieldValue, className, placeholder, isDisabled, alreadySelected} = props;


  const [prevValue, setPrevValue] = useState(Object.values(alreadySelected).includes(name)) 

  useEffect(()=>{
    setFieldValue([name], prevValue)
  },[])
  return (
    <Field name={name}>
      {({
          field: { name, value, onChange },
          form: { touched, errors, isValid },
          meta,
        }) => {
          return (
            <FormControl error={meta.touched && !!errors[name]} className={className}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={prevValue}
                    onChange={() => {setFieldValue([name],  !value); setPrevValue(!value)}}
                    name={name}
                    disabled={isDisabled}
                    color="default"
                    icon={<CheckedEmptyIcon />}
                    checkedIcon={<CheckedCheckedIcon />}
                  />}
                label={placeholder}
              />
              <FormHelperText>{meta.touched && errors[name]}</FormHelperText>
            </FormControl>
          )
        }
      }
    </Field>
  );
}

export default CheckboxEditTiers;
