import {action, decorate, observable} from "mobx";

export class HelpersController {
  constructor() {
    this.redirectPathCb = false
  }

  setRedirectPathCb = func => this.redirectPathCb = func;

  callRedirect = (user, communityList) => {
    if (this.redirectPathCb) {
      this.redirectPathCb(user, communityList);
      this.setRedirectPathCb(false)
    }
  }

}

decorate(HelpersController, {
  redirectPathCb: observable,
  callRedirect: action,
  setRedirectPathCb: action
})

export const HelpersControllerModel = new HelpersController()