import React, { memo } from 'react';

const AudioLabel = props => {
  // const { color = 'white' } = props;

  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8681 5.77021L13.7872 0.612766C13.4043 0.229787 12.8936 0 12.3319 0H2.04255C0.919149 0 0 0.919149 0 2.04255V21.9574C0 23.0809 0.919149 24 2.04255 24H17.4128C18.5362 24 19.4553 23.0809 19.4553 21.9574V7.2C19.4553 6.66383 19.2511 6.15319 18.8681 5.77021ZM14.3489 6.12766C13.7872 6.12766 13.3277 5.66809 13.3277 5.10638V1.58298L17.7957 6.12766H14.3489Z" fill="#7E7D8F"/>
      <path d="M15.3596 14.9925C15.3596 16.5488 14.1032 17.8052 12.5469 17.8052V12.1797C14.1032 12.1797 15.3596 13.4362 15.3596 14.9925Z" fill="#F4F4F7"/>
      <path d="M11.4612 9.06559C11.2756 8.96564 11.0471 8.97992 10.8758 9.10842L6.7209 12.0354H4.65059C4.33648 12.0354 4.09375 12.2924 4.09375 12.5923V17.4182C4.09375 17.7323 4.35075 17.9751 4.65059 17.9751H6.74946L10.9044 20.9021C11.1614 21.0734 11.504 21.0163 11.6897 20.7593C11.761 20.6593 11.7896 20.5594 11.7896 20.4452V9.56532C11.7753 9.36543 11.6468 9.16554 11.4612 9.06559Z" fill="#F4F4F7"/>
    </svg>
  )
}

export default memo(AudioLabel);
