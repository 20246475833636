import React, {useState, useEffect, useRef} from 'react';
import Button from '../../../components/Button';
import EventItem from '../../../components/EventItem';
import HeaderMaster from './headerMaster';
import HeaderSimple from './headerSimple';
import GroupNotFound from '../../../components/GroupNotFoundDialog';
import { home, livestreams, collaborations, workshops } from '../../../const/urlKeys'; 
import { getLabel } from '../../../units/func';
import moment from "moment";

import './daily.scss';
import {inject, observer} from "mobx-react";
import ContentDetailsPopup from "../../../components/ContentDetailsPopup";
import Dialog from "../../../components/Dialog";
import AttendGroup from "../../AttendGroup";
import {groupType} from "../../../const/general";
import {useHistory} from "react-router";
import WebJoinLiveStreamDialog from "../../../components/WebJoinLiveStreamDialog";

const Daily = props => {
  const {data, match, role,
      showProfile = null,
      showMoreIncrement = 10,
      initialMoreCount = 10,
      subscribePackageModel:{subscriptionPackagesList},
      userModel: {community},
      masterMindsModel: {masterMindProfile},
      groupsModel:{
      state: { filters },
      openGroupPopupAfterCreate, clearOpenGroupPopupAfterCreate, setGroupNotFound, lastGroup, setLastGroup, groups
  }} = props; // community
  const history = useHistory()
  const [isDetailsOpen, setDetailsOpen] = useState(false)
  const [isAttendOpen, setAttendOpen] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [detailsPopupType, setDetailsPopupType] = useState(false)
  const [availableBySubscriptions, setAvailableBySubscriptions] = useState(false)
  const [postWidth, setPostWidth] = useState("auto")
  const [postHeight, setPostHeight] = useState("auto")
  const [firstRender, setFirstrender] = useState(true)
  const [masterMindGroups, setMasterMindGroups] = useState(null);
  const query = new URLSearchParams(window.location.search);

  const [defualtOpen, setDefualtOpen] = useState((match && match.params && match?.params?.id) || query?.get('id') || null)

  const postListRef = useRef(null)


  const path = showProfile || (match && match.path);
  const isDaily = !(path === livestreams || path === collaborations || path === workshops)

  useEffect(_ => {
      if (isDetailsOpen){
          const path = getItemPath(isDetailsOpen.id)
          path && history.push(path.forSet)
      }
  }, [isDetailsOpen])

  useEffect(_ => {
      setDetailsOpen(lastGroup)
  },[lastGroup])

  useEffect(_ => {
      if(groups?.groups?.length && masterMindProfile?.id) {
          setMasterMindGroups(groups?.groups?.filter(group => group.owner.id === masterMindProfile.id && group.group_type === "master_mind"))
      }
  },[groups])

  /*useEffect(() => {
    if(!isDaily){
      if(postListRef?.current?.children && postListRef.current.children.length){
        postListRef.current.children[0].clientWidth &&
        postListRef.current.children[0].clientWidth !== 250 && 
        setPostWidth(postListRef.current.children[0].clientWidth);
      }
    }else{
      if(postListRef?.current?.children[0] && Array.from(postListRef?.current?.children[0].children).length){
        let blockHeights = Array.from(postListRef?.current?.children[0].children).map(item => item.clientHeight);
        setPostHeight(Math.max(...blockHeights) + 30)
      }
    }
  }, [postListRef,data])*/

  const calcHeight = () =>{
    let blockHeights = Array.from(postListRef?.current?.children[0].children).map(item => item.clientHeight);
    let maxHeight = Math.max(...blockHeights)
    setPostHeight(prev => prev > maxHeight ? prev : Number(maxHeight + 40))
  }

  useEffect(() => {
    const handleResize = () => {
      if(!isDaily){
        if(postListRef?.current?.children && postListRef.current.children.length){
          postListRef.current.children[0].clientWidth &&
          postListRef.current.children[0].clientWidth !== 250 && 
          setPostWidth(postListRef.current.children[0].clientWidth);
        }
      }else{
        if(postListRef?.current?.children[0] && Array.from(postListRef?.current?.children[0].children).length){
          setTimeout(() => calcHeight(), 100)
          let blockWidth = postListRef?.current.clientWidth - (firstRender ? 37 : 20);
          let windowWidth = window.innerWidth
          let postWidthresult = 0
          if(windowWidth <= 720){
            postWidthresult = blockWidth
          }else if(windowWidth <= 1200){
            postWidthresult = (blockWidth - 60)/2
          }else if(windowWidth <= 1600){
            postWidthresult = (blockWidth - 100)/3
          }else{
            postWidthresult = (blockWidth - 140)/4
          }
          setPostWidth(postWidthresult)

          setFirstrender(false)
        }
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  useEffect(() => {
    const type = isAttendOpen.group_type || isAttendOpen.record_type
    let editedType = type
    if(type === "recurring_webinar"){
      editedType = "webinar"
    }else if(type === "recurring"){
      editedType = "master_mind"
    }
    let subsPacks = subscriptionPackagesList.subscription_packages
    let availableBySubscriptionsDraft =  subsPacks.some((pack) => {
      return pack.current && 
        pack.tiers.includes(editedType) && 
        (pack.communities.some(comm => comm == community)) && 
        (isAttendOpen?.subscription_packages_ids && isAttendOpen.subscription_packages_ids.length) && (
          (isAttendOpen?.communities ? isAttendOpen.communities.some(comm => pack.communities.includes(comm.id + "")):false) ||
          (isAttendOpen?.distribution_group ? isAttendOpen.distribution_group.communities.some(comm => pack.communities.includes(comm.id + "")): false)
        )
    })
    setAvailableBySubscriptions(availableBySubscriptionsDraft)
  }, [isAttendOpen])


  const attendDialog = (item) => {
      return <Dialog
        openButton={null}
        className="detailGroup"
        closeColor="#43425D"
        onClose={_ => {
            setAttendOpen(false)
        }}
        defaultOpen={isAttendOpen}
      >
          <AttendGroup item={item}/>
      </Dialog>
  };

  const initMore = (function () {
      let index = data.findIndex(item =>  item.id == defualtOpen)
      return (!!~index && index && Math.ceil(index/10)*10) || initialMoreCount
  })()
  
  const [more, setMore] = useState(isDaily ? 10000 : initMore);
  more < initMore && setMore(initMore)

  const defualtOpenChange = () => {
      setDefualtOpen(null);
  }
  const  getItemPath = id => {
      return match?.params && !Object.keys(match?.params).length && {
          forSet: `${match.url}?id=${id}`,
          forBack: match.url
      }
  }

  if(openGroupPopupAfterCreate.can && openGroupPopupAfterCreate.id && !defualtOpen){
      let id = openGroupPopupAfterCreate.id
      clearOpenGroupPopupAfterCreate()
      setDefualtOpen(id)
  }

  const dailyBlockDisplay = (insidecontent) => {
    if(isDaily){
      return <div className='listBlockUpcomingWrapper'>
        {insidecontent}
      </div>
    }else{
      return insidecontent
    }
  }

  const newData = data.filter(item => {
    const meetings_records = item.meetings_records;
    const date2 = new Date();
    const date1 = new Date(item.created_at);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);

    if(!meetings_records){
      return true;
    } else if(item.status !== "ended") {
      return true;
    }else if(item.status === "ended" && diffInDays <= 7) {
      return !!meetings_records.find(el => !!el.video_file);
    }
  }).sort(compareStartDate);

    function compareStartDate(a, b) {
      return moment(a.start_time).unix() - moment(b.start_time).unix()
    }

    const [livestreamsArr, setLivestreamsArr] = useState([]);
    useEffect(() => {
        const arr = [];
        if(!!newData && newData.length > 0) {
            newData.forEach(item => {
                item.communities.forEach(el => {
                    if (filters.communities.includes(el.id)) {
                        arr.push(item);
                    }
                });
            })
            const unique = [...new Set(arr)];
            setLivestreamsArr(unique)
        } else {
            setLivestreamsArr([]);
        }
    }, [filters.communities, data]);

    const [groupsArr, setGroupsArr] = useState([]);
    useEffect(() => {
        const arr = [];
        if(!!masterMindGroups && masterMindGroups.length > 0) {
            masterMindGroups.forEach(item => {
                item.communities.forEach(el => {
                    if (filters.communities.includes(el.id)) {
                        arr.push(item);
                    }
                });
            })
            const unique = [...new Set(arr)];
            setGroupsArr(unique)
        } else {
            setGroupsArr([]);
        }
    }, [filters.communities]);

  return (
  <div className="dailyBlock">
    {(path === livestreams || path === collaborations || path === workshops) ? (
      <>{/*<HeaderMaster
        path={path}
        short={!!showProfile}
    />*/}</>
    ) : (
      <HeaderSimple label="Upcoming Groups" />
    )}
    <div 
      className={`${!isDaily ? "listBlock" : "listBlockUpcoming"} 
                  ${(!!livestreamsArr?.length || (path === collaborations && masterMindGroups?.length)) ? "" : "emptyListBlock"}`}
      ref = {postListRef} 
      style = {{height: `${isDaily ? (postHeight < 550 ? 550 : postHeight): ""}px`}}
    >
      {!!livestreamsArr?.length ?
        dailyBlockDisplay(livestreamsArr.map((item, index) => {
           if (index < more || defualtOpen == item.id) {
                return (
              <EventItem
                index={index}
                item={item}
                key={item.id}
                role={role}
                defualtOpen={defualtOpen}
                itemPath={getItemPath(item.id)}
                setGroupNotFound={setGroupNotFound}
                postWidth = {isDaily ? postWidth : null}
                postHeight = {postHeight < 550 ? 550 : postHeight}
                handleOpenDialog={({type} = {})=>{
                    setDetailsPopupType(type)
                    setDetailsOpen(item)
                }}
              />
            )
          }
        })
      ) :
          groupsArr?.length && path === collaborations ?
              dailyBlockDisplay(groupsArr.map((item, index) => {
                  if (index < more || defualtOpen == item.id) {
                      return (
                          <EventItem
                              index={index}
                              item={item}
                              key={item.id}
                              role={role}
                              defualtOpen={defualtOpen}
                              itemPath={getItemPath(item.id)}
                              setGroupNotFound={setGroupNotFound}
                              postWidth = {isDaily ? postWidth : null}
                              postHeight = {postHeight < 550 ? 550 : postHeight}
                              handleOpenDialog={({type} = {})=>{
                                  setDetailsPopupType(type)
                                  setDetailsOpen(item)
                              }}
                          />)
                      }
                  })
              ) :
        <div className="emptyBlock">
          <div className="empty">
            <div className="title">Currently no {path !== home ? getLabel(path) : 'Groups'} are available</div>
          </div>
        </div>
      }
    </div>
    {!isDaily && livestreamsArr && livestreamsArr.length > more && <div className="moreButtonBlock">
      <Button label="Show More" big60 grey width100 className="moreButton" onClick={() => setMore(more + showMoreIncrement)} />
    </div>}
      {isDetailsOpen && (<ContentDetailsPopup
        item={isDetailsOpen}
        handleClose={data => {
            setLastGroup(null)
            setDetailsOpen(data)
            defualtOpenChange()
        }}
        setAttendOpen={setAttendOpen}
        reportState={[openReport, setOpenReport]}
        renderType={groupType}
        itemPath={getItemPath(isDetailsOpen.id)}
        detailsPopupType={detailsPopupType}
      />)}
      {(isAttendOpen && isAttendOpen.price > 0 && !isAttendOpen.subscribed && !availableBySubscriptions) ? (attendDialog(isAttendOpen)) : null}
    <GroupNotFound />
    <WebJoinLiveStreamDialog />
  </div>);
  }

export default inject( 'groupsModel', 'subscribePackageModel', 'userModel', 'masterMindsModel')(observer(Daily));
