export const home = '/';
export const subscribe = '/subscribe';
export const notifications = '/notifications'
export const calendar = '/calendar';
export const terms_conditions = '/terms_conditions';
export const privacy_policy = '/privacy_policy';
export const cookie_policy = '/cookie_policy';
export const contact_support = '/contact_support';
export const register = '/register';
export const forgot_password = '/forgot_password';
export const twitter = '/twitter';
export const profile = '/profile';
export const groups_create = '/create_groups';
export const daily = '/daily';
export const livestreams = '/livestreams';
export const collaborations = '/all_groups';
export const workshops = '/coaching';
export const groupByLink = '/group';
export const search = '/search'
export const user = '/user';
export const masterMindProfile = '/mastermind/profile';
export const stripeVerify = '/stripe_verify';
export const records = '/content/livestream';
export const createVideoContent = '/upload/video';
export const videos = '/content/videos';
export const meditations = '/content/meditations';
export const podcasts = '/content/podcasts';
export const createMediaContent = '/upload/media';
export const createAudioContent = '/upload/audio';
export const allCreators = '/all_creators'
export const channel = '/channel'
export const subscriptions = '/subscriptions';
export const chooseCommunity = '/choose_community';
export const externalChooseCommunity = '/community'
export const playlists = '/content/playlists';
export const myOrganizationPage = '/my_organization';
export const otherPage = '/other_page';
export const MMGetStarted = '/get_started'
