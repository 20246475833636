import React , {useState, useRef, useEffect, useReducer} from 'react';
import { Field } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './select.scss';

const CustomSelectMultipleCategory = props => {
  const { defValue, name, data, className = '', label, customChange, disabled = false, isShrink, style=null} = props;

  return (
      <Field name={name}>
        {({
            field: { name, value, onChange, onBlur },
            form: { touched, errors, isValid },
            meta,
          }) => {
          return (
              <FormControl error={meta.touched && !!errors[name]} className={className} style={style}>
                <div className="categorySelect_placeholder">{label}</div>
                <Select
                    labelId="select-label"
                    id="select"
                    value={value || defValue.id || defValue}
                    onChange={e => {customChange(e.target.value)}}
                    onBlur={onBlur}
                    disabled={disabled}
                >
                  {data.map(item => (
                      <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{meta.touched && errors[name]}</FormHelperText>
              </FormControl>
          );
        }
        }
      </Field>
  )
};

export default CustomSelectMultipleCategory;
