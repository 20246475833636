import React, { useState, useEffect } from "react";
import CustomInput from "../../Input/customInput";
import { Formik, Form } from "formik";
import PlaylistEmpty from "../PlaylistEmpty";
import DragAndDrop from "../../DragAndDrop";
import * as Yup from "yup";
import RecomendationItem from "../../ContentRecomendations/RecomendationItem";
import ViewPlaylistIcon from "../../../res/icons/delete_playlist.svg";
import name_icon from "../../../res/icons/content_black.svg";
import darg_n_drop_icon from "../../../res/icons/dragNdrop.svg";
import Dialog from "../../Dialog";

import "./styles.scss";

const EditPlaylistDialogContent = (props) => {
  const {
    close,
    playlist,
    handleSubmit,
    handleDeleteItem,
  } = props;

  const [showDone, tooggleShowDone] = useState(true);
  const [playlistItems, updatePlaylistItems] = useState(playlist.items ? playlist.items : []);

  useEffect(() => {
    updatePlaylistItems(playlist.items ? playlist.items : []);
  }, [playlist])

  const handleDragEnd = (list) => {
    updatePlaylistItems(list);
    tooggleShowDone(true);
  };

  const handleDragStart = (list) => {
    tooggleShowDone(false);
  };

  return (
    <div className="edit_playlist_dialog_content">
      <div className="edit_playlist_dialog_content_title">Edit Playlist</div>
      <Formik
        initialValues={{...playlist, title: playlist?.title, items: playlistItems}}
        onSubmit={(values) => {
          handleSubmit(values);
          close();
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
          .required("Please, enter the Playlist Name correctly")
          .min(2, 'Minimum 2 characters')
        })}
        enableReinitialize
      >
        {(formData) => {
          const { handleSubmit, values } = formData;
          return (
            <Form>
              <div className="play_list-add_form-name_field">
                <img src={name_icon} className="playlist_name_field-icon" />
                <CustomInput
                  inputProps={{ maxLength: 64 }}
                  disabled={playlist.playlist_type !== "custom"}
                  placeholder="Playlist name"
                  id="title"
                  name="title"
                  className="playlist_name_field_input"
                  value={values.title}
                />
              </div>

              <div className="view_playlist_dialog-playlist_items">
                {playlistItems.length < 1 ? (
                  <PlaylistEmpty />
                ) : (
                  <DragAndDrop
                    items={playlistItems}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                  >
                    {(item) => (
                      <div className="view_playlist_dialog-playlist_item_wrapper">
                        <div className="drag_playlist_item_dialog_btn">
                          <img src={darg_n_drop_icon} alt="darg_n_drop_icon" />
                        </div>
                        <RecomendationItem
                          className="playlist_item"
                          handleClick={() => console.log(item)}
                          recomendation={item}
                        />
                        <div
                          className={"delete_playlist_item_dialog_btn"}
                          onClick={() => handleDeleteItem(item.id)}
                        >
                          <img src={ViewPlaylistIcon} alt="ViewPlaylistIcon" />
                        </div>
                      </div>
                    )}
                  </DragAndDrop>
                )}
              </div>

              <div className={`play_list-edit_form-save_btn ${ showDone ? "visible" : ""}`} onClick={handleSubmit}>
                Done
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const EditPlaylist = (props) => {
  return (
    <div className="playlist_list_item_edit">
      <Dialog
        openButton={props.children}
        className="edit_playlist_dialog"
        closeColor="#7E7D8F"
      >
        <EditPlaylistDialogContent {...props} />
      </Dialog>
    </div>
  );
};

export default EditPlaylist;
