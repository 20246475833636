import { observable, decorate, action } from 'mobx';
import axios from 'axios';
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { baseAPI } from '../const/settings';

import { userModel } from './user';
import { fileUploadingModel } from './fileUploading';

import { obtainOauthRequestToken } from '../units/services/oauth1';
import {LoaderControllerModel} from "./loader";

export class AuthModel {
  constructor() {
    this.session_token = {};
    this.loading = true;
    this.loginErrorNotification = '';
    this.checkAuthToken();
  }

  setLoginErrorNotification = notification => this.loginErrorNotification = notification;

  changeLoading = val => {
    this.loading = val;
  };

  setSesionToken = token => {
    this.session_token = token;
    localStorage.setItem('session_token', token);
  }

  checkAuthToken = () => {
    this.session_token = localStorage.getItem('session_token') || '';
    if (!!this.session_token && this.session_token !== "undefined") {
      userModel.getCurentUser(this.session_token);
    } else {
      this.changeLoading(false);
    }

  }

  login = (phone_number, password, setErrors) => {
    const body = {
      phone_number,
      password
    }

    const setSesionToken = this.setSesionToken;

    axios.post(`${baseAPI}sessions`, body)
      .then(function (response) {
        setSesionToken(response.data.session_token);
        userModel.updateLocalUser(response.data.current_user);
        userModel.setAuthUser(response.data.current_user)
      })
      .catch(function (error) {
        console.log('error', error);
        if (error.response && error?.response?.status !== 500 && error?.response?.data) {
          setErrors("phone", error.response.data.errors[0]);
          setErrors("password", error.response.data.errors[0]);
        } else {
          setErrors("phone", 'Somesing wrong');
          setErrors("password", 'Somesing wrong');
        }
      })
  }

  socialLogin = (body) => {
    const setSesionToken = this.setSesionToken;

    axios.post(`${baseAPI}sessions/social_login`, body)
      .then(function (response) {
        setSesionToken(response.data.session_token);
        userModel.updateLocalUser(response.data.current_user);
        userModel.setAuthUser(response.data.current_user)

      })
      .catch(function (error) {
        console.log('error.response.data', error.response);
      })
  }

  twitterLogin = async () => {
    const setLoginErrorNotification = this.setLoginErrorNotification;
    const socialLogin = this.socialLogin;
    const provider = new TwitterAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          const credential = TwitterAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const secret = credential.secret;
          // The signed-in user info.
          const user = result.user;

          socialLogin({
            social_type: 'twitter',
            access_token: token,
            access_token_secret: secret,
            full_name: user.displayName,
          })
        }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;

      if(error) {
        setLoginErrorNotification('Access Denied!')
      }
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = TwitterAuthProvider.credentialFromError(error);
      // ...
    });
  }

  twitter = url => {
    const socialLogin = this.socialLogin;
    axios({
      method: 'post',
      url: `https://cors-anywhere.herokuapp.com/https://api.twitter.com/oauth/access_token${url}`,
      data: {
        "consumer_key": process.env.REACT_APP_CONSUMER_KEY,
        "CONSUMER_SECRET": process.env.REACT_APP_CONSUMER_SECRET
      },
    })
      .then(data => {
        const access_token = data.data.split('&').filter(str => str.includes('oauth_token'))[0].split('=')[1];
        const access_token_secret = data.data.split('&').filter(str => str.includes('oauth_token_secret'))[0].split('=')[1];
        socialLogin({ social_type: "twitter", access_token, access_token_secret })
      })
      .catch(error => console.log('error, ', error))
  }

  logout = () => {
    if(fileUploadingModel.isUploading){
      if(window.confirm('The download of files will be interrupted. Do you want to log out?')){
        fileUploadingModel.abortUploading();
      }else{
        return;
      }
    }

    const body = { session_token: this.session_token };
    localStorage.removeItem('session_token');
    localStorage.removeItem('user_community');
    this.session_token = null;
    userModel.updateLocalUser(null);
    userModel.setAuthUser(null)
    LoaderControllerModel.setIsGlobalLoaderNeed(false)
    return axios.post(`${baseAPI}logout`, body)
      .then(function (response) {
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
      .then(function () {
        // always executed
      });
  }
}

decorate(AuthModel, {
  codes: observable,
  session_token: observable,
  loading: observable,
  login: action,
  loginErrorNotification: observable,
  setLoginErrorNotification: action,
})

export const authModel = new AuthModel();
