import { observable, decorate } from 'mobx';
import axios from 'axios';
import { authModel } from './auth';
import { baseAPI } from '../const/settings';
import {getToken} from "../units/func";

const notifTypes = {
  meeting_started: true,
  new_groups: true,
  invite_to_a_meeting: true,
  removed_from_group: true,
  ten_minutes_to_start_meeting: true,
  group_cancelled: true,
  start_time_changed: true,
  invite_to_a_webinar: true,
  invite_to_a_channel: true,
  post_commented: false,
  post_liked: false,
  new_records: false,
  meeting_cancelled: true,
  meeting_resheduled: true,
  community_access_request: true,
  subscribed_to_package: true,
  unsubscribed_from_package : true
}
const renderNotifTypeRequest = Object.keys(notifTypes).reduce( (str, key, index) => {
  return notifTypes[key]? str + `${index && str.length? ",": ''}${key}` : str+''
}, '')

const getHeaders = (token) => ({
  "accept": "application/json, text/plain, */*",
  "session-token": token || authModel.session_token
})

export class NotificationsModel {
  constructor() {
    this.notifications = {
      notifications: [],
      count: 0,
      unreadCount: 0,
    };
  }

  setNotifications = data => {
    this.notifications = data;
    this.unreadCount = 0;
  }

  setUnreadCount = count => this.unreadCount = count;

  getUnreadNotifications = () => {
    const setUnreadCount = this.setUnreadCount;
    const headers = {
      "headers": getHeaders(getToken())};

    axios.get(`${baseAPI}users/unread_notifications`, headers)
      .then(function (response) {
        setUnreadCount(response.data?.unread_notifications_count);
      })
      .catch(function (error) {
        console.log(error.response);
        setUnreadCount(0);
      })
  }

  getNotifications = (per_page = 10) => {
    const setNotifications = data => this.setNotifications(data);
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};

    axios.get(`${baseAPI}notifications?[per_page]=${per_page}&[notification_type]=${renderNotifTypeRequest}`, headers)
      .then(function (response) {
        setNotifications(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      })
  }

  deleteNotification = (id) => {
    const getNotifications = this.getNotifications;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};

    axios.delete(`${baseAPI}notifications/${id}`, headers)
      .then(function () {
        getNotifications()
      })
      .catch(function (error) {
        console.log(error.response);
      })
  }
}

decorate(NotificationsModel, {
    notifications: observable,
    unreadCount: observable,
})

export const notificationsModel = new NotificationsModel();
