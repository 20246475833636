import React, {useEffect, useRef} from 'react';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { Field } from 'formik';

const CustomInput = props => {
  const { name, className = '', showError = true, placeholder, inputPlaceholder, customPlaceholder, disabled = false, type = "text",  selectedCommunities, editGroup, ...rest } = props
  const input = useRef();
  const isExist = (value) => {
    let result
    if(name === "themes"){
      result = value ? value[rest.themeKey] !== '' : true;
    } else {
      result = (value !== undefined && value !== '' && value !== null && value !== false)
    }
    return result
  }

  useEffect(() => {
    if(rest.id === 'themes' && !rest.value){
      input.current.value = null;
    }
  }, [rest])

  return (
    <Field name={name}>
      {({
          field: { name, value, onChange, onBlur },
          form: { errors },
          meta,
        }) => {
          var editedValue = value;
          if(name === "tags" && value){
            let tagTitles = [];
            value.map((tag, id) => {
              if(editGroup){
                if(id === 0){
                  tagTitles.push((tag.title));
                }else{
                  tagTitles.push((" " + tag.title));
                }               
              }else{
                if(selectedCommunities.split(",").some(id => (tag?.community_id ? tag?.community_id === parseInt(id) : parseInt(tag?.communities[0])))){
                  if(id === 0){
                    tagTitles.push((tag.title));
                  } else {
                    tagTitles.push((" " + tag.title));
                  }
                }
              }
            })
            let tagValueFinal = [];
            let genString = ""
            tagTitles.map((title, id)=>{
              if(id){
                let tempGenString = genString + title;
                if(tempGenString.length > 27){
                  let countOfNotdisplayedTags = tagTitles.length - id
                  tagValueFinal.push(" +" + countOfNotdisplayedTags);
                  tagValueFinal.push(0);
                }else{
                  tagValueFinal.push(title)
                }
              }else{
                tagValueFinal.push(title)
              }
              genString += title
            })
            editedValue = [];
            let key = 0;
            while(key < tagValueFinal.length && tagValueFinal[key] !== 0){
              editedValue.push(tagValueFinal[key]);
              key += 1;
            }
          }

          return(
            <FormControl error={meta.touched && !!errors[name] && showError} className={className}>
              {(placeholder && !customPlaceholder) &&
                  <InputLabel htmlFor="custom-input" shrink={isExist(value)}>{placeholder}</InputLabel>
              }
              {customPlaceholder &&
                  <div className="customScheduleMeeting">{customPlaceholder}</div>
              }
              <Input
                  inputRef={input}
                id="custom-input"
                type={type}
                value={editedValue}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={inputPlaceholder || rest.placeholder}
                name={name}
                disabled={disabled}
                {...rest}
              />
              <FormHelperText>{showError && meta.touched && errors[name]}</FormHelperText>
            </FormControl>
          )
        }
      }
    </Field>
  );
};

export default CustomInput;
