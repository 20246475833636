import React, { memo } from 'react';

const PromoCodeIcon = props => {
  // const { color = 'white' } = props;

  return (
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.2258 5.03223H12.4194V5.41932C12.4194 5.6333 12.2462 5.80641 12.0323 5.80641C11.8183 5.80641 11.6452 5.63326 11.6452 5.41932V5.03223H7.77419C7.34664 5.03223 7 5.37882 7 5.80641V9.21409C7 9.40923 7.09595 9.59466 7.26189 9.69732C8.03341 10.1745 8.54838 11.0261 8.54838 12C8.54838 12.9738 8.03341 13.8254 7.26189 14.3026C7.09595 14.4053 7 14.5907 7 14.7858V18.1935C7 18.6211 7.34664 18.9677 7.77419 18.9677H11.6452V18.5806C11.6452 18.3666 11.8183 18.1935 12.0323 18.1935C12.2463 18.1935 12.4194 18.3667 12.4194 18.5806V18.9677H30.2258C30.6534 18.9677 31 18.6211 31 18.1935V5.80641C31 5.37882 30.6534 5.03223 30.2258 5.03223ZM12.4194 17.0322C12.4194 17.2462 12.2462 17.4193 12.0323 17.4193C11.8183 17.4193 11.6452 17.2462 11.6452 17.0322V16.258C11.6452 16.0441 11.8183 15.8709 12.0323 15.8709C12.2463 15.8709 12.4194 16.0441 12.4194 16.258V17.0322ZM12.4194 14.7096C12.4194 14.9236 12.2462 15.0967 12.0323 15.0967C11.8183 15.0967 11.6452 14.9236 11.6452 14.7096V13.9354C11.6452 13.7214 11.8183 13.5483 12.0323 13.5483C12.2463 13.5483 12.4194 13.7215 12.4194 13.9354V14.7096ZM12.4194 12.3871C12.4194 12.601 12.2462 12.7741 12.0323 12.7741C11.8183 12.7741 11.6452 12.601 11.6452 12.3871V11.6129C11.6452 11.3989 11.8183 11.2258 12.0323 11.2258C12.2463 11.2258 12.4194 11.3989 12.4194 11.6129V12.3871ZM12.4194 10.0645C12.4194 10.2785 12.2462 10.4516 12.0323 10.4516C11.8183 10.4516 11.6452 10.2784 11.6452 10.0645V9.2903C11.6452 9.07632 11.8183 8.90321 12.0323 8.90321C12.2463 8.90321 12.4194 9.07637 12.4194 9.2903V10.0645ZM12.4194 7.74188C12.4194 7.95587 12.2462 8.12898 12.0323 8.12898C11.8183 8.12898 11.6452 7.95582 11.6452 7.74188V6.9677C11.6452 6.75371 11.8183 6.5806 12.0323 6.5806C12.2463 6.5806 12.4194 6.75376 12.4194 6.9677V7.74188Z" fill="#43425D" />
      <path d="M29.8385 18.1935H13.5805C13.3667 18.1935 13.1934 18.0202 13.1934 17.8064V6.19349C13.1934 5.97969 13.3667 5.8064 13.5805 5.8064H29.8385C30.0523 5.8064 30.2256 5.97969 30.2256 6.19349V17.8064C30.2256 18.0202 30.0523 18.1935 29.8385 18.1935Z" fill="#FFCA00" />
      <path d="M21.3894 14.6751L19.3829 15.9348C18.9407 16.2123 18.3858 15.8092 18.5132 15.3029L19.0912 13.0054L17.2732 11.4864C16.8725 11.1516 17.0844 10.4993 17.6054 10.464L19.969 10.3038L20.8518 8.10532C21.0464 7.62082 21.7323 7.62082 21.9269 8.10532L22.8097 10.3038L25.1733 10.464C25.6943 10.4994 25.9062 11.1517 25.5055 11.4864L23.6875 13.0054L24.2655 15.3029C24.3929 15.8092 23.838 16.2124 23.3958 15.9348L21.3894 14.6751Z" fill="white" />
      <rect x="0.5" y="0.5" width="39" height="23" rx="3.5" stroke="#F4F4F7" />
    </svg>
  )
}

export default memo(PromoCodeIcon);
