import React, { memo } from 'react';

const UploadIcon = props => {
  const { color = '#43425D' } = props;

  return (
    <svg className = "icon" width="60" height="44" viewBox="0 0 60 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.8819 43.8287H37.1843H34.5729H34.009V30.8209H38.2632C39.3421 30.8209 39.9796 29.5949 39.3421 28.7122L31.0666 17.2615C30.5394 16.5259 29.4483 16.5259 28.9211 17.2615L20.6457 28.7122C20.0082 29.5949 20.6334 30.8209 21.7246 30.8209H25.9787V43.8287H25.4148H22.8034H11.5611C5.12464 43.4731 0 37.4535 0 30.9313C0 26.4319 2.43972 22.5087 6.0564 20.3877C5.72538 19.4928 5.55374 18.5365 5.55374 17.5312C5.55374 12.9337 9.26849 9.21896 13.866 9.21896C14.859 9.21896 15.8153 9.3906 16.7103 9.72161C19.3707 4.08205 25.1083 0.171143 31.7777 0.171143C40.4087 0.183402 47.5194 6.7915 48.3286 15.2141C54.9612 16.3542 60 22.4964 60 29.4478C60 36.8773 54.2133 43.3138 46.8819 43.8287Z" fill={color}/>
    </svg>

  )
}

export default memo(UploadIcon);
