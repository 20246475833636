import React, { memo } from 'react';

import PaymentBlock from './PaymentBlock';
import Earnings from './Earnings';
import Subscriptions from "./Subscriptions/Subscriptions";

const MMPayments = props => {
    const { viewBlock } = props;
    
    return (
      <>
        {viewBlock === 'Earnings' && <Earnings />}
        {viewBlock === 'Payments' && <PaymentBlock />}
        {viewBlock === 'Subscriptions' && <Subscriptions />}
      </>
    )
}

export default memo(MMPayments);
