import React from "react";
import Button from "../Button";
import moment from "moment";

import './styles.scss'
import {inject, observer} from "mobx-react";

const UnsubscribePopup = (props) => {

  const {packageData:{id, active_to},
    subscribePackageModel: {cancelSubscriptionPackage},
    mmData,
    close=()=>{}} = props;

  const handleClose = (e) => {
    e.stopPropagation()
    close()
  }

  const unsubscribe = () => {
    cancelSubscriptionPackage({id, user_id: mmData.id})
    close()
  }
  return <div className={"unsubscribePopup"}>
    <div className="unsubscribePopup_title">Unsubscribe?</div>
    <div className="unsubscribePopup_text">
      If you unsubscribe now, the next payment will not be automatically completed. From <b>{moment(active_to, 'YYYY-MM-DD').format('MMM DD, YYYY')}</b> all your subscription benefits will be canceled.
    </div>
    <div className="unsubscribePopup_btns">
      <Button violetGradien className="unsubscribePopup_yesBtn" label={'Yes, Unsubscribe Now'} onClick={unsubscribe}/>
      <Button whiteViolet label={'Cancel'} onClick={handleClose}/>
    </div>
  </div>
}

export default  inject("subscribePackageModel")(observer(UnsubscribePopup))