import React, {useEffect, useState} from 'react';

const Monthly = props => {
    const {
        dayArr,
        day,
        month,
        year,
        dayEndDate,
        monthEndDate,
        monthlyArr,
        isUpdating,
        yearEndDate,
        setMonthlyArr,
    } = props;

    const [weekDays, setWeekDays] = useState([]);

    console.log('dayArr', dayArr);
    console.log('weekDays', weekDays);

    useEffect(() => {
        setWeekDays([...getActiveMonthDays(dayArr)]);
    }, [day, month, year, dayEndDate, monthEndDate, yearEndDate, dayArr]);

    useEffect(() => {
        if(!isUpdating || !monthlyArr.length){
            return;
        }

        const days = dayArr.map(item => {
            const updateDay = monthlyArr.find(i => item === i.day);

            if(updateDay){
                return updateDay;
            } else {
                return { day: item, disable: false, active: false };
            }
        });

        setWeekDays(days);

    }, [isUpdating, dayArr]);

    function getActiveMonthDays(dayArr) {
          let arr = [];
          if(dayArr[0] !== 1) {
              for (let i = 1; i < dayArr[0]; i++) {
                  arr.push(i);
              }
          }

          arr = [...arr, ...dayArr];

          const newArr = arr.map(item => {

              if(item === +day) {
                  return { day: item, disable: true, active: true }
              }

              if(+year !== +yearEndDate || (+year === +yearEndDate && +monthEndDate > +month)) {
                  return { day: item, disable: false, active: false };
              }

              if(+year === +yearEndDate && +monthEndDate === +month) {
                  if(+dayEndDate > +day) {
                      if(item > +day && item <= +dayEndDate) {
                          return { day: item, disable: false, active: false };
                      }

                      if(item < +day || item > +dayEndDate) {
                          return { day: item, disable: true, active: false };
                      }
                  }

                  if(+dayEndDate === +day && item !== +day) {
                      return { day: item, disable: true, active: false };
                  }
              }

              if(+year === +yearEndDate && +monthEndDate-1 === +month) {
                  if(+dayEndDate > +day && item > +day && item <= +dayEndDate) {
                      return { day: item, disable: false, active: false };
                  }
              }

              if(+year === +yearEndDate && +monthEndDate < +month) {
                  return { day: item, disable: true, active: false };
              }
          })
        if(!isUpdating){
            setMonthlyArr(newArr.filter(item => item.active))
        }
        return newArr;
    }

    function addActiveDay (event) {
        const notActive = weekDays.find(item => +event.currentTarget.innerHTML === item.day && item.disable);
        if(notActive) return;

        const Active = weekDays.map(item => {
            if(+event.currentTarget.innerHTML === item.day) {
                if(item.active) {
                    item.active = false;
                } else {
                    item.active = true;
                }
            }

            return item;
        });
        setMonthlyArr(Active.filter(item => item.active));
        setWeekDays([...Active])
    }

    return (
        <div className="monthly">
            {weekDays.length && weekDays.map( (item, index) =>
                <div className={
                        item.active && item.disable ? "monthlyDay monthlyDayActiveStatic" :
                        item.active && !item.disable ? "monthlyDay monthlyDayActive" :
                        item.disable ? "monthlyDay monthlyDayDisabled"  : "monthlyDay" }
                        key={index+item}
                        onClick={(event => addActiveDay(event))}>
                        {item.day}
                </div>
            )}
        </div>
    );
};

export default Monthly;