
const inviteToGroup =  {
  getSubject : (groupName, groupType) => {
    return `Please visit ${groupType} “${groupName}“`
  },
  getBody : (groupType, groupTitle, groupLink,groupCreator ) => {
    return `Please visit ${groupType} ${groupTitle} ${groupLink} by ${groupCreator}`
  }
}

const inviteToCommunity =  {
  getSubject : (groupName) => {
    return `Please visit community “${groupName}“`
  },
  getBody : (groupTitle, groupLink ) => {
    return `Please visit community ${groupTitle} ${groupLink}`
  }
}

const MailBuilder = {
  inviteToGroup,
  inviteToCommunity
}

export default MailBuilder;
