import React, { useEffect } from "react";
import plylist_empty_ic from '../../../res/icons/playlist_empty.svg';

import "./styles.scss";
const PlaylistEmpty = (props) => {
  const {  } = props;

  return (
    <div className="playlist_list_items_empty">
      <img src={plylist_empty_ic} alt=""/>
      <div className = "playlist_list_items_empty-title">No Items to Display</div>
      <div className = "playlist_list_items_empty-desc">
        Open video or audio, and use the
        "Add to playlist" icon to add content.
      </div>
    </div>
  );
};

export default PlaylistEmpty;
