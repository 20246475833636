import React from 'react';
import moment from 'moment';
// import styled from '@react-pdf/styled-components';
import { Page, Text, View, Document, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';
import Logo from '../../../res/img/logo.png';
import IOS from '../../../res/img/App_Store_btn.png';
import Android from '../../../res/img/GooglePlay_btn.png';

import { returnName } from '../../../units/func';

Font.register({
  family: 'Roboto',
  src: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'
});
// Font.register({ family: 'Red Hat Display', src: "https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap" });
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: '55px 0 0 48px',
  },
  divider: {
    border: '1px solid #E0E4E9',
    width: '100%',
    height: 1,
    backgroundColor: '#E0E4E9',
    margin: '10px 0',
  },
  table: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  fieldTitle: {
    // fontFamily: 'Red Hat Display',
    fontSize: 11,
    color: '#757575',
    width: 35,
    height: 15,
  },
  fieldData: {
    width: 500,
    color: '#333333',
    fontSize: 11,
    height: 15,
  },
  logoBlock: {
    display: 'flax',
    justifyContent: 'center',
    margin: '15px 150px',
  },
  logo: {
    width: 195,
    height: 50,
  },
  data: {
    // fontFamily: 'Roboto',
    fontSize: 16,
    color: '#A5A5A5',
    margin: '15px 50px',
  },
  amountTable: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: 48,
    marginLeft: -48,
  },
  firstTitle: {
    width: 315,
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333333',
    marginLeft: 60,
    alignSelf: 'flex-end',
    marginRight: 'auto',
    marginBottom: 22,
  },
  secondTitle: {
    width: 43,
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333333',
    marginLeft: 5,
    textAlign: 'right',
    marginBottom: 22,
  },
  therdTitle: {
    width: 100,
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333333',
    marginLeft: 10,
    textAlign: 'right',
    marginBottom: 22,
  },
  firstText: {
    width: 315,
    // fontFamily: 'Roboto',
    fontSize: 14,
    color: '#757575',
    marginLeft: 60,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    marginBottom: 12,
  },
  secondText: {
    width: 43,
    // fontFamily: 'Roboto',
    fontSize: 14,
    color: '#757575',
    textAlign: 'right',
    marginBottom: 12,
  },
  therdText: {
    width: 100,
    // fontFamily: 'Roboto',
    fontSize: 14,
    color: '#757575',
    marginLeft: 10,
    textAlign: 'right',
    marginBottom: 12,
  },
  taxCredit: {
    width: 150,
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: 33,
    marginBottom: 32,
  },
  taxTitle: {
    width: 40,
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    color: '#333333',
    textAlign: 'right',
    marginRight: 15,
  },
  creditTitle: {
    width: 130,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    color: '#333333',
    marginLeft: 15,
    textAlign: 'right',
  },
  taxData: {
    width: 40,
    textAlign: 'right',
    // fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '16px',
    color: '#757575',
    marginRight: 15,
  },
  creditData: {
    width: 130,
    marginLeft: 15,
    textAlign: 'right',
    // fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '16px',
    color: '#757575',
  },
  totalTitle: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    color: '#333333',
    textAlign: 'right',
  },
  totalData: {
    width: '100%',
    // fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '16px',
    color: '#757575',
    textAlign: 'right',
  },
  textDetails: {
    // fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 12,
    color: '#333333',
  },
  middleText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  details: {
    display: 'flex',
    marginBottom: 18,
    fontSize: 13,
    lineHeight: '15px',
    color: '#333333',
    flexDirection: 'row',
    marginRight: 35,
  },
  detailsFrom: {
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    width: 200,
    marginLeft: 40,
  },
  contact: {
    width: 200,
  },
  support: {
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '14px',
    color: '#333333',
    marginTop: 18,
    marginBottom: 8,
  },
  address: {
    // fontFamily: 'Roboto',
    fontSize: 12,
    color: '#757575',
    marginTop: 8,
    marginBottom: 53,
  },
  linkBlock: {
    marginBottom: 25,
    marginTop: 53,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row'
  },
  link: {
    margin: 4,
  },
  image: {
    width: 121,
    height: 36,
  },
  difFonts: {
    display: 'flex',
    flexDirection: 'row'
  },
  coursesBtn: {
    backgroundColor: '#EA5356',
    borderRadius: 2,
    width: 96,
    height: 27,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
  },
  courser: {
    // fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '14px',
    color: '#FFFFFF',
    // color: '#FFFFFF',
  }
});

const MyEarnings = props => {
  const { item, showMettingsLine } = props;

  const fullPrice = item.amount / 97;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Order Confirmation for {moment(item.created_at).format('MMMM DD, YYYY')}</Text>
          {/*April 2, 2018*/}
          <View style={styles.divider} />
          <View style={styles.table}>
            <Text style={styles.fieldTitle}>From:</Text>
            <Text style={styles.fieldData}>Ascend (customersuccess@ascend2u.com)</Text>
            <Text style={styles.fieldTitle}>To:</Text>
            <Text style={styles.fieldData}>{`${returnName(item.user)} (${item.user?.email})`}</Text>
            <Text style={styles.fieldTitle}>Date:</Text>
            <Text style={styles.fieldData}>{moment().format('dddd, MMMM DD, YYYY, hh:mma ')}</Text>
            {/*Monday, Aprel 2, 2019, 09:37PM*/}
          </View>
          <View style={styles.divider} />
          <View style={styles.logoBlock}>
            <Image object-fit="fill" src={Logo} alt="images" style={styles.logo} />
          </View>
          <Text style={styles.data}>Your Order Confirmation from {moment(item.created_at).format('MMMM DD, YYYY')}</Text>
          <View style={styles.amountTable}>
            <Text style={styles.firstTitle}>DESCRIPTION</Text>
            <Text style={styles.secondTitle}>PRICE</Text>
            <Text style={styles.therdTitle}>EARNINGS</Text>
            <View style={styles.firstText}>
              <Text>{item.source && item.source.name}</Text>
            </View>
            <Text style={styles.secondText}>
              {`$${item.source && (item.source.price/100).toFixed(2)}`}
              </Text>
            <Text style={styles.therdText}>
              {/* {`$${(item.amount/100).toFixed(2)}`} */}
            </Text>
            {showMettingsLine && 
              <>
                <View style={styles.firstText}>
                  <Text>{item.source && `Meeting: ${item.source.past_meetings_count}/${item.source.meetings_count}`}</Text>
                </View>
                <Text style={styles.secondText}>
                  {`$${item.source && (item.source.price/100/item.source.meetings_count).toFixed(2)}`}
                </Text>
                <Text style={styles.therdText}>
                  {`$${(fullPrice).toFixed(2)}`}
                </Text>
              </>
            }
            {item.count && <View style={styles.firstText}>
              <Text>{item.source && `Partisipants: ${item.count}`}</Text>
            </View>}
            <Text style={styles.secondText} />
            <Text style={styles.therdText} />
            <View style={styles.taxCredit}>
              {/* <Text style={styles.taxTitle}>SERVICE FEE</Text> */}
              <Text style={styles.creditTitle}>TECHNOLOGY FEE</Text>
              <Text style={styles.creditData}>
                {`$${((item.source.price - item.amount)/100).toFixed(2)}`}
                {/* // item.amount */}
              </Text>
              {/* <Text style={styles.creditData}>$0.00</Text> */}
              <View style={styles.divider} />
              <Text style={styles.totalTitle}>Total</Text>
              <Text style={styles.totalData}>
                {`$${(item.amount/100).toFixed(2)}`}
                </Text>
            </View>
          </View>
          <View style={styles.details}>
            <View style={styles.detailsFrom}>
              <Text style={styles.fromCompany}>Sold by: Ascend</Text>
            </View>
            <Text style={styles.contact}>
              Questions? Please contact us at: <Link src="mailto:customersuccess@ascend2u.com">customersuccess@ascend2u.com</Link>
            </Text>
          </View>
        </View>
        <View style={styles.middleText}>
          {/* <Text style={styles.textDetails}>
            Want to know when Udemy courses are on sale? Click here to receive our promotional emails.
          </Text>
          <Text style={styles.support}>SUPPORT</Text>
          <Text style={styles.address}>
            Udemy.com 600 Harrison Street, 3rd Floor San Francisco, CA 94107 USA
          </Text> */}
          <View style={styles.linkBlock}>
            <Link src="https://apps.apple.com/ua/app/ascendvideo/id1496739998" style={styles.link} >
              <Image object-fit="fill" src={IOS} alt="images" style={styles.image} />
            </Link>
            <Link src="https://play.google.com/store/apps/details?id=com.doneit.ascend" style={styles.link} >
              <Image object-fit="fill" src={Android} alt="images" style={styles.image} />
            </Link>
          </View>
        </View>
      </Page>
    </Document>
  )
};

export default MyEarnings;
