import React, {useEffect, useState} from 'react'; // , { useState }
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import HeaderMaster from '../../Dashboard/Daily/headerMaster';
import RightMenuFilters from '../../Dashboard/Daily/rightMenuFilters';

import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';

import AddVideoContent from '../../../components/AddVideoContent/AddVideoContent';

import SucceseSubbscribe from '../../../pages/Subscribe/SucceseSubbscribe';

import Subscribe from '../../Subscribe';

import { menuList1, menuListMobile, recordsMenu } from '../../../const/menu'; // menuList2
import { userRole } from '../../../const/general';
import { groups_create } from '../../../const/urlKeys';

import {checkIsUserMM } from '../../../units/func'; // createAGroup

const MainMenu = props => {
  const history = useHistory();
  const { push, location: { pathname } } = history;
  const {
    groupsModel: {userGroups, setEditGroup, profileActiveLink},
    userModel: { user, community, auth_user },
    subscribeModel: { subscribeStatus, setSubscribeStatus },
    fileUploadingModel: {filesToUpload},
    communityModel: { getCommunityDataById, communityList },
    subscribePackageModel : {getSubscriptionPackagesList}
  } = props;

  // const [newWind, setNewWind] = useState(null);

  const linkAction = item => {
    if (pathname !== item.link) {
      if (item.linkType === 'outside') {
        window.open(item.link, "_blank")
      } else {
        push(item.link)
      }
    }
  }
 

  const buiidMenu = (data, className = '') => {
    return (<>
      <List className={className}>
        {data.map(item => (
          <ListItem
            button
            key={item.key}
            onClick={() => linkAction(item)}
            className={classNames({ selectedItem: pathname === item.link })}
          >
            <ListItemIcon>
              <Badge color="secondary" variant="dot" invisible={!item.badge}>
                {item.icon && <img src={item.icon} alt={`${item.label} icon`}/>}
                {/* {!!item.iconComp && item.iconComp} */}
              </Badge>
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </>)
  }

  const currentMenuList1 = menuList1.map( item => {
    switch (item.key) {
      case 'webinars':
        return ({ ...item })

      case 'groups':
        if (community === 'Recovery' || community === 'Family') {
          return ({ ...item, label: 'Groups' })
        }
        return ({ ...item })

      case 'masterMind':
        if (community === 'Fitness' || community === 'Family' || 
          community === 'Spiritual' || community === 'Lifestyle') {
          return ({ ...item, label: 'Group' })
        }
        return ({ ...item })
    
      default:
        return ({ ...item })
    }
  })

  const buttonFunc = () => {
    return user?.role === userRole && !user?.have_subscription 
      ? <Dialog 
          openButton={<Button label="Create Group" violetGradien smaller130  />}
          className="eventGroup"
          closeColor="#FE1E92"
        >
            <Subscribe />
        </Dialog>
      : <Button
          label="Create Group"
          violetGradien
          smaller130
          onClick={() => {
            if (history.location.pathname === groups_create){
              return false;
            }
            setEditGroup(null)

            history.push(groups_create)
            getSubscriptionPackagesList(auth_user.id)
          }}
        />  
  }


  const mmShowButton = checkIsUserMM(user, communityList)
  const typeOfAddContentButton = () =>{
    let locationPath = window.location.pathname
    if(locationPath.includes("content/videos")){
      return "video_record"
    }else if(locationPath.includes("content/meditations")){
      return "meditation"
    }else if(locationPath.includes("content/podcasts")){
      return "podcast"
    }else{
      return false
    }
  }
  
  return (
    <nav>
      
      {window.innerWidth < 720 && buiidMenu(menuListMobile)}
      {window.innerWidth < 720 && <Divider />}
      <div className='menuWhiteContainer menuWhiteContainerWithButton'>
        {buiidMenu(currentMenuList1, 'noBottomSpace')}
        {user?.role !== userRole && /*mmShowButton && */buttonFunc()}
        {/*user?.role === userRole && buttonFunc() */}
      </div>
      <div className='menuWhiteContainer'>
        <div className='menuBlockTitle'>Media Library</div>
        {buiidMenu(recordsMenu)}
        {((user?.role !== userRole) && typeOfAddContentButton()) ?
          <div className='button-wrapper' style = {{width: "100%"}}>
            <div className='button violetGradien smaller130' style = {{width: "100%", cursor: "pointer"}}>
              <AddVideoContent record_type = {typeOfAddContentButton()}/>
            </div>
          </div> : null}
      </div>
      {(!["/", "/content/playlists","/choose_community", "/notifications", "/profile", "/all_creators"].includes(window.location.pathname)
      && !window.location.pathname.includes("/user/")) || (window.location.pathname.includes("/user/") && userGroups) ?
          <div className='menuWhiteContainer'>
            <div className='menuBlockTitle'>Filter</div>
            <RightMenuFilters
                coaching = {window.location.pathname === "/coaching"}
                mediaLibrary = {window.location.pathname.includes("content") || profileActiveLink === "Content"}/>
          </div>: null}
      {subscribeStatus && <Dialog
        className="eventGroupSuccese"
        closeColor="#FE1E92"
        defaultOpen={!!subscribeStatus}
        onClose={() => setSubscribeStatus(false)}
      >
        <SucceseSubbscribe />
      </Dialog>}
    </nav>
  );
}

export default inject('userModel', 'subscribeModel', 'fileUploadingModel', 'groupsModel', 'communityModel', 'subscribePackageModel')(observer(MainMenu));
