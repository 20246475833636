import React, {useEffect, useMemo, useState} from 'react';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';

import DashboardPage from './Dashboard';
import Home from '../Home/Home';
import FirstLogin from '../FirstLogin';

import SimplePage from '../SimplePage';
import RightBlock from './Right';
import MainPage from '../MainPage';
// import Chat from '../ChatPage';

import { workshops, livestreams, collaborations, subscribe, home } from '../../const/urlKeys';
import {dailyPage, gtMasterMind, gtWebinar, gtSupport, gtRecurring, gtRecurringWebinar} from '../../const/general';
import Subscribe from "../Subscribe";
import Dialog from "../../components/Dialog";

const Dashboard = props => {
  const { 
    authModel: { session_token, loading, twitter }, 
    userModel: { user }, 
    groupsModel: { groupTypeFilter, setSimpleId, clearSimpleId }, 
    fileUploadingModel: { filesToUpload },
    match 
  } = props;
  const query =  new URLSearchParams(window.location.search);
  useEffect(() => {
    if (match?.params?.id || query?.get('id') ) {
      setSimpleId(match?.params?.id);
    } else {
      // clearSimpleId(); // -> moved to return callback useEffect[] hook to avoid unnecessary rerender after storage change
    }
    
    switch (match.path) {
      case workshops:
        groupTypeFilter([gtMasterMind, gtRecurring]);
        break;

      case livestreams:
        groupTypeFilter([gtWebinar, gtRecurringWebinar]);
        break;

      case collaborations:
        groupTypeFilter(gtSupport);
        break;
    
      default:
        groupTypeFilter(dailyPage);
        break;
    }

    if (!session_token && window.location.search.includes('oauth_token')) {
      twitter(window.location.search);
    }
  });
  useEffect(_ => {
    return _ => {
      clearSimpleId();
    }
  },[])

  if (loading) return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <CircularProgress className="loader" />
  </div>

  if (!session_token || isEmpty(user)) return <Home />

  if (user && !user.community_id) return <SimplePage><FirstLogin /></SimplePage>
  
  return (
    <MainPage key={user?.id}>
      {match?.path === subscribe && <Dialog
          className="eventGroup"
          closeColor="#FE1E92"
          defaultOpen={true}
          itemPath={
            {
              forSet: subscribe,
              forBack: home
            }
          }
      >
        <Subscribe />
      </Dialog>}
      <DashboardPage match={match} />
      {/*<RightBlock />*/}
      {/* <Chat /> */}
    </MainPage>
    );
}

export default inject('authModel', 'userModel', 'groupsModel', 'fileUploadingModel')(observer(Dashboard));
