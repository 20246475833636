import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const CustomSneckbar = props => {
  const { message, onClose, duration = 3500, severity, showMessages } = props;

  // if (!message && !showMessages) return null

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={!!message}
      onClose={onClose}
      key="reaction"
      autoHideDuration={duration}
    >
      {message && message.length && <Alert severity={severity}>{message}</Alert>}
    </Snackbar>
  );
};

export default CustomSneckbar;
