import React, {useEffect, useState, useReducer} from "react";
import { useFormikContext } from 'formik';
import InputWithIcon from '../Input/inputWithIcon';
import CustomInput from "../Input/customInput";
import create_group_icons from '../../res/icons/create_group_icons';
import moment from "moment";
import "./styles.scss";

const NewThemesSubscriptionGroup = (props) => {
    const {
        isEdit,
        amountOfSchedules,
        rest,
        minMeetings,
        color,
        starttimeFromCalendar,
        editGroup,
        setErrorStateThemesSubGroup,
        webinarsArr,
        setWebinarsArr
    } = props;
    const { values, setFieldValue } = useFormikContext()
    const [labelList, setLabelList] = useState([])
    const [inputValuesList, setInputValuesList] = useState([])
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        if(amountOfSchedules === 1 && minMeetings === 1 && starttimeFromCalendar){
            setInputValuesList([moment(starttimeFromCalendar).format("DD.MM.YYYY")]);
            setLabelList([moment(starttimeFromCalendar).format("ddd, DD.MM.YYYY, hh:mm A")]);
        }
    }, [amountOfSchedules, minMeetings, starttimeFromCalendar])

    useEffect(() => {
        if(!webinarsArr.length) {
            return;
        }

        const inputValueList = webinarsArr.sort((a,b) => a.time.unix() - b.time.unix()).map(item => item.touched ? item.title : null);
        const labelList = webinarsArr.sort((a,b) => a.time.unix() - b.time.unix()).map(item => item.time.format("ddd, DD.MM.YYYY, hh:mm A"))


        setInputValuesList(inputValueList);
        setLabelList(labelList);

    }, [webinarsArr])

    const handleThemeChange = (e, index) => {
        if(e.target.value.length < 33){
            setWebinarsArr((data) => {

                return data.map((i, ind) => ind === index ? ({
                    ...i,
                    title: e.target.value,
                    touched: true,
                }) : i);
            });
        }
    }

    const isThemes = values['themes'] !== ""

    return (
        <>
            {(editGroup || inputValuesList.length) ? <div className="title themeTitle" style = {{color: `${color}`}}>Themes</div> : null}
            <div className="themesContainer">
                {(editGroup || isThemes) && inputValuesList.map((item, key) => {
                    const isFinished = isEdit && values?.past_meetings_count && key + 1 <= values?.past_meetings_count;
                    inputValuesList[key] === '' ? setErrorStateThemesSubGroup(true) : setErrorStateThemesSubGroup(false)
                    return (
                        <div style={{ position: 'relative'}} key={key} className={`itemThemesContainer ${inputValuesList[key] === '' && "hideLabelTheme"}`}>
                            { inputValuesList[key] === '' && <div className="requiredNotification">required</div>}
                            <InputWithIcon className="width320" icon={create_group_icons.red.fire} key={rest.name}>
                                <CustomInput
                                    {...rest}
                                    key={key + rest.name}
                                    id={rest.name}
                                    disabled={isFinished}
                                    value={inputValuesList[key]}
                                    onChange={(e) => handleThemeChange(e, key)}
                                    inputPlaceholder={'Ádd theme name'}
                                    placeholder= {labelList[key]}
                                    className = "inputItemThemesContainer"
                                />
                            </InputWithIcon>
                        </div>
                    )
                })}
            </div>
        </>
    )

}

export default NewThemesSubscriptionGroup;