import React, {useState} from 'react';
import classNames from 'classnames/bind';
import Button from '@material-ui/core/Button';
import CustomPopover from "../../../../components/Popover";
import CustomModal from "../../../../components/Modals/Modal";
import EditPackage from "../../../../components/Modals/EditPackageModal";
import UserCountIcon from "../../../../res/img/icons/user-count.png";
import ArrowIcon from "../../../../res/icons/arrowIcon.svg";
import UserCountActiveIcon from "../../../../res/img/icons/user-count-active.png";
import PointButtonIcon from "../../../../res/img/icons/point-x3.png";

import './subscriptionsPackages.scss';
import {findTiersTitleByName} from "../../../../units/func";
import {inject, observer} from "mobx-react";
import {
  gtIndividual,
  gtRecurring,
  gtRecurringWebinar,
  statusActive,
  statusDeactivated,

} from "../../../../const/general";

const SubscriptionsPackagesCard = (props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    name, active_subscribers_count,  subscribers_count, active_to,  status = statusActive, price, tiers, customWidth, 
    subscriptionPage, communities, className, description = '', id, distribution_group_id,
    detailsSubscription = false, current, cancelled,
    toggleBuySubscriptionPopup = (_) => {},
    toggleCancelSubscriptionPopup = (_) => {},
    customFlexStyle = {},
    subscribePackageModel: {destroySubscriptionPackage, deactivateSubscriptionPackage},
    communityModel: { getCommunityNameById, communityList, distribution_groups}
  } = props;
  const selectedSubscription = detailsSubscription && tiers.includes(detailsSubscription?.type) && communities.includes(detailsSubscription?.community + "")
  const isActive = status === statusActive
  const mainClassName = classNames('subscriptionsPackagesCard', className, {deActivatedCard: !isActive,
    selectedSubscription: selectedSubscription,
    currentSubscription: current
  });

  const handleOpenModal = () => setIsOpenModal(!isOpenModal)
  const handleDeletePackage = () => destroySubscriptionPackage({id})
  const handleDeactivatePackage = () => deactivateSubscriptionPackage({id})
  const handleOpenCancelPopup = () => toggleCancelSubscriptionPopup({id, active_to})

  const handleOpenBuyPopup = () => toggleBuySubscriptionPopup({
    id,
    price,
    name
  })

  

  const communityTitles = [];
  if(distribution_group_id){
    distribution_groups.distribution_groups.map(grp => grp.id === distribution_group_id && communityTitles.push(grp.title))
  }
  communityList.communities.map(comm => communities.includes(comm.id + "") && communityTitles.push(comm.title))

  const getDeactivateDeleteBtn = () => {
    if (active_subscribers_count && isActive) {
      return <div className="popoverLink popoverLinkdeactivate">
        <button className="popoverLinkItem" onClick={handleDeactivatePackage}>Deactivate</button>
      </div>
    } else if (!active_subscribers_count) {
      return <div className="popoverLink popoverLinkDelete">
        <button className="popoverLinkItem" onClick={handleDeletePackage}>Delete</button>
      </div>
    } else return null
  }
  const getLabel = () => {
    if (current) {
      return <div className="subscriptionsCurrent text-uppercase">
        <span id='triangleCurrent'>subscribed</span>
      </div>
    }
    if (!isActive){
      return <div className="subscriptionsStatus text-uppercase">
        <span id='triangleDeactivate'>Deactivated</span>
      </div>
    }
    if (selectedSubscription){
      return <div className="subscriptionsLabel text-uppercase">
        <span id='triangleSubscribe'>Subscribe to join</span>
      </div>
    }
    return ''
  }
  const getActionBtn = () => {
    if (subscriptionPage){
      return (
        <div className="countUsers">
          {isActive ? (
            <span className="activeBorder">
                                <img src={UserCountActiveIcon} alt="user count icon" className="userContIcon"/>
                                <label className="activeNumber">{active_subscribers_count}</label>
                             </span>
          ) : (
            <span className="greyBorder">
                                <img src={UserCountIcon} alt="user count icon" className="userContIcon"/>
                                <label className="number">{active_subscribers_count}</label>
                            </span>
          )}
        </div>
      )
    }

    if (status === statusDeactivated) return false;

    if (current && !cancelled ) {
      return <Button variant="contained" className='unsubscribeButton' onClick={handleOpenCancelPopup}>Unsubscribe</Button>
    } else {
      return <Button variant="contained" className='subscribeButton' onClick={handleOpenBuyPopup}>Subscribe</Button>
    }

  }

  return (
    <div className={`col-md-6 ${customWidth ? 'customWidth' : ''}`} style = {customFlexStyle}>
      <div className={mainClassName}>
        <div className="headerTop d-flex justify-content-between">
          <div className="headerTitle">
            {name}
          </div>

            {subscriptionPage ? (
              <div className="headerStatus">
              <CustomPopover
                myBuyyon={
                  <button className="btnPointX3">
                    <img src={PointButtonIcon} alt="point icon" className="pointBtn"/>
                  </button>
                }
                isActive={isActive}
              >
                <div className="popoverContent p-1 bg-light">
                  <div className="popoverLink popoverLinkEdit">
                    <button className="popoverLinkItem" onClick={handleOpenModal}>Edit</button>
                  </div>
                  {getDeactivateDeleteBtn()}
                </div>
              </CustomPopover>
              </div>
            ): getLabel()}

        </div>
        <div className="headerBottom d-flex justify-content-between subscriptionPackagesCardCommunities">
          {communityTitles.map((commTitle, key) => <p key = {key} className="spanRecovery">{commTitle}</p>)}
          {subscriptionPage && getLabel()}
        </div>
        <div className='cardContent'>
          {subscriptionPage && (
            <p className="cardContentTitleText justify-content-center">
              <img src={UserCountIcon} alt="useres icon" className="pr-2"/>
              {subscribers_count} subscribers for all time
            </p>)}
          <p className="cardContentTitleTextSubText">
            {description}
          </p>
          <ul className="listItems pl-0">
            {tiers?.map((name) => {
              if (name === gtRecurring || name === gtRecurringWebinar || name === gtIndividual) return null;
              return (
                <li className="item" key={name}>
                  <div className={'check'}>
                    <img src={ArrowIcon} alt="arrow icon"/>
                  </div>
                  <span className="itemText">{findTiersTitleByName(name)}</span>
                </li>)
            })}
          </ul>
        </div>
        <div className="footerCard">
          <div className="pricePerMonth">
            <h4 className="pricePerMonthValue">
              {parseFloat(price / 100).toFixed(2)} $
            </h4>
            <span className="pricePerMonthText">/ month
                    </span>
          </div>
          {getActionBtn()}
        </div>
      </div>
      <CustomModal isOpen={isOpenModal} handleClose={handleOpenModal} closeColor={'#FFFFFF'}>
        <EditPackage handleClose={handleOpenModal} {...props} />
      </CustomModal>
    </div>
  )
}
export default inject("subscribePackageModel", 'communityModel')(observer(SubscriptionsPackagesCard))