import React, { memo } from 'react';
import Button from '../../components/Button';

import Clock from '../../res/icons/clockPicker.svg';
import DownIcon from '../../res/icons/arrowDown.svg'
 
const TimePickerButton = props => {
	const { label, ...rest } = props;
	return(
		<Button 
			label={
			  <span>
          <img src={Clock} alt="clockIcon" />
          <span className = "time-picker-time-label">{label}</span>
          <img src={DownIcon} alt="down" />
        </span>
      }
			{...rest}
		/> 
	)
}

export default memo(TimePickerButton);
