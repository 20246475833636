import React, { useState, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PlaylistItem from './PlaylistItem';
import Dialog from '../Dialog';
import classNames from 'classnames';

import './style.scss';

const Playlists = (props) => {
  const { 
    playlists,
    playlistModel: {
      deletePlaylist,
      updatePlaylist,
      deleteItem,
      updateMyPlaylistItems
    }
   } = props;

  const [postWidth, setPostWidth] = useState("auto")
  const postListRef = useRef(null)

  useEffect(() => {
    if(postListRef?.current?.children && postListRef.current.children.length){
      postListRef.current.children[0].clientWidth &&
      postListRef.current.children[0].clientWidth !== 250 && 
      setPostWidth(postListRef.current.children[0].clientWidth)
    }
  }, [postListRef, playlists])

  return (
    <div className = "playlists_items_wrapper" ref = {postListRef}>
      {playlists.map(item => {
        return <PlaylistItem 
          playlist = {item}
          handleDelete = {() => deletePlaylist(item.id)} 
          handleUpdate = {(updatedItem) => updatePlaylist(item.id, updatedItem)}
          handleDeleteItem = {(playlist_item_id) => deleteItem(item.id, playlist_item_id)}
          handleUpdatePlaylistItems = {(new_list) => updateMyPlaylistItems(item.id, new_list)}
          postWidth = {postWidth} 
          key = {item.id}
        />
      })}
    </div>
  );
};

export default inject('playlistModel')(observer(Playlists));
