import React, { memo } from 'react';
import VideoPlayer from '../VideoPlayer/index';
import Dialog from '../Dialog';

import './style.scss';

const MyPlayer = ({ children, onClose = _ => {}, autoplay, record_info, owner, playlist, videoFromNewContent}) => {
  
  return <>
    <Dialog
      openButton={children}
      className="my_player_dialog"
      closeColor="#FFFFFF"
      onClose={onClose}
      defaultOpen={autoplay}
    >
      <VideoPlayer playlist = {playlist} record_info = {record_info} owner = {owner} videoFromNewContent = {videoFromNewContent}/>
    </Dialog>
  </>
}

export default memo(MyPlayer);
