import { decorate, observable } from 'mobx'; // action
import axios from 'axios';
import moment from 'moment';

import { baseAPI } from '../const/settings';

import { authModel } from './auth';

const currentYear = moment().format('YYYY');

export class TransactionsModel {
  constructor() {
    this.transactions = null;
    this.balance = null;
    this.transactionsEarnings = null;
    this.withdrawStatus = '';
    this.earnings = null;
  }

  setTransactionsData = data => this.transactions = data;
  setBalanceData = data => this.balance = data;
  setWithdrawStatus = status => this.withdrawStatus = status;
  setEarningsData = data => this.earnings = data;

  transactionsGrouping = () => {
    let earnings = {};
    this.transactions && this.transactions.payouts.forEach(transaction => {
      const year = moment(transaction.created_at).format('YYYY');
      const month = moment(transaction.created_at).format('MMMM');
      const key = moment(transaction.created_at).format('MMMM YYYY');
      if (!earnings[key]) {
        earnings[key] = {
          'name': currentYear === year ? month : key,
          'balance': 0 - transaction.amount,
          'transactions': [{...transaction, type: 'withdrawn'}]
        }
      } else {
        earnings[key].balance = earnings[key].balance - transaction.amount;
        earnings[key].transactions.push({...transaction, type: 'withdrawn'});
      }
    });
    this.earnings && this.earnings.earnings.forEach(transaction => {
      const year = moment(transaction.created_at).format('YYYY');
      const month = moment(transaction.created_at).format('MMMM');
      const key = moment(transaction.created_at).format('MMMM YYYY');
      if (!earnings[key]) {
        earnings[key] = {
          'name': currentYear === year ? month : key,
          'balance': transaction.amount,
          'transactions': [{...transaction, type: transaction.name}]
        }
      } else {
        earnings[key].balance = earnings[key].balance + transaction.amount;
        earnings[key].transactions.push({ ...transaction, type: transaction.name, created_at: transaction.start_time });
      }
    });
    Object.keys(earnings).map(key => earnings[key].transactions = earnings[key].transactions.slice().sort(function (a, b) {
      return new Date(a.created_at) - new Date(b.created_at);
    }));
    this.transactionsEarnings = earnings;
  }

  // getTransactions = () => {
  //   const setTransactionsData = this.setTransactionsData;
  //   const transactionsGrouping = this.transactionsGrouping;
  //   const headers = {
  //     "headers": {
  //       "accept": "application/json, text/plain, */*",
  //       "session-token": authModel.session_token
  //     }};
  //   axios.get(`${baseAPI}transactions?[per_page]=1000`,  headers)
  //     .then(function (response) {
  //       // handle success
  //       setTransactionsData(response.data);
  //       transactionsGrouping();
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error.response.data);
  //     })
  // }

  getEarnings = () => {
    const setEarningsData = this.setEarningsData;
    const transactionsGrouping = this.transactionsGrouping;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.get(`${baseAPI}transactions/earnings?[per_page]=1000`,  headers)
      .then(function (response) {
        // handle success
        setEarningsData(response.data);
        transactionsGrouping();
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }

  getBalance = () => {
    const setBalanceData = this.setBalanceData;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.get(`${baseAPI}transactions/show_balance`,  headers)
      .then(function (response) {
        // handle success
        setBalanceData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }

  getWithdraw = (amount, cardId, type) => {
    // const getTransactions = this.getTransactions;
    const getBalance = this.getBalance;
    const setWithdrawStatus = this.setWithdrawStatus;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    const data = {
      payout_destination_id: cardId,
      payout_destination_type: type,
      amount
    }
    axios.post(`${baseAPI}transactions/withdraw_balance`, data, headers)
      .then(function (response) {
        // handle success
        setWithdrawStatus('Success');
        getBalance();
        // getTransactions();
      })
      .catch(function (error) {
        // handle error
        setWithdrawStatus('Error');
      })
  }
}

decorate(TransactionsModel, {
  transactions: observable,
  balance: observable,
  transactionsEarnings: observable,
  withdrawStatus: observable,
  // getTransactions: action,
})

export const transactionsModel = new TransactionsModel();
