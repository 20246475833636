import React, { memo } from 'react';

const AtendeesIcon = props => {
  // const { color = 'white' } = props;

  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6 5.57029C11.9956 5.57029 12.3822 5.45362 12.7111 5.23502C13.04 5.01642 13.2964 4.70572 13.4478 4.34221C13.5991 3.9787 13.6387 3.5787 13.5616 3.19279C13.4844 2.80689 13.2939 2.45241 13.0142 2.17419C12.7345 1.89597 12.3781 1.7065 11.9902 1.62974C11.6022 1.55298 11.2001 1.59237 10.8346 1.74295C10.4692 1.89352 10.1568 2.1485 9.93706 2.47566C9.7173 2.80281 9.6 3.18744 9.6 3.5809C9.59915 3.84239 9.65031 4.10146 9.75052 4.3432C9.85073 4.58495 9.99802 4.80459 10.1839 4.98949C10.3698 5.17439 10.5906 5.32089 10.8336 5.42057C11.0767 5.52025 11.3371 5.57113 11.6 5.57029ZM5.6 4.77454C6.07468 4.77454 6.53869 4.63453 6.93337 4.37221C7.32805 4.10989 7.63566 3.73705 7.81731 3.30084C7.99896 2.86462 8.04649 2.38462 7.95389 1.92154C7.86128 1.45845 7.6327 1.03308 7.29706 0.699216C6.96141 0.36535 6.53377 0.137985 6.06822 0.0458718C5.60266 -0.0462415 5.1201 0.00103437 4.68156 0.181721C4.24302 0.362408 3.86819 0.668389 3.60447 1.06097C3.34076 1.45356 3.2 1.91511 3.2 2.38727C3.19862 2.70115 3.25976 3.0122 3.37989 3.30245C3.50001 3.59271 3.67674 3.85642 3.89987 4.07837C4.12301 4.30033 4.38813 4.47612 4.67993 4.5956C4.97174 4.71509 5.28444 4.77591 5.6 4.77454ZM11.6 7.1618C10.136 7.1618 7.2 7.8939 7.2 9.35013V11.1406H16V9.35013C16 7.8939 13.064 7.1618 11.6 7.1618ZM5.6 6.36605C3.736 6.36605 0 7.29708 0 9.15119V11.1406H5.6V9.35013C5.62196 8.75883 5.81165 8.18578 6.14715 7.69717C6.48265 7.20856 6.95027 6.82433 7.496 6.58886C6.87277 6.45355 6.23777 6.37893 5.6 6.36605Z" fill="#43425D"/>
    </svg>
  )
}

export default memo(AtendeesIcon);
