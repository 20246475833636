import React, { memo } from 'react';

const DownloadIcon = props => {
  // const { color = '#43425D', className } = props;

  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99984 13.6668C10.7358 13.6668 11.3332 13.0695 11.3332 12.3335V4.3335L7.33317 0.333496H1.99984C1.26384 0.333496 0.666504 0.930829 0.666504 1.66683V12.3335C0.666504 13.0695 1.26384 13.6668 1.99984 13.6668H9.99984ZM6.6665 1.66683L9.99984 5.00016H7.33317C6.9645 5.00016 6.6665 5.00016 6.6665 5.00016V1.66683ZM2.6665 4.3335H4.6665V5.66683H2.6665V4.3335ZM2.6665 7.00016H9.33317V8.3335H2.6665V7.00016ZM2.6665 9.66683H9.33317V11.0002H2.6665V9.66683Z" fill="#43425D"/>
    </svg>
  )
}

export default memo(DownloadIcon);
