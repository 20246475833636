import React, {useEffect} from "react";
import MyPlayer from "../../MyPlayer";
import {
  gtRecurringWebinar,
  gtWebinar,
  meditation,
  podcast,
  video_record,
} from "../../../const/general";
import classNames from "classnames";
import PlayVideoIcon from "../../../res/iconComp/PlayVideoIcon";
import ImgBuilder  from "../../../res/builders/ImgBuilder";
import { inject, observer } from 'mobx-react';

import "./styles.scss";

const NewContentItem = (props) => {
  const {
    content,
    handleOpenDialog,
    playSingleRecordHandler = (_) => {},
  } = props;
  
  const mustBuy = !content.purchase && !content.blocked && content.price !== 0;
  const handleChoosePlayBtnAction = (e) => {
    if (
      content?.meetings_records &&
      content?.meetings_records?.length === 1 &&
      (content?.meetings_records[0].url || content?.meetings_records[0].file)
    ) {
      playSingleRecordHandler(content);
      e.stopPropagation();
    }
  };
  const type = (_) => {
    if (content?.record_type) {
      switch (content.record_type) {
        case video_record:
          return "Video by";

        case meditation:
          return "Meditation by";

        case podcast:
          return "Podcast by";

        default:
          return "";
      }
    } else if (content?.group_type) {
      switch (content.group_type) {
        case gtWebinar:
        case gtRecurringWebinar:
          return "Livestream by";

        default:
          return "";
      }
    }
    return null;
  };
  
  const playIcon = (onClick) => (<div className={"content_item_play"} onClick={onClick}>
    <img src = {ImgBuilder.play_button_white}/>
  </div>)
  return (
    <div className={"content_item"}>
      
      <div className={"content_item_image"} >
        {content.meetings_records.length > 0 && !mustBuy ? (
          <MyPlayer 
            record_info={{...content.meetings_records[0],url: content.meetings_records[0].file,image: content.image }}
            owner={content?.owner}
            >
            {playIcon()}
          </MyPlayer>
        ) : (
          playIcon(handleOpenDialog)
        )}
        <div className={"content_item_name"} onClick={handleOpenDialog}>{content?.name}</div>
        <img src={content?.image?.url} alt={content?.name} />
        {(content.price > 0 && !content.purchase) &&
          <img src = {ImgBuilder.subscription_premium} className = "content_item_premium"/>
        }
        {(content.available_for_subscribers && content.have_subscription) &&
          <img src = {ImgBuilder.subscribe_pink} className = "content_item_premium"/>
        }   
      </div>
      <div className={"content_item_description"}>
        <div className={"content_item_text"}>
          {type && <div className={"content_item_type"}>{type()}</div>}
          {content?.owner?.full_name && (
            <div className={"content_item_owner"}>
              {content.owner.full_name}
            </div>
          )}
          {content?.owner?.location && (
            <div className={"content_item_location"}>
              {content.owner.location}
            </div>
          )}
        </div>
        
      </div>
    </div>
  );
};

NewContentItem.propTypes = {};

export default NewContentItem;

