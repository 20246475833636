import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Redirect, useHistory } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import AppleSignin from 'react-apple-signin-auth';
import FacebookAuth from 'react-facebook-auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../firebase';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import PasswordInput from '../../../components/Input/passwordInput';
import NumberFormatCustom from '../../../components/Input/numberFormat';

import Fb from '../../../res/icons/fb.svg';
import Google from '../../../res/icons/google.svg';
import Twiter from '../../../res/icons/twiter.svg';
import Phone from '../../../res/icons/phone.svg';
import Key from '../../../res/icons/key.svg';
import Apple from '../../../res/icons/apple-auth.svg';
import Logo from '../../../res/img/logo.png';
import {
  home,
  forgot_password,
  register,
  profile,
} from '../../../const/urlKeys';

import './loginForm.scss';

const SignupSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Phone Number can't be blank")
    .matches(/^[0-9]{8,}$/, 'Phone number must contain at least 10 digits'), // The number is invalid. Please check it once again
  code: Yup.string().required('Code not selected'),
  password: Yup.string()
    .required("Password can't be blank")
    .matches(/^(?=(.*[a-zA-Z]){1,})(?=(.*[\d]){1,}).{6,48}$/, 'Wrong password'), // The password should contain at least 1 number and 1 letter, from 6 till 48 symbols.
});

const LoginForm = (props) => {
  const { countryModel, authModel } = props;
  const { phonesCode, currentCode } = countryModel;
  const {
      login,
      session_token,
      socialLogin,
      checkAuthToken,
      twitterLogin,
      loginErrorNotification,
      setLoginErrorNotification,
  } = authModel;
  const [isFucus, setIsFocus] = useState(false);
  const [valueIsEmpty, setIfValueEmpty] = useState(false);
  const history = useHistory();

  useEffect(() => {
      setTimeout(() => {
          setLoginErrorNotification('');
          }, 14000);
      },[loginErrorNotification]);

  initializeApp(firebaseConfig);

  if (session_token && session_token !== 'undefined') {
    const pathname = localStorage.getItem('pathname') || null;

    if (pathname && pathname !== profile) {
      return <Redirect to={pathname} />;
    }

    return <Redirect to={home} />;
  }

  return (
    <div className="loginForm">
      <Formik
        initialValues={{ phone: '', code: currentCode || '', password: '' }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          const { password, code, phone } = values;
          const phone_number = `+${code}${phone}`;
          login(phone_number, password, actions.setFieldError);
        }}
        enableReinitialize
      >
        {(formikProps) => {
          const { setFieldValue, isValid, values } = formikProps;
          values.phone === '' ? setIfValueEmpty(true) : setIfValueEmpty(false);
          const disabledButton = !(
            isValid &&
            values.code !== '' &&
            values.phone !== '' &&
            values.password !== ''
          );
          return (
            <Form>
              <div className="title">
                <img src={Logo} alt="Ascent video" />
              </div>
              <div className="line">
                <div className="code">
                  <img src={Phone} alt="phone icon" />
                  <Select
                    name="code"
                    data={phonesCode}
                    className="codeBlock"
                    setFieldValue={setFieldValue}
                  />
                </div>
                <Input
                  name="phone"
                  id="phone"
                  className={`phoneInput ${valueIsEmpty && 'removeAutofill'}`}
                  placeholder="Phone Number"
                  autocomplete="off"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </div>
              <div className="line end">
                <img src={Key} alt="key icon" />
                <PasswordInput
                  name="password"
                  id="password"
                  className={`passwordInput ${
                    valueIsEmpty && 'removeAutofill'
                  }`}
                  placeholder={
                    values.password || isFucus
                      ? 'Enter your password'
                      : 'Password'
                  }
                  // customFocus={() => setIsFocus(true)} // [AW-2101] breaks the input field after logout
                  customBlur={() => setIsFocus(false)}
                />
              </div>
              <Button
                type="submit"
                label="Sign In"
                disabled={disabledButton}
                width100
                violetGradien
                big60
              />
              <a className="forgotPassword" href={forgot_password}>
                Forgot Password?
              </a>
            </Form>
          );
        }}
      </Formik>
      <div className="grayBackground">
        <Button
          label="Create New Account"
          width100
          onClick={() => history.push(register)}
        />
        {/* <div className="register">
          Don't have an account? <a className="signUp" href={register} >Sign Up here</a>
        </div> */}
        <div style={{ textTransform: 'uppercase' }}>- or -</div>
        <div className="socialTitle">Sign in with these</div>
        <div className="socialButtons">
          <FacebookAuth
            appId={process.env.REACT_APP_FACEBOOK_APPID}
            callback={(data) =>
              socialLogin({
                social_type: 'facebook',
                access_token: data.accessToken,
              })
            }
            component={({ onClick }) => (
              <button onClick={onClick}>
                <img className="facebook" src={Fb} alt="facebook icon" />
              </button>
            )}
            isMobile={false}
            disableMobileRedirect={true}
            redirectUri="https://ascend.video"
          />
          {['Mac', 'iPhone', 'iPad'].some((platform) =>
            navigator.userAgent.includes(platform),
          ) && (
            <AppleSignin
              authOptions={{
                clientId: process.env.REACT_APP_APPLE_CLIENTID,
                scope: 'email name',
                redirectURI: process.env.REACT_APP_REDIRECT_SECURE,
                state: '',
                nonce: 'nonce',
                usePopup: true,
              }}
              onSuccess={(response) =>
                socialLogin({
                  social_type: 'apple_web',
                  access_token: response.authorization.id_token,
                  full_name: `${response.user?.name?.firstName} ${response.user?.name?.lastName}`,
                })
              }
              onError={(err) => console.log(err)}
              render={(props) => (
                <button {...props}>
                  <img src={Apple} alt="apple authorization" />
                </button>
              )}
            />
          )}
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <img className="google" src={Google} alt="google icon" />
              </button>
            )}
            buttonText="Login"
            onSuccess={(data) =>
              socialLogin({
                social_type: 'google',
                access_token: data.accessToken,
              })
            }
            onFailure={(data) => console.log('goog data onFailure', data)}
            cookiePolicy={'single_host_origin'}
          />
            <button onClick={() => twitterLogin()}>
              <img className="twiter" src={Twiter} alt="twiter icon" />
            </button>
        </div>
          {loginErrorNotification.length > 0 &&
              <div style={{marginBottom:"20px", color:"red"}}>
                  {loginErrorNotification}
              </div>
          }
      </div>
    </div>
  );
};

export default inject('countryModel', 'authModel')(observer(LoginForm));
