import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'mobx-react';
import App from './App';
import { rootModel } from './models';
import { Helmet } from "react-helmet";
import './i18n';
import * as serviceWorker from './serviceWorker';
import './index.css';

import Ascend from './res/img/Ascend.png';

import 'mobx-react-lite/batchingForReactDom';

const models = {
  rootModel: rootModel,
  countryModel: rootModel.countryModel,
  authModel: rootModel.authModel,
  userModel: rootModel.userModel,
  questionsModel: rootModel.questionsModel,
  answersModel: rootModel.answersModel,
  groupsModel: rootModel.groupsModel,
  masterMindsModel: rootModel.masterMindsModel,
  cardsModel: rootModel.cardsModel,
  purchasesModel: rootModel.purchasesModel,
  subscribeModel: rootModel.subscribeModel,
  transactionsModel: rootModel.transactionsModel,
  pageModel: rootModel.pageModel,
  chatsModel: rootModel.chatsModel,
  postsModel: rootModel.postsModel,
  meetingsModel: rootModel.meetingsModel,
  mmContextModel: rootModel.mmContextModel,
  mediaModel: rootModel.mediaModel,
  messagesModel: rootModel.messagesModel,
  enablexModel: rootModel.enablexModel,
  groupSocketModel: rootModel.groupSocketModel,
  tagsModel: rootModel.tagsModel,
  fileModel: rootModel.fileModel,
  inviteMembersModel: rootModel.inviteMembersModel,
  notificationsModel: rootModel.notificationsModel,
  searchModel: rootModel.searchModel,
  fileUploadingModel: rootModel.fileUploadingModel,
  bankAccountsModel: rootModel.bankAccountsModel,
  subscribePackageModel: rootModel.subscribePackageModel,
  communityModel: rootModel.communityModel,
  playlistModel:rootModel.playlistModel,
  LoaderControllerModel : rootModel.LoaderControllerModel,
  HelpersControllerModel: rootModel.HelpersControllerModel,
  supportModel: rootModel.supportModel
};

const title = "Ascend Video - Live Video Communities";
const description = 'Ascend Video allows everyone to join live streams, create collaboration groups, and attend workshops from influencers or interest leaders. Easily connect with like-minded people in any community along with access to mastermind coaches and content that interests you';

ReactDOM.render(
  <Provider {...models}>
    <HttpsRedirect>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" itemProp="image" content={Ascend}/>
        <meta property="og:image:height" content="630" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={window.location.href}/>
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" itemProp="image" content={Ascend}/>
        <meta property="twitter:card" content="summary_large_image"/>
      </Helmet>
      <App />
    </HttpsRedirect>
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
