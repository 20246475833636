import React, { memo } from 'react';
// import LinkImg from '../LinkImg/linkImg';
import AppStore from '../../res/img/AppStore.svg';
import GooglePlay from '../../res/img/GooglePlay.svg';

// import { home } from '../../const/urlKeys';

import './linkStores.scss';

const LinkStores = ({ name }) => {
  return(
    <div className="storesBlock">
      <a href="https://apps.apple.com/ua/app/ascendvideo/id1496739998" target="_blank" rel="noopener noreferrer">
        <img src={AppStore} alt="app-store" className={`${name}-app-store`} />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.doneit.ascend" target="_blank" rel="noopener noreferrer">
        <img src={GooglePlay} alt="google-play" className={`${name}-google-play`} />  
      </a>
      {/* <LinkImg className={`${name}-google-play`} src={GooglePlay} alt="google-play" url={home} /> */}
    </div>
  )
}

export default memo(LinkStores);
