import React, { memo } from 'react';

const AlertIcon = props => {
  const { color = 'white' } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3156 0H4.68444L0 4.68444V11.3156L4.68444 16H11.3156L16 11.3156V4.68444L11.3156 0ZM8 12.7111C7.77145 12.7111 7.54804 12.6433 7.35801 12.5164C7.16798 12.3894 7.01987 12.2089 6.93241 11.9978C6.84494 11.7866 6.82206 11.5543 6.86665 11.3301C6.91124 11.106 7.02129 10.9001 7.1829 10.7385C7.34451 10.5768 7.55041 10.4668 7.77456 10.4222C7.99872 10.3776 8.23106 10.4005 8.44221 10.488C8.65336 10.5754 8.83384 10.7235 8.96081 10.9136C9.08778 11.1036 9.15556 11.327 9.15556 11.5556C9.15556 11.862 9.03381 12.1559 8.8171 12.3727C8.60039 12.5894 8.30647 12.7111 8 12.7111ZM8.88889 8.88889H7.11111V3.55556H8.88889V8.88889Z" fill={color} />
    </svg>
  )
}

export default memo(AlertIcon);
