import { observable, decorate } from 'mobx';
import contries from '../const/counties';
import phoneCodes from '../const/phoneCode';

export class CountryModel {
  constructor() {
		this.codes = [];
    this.names = [];
    this.phonesCode = [];
    this.getCodesError = null;
    this.getNamesError = null;
    this.buildPhinesCode();
    this.getGeoInfo();
    this.currentCode = null;
  }

  getGeoInfo = () => {
    fetch('https://extreme-ip-lookup.com/json/')
    .then( res => res.json())
    .then(response => {
      this.currentCode = phoneCodes[response.countryCode] || '';
    })
    .catch((data, status) => {
        // console.log('Request failed');
    })
  };
  
  buildPhinesCode = () => {
    this.phonesCode = Object.keys(phoneCodes).map(countryCode => ({
      value: phoneCodes[countryCode],
      label: contries[countryCode]
    })).filter(item => item.value).sort(function (a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      // a должно быть равным b
      return 0;
    })
  }
}

decorate(CountryModel, {
	codes: observable,
  names: observable,
  currentCode: observable,
})

export const countryModel = new CountryModel();
