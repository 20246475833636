import React from 'react';
import { inject, observer } from 'mobx-react';
import SelectInvite from './SelectInvite';
import ClearButton from './ClearButton';
import DoneButton from './DoneButton';

import CurrentAvatar from '../CurrentAvatar';
import Dialog from '../Dialog';
import ImgBuilder from '../../res/builders/ImgBuilder';
import { returnName } from '../../units/func';

import './invite.scss';
import {actionDuplicate} from "../../const/general";

const InviteGuests = props => {
  const {
    inviteMembersModel: { setMembers },
    color, onClick, invites, action, setRemovedGuestsList, removedGuestsList
  } = props;

  const renderButton = () => (
    <div className="addBlock">
      <div className="addButton">
        <img src={ImgBuilder.plus} />
      </div>
      <div className="addTitle">Add<br />Guests</div>
    </div>
  );

  const removeInvite = (user) => {
    setRemovedGuestsList([...removedGuestsList, user])
    const filteredArray = invites.filter(member => member.email != user.email);
    onClick([...filteredArray]);
  }

  return (
    <div className="inviteMembersBlock">
      <div className="title" style = {{color: color, marginTop: '15px'}}>Invite Guest Speakers</div>
      <div className="membersList">
        <Dialog 
          openButton={renderButton()}
          className="withdrawDialog"
          key='dialog'
          onClick={() => setMembers(null)}
          onClose = {() => setMembers(null)}
        >
        <SelectInvite onClick={onClick} invites={invites} />
        </Dialog>
        {invites && invites.map(user => {
          const userName = returnName(user);
          return (
            <div className="imviteUser" key={user.email} >
              {user.participant && action !== actionDuplicate? <DoneButton/> : <ClearButton className="delete" onClick={() => removeInvite(user)}/>}
              <CurrentAvatar src={user?.image?.url} alt={userName} className="userAvatar"/>
              <div className="name">{userName ? userName : user.email}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default inject('inviteMembersModel')(observer(InviteGuests));
