import React from 'react';
import { inject, observer } from 'mobx-react';
import WeekDays from '../../../../components/UI/WeekDays';

const WeekDayFilter = props => {
  const {
    groupsModel: {
      state: { filters },
      changeFilterWdays
    },
  } = props;

  return <WeekDays className='wdayButton' onClick={changeFilterWdays} activeDays={filters.wdays} />
}

export default inject('groupsModel')(observer(WeekDayFilter));
