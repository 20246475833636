import React, { memo } from 'react';

const VisaIcon = props => {
  // const { color = '#43425D' } = props;

  return (
    <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.80995 0.244812L6.60544 7.74035H8.53069L9.73446 0.244812H7.80995ZM13.6172 3.29807C12.9444 2.96583 12.532 2.7416 12.532 2.40179C12.5402 2.09283 12.8808 1.7763 13.6412 1.7763C14.2667 1.76058 14.7265 1.90758 15.0752 2.05384L15.25 2.13182L15.5117 0.563563C15.1314 0.416567 14.5285 0.2546 13.7837 0.2546C11.8825 0.2546 10.5437 1.2431 10.5354 2.65687C10.5197 3.70015 11.4939 4.27909 12.2229 4.62638C12.9677 4.98264 13.2212 5.21362 13.2212 5.53015C13.213 6.01613 12.619 6.24037 12.0648 6.24037C11.2968 6.24037 10.8843 6.12488 10.258 5.85409L10.0045 5.73859L9.73528 7.36838C10.1875 7.56942 11.0193 7.74635 11.8826 7.7554C13.9031 7.7554 15.2178 6.78188 15.2343 5.27515C15.2409 4.44862 14.7272 3.81556 13.6172 3.29807ZM20.4474 0.268009H18.9579C18.4989 0.268009 18.1501 0.400033 17.9514 0.871044L15.0916 7.74035H17.1121L17.6686 6.25312H19.9291L20.2179 7.74635H21.9999L20.4474 0.268009ZM18.2289 4.75224C18.2679 4.75602 19.0045 2.32447 19.0045 2.32447L19.591 4.75224C19.591 4.75224 18.6092 4.75224 18.2289 4.75224ZM4.99671 0.244812L3.11045 5.33734L2.90497 4.33305C2.55619 3.17427 1.46273 1.91507 0.242432 1.28957L1.96969 7.73286H4.00673L7.03376 0.245634H4.99671V0.244812Z" fill="#2394BC" />
    </svg>
  )
}

export default memo(VisaIcon);
