import React from 'react';
import moment from 'moment';

import './owner.scss';

const EvenetDateTime = props => {
  const { start_time } = props;

  const currentDate = moment(start_time);
  
  return (
    <div className="dateTime">
      <div className="date">{currentDate.format("DD MMM")}</div>
      <div className="time">{currentDate.format("h:mm a")}</div>
    </div>
  )
}

export default EvenetDateTime;
