import Amplify, { Auth, Storage } from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:16288c3c-c8eb-4c4f-8219-a38737f3438e',
    region: 'us-east-1',
  },
  Storage: {
    AWSS3: {
      bucket: 'bucket-ascend',
      region: 'us-east-1',
    }
  }
});

export async function handleAWSS3FileUpload(key, file, onProgress = _ => {}){
  let getFileUrl =  Storage.put(key, file, {
    progressCallback(progress) {
      onProgress(progress);
    }
  }).then(r => Storage.get(r.key));
  let result = await getFileUrl;
  return result;
}