import React from "react";
import {findTiersTitleByName} from "../../units/func";
import logo from '../../res/img/Ascend.png'
import './styles.scss'

const ViewSubscriptionBtn = (props) => {
  const {group, openSubscriptions = () => {}} = props
  const type = group.group_type || group.record_type

  return (
    <div className={'subscribeBtn'} onClick={openSubscriptions}>
      <div className="subscribeBtn_wrapper">
        <div className={'subscribeBtn_icon'}>
          <img src={logo} alt="Ascend logo"/>
        </div>
        <div className={'subscribeBtn_content'}>
          <div className={'subscribeBtn_subText'}>this {findTiersTitleByName(type).toLowerCase()} is available for subscribers.</div>
          <div className={'subscribeBtn_mainText'}>View Subscriptions →</div>
        </div>
      </div>
    </div>
  )

}

export default ViewSubscriptionBtn