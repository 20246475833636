import React, { useEffect, useState } from 'react';
import ImgBuilder from '../../res/builders/ImgBuilder';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Input from '../Input';
import { FieldArray } from 'formik';

import LinearProgress from '@material-ui/core/LinearProgress';

import { video_record, meditation, podcast } from '../../const/general';
import audioBack from '../../res/img/audioBack.svg';

import './style.scss';

const UploadVideos = (props) => {
  const { files, setFiles, loadProgress, error, setError, isValid, record_type } = props;
  const [lastVideo, setLastVideo] = useState(null);

  useEffect(() => {
    videosTotalSizeIsValid();
    if (!!lastVideo) {
      setVideoName(lastVideo, '');
      setLastVideo(null);
    }
  }, [files]);

  const removeFile = (video) => {
    setFiles(files.filter((item) => item.id !== video.id));
    videosTotalSizeIsValid();
  };

  const videosTotalSizeIsValid = () => {
    let total = 0;
    for (let i = 0; i < files.length; i++) {
      total = total + files[i].file.size;
    }
    if (total.toString().length > 10) {
      setError('Video total size must be no larger than 10 GB');
    } else {
      setError('');
    }
  };

  const vaidationName = (name) => {
    const noSpaceReg = /^[^\s]((.*)[^\s])?$/;

    if(!noSpaceReg.test(name)) return 'Please, enter Group Name without whitespase by start and end of line';
    if(name.length < 2 || name.length > 32) return 'Please, enter the Group Name correctly. (minimum is 2 and maximum is 64 characters)';
  };

  const saveFile = (e) => {
    const file = e.target.files[0];
    let isFileType = null;

    const URLSplit = file?.name.split('.');
    const isAVI = URLSplit[URLSplit.length - 1] === 'avi';
    const isWav = URLSplit[URLSplit.length - 1] === ('wav' || 'wave');
    const isVideo = file?.type?.includes('video') && !isAVI;
    const isAutio = file?.type?.includes('audio');
//debugger;

    switch (record_type) {
      case video_record:
        isFileType = isVideo;
        break;

      case meditation:
        isFileType = isVideo || isAutio;
        break;

      case podcast:
        isFileType = isVideo || isAutio;
        break;

      default:
        break;
    }

    if (isAVI) {
      setError('Plase chose other files type (.mp4, .mov)');
    }

    if (isWav) {
      setError('Plase chose other files type (.mp3, .alac, .flac, .m4a)');
    }

    if (isFileType) {
      e.target.value = ''; //reset input
      if (!files.some((item) => item.file.size === file.size)) {
        //disable adding same videos
        let arr = [...files];
        const video = { file, id: new Date() };
        arr.push(video);
        setFiles(arr);
        setLastVideo(video);
      }
    }
  };

  const setMetadata = (video, e) => {
    const duration = parseInt(e.target.duration == 'Infinity' ? 0.01 : e.target.duration);
    // const videoName = video.file.name.substr(0, video.file.name.lastIndexOf('.'));
    setFiles(
      files.map((item) => {
        return item.file.size === video.file.size
          ? { ...item, meta: { duration, name: '', error: false } }
          : item;
      })
    );
  };

  const setVideoName = (video, name) => {
    setFiles(
      files.map((item) => {
        return item.file.size === video.file.size
          ? { ...item, meta: { name, duration: item.meta?.duration || 0, error: true } }
          : item;
      })
    );
  };

  const formatDuration = (video) => {
    return moment
      .utc(moment.duration(video?.meta?.duration, 'seconds').asMilliseconds())
      .format('HH:mm:ss');
  };

  const addVideoTitle = () => {
    switch (record_type) {
      case video_record:
        return 'Add Video';

      case meditation:
        return (
          <div>
            <div>Add Audio or Video</div>Content
          </div>
        ); // 'Purchased/Saved'

      case podcast:
        return (
          <div>
            <div>Add Audio or Video</div>Content
          </div>
        ); // 'Purchased/Saved'

      default:
        break;
    }
  };

  const acceptData = () => {
    switch (record_type) {
      case video_record:
        return '.mp4, .mov'; // .avi,

      case meditation:
        return '.mp4, .mov, .mp3, .alac, .flac, .m4a';

      case podcast:
        return '.mp4, .mov, .mp3, .alac, .flac, .m4a';

      default:
        break;
    }
  };

  const addVideo = (
    <div className="upload_video_item">
      <input
        className="upload-video-file"
        type="file"
        name="file"
        accept={acceptData()}
        onChange={saveFile}
      />
      <div className="drop_zone">
        <div className="upload_image_content">
          <img src={ImgBuilder.plus} alt="plus" />
          <div className="text">{addVideoTitle()}</div>
          <div className="accept_data">{acceptData()}</div>
        </div>
      </div>
    </div>
  );

  const deleteIcon = (item) =>
    loadProgress && isValid ? (
      ''
    ) : (
      <IconButton onClick={() => removeFile(item)}>
        <img src={ImgBuilder.deleteImg} alt="cancelLoading" className="" />
      </IconButton>
    );

  const videoMetaData = (item) => (
    <div className="video_uploading_meta_data">
      {loadProgress && isValid ? (
        <>
          <div className="loading">
            <div className="">Uploading</div>
            <div className="">{loadProgress}%</div>
          </div>
          <LinearProgress variant="determinate" value={loadProgress} />
        </>
      ) : (
        <FieldArray
          name="friends"
          render={(arrayHelpers) => {

            return (
              <>
                <Input
                  value={item.meta?.name}
                  onChange={(e) => setVideoName(item, e.target.value)}
                  className="videoName"
                  error={
                    item.meta?.error && vaidationName(item.meta.name)
                  }
                  placeholder="Add name"
                />
                <div className="error">
                  {item.meta && vaidationName(item.meta.name)}
                </div>
              </>
            );
          }}
        />
      )}
    </div>
  );

  return (
    <div className="upload_video">
      {files.map((item) => {
        return (
          <div className="upload_video_item uploaded" key={item.id}>
            <div className="bg-top-gradient"></div>
            <div className="top-block">
              <div className="time">{formatDuration(item)}</div>
              {deleteIcon(item)}
            </div>
            <video
              className="uploaded_video_item_preview"
              onLoadedMetadata={(e) => {
                setMetadata(item, e);
              }}
              style={{ backgroundImage: `url(${audioBack})` }}
            >
              <source src={URL.createObjectURL(item.file)} id="video_here" />
            </video>
            {videoMetaData(item)}
          </div>
        );
      })}
      {files.length < 10 && !error ? addVideo : null}
    </div>
  );
};

export default UploadVideos;
