import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import RightBlock from '../Dashboard/Right';
import MainPage from '../MainPage';

import Empty from '../../components/Empty';
import FilterContent from '../../components/FilterContent';
import VideosGroups from './videos';

// import VideosFolderIcon from '../../res/iconComp/VideosFolderIcons';

import { video_record, meditation, podcast } from '../../const/general'; // createdByMe

import './videos.scss';
import LoadingBlock from "../../components/LoadingBlock";

const VideosPage = props => {
  const {
    groupsModel: { changeResultMessage },
    userModel: { user },
    masterMindsModel: { randomItems },
    mediaModel: { mediaGroups, takeSingleRecord, selectRecord, mediaGroupsIsLoading, mediaGroupsFirstLoading }, // filteredMediaGroups
    record_type,
    match
  } = props;

  useEffect(() => {
    changeResultMessage('');
    randomItems(2);
    if (match?.params?.id && selectRecord?.id != match?.params?.id) {
      takeSingleRecord(match?.params?.id);
    }
  });

  const renderTitle = () => {
    switch (record_type) {
      case video_record:
        return 'Videos'

      case meditation:
        return 'Meditations'

      case podcast:
        return 'Podcasts'

      default:
        break;
    }
  }

  return (
    <MainPage key={user?.id}>
      <div className="dashboardBlock">
        <div className="dailyBlock">
          {(mediaGroups && user?.role !== undefined) ?
            <FilterContent
              userId={user?.id}
              role={user?.role}
              type={record_type}
              title={renderTitle()}
              data={mediaGroups}
              exeptions={selectRecord}
            >
              <VideosGroups
                id={parseInt(match?.params?.id)}
                key="daily"
                title={renderTitle()}
                match={match}
                isLoading={mediaGroupsIsLoading || mediaGroupsFirstLoading}
              />
            </FilterContent>
            :
            (mediaGroupsIsLoading || mediaGroupsFirstLoading)?
              <LoadingBlock/>
              :
              <Empty />}
        </div>
      </div>
      {/*<RightBlock />*/}
      {/* <Chat /> */}
    </MainPage>
  )
}

export default inject('groupsModel', 'masterMindsModel', 'userModel', 'mediaModel')(observer(VideosPage));
