import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Button from '../../Button';

import './style.scss';

const weeks = [0, 1, 2, 3, 4, 5, 6];

const months = [];

for (let i = 1; i < 13; i++) {
  months.push({ value: i, label: moment(i, 'M').format('MMMM')})
}

const years = [
  moment().format('YYYY'),
  moment().add(1, 'years').format('YYYY'),
  moment().add(2, 'years').format('YYYY'),
  moment().add(3, 'years').format('YYYY'),
];

const generalFormat = 'DD/M/YYYY';

const CustomCalendar = props => {
  const {
    title = "Start Date",
    currentDay = moment(),
    // date = moment().format(generalFormat),
    onSelect,
    close,
    value,
  } = props;

  const dateMoment = value ? moment(value, generalFormat) : moment();
  const mValue = dateMoment.format('M');
  const mYear = dateMoment.format('YYYY');
  const mDay = dateMoment.format('DD');

  const [mounth, setMounth] = useState(mValue);
  const [year, setYear] = useState(mYear);
  const [day, setDay] = useState(mDay);

  useEffect(() => {
    const dateMoment = value ? moment(value, generalFormat) : moment();
    const mValue = dateMoment.format('M');
    const mYear = dateMoment.format('YYYY');
    const mDay = dateMoment.format('DD');

    customSetMounth(mValue);
    setYear(mYear);
    setDay(mDay);
  }, [value]);

  const customSetMounth = value => {
    const changeCount = value - mounth;
    let newDate;
    if (changeCount > 0) {
      newDate = moment(`${day}/${mounth}/${year}`, generalFormat).add(changeCount, 'month')
    } else {
      newDate = moment(`${day}/${mounth}/${year}`, generalFormat).subtract(Math.abs(changeCount), 'month')
    }

    setDay(newDate.format('DD'))
    setMounth(newDate.format('M'));
  }

  let prevMounth = mounth - 1;
  let prevYear = year;
  if (prevMounth < 0) {
    prevMounth = 12;
    prevYear = prevYear - 1;
  }

  const newDays = () => {
    let days = [];
    const prewDeys = moment(`01/${mounth}/${year}`, generalFormat).day();

    for (let i = 0; i < prewDeys; i++) {
      days.unshift({ value: '', active: false });
    }

    for (let i = 1; i <= moment(`${mounth}/${year}`, 'MM/YYYY').daysInMonth(); i++) {
      days.push({ value: i, active: true })
    }

    return days;
  }

  const handleSubmit = () => {
    onSelect(`${day}/${mounth}/${year}`);
    close();
  }

  return (
    <div className={classNames("calendarBlock", "sheduleSelectBlock")}>
      <div className="title">{title}</div>
      <div className={classNames("selectsBlock", 'calendarSelectsBlock')}>
        <FormControl variant="outlined" className="selectItem">
          {/* <InputLabel id="month">Month</InputLabel> */}
          <Select
            labelId="month"
            id="month-select"
            value={mounth}
            onChange={(data) => customSetMounth(data.target.value)}
            defaultValue={mounth}
            // label="Month"
          >
            {months.map(item => (
              <MenuItem value={item.value} key={item.value} selected={item.value == mounth}>{item.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="selectItem">
          {/* <InputLabel id="year">Year</InputLabel> */}
          <Select
            labelId="year"
            id="year-select"
            value={year}
            onChange={(data) => setYear(data.target.value)}
            defaultValue={year}
            // label="Year"
          >
            {years.map(item => (
              <MenuItem value={item} key={item} selected={item == year}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="daysBlock">
        <div className="daysBlock">
          {weeks.map(item => (
            <div className="nameItem" key={item}>
              {moment().isoWeekday(item).format('ddd').toUpperCase()}
            </div>
          ))}
        </div>
        <div className="days">
          {newDays().map((item, i) => {
            const currentDate = moment(`${item.value}/${mounth}/${year}`, generalFormat);
            const disable = currentDate.isBefore(currentDay, 'day') || !item.active;

            return (
              <div
                className={classNames("dayItem", { active: item.value == day && !disable, disable })}
                key={`${year}/${mounth}/${i}`}
                onClick={() => !disable && setDay(item.value)}
              >
                {item.value}
              </div>
            )}
          )}
        </div>
      </div>
      <Button
        violetGradien width100
        label="OK"
        onClick={handleSubmit}
        disabled={moment(`${day}/${mounth}/${year}`, generalFormat).isBefore(currentDay, 'day')}
      />
    </div>
  )
}

export default CustomCalendar;
