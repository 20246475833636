import React, { cloneElement } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Field } from 'formik';

const CustomField = props => {
  const { name, children, close, onSelect } = props

  return (
    <Field name={name}>
      {({
          field: { name, value, onChange },
          form: { errors },
          meta,
        }) => {
          return(
            <FormControl error={meta.touched && !!errors[name]}>
              {children && cloneElement(children, { onChange, value: value, close, onSelect })}
            </FormControl>
          )
        }
      }
    </Field>
  );
};

export default CustomField;
