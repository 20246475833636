import React from 'react';
import InputRange from 'react-input-range';
import ContentVideoPlayerPlaylist from '../Playlist';

import fullIcon from '../../../res/icons/full_screen.svg';
import volumeIcon from '../../../res/icons/volume.svg';
import muteIcon from '../../../res/icons/mute.svg';
import normalizeIcon from '../../../res/icons/normalsize.svg';
import playlistIcon from '../../../res/icons/playlist.svg';
import playPrevIcon from '../../../res/icons/play_prev_ic.svg';
import playNextIcon from '../../../res/icons/play_next_ic.svg';

import 'react-input-range/lib/css/index.css';
import './style.scss';

const ContentVideoPlayerControls = (props) => {
  const { 
    videoRef,
    tooglePlay,
    isPlaying,
    rewindProgress,
    progress,
    volumeHovered,
    time,
    setVolumeHovered,
    toggleMute,
    toggleDisabledMouseLeaveEvent,
    disabledOnMouseLeaveEvent,
    changeVolume,
    toggleFullscreen,
    isMuted,
    isFullScreen,
    toogleShowPlaylist,
    showPlaylist,
    playNext,
    playPrevious,
    isPlaylist,
  } = props;

  const renderPlay = () => {
    return (
      <div className="play_button_controls">
        {isPlaylist && <img src={playPrevIcon} onClick={playPrevious} />}
        <button
          onClick={tooglePlay}
          className={`btn_play_pause ${isPlaying && 'paused'}`}
        />
        {isPlaylist && <img src={playNextIcon} onClick={playNext} />}
      </div>
    );
  };

  const renderProgress = () => {
    return (
      <InputRange
        maxValue={100}
        minValue={0}
        value={progress}
        onChange={(value) => rewindProgress(value)}
      />
    );
  };

  const renderTime = () => {
    if (volumeHovered) return null;
    return <div className="time">{time}</div>;
  };

  const renderVolume = () => {
    const videoVolume = videoRef.current ? videoRef.current.volume * 100 : 0;
    return (
      <div
        className="volume"
        onMouseEnter={() => setVolumeHovered(true)}
        onMouseDown={() => toggleDisabledMouseLeaveEvent(true)}
        onMouseUp={() => toggleDisabledMouseLeaveEvent(false)}
      >
        <img
          onClick={toggleMute}
          src={isMuted ? muteIcon : volumeIcon}
          className="btn_volume"
        />
        <div
          className={`volume_level-progress ${
            volumeHovered ? '_show' : '_hide'
          }`}
        >
          <InputRange
            maxValue={100}
            minValue={0}
            value={videoVolume}
            onChange={(value) => changeVolume(value)}
          />
        </div>
      </div>
    );
  };

  const renderFullScreen = () => {
    const config = {
      src: isFullScreen ? normalizeIcon : fullIcon,
      onClick: toggleFullscreen,
      alt: 'full_screen',
      className: 'btn_full_screen',
    };
    return <img {...config} />;
  };

  const renderPlaylistIcon = () => {
    const config = {
      src: playlistIcon,
      onClick: (e) => {
        toogleShowPlaylist((p) => !p);
        e.stopPropagation();
      },
      alt: 'play_list',
      className: 'btn_playlist',
    };
    return <img {...config} />;
  };

  const renderPlaylist = () => {
    return (
      <ContentVideoPlayerPlaylist
        handleClose={() => toogleShowPlaylist(false)}
        showPlaylist={showPlaylist}
      />
    );
  };

  return (
    <div className={`controls_wrapper ${isFullScreen && 'full_screen'}`}>
      <div className="controls">
        {renderPlay()}
        {renderProgress()}
        <div
          className="controls_time_volume"
          onMouseLeave={() =>
            disabledOnMouseLeaveEvent ? null : setVolumeHovered(false)
          }
        >
          {renderTime()}
          {renderVolume()}
        </div>

        {renderFullScreen()}
        {renderPlaylistIcon()}
        {renderPlaylist()}
      </div>
    </div>
  );
};

export default ContentVideoPlayerControls;
