import React from 'react';
import Select from '@material-ui/core/Select';
import words from 'lodash/words';

import ChangeIcon from '../../res/icons/change.svg';

import './datePicker.scss';

const TimePicker = props => {
  const { onClick, name, close, value } = props;

  const elements = words(value);
  const state = {
    hours: elements[0] || '',
    minute: elements[1] || '',
    meridiem: elements[2] === 'pm'  ? 'pm' : 'am'
  };

  const handleChange = (event) => {
    const nameElm = event.target.name;
    const newTime = {
      ...state,
      [nameElm]: event.target.value,
    }

    onClick(`${newTime.hours}:${newTime.minute} ${newTime.meridiem}`);
    close && close();
  }

  const handleChangeMeridiem = () => {
    let newMeridiem = 'pm';
    if (state.meridiem === 'pm' ) {
      newMeridiem = 'am';
    }
    onClick(`${state.hours}:${state.minute} ${newMeridiem}`);
    close && close();
  }

  const hours = [];
  const minutes = [];
  for (let i = 0; i < 60; i++) {
    if (i < 12) {
      hours.push((i+1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}));
    }
    minutes.push(i.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}));
  }

  return (
    <div className="datePicker">
      <Select
        native
        value={state.hours}
        onChange={handleChange}
        inputProps={{
          name: 'hours',
          id: 'hourse',
        }}
      >
        {hours.map(value => <option value={value} key={value}>{value}</option>)}
      </Select>
      <span>:</span>
      <Select
        native
        value={state.minute}
        onChange={handleChange}
        inputProps={{
          name: 'minute',
          id: 'minute',
        }}
      >
        {minutes.map(value => <option value={value} key={value}>{value}</option>)}
      </Select>
      <span />
      <button onClick={handleChangeMeridiem}>{state.meridiem.toUpperCase()} <img src={ChangeIcon} alt="change" /></button>
    </div>
  )
}

export default TimePicker;
