import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {gtRecurringWebinar, gtWebinar} from '../../const/general';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      height: 75,
    },
    right: '5%',
    width: '110%!important',
    position: 'relative',
    padding: '0px 20%',
    background: '#FF2D55',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      padding: '0px 25px !important'
    },
  },
  text: {
    fontSize: 15,
    color: '#fff',
    padding: 15,
    fontWeight: 300,
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
  }
}));

const BannedMessage = ({ item }) => {
  const classes = useStyles();
  let title;
  switch (item.group_type) {
    case gtWebinar:
    case gtRecurringWebinar:
      title = 'livestream'
      break;
    default:
      title = 'group'
      break;
  }
  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.text}>
          You have been banned by the {title} organizer and cannot join.
        </Typography>
      </div>
    </>
  )
}

export default BannedMessage