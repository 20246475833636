import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import DatePicker from '../../../components/DatePicker';
import PaymentItem from './PaymentItem';

import TransactionDetails from './TrasactionDetails';
import Dialog from '../../../components/Dialog';

import './payments.scss';

const PaymentBlock = props => {
  const { 
    purchasesModel: { filteredPurchases, setFilter, state: { filter: { start, end } } },
    userModel: { user },
    groupsModel: { lastGroup, getSingleGroup, getGroupError, setLastGroup }
  } = props;

  const [details, setDetails] = useState(null);
  const [group, setGroup] = useState(null);

  useEffect(() => {
    return () => {
      setLastGroup(null)
    }
  },[])

  useEffect(() => {
    if (details && details.group_id && lastGroup?.id !== details.group_id) {
      setGroup(getSingleGroup(details.group_id));
    }
  }, [details, lastGroup]);

  const handleChangeStart = data => setFilter('start', data);
  const handleChangeEnd = data => setFilter('end', data);

  const currentGroup = group ? group : lastGroup;
  const item = { ...details, user, group: currentGroup };
  
  return (
  <>
    <div className="filter">
      <DatePicker
        label="Start date"
        onChange={handleChangeStart}
        date={start.toDate()}
        maxDate={end.toDate()}
        className="paymentDatePicker"
        key="start"
        onInput={value => handleChangeStart(value)}
      />
      <DatePicker
        label="End date"
        onChange={handleChangeEnd}
        date={end.toDate()}
        minDate={start.toDate()}
        maxDate={new Date()}
        className="paymentDatePicker"
        key="end"
        onInput = {value => handleChangeEnd(value)}
      />
    </div>
    {filteredPurchases && filteredPurchases.map(pay => {
      return <PaymentItem item={pay} key={pay.id} className="purchases" onClick={() => {
        setDetails({...pay, type: 'PAYMENT' })
      }} />
    })}
    <Dialog 
      className="transactionDetailsDialog"
      onClose={() => setDetails(null)}
      defaultOpen={!!details}
    >
      <TransactionDetails item={item} groupError={getGroupError} />
    </Dialog>
  </>
  );
}

export default inject('purchasesModel', 'userModel', 'groupsModel')(observer(PaymentBlock));
