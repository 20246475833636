import React, { cloneElement } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
  }
  return stripePromise;
}

const ConectStripe = props => {
  const { children, ...rest } = props;
  return (
    <Elements stripe={getStripe()}>
      {cloneElement(children, { ...rest })}
    </Elements>
  )
}

export default ConectStripe;

