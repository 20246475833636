import {Redirect, useHistory} from "react-router-dom";
import {home, stripeVerify} from "../../const/urlKeys";
import {inject, observer} from "mobx-react";
import React from "react";
import {checkIsUserVerifyMM} from "../../units/func";

function VariableRedirect({authModel, userModel, HelpersControllerModel, communityModel, computedMatch}) {
  const {params: {id}} = computedMatch;
  const {session_token} = authModel;
  const {user} = userModel;
  const { communityList, setCommunityAsCurrent } = communityModel;
  const history = useHistory()
  const switchCommunity = (currentId, path) => {
    currentId && id !== currentId && setCommunityAsCurrent({id})
    return path
  }
  const getPath = (userData, communityListData) => (!Object.keys(userData).length ||  checkIsUserVerifyMM(userData, communityListData, id))?
    switchCommunity(userData.community_id,{pathname: home}):
    switchCommunity(userData.community_id, {pathname: stripeVerify})

  HelpersControllerModel.setRedirectPathCb((userData, communityListData) => {
    history.push(getPath(userData, communityListData))
  });
  if (!session_token) {
    return <Redirect to={home}/>
  }
  return <Redirect to={getPath(user, communityList)}/>
}

export default inject('authModel', 'userModel', 'HelpersControllerModel', 'communityModel')(observer(VariableRedirect));
