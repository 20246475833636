import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import ContentVideoPlayer from "../ContentVideoPlayer";
import ContentRecomendations from "../ContentRecomendations";
import { toggleHelpButton } from "../../units/func";
import Vimeo from "@u-wave/react-vimeo";

import "./style.scss";
/*window.addEventListener('contextmenu', function (e) { 
  // do something here... 
  e.preventDefault(); 
}, false);*/




const VideoPlayer = (props) => {
  const {
    record_info,
    owner,
    playlist,
    playlistModel: {
      recomendations,
      getRecomendationsData,
      setCurrentMedia,
      currentMedia,
      getMyPlaylists,
    },
    meetingsModel: { getVideoUrlData }
  } = props;
  const videoRef = useRef(null);

  function preventDef(event) {
    event.preventDefault();
  }
  
  useEffect(()=>{
    videoRef.current && videoRef.current.addEventListener("contextmenu", preventDef, false);
    getVideoUrlData(record_info.id);
  },[currentMedia])



  const [activeTab, setActiveTab] = useState(playlist ? "playlist" : "recomended");
  const [showPlaylist, updateShowPlaylist] = useState(false);
  const isVimeo = currentMedia?.url?.includes("vimeo");
  useEffect((_) => {
    toggleHelpButton(true);
    setCurrentMedia({ ...record_info, owner: owner });
    getRecomendationsData(owner?.id);
    getMyPlaylists();
    return (_) => toggleHelpButton(false);
    
  }, []);
  
  /*useEffect(()=>{
    videoRef && window.addEventListener('contextmenu', function (e) { 
      e.preventDefault(); 
    },false);
  }, [videoRef]);*/

  const handlePlayNext = () => {
    const indexOfCurrent = playlist.items.findIndex(
      (i) => i.id === currentMedia.id
    );
    if (indexOfCurrent < 0) return;
    if (indexOfCurrent === playlist.items.length - 1) {
      setCurrentMedia({ ...playlist.items[0] });
      return;
    }
    setCurrentMedia(playlist.items[indexOfCurrent + 1]);
  };

  const handlePlayPrevious = () => {
    const indexOfCurrent = playlist.items.findIndex(
      (i) => i.id === currentMedia.id
    );
    if (indexOfCurrent < 0) return;
    if (indexOfCurrent === 0) {
      setCurrentMedia({ ...playlist.items[playlist.items.length - 1] });
      return;
    }
    setCurrentMedia(playlist.items[indexOfCurrent - 1]);
  };

  const player = () => {
    if (isVimeo) {
      return <Vimeo className="player-vimeo" video={currentMedia?.url} controls autoplay />
    } else {
      return (
          <ContentVideoPlayer
            isPlaylist={playlist && activeTab === "playlist"}
            handlePlayNext={handlePlayNext}
            handlePlayPrevious={handlePlayPrevious}
            record_info={currentMedia}
            updateShowPlaylist={updateShowPlaylist}
            showPlaylist={showPlaylist}
          />
      );
    }
  };

  if (!currentMedia) {
    return null;
  }

  return (
    <div className="video_player_page" 
         onClick={() => updateShowPlaylist((prev) => prev && false)}
         ref = {videoRef}
    >
       <div className="player">
        {player()}
       </div>
      <div className="recomendations" >
        <ContentRecomendations
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          playlist={playlist}
          handleRecomendationItemClick={setCurrentMedia}
          recomendations={recomendations}
          currentMediaId = {currentMedia.id}
        />
      </div>
    </div>
  );
};

export default inject("playlistModel", "meetingsModel")(observer(VideoPlayer));
