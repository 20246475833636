import React, { useState, useEffect} from "react";
import { getPlaylistMeta } from '../../../units/func';
import "./style.scss";


const items = [
  {label: "Playlist", key: "playlist"},
  {label: "Recommended", key: "recomended"}
]


const PlaylistRecomendedHeader = (props) => {
  const { active, setActive, playlist } = props;

  function getActiveClass(item){
    return item === active ? 'active' : '';
  }
 
  return (
          <div className = {`playlist-recomended_header`}>
           <div className = {`playlist-recomended_header_nav_items`}>
            {items.map((i,k) => (
                <div onClick = {() => setActive(i.key)} className = {`playlist-recomended_header_item ${getActiveClass(i.key)}`}>
                  {i.label}
                </div>
              ))}
           </div>
            
            {active === "playlist" && (
              <div className="playlist-recomended_header_playlist--info">
                <div className="playlist-recomended_header_playlist--info_title">{playlist.title}</div>
                <div className="playlist-recomended_header_playlist--info_meta">{getPlaylistMeta(playlist.items)}</div>
              </div>
            )}
          </div>
  );
};

export default PlaylistRecomendedHeader;
