import React, { memo } from 'react';

import Badge from '@material-ui/core/Badge';

import './badgeButton.scss';

const BadgeButton = props => { 
  const { src, onClick, alt, invisible = false } = props;
  return(
    <button onClick={onClick} className="badgeButton">
      <Badge color="secondary" variant="dot" invisible={invisible}>
        <img alt={alt} src={src} />
      </Badge>
    </button>
  )
}

export default memo(BadgeButton);
