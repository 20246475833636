import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import HeadRoom from 'react-headroom';
import { inject, observer } from 'mobx-react';
import { stack as MenuStack } from 'react-burger-menu';

import ButtonBase from '@material-ui/core/ButtonBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';

import BadgeButton from '../BadgeButton';
import MainMenu from '../../pages/MainPage/Components/MainMenu';
import LinkImg from '../LinkImg/linkImg';
import CurrentAvatar from '../CurrentAvatar';

import SearchHeader from '../../components/SearchHeader';

import Logo from '../../res/img/logo_logined.png';
import ChatIcon from '../../res/icons/chat-header.svg';
import NitificationIcon from '../../res/icons/notification-header.svg';
import CloseRoundIcon from '../../res/iconComp/CloseRound';
import CommunityIcon from '../../res/iconComp/CommunityIcon';

import {
  home,
  profile,
  livestreams,
  collaborations,
  workshops,
  notifications,
  chooseCommunity
} from '../../const/urlKeys';
import { disableBodyScroll, returnName } from '../../units/func';

import './Header.scss';

const maxlimit = 20;

const LoginHeader = props => {
  const history = useHistory();
  const { location, push } = history;
  const { 
    user, updatecommunity, community,
    notificationsModel: { unreadCount },
    communityModel: {getCommunityNameById, communityList}
  } = props;


  useEffect(() => {
    // enable window scroll by default
    disableBodyScroll(false);
  });

  const isMenuOpen = function(state) {
    disableBodyScroll(state.isOpen);
    return state.isOpen;
  };

  const withCommunity = location.pathname === home || location.pathname === livestreams ||
    location.pathname === collaborations || location.pathname === workshops;

  const renderChangeCommunity = () => {
    return <Link to={chooseCommunity} className="community" style={{width:"auto"}}>
      {/*<CommunityIcon />*/}<span> {getCommunityNameById(community)}</span>
    </Link>
  }

  const returnUser = () => {
    const userName = returnName(user);
    return (
      <>
        {user?.id ? 
          <CurrentAvatar
            alt={userName}
            src={user?.image ? user?.image?.thumbnail?.url : ''}
            className="headerAvatar"
          /> :
          <Skeleton variant="circle" width={50} height={50} style={{ backgroundColor: 'rgba(256, 256, 256, 0.1)' }} />
        }
        {user?.id ? 
          <div className="userName">
            {userName && userName.split(' ').map(text => (
              text.length > maxlimit ? text.substring(0,maxlimit-3) + '... ' : text + ' '
            ))}
          </div> : 
          <div className="userName">
            <Skeleton variant="text" style={{ backgroundColor: 'rgba(256, 256, 256, 0.1)' }} width="100%"/>
          </div>
        }
      </>
    )
  }

  const goTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div className="sticky">
      <HeadRoom>
        <div className="header logined">
          <LinkImg alt="logo" src={Logo} url={home} className="logoMain" onClick={goTop}/>
          {communityList && renderChangeCommunity()}
          <div className={classNames("buttons", { withCommunity: withCommunity })}>
            <SearchHeader />
            <Link to={notifications}>
              <BadgeButton src={NitificationIcon} alt="notification icon" invisible={unreadCount === 0} />
              {(window.innerWidth >= 720 && window.innerWidth < 960)&& 
                <BadgeButton src={ChatIcon} alt="chat icon" invisible={false} />
              }
            </Link>
            <ButtonBase className="avatarActionBlock" onClick={() => push(profile)}>
              {returnUser()}
            </ButtonBase>
          </div>
          <MenuStack
            className="burger"
            right={true}
            width={'100%'}
            onStateChange={ isMenuOpen }
            customCrossIcon={<CloseRoundIcon />}
          >
            <ButtonBase className="userBlock" onClick={() => push(profile)}>
              {returnUser()}
            </ButtonBase>
            <ButtonBase className="communityMobileBlock" onClick={() => push(chooseCommunity)}>
              {communityList && renderChangeCommunity()}
            </ButtonBase>
            <MainMenu />
          </MenuStack>
        </div>   
      </HeadRoom>
    </div>
  );
}

export default inject('notificationsModel', 'communityModel')(observer(LoginHeader));
