import { observable, decorate, action } from 'mobx';
import axios from 'axios';

import { authModel } from './auth';
import { groupsModel } from './groups';
import { baseAPI } from '../const/settings';
import {communityModel} from "./community";
import {userModel} from "./user";
import {checkCommunityIncludeInFilters} from "../units/func";

const getAllCommunitiesForReqeust = () => {
  if (communityModel.communityList.selected.includes(userModel.community)){
    return communityModel.communityList.selected.join(',')
  }
  const all = communityModel.communityList.selected.concat([userModel.community])
  return all.join(',')
}
const timeStep = 300000;

export class MMContextModel {
  constructor() {
    this.mmcontext = [];
    this.userId = [];
    this.filteredMMCotextGroups = [];
  }

  setMMContext = data => {
    this.mmcontext = this.mmcontext.concat(data);

    this.runMMContentFilter();
  };

  setUserId = id => this.userId.push({id, timestamp: new Date()});
  changeUserId = index => this.userId[index].timestamp = new Date();

  removeGroups = userId => {
    const removedMMContext = this.mmcontext.filter(group => group?.owner.id !== userId);
    this.mmcontext = removedMMContext;
  }

  getCommunitieswithdistGroup = group =>{
    let communitiesId = []
    let iterableCommunities = group?.communities ? group?.communities : [];
    if(communityModel?.distribution_groups?.distribution_groups){
      group?.distribution_group && communityModel.distribution_groups.distribution_groups.map(grp => grp.id === group?.distribution_group.id && 
        grp.communities.map(comm => 
          !communitiesId.includes(comm.id) && communitiesId.push(comm.id) &&
          iterableCommunities.push(comm)
        ))
      let copyGroup = group;
      copyGroup.communities = iterableCommunities;
      return copyGroup;
    }else if(group?.communities){
      return group
    }else{
      let copyGroup = group;
      copyGroup.communities = ["none"]
      return copyGroup
    }
  }


  runMMContentFilter = () => {
    const { filters } = groupsModel.state;
    //console.log("this.mmcontext", JSON.parse(JSON.stringify(this.mmcontext)))
    this.filteredMMCotextGroups = this.mmcontext
      .map(group => ({ ...group, timestamp: new Date()}))
      .filter(group => {
        if(filters.communities && filters.communities.length) {
          let modifiedGroups = this.getCommunitieswithdistGroup(group)
          return checkCommunityIncludeInFilters(modifiedGroups, filters.communities)
        } 
        return true
      });
  }

  mmContextGroupsData = (id, session_token = false) => {
    const index = this.userId.findIndex(uid => uid.id === id);

    // if (index === -1 || this.userId[index]?.timestamp + timeStep < new Date()) {
      this.removeGroups(id);
      const setMMContext = this.setMMContext;
      const setUserId = this.setUserId;
      const changeUserId = this.changeUserId;
      const headers = {
        "headers": {
          "accept": "application/json, text/plain, */*",
          "session-token": session_token ? session_token : authModel.session_token,
        },
        params:{
          per_page: 10000,
          user_id: id,
          visited: false,
          records: "all",
          status: "all",
          group_type: "webinar",
          communities_ids: getAllCommunitiesForReqeust()
        }
      };
      
      axios.get(
        `${baseAPI}groups`,
        headers
      )
        .then(response => {
          if (index === -1) {
            setUserId(id);
          } else {
            changeUserId(index);
          }
          setMMContext(response.data.groups);
        })
        .catch(function (error) {
          // handle error
          console.log('mmContextGroupsData error', error)
        })
        .finally();
    // }
  }
}

decorate(MMContextModel, {
  mmcontext: observable,
  filteredMMCotextGroups: observable,
  mmContextGroupsData: action,
})

export const mmContextModel = new MMContextModel();
