import React, {useCallback} from 'react'
import moment from 'moment'
import { useFormikContext } from 'formik';
import ActionIcon from '../ActionIcon';
import Dialog from '../../Dialog';
import InputWithIcon from '../../Input/inputWithIcon';
import CustomInput from '../../Input/customInput';
import CustomField from '../CustomField';
import SheduleSelect from '../../Shedule';

const MultipleSchedules = ({ rest, icon, schedules, setSchedules, onSelect, webinars, setWebinarsArr, editGroup }) => {
  const { values, setFieldValue } = useFormikContext()

  const handleAddSchedule = () => {
    let count = values['meetings_count'];
    setSchedules([...schedules, null]);

    if(count > schedules.length){return}
    setFieldValue('meetings_count', ++count)
    setFieldValue('themes', values['themes'].concat(['']))
  }

    const getPlaceholder = useCallback((scheduleItem) => {
        if(!scheduleItem){
            return undefined;
        }

        if(scheduleItem.initialData.weekly){
            return `RE: Weekly, from ${scheduleItem.startTime.format('hh:mm a')} to ${scheduleItem.endTime.format('hh:mm a')}`;
        }

        if(scheduleItem.initialData.monthly){
            return `RE: Monthly, from ${scheduleItem.startTime.format('hh:mm a')} to ${scheduleItem.endTime.format('hh:mm a')}`;
        }

        return `${moment(scheduleItem.startTime).format('dddd')},  ${scheduleItem.startTime.format('DD MMMM YYYY')}`;
    },[]);

  const handleRemove = (index, id) => {
      if(id){
          setWebinarsArr(array => array.filter(item => item.id !== id));
          setSchedules(schedules.filter((s, key) => key !== index));
      } else {
          setSchedules(data => {
              const array = [...data];
              array.pop();
              return array
          });
      }
  }

  const updateSchedule = (value, key) => {
    setSchedules(schedules.map((i, index) => key === index ? value : i))
  }

  const getRandomArbitrary = (index) => {
      return (Math.random() * (10000 - 1) + 1).toString() + index;
  }

  return schedules.map((item, key) => {
    const startTime = schedules[key]?.startTime;
    const endTime = schedules[key]?.endTime;
    const dateTitle =  `${startTime ? startTime.format('hh:mm a') : ''} - ${endTime  ? endTime.format('hh:mm a') : ''}`;

    return (
      <div style={{ display: 'flex', alignContent: 'center' }} key={key}>
        <Dialog
          openButton={
            <InputWithIcon className={'input_with_icon100'} icon={icon} >
              <CustomInput id={rest.name} value={dateTitle !== ' - ' ? dateTitle : ""} {...rest} customPlaceholder={getPlaceholder(item)} />
            </InputWithIcon>
          }
          className="payment"
          key={rest.name}
        >
                <CustomField name="start_date" key="start_date" onSelect={(value) => {
                    const reccuring = value.weekly || value.monthly;
                    const id = (item && item.id) || getRandomArbitrary(key);

                    if(reccuring){
                        if(value.weekly){
                            value.weekly = value.weekly.map(item => ({...item, id}));
                        }

                        if(value.monthly){
                            value.monthly = value.monthly.map(item => ({...item, id}));
                        }
                    }

                    value.id = id;
                    onSelect(value);
                }}>
                    <SheduleSelect
                        onSelect={(value) => {
                            setFieldValue('schedule', 'Fake data in order to remove error trigger');
                            updateSchedule(value, key);
                        }}
                        webinars={webinars}
                        editGroup={editGroup}
                        scheduleItem={item}
                        selected =  {schedules}
                        oneDayMode = {true}
                        id = {key}
                    />
                </CustomField>
        </Dialog>
        {schedules.length < 7 && (
          <ActionIcon
            type='add'
            offset={{ right: 10 }}
            onClick={handleAddSchedule}
          />
        )}
        {key !== 0  && (
          <ActionIcon
            type='remove'
            offset={{ right: 5 }}
            onClick={() => handleRemove(key, item && item.id)}
          />
        )}
      </div>
    )
  })
}

export default MultipleSchedules