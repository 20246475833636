import React, { useState, cloneElement, useEffect } from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import {useHistory} from 'react-router-dom'

import CloseIcon from '../../res/iconComp/CloseIcon';

import './dialog.scss';

const CustomDialog = props => {
  const {
    children,
    openButton,
    defaultOpen = false,
    className = '',
    closeColor,
    onClose,
    closeIcon = null,
    itemPath,
    refresh = () => {},
    onEntering = () => {},
    disableEscapeKeyDown,
    disableBackdropClick
  } = props;
  const [open, setOpen] = useState(defaultOpen);

  const history = useHistory();
  const path = history?.location?.pathname

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  useEffect(_ => { // костыль, что бы убрать правый паддинг, вычисляемый MaterialUI [AW-1654]
    requestAnimationFrame(() => {
      document.body.style.paddingRight && (document.body.style.paddingRight=null)
    })
  },[open])

  const setDialogPath = _ => itemPath?.forSet &&  history.push(itemPath.forSet)
  const setNativePath = _ => itemPath?.forBack &&  history.replace(itemPath.forBack)

  const handleClickOpen = () => {
    setDialogPath()
    setOpen(true);
  };

  const handleClose = () => {
    setNativePath();
    setOpen(false);
    if (onClose) { onClose() }
  };

  return (
      <>
        {openButton && cloneElement(openButton, { onClick: handleClickOpen })}
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className={classNames('dialogBlock', className)}
            classes={{ container: 'dialogConteiner' }}
            onEntering={onEntering}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
        >
          <DialogContent className="dialogContent">
            <div className="dialodScrollBlock">
              <IconButton aria-label="close" onClick={handleClose} className={classNames("closeButton", { roundIcon: !!closeIcon })}>
                {closeIcon ? closeIcon : <CloseIcon color={closeColor} />}
              </IconButton>
              {children && cloneElement(children, { close: handleClose, refresh: refresh })}
            </div>
          </DialogContent>
        </Dialog>
      </>
  );
}

export default CustomDialog;
