import React, { useEffect, useState, useReducer } from "react";
import loader from "../../../res/icons/default_loader.gif";
import { inject, observer } from 'mobx-react';

import "./style.scss";

const Player = ({
  videoRef,
  videoUrl,
  updateProgress,
  handlePlayerClick,
  handleMediaEnded,
  audioPreview,
  isPlaying,
  isFullScreen,
  meetingRecord,
  videoFromNewContent,
  meetingsModel: { videoUrlData },
  record_info
}) => {
  const [isLoading, setIsLoading] = useState(true);
  let filteredVideoUrl = videoUrl;

  if((record_info.id === videoUrlData.id) && videoUrlData?.url){
     filteredVideoUrl = videoUrlData.url   
  }
  
  const config = {
    id: "video",
    ref: videoRef,
    src: filteredVideoUrl,
    onTimeUpdate: updateProgress,
    onLoadStart: () => setIsLoading(true),
    onCanPlay: () => setIsLoading(false),
    autoPlay: true,
    playsInline: true,
    onEnded: handleMediaEnded,
    poster: audioPreview ? audioPreview : null,
  };

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

 
  return (
    <div className={`player ${isFullScreen ? 'full_screen' : ""}`}  onClick={handlePlayerClick}>
      {(isLoading && isPlaying) && <img src={loader} className = "player_loader" />}
      <video {...config} />
    </div>
  );
};

export default inject('meetingsModel')(observer(Player));
