import React, { memo } from 'react';

import PaymentBlock from './PaymentBlock';
import Subscriptions from './Subscriptions/Subscriptions';

const UserPayments = props => {
  const { viewBlock } = props;

  return (
    <>
      {viewBlock === 'Payments' && <PaymentBlock />}
      {viewBlock === 'Subscriptions' && <Subscriptions />}
    </>
  )
}

export default memo(UserPayments);
