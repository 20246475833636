import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Field } from 'formik';

const PasswordInput = props => {
  const { name = "test", className, placeholder = "test", customBlur, customFocus, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event, length) => {
    event.preventDefault();
  };

  return (
    <Field name={name}>
      {({
          field: { name, value, onChange, onBlur},
          form: { touched, errors, isValid },
          meta,
        }) => {
          return(
            <FormControl error={meta.touched && !!errors[name]} className={className}>
              <InputLabel htmlFor="input-password">{placeholder}</InputLabel>
              <Input
                id="input-password"
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                onBlur={e => { if (customBlur) { customBlur(); } onBlur(e); }}
                onInput={() => { if (customFocus) { customFocus(); } }}
                onFocus={() => { customFocus && customFocus() }}
                name={name}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={e => handleMouseDownPassword(e, value.length)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                {...rest}
              />
              <FormHelperText>{meta.touched && errors[name]}</FormHelperText>
            </FormControl>
          )
        }
      }
    </Field>
  );
};

export default PasswordInput;
