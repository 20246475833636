import React, { memo } from 'react';

const SubscriptionsIcon = props => {
  const { color = '#A8A7BC' } = props;

  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2584 3.02325C8.80407 1.28425 7.36849 0.15397 7.30818 0.106784C6.98047 -0.149551 6.50008 0.0846911 6.50008 0.500521V2.6992C6.50008 3.83454 4.9976 4.23408 4.43453 3.2473C4.25944 2.94125 3.8337 2.909 3.6133 3.17824C2.1002 5.02481 0.750275 7.35307 0.750275 9.75021C0.750275 13.1963 3.55393 16 7.00007 16C10.4462 16 13.2499 13.1963 13.2499 9.75021C13.2499 7.29105 11.8299 4.90262 10.2584 3.02325ZM4.25016 8.00027C4.25016 7.44873 4.69889 7.0003 5.25013 7.0003C5.80136 7.0003 6.25009 7.44873 6.25009 8.00027C6.25009 8.55182 5.80136 9.00024 5.25013 9.00024C4.69889 9.00024 4.25016 8.55182 4.25016 8.00027ZM5.6448 12.307C5.47618 12.5243 5.16194 12.5648 4.94326 12.3948C4.72514 12.2251 4.68608 11.9111 4.85545 11.6933L8.35533 7.19342C8.52502 6.97531 8.83907 6.93624 9.05687 7.10561C9.27499 7.2753 9.31405 7.58935 9.14468 7.80715L5.6448 12.307ZM8.75001 12.5001C8.19878 12.5001 7.75004 12.0517 7.75004 11.5002C7.75004 10.9486 8.19878 10.5002 8.75001 10.5002C9.30124 10.5002 9.74998 10.9486 9.74998 11.5002C9.74998 12.0517 9.30124 12.5001 8.75001 12.5001Z" fill={color}/>
    </svg>

  )
}

export default memo(SubscriptionsIcon);

