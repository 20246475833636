import React, { memo } from 'react';

const CloseRoundIcon = props => {
  // const { color = '#43425D', className } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="#A8A7BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 8L8 14" stroke="#A8A7BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 8L14 14" stroke="#A8A7BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default memo(CloseRoundIcon);
