import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import ContentHeader from "./header";
import CloseGroupForm from "../../components/CloseGroupForm";
import ReportGroupForm from "../../components/ReportGroupForm";
import DeleteGroupForm from "../../components/DeleteGroupForm";
import CancelGroupForm from "../../components/CancelGroupForm";
import GroupActions from "../../components/GroupActions";
import EventButton from "../../components/EventItem/eventButton";
import {gtMasterMind} from "../../const/general"; // gtWebinar
import AttendeesList from "../../components/AttendeesList";

import "./details.scss";
import ViewSubscriptionBtn from "../../components/ViewSubscriptionsBtn";
import GroupOwner from "../../components/EventItem/eventOwner";
import HeartIcon from "../../res/iconComp/HeartIcon";
import ShareButton from "../../components/ShareButton";
import {IconButton} from "@material-ui/core";
import {generateShareLinkStart, getRecordFileType, getLsRecordsTotalViews} from "../../units/func";

const ContentDetails = (props) => {
  const {
    children,
    toggleSave = "",
    openSubscriptions = () => {},
    needSubscriptionsBtn = false,
    availableBySubscription = false,
    tab,
    is_owner,
    item,
    isReport,
    hideReport,
    hideModal,
    report = false,
    groupsModel: {
      setEditGroup,
      reportGroup,
      deleteGroup,
      removeGroup,
      restoreGroup,
      closeGroup,
      openGroup,
      lastGroup,
      shareGroup,
      likeGroup,
      unlikeGroup
    },
    userModel: { isOwner },
    playlistModel: {
      showPlaylistIcon
    },
    subscribePackageModel:{
      getSubscriptionPackagesList
    }
  } = props;

  const [openClose, setOpenClosed] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openAttendees, setOpenAttendees] = useState(false);

  const groupClosed = item.private;
  const groupEnded = item.status === "canceled" || item.status === "ended";

  const fullItemData = item
  const setHideReport = () => {
    if (isReport) {
      hideReport();
    } else {
      setOpenReport(false);
    }
  };

  useEffect(() => {
    getSubscriptionPackagesList({user_id:item.owner.id})
  }, [])

  const showDelete = () => setOpenDelete(true);
  const hideDelete = () => setOpenDelete(false);

  const showClosed = () => setOpenClosed(true);
  const hideClosed = () => setOpenClosed(false);

  const showCancel = () => setOpenCancel(true);
  const hideCancel = () => setOpenCancel(false);

  const showAttendees = () => setOpenAttendees(true);
  const hideAttendees = () => setOpenAttendees(false);

  const showReport = () => setOpenReport(true);

  const saveReport = async (content) => {
    setHideReport();
    reportGroup(item.id, content);
  };

  const handleDeleteGroup = () => {
    setOpenDelete(false);
    if (isOwner(item.owner)) {
      deleteGroup(item.id);
    } else {
      removeGroup(item.id);
    }
    hideModal();
  };

  const handleCloseGroup = () => {
    setOpenClosed(false);
    closeGroup(item.id);
    hideModal();
  };

  const handleOpenGroup = () => {
    setOpenClosed(false);
    openGroup(item.id);
    hideModal();
  };

  const toggleGroup = () =>
    groupClosed ? handleOpenGroup() : handleCloseGroup();

  const successCancelClose = (needCloseModal) => {
    setOpenClosed(false);
    needCloseModal ? hideModal() : hideCancel();
  };

  const handleShareGroup = () => {
    shareGroup({
      group_id: item.id,
    })
  }
  const handleLikeGroup = () => likeGroup({
    group_id: item.id,
  })
  const handleUnlikeGroup = () => unlikeGroup({
    group_id: item.id,
  })

  const renderTitle = () => {
    if (!item.hasOwnProperty("meetings_records")) return null;

    let result = "";
    let fileTypesCounter = {};
    const isLivestream = !item.hasOwnProperty("record_type");

    if (isLivestream) {
      let meeting = item?.meetings_count > 1 ? "Meetings" : "Meeting";

      result = item?.meetings_count
        ? `${item?.past_meetings_count}/${item?.meetings_count} ${meeting}`
        : `${item?.meetings_records.length}/${item?.meetings_records.length} ${meeting}`;
    } else {
      for (const record of item?.meetings_records) {
        const fileType = getRecordFileType(record);
        fileTypesCounter[fileType] = fileTypesCounter[fileType]
          ? fileTypesCounter[fileType] + 1
          : 1;
      }
      Object.keys(fileTypesCounter).map((fileType) => {
        const count = fileTypesCounter[fileType];
        const title = count > 1 ? fileType + "s" : fileType;
        result += `${count} ${title}`;
      });
    }
    item.id === 3097 && console.log("item", JSON.parse(JSON.stringify(item)))
    return (
      <div className="detailsBlock">
        <div className="detailsBlock_name">{item.name}</div>
        <div className={"detailsBlock_owner"}>
          <GroupOwner owner={item.owner} showLink={true} />
        </div>
        <div className={'detailsBlock_mediaInfo'}>
          <div style = {{display:"flex", gap:"3px"}}>
            <div style={{ color: "#7E7D8F" }}>{result}</div>
            <div style={{ color: "#7E7D8F" }}>• {getLsRecordsTotalViews(item.meetings_records)} views</div>
          </div>
          <div className={"detailsBlock_socialBtns"}>
            <div className={'button-wrapper like'}>
              <IconButton
                className="like"
                onClick={item.liked? handleUnlikeGroup: handleLikeGroup}
              >
                <HeartIcon color={item.liked ? '#FF2D55' : "#A1A0AE"}/>
              </IconButton>
              {fullItemData.likes_count}
            </div>
            <div className={'button-wrapper share'}>
              <ShareButton
                item={item}
                contentType={generateShareLinkStart(item)}
                position="right"
                color={'#7E7D8F'}
                showByClick={true}
                onShareAction={handleShareGroup}
              />
             {fullItemData.external_sharings_count}
            </div>
            {showPlaylistIcon(item) ? <div className={'button-wrapper'} style = {{padding: "10px"}}>
              {toggleSave}
            </div> : null}
          </div>
        </div>
        <div className={"content_description_wrapper"}>
          {item.description && (
            <div className={"content_description"}>{item.description}</div>
          )}
        </div>
        {!isOwner(item.owner) && (["webinar", "recurring_webinar"].includes(item?.group_type)) && item.available_for_subscribers && !item.have_subscription  ? (
          <ViewSubscriptionBtn
            group={item}
            openSubscriptions={openSubscriptions}
          />
        ) : 
          <EventButton
            item={item}
            place="dialog"
            type="content"
            availableBySubscription={availableBySubscription}
            needSubscriptionsBtn={needSubscriptionsBtn}
            onClick={openSubscriptions}
          />
        }
        {!["webinar", "recurring_webinar"].includes(item?.group_type) ? 
          <EventButton
            item={item}
            place="dialog"
            type="content"
            availableBySubscription={availableBySubscription}
            needSubscriptionsBtn={needSubscriptionsBtn}
            onClick={openSubscriptions}
          /> : null}
        {isOwner(item.owner) && item.group_type != gtMasterMind && groupEnded && (
          <div className="groupActionsBlock">
            <GroupActions
              group={item}
              showDelete={showDelete}
              showCancel={showCancel}
              showClosed={showClosed}
              setEditGroup={setEditGroup}
              tab={tab}
            />
          </div>
        )}
        
        {children}
 
        {!isOwner(item.owner) && (["video_record"].includes(item.record_type)) && item.available_for_subscribers && !item.have_subscription  ? (
          <ViewSubscriptionBtn
            group={item}
            openSubscriptions={openSubscriptions}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div className="groupDetailsBlock">
      <ContentHeader
        tab={tab}
        item={item}
        is_owner={is_owner}
        showReport={showReport}
        reportGroup={reportGroup}
        removeGroup={removeGroup}
        deleteGroup={deleteGroup}
        restoreGroup={restoreGroup}
      />

      {item.price > 0 && (
        <button className="buyMediaGroups" disabled>
          $ {(item.price / 100).toFixed(2)}
        </button>
      )}

      {(report || openReport) && (
        <ReportGroupForm hideReport={setHideReport} saveReport={saveReport} />
      )}
      {openCancel && (
        <CancelGroupForm
          hideCancel={hideCancel}
          successCancelClose={successCancelClose}
        />
      )}
      {openDelete && (
        <DeleteGroupForm
          hideDelete={hideDelete}
          deleteGroup={handleDeleteGroup}
          isOwner={isOwner(item.owner)}
        />
      )}
      {openClose && (
        <CloseGroupForm
          hideClosed={hideClosed}
          toggleGroup={toggleGroup}
          groupClosed={groupClosed}
        />
      )}
      {openAttendees && (
        <AttendeesList hideAttendees={hideAttendees} groupId={item.id} />
      )}
      {!report &&
        !openReport &&
        !openDelete &&
        !openClose &&
        !openCancel &&
        !openAttendees &&
        renderTitle()}
    </div>
  );
};

export default inject("groupsModel", "userModel", "playlistModel", "subscribePackageModel")(observer(ContentDetails));
