import React, { memo } from 'react';

const SupportMaskIcon = props => {
//   const { color = '#43425D' } = props;

  return (
    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7732 0.531844C10.4349 0.57697 9.77147 1.18359 7.99999 1.18359C6.22851 1.18359 5.56506 0.577014 4.22675 0.531844C2.38268 0.470224 -0.64151 1.12789 0.120728 3.42714C0.682355 5.12129 1.7503 7.1315 3.6753 7.42863C5.42715 7.69909 6.29253 6.65621 7.512 5.63696C7.68023 5.4964 7.84412 5.38182 8.00004 5.38182C8.15596 5.38182 8.31984 5.4964 8.48808 5.63696C9.70754 6.65621 10.5729 7.69909 12.3248 7.42863C14.2497 7.1315 15.3177 5.12133 15.8793 3.42714C16.6415 1.12794 13.6173 0.470224 11.7732 0.531844ZM4.01295 4.75396C3.28496 4.50653 2.73112 3.85817 2.40139 3.18932C3.93131 2.43757 5.4903 2.96765 6.24409 4.49669C5.57489 4.82555 4.74072 5.00157 4.01295 4.75396ZM11.987 4.75396C11.2592 5.00153 10.425 4.82555 9.75593 4.49669C10.5097 2.96769 12.0687 2.43757 13.5986 3.18932C13.2689 3.85817 12.715 4.50657 11.987 4.75396Z" fill="#96D9EE" />
    </svg>
  )
}

export default memo(SupportMaskIcon);
