import React from "react";
import MainPage from "../MainPage";
import RightBlock from "../Dashboard/Right";
import AllCreatorsBlock from "../../components/AllCreatorsBlock";

import './styles.scss';

const AllCreators = props => {
  return (
    <MainPage>
      <AllCreatorsBlock/>
      <RightBlock />
    </MainPage>

  )


}

export default AllCreators