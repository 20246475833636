import React, {useEffect, useState} from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../../Input'
import Button from "../../Button";
import CheckboxEditTiers from '../../CheckboxEditTiers'
import {tiersList, tiersNamesArray} from '../../../const/tiers'
import {gtMasterMind, gtRecurring, gtRecurringWebinar, gtWebinar} from "../../../const/general";

import '../CreatePackageModal/styles.scss';
import {inject, observer} from "mobx-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import SelectCommunityCheckbox from '../SelectCommunityCheckbox';
import {findTiersTitleByName} from "../../../units/func";

const EditPackage = (props) => {
  const {
    id,
    name,
    communities,
    price,
    description,
    tiers,
    active_subscribers_count,
    subscribers_count,
    distribution_group_id,
    handleClose = () => {},
    subscribePackageModel:{updateSubscriptionPackage, packageCreateState, clearCreatePackageState},
    communityModel:{getCommunityNameById, communityList},
    userModel:{user}
  } = props;

  const [processing, setProcessing] = useState(false)
  const [visibleDrop, setVisibleDrop] = useState(false)

  const showDrop = () =>{
    visibleDrop ? setVisibleDrop(false) : setVisibleDrop(true)
  }

  const initCommunitiesData = {communities: communities, distribution_group_id: distribution_group_id}
  const currentCommunityList = [];
  const allCommunities = Object.keys(communityList.mappedCommunities).includes(user.community_id + "") ? Object.keys(communityList.mappedCommunities).slice() : Object.keys(communityList.mappedCommunities).concat([user.community_id])
  allCommunities.forEach( (id) => {
    const comItem = communityList.mappedCommunities[id]
    comItem && comItem.moderator && currentCommunityList.push({
      value: comItem.id + "",
      label: comItem.title,
    });
  })


  useEffect(function(){
    setProcessing(packageCreateState === 'start')
    if(packageCreateState === 'success'){
      clearCreatePackageState()
      handleClose()
    }
  },[clearCreatePackageState, handleClose, packageCreateState])

  const createValidationSchema = () => {
        return Yup.object().shape({
            name: Yup.string()
                .required('Incorrect Name')
                .min(2, 'Please, enter the Group Name correctly. (minimum is 2 characters)')
                .max(64, 'Please, enter the Group Name correctly. (maximum is 64 characters)'),
            description: Yup.string()
                .min(2, 'Description is too short (minimum is 2 characters)')
                .max(500, 'Please, enter the Group Description correctly (maximum is 500 characters)')
                .required('Please write group desciption'),
        });
    };

    const setSelectTiers = (values) => {
      return tiersNamesArray.reduce((res, item) => {
        if (values[item]){
          res.push(item)
          if (item === gtWebinar) res.push(gtRecurringWebinar)
          if (item === gtMasterMind) res.push(gtRecurring)
        }
        return res
      },[])
    }

    const handleSubmit = (values) => {
      let selectedTiers = setSelectTiers(values)
      const params = {
        id: id,
        name: values.name,
        description: values.description,
        communities: values.communities[0],
        distribution_group_id: values.communities[1],
        tiers: selectedTiers,
        price: values.price*100
      }
      updateSubscriptionPackage(params)
    }
    
    const customValidate = (values) =>{
      const errors = {}
      
  
      if((values.communities.length === 0 || !values.communities[0].length && !values.communities[1])){
      //|| (props?.communities ? (props.communities.lenght === 0 && !props?.distribution_group_id) : !props?.distribution_group_id)){
        errors.communities = "Please select communities"
      }
      let countOfValuesWithoutTiers = 6
      if(Object.keys(values).length > countOfValuesWithoutTiers && !tiersList.some(tier => values[tier?.name])){
        errors.tiers = "Please select tiers"
      }
      return errors
    }

    return (
        <div className="createPackage edit">
            <div className="createPackageHeader">
                <p className="title">
                    Edit Package
                </p>
            </div>
            <div className="createPackageForm">
                <Formik
                    initialValues={{
                        name: '' || name,
                        communities: ''|| communities,
                        price:  0 || parseFloat(price/100),
                        description: '' || description,
                        distribution_group_id: '' || distribution_group_id
                    }}
                    isInitialValid={true}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validationSchema={createValidationSchema}
                    validate = {customValidate}
                >
                  {({ isValid, setFieldValue }) => (
                    <Form className="p-0">
                        <CustomInput 
                          style={{marginBottom: '30px'}} 
                          className="formInput" 
                          name="name" label="Name" 
                          isShrink={true} 
                          inputProps={{
                            maxLength: 48
                          }}
                        />
                        {/*<CustomInput 
                          style={{marginBottom: '30px'}} 
                          className="formInput" 
                          name="community" 
                          label="Community" 
                          isDisabled={active_subscribers_count === 0 ? false : true} 
                          isShrink={true} 
                        />*/}
                        <SelectCommunityCheckbox
                          customChange={(tab) => setFieldValue("communities", tab)}
                          data = {currentCommunityList}
                          showDrop = {showDrop}
                          visibleDrop = {visibleDrop}
                          style={{marginBottom: '30px'}} 
                          className="formInput"
                          placeholder = "Community"
                          setFieldValue = {setFieldValue}
                          initCommunitiesData = {initCommunitiesData}
                          disabled = {subscribers_count === 0 ? false : true}
                        />
                        <CustomInput 
                          style={{marginBottom: '30px'}} 
                          className="formInput" 
                          name="price" 
                          label="Price" 
                          isDisabled={subscribers_count === 0 ? false : true} 
                          isShrink={true} 
                          tag='USD'
                        />
                        <CustomInput 
                          style={{marginBottom: '30px'}} 
                          className="formInput" 
                          name="description" 
                          label="Description" 
                          isShrink={true} 
                          isTextArea 
                          inputProps={{
                            maxLength: 500
                          }}
                        />
                        
                        {subscribers_count ? <>
                            <span className='tiersTitle'>Tiers</span>
                            <div className="selectedTiersWrap">
                                {tiers.map(i => (
                                    <span className="selectedTiers">{findTiersTitleByName(i)}</span>
                                ))}
                            </div> 
                          </> :
                          <>
                            <div className='tiersTitleWrap'>
                              <span className='tiersTitle'>Tiers</span>
                            </div>
                            <div className='tiersList'>
                            {tiersList.map(({name, label}) =>
                                <div className='tiersList-item' key={name}>
                                    <CheckboxEditTiers
                                        setFieldValue={setFieldValue}
                                        name={name}
                                        placeholder={label}
                                        alreadySelected = {tiers}
                                    />
                                </div>)}
                              </div>
                            </>      
                        }

                      {processing? <CircularProgress/>: <Button type="submit" label="Save" disabled={!isValid} className={classNames("createPackageButton", {unactive: !isValid})} />}
                    </Form>
                  )}
                </Formik>
                {visibleDrop && <div className = "selectCommunityCheckbox_overlay" onClick = {showDrop}></div>}
            </div>
        </div>
    );
}

export default inject("userModel","subscribePackageModel", 'communityModel')(observer(EditPackage));
