import React, { useRef, useEffect } from 'react';
import { Field } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import UploadFileIcon from '../../res/icons/uploadFile.svg';
import CloseImageIcon from '../../res/iconComp/CloseImageIcon';

import './imagePicker.scss';

const CustomImagePicker = props => {
  const inputEl = useRef(null);
  const { name, customChange, text } = props;
  
  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.addEventListener('change', e => {
        customChange(e.currentTarget.files[0]);
      })
    }
  })


  return (
    <Field name={name}>
      {({
          field: { name, value }, // onChange, onBlur
          form: { touched, errors, isValid },
          meta,
        }) => {
          const fileInputKey = value ? value.name : +new Date();
          return(
            <FormControl error={meta.touched && !!errors[name]} className="buttonsBlock">
              <input
                // value={value}
                key={fileInputKey}
                ref={inputEl}
                id="file"
                name="file"
                type="file"
                accept="image/*"
                onChange={(event) => {
                  if (event.currentTarget.files.length) {
                    customChange(event.currentTarget.files[0]);
                    // customChange(URL.createObjectURL(event.currentTarget.files[0]));
                  }
                }}
                style={{ display: 'none' }}
                onClick={event => {
                  if (event.currentTarget.files.length) {
                    customChange(event.currentTarget.files[0]);
                    // customChange(URL.createObjectURL(event.currentTarget.files[0]));
                  }
                }}
              />
                <button
                  type="button"
                  className="uploadImage"
                  onClick={() => inputEl.current.click()}
                  style={value && value.length !== 0 ? 
                    { backgroundImage: `url(${URL.createObjectURL(value)})` } : 
                    { backgroundImage: `url(${UploadFileIcon})` }
                  }
                >
                  {!value ? text : null}
                </button>
                {(value && value.length !== 0) && <button onClick={() => customChange(null)} className="clearImage">
                  <CloseImageIcon onClick={() => customChange(null)}/>
                  </button>
                }

              <FormHelperText style={{ height: 'auto' }}>{meta.touched && errors[name]}</FormHelperText>
            </FormControl>
          )
        }
      }
    </Field>
  );
};

export default CustomImagePicker;
