import React, { useState } from "react";
import RightBlock from "../Dashboard/Right";
import MainPage from "../MainPage";
import "./styles.scss";
import ChooseCommunityBlock from "./ChooseCommunityBlock";
import { inject, observer } from "mobx-react";

const ChooseCommunity = (props) => {
  const {
    authModel: { session_token },
    userModel: { community },
  } = props;

  const invitedId =
    new URLSearchParams(window.location.search)?.get("invite") ||
    localStorage.getItem("community_invite_id");

  if (session_token && !community) {
    return (
      <MainPage hideMenu={true}>
        <ChooseCommunityBlock invitedId={invitedId} />
      </MainPage>
    );
  }
  return (
    <MainPage>
      <ChooseCommunityBlock invitedId={invitedId} />
      {/*<RightBlock />*/}
    </MainPage>
  );
};

export default inject("authModel", "userModel")(observer(ChooseCommunity));
