export const userRole = 'user';
export const mmRole = 'master_mind';

export const gtMasterMind = 'master_mind'; // coaching public
export const gtIndividual = 'individual'; // coaching private
export const gtWebinar = 'webinar'; // livestream
export const gtSupport = 'support'; // groups
export const gtRecurring = 'recurring';
export const gtRecurringWebinar = 'recurring_webinar'

export const dailyPage = 'daily';

export const video_record = 'video_record';
export const meditation = 'meditation';
export const podcast = 'podcast';
export const chatChannel = "chat_channel";

export const added = 'Purchased/Saved';
export const createdByMe = 'Upload'; // Upload
export const myLivestreams = 'My Livestreams';
export const titleLiveStreams = 'Livestreams';

export const requestInterval = 360000; // 00
export const maxSubscriptionsCount = 10;
export const tabMy = 'my';
export const tabSaved = 'saved';
export const tabAll = 'all';

export const tabTitleAll = 'All';
export const tabTitleSaved = 'Saved';
export const tabTitleMy = 'My';
export const tabTitleUpload = 'Upload'
export const tabTitleFollowing = 'Following';

export const statusUpcoming = 'upcoming';
export const statusEnded = 'ended';
export const statusCanceled = 'cancelled';
export const statusActive = 'active';
export const statusDeactivated = 'deactivated';

export const select_all_community = 'select_all_community'
export const set_default_community = 'set_default_community'
export const set_community_from_memory = 'set_from_memory'

export const contentType = 'content'
export const groupType = 'group'

export const actionEdit = 'edit'
export const actionDuplicate = 'duplicate'

export const MyProfile = 'My profile'
export const paymentPortal = 'Payment portal'
export const mySubscriptions = 'My Subscriptions'
export const myOrganization = "My Organization"

export const GroupLiveStreamTag = 'Livestream'
export const GroupGroupTag = 'Group'
export const GroupCoachingTag = 'Group'
export const recurringGroupTitleTag = 'Subscription Group'
export const recurringWebinarTitleTag = 'Subscription Livestream'


export const ctGeneral = "general"
export const ctOrganization  = "organization"
export const ctInfluencer = "influencer"
export const ctDistributionGroups = "distribution_group"

export const successMessage = 'Success'
export const errorMessage = 'Error'

export const PublicLabel = "Public"
export const PrivateLabel = "Private"
export const OrganizationLabel = "Organization"
export const InfluencerLabel = "Influencer"

export const actionPay = 'pay'
export const actionSubscribe = 'get_subscription'

export const PAYMENT_TYPE = 'PAYMENT'

