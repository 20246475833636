import React from "react";
import CurrentAvatar from '../../CurrentAvatar';
import "./style.scss";


const ContentVideoPlayerHeader = (props) => {
  const { 
    isFullScreen, 
    theme, 
    full_name, 
    image, 
    ownerLocation
  } = props;

    return (
      <div className = {`video_player_header ${isFullScreen && "full_screen"}`}>
        <span className = "video_player_title">{theme}</span>
        <div className = "video_player_video_owner">
          <div className="MuiAvatar-root MuiAvatar-circle master_mind_avatar">
            <CurrentAvatar alt={full_name} src={image && image.url} className="owner_avatar" />
          </div>
          <div className = "video_player_video_owner_info">
              <span className = "video_player_video_owner_info_full_name">{full_name}</span>
              <span className = "video_player_video_owner_info_address">{ownerLocation}</span>
          </div>
        </div>
      </div>
  )
};

export default ContentVideoPlayerHeader;
