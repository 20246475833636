import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';

import EventButton from '../../components/EventItem/eventButton';
import WhoFolloow from '../../components/WhoFollow';

import GroupTypeLabel from '../../components/EventItem/groupType';

import { home } from '../../const/urlKeys';

import './right.scss';

const EmptyUncoming = () => (
  <>
    <div className="desc">
      List is empty. Please, join to group using <Link to={home} className="link">search</Link> or select from <Link to={home} className="link">list</Link>.
    </div>
    <Divider />
  </>
);

const RightBlock = props => {
  const {
    // masterMindsModel: { randomMinds },
    groupsModel: { groups: { groups } }
  } = props;

  const currentGroups = groups?.filter(item => (item.status !== "ended") && (item.group_type !== "support"));

  return (
    <div className="rightBlock">
      <div className="rightBox">
        <h2 className='rightBox-title'>Upcoming Groups</h2>
        <Divider />
        {currentGroups && currentGroups.length > 0 ?
          currentGroups.map((item, i) => {
            if (i < 3) {
              return (
                <Fragment key={item.id}>
                  <div className="rightGroupItem">
                    <div className="groupName">{item.name}</div>
                    <div className="groupDesc">{item.description}</div>
                    <GroupTypeLabel className="groupType"
                      group_type={item.group_type}
                      owner={item.owner}
                      isPrivate={item.private}
                      isClosed={item.price > 0}
                      group={item}
                    />
                    <div className="groupTime">
                      {moment(item.start_time).format('dddd [at] h:mm a')}
                    </div>
                    <EventButton item={item} />
                  </div>
                  {i !== 2 && <Divider />}
                </Fragment>
              )
            } else {
              return null;
            }
          }) : <EmptyUncoming />}
        {/* <Link to={home} className="link linkBlock">My Daily Groups List</Link> */}
      </div>
      {/*<WhoFolloow />*/}
    </div>
  )
}

export default inject('groupsModel', 'userModel')(observer(RightBlock)); // 'masterMindsModel', 
