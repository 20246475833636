import React from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Button from '../../components/Button';
import CustomSneckbar from '../../components/CustomSnackbar';
import Input from '../../components/Input';

const SignupSchema = Yup.object().shape({
  reasone: Yup.string()
    .required("Reasone can't be blank")
    .min(2, "Reason must include not less than 2 characters")
    .max(200, "The maximum number of characters in the field is 200"),
})

const GroupRefund = props => {
  const { close, group_id, groupsModel: { leaveGroup, resualtMessage, changeResultMessage } } = props;

  return (
    <>
      <Formik
        initialValues={{ reasone: '' }}
        validationSchema={SignupSchema}
        isInitialValid={false}
        onSubmit={(values, actions) => {
          leaveGroup({ id: group_id, refund: true,  ...values })
        }}
      >
        {formikProps => {
          const { setFieldValue, isValid, errors } = formikProps;
          const disabledButton = !isValid
          return (
            <Form className="refundBlock">
              <div className="refundTitle">Request a Refund</div>
              <label className="refundText">
                <div className="labelText">Enter a reason</div>
                <Input name="reasone" id="reasone" className="reasoneInput" />
              </label>
              <div className="refundButtons">
                <Button label="Don’t Cancel" onClick={() => close()} className="refundCancel" />
                <Button type="submit" label="Complete" disabled={disabledButton} className="refuntComplate" />
              </div>
            </Form>
          );
        }}
      </Formik>
      <CustomSneckbar 
        message={resualtMessage}
        onClose={() => { changeResultMessage(''); close(); }}
        severity={resualtMessage === 'You Request send' ? "success" : 'error'}
      />
    </>
  )
}

export default inject('groupsModel')(observer(GroupRefund));
