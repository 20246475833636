import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import Select from "@material-ui/core/Select";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import CustomSneckbar from "../CustomSnackbar";
import Button from "../Button";
import CardBrand from "../CardBrand/CardBrand";

import { countriesList } from "../../const/counties";

import "../../pages/Profile/profile.scss";
import "./cardForm.scss";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#43425D",
      fontWeight: 500,
      fontFamily: "Red Hat Display, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      textAlign: "center",
      ":-webkit-autofill": { color: "#43425D" },
      "::placeholder": {
        color: "#7c7c7d",
        fontSize: "14px",
      },
    },
  },
};

const CVV_OPTIONS = { ...CARD_OPTIONS, placeholder: "CVV" };

const CARD_DATE_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#43425D",
      fontWeight: 500,
      fontFamily: "Red Hat Display, sans-serif",
      fontSize: "14px",
      fontSmoothing: "antialiased",
      textAlign: "center",
      lineHeight: "21px",
      ":-webkit-autofill": { color: "#43425D" },
      "::placeholder": { color: "#7c7c7d" },
    },
  },
};

const CardForm = (props) => {
  const {
    cvc,
    close,
    label,
    className,
    buttonLabel = "Add",
    button100 = false,
    cardsModel: {
      checkIfCardExist,
      createCard,
      changeResultMessage,
      resualtMessage,
    },
    variant = "payment",
  } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [brand, setBrand] = useState(null);
  const [countrySelect, setCountry] = useState("US");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    const firstToken = stripe.createToken(cardNumberElement, {
      name: event.target.name.value,
      currency: "usd",
    });

    const secondToken = stripe.createToken(cardNumberElement, {
      name: event.target.name.value,
      currency: "usd",
    });

    Promise.all([firstToken, secondToken])
      .then((data) => {
        const isExist = checkIfCardExist(data[0].token.card);
        const isCreditCardAddedAsPayout =
          data[0].token.card.funding === "credit" && variant === "payout"; // user cant use credit card as payout
        if (isExist.length > 0) {
          changeResultMessage("This Payment Card is exist");
          return null;
        }

        if (isCreditCardAddedAsPayout) {
          changeResultMessage(
            "Payout method incorrect. Please, add debit card for payout operations."
          );
          return null;
        }
        createCard({
          token: data[0].token.id,
          payout_token: data[1].token.id,
          name: data[0].token.card.name,
          event: close,
          country: countrySelect,
          variant: variant,
        });
        // setShow("Payment Card added");
      })
      .catch((error) => {
        changeResultMessage("Add all card data");
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ width: "100%" }}
      className={classNames("cardForm", className)}
    >
      {label && <h2 className="title">{label}</h2>}
      <div className="likeCard openData">
        <div className="cardBrand">
          <CardBrand brand={brand} />
        </div>
        <label className="cardNumber">
          <div className="cardFieldName">Card Number</div>
          <CardNumberElement
            options={CARD_OPTIONS}
            onChange={(data) => setBrand(data.brand)}
          />
        </label>
        <label className="cardholderName">
          <div className="cardFieldName">Cardholder Name</div>
          <input name="name" type="text" placeholder="Jane Doe" required />
        </label>
        <label className="cardDate">
          <div className="cardFieldName">Valid Thru</div>
          <CardExpiryElement options={CARD_DATE_OPTIONS} />
        </label>
      </div>
      {cvc && (
        <div className="likeCard saveData">
          <label className="selectCountry">
            <div className="cardFieldName">Country or region</div>
            <select
              name="language"
              required
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value={null}></option>
              {countriesList.map((country) => (
                <option
                  value={country.value}
                  key={country.value}
                  selected={country.value == countrySelect}
                >
                  {country.label}
                </option>
              ))}
            </select>
          </label>
          <label className="cardCVC">
            <div className="cardFieldName">CVV</div>
            <CardCvcElement options={CVV_OPTIONS} />
          </label>
        </div>
      )}
      <Button
        type="submit"
        label={buttonLabel}
        violetGradien
        disabled={!stripe}
        width100
        big60
        className={button100 && "button100"}
      />
      <CustomSneckbar
        message={resualtMessage}
        onClose={() => {
          changeResultMessage("");
          if (resualtMessage.includes("Success")) {
            close();
          }
        }}
        severity={resualtMessage.includes("Success") ? "success" : "error"} // resualtMessage === 'Success'
      />
    </form>
  );
};

export default inject("cardsModel")(observer(CardForm));
