import React, {useEffect, useState} from 'react';
import { inject, observer } from 'mobx-react';

import Empty from '../../components/Empty';
import Daily from './Daily';
import HomeDailyDashboard from "./HomeDailyDashboard";

// import { mmRole } from '../../const/general';
import { home } from '../../const/urlKeys'

import './dashboard.scss';
import {statusCanceled, statusEnded} from "../../const/general";
import LoadingBlock from "../../components/LoadingBlock";

const DashboardPage = props => {
  const {
    groupsModel: { groups, filteredGroups, changeResultMessage, lastGroup, setGroupNotFound, setLastGroup, canSetGroupNotFound, state: { filters } },
    userModel: { user, community },
    masterMindsModel: { masterMinds, randomItems },
    match
  } = props;

  useEffect(() => {
    randomItems(2);
  }, [masterMinds, match.url]);

  useEffect(()=>{
    return _ => {
      changeResultMessage('');
    }
  },[])

  let groupsData = filteredGroups;
  if (lastGroup && filteredGroups.findIndex(group => group?.id == lastGroup?.id) == -1) {
    if ((lastGroup.status === statusEnded  || lastGroup.status === statusCanceled) && canSetGroupNotFound){
      setLastGroup(false)
      setGroupNotFound(true)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
  
  const [comm, setComm] = useState(groupsData);

  useEffect(() => {
    const arr = [];
     groupsData.forEach(item => {
       item.communities.forEach(el => {
         if(filters.communities.includes(el.id)) {
           arr.push(item);
         }
       });
     })
    const unique = [...new Set(arr)]
    setComm(unique)
  }, [filters.communities]);

  return (
    <div className="dashboardBlock">
      {match.path === home ? <HomeDailyDashboard/> :
        groups && groups.count ?
          <Daily
            data={comm}
            match={match}
            role={user?.role}
            key="daily"
            community={community}
          />
          :
          (groups.isLoading || groups.firstLoading) ?
            <LoadingBlock/>
            :
            <Empty/>
      }
    </div>
  )
};

export default inject('groupsModel', 'masterMindsModel', 'userModel')(observer(DashboardPage));
