import React, { memo } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const CustomAccordion = props => {
  const { 
    mainRoot, contentSummary, summary, rootDetails, children
  } = props;

  return (
    <Accordion classes={{ root: mainRoot }} >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
        classes={{ content: contentSummary }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails classes={{ root: rootDetails }}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default memo(CustomAccordion);
