import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import SharePopup from '../SharePopup/sharePopup';
import ShareIcon from '../../res/iconComp/ShareIcon';
import { returnName } from '../../units/func';
import {contentMailToText} from "../SharePopup/sharePopupContent/sharePopupContent";
import {communityModel} from "../../models/community";

const ShareButton = props => {
  const { item, children, position = "left", color, showByClick = false, onShareAction, contentType = "/group"} = props

  const getPath = () => {
    const currentPath = window.location.pathname;
    if(currentPath.includes('content')){
      return currentPath;
    }

    return `${contentType}`
  }

  const mailData = {
    name: item.name,
    community: communityModel.getCommunitiesTitlesString(item),
    link: `${process.env.REACT_APP_REDIRECT}${getPath()}/${item.id}`,
    owner: returnName(item.owner),
    type: item.group_type,
  }
  return (
    <SharePopup
      url={`/group/${item.id}`}
      mailData={mailData}
      showShareWithEmail
      position={position}
      mailToText={contentMailToText(mailData)}
      showByClick={showByClick}
      onShareAction={onShareAction}
    >
      <IconButton classes={{ root: "attendIcon" }}>
        <ShareIcon color={color}/>
      </IconButton>
      {children}
    </SharePopup>
  )
}

export default ShareButton;
