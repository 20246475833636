import envelope from '../img/envelope.svg';
import linkChain from '../img/linkChain.svg';
import success from '../img/Success.svg';
import share from '../img/share.svg';
import complain from '../img/complain.svg';
import arrowUp from '../img/arrowUp.svg';
import arrowDown from '../img/arrowDown.svg';
import close_white from '../img/close_white.svg'
import addVideoIcon from '../img/addVideoIcon.svg';
import contentIcon from '../img/contentIcon.svg';
import coinsIcon from '../img/coinsIcon.svg';
import contentDesc from '../img/contentDesc.svg';
import cloudIcon from '../img/cloudIcon.svg';
import pencil from '../img/pencil.svg';
import plus from '../img/plus.svg';
import cancelLoading from '../img/cancelLoading.svg';
import deleteImg from '../img/delete.svg';
import pause from '../img/pause.svg';
import date_blue from '../img/date_blue.svg';
import ping_chat_blue from '../img/ping_chat_blue.svg';
import chedule_blue from '../img/chedule_blue.svg';
import hand_blue from '../img/hand_blue.svg';
import tag_blue from '../img/tag_blue.svg';
import align_blue from '../img/align_blue.svg';
import menudots_darker_blue from '../img/menudots_darker_blue.svg';
import subscription_premium from "../img/subscription_premium.svg";
import subscription_premium_text from "../img/subscription_premium_text.svg";
import play_button_white from "../img/play_button_white.svg"
import four_squares from "../img/4squares.svg"
import subscribe_pink from "../img/subscribe_pink.svg"
import infoButton from "../img/infoButton.svg"
import textInfoButton from "../img/textInfoButton.svg"

const ImgBuilder = {
    envelope,
    linkChain,
    success,
    share,
    complain,
    arrowUp,
    arrowDown,
    close_white,
    addVideoIcon,
    contentIcon,
    coinsIcon,
    contentDesc,
    cloudIcon,
    pencil,
    plus,
    cancelLoading,
    deleteImg,
    pause,
    date_blue,
    ping_chat_blue,
    chedule_blue,
    hand_blue,
    tag_blue,
    align_blue,
    menudots_darker_blue,
    subscription_premium,
    subscription_premium_text,
    play_button_white,
    four_squares,
    subscribe_pink,
    infoButton,
    textInfoButton
}

export default ImgBuilder;
