import React, { memo } from 'react';
import Avatar from '@material-ui/core/Avatar';

const CurrentAvatar = (props) => {
  const { alt, src, className } = props;
  let altName = '';
  if (alt) {
    alt.split(' ').forEach((str, index) => {
      if (index < 3) {
        altName = altName + str.charAt(0);
      }
    });
  }

  return(
    <Avatar
      alt={alt}
      src={src}
      className={className}
    >
      {altName}
    </Avatar>
  );
}

export default CurrentAvatar;

