import React, {useState, useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';
import debounce from 'lodash/debounce';

import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '../../components/Button';
import CardList from '../../components/PaymentMethods/Cards';
import PromoCodeIcon from '../../res/iconComp/PromoCodeIcon';
import AddPaymentMethod from '../../components/AddPaymentMethod'
import PromoCheckIcon from '../../res/iconComp/PromoCheck';
import PromoUncheckIcon from '../../res/iconComp/PromoUncheckIcon';
import MitingsDateList from '../../components/MitingsDateList';
import GroupHeader from './GroupHeader';

import './attendGroup.scss';
import ReportGroupForm from "../../components/ReportGroupForm";

const CustomCheckbox = withStyles({
  root: {
    color: "#E9E9F0",
    '&$checked': {
      color: '#43425D',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" icon={<PromoUncheckIcon/>} checkedIcon={<PromoCheckIcon/>} {...props} />);

const AttendGroupPack = props => {
  const {
    item,
    close,
    handleClose,
    cardsModel: {defaultCard, cards},
    groupsModel: {
      buyGroup, reportGroup, checkPromokode, promocode, setPromokode,
    },
    supportModel: {getStripeFeeForGroup, commissions: {group_fee: AscendFee}}
  } = props;

  const [promo, setPromo] = useState('');
  const [cardId, setCardId] = useState(defaultCard?.id || (cards?.count && cards?.cards[0].id) || null);
  const [openReport, setOpenReport] = useState(false);

  useEffect(() => {
    setCardId(defaultCard?.id);
  }, [defaultCard?.id, item]);

  const delayedQuery = useRef(debounce(q => checkPromokode(q), 500)).current;

  const changePromocode = value => {
    setPromo(value);
    delayedQuery(value);
    if (!value.length) {
      setPromokode('');
    }
  }

  const buy = (id, cardId, type = 'card', promo) => {
    setPromokode(null);
    if (!totalPrice) {
      buyGroup(id, null, null);
    } else {
      buyGroup(id, cardId, type, promo);
    }
    close();
    handleClose();
  }
  const stripeFee = getStripeFeeForGroup(item.pack_price);
  let totalPrice = item.pack_price / 100;
  let currentPromo = 0;
  if (promocode && promocode.promo_code) {
    switch (true) {
      case item.pack_price - promocode.discount <= 0:
        totalPrice = 0;
        currentPromo = promocode.discount / 100;
        break;

      case item.pack_price - promocode.discount < 50:
        currentPromo = (item.pack_price - 50) / 100;
        totalPrice = 50 / 100;
        break;

      default:
        currentPromo = promocode.discount / 100;
        totalPrice = item.pack_price / 100 - currentPromo;
        break;
    }
  }

  const setHideReport = () => setOpenReport(false);
  const showReport = () => setOpenReport(true);
  const saveReport = async (content) => {
    setHideReport();
    reportGroup(item.id, content);
  };

  return (
    <div className="attendGroupBlock">
      <GroupHeader item={item} reportGroup={showReport}/>
      {openReport ? (
          <ReportGroupForm hideReport={setHideReport} saveReport={saveReport}/>
        ) :
        <>
          <div className="attendGroupHeaderInfo">
            <div className="attendGroupHeaderInfoGroup bullet">{item.private ? "private group" : "public group"}</div>
            <div className="bullet">{item.meetings_count} meetings</div>
            <div><MitingsDateList item={item}/></div>
          </div>
          <div className="attendGroupPayment">
            Select Payment Method
            $ <span>{(item.pack_price / 100).toFixed(2)}</span>
          </div>
          <div className="attendGroupCardList">
            <Divider/>
            {cardId && <CardList onClick={setCardId} cardId={cardId}/>}
          </div>
          <div className="attendGroupPromocode">
            <CustomCheckbox
              checked={!!promo}
              onClick={() => changePromocode('')}
            />
            <PromoCodeIcon/>
            <input placeholder="Promo Code" className="fieldPromo" value={promo}
                   onChange={e => changePromocode(e.target.value)}/>
            {promo && promocode === 'error' && <div className="wrongCode">Wrong Code</div>}
            {promocode && promocode.promo_code &&
            <div className="promoValue">$ <span>- {currentPromo.toFixed(2)}</span></div>}
          </div>
          <div className="attendGroupAddPM">
            <Divider/>
            <AddPaymentMethod title="Add Payment Method"/>
            <Divider/>
          </div>
          <div className="attendGroupPaymentData">
            <div className="amount">
              Amount $ <span>{(item.pack_price / 100).toFixed(2)}</span>
            </div>

            <div className="serviceFee">
              Stripe Fee <span>$ {(stripeFee / 100).toFixed(2)}</span>
            </div>
            <div className="serviceFee">
              Service Fee <span>$ {(totalPrice * AscendFee).toFixed(2)}</span>
            </div>
          </div>
          <div className="attendGroupTotal">
            Total:
            <span>$ <b>{((totalPrice * (1 + AscendFee)) + stripeFee / 100).toFixed(2)}</b></span>
          </div>
          <div className="attendGroupButtons">
            <Button label="Cancel" className="attendGroupCancel" onClick={() => {
              setPromokode(null);
              close();
            }}/>
            <Button
              label="Pay"
              className="attendGroupPay"
              onClick={() => buy(item.id, cardId, 'card', promocode ? promocode.promo_code : null)}
              disabled={!cardId || (promo && !(promocode && promocode.discount))}
            />
          </div>
        </>
      }
    </div>
  )
}

export default inject('cardsModel', 'groupsModel', 'supportModel')(observer(AttendGroupPack));
