import React from 'react';
import { inject, observer } from 'mobx-react';
import { Markup } from 'interweave';

import CircularProgress from '@material-ui/core/CircularProgress';

const CookiePolicy = props => {
  const { pageModel: { cookie_policy } } = props;

  if (!cookie_policy) return <CircularProgress className="loader" />

  return (
    <div className="simplePage">
      <div className="title">Cookie Policy</div>
      <Markup content={cookie_policy.content} />
    </div>
  )
}

export default inject('pageModel')(observer(CookiePolicy));
