import React, {useEffect, useState} from 'react';
import CalendarForm from "./CalendarForm";
import "./style.scss";

const SpecificDate = (props) => {
    const {
        close,
        onSelect,
        onSelect1,
        webinars,
        value,
        scheduleItem,
        editGroup,
    } = props;

    const [recurring, setRecurring] = useState(false);
    const [weekly, setWeekly] = useState(true);

    useEffect(() => {
        if(!scheduleItem){
            return;
        }

        const isReccuring = !!scheduleItem.initialData
            && !!(scheduleItem.initialData.weekly || scheduleItem.initialData.monthly);

        if(isReccuring){
            setRecurring(isReccuring);
            setWeekly(!!scheduleItem.initialData.weekly);
        }

    }, [scheduleItem]);

    return (
        <div className="selectsBlock">
            <div className="date">
                <div className={recurring ? "dateDisabled": "dateActive"}
                    onClick={() => setRecurring(false)}>Specific Date</div>
                <div className={!recurring ? "dateDisabled": "dateActive"}
                     onClick={() => setRecurring(true)}>Recurring</div>
            </div>
            {recurring &&
                <div className="recurringBlock">
                    <div className={weekly ? "recurringActive": "recurringDisabled"}
                         onClick={() => setWeekly(true)}>Weekly</div>
                    <div className={!weekly ? "recurringActive": "recurringDisabled"}
                        onClick={() => setWeekly(false)}>Monthly</div>
                </div>
            }
            <CalendarForm
                value={value}
                close={close}
                editGroup={editGroup}
                onSelect={onSelect}
                onSelect1={onSelect1}
                recurring={recurring}
                weekly={weekly}
                webinars={webinars}
                scheduleItem={scheduleItem}
            />
        </div>
    );
};

export default SpecificDate;