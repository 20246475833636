import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import MyPlayer from '../MyPlayer';

const PlayRecord = (props) => {
  const {
    record,
    owner,
    groupID,
    children,
    recordPrice,
    meetingsModel: { buyRecord, setMeettingTiming, setLastData, lastMettingID },
  } = props;

  const handleBuy = _ =>  {
    buyRecord(record.id, null, 'card', null);
    setLastData(groupID, record.id);
  }
 
  const playVideo = (data, children) => {
    switch (true) {
      case data?.ended && data?.video_file && data?.record_access:
        return (
          <MyPlayer record_info={data} owner={owner} onClose={() => setLastData(null, null)} autoplay={data.id === lastMettingID}>
            {children}
          </MyPlayer>
        );

      case data?.video_file && !data?.record_access && recordPrice === 0:
        return (
          <div className={classNames('record_item_play', 'record_item_play_button')} onClick={handleBuy}>
            {children}
          </div>
        );

      case data?.video_file && !data?.record_access:
        return <div className="record_item_play">{children}</div>;

      case !!data?.file:
        return (
          <MyPlayer record_info={{...data, url: data.file}} owner={owner} onClose={() => setLastData(null, null)} autoplay={data.id === lastMettingID}>
            <div className="record_item_play">{children}</div>
          </MyPlayer>
        );

      default:
        return null;
    }
  };
  return <>{playVideo(record, children)}</>;
};

export default inject('meetingsModel')(observer(PlayRecord));
