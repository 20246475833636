import React from 'react';
import "./styles.scss";
import classNames from "classnames";
import categoryNameIcon from "./../../res/icons/categoryName.svg";
import membersIcon from "./../../res/icons/members.svg";
import privateIcon from "./../../res/icons/private.svg";
import ShareCommunityBtn from "../../components/ShareButton/shareCommunityBtn";

const ChooseCommunityColumn = (props) => {
    const {
        arr,
        invitedId,
        communityType,
        externalChooseCommunity,
        toggleSelected,
        changeBtn,
        addInfo,
        countColumn,
    } = props;

    const width = countColumn === 4 ? "25%" : countColumn === 3 ? "33%" : countColumn === 2 ? "50%" :  "100%";
    const img = "https://icon-library.com/images/user-group-icon/user-group-icon-14.jpg";

    return (
        <div style={{width: width}}>
            {arr.map(item => {
                    const invited = Number(item.id) === Number(invitedId);

                    return (
                        <div key={item.id}
                             className={classNames("communityList_item", {invited})}>
                            <div className={invited ? "invitedBlock" : "invitedBlock hidden"}>
                                <div className="invitedBlock_line">you're invited</div>
                                <div className="invitedBlock_triangle"></div>
                            </div>
                            <div className={item.selected ?
                                classNames("communityList_itemSelected") : classNames("communityList_itemNotSelected", {invited})}>
                                <div className="cardTopBlock">
                                    <div className="imgWrapper">
                                        <img className="img"
                                             src={item.image?.thumbnail?.url || img} alt="icon"/>
                                    </div>
                                    <div style={{width: "100%"}}>
                                        <div className="infoBlock">
                                            <div className="infoBlock_title">
                                                {item.private && <img src={privateIcon} alt="private"/>}
                                                <div className="infoBlockText">{item.title}</div>
                                            </div>
                                            {/*<div>*/}
                                            {/*    {communityType !== "distribution_group" &&*/}
                                            {/*        <div className="communityList_itemShare">*/}
                                            {/*            {(*/}
                                            {/*                <div>*/}
                                            {/*                    <ShareCommunityBtn*/}
                                            {/*                        item={item}*/}
                                            {/*                        inviteUrl={`${externalChooseCommunity}/${item.id}`}*/}
                                            {/*                        color={"#7E7D8F"}*/}
                                            {/*                        position={"right"}*/}
                                            {/*                    />*/}
                                            {/*                </div>*/}
                                            {/*            )}*/}
                                            {/*        </div>*/}
                                            {/*    }*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="membersBlock">
                                            {item.category?.name && <div className="flex">
                                                <img src={categoryNameIcon} alt="categoryName"/>
                                                <div className="membersBlockText">{item.category?.name}</div>
                                            </div>}
                                            <div className="flex">
                                                <img src={membersIcon} alt="usersCount"/>
                                                <div className="membersBlockText">{item.users_count} members</div>
                                            </div>
                                        </div>
                                        <div onClick={() => {toggleSelected(item)}}>
                                            {changeBtn(item)}
                                        </div>
                                    </div>
                                </div>
                                <div className="cardBottomBlock">
                                    <div>
                                        {item?.description}
                                    </div>
                                    {addInfo(item)}
                                </div>
                            </div>
                        </div>
                    );
                }
            )}
        </div>
    );
};

export default ChooseCommunityColumn;