import React from 'react';
import { inject, observer } from 'mobx-react';
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup';

import CustomSneckbar from '../../../components/CustomSnackbar';
// import EmailInput from '../../../components/EmailInput/EmailInput';
// import contactUs from '../../../res/img/become_mastermind.svg';
import Button from '../../../components/Button';

import './NewContacts.scss';

// const SignupSchema = Yup.object().shape({
//   email: Yup.string()
//     .required("Email can't be blank")
//     .email('The email is incorrect. Please, make sure you entered correct data'),
// });

const Contacts = props => {
  const { pageModel: { resultMessage, setPage } } = props; // sendContact
  // const initData = { email: '' };

  return (
    <div className="contacts">
      {/* <img src={contactUs} alt="contact us" /> */}
      <div className="form">
        <span className="title">BECOME A CREATOR</span>
        <span className="description-text">
          Join the thousands of creators and start earning income today.<br />All creators go through a verification process.
        </span>
        <div className="subscribeForm">
          <Button 
            onClick = {() => window.location.replace("https://creator.ascend.video/")} 
            label="Learn More" violetGradien width100 />
        </div>
      </div>
      <CustomSneckbar
        message={resultMessage}
        onClose={() => setPage('', resultMessage)}
        severity={resultMessage === 'Success' ? "success" : 'error'}
      />
    </div>
  )
}

export default inject('pageModel')(observer(Contacts));
