import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Button from "../Button";
import ChangeIcon from "../../res/icons/change.svg";
import CalendarIcon from "../../res/icons/eventcalendar.svg";
import ClockIcon from "../../res/icons/clock.svg";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import {wdays} from "../../const/calendar";

const CalendarForm = (props) => {
    const {
        close,
        value,
        recurring,
        weekly,
        onSelect,
        scheduleItem,
        webinars,
        editGroup
    } = props;

    const [intersection, setIntersection] = useState(false);

    const generalFormat = 'DD/M/YYYY';
    const dateMoment = value ? moment(value, generalFormat) : moment();
    const mMonth = dateMoment.format('M');
    const mYear = dateMoment.format('YYYY');
    const mDay = +dateMoment.format('DD');

    const [month, setMonth] = useState(mMonth);
    const [year, setYear] = useState(mYear);
    const [day, setDay] = useState(mDay);

    const [dayArr, setDayArr] = useState([]);
    const [monthArr, setMonthArr] = useState([]);
    const [yearArr, setYearArr] = useState([]);

    const [weeklyArr, setWeeklyArr] = useState([]);
    const [monthlyArr, setMonthlyArr] = useState([]);

    const [startTime, setStartTime] = useState({hoursData: '', minutesData: '', AMPMData: '', name: ''});
    const [endTime, setEndTime] = useState({hoursData: '', minutesData: '', AMPMData: '', name: ''});
    const [AMPM, setAMPM] = useState('');
    const [hoursArr, setHoursArr] = useState([]);
    const [minutesArr, setMinutesArr] = useState([]);
    const [minutesArrEnd, setMinutesArrEnd] = useState([]);
    const [hoursArrEnd, setHoursArrEnd] = useState([]);
    const [errors, setErrors] = useState(null);

    const [dayEndDate, setDayEndDate] = useState(mDay);
    const [monthEndDate, setMonthEndDate] = useState(+mMonth+1);
    const [yearEndDate, setYearEndDate] = useState(mYear);

    const [dayEndDateArr, setDayEndDateArr] = useState([]);
    const [monthEndDateArr, setMonthEndDateArr] = useState([]);
    const [yearEndDateArr, setYearEndDateArr] = useState([]);

    const months = [];

    for (let i = 1; i < 13; i++) {
        months.push({ value: i, label: moment(i, 'M').format('MMMM')})
    }

    const years = [
        moment().format('YYYY'),
        moment().add(1, 'years').format('YYYY'),
        moment().add(2, 'years').format('YYYY'),
        moment().add(3, 'years').format('YYYY'),
    ];

    const date = new Date();
    const currentDay = date.getDate();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const ampm1 = date.getHours() >= 12 ? 'PM' : 'AM';

    const formatStartTime = (momentObj) => {
        setAMPM(momentObj.format("A"));
        setHours(momentObj.format("hh"));
        setMinutes(momentObj.format("mm"));

        setStartTime({
            hoursData: momentObj.format("hh"),
            minutesData: momentObj.format("mm"),
            AMPMData: momentObj.format("A"),
            name: 'start'
        });
    };

    const formatEndTime = (momentObj) => {
        setAmpm(momentObj.format("A"));
        setHoursEnd(momentObj.format("hh"));
        setMinutesEnd(momentObj.format("mm"));

        setEndTime({
            hoursData: momentObj.format("hh"),
            minutesData: momentObj.format("mm"),
            AMPMData: momentObj.format("A"),
            name: 'end'
        });
    };

    useEffect(() => {
        const date = new Date();
        const currentYear = date.getFullYear();

        if(yearEndDate === currentYear && year === yearEndDate) {
            setYearEndDateArr(years);

            if(monthEndDate <= month) {
                setMonthEndDateArr(months.filter(item => item.value >= month));
                setMonthEndDate(months.filter(item => item.value >= month)[0].value);
                if(dayEndDate < day) {
                    setDayEndDate(day);
                }

                setDayEndDateArr(daysInMonth(monthEndDate, yearEndDate).filter(item => item >= day));
            }
            else {
                setMonthEndDateArr(months.filter(item => item.value >= month));
                setDayEndDateArr(daysInMonth(monthEndDate, yearEndDate));
            }
        } else {
            if(year !== yearEndDate ) {
                setYearEndDateArr(years.filter(item => +item >= +year));
                setMonthEndDateArr(months);
                setDayEndDateArr(daysInMonth(monthEndDate, yearEndDate));
            }

            if(year === yearEndDate && yearEndDate !== currentYear) {
                setYearEndDateArr(years.filter(item => +item >= +year));

                if(monthEndDate <= month) {
                    setMonthEndDateArr(months.filter(item => item.value >= month));
                    setMonthEndDate(months.filter(item => item.value >= month)[0].value);

                    if(dayEndDate < day) {
                        setDayEndDate(day);
                    }

                    setDayEndDateArr(daysInMonth(monthEndDate, yearEndDate).filter(item => item >= day));
                }

                if(monthEndDate > month) {
                    setDayEndDateArr(daysInMonth(monthEndDate, yearEndDate));
                }
            }

        }
    }, []);

    useEffect(() => {
        const hoursArr = [];
        const minutesArr1 = [];

        for (let i = 0; i < 60; i++) {

                if (i <= 12 &&  i > 0) {
                    hoursArr.push((i).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}));
                }

            minutesArr1.push(i.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}));

            }

        setMinutesArr(minutesArr1);
        setHoursArr(hoursArr);
        setMinutesArrEnd(minutesArr1);
        setHoursArrEnd(hoursArr);
    }, [])

    useEffect(() => {
        setMonthArr(months.filter(item => item.value >= currentMonth));
        setMonthEndDateArr(months.filter(item => item.value >= currentMonth));
        setYearArr(years);
        setDayArr(daysInMonth(month, year))
    }, []);

    useEffect(() => {
        if(!scheduleItem){
            setDay(moment().date());
            setDayEndDate(moment().date());
            setMonth(moment().format('M'));
            setMonthEndDate(moment().add(1, 'month').format('M'));
            setYear(moment().format('YYYY'));
            setYearEndDate(moment().add(1, 'month').format('YYYY'));

            setDayArr(daysInMonth(parseInt(moment().format('M')), year));
            return;
        }

        const isReccuring = !!scheduleItem.initialData
            && !!(scheduleItem.initialData.weekly || scheduleItem.initialData.monthly);

        setDay(scheduleItem.initialData.startTime.clone().date());
        setMonth(scheduleItem.initialData.startTime.clone().format('M'));
        setYear(scheduleItem.initialData.startTime.clone().format('YYYY'));

        setDayEndDate(scheduleItem.initialData.endTime.clone().date());

        if(isReccuring){
            setMonthEndDate(scheduleItem.initialData.endTime.clone().format('M'));
            setYearEndDate(scheduleItem.initialData.endTime.clone().format('YYYY'));
        } else {
            setMonthEndDate(scheduleItem.initialData.endTime.clone().add(1, 'month').format('M'));
            setYearEndDate(scheduleItem.initialData.endTime.clone().add(1, 'month').format('YYYY'));
        }

        setDayArr(daysInMonth(parseInt(scheduleItem.initialData.startTime.clone().format('M')), year))
        setMonthEndDateArr(months.filter(item => item.value >= scheduleItem.initialData.startTime.clone().format('M')));
        setYearEndDateArr(years);
        setDayEndDateArr(daysInMonth(parseInt(scheduleItem.initialData.endTime.clone().format('M')),
            parseInt(scheduleItem.initialData.endTime.clone().format('YYYY'))));

        formatStartTime(scheduleItem.startTime);
        formatEndTime(scheduleItem.endTime);

        if (isReccuring && scheduleItem.initialData.weekly) {
            setWeeklyArr(scheduleItem.initialData.weeklyArr);
        } else if (isReccuring && scheduleItem.initialData.monthly){
            setMonthlyArr(scheduleItem.initialData.monthlyArr);
        }
    }, [scheduleItem]);

    const customSetDay = value => {
        setDay(value);

        if(+year === +yearEndDate && +month === +monthEndDate && dayEndDate < value) {
            setDayEndDate(value);
        }

    }

    const customSetMonth = value => {

        setMonth(value);

        if(+year === +yearEndDate && month > monthEndDate) {
            setMonthEndDate(value);
        }

        if(currentMonth > value && currentYear == year && dayArr.length >= day) {
            setDayArr(daysInMonth(currentMonth, year));
        } else {
            setDayArr(daysInMonth(value, year));
            if(daysInMonth(value, year).length < day) {

                setDay(daysInMonth(value, year)[0]);
                setDayEndDate(daysInMonth(value, year)[0]);
            }

            if(!daysInMonth(value, year).find(item => +item === +day)) {
                setDay(String(currentDay).length === 1 ? "0" + currentDay : String(currentDay));
            }
        }
    }

    function daysInMonth(month, year, type) {
        let numberOfDays;
        const days = [];
        switch (month) {
            case 2:
                // valid only till 2028 ))
                numberOfDays = year === 2024 ? 29 : 28;
                break;
            case 4: case 6: case 9: case 11:
                numberOfDays = 30;
                break;
            default:
                numberOfDays = 31;
        }

        for (let i = 1; i < numberOfDays+1; i++) {
            days.push(i);
        }

        if(currentYear == year && currentMonth == month &&
            !(scheduleItem && scheduleItem.initialData && scheduleItem.initialData.monthly)) {
            return days.filter(item => item >= currentDay)
        }

        return days;
    };

    const customSetYear = value => {

        if(value == date.getFullYear()) {
            if(month <= currentMonth) {
                setMonth(currentMonth);
                if(day < currentDay) {
                    setDay(currentDay);
                    setDayArr(daysInMonth(currentMonth, value))
                } else {
                    setDayArr(daysInMonth(currentMonth, value))
                }
            }

            setMonthArr(months.filter(item => item.value >= currentMonth));
            setYearArr(years);
            setYear(value);
            return;
        }

        if(month === 2 && day === 29) {
            setDay("28");
        }

        setYear(value);
        setYearArr(years);
        setMonthArr(months);
        setDayArr(daysInMonth(month, value));
    }

    const [ampm, setAmpm] = useState('');
    const [hours, setHours] = useState('');
    const [hoursEnd, setHoursEnd] = useState('');
    const [minutes, setMinutes] = useState('');
    const [minutesEnd, setMinutesEnd] = useState('');

    function formatAMPM() {
        const date = new Date();
        const currentHours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        const currentMinutes = date.getMinutes();
        const time = currentHours * 60 + currentMinutes;
        const timeToStart = 30 - (time % 60);
        let currentTimeInMin;

        if(timeToStart < 15 ) {
            currentTimeInMin = time + timeToStart + 30;
        } else {
            currentTimeInMin = time + timeToStart;
        }

        const hours1 = Math.trunc(currentTimeInMin/60) === 13 ? 1 : Math.trunc(currentTimeInMin/60);
        const minutes = currentTimeInMin % 60;

        if(+hours1 === 12) {
            setAmpm(ampm === "AM" ? "PM" : "AM");
        } else {
            setAmpm(ampm);
        }

        setAMPM(ampm);

        setHours(String(hours1).length === 1 ? "0" + hours1 : String(hours1));
        setHoursEnd(String(hours1 + 1).length === 1 ? "0" + (hours1 + 1) : String(hours1 + 1));
        setMinutes(String(minutes).length === 1 ? "0" + minutes : String(minutes));
        setMinutesEnd(String(minutes).length === 1 ? "0" + minutes : String(minutes));

        setStartTime({
            hoursData: String(hours1).length === 1 ? "0" + hours1 : String(hours1),
            minutesData: String(minutes).length === 1 ? "0" + minutes : String(minutes),
            AMPMData: ampm,
            name: 'start'
        })

        setEndTime({
            hoursData: String(hours1 +1).length === 1 ? "0" + (hours1 + 1) : String(hours1+1),
            minutesData: String(minutes).length === 1 ? "0" + minutes : String(minutes),
            AMPMData: ampm,
            name: 'end'
        })
    }


    useEffect(() => {
        if(!scheduleItem){
            formatAMPM();
        }
    }, [scheduleItem])

    const getTimeString = (number) => {
        return number.toString().length === 1 ? '0' + number : number;
    }

    const getMomentTime = ({hoursData, minutesData, AMPMData}, type) => {
        const resultDate = moment(getTimeString(type === 'startTime' ? day : dayEndDate) + '-' +
            getTimeString(type === 'startTime' ? month : monthEndDate) + '-' + `${type === 'startTime' ? year : yearEndDate}`
            + ' ' + hoursData + ':' + minutesData + ' ' + AMPMData.toLowerCase(), 'DD-MM-YYYY hh:mm a');

        if((type === 'startTime') || recurring){
            return resultDate;
        } else if (type === 'endTime') {
            if(startTime.AMPMData === 'PM' && AMPMData === 'AM'){
                return resultDate.add(1, 'day');
            } else {
                return resultDate;
            }
        }

        return resultDate;
    }

    const getMomentTimeDate = (currentDay, month, year, {hoursData, minutesData, AMPMData}) => {
        return moment(getTimeString(currentDay) + '-' + getTimeString(month) + '-' + year + ' ' + hoursData + ':' + minutesData + ' ' + AMPMData.toLowerCase(), 'DD-MM-YYYY hh:mm a');
    }

    const getPeriodEvents = (dateEnd, startTime, endTime, periodArr, periodType) => {
        const events = [];

        for(let i = 0; i < periodArr.length; i++){
            const period = Math.floor(events.length/periodArr.length);

            if(!period){
                if(periodType === 'week'){
                    const initialDayOfWeek = wdays.indexOf(periodArr[0].day);
                    const currentDayOfWeek = wdays.indexOf(periodArr[i].day);
                    let numberOfDaysToAdd;

                    if(initialDayOfWeek < currentDayOfWeek){
                        numberOfDaysToAdd = currentDayOfWeek - initialDayOfWeek;
                    } else {
                        numberOfDaysToAdd = 7 - initialDayOfWeek + currentDayOfWeek;
                    }

                    if(!events.filter(item => !!item).length){
                        if(wdays.indexOf(periodArr[i].day) >= startTime.day()){
                            events.push({
                                ...periodArr[i],
                                day: wdays.indexOf(periodArr[i].day),
                                time: startTime.clone(),
                                startTime: startTime.clone(),
                                endTime: endTime.clone()
                            })
                        } else {
                            events.push(null);
                        }
                    } else {
                        events.push({
                            ...periodArr[i],
                            day: wdays.indexOf(periodArr[i].day),
                            time: startTime.clone().add(numberOfDaysToAdd, 'day'),
                            startTime: startTime.clone().add(numberOfDaysToAdd, 'day'),
                            endTime: endTime.clone().add(numberOfDaysToAdd, 'day')
                        })
                    }
                } else {
                    const initial = periodArr.filter(item => !!item).find(item => item.disable);
                    const initialDayOfMonth = initial ? initial.day : periodArr[0].day;
                    const currentDayOfMonth = periodArr[i].day;
                    const numberOfDaysToAdd = currentDayOfMonth >= initialDayOfMonth ? currentDayOfMonth - initialDayOfMonth : -1;

                    if(!events.filter(item => !!item).length){
                        if(numberOfDaysToAdd !== -1 ){
                            events.push({
                                ...periodArr[i],
                                time: startTime.clone(),
                                startTime: startTime.clone(),
                                endTime: endTime.clone()
                            })
                        } else {
                            events.push(null);
                        }
                    } else {
                        events.push({
                            ...periodArr[i],
                            time: startTime.clone().add(numberOfDaysToAdd, 'day'),
                            startTime: startTime.clone().add(numberOfDaysToAdd, 'day'),
                            endTime: endTime.clone().add(numberOfDaysToAdd, 'day'),
                        })
                    }
                }

            } else {
                if(periodType === 'week'){
                    const initialDayOfWeek = events.filter(item => !!item)[0] ? events.filter(item => !!item)[0].day : periodArr[0].day;
                    const currentDayOfWeek = wdays.indexOf(periodArr[i].day);
                    let numberOfDaysToAdd;

                    if(initialDayOfWeek <= currentDayOfWeek){
                        numberOfDaysToAdd = currentDayOfWeek - initialDayOfWeek + 7 * period;
                    } else {
                        numberOfDaysToAdd = 7 * period - initialDayOfWeek + currentDayOfWeek;
                    }

                    const dateTime = startTime.clone().add(numberOfDaysToAdd, 'day');

                    if(dateTime.unix() <= dateEnd.unix()){
                        events.push({
                            ...periodArr[i],
                            day: wdays.indexOf(periodArr[i].day),
                            time: dateTime,
                            startTime: dateTime.clone(),
                            endTime: endTime.clone().add(numberOfDaysToAdd, 'day')
                        })
                    } else {
                        break;
                    }
                } else {
                    const currentDayOfMonth = periodArr[i].day;
                    const daysInNextMonth = startTime.clone().add(period, 'month').daysInMonth();


                    if(daysInNextMonth >= currentDayOfMonth){
                        const currentYear = startTime.clone().add(period, 'month').year();
                        const currentMonth = startTime.clone().add(period, 'month').month();
                        const dateTime = moment(`${currentYear}-${currentMonth}-${currentDayOfMonth} ${startTime.clone().format('hh:mm a')}`, 'YYYY-MM-DD hh:mm a')
                        const duration = endTime.clone().diff(startTime.clone(), 'minutes');

                        if(dateTime.clone().add(duration, 'minutes').unix() <= dateEnd.unix()){
                            events.push({
                                ...periodArr[i],
                                time: dateTime.clone(),
                                startTime: dateTime.clone(),
                                endTime: dateTime.add(duration, 'minutes')
                            })
                        } else {
                            break;
                        }
                    } else {
                        events.push(null)
                    }
                }
            }

            if(i === periodArr.length - 1){
                i = -1;
            }
        }

        const currentDate = moment();
        const filtered = events
            .filter(item => !!item)
            .filter(item => item.startTime.clone().unix() > currentDate.unix())
            .map(i => ({...i, filter: i.time.toISOString()}));
        return [...new Map(filtered.map(item => [item['filter'], item])).values()];
    }

    const isIntersection = (webinars, newArr) => {
        let intersection;
        const valId = scheduleItem && scheduleItem.id;
        const selectedWebinars = valId ? webinars.filter(w => w.id !== valId) : webinars;

        if(!selectedWebinars){
            return;
        }

        selectedWebinars.forEach(item => {
            newArr.forEach(newItem => {
                if(!newItem.startTime || !newItem.endTime || !item.startTime || !item.endTime){
                    return;
                }

                if(newItem.startTime.clone().isBetween(item.startTime.clone(), item.endTime.clone()) || newItem.endTime.clone().isBetween(item.startTime.clone(), item.endTime.clone())){
                    intersection = newItem;
                }
                if(newItem.startTime.clone().toISOString() === item.startTime.clone().toISOString() || newItem.endTime.clone().toISOString() === item.endTime.clone().toISOString()){
                    intersection = newItem;
                }
            })
        });

        if(intersection){
            setIntersection(intersection);
        } else {
            setIntersection(false);
        }

        return intersection;
    }

    useEffect(() => {
        setStartTime({hoursData: hours, minutesData: minutes, AMPMData: AMPM, name: 'start'})
    }, [hours, minutes, AMPM])

    useEffect(() => {
        setEndTime({hoursData: hoursEnd, minutesData: minutesEnd, AMPMData: ampm, name: 'end'})
    }, [hoursEnd, minutesEnd, ampm])

    const handlerSubmit = (withoutSelect) => {
        const startMomentTime = getMomentTime(startTime, 'startTime');
        const endMomentTime = getMomentTime(endTime, 'startTime');
        const duration = endMomentTime.clone().diff(startMomentTime.clone(), 'minutes');

        if(!withoutSelect){
            if((duration < 60 && duration >= 0) || duration > 60*8){
                setErrors('Duration should be from 1 to 8 hours');
                return;
            }

            if(duration < 0){
                setErrors('Start time need to be before end time');
                return;
            }

            if(startMomentTime.unix() < moment().unix()){
                setErrors('Your meeting cannot start in past');
                return;
            }
        }

        if(!recurring) {
            const intersection = isIntersection(webinars, [{
                startTime: getMomentTime(startTime, 'startTime'),
                endTime: getMomentTime(endTime, 'startTime'),
            }]);

            if(!intersection && !withoutSelect){
                onSelect({
                    date: getTimeString(day) + '/' + getTimeString(month) + '/' + year,
                    startTime: getMomentTime(startTime, 'startTime'),
                    endTime: getMomentTime(endTime, 'startTime'),
                });
                close();
            }

        } else {
            if(weekly) {
                const weekly = getPeriodEvents(getMomentTimeDate(dayEndDate, monthEndDate, yearEndDate, endTime),
                    startMomentTime, endMomentTime, weeklyArr, 'week');
                const intersection = isIntersection(webinars, weekly);

                if(!intersection && !withoutSelect){
                    onSelect({
                        date: getTimeString(day) + '/' + getTimeString(month) + '/' + year,
                        startTime: getMomentTime(startTime, 'startTime'),
                        endTime: getMomentTime(endTime, 'endTime'),
                        dateEnd: getTimeString(dayEndDate) + '/' + getTimeString(monthEndDate) + '/' + year,
                        weeklyArr,
                        weekly,
                    });
                    close();
                }
            } else {
                const monthly = getPeriodEvents(getMomentTimeDate(dayEndDate, monthEndDate, yearEndDate, endTime),
                    startMomentTime, endMomentTime, monthlyArr, 'month');
                const intersection = isIntersection(webinars, monthly);

                if(!intersection && !withoutSelect){
                    onSelect({
                        date: getTimeString(day) + '/' + getTimeString(month) + '/' + year,
                        startTime: getMomentTime(startTime, 'startTime'),
                        endTime: getMomentTime(endTime, 'endTime'),
                        dateEnd: getTimeString(dayEndDate) + '/' + getTimeString(monthEndDate) + '/' + year,
                        monthlyArr,
                        monthly,
                    });
                    close();
                }
            }
        }

        setErrors(null);
    };

    const generateUniqId = (val) => val  + (new Date()).getTime() + Math.random();

    useEffect(() => {
        handlerSubmit(true)
    }, [startTime, endTime, month ,monthEndDate, yearEndDate, year, weeklyArr, monthlyArr])

    const handleChangeHoursAndMinutesStart = (event) => {
        let time = +event.target.value;

             if (event.target.name === "hours") {
                 setHours(String(time).length === 1 ? "0" + (time) : String(time));

                 if(time === 12) {
                     setHoursEnd("01");
                     setAmpm(prevState => prevState === "AM" ? "PM" : "AM")
                 } else {
                     setHoursEnd(String(time+1).length === 1 ? "0" + (time+1) : String(time+1));

                     if(AMPM !== ampm) {
                         setAmpm(AMPM)
                     }
                 }

                 setMinutesEnd(String(minutes).length === 1 ? "0" + (minutes) : String(minutes));
             }


        if (event.target.name === "minutes") {
            setMinutes(String(time).length === 1 ? "0" + (time) : String(time));
            setMinutesEnd(String(time).length === 1 ? "0" + (time) : String(time));
        }
    }

    const handleChangeHoursAndMinutesEnd = (event) => {
        const time = +event.target.value;
        if (event.target.name === "hours") {
            setHoursEnd(String(time).length === 1 ? "0" + (time) : String(time));
        }

        if (event.target.name === "minutes") {
            setMinutesEnd(String(time).length === 1 ? "0" + (time) : String(time));
        }
    }

   const handleChangeAMPMEnd = (event) => {
       if (event.currentTarget.innerText === "PM") {
           setAmpm("AM");
       } else {
           setAmpm("PM");
       }
   }


    const handleChangeAMPMStart = (event) => {
        if(event.currentTarget.innerText === "AM") {
            setAMPM("PM");
        } else if(event.currentTarget.innerText === "PM") {
            setAMPM("AM");
        }
    }

    useEffect(() => {
        if(year === yearEndDate && monthEndDate === month && dayEndDate < day) {
            setDayEndDate(day);
        }

        if(year === yearEndDate && parseInt(monthEndDate) < parseInt(month)) {
            setMonthEndDate(month);
                if(dayEndDate < day) {
                    setDayEndDate(day);
                }
        }

        if(year > yearEndDate) {
            setYearEndDate(year);

            if (month <= monthEndDate) {
                setMonthEndDate(month);

                    if(dayEndDate < day) {
                        setDayEndDate(day);
                    }
            }
        }

    }, [day, month, year])

    const handleChangeMonthEndDate = value => {
        setMonthEndDate(value);
       if (+dayEndDate === 31 && (+value === 4 || +value === 6 || +value === 9 || +value === 11)) {
           setDayEndDate("30");
       }

        if( dayEndDate > 28 && +value === 2) {
            if(+yearEndDate === 2024) {
                setDayEndDate("29");
            } else {
                setDayEndDate("28");
            }
        }

        setDayEndDateArr(daysInMonth(parseInt(value), year));
    }

    const handleChangeYearEndDate = value => {
        setYearEndDate(value);

        if( +dayEndDate === 29 && +monthEndDate === 2 && +value !== 2024) {
            setDayEndDate("28");
        }

        if(+value === +currentYear && +monthEndDate > +month) {
            setMonthEndDate(month);

            if(day > dayEndDate) {
                setDayEndDate(day);
            }
        }

        if(+value === +currentYear && +monthEndDate === +month && +dayEndDate < +day) {
            setDayEndDate(day);
        }
    }

    return (
        <div className={classNames("selectsBlock", 'calendarSelectsBlock')}>
            {!recurring && <div className="space"></div>}
            {recurring && weekly && <div>
                  <div className="selectDaysTitle">Select Days of Week</div>
                  <Weekly className='wdayButton'
                          day={day}
                          month={month}
                          year={year}
                          dayEndDate={dayEndDate}
                          monthEndDate={monthEndDate}
                          yearEndDate={yearEndDate}
                          isUpdating={!!scheduleItem && !!scheduleItem.initialData && !!scheduleItem.initialData.weekly}
                          weeklyArr={weeklyArr}
                          setWeeklyArr={setWeeklyArr}
                  />
            </div>}
            {recurring && !weekly &&
                <Monthly
                    dayArr={dayArr}
                    day={day}
                    month={month}
                    year={year}
                    dayEndDate={dayEndDate}
                    monthEndDate={monthEndDate}
                    yearEndDate={yearEndDate}
                    isUpdating={!!scheduleItem && !!scheduleItem.initialData && !!scheduleItem.initialData.monthly}
                    monthlyArr={monthlyArr}
                    setMonthlyArr={setMonthlyArr}
                />}
                {recurring && <div className="scheduleTitle">
                    <img src={ClockIcon} alt="clock"/>
                    <div>Start Date</div>
                </div>}
            <FormControl className="selectDay">
                <Select
                    labelId="day"
                    id="day-select"
                    value={day}
                    onChange={(data) => customSetDay(data.target.value)}
                    defaultValue={day}
                >
                    {dayArr.map(item => (
                        <MenuItem value={item} key={item} selected={item == day}>{item}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className="selectMonth">
                <Select
                    labelId="month"
                    id="month-select"
                    value={month}
                    onChange={(data) => customSetMonth(data.target.value)}
                    defaultValue={month}
                >
                    {monthArr.map(item => (
                        <MenuItem value={item.value} key={item.value} selected={item.value == month}>{item.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className="selectYear">
                <Select
                    labelId="year"
                    id="year-select"
                    value={year}
                    onChange={(data) => customSetYear(data.target.value)}
                    defaultValue={year}
                >
                    {yearArr.map(item => (
                        <MenuItem value={item} key={item} selected={item == year}>{item}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="scheduleTitle">
                <img src={CalendarIcon} alt="calendar"/>
                <div>Start Time</div>
            </div>
            <div className="datePicker">
                <Select
                    native
                    value={hours}
                    onChange={handleChangeHoursAndMinutesStart}
                    inputProps={{
                        name: 'hours',
                        id: 'hours',
                    }}
                >
                    {hoursArr.map(value => <option value={value} key={generateUniqId(value)}>{value}</option>)}
                </Select>
                <span>:</span>
                <Select
                    native
                    value={(String(minutes).length === 1 ? "0" + minutes : String(minutes))}
                    onChange={handleChangeHoursAndMinutesStart}
                    inputProps={{
                        name: 'minutes',
                        id: 'minutes',
                    }}
                >
                    {minutesArr.map(value => <option value={value} key={generateUniqId(value)}>{value}</option>)}
                </Select>
                <span/>
                <button
                    className={"PM"}
                    onClick={handleChangeAMPMStart}
                >
                    {AMPM}
                    {/*{day == currentDay && month == currentMonth &&*/}
                    {/* year == currentYear && ampm1 === "PM" ? "" : <img src={ChangeIcon} alt="change"/>}*/}
                    <img src={ChangeIcon} alt="change"/>
                </button>
            </div>
            <div className="scheduleTitle">
                <img src={CalendarIcon} alt="calendar"/>
                <div>End Time</div>
            </div>
            <div className="datePicker">
                <Select
                    native
                    value={hoursEnd}
                    onChange={handleChangeHoursAndMinutesEnd}
                    inputProps={{
                        name: 'hours',
                        id: 'hours',
                    }}
                >
                    {hoursArrEnd.map(value => <option value={value} key={generateUniqId(value)}>{value}</option>)}
                </Select>
                <span>:</span>
                <Select
                    native
                    value={(String(minutesEnd).length === 1 ? "0" + minutesEnd : String(minutesEnd))}
                    onChange={handleChangeHoursAndMinutesEnd}
                    inputProps={{
                        name: 'minutes',
                        id: 'minutes',
                    }}
                >
                    {minutesArrEnd.map(value => <option value={value} key={generateUniqId(value)}>{value}</option>)}
                </Select>
                <span />
                <button
                    className={""}
                    onClick={handleChangeAMPMEnd}
                >
                    {ampm}
                    {/*{day != currentDay && month != currentMonth && year != currentYear && AMPM != "AM" && +hours === 12 && <img src={ChangeIcon} alt="change"/>}*/}
                    <img src={ChangeIcon} alt="change"/>
                </button>
            </div>
            { recurring && <div>
                    <div className="scheduleTitle">
                        <img src={ClockIcon} alt="clock"/>
                        <div>End Date</div>
                    </div>
                <FormControl className="selectDay">
                    <Select
                        labelId="day"
                        id="day-select"
                        value={dayEndDate}
                        onChange={(data) => setDayEndDate(data.target.value)}
                        defaultValue={dayEndDate}
                    >
                        {dayEndDateArr.map(item => (
                            <MenuItem value={item} key={item} selected={item == dayEndDate}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className="selectMonth">
                    <Select
                        labelId="month"
                        id="month-select"
                        value={monthEndDate}
                        onChange={(data) => handleChangeMonthEndDate(data.target.value)}
                        defaultValue={monthEndDate}
                    >
                        {monthEndDateArr.map(item => (
                            <MenuItem value={item.value} key={item.value} selected={item.value == monthEndDate}>{item.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className="selectYear">
                    <Select
                        labelId="year"
                        id="year-select"
                        value={yearEndDate}
                        onChange={(data) => handleChangeYearEndDate(data.target.value)}
                        defaultValue={yearEndDate}
                    >
                        {yearEndDateArr.map(item => (
                            <MenuItem value={item} key={item} selected={item == yearEndDate}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>}
            {intersection &&
            <div style={{marginTop: "30px", color: "red"}}>{`Already scheduled ${intersection.startTime.format('hh:mm')} - ${intersection.endTime.format('hh:mm')} ${intersection.startTime.format('DD MMMM YYYY')}`}</div>}
            {errors && <div style={{marginTop: "30px", color: "red"}}>{errors}</div>}

            <div className="space"></div>
            <Button violetGradien width100 label="OK" onClick={() => handlerSubmit()} disabled={intersection}/>
        </div>
    );
};

export default CalendarForm;