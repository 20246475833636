import React, { memo } from 'react';
import classNames from 'classnames';
import SimplePage from '../SimplePage';
import MainMenu from './Components/MainMenu';
import MessagesGlobal from '../../components/MessagesGlobal';

import './page.scss';

const SearchPage = ({ children, className, hideMenu, applySearch, searchParams }) => (
  <SimplePage className={classNames('searchPage', className)}>
    {!hideMenu && <MainMenu applySearch={applySearch} searchParams={searchParams} />}
    <main className="searchContentCenter" style={{ flexDirection: 'column', width: 515 }}>
      {children}
    </main>
    <MessagesGlobal />
  </SimplePage>
);

export default memo(SearchPage);
