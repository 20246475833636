import React from 'react';
import { inject, observer } from 'mobx-react';
import CustomPopover from '../../../../components/Popover';
import TimePickerButton from '../../../../components/DatePickerButton/DatePickerButton';
import TimePicker from '../../../../components/TimePicker';

const TimeFilter = props => {
  const {
    groupsModel: {
      state: { filters },
      changeFilterTime
    },
  } = props;
  return <>
    <CustomPopover
      myBuyyon={<TimePickerButton label={filters.time.start} className="datePickerBlock" />}
    >
      <TimePicker value={filters.time.start} onClick={time => changeFilterTime("start", time)} name="start" />
    </CustomPopover>
    <span>-</span>
    <CustomPopover
      myBuyyon={<TimePickerButton label={filters.time.end} className="datePickerBlock" />}
    >
      <TimePicker value={filters.time.end} name="end" onClick={time => changeFilterTime("end", time)} />
    </CustomPopover>
  </>
}

export default inject('groupsModel')(observer(TimeFilter));
