import React, { useEffect } from "react";
import Dialog from "../../Dialog";
import Button from "../../Button";
import EditPlaylist from "../EditPlaylist";
import PlaylistEmpty from "../PlaylistEmpty";
import MyPlayer from "../../MyPlayer";
import ViewPlaylistIcon from "../../../res/icons/delete_playlist.svg";
import EditPlaylistIcon from "../../../res/icons/edit _playlist.svg";
import RecomendationItem from "../../ContentRecomendations/RecomendationItem";

import "./styles.scss";
const ViewPlaylist = (props) => {
  const {
    children,
    playlist,
    playlistMeta,
    handleDelete,
    handleUpdate,
    handleDeleteItem,
    handleUpdatePlaylistItems,
    handleSubmit,
  } = props;

  return (
    <div className="playlist_list_item_view">
      <Dialog
        openButton={children}
        className="view_playlist_dialog"
        closeColor="#FFFFFF"
      >
        <>
          <div className="view_playlist_dialog-header">
            <img
              className="view_playlist_dialog-header_preview"
              src={
                playlist?.items && playlist?.items[0]
                  ? playlist?.items[0].image.url
                  : null
              }
            />
            <div className="view_playlist_dialog-header_info">
              <div className="view_playlist_dialog-header_title">
                {playlist.title}
              </div>
              <div className="view_playlist_dialog-header_meta">
                {playlistMeta}
              </div>
            </div>
          </div>

          <div className="view_playlist_dialog-actions">
            {playlist?.items && playlist?.items.length > 0 ? (
              <MyPlayer
                playlist={playlist}
                record_info={playlist.items[0]}
                owner={playlist.items[0].owner}
              >
                <div className="btn_wrapper">
                  <Button
                    label="PLAY ALL"
                    violetGradien
                    disabled={false}
                    width100
                    big60
                  />
                </div>
              </MyPlayer>
            ) : (
              <div className="btn_wrapper">
                <Button
                  label="PLAY ALL"
                  violetGradien
                  disabled={true}
                  width100
                  big60
                />
              </div>
            )}

            <EditPlaylist
              playlist={playlist}
              handleSubmit={handleSubmit}
              handleDeleteItem={handleDeleteItem}
              handleUpdatePlaylistItems={handleUpdatePlaylistItems}
            >
              <div className={"view_playlist_dialog_btn"}>
                <img src={EditPlaylistIcon} alt="DeletePlaylistIcon" />
                Edit
              </div>
            </EditPlaylist>
            {playlist.playlist_type === "custom" && (
              <div
                className={"view_playlist_dialog_btn"}
                onClick={handleDelete}
              >
                <img src={ViewPlaylistIcon} alt="ViewPlaylistIcon" />
                Delete
              </div>
            )}
          </div>
          <div className="view_playlist_dialog-playlist_items">
            {playlist.items && playlist.items.length > 0 ? (
              playlist.items.map((recomendation) => (
                <MyPlayer
                  playlist={playlist}
                  record_info={recomendation}
                  owner={recomendation.owner}
                >
                  <div>
                    <RecomendationItem
                      className="playlist_item"
                      recomendation={recomendation}
                    />
                  </div>
                </MyPlayer>
              ))
            ) : (
              <PlaylistEmpty />
            )}
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default ViewPlaylist;
