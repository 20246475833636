import {withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const FormSwitcherDarkViolet= withStyles((theme) => ({
  root: {
    width: 30,
    height: 18,
    padding: 0,
    display: 'flex',
    margin: `0 15px 0 0`,
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#43425D',
        borderColor: '#43425D',
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: 'none',
    backgroundColor:  theme.palette.common.white,
  },
  track: {
    // border: `1px solid transparent`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#DADADA",
  },
  checked: {},
}))(Switch);

export default FormSwitcherDarkViolet