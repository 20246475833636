import React, { memo }  from 'react';
import Button from '../Button';
import CustomSneckbar from '../CustomSnackbar';

const Message = props => {
  const { close, onDelete, resualtMessage, changeResultMessage } = props;
  return (
    <div className="messageDeleteBlock">
      <div className="messageDeleteTitle">Delete Payment Method</div>
      <div className="messageDeleteText">Are you sure you want to delete this payment method?</div>
      <div className="messageDeleteActions">
        <Button label="Cancel" className="MDCancel" onClick={() => close()} />
        <Button label="Delete" className="MDDelete" onClick={() => onDelete()} />
      </div>
      <CustomSneckbar 
        message={resualtMessage}
        onClose={() => { changeResultMessage(''); close(); }}
        severity={resualtMessage === 'Success' ? "success" : 'error'}
      />
    </div>
  )
}

export default memo(Message);
