import { observable, decorate } from 'mobx';
import axios from 'axios';

import { baseAPI } from '../const/settings';

export class PageModel {
  constructor() {
    this.privacy_policy = null;
    this.terms_and_conditions = null;
    this.cookie_policy = null;
    this.other_page = null;
    this.resultMessage = null;
    this.getPage('privacy_policy');
    this.getPage('cookie_policy');
    this.getPage('terms_and_conditions');
    this.getPage('other_page');
  }

  setPage = (data, key) => this[key] = data;
  
  getPage = key => {
    const setPage = data => this.setPage(data, key);
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
      }};
    axios.get(`${baseAPI}pages/${key}`, headers)
      .then(function (response) {
        // handle success
        setPage(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }

  sendContact = data => {
    const setPage = data => this.setPage(data, 'resultMessage');
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
      }};
    axios.post(`${baseAPI}contacts`, data.values, headers)
      .then(function (response) {
        setPage('Success');
        data.action();
      })
      .catch(function (error) {
        // handle error
        setPage('Error');
        // action();
      })
      .finally();
  }
}

decorate(PageModel, {
  privacy_policy: observable,
  terms_and_conditions: observable,
  cookie_policy: observable,
  other_page: observable,
  resultMessage: observable,
})

export const pageModel = new PageModel();
