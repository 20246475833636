import { observable, decorate, action } from 'mobx';
import axios from 'axios';
import { baseAPI } from '../const/settings';
import { authModel } from './auth';
import { groupsModel } from './groups';
import { handleAWSS3FileUpload } from '../units/services/aws_amplify';
import {detectUploadFileType} from "../units/func";

export class FileUploadingModel {
  constructor() {
    this.isUploading = false;
    this.formData = null;
    this.filesToUpload = [];
    this.handleSuccess = null;
  }

  setIsUploading = (value) => {
    this.isUploading = value;
    window.onbeforeunload = value ?  () => "You cant do it" : null;
  };

  abortUploading = () => {
    this.setIsUploading(false);
    window.stop();
    this.formData = null;
    this.filesToUpload = [];
  }
  
  updateStatus = (id, status) => {
    this.filesToUpload = this.filesToUpload.map(item => {
      return item.id == id ? {...item, status: status} : item;
    })
  }

  setUploadedFileUrl = (id, url) => {
    this.filesToUpload = this.filesToUpload.map(item => {
      return item.id == id ? {...item, url: url} : item;
    })
  }

  updateProgress = (id, progress) => {
    const progressInPercents = (progress.loaded / progress.total) * 100;
    this.filesToUpload = this.filesToUpload.map(item => {
      return item.id == id ? {...item, progress: progressInPercents} : item;
    })
  }

  isAllUploaded = () => {
    return this.filesToUpload.filter(item => item.status !== 'uploaded').length === 0;
  }

  handleSuccessFileUpload = (uploaded_file_url, file) => {
    const file_url = uploaded_file_url.split("?")[0]; // remove query from url
    this.formData.append(`files[${file.id}][theme]`, file.file_data.meta.name);
    this.formData.append(`files[${file.id}][url]`, file_url);
    this.formData.append(`files[${file.id}][duration]`, file.file_data.meta.duration);
    this.formData.append(`files[${file.id}][position]`, file.id);
    this.formData.append(`files[${file.id}][file_type]`, detectUploadFileType(file.file_data.file));
    this.updateStatus(file.id, 'uploaded');
    this.setUploadedFileUrl(file.id, file_url);
   if(this.isAllUploaded()){
     this.handleSuccess(this.formData);
     this.setIsUploading(false);
   }

}

  handleFilesUpload = (files, form, onSuccess) => {
    this.setIsUploading(true);
    this.formData = form;
    this.handleSuccess = onSuccess;

    this.filesToUpload = files.map((file, key) => {
      return {id: key, file_data: file, status: 'uploading', progress: 0}
    });

    this.filesToUpload.map((item) => {
      this.uploadFile(item);
    })
  }

  uploadFile = (item) => {
    handleAWSS3FileUpload(
      `${item.id}${item.file_data.file.name}`, 
      item.file_data.file, 
      (progress) => this.updateProgress(item.id, progress)
    ).then(r => {
      this.handleSuccessFileUpload(r, item);
    }).catch(r => {
      //make error handler
    })
  }
}

decorate(FileUploadingModel, {
  isUploading: observable,
  setIsUploading: action,
  handleFilesUpload: action,
  abortUploading: action,
  filesToUpload: observable,
})

export const fileUploadingModel = new FileUploadingModel();
