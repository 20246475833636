import { decorate, action, observable } from "mobx";
import axios from "axios";
import { baseAPI } from "../const/settings";
import { authModel } from "./auth";
import { userModel } from "./user";
import { meetingsModel } from "./meetings";

export class PlaylistModel {
  constructor() {
    this.recomendations = [];
    this.currentMedia = null;
    this.myPlaylists = [];
  }

  showPlaylistIcon = (mediaGroup) =>{
    if(userModel.isOwner(mediaGroup.owner)){
      return true
    }else if(!mediaGroup.available_for_subscribers && !mediaGroup.price){
      return true
    }else if(!!mediaGroup.price && (mediaGroup.purchase || (mediaGroup.available_for_subscribers && mediaGroup.have_subscription))){
      return true
    }else if(mediaGroup.available_for_subscribers && mediaGroup.have_subscription){
      return true
    }else if(mediaGroup.invited){
      return true
    }else{
      return false
    }
  }

  getReqHeaders = () => {
    return {
      headers: {
        accept: "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      },
    };
  };

  updateMyPlaylistItems = (playlist_id, newItems) => {
    let playlistCopy = this.myPlaylists;
    this.myPlaylists = playlistCopy.map((playlist) =>
      playlist.id === playlist_id ? { ...playlist, items: newItems } : playlist
    );
  };

  setCurrentMedia = (media) => {
    this.currentMedia = media;
    meetingsModel.incrementMeetingViewsCounter(
      media?.meeting_id ? media?.meeting_id : media.id
    );
  };

  updatePlaylistItems = (playlist, currentMediaIdViaProps = null) => {
    let newPlaylist = { ...playlist };
    if (!newPlaylist.items) newPlaylist.items = [];
    const playlistId = playlist.id;
    const playlistItems = newPlaylist.items;
    const isInPlaylistIndex = (currentMediaIdViaProps && Array.isArray(currentMediaIdViaProps)) ? 
      currentMediaIdViaProps.map(videoId => playlistItems.findIndex(i => i.meeting_id === videoId)): 
      playlistItems.findIndex(
        (i) => i.meeting_id === currentMediaIdViaProps ? currentMediaIdViaProps : this.currentMedia?.id
      );
    if ((Array.isArray(isInPlaylistIndex) && !isInPlaylistIndex.some(i => !(i < 0))) || isInPlaylistIndex < 0) {
      if(currentMediaIdViaProps && Array.isArray(currentMediaIdViaProps)){
        currentMediaIdViaProps.filter(video_id => newPlaylist.items.push({ meeting_id: video_id }))
      }else{
        newPlaylist.items.push({ meeting_id: currentMediaIdViaProps ? currentMediaIdViaProps : this.currentMedia?.id });
      }
      this.updatePlaylist(playlistId, newPlaylist);
    } else if(Array.isArray(isInPlaylistIndex)) {
      isInPlaylistIndex.filter(i => this.deleteItem(playlistId, playlistItems[i].id))
    } else {
      this.deleteItem(playlistId, playlistItems[isInPlaylistIndex].id);
    }
  };

  getRecomendationsData = (id) => {
    let url = `${baseAPI}playlists/recommendations?user_id=${id}`;
    axios
      .get(url, this.getReqHeaders())
      .then((response) => {
        this.recomendations = response.data.records;
      })
      .catch(function (error) {
        console.log("error", error);
      })
      .finally();
  };

  getMyPlaylists = () => {
    let url = `${baseAPI}playlists?sort_column=created_at&per_page=10000`;
    axios
      .get(url, this.getReqHeaders())
      .then((response) => {
        this.myPlaylists = response.data.playlists;
      })
      .catch(function (error) {
        console.log("error", error);
      })
      .finally();
  };

  getPlaylist = (id) => {
    let url = `${baseAPI}playlists/${id}`;
    axios
      .get(url, this.getReqHeaders())
      .then((response) => {
        //console.log(response);
      })
      .catch(function (error) {
        console.log("error", error);
      })
      .finally();
  };

  createPlaylist = (data, handleSuccess) => {
    let url = `${baseAPI}playlists`;
    axios
      .post(url, data, this.getReqHeaders())
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log("error", error);
      })
      .finally(() => {
        this.getMyPlaylists();
        handleSuccess();
      });
  };

  updatePlaylist = (id, data) => {
    let url = `${baseAPI}playlists/${id}`;
    const body = { ...data, items: this.updatePlaylistItemsPosition(data) };
    axios
      .put(url, body, this.getReqHeaders())
      .then((response) => {
        this.getMyPlaylists();
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  deletePlaylist = (id) => {
    let url = `${baseAPI}playlists/${id}`;
    axios
      .delete(url, this.getReqHeaders())
      .then((response) => {
        this.getMyPlaylists();
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  deleteItem = (play_list_id, item_id) => {
    let url = `${baseAPI}playlists/${play_list_id}/remove?item_id=${item_id}`;
    axios
      .delete(url, this.getReqHeaders())
      .then((response) => {
        this.removeItemFromPlaylist(play_list_id, item_id);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  removeItemFromPlaylist = (playlist_id, item_id) => {
    this.myPlaylists = this.myPlaylists.map((playlist) => {
      if (playlist.id !== playlist_id) {
        return playlist;
      }
      return {
        ...playlist,
        items: playlist.items.filter((item) => item.id !== item_id),
      };
    });
  };

  updatePlaylistItemsPosition = (playlist = []) => {
    return playlist.items?.map((item, key) => {
      return { ...item, position: key };
    });
  };
}

decorate(PlaylistModel, {
  recomendations: observable,
  currentMedia: observable,
  myPlaylists: observable,
  getRecomendationsData: action,
  setCurrentMedia: action,
  deletePlaylist: action,
  updatePlaylist: action,
  createPlaylist: action,
  getPlaylist: action,
  getMyPlaylists: action,
  updatePlaylistItems: action,
  deleteItem: action,
  updateMyPlaylistItems: action,
  showPlaylistIcon: action
});

export const playlistModel = new PlaylistModel();
