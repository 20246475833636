import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { Field } from 'formik';
import AntSwitchWhiteBlue from './AntSwitchWhiteBlue'
import AntSwitchBlue from './AntSwitchBlue'

const useStyles = makeStyles({
  swicherBase: {
    marginBottom: 30,
  },
  swicherBlock: {
    background: '#FFFFFF',
    border: (props) => `1px solid ${props.color}`,
    boxSizing: 'border-box',
    'border-radius': '4px',
    display: 'flex',
    'align-items': 'center',
    padding: '12px 20px',
    'font-family': 'Red Hat Display',
    'font-style': 'normal',
    'font-weight': 'normal',
    'font-size': '14px',
    'line-height': '19px',
    color: (props)=> props.color,
  },
  swicherTitleActive: {
    opacity: 1,
  },
  swicherTitleInActive: {
    opacity: 0.5,
  }
});

// const AntSwitch = withStyles((theme) => ({
//   switchBase: {
//     color: theme.palette.grey[500],
//     '&$checked': {
//       '& + $track': {
//         opacity: 1,
//       },
//     },
//   },
// }))(Switch);


const CustomizedSwitche = props => {
  const { 
    color,
    name, 
    onSwitch,
    falseLabel = "Add falseLabel prop",
    trueLabel = "Add trueLabel prop",
    alpha,
  } = props;

  const classes = useStyles(props);

  const getSwitch = (value) => {
    switch(color){
      case '#4E519F': {
        return (
          <AntSwitchBlue
            style={{color: value? "white" : color}}
            checked={value}
            onChange={(e) => onSwitch(e.target.checked)}
          />
        )
      }
      default: {
        return (
          <AntSwitchWhiteBlue
            style={{color: value? "white" : color}}
            checked={value}
            onChange={(e) => onSwitch(e.target.checked)} 
          />
        )
      }
    }
  }

  return (
    <Field name={name}>
      {({
          field: { name, value, onChange, onBlur },
          form: { touched, errors, isValid },
          meta,
        }) => {
          return (
            <FormGroup className={classes.swicherBase}>
              <Typography  component="div" className={classes.swicherBlock}>
                <span
                  className={alpha ? (!value ? classes.swicherTitleActive : classes.swicherTitleInActive) : null}
                >
                  {falseLabel}
                </span>
                {getSwitch(value, color)}
                <span
                  className={alpha ? (!!value ? classes.swicherTitleActive : classes.swicherTitleInActive) : null}
                >
                  {trueLabel}
                </span>
              </Typography>
            </FormGroup>
          );
        }
      }
    </Field>
  );
}

export default CustomizedSwitche;
