import { observable, decorate, action } from 'mobx';
import axios from 'axios';

import { baseAPI } from '../const/settings';
import { authModel } from './auth';

export class TagsModel {
  constructor() {
    this.tags = null;
  }

  setTags = data => this.tags = data;
  
  getTags = (session_token, community = null) => {
    const setTags = this.setTags;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": session_token ? session_token : authModel.session_token,
      }};
    let url = `${baseAPI}tags`;
    if (community) {
      //url = `${baseAPI}tags?[community]=${community}`;
      url = `${baseAPI}tags?[communities_ids]=${community}`;
    }

    axios.get(url, headers)
      .then(function (response) {
        setTags(response.data.tags);
      })
      .catch(function (error) {
        console.log(error.response);
      })
  }

}




decorate(TagsModel, {
  tags: observable,
  getTags: action,
})

export const tagsModel = new TagsModel();
