import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import './style.scss';

const DragAndDrop = props => {
  const {
   children,
   items,
   onDragStart,
   onDragEnd,
  } = props;
  

  const handleOnDragEnd = (result) => {
    if(!result.destination){
      result.destination = result.source;
    }
    const list = [...items];    
    const [reorderedItem] = list.splice(result.source.index, 1);
    list.splice(result.destination.index, 0, reorderedItem);
    onDragEnd(list);
  }

  const handleOnDragStart= (result) => {
    onDragStart(result);
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
    <Droppable droppableId="list">
      {(provided) => (
        <ul
          id="list_dragable"
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="drag_n_drop_list"
        >
          {items.map((item, key) => (
            <Draggable
              key={item.id}
              draggableId={item.id.toString()}
              index={key}
            >
              {(provided) => (
                <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="drag_n_drop_list_item">
                  {children(item)}
                  {provided.placeholder}
                </li>
              )}
            </Draggable>
          ))}
        </ul>
      )}
    </Droppable>
  </DragDropContext>
  );
}

export default DragAndDrop;
