import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import MainPage from '../MainPage';
import ProfileCard from './ProfileCard/Profile';
import Empty from '../../components/Empty';
import Bio from './Bio/Bio';
import Daily from '../Dashboard/Daily';
import WhoFolloow from '../../components/WhoFollow';
import Channels from './Channels/Channels';
import Content from './Content';
import Posts from './Posts';
import {
  gtMasterMind,
  gtRecurring,
  gtWebinar,
  gtSupport,
  select_all_community,
  set_default_community, successMessage, gtRecurringWebinar,
} from '../../const/general';
import { workshops, livestreams, collaborations } from '../../const/urlKeys';
import { getLabel, returnName } from '../../units/func';
import DropDown from '../../components/dropDown/DropDown';
import './MasterMindProfileMain.scss';

const tabs = ['Content', 'Live Streams','Groups', 'Channels', 'Posts', 'BIO'];
const MasterMindProfileMain = (props) => {

  const {
    groupsModel: {
      groupTypeFilter,
      filteredGroups,
      typeGroups,
      filteredRecordsGroups,
      setParticipants,
      changeCommunity,
      setNeedSyncCommunities,
      groups,
      setUserGroups,
      setFilteredGroup,
      setProfileActiveLink,
      recordsGroups,
    },
    userModel: { user },
    chatsModel: { mmChannels, getMMChannels },
    masterMindsModel: {
      masterMinds,
      setMasterMindProfile,
      masterMindProfile,
      getMasterMindFullProfile,
      masterMindProfileFullData,
      setMMID,
      currentMMID,
    },
    postsModel: { posts, getPostsData },
    mmContextModel: { mmContextGroupsData },
    mediaModel: { filteredMediaGroups },
    meetingsModel: { lastGroupID },
    subscribePackageModel: {getSubscriptionPackagesList, requestResultMessage},
    communityModel: { getUserCommunityList, clearUserOrganization },
    match,
  } = props;
  const [active, setActive] = useState('Content');
  const [currentGroupId, setCurrentGroupId] = useState(null)
  const [currentGroupData, setCurrentGroupData] = useState(null)

  useEffect(() => {
    if(active === "Live Streams" || active === "Groups" || active === "Content") {
      setUserGroups(true);
      setProfileActiveLink(active);
    } else {
      setUserGroups(false);
    }
  }, [active]);

  useEffect(() => {
    return () => {
      setProfileActiveLink('');
    }
  }, []);

  useEffect(_ => {
    setNeedSyncCommunities(false)
    return _=>{
      setNeedSyncCommunities(true)
      clearUserOrganization()
    }
  },[]);
  useEffect(() => {
    const userId = match?.params?.id
    setMMID(userId);
    masterMinds && setMasterMindProfile(parseInt(userId));
    getMasterMindFullProfile(parseInt(userId));
    masterMinds && getMMChannels(parseInt(userId));
    getSubscriptionPackagesList({user_id:parseInt(userId)})
    getPostsData(userId);
    mmContextGroupsData(userId);
    getUserCommunityList({user_id: userId})
    switch (active) {
      case 'Coaching':
        groupTypeFilter([gtMasterMind, gtRecurring]);
        break;

      case 'Live Streams':
        groupTypeFilter([gtWebinar, gtRecurringWebinar]);
        break;

      case 'Groups':
        groupTypeFilter(gtSupport);
        break;

      case 'Content':
        groupTypeFilter([gtWebinar, gtRecurringWebinar]);
        break;

      default:
        break;
    }
  }, [active, masterMinds, match]);

  useEffect(() => {
    if (requestResultMessage === successMessage){
      getMasterMindFullProfile(currentMMID);
    }
  },[requestResultMessage])

  useEffect(
    (_) => {
      changeCommunity(select_all_community);
      return (_) => changeCommunity(set_default_community);
    },
    [currentMMID]
  );
  useEffect(_ => {
    if (currentGroupId) {
      const concatedArray = [...filteredGroups, ...filteredMediaGroups, ...filteredRecordsGroups];
      const updatedItem = concatedArray?.find(group => {
        return group.id === currentGroupId.id &&
          group.group_type === currentGroupId.group_type &&
          group.record_type === currentGroupId.record_type
      })

      updatedItem && setCurrentGroupData(updatedItem)
    } else {
      setCurrentGroupData(null)
    }

  },[currentGroupId, filteredMediaGroups, filteredGroups, filteredRecordsGroups])

  const userNewGroups = filteredGroups?.filter((group) => group.owner?.id == match.params?.id && (group.group_type !== "webinar" && group.group_type !== "recurring_webinar"));
  const userGroups = typeGroups?.filter((group) => group.owner?.id == match.params?.id);
  const activeGroups = filteredGroups?.filter((group) => group.owner?.id == match.params?.id);
  const upcomingGroups = filteredGroups?.filter(
    (group) => group.owner?.id == match.params?.id && group.status === 'upcoming'
  );

  const [contentGroups, setContentGroups] = useState([]);

  useEffect(() => {
    const arr = recordsGroups.groups?.filter(
        (group) =>
            group.owner?.id == match.params?.id &&
            group?.past_meetings_count &&
            group?.meetings_records &&
            group?.meetings_records.length > 0
    );

    setContentGroups(arr);
  }, [recordsGroups]);

  const videosGroupsData = filteredMediaGroups.filter(
    (group) => group.owner?.id == match.params?.id && (group.removed || !group.purchase)
  );
  const [masterMindGroups, setMasterMindGroups] = useState(null);

  useEffect(_ => {
    if(groups?.groups?.length && masterMindProfile?.id) {
      setMasterMindGroups(groups?.groups?.filter(group => group.owner.id === masterMindProfile.id && group.group_type === "master_mind"))
    }
  },[groups])

  const renderContent = () => {
    const daily = (type, groups) => (
      <Daily
        data={groups}
        role={user?.role}
        key="daily"
        showProfile={type}
        setParticipants={setParticipants}
      />
    );

    const mmName = returnName(masterMindProfile);
    const BIO = masterMindProfileFullData?.bio?.replaceAll("(\r\n|\n)", "<br/>");

    switch (active) {
      case 'Live Streams':
        return userGroups && userGroups.length > 0 ? (
          daily(livestreams, activeGroups)
        ) : (
          <div className="emptyBlock">
            {/*<HeaderMaster path={livestreams} short={true} />*/}
            <div className="empty">
              <div className="title">There are no {getLabel(livestreams)} available</div>
            </div>
            {/* // user.community, */}
          </div>
        );
      case 'Workshops':
        return userGroups && userGroups.length > 0 ? (
          daily(workshops, activeGroups)
        ) : (
          <div className="emptyBlock">
            {/*<HeaderMaster path={workshops} short={true} />*/}
            <div className="empty">
              <div className="title">There are no {getLabel(workshops)} available</div>
            </div>
            {/* // user.community, */}
          </div>
        );
      case 'Collaborations':
        return userGroups && userGroups.length > 0 ? (
          daily(collaborations, activeGroups)
        ) : (
          <div className="emptyBlock">
            {/*<HeaderMaster path={collaborations} short={true} />*/}
            <div className="empty">
              <div className="title">There are no {getLabel(collaborations)} available</div>
            </div>
            {/* // user.community, */}
          </div>
        );
      case 'BIO':
        return masterMindProfileFullData && masterMindProfileFullData.bio ? (
          <Bio bio={BIO} insta={false} />
        ) : (
          <div className="emptyBlock">
            <div className="empty">
              <div className="title">{`${mmName} hasn't updated his bio`}</div>
            </div>
          </div>
        );
      case 'Channels':
        return mmChannels && mmChannels.count ? (
          <Channels channels={mmChannels} />
        ) : (
          <div className="emptyBlock">
            <div className="empty">
              <div className="title">{`${mmName} hasn't created channels`}</div>
            </div>
          </div>
        );
      case 'Posts':
        return posts && posts.count ? (
          <Posts posts={posts} />
        ) : (
          <div className="emptyBlock">
            <div className="empty">
              <div className="title">{`${mmName} hasn't created posts`}</div>
            </div>
          </div>
        );
      case 'Content':
        return contentGroups ? (
          <Content
            contentGroups={contentGroups}
            key="daily"
            action="contentFilter"
            mediaGroups={videosGroupsData}
            userId={match?.params?.id}
            currentGroupData={currentGroupData}
            setCurrentGroupId={setCurrentGroupId}
          />
        ) : (
          <div className="emptyBlock">
            <div className="empty">
              <div className="title">{`${mmName} hasn't created content`}</div>
            </div>
          </div>
        );
      case 'Groups':
        return (userGroups?.length > 0) || (masterMindGroups?.length) || userNewGroups?.length ? (
          daily(collaborations, activeGroups.filter(group => group.group_type !== "webinar" && group.group_type !== "recurring_webinar"))
        ) : (
          <div className="emptyBlock">
            {/*<HeaderMaster path={collaborations} short={true} />8/}
            <div className="empty">
              <div className="title">{`${mmName} hasn't created groups`}</div>
            </div>
            {/* // user.community, */}
          </div>
        );
      case 'Coaching':
        return userGroups && userGroups.length > 0 ? (
          daily(workshops, activeGroups)
        ) : (
          <div className="emptyBlock">
            {/*<HeaderMaster path={workshops} short={true} />*/}
            <div className="empty">
              <div className="title">{`${mmName} hasn't created groups`}</div>
            </div>
          </div>
        );
      default:
        return <Empty />; // Robert Curtis doesn't have any content
    }
  };

  const tabLabel = (tab) => {
    switch (tab) {
      case 'Live Streams':
        return getLabel(livestreams); // user.community,
      case 'Workshops':
        return getLabel(workshops); // user.community,
      case 'Collaborations':
        return getLabel(collaborations); // user.community,
      default:
        return tab;
    }
  };

  return (
    <MainPage>
      <div className="mmProfile_wrapper">
        {!masterMindProfile ? (
          <Empty />
        ) : (
          <>
            <ProfileCard />
            <div className="profileTabsContainer">
              <div className="profileTabsWrapper">
                <div className="profileTabs">
                  <DropDown
                    tabs={tabs}
                    active={active}
                    setActive={(tab) => setActive(tab)}
                    tabLabel={(tab) => tabLabel(tab)}
                    dropDownToogleSize={820}
                  />
                </div>
                <div className="personData">
                  <div className="personContent">{renderContent()}</div>
                </div>
              </div>
              <WhoFolloow title="Similar Creators" count={5}/>
            </div>
          </>
        )}
      </div>
    </MainPage>
  );
};

export default inject(
  'groupsModel',
  'masterMindsModel',
  'userModel',
  'chatsModel',
  'postsModel',
  'mmContextModel',
  'mediaModel',
  'meetingsModel',
  'subscribePackageModel',
  'communityModel'
)(observer(MasterMindProfileMain));
