import React from 'react';
//components
import GroupOwner from '../../components/EventItem/eventOwner';
import EvenetDateTime from '../../components/EventItem/eventDateTime';
import ShareButton from '../../components/ShareButton';
import ReportButton from '../../components/ReportButton';
// import DeleteButton from '../../components/DeleteButton';

import './attendGroup.scss';

const GroupHeader = props => {
  const { item, reportGroup } = props; // removeGroup
  const isUpcoming = item?.status === 'upcoming'
  const isOwner = item?.is_owner !== undefined ? item?.is_owner : false

  return (
    <>
      <div className="attendGroupSocial">
        <ShareButton item={item} />
        {/* {isUpcoming && <ReportButton onClick={reportGroup} />} */}
        {!isOwner && <ReportButton onClick={reportGroup} color='#fff' />}
      </div>
      <div className="imageWrapper">
        <img src={item.image.url} alt=""/>
        <div className="attendGroupInfo">
          <EvenetDateTime start_time={item.start_time || item.updated_at} />
          {/* {!isUpcoming && isOwner && <DeleteButton onClick={removeGroup} color='#fff' />} */}
        </div>
      </div>
    </>
  )
}

export default GroupHeader;
