import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteItem from '../../res/iconComp/DeleteIcon';

const DeleteButton = props => {
  const { onClick, color } = props;

  return (
    <IconButton
      onClick={onClick}
      classes={{ root: "attendIcon" }}
    >
      <DeleteItem color={color} />
    </IconButton>
  )
}

export default DeleteButton;