import { observable, decorate, action } from 'mobx';
import moment from 'moment';
import axios from 'axios';

import { baseAPI } from '../const/settings';

import { authModel } from './auth';

export class PurchasesModel {
  constructor() {
    this.purchases = null;
    this.filteredPurchases = null;
    this.state = {
      filter: {
        start: moment().subtract(1, 'months').startOf('month'),
        end: moment(),
      }
    }
  }

  setFilter = (key, value) => {
    this.state.filter[key] = moment(value);
    this.filter();
  }

  filter = () => {
    const { start, end } = this.state.filter;
    if (this.purchases) {
      this.filteredPurchases = this.purchases.purchases.filter(purchase => {
        const create = moment(purchase.created_at);
        return create.isBetween(start, end) || create.isSame(end, 'day') || create.isSame(start, 'day') });
    }
  }

  statePurchases = data => this.purchases = data;
  
  getPurchases = () => {
    const statePurchases = this.statePurchases;
    const filter = this.filter;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.get(`${baseAPI}purchases?[per_page]=10000`, headers)
      .then(function (response) {
        // handle success
        statePurchases(response.data);
        filter();
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }
}

decorate(PurchasesModel, {
  purchases: observable,
  filteredPurchases: observable,
  state: observable,
  getPurchases: action,
})

export const purchasesModel = new PurchasesModel();
