import React from "react";
import { inject, observer } from "mobx-react";
import Button from "../Button";
import Dialog from "../Dialog";
import AttendGroup from "../../pages/AttendGroup";
import GroupDetails from "../../pages/GroupDetails";
import { isAndroid, isIOS, isChrome } from "react-device-detect";
import RecordItem from "../RecordItem";
import PlaySingleRecord from "../PlayRecord";
import moment from "moment";

import {
  gtWebinar,
  gtSupport,
  statusUpcoming,
  statusEnded, gtRecurringWebinar,
} from "../../const/general";

import {timeToStartMetting, redirectToAPP, findTiersTitleByName} from "../../units/func";

const EventButton = (props) => {
  const {
    availableBySubscription = false,
    needSubscriptionsBtn = false,
    item,
    itemPath,
    groupsModel: { buyGroup, leaveGroup},
      userModel: { user },
      subscribePackageModel:{subscriptionPackagesList},
    enablexModel,
    place,
    type = null,
    onClick = () => {},
    refresh = () => {},
    openSubscription,
    isPreview = false,
    showTextUnderAttend = false,
    handleOpenDialog = () => {},
  } = props;
  
  const timeToEvent = timeToStartMetting(item?.start_time);
  const startName = item.group_type === gtWebinar || item.group_type === gtRecurringWebinar ? "Livestream" : "Group";
  const owner = item.is_owner || item.owner.id === user?.id;
  const isSingleRecord =
    item?.meetings_records && item?.meetings_records.length === 1;
  const record = isSingleRecord ? item?.meetings_records[0] : null;
  const videoParams = record
    ? {
        item: item,
        record:
          item?.meetings_records && item?.meetings_records?.length === 1
            ? item.meetings_records[0]
            : {},
        groupID: item.id,
        owner: item.owner,
        is_owner: item.is_owner,
        record_type: item.record_type,
        recordPrice: item.price / item.meetings_count,
      }
    : null;

  const openDialog = () =>{
    if(place !== "dialog"){
      if(item.group_type === gtWebinar || item.group_type === gtRecurringWebinar){
        if(item?.available_for_subscribers && !item?.have_subscription){
          return false
        }else if(item.status === statusEnded && item.meetings_count > 0){
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    }else{
      return false
    } 
  }

  const checkIfNeedSubscriptionButton = () => {
    if(item.group_type === gtWebinar || item.group_type === gtRecurringWebinar){
      if(item?.available_for_subscribers && !item?.have_subscription){
        return true
      }
    }
    return false
  }

    switch (true) {
    case item.blocked:
      return null; // <Button label={`You Blocked`} disabled />

    case !item.status:
      // case place === 'dialog':
      return null;

    case openDialog():
      return (
        <PlaySingleRecord {...videoParams}>
          <Button label="Play livestream" violetGradien />
        </PlaySingleRecord>
      );

    // case item.in_person && owner:
    //   return null;

    case (item.group_type === gtWebinar || item.group_type === gtRecurringWebinar) &&
      item.status === statusEnded &&
      item.meetings_count > 0 &&
      place !== "dialog":
      return (
        <Dialog
          openButton={<Button label="Play livestream" violetGradien />}
          className="eventGroupDetails"
          closeColor="#43425D"
        >
          <GroupDetails item={item} needSubscriptionsBtn = {checkIfNeedSubscriptionButton()}>
            <div className="livestream_records">
              {item?.meetings_records?.map((record, key) => (
                <div className="record" key={key}>
                  <RecordItem
                    owner = {item?.owner}
                    index={key}
                    record={record}
                    groupID={item.id}
                    is_owner={item.is_owner}
                    record_type={item.record_type}
                    record_preview={item.image}
                    event_type={item.group_type}
                    recordPrice={item.price / item.meetings_count}
                    item = {item}
                  />
                </div>
              ))}
            </div>
          </GroupDetails>
        </Dialog>
      );

    case item.status === statusEnded && item.group_type == gtSupport:
      return <Button label={`${startName} Ended`} disabled />;

    case item.status === "canceled":
      return <Button label={`${startName} Canceled`} disabled />;

    case (item.status === statusUpcoming ||
      item.status === "active" ||
      item.status === statusEnded) &&
      owner &&
      timeToEvent >= -60:
      return (
            <Button
              label={`Start ${startName}`}
              violetGradien
              disabled={item.status === statusEnded || timeToEvent >= 10}
              onClick={() => {
                if(!(isAndroid || isIOS)){
                  enablexModel.getGroupCredential(item);
                  return;
                }
                redirectToAPP(
                  `ascend.video://group/${item.id}/start`,
                  `ascend.video://group/${item.id}/start`
                );
              }}
            />
      );

      case item.status === "active"
      && owner
      && !Boolean(item.meetings_records.find(el => {
          const time = moment(el.start_time).unix() - moment().unix();
          return time <= 10 && time >= 0;
      })):
          return (
              <Button
                  label={`Start ${startName}`}
                  violetGradien
                  disabled={!Boolean(item.meetings_records.find(el => {
                      const time = moment(el.start_time).unix() - moment().unix();
                      return time <= 10 && time >= 0;
                  }))}
                  onClick={() => {
                      if(!(isAndroid || isIOS)){
                          enablexModel.getGroupCredential(item);
                          return;
                      }
                      redirectToAPP(
                          `ascend.video://group/${item.id}/start`,
                          `ascend.video://group/${item.id}/start`
                      );
                  }}
              />
          );

      case owner && item.status === "started":
      return (
            <Button
            label= {["webinar", "recurring_webinar"].includes(item.group_type) ? "Join Livestream" :"Join Discussion"}
            yellowGradient
              onClick={() => {
                if (!isAndroid || !isIOS) {
                  enablexModel.getGroupCredential(item);
                  return;
                }
                redirectToAPP(
                  `ascend.video://group/${item.id}/join`,
                  `ascend.video://group/${item.id}/join`
                );
              }}
            />
      );

      case owner && item.status === "active" && !!item.meetings_records.find(el => {
          const time = moment(el.start_time).unix() - moment().unix();
          return time <= 10 && time >= 0;
      }):
          return (
              <Button
                  label= {["webinar", "recurring_webinar"].includes(item.group_type) ? "Join Livestream" :"Join Discussion"}
                  yellowGradient
                  onClick={() => {
                      if (!isAndroid || !isIOS) {
                          enablexModel.getGroupCredential(item);
                          return;
                      }
                      redirectToAPP(
                          `ascend.video://group/${item.id}/join`,
                          `ascend.video://group/${item.id}/join`
                      );
                  }}
              />
          );

    case item.subscribed && timeToEvent <= 10 && item.status !== statusEnded && !item.in_person:
      return (
            <Button
              label= {["webinar", "recurring_webinar"].includes(item.group_type) ? "Join Livestream" :"Join Discussion"}
              yellowGradient
              onClick={() => {
                if (!(isAndroid || isIOS)) {
                  enablexModel.getGroupCredential(item);
                  return;
                }
                redirectToAPP(
                  `ascend.video://group/${item.id}/join`,
                  `ascend.video://group/${item.id}/join`
                );
              }}
            />
      );

      case item.subscribed && timeToEvent <= 10 && item.status !== statusEnded && item.in_person:
          return (
              <Button
                  label={`Leave this ${startName}`}
                  width100
                  big60
                  className="unsubscribeButton"
                  onClick={() => {
                      leaveGroup({ id: item.id, refund: false, reasone: "leave button" }, refresh);
                      onClick();
                  }}
              />
          );

    case item.status === statusEnded &&
      !item.subscribed &&
      !owner &&
      item.price == 0:
      return null;
      return (
        <Button
          lightBlueGradient // ={item.price === 0}
          // orangeGradient={item.price !== 0}
          label="Save"
          onClick={() => buyGroup(item.id, null, null)}
        />
      );
      case availableBySubscription && (item.status === statusEnded || type == "content") &&
      !item.subscribed &&  !owner:
        return item?.meetings_records.some(
          (record) => !record.record_access && record.record_present
        ) ? (
            <div className={'bySubscriptions'}>
              <Button
                label="SAVE"
                whiteGradient
                onClick={() => {
                  buyGroup(item.id, null, null, null, refresh, 'subscription_purchase');
                  onClick();
                }}
              />
              <p>
                this {findTiersTitleByName(item.group_type || item.record_type).toLowerCase()} is available as part
                of your subscription
              </p>
            </div>
        ) : null;

    case (item.status === statusEnded || type == "content") &&
      !item.subscribed && !owner && needSubscriptionsBtn:
      return item?.meetings_records.some(
        (record) => !record.record_access && record.record_present
      ) ? (
        <Button label="Buy" orangeGradient onClick={ (e) => {
          e.stopPropagation();
          onClick();
        }}/>
      ) : null;

    case (item.status === statusEnded || type == "content") &&
      !item.subscribed &&
      !owner:
      return item?.meetings_records.some(
        (record) => !record.record_access && record.record_present
      ) ? (
        <Dialog openButton={<Button label="Buy" orangeGradient />}>
          <AttendGroup item={item} />
        </Dialog>
      ) : null;
    case !item.subscribed && (!item.price || availableBySubscription) && (owner.id !== user.id) && showTextUnderAttend:
      item.id === 6543 && console.log("tut1")
      return (
        <div className={'bySubscriptions'}>
          <Button
            label="Attend"
            whiteGradient
            onClick={() => {
              buyGroup(item.id, null, null, null, refresh, 'subscription_purchase');
              onClick();
            }}
          />
          <p>
            this {findTiersTitleByName(item.group_type || item.record_type).toLowerCase()} is available as part
            of your subscription
          </p>
        </div>
      );
    case !item.subscribed && !item.price && !owner && isPreview && !(item?.subscription_packages_ids && item.subscription_packages_ids.length > 0):
      item.id === 6543 && console.log("tut2")
      return (
        <Button
          label="Attend"
          violetGradien
          onClick={() => {
            handleOpenDialog()
            onClick();
          }}
        />
      );
   
    case !item.subscribed && !item.price && !owner && !(item?.subscription_packages_ids && item.subscription_packages_ids.length > 0):
      item.id === 6543 && console.log("tut3")
      return (
        <Button
          label="Attend"
          violetGradien
          onClick={() => {
              buyGroup(item.id, null, null, null, refresh, 'group_purchase', item);
            onClick();
          }}
        />
      );

    case !item.subscribed && item.invited === "speaker" && !owner && item.group_type !== "master_mind":
      item.id === 6543 && console.log("tut3")
        return (
          <Button
            label="Attend"
            violetGradien
            onClick={() => {
            buyGroup(item.id, null, null, null, refresh);
            onClick();
            }}
          />
        );

    case !item.subscribed && !!item.price && item.leaved && !owner:
      item.id === 6543 && console.log("tut5")
      return (
        <Button
          label="Attend"
          violetGradien
          //smaller110
          onClick={() => {
            buyGroup(item.id, null, null, null, refresh);
            onClick();
          }}
        />
      );

    case (availableBySubscription || item.leaved) && !item.subscribed && !!item.price && !owner && isPreview:
      item.id === 6543 && console.log("tut6")
      return (
        <Button
          label="Attend"
          violetGradien
          onClick={() => {
            buyGroup(item.id, null, null, null, refresh, 'subscription_purchase');
            onClick();
          }}
        />
      );

    case needSubscriptionsBtn && !item.subscribed && !!item.price && !owner:
      item.id === 6543 && console.log("tut7")
      return (
        <Button
          label="Attend"
          violetGradien
          onClick={() => {
            onClick();
          }}
        />
      );
    case !item.subscribed && 
      (owner.id !== user.id) &&
      item.invited === "none" &&
      (item?.subscription_packages_ids && item.subscription_packages_ids.length > 0):
        if(openSubscription){
          item.id === 6543 && console.log("tut8.0")
          return <Button label="Attend" violetGradien /*smaller110*/ onClick={openSubscription}/>
        }
      
    case !item.subscribed && !!item.price && !owner && !item.create_package:
      if (openSubscription){
        item.id === 6543 && console.log("tut8")
        return <Button label="Attend" violetGradien /*smaller110*/ onClick={openSubscription}/>
      }
      item.id === 6543 && console.log("tut9")
      return (
        <Dialog
          itemPath={
            itemPath?.forSet && {
              forSet: `${itemPath.forSet}&action=attend`,
              forBack: itemPath.forSet,
            }
          }
          openButton={<Button label="Attend" violetGradien /*smaller110*/ />}
          className="eventGroupDetails"
          closeColor="#43425D"
        >
          <AttendGroup item={item} />
        </Dialog>
      );

      case !owner && item.available_for_purchases && item.create_package && !item.subscribed:
        if(openSubscription){
          item.id === 6543 && console.log("tut8.0")
          return <Button label="Attend"
                         violetGradien
                         onClick={() => {
                           handleOpenDialog()
                           onClick();
                         }}
          />
        }

    case item.subscribed && item.status === statusUpcoming:
        if(item.subscribed && item.status === statusUpcoming){
            return <Button
                label={`Leave this ${startName}`}
                width100
                big60
                className="unsubscribeButton"
                onClick={() => {
                    leaveGroup({ id: item.id, refund: false, reasone: "leave button" }, refresh);
                    onClick();
                }}
            />
        }

      case item.subscribed &&
      item.status === statusUpcoming &&
      item?.past_meetings_count > 0:
          if(item.subscribed &&
              item.status === statusUpcoming &&
              item?.past_meetings_count > 0) {
              return <Button
                  label={["webinar", "recurring_webinar"].includes(item.group_type) ? "Join Livestream" : "Join Discussion"}
                  disabled
              />
          }

    default:
      return null;
  }
};

export default inject('groupsModel', 'userModel', 'enablexModel', 'subscribePackageModel')(observer(EventButton));
