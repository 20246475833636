import { observable, decorate, action } from 'mobx';
import axios from 'axios';

import { baseAPI } from '../const/settings';

import { authModel } from './auth';
import { userModel } from './user';
import {bankAccountsModel} from './bankAccounts';

export class CardsModel {
  constructor() {
    this.cards = null;
    this.defaultCard = null;
    this.resualtMessage = '';
  }

  changeResultMessage = text => this.resualtMessage = text;

  findDefaultCard = () => {
    if (this.cards) {
      const defaultCard = this.cards.cards.find(card => card.default);
      if (!defaultCard) {
        this.defaultCard = this.cards.cards[0];
      } else {
        this.defaultCard = defaultCard;
      }
    }
  }

  setCards = data => {
    this.cards = data;
    this.findDefaultCard();
  }

  updateCards = card => {
    if (this.cards && this.cards.count > 0) {
      this.cards.cards.push(card);
      this.cards.count = this.cards.count + 1;
    } else {
      this.cards.cards = [card];
      this.cards.count = 1;
    }
  }

  getCardsData = () => {
    const setCards = this.setCards;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.get(`${baseAPI}cards`, headers)
    .then(function (response) {
      setCards(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log('error', error)
    })
    .finally()
  }

  createCard = (data) => {
    const { event, ...rest } = data;
    const getCardsData = this.getCardsData;
    const updateCards = this.updateCards;
    const changeResultMessage = this.changeResultMessage;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}cards`, rest, headers)
      .then(function (response) {
        updateCards(response.data);
        getCardsData();
        changeResultMessage('Success');
        // event();
      })
      .catch(function (error) {
        // handle error
        changeResultMessage(error.response.data ? error.response.data.errors[0] : 'Error');
        console.log('error', error)
      })
      .finally()
  }

  checkIfCardExist = data => {
    return this.cards.cards.filter(card => card.last4.toString() === data.last4.toString() && 
      card.exp_month.toString() === data.exp_month.toString() && 
      card.exp_year.toString() === data.exp_year.toString() && card.brand === data.brand);
  }

  deleteCard = id => {
    const getCardsData = this.getCardsData;
    const changeResultMessage = this.changeResultMessage;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.delete(`${baseAPI}cards/${id}`, headers)
      .then(function (response) {
        getCardsData();
        // changeResultMessage('Success');
      })
      .catch(function (error) {
        // handle error
        console.log('error.response.data', error.response.data);
        switch (true) {
          case error.response.data && error.response.data.errors[0].includes('add a new card before deleting'):
            changeResultMessage(`Active payment method can't be deleted. Please, add another payment method as active in order to delete the current one.`);
            break;

          case error.response.data:
            changeResultMessage(error.response.data.errors[0]);
            break;  
        
          default:
            changeResultMessage(error.response.data.errors[0] || 'Error');
            break;
        }
      })
  }

  setAsDefault = (id, type) => {
    const getCardsData = this.getCardsData;
    const getBankAccounts = bankAccountsModel.getBankAccounts;
    const url = type === "payout" ? "default_payout" : "default";

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}cards/${id}/${url}`, null ,headers)
    .then(function (response) {
      getCardsData();
      getBankAccounts();
    })
    .catch(function (error) {
      console.log('error', error)
    })
    .finally()
  }

  stripeVerify = ({ setErrors, ...data }) => {
    const changeResultMessage = this.changeResultMessage;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}cards/verify_stripe_account`, data.formData ,headers)
    .then(function (response) {
      userModel.getCurentUser(authModel.session_token);
      changeResultMessage('You Request send');
    })
    .catch(function (error) {
      // handle error
      // changeResultMessage('You need change some data.');

      let errors = [];

      error.response.data.errors.forEach(errorMessage => {
        changeResultMessage(errorMessage);
        switch (true) {
          case errorMessage.includes('phone'):
            // errors.push({ 'phone': errorMessage });
            errors['phone'] = errorMessage;
            break;
  
          case errorMessage.includes('state'):
            // errors.push({ 'state': errorMessage });
            errors['state'] = errorMessage;
            break;

          case errorMessage.includes('city'):
            // errors.push({ 'city': errorMessage });
            errors['city'] = errorMessage;
            break;

          case errorMessage.includes('ID numbers'):
            // errors.push({ 'personal_id_number': errorMessage });
            errors['personal_id_number'] = errorMessage;
            break;

          case errorMessage.includes('postal code'):
            // errors.push({ 'zip_code': errorMessage });
            errors['zip_code'] = errorMessage;
            break;
        
          default:
            break;
        }
      });

      setErrors(errors);
    })
    .finally()
  }
}

decorate(CardsModel, {
  cards: observable,
  defaultCard: observable,
  resualtMessage: observable,
  getCardsData: action,
})

export const cardsModel = new CardsModel();
