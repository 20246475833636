import React, { memo, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

import BadgeButton from '../BadgeButton'

import SearchSimple from '../../res/icons/search.svg';
import SearchIcon from '../../res/icons/search-header.svg';

import './searchHeader.scss';

const SearchHeader = props => {
  const history = useHistory();
  const { search } = useParams();
  const [searchParam, setSearch] = useState('')

  const handleSearch = (e) => {
    e.preventDefault()
    history.push(`/search/${searchParam}`)
  }

  return (
    <div className="searchHeader">
      <Paper component="form" className="root">
        <InputBase
          className="inputElem"
          placeholder="Search"
          defaultValue={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ 'aria-label': 'search', 'maxLength': 48 }}
        />
        <IconButton onClick={handleSearch} type="submit" className="iconButton" aria-label="search">
        
          <img src={SearchSimple} alt="search" />
        </IconButton>
      </Paper> 
      <BadgeButton src={SearchIcon} alt="search icon" invisible={true} />
    </div>
  );
}

export default memo(SearchHeader);
