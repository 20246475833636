import React, {useEffect, useState} from "react";
import CurrentAvatar from "../CurrentAvatar";
import {observer, inject} from "mobx-react";
import ButtonBase from '@material-ui/core/ButtonBase';
import partition from 'lodash/partition'
import flatten from 'lodash/flatten'

import moment from "moment";

import './style.scss'
import CustomModal from "../Modals/Modal";
import AllCommunitiesPopup from "../AllCommunitiesPopup";
import {Link} from "react-router-dom";
import {chooseCommunity} from "../../const/urlKeys";

const CommunitiesBlock = (props) => {
  const {
    communityModel: {communityList, setCommunityAsCurrent, getModeratorsList},
    userModel: {community}
  } = props

  const [isOpenModal, setIsOpenModal] = useState(false)

  const allCommunityList =  communityList?.selected.includes(community) ? communityList?.selected: communityList?.selected.concat([community])
  const unsortCommunitiesData = allCommunityList
    .filter(id => id !== community)
    .map((id)=> communityList.mappedCommunities[id])

  const sortedCommunitiesData = partition(unsortCommunitiesData, community => {
    return !!community.selected_at
  })
    .map((arr) => {
        return arr.sort( (next, prev) => {
          if ( prev.selected_at ) return moment(next.selected_at).unix() - moment(prev.selected_at).unix()
          return  moment(next.updated_at).unix() - moment(prev.updated_at).unix()
      }).reverse()
    })


  const communitiesData = flatten(sortedCommunitiesData);
  let sortCommunitiesData = [];
  communityList.communities.map((cmnty) => {
    cmnty.id === community && sortCommunitiesData.push(cmnty)
  })
  communitiesData.map((cmnty) => {
    cmnty.id != community && sortCommunitiesData.push(cmnty)
  })

  const makeCurrent = (id) => {
    setCommunityAsCurrent({id})
    getModeratorsList({id})
  }

  useEffect(() => {
    if (sortCommunitiesData.length) {
      getModeratorsList({id: sortCommunitiesData[0].id})
    }
  }, [sortCommunitiesData])

  return (
    <>
      {sortCommunitiesData.length? (
        <div className="communityBlock">
          <div className="communityBlock_title">
            <h3>Communities</h3>
            {/*<div className={'communityBlock_allPopup'} onClick={()=>setIsOpenModal(true)}>View All</div>*/}
            <Link className={'communityBlock_allPopup'} to={chooseCommunity}>Find More</Link>
          </div>
          <div className={'communityBlock_wrapper'}>
            <div className={'communityBlock_communities'}>
              {sortCommunitiesData
                .map((c) => {
                  // const item = communityList?.mappedCommunities[c_id]
                  return <ButtonBase key={c.id} className={'community buttonsCarousel'}>
                      <div className={'community_general'} onClick={()=>{makeCurrent(c.id)}}>
                        <CurrentAvatar alt={c.title} src={c?.image?.thumbnail?.url} className='personInfo-avatar' />
                        <div className={'community_name'}>{c?.title}</div>
                      </div>
                    </ButtonBase>
                })
              }
            </div>
          </div>
          {/*<CustomModal isOpen={!!isOpenModal} handleClose={()=> setIsOpenModal()} customWidth={"660px"} >*/}
          {/*  <AllCommunitiesPopup onClick={makeCurrent} communitiesList={communitiesData}/>*/}
          {/*</CustomModal>*/}
        </div>
      ): null}
    </>
  )
}

export default inject('communityModel', 'userModel')(observer(CommunitiesBlock))
