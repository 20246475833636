import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import classNames from "classnames";

import VisaImg from "../../res/img/visa.png";
import DeleteImg from "../../res/icons/delete_grey.svg";
import MasterImg from "../../res/img/master.png";
import Dialog from "../Dialog";
import MessageDelete from "./Message";

import "./style.scss";

const CardList = (props) => {
  const {
    cardsModel: { cards, resualtMessage, changeResultMessage, deleteCard, setAsDefault },
  } = props;

  return cards?.cards.map((card) => {

    const isExpired =  moment().isAfter(moment(`${card.exp_year}-${card.exp_month}`).endOf("month"));
    const isPayoutCard = card.variant === "payout";
    const isDefaultPayoutCard = card.default_payout;
    const isDefaultPaymentCard = card.default;


    return (
      <div className="payment_method_item_wrapper" key={card.id}>
        <div className="payment_method_item">
          {/* <Checkbox
            icon={<CircleIcon />}
            checkedIcon={<CheckedIcon />}
            checked={cardId ? cardId === card.id : card.default}
            onClick={() => onClick(card.id)}
          /> */}
          {/* {JSON.stringify(card)} */}
          <div className="payment_method_item_img_info">
            <div className="payment_method_item_image">
              {card.brand === "Visa" && <img src={VisaImg} alt="Visa" />}
              {card.brand === "MasterCard" && (
                <img src={MasterImg} alt="MasterCadr" />
              )}
            </div>

            <div className="payment_item_info">
              <span>
                {card.brand} - {card.last4}
              </span>
              {isExpired ? (
                <div className="cardExpired">Expired</div>
              ) : (
                <div className="card_type">
                  {isPayoutCard ? "Payout" : "Payment"} Card
                </div>
              )}
            </div>
          </div>

          {!(isDefaultPayoutCard || isDefaultPaymentCard) ? ( // && !card.default
            <Dialog
              openButton={<img className="delete_icon" src={DeleteImg} />}
              className="login"
            >
              <MessageDelete
                onDelete={() => deleteCard(card.id)}
                resualtMessage={resualtMessage}
                changeResultMessage={changeResultMessage}
              />
            </Dialog>
          ) : (
            <div className="freeRight" />
          )}
        </div>
        <div className="payment_method_default_actions">
           <div
            className={classNames(
              "payment_method_default_action",
              isDefaultPaymentCard && "active"
            )}
            onClick={() => setAsDefault(card.id, 'payment')}
          >
            Default Payment Method
          </div>
          {isPayoutCard && <div
            className={classNames(
              "payment_method_default_action",
              isDefaultPayoutCard && "active"
            )}
            onClick={() => setAsDefault(card.id, 'payout')}
          >
            Default Payout Method
          </div>}
        </div>
      </div>
    );
  });
};

export default inject("cardsModel")(observer(CardList));
