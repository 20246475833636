import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import CurrentAvatar from '../CurrentAvatar';
import { returnName } from '../../units/func';
import './owner.scss';
import {inject, observer} from "mobx-react";

const GroupOwner = props => {
  const { owner, showLink,
    masterMindsModel: {
      setMasterMindProfile
    }
  } = props;

  const ownerName = returnName(owner);

  function redirectToOwnerPage(event) {
    event.stopPropagation()
    setMasterMindProfile(owner.id)
  }
  const ownerContent = () => (
    <>
      <CurrentAvatar alt={ownerName} src={owner.image && owner.image.thumbnail.url} className="avatar" />
      <div className="ownerData">
        <div className="ownerName">{ownerName}</div>
        <div className="ownerLocation">{owner.location}</div>
      </div>
    </>
  );
  
  if (showLink) {
    return (
      <Link to={`/user/${owner.id}`} className="owner" onClick={redirectToOwnerPage}>
        {ownerContent()}
      </Link>
    )
  }

  return (
    <div className="owner">
      {ownerContent()}
    </div>
  )
}

export default memo(inject('masterMindsModel')(observer(GroupOwner)))
