import {
  observable,
  decorate,
  action,
  toJS
} from 'mobx';
import axios from 'axios';
import moment from 'moment';

import concat from 'lodash/concat';
import pull from 'lodash/pull';
import indexOf from 'lodash/indexOf';
import isArray from 'lodash/isArray';

import {
  ctGeneral,
  dailyPage,
  gtRecurring,
  select_all_community,
  set_community_from_memory,
  set_default_community
} from '../const/general';
import { baseAPI } from '../const/settings';
import {
  gtSupport,
  gtMasterMind,
  gtWebinar
} from '../const/general';

import { purchasesModel } from './purchases';
import { authModel } from './auth';
import { tagsModel } from './tags';
import { userModel } from './user';
import { messagesModel } from './messages';
import { vimeoModel } from './vimeo';
import { mmContextModel } from './mmcontext';
import { mediaModel } from './media';
import { inviteMembersModel } from './inviteMembers';
import {searchModel} from "./search";
import {checkCommunityIncludeInFilters, findIndexInArrOfObj} from "../units/func";
import {subscribePackageModel} from "./subscriptionPackages";
import {communityModel} from "./community";
import { filter } from 'lodash-es';

const getAllCommunitiesForReqeust = () => {
  let generalCommunitiesIds = communityModel.communityList.general.map(comm => comm.id)
  let iter = communityModel.communityList.selected.map(id => !generalCommunitiesIds.includes(id) && generalCommunitiesIds.push(id))
  if (generalCommunitiesIds.includes(userModel?.user?.community_id)){
    return generalCommunitiesIds.join(',')
  }
  const all = generalCommunitiesIds.concat([userModel?.user?.community_id])

  return all.join(',')
}
// import { timeToStartMetting } from '../units/func';

const twoDays = -2940;

export class GroupsModel {
  constructor() {
    this.groups = {
      groups: [],
      count: 0,
      isLoading: false,
      firstLoading: true
    };
    this.userGroups = null;
    this.filteredGroups = null;
    this.typeGroups = null;
    this.resualtMessage = '';
    this.canBuy = true;
    this.lastGroup = null;
    this.state = {
      filters: {
        private: false,
        wdays: [],
        time: {
          start: "00:00 am",
          end: "11:59 pm"
        },
        type: 'daily',
        tags: [],
        communities: [],
        userCommunity: null,
        needSyncCommunities: true,
      },
    }
    this.promocode = null;
    this.simpleId = null;
    this.getGroupError = null;
    this.groupNotFound = false;
    this.canSetGroupNotFound = true;
    this.recordsGroups = {
      groups: [],
      count: 0,
      groupsIds: [],
      isLoading: false,
      firstLoading: true
    }
    this.openJoinLSPopup = false;
    this.filteredRecordsGroups = [];
    this.groupParticipants = [];
    this.attachments = [];
    this.editGroup = null;
    this.createGroupStatus = false;
    this.categoryList = [];
    this.openGroupPopupAfterCreate = {
      id: null,
      need: false,
      can: false
    };
    this.templateGroup = [];
    this.createGroupError = '';
    this.groupChooseSessions = {};
    this.groupChooseSessionsError = '';
    this.groupChooseSlotsError = '';
    this.successfulPurchase = false;
    this.scheduleToday = [];
    this.sessionGroup = null;
    this.profileActiveLink = '';
  }

  setProfileActiveLink = activeLink => this.profileActiveLink = activeLink;
  setGroupChooseSlotsError = error => this.groupChooseSlotsError = error;
  setScheduleToday = schedule => this.scheduleToday = schedule;
  setGroupChooseSessionsError = error => this.groupChooseSessionsError = error;
  setSuccessfulPurchase = purchase => this.successfulPurchase = purchase;
  setGroupChooseSessions = group => this.groupChooseSessions = group;
  changeResultMessage = text => this.resualtMessage = text;
  changeState = newState => this.state = newState;
  setPromokode = code => this.promocode = code;
  setLastGroup = lastGroup => {
    if (!this.canSetGroupNotFound) return;
    this.lastGroup = lastGroup
  };
  setSessionGroup = sessionGroup => {
    this.sessionGroup = sessionGroup;
  }
  setCreateGroupError = error => this.createGroupError = error;
  setUserGroups = isGroups => this.userGroups = isGroups;
  setSimpleId = id => this.simpleId = id;
  setGroupError = error => this.getGroupError = error;
  setCanChangeGroupNotFound = bool => {
    this.canSetGroupNotFound = bool
  };
  setGroupNotFound = error => this.canSetGroupNotFound && (this.groupNotFound = error);
  setFilteredGroup = data => {
    this.filteredGroups = data.filter(item => item.group_type !== "support")
  }
  setRecords = groups => {

    this.recordsGroups = { ...groups, groups: groups?.groups.filter(grp => {if((grp.status === "ended") &&
          grp?.meetings_records &&
          !grp?.meetings_records.some(record => (record.status === "ended" && record?.video_file) || record?.url)
      ){
        return false
      }
        return true
      })}
    this.setRecordsIsLoading(false)
    this.runRecordsFilter()
    // this.setGroupsVimeoDurations(this.recordsGroups);
  };
  setRecordsIsLoading = (bool) => {
    this.recordsGroups = {...this.recordsGroups, isLoading: bool, firstLoading: false}
  }
  setCanBuy = value => this.canBuy = value;
  setEditGroup = group => this.editGroup = group;

  setGroups = data => {
    const sortOrder = ['upcoming', 'ended'];
    this.groups.groups = data.groups.slice().sort(function (a, b) {
      return sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status)
          || new Date(a.start_time) - new Date(b.start_time);
    });
    this.groups.count = data.count;
    this.setGroupsIsLoading(false)
    // this.setGroupsVimeoDurations(this.groups);
    this.groupTypeFilter(this.state.filters.type);
    this.openGroupPopupAfterCreate.need && this.setCanOpenPopupAfterCreateGroup(true)
  }
  setGroupsIsLoading = (bool) => {
    this.groups = {...this.groups, isLoading: bool, firstLoading: false}
  }

  setParticipants = (data, id) => this.groupParticipants = { ...data, id };
  setAttachments = data => this.attachments = data;
  setCreateGroupStatus = val => this.createGroupStatus = val;
  setNeedOpenPopupAfterCreateGroup = bool => this.openGroupPopupAfterCreate.need = bool;
  setCanOpenPopupAfterCreateGroup = bool => this.openGroupPopupAfterCreate.can = bool;
  setIDOpenPopupAfterCreateGroup = id => this.openGroupPopupAfterCreate.id = id;
  clearOpenGroupPopupAfterCreate = _ => this.openGroupPopupAfterCreate = {id: null, need: false, can: false};
  setOpenJoinLSPopup = bool => this.openJoinLSPopup = bool;


  clearSimpleId = () => {
    this.simpleId = null;
    this.lastGroup = null;
  }

  extendFilterByGroup = (id) =>{
    const group = this.recordsGroups.groups.find(group => String(group.id) === String(id))

    if (group){
      group.communities.forEach((c_id) => this.changeFilterCommunity(+c_id))
      return group
    }
    return false
  }
  groupTypeFilter = values => {
    if (values) {
      this.state.filters.type = values;
    }
    this.typeGroups = toJS(this.groups.groups)
        // .map(group => ({ ...group, timestamp: new Date() }))
        .filter(group => {
          if (this.state.filters.type === dailyPage) {
            const notEnded = group.status === 'upcoming' || group.status === 'active' || group.status === 'started' || (
                group.status === 'ended' && group?.meetings_records && group.meetings_records.some(recrd => recrd.video_file)
            );
            const isAttend = group.subscribed
            const isOwner = group.is_owner || userModel.isOwner(group?.owner)
            const isFollowed = group?.owner?.followed;
            const invited = group.invited;
            const openOrInvite = !(group.private && group.closed) || invited;
            const subscriptionsFilter = () => {
              return subscribePackageModel.subscribedPackagesList.subscription_packages.find( (pack) => {
                return pack?.owner?.id === group?.owner.id && group.communities.some(comm => comm.id === pack.community_id) && pack.tiers.includes(group.group_type)
              })
            }
            const isPaidAvailableBySubscription = !!group.price && group.available_for_subscribers && subscriptionsFilter();
            let iterableCommunities = group?.communities ? group?.communities : [];
            group?.distribution_group && group.distribution_group.communities.map(comm => iterableCommunities.push(comm))
            return (invited || isOwner) || iterableCommunities.some(comm => comm.id === userModel?.user?.community_id) && notEnded && (isAttend || isFollowed && (openOrInvite || isPaidAvailableBySubscription)
            )
          }
          if (isArray(this.state.filters.type)) {
            let isFiltered = false;
            this.state.filters.type.forEach(value => {
              if (group.group_type === value) {
                if(this.state.filters.type.some(val => val.includes('webinar'))){
                  if(group.status === 'ended'){
                    if(group?.meetings_records && group.meetings_records.some(recrd => recrd.video_file)){
                      isFiltered = true
                    }
                  }else{
                    isFiltered = true
                  }
                }else{
                  isFiltered = true;
                }
              }
            })
            this.runFilter();
            return isFiltered
          }

          if(this.state.filters.type === "support") {
            return true;
          }

          return group.group_type === this.state.filters.type
        });
    this.setFilteredGroup(this.typeGroups);
    if (this.state.filters.type !== dailyPage) {
      this.runFilter();
    }
  }

  checkSimpleID = () => {
    if (this.simpleId) {
      /* eslint eqeqeq: 0 */
      const simpleId = this.filteredGroups.findIndex(group => group.id == this.simpleId);
      if (simpleId < 0) {
        this.getSingleGroupData(this.simpleId);
      } else {
        this.setLastGroup(this.filteredGroups[simpleId])
      }
    }
  }

  changeFilterPrivate = value => {
    this.state.filters.private = value;
    this.runFilter();
  }

  changeFilterWdays = value => {
    const wdays = toJS(this.state.filters.wdays);
    let newWdays = null;
    if (indexOf(wdays, value) >= 0) {
      newWdays = pull(wdays, value);
    } else {
      newWdays = concat(wdays, value);
    }
    this.state.filters.wdays = newWdays;
    this.runFilter();
  }

  changeFilterTags = value => {
    const tags = toJS(this.state.filters.tags);
    let newTag = null;
    if (indexOf(tags, value) >= 0) {
      newTag = pull(tags, value);
    } else {
      newTag = concat(tags, value);
    }
    this.state.filters.tags = newTag;
    this.runFilter();
  }

  setNeedSyncCommunities = bool => {
    this.state.filters.needSyncCommunities = bool;
    if (bool){
      this.state.filters.communities = [this.state.filters.userCommunity]
    }
  }

  changeUserCommunity = value => {
    this.state.filters.userCommunity = value;
    this.state.filters.needSyncCommunities && (this.state.filters.communities = [value])
    this.runTypeFilter('all')
  }

  changeCommunity = value => {
    const communities = toJS(this.state.filters.communities);
    let newCommunities = null;
    const communityIndex = indexOf(communities, value);
    switch (true) {
      case value === select_all_community:
        newCommunities = communityModel.communityList.selected.slice()
        break
      case value === set_default_community:
        newCommunities = [userModel?.user?.community_id]
        break
      case value === set_community_from_memory:
        newCommunities = communityModel.filterSelectedCommunity
        break
      case communityIndex >= 0 && communities.length > 1:
        newCommunities = pull(communities.slice(), value);
        break;
      case communityIndex >= 0 && communities.length === 1:
        newCommunities = communities;
        break;

      case communityIndex === -1:
        newCommunities = concat(communities, value);
        break;

      default:
        break;
    }

    this.state.filters.communities = newCommunities;
  }

  changeFilterCommunity = (value, type = 'group', action = false) => {
    this.changeCommunity(value);

    this.runTypeFilter(type)
    if (action) {
      this.runFilter();
    }
  }

  runTypeFilter = type => {
    switch (type) {
      case 'recordsGroups':
        this.runRecordsFilter();
        this.groupTypeFilter();
        break;

      case 'contentFilter':
        this.runRecordsFilter();
        mmContextModel.runMMContentFilter();
        mediaModel.runMediaCommunityFilter();
        break;

      case 'mediaFilter':
        mmContextModel.runMMContentFilter();
        mediaModel.runMediaCommunityFilter();
        break;
      case 'all':
        this.runRecordsFilter();
        this.groupTypeFilter();
        mmContextModel.runMMContentFilter();
        mediaModel.runMediaCommunityFilter();

      default:
        this.groupTypeFilter();
        break;
    }
  }

  getCommunitieswithdistGroup = group =>{
    let communitiesId = []
    let iterableCommunities = group?.communities ? group?.communities : [];
    if(communityModel?.distribution_groups?.distribution_groups){
      group?.distribution_group && communityModel.distribution_groups.distribution_groups.map(grp => grp.id === group?.distribution_group.id &&
          grp.communities.map(comm =>
              !communitiesId.includes(comm.id) && communitiesId.push(comm.id) &&
              iterableCommunities.push(comm)
          ))
      let copyGroup = group;
      copyGroup.communities = iterableCommunities;
      return copyGroup;
    }else if(group?.communities){
      return group
    }else{
      let copyGroup = group;
      copyGroup.communities = ["none"]
      return copyGroup
    }
  }

  runRecordsFilter = () => {
    const { filters } = this.state;
    this.filteredRecordsGroups = this.recordsGroups.groups
        // .map(group => ({ ...group, timestamp: new Date()}))
        .filter(group => {
          if (group.group_type !== gtWebinar) return false
          if(filters.communities && filters.communities.length) {
            let modifiedGroup = this.getCommunitieswithdistGroup(group);
            return checkCommunityIncludeInFilters(modifiedGroup, filters.communities);
          }
          return true
        });
  }

  changeFilterTime = (key, value) => {
    this.state.filters.time[key] = value;
    this.runFilter();
  };

  changeFilterType = values => {
    this.state.filters.type = values;
    this.runFilter();
  }

  runFilter = () => {

    const { filters } = this.state;
    const isWorkshopCoaching = (group_type) => (group_type === gtMasterMind || group_type === gtRecurring);
    const isCollaborationGroup = (group_type) => group_type === gtSupport;
    // const isLifeStream = (group_type) => group_type == gtWebinar;
    const newGroups = this.typeGroups
        // .map(group => ({ ...group, timestamp: new Date()}))
        .filter(group => {
          const now = moment();
          const start_time = moment(group?.start_time);
          return now.diff(start_time, 'days') <= 2
        })
        .filter(group => {
          let groupType = group.group_type;
          //filter flags
          let isPrivate = false;
          let isWeekDay = false;
          let isTag = false;
          let isCommunity = false;

          if(filters.communities && filters.communities.length){
            let copyGroup = group;
            let commListIds = group?.communities ? group.communities.map(comm => comm.id) : []
            let distGrpCommunities = group?.distribution_group ? group?.distribution_group.communities : []
            copyGroup?.communities ? copyGroup.communities.concat(distGrpCommunities) : copyGroup.communities = distGrpCommunities;

            isCommunity = checkCommunityIncludeInFilters(copyGroup, filters.communities);
          } else {
            isCommunity = true;
          }

          if (isWorkshopCoaching(groupType) ) {
            let isForPrivate = group.private
            isPrivate = filters.private? isForPrivate : !isForPrivate;
          } else {
            isPrivate = true;
          }

          if (filters.wdays.length) {
            isWeekDay = false;
            filters.wdays.forEach(filterWday => {
              console.log('filterWday', filterWday);
              group.wdays && group.wdays.forEach((groupWday, index) => {
                isWeekDay = groupWday == filterWday;
              })
              group.week_diffs && group.week_diffs.forEach((diff, index) => {
                const groupWday = moment().zone(0).weekday(1).set({
                  hour: 0,
                  minute: 0,
                  second: 0
                }).add(diff, 'minutes').weekday()
                console.log('groupWday', groupWday, filterWday);
                isWeekDay = groupWday == filterWday;
              })
            });
          } else {
            isWeekDay = true;
          }
          let distributionGroupsTagIds = (group.distribution_group && communityModel?.distribution_groups?.distribution_groups && communityModel?.distribution_groups?.distribution_groups.length) ?
              //communityModel?.distribution_groups?.distribution_groups.filter(distGrp => distGrp.id === group.distribution_group_id)[0].tags.map(tg => tg.id):
              communityModel?.distribution_groups?.distribution_groups[0].tags.map(tg => tg.id):
              false;
          if (filters.tags.length && (isWorkshopCoaching(groupType) || isCollaborationGroup(groupType))) {
            filters.tags.forEach(tag => {
              if(group?.tags){
                if (group.tags.some(groupTag => groupTag.id === tag)) {
                  isTag = true;
                }else if(distributionGroupsTagIds && distributionGroupsTagIds.includes(tag)){
                  isTag = true;
                }
              }else{
                if(group?.distribution_group){
                  if(communityModel?.distribution_groups.distribution_groups.some(grp => grp.id === group.distribution_group.id && grp.tags.some(tg => tg.id === tag))){
                    isTag = true;
                  }
                }
              }
            });

          } else {
            isTag = true;
          }

          const format = 'hh:mm a';
          const eventsTimes = group.meetings_records?.length && group.meetings_records.map(record => moment(`${record.start_time}${record.start_time.includes('Z')? '': 'Z'}`).format(format)) || [moment(group.start_time).format(format)]
          const times = eventsTimes.map(time => moment(time, format)),
              beforeTime = moment(filters.time.start, format),
              afterTime = moment(filters.time.end, format);

          return (group.invited || (isCommunity && isPrivate && isWeekDay && isTag)) &&
              (times.some(time => time.isBetween(beforeTime, afterTime)) ||
                  times.some(time => time.isSame(beforeTime)) ||
                  times.some(time => time.isSame(afterTime))
              );
        });

    if (JSON.stringify(this.filteredGroups) !== JSON.stringify(newGroups)){
      this.setFilteredGroup(newGroups)
    }
  }

  getGroupsData = (session_token = false) => {
    const setGroups = this.setGroups;
    const setGroupsIsLoading = this.setGroupsIsLoading;
    const checkSimpleID = this.checkSimpleID;
    setGroupsIsLoading(true)

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": session_token ? session_token : authModel.session_token,
      },
      params:{
        per_page: 10000,
        visited: false,
        communities_ids: getAllCommunitiesForReqeust()
      }
    };

    axios.get(`${baseAPI}groups`, headers)
        .then(response => {;
          setGroups(response.data);
          checkSimpleID();
        })
        .catch(function (error) {
          // handle error
          setGroupsIsLoading(false)
          console.log('error', error)
        })
        .finally(() => this.setCanBuy(true))
  }

  buildFormData = data => {

    let form = new FormData();
    let copyData = data;
    Object.keys(data).map(key => {
      switch (true) {
        case key ==='image' && !data?.image?.url:
          if(!data[key]?.src?.file) {
            form.append('[remote_image_url]', data.image);
          } else {
            form.append(key, data[key]?.src?.file);
          }
          break;

        case ['themes', 'week_diffs', "subscription_packages_ids", "communities"].includes(key):
          const arr = copyData[key];
          for (let i = 0; i < arr.length; i++) {
            form.append(`${key}[]`, arr[i]);
          }
          break;
        case ["recurring_themes"].includes(key):
          const recurring_themes_arr = copyData[key];
          if(recurring_themes_arr){
            for (let i = 0; i < recurring_themes_arr.length; i++) {
              form.append(`${key}[][start_time]`, recurring_themes_arr[i].start_time);
              form.append(`${key}[][theme]`, recurring_themes_arr[i].theme);
            }
          }
          break;
        case ["meetings_data"].includes(key):
          const meetings_data = copyData[key];
          if(meetings_data){
            for (let i = 0; i < meetings_data.length; i++) {
              form.append(`${key}[][start_time]`, meetings_data[i].start_time);
              form.append(`${key}[][theme]`, meetings_data[i].theme);
              form.append(`${key}[][duration]`, meetings_data[i].duration);
            }
          }
          break;
        case ["schedule_info"].includes(key):
          const schedule_info = copyData[key];
          if(schedule_info){
            for (let i = 0; i < schedule_info.length; i++) {
              form.append(`${key}[][start_time]`, schedule_info[i].start_time);
              form.append(`${key}[][end_time]`, schedule_info[i].end_time);
              form.append(`${key}[][start_date]`, schedule_info[i].start_date);
              form.append(`${key}[][end_date]`, schedule_info[i].end_date);
              form.append(`${key}[][type]`, schedule_info[i].type);

              if(schedule_info[i].repeats){
                schedule_info[i].repeats.forEach(item => {
                  form.append(`${key}[][repeats][]`, item);
                });
              }
            }
          }
          break;
        case ["participants"].includes(key):
          const participants = copyData[key];
          if(participants){
            for (let i = 0; i < participants.length; i++) {
              form.append(`${key}[][email]`, participants[i].email);
              form.append(`${key}[][speaker]`, participants[i].speaker);
            }
          }
          break;
        case ['tags'].includes(key):
          if(data?.tags){
            let communitiesForTags = "";
            let tagsData = [];
            let workdata = null;
            if(data?.communities){
              workdata = data;
            }else if(this.editGroup?.communities){
              workdata = this.editGroup;
            }
            workdata && workdata.communities.map((id,ind) => {if(ind != 0){
              communitiesForTags = communitiesForTags + ", " + id
            }else{
              communitiesForTags += id
            }})
            tagsModel.getTags(authModel.session_token, communitiesForTags);
            tagsModel.tags.map(tag => data.tags.some(tagData => tagData.id === tag.id) &&
                tagsData.push(tag.id))
            //data?.distribution_group_id && communityModel.distribution_groups.distribution_groups.map(grp => grp.id === data?.distribution_group_id &&
            //grp.tags.map(tg => tagsData.push(tg.id)))
            for (let i = 0; i < tagsData.length; i++) {
              form.append(`${key}[]`, tagsData[i]);
            }
            break;
          }else{
            let onlyDistGrp = ["empty"]
            //data?.distribution_group_id && communityModel.distribution_groups.distribution_groups.map(grp => grp.id === data?.distribution_group_id &&
            //grp.tags.map(tg => onlyDistGrp.push(tg.id)))
            for (let i = 0; i < onlyDistGrp.length; i++) {
              form.append(`${key}[]`, onlyDistGrp[i]);
            }
            break;
          }
          /*case key === "communities":
            let communities = data[key];
            if(data?.distribution_group_id){
              let distGroups = communityModel.distribution_groups.distribution_groups.filter(grp => grp.id === parseInt(data["distribution_group_id"]))
              console.log("distribution_groups", JSON.parse(JSON.stringify(communityModel.distribution_groups.distribution_groups)));
              console.log("filtered Groups", distGroups);
              console.log("communities",communities)
              distGroups.map(grp => grp.communities.map(comm => !communities.includes(comm.id + "") && communities.push(comm.id + "")))
              for (let i = 0; i < communities.length; i++) {
                form.append(`${key}[]`, communities[i]);
              }
            }
            else{
              for (let i = 0; i < data[key].length; i++) {
                form.append(`${key}[]`, data[key][i]);
              }
            }
            break;
          /*case key === "distribution_group_id":
            if(!data?.communities){
              let communities = [];
              let distGroups = communityModel.distribution_groups.distribution_groups.map(grp => grp.id === parseInt(data[key]))
              distGroups.map(grp => grp.communities.map(comm => !communities.includes(comm) && communities.push(comm)))
              for (let i = 0; i < communities.length; i++) {
                form.append('communities', communities[i]);
              }
            }

            form.append(key, data[key]);
            break;*/
        default:
          form.append(key, data[key]);
          break;
      }
    });
    return form;
  }

  checkErrors = (error, setFieldError) => {
    if (error.response && error.response.data && error.response.data.errors) {
      error.response.data.errors.forEach(element => {
        switch (true) {
          case element.hasOwnProperty('price'):
            setFieldError('price', element);
            break;

          case element.hasOwnProperty('time'):
            setFieldError('schedule', element);
            break;

          case element.hasOwnProperty('tag') || element.hasOwnProperty('Tag'):
            setFieldError('tags', element);
            break

          case element.hasOwnProperty('in past'):
            setFieldError('schedule', element);
            break;

          case element.hasOwnProperty('file'):
            setFieldError('files', "Something went wrong. The video size can't be more than 10 Gb in a .mp4, .mov format");
            break;

          case element.hasOwnProperty('schedule') || element.hasOwnProperty('day of the week corresponding') || element.hasOwnProperty('meetings.start_time'):
            setFieldError('schedule', element['meetings.start_time']);
            break;

          case element.hasOwnProperty('theme'):
            setFieldError('themes', 'Please, enter the meetting theme correctly. (minimum is 2 characters)');
            break;

          default:
            setFieldError('files', element);
            messagesModel.setMessages("Error");
            messagesModel.setStatus(false);
            messagesModel.setShowMessages(true);
            break;
        }
      });
    }
  }

  createGroup = ({members, ...data}, setFieldError, templates, formTemplates) => {
    const getGroupsData = this.getGroupsData;
    const setCreateGroupStatus = this.setCreateGroupStatus;
    const checkErrors = this.checkErrors;
    const setIDOpenPopupAfterCreateGroup = this.setIDOpenPopupAfterCreateGroup;
    const createGroupTemplates = this.createGroupTemplates;
    const setCreateGroupError = this.setCreateGroupError;

    const form = this.buildFormData(data);
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      }
    };

    axios.post(`${baseAPI}groups`, form, headers)
        .then(response => {
          setCreateGroupStatus('ended');
          setIDOpenPopupAfterCreateGroup(response.data.id)
          getGroupsData();

          if(templates === undefined) {
            inviteMembersModel.setInvites(response.data.id, members);
          }

          if(templates) {
            createGroupTemplates(response.data.id, formTemplates);
          }
        })
        .catch(function (error) {
          checkErrors(error, setFieldError);
          if(error.response && error.response.data && error.response.data.errors) {
            setCreateGroupError(error.response.data.errors['meetings.start_time']);
          }
        })
  }

  updateGroup = (id, {members, ...data}, setFieldError, removedMembers) => {
    const getGroupsData = this.getGroupsData;
    const setCreateGroupStatus = this.setCreateGroupStatus;
    const checkErrors = this.checkErrors;
    const setLastGroup = this.setLastGroup;
    const updateSingleGroup = this.updateSingleGroup;

    const form = this.buildFormData(data);

    setCreateGroupStatus('run');
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      }
    };

    axios.put(`${baseAPI}groups/${id}`, form, headers)
        .then(response => {
          setCreateGroupStatus('ended');
          getGroupsData();
          updateSingleGroup(response.data)
          setLastGroup(response.data)
          inviteMembersModel.setInvites(response.data.id, data.participants, removedMembers.map(user => user.email));
          inviteMembersModel.deleteInvites(response.data.id, removedMembers.map(user => user.id))
        })
        .catch(function (error) {
          checkErrors(error, setFieldError);
          setCreateGroupStatus(false);
        })
  }

  getGroupTemplates = () => {
    const setGroupTemplate = this.setGroupTemplate;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      }
    };

    axios.get(`${baseAPI}group_templates`, headers)
        .then(response => {
          setGroupTemplate(response.data.group_templates.length ? [response.data.group_templates[0]] : []);
        })
        .catch(function (error) {
          console.error(error);
        })
  };

  createGroupTemplates = (id, data) => {
    data.group_id = id;
    const form = this.buildFormData(data);

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      }
    };
    axios.post(`${baseAPI}group_templates`, form, headers)
        .then(response => {
          console.log('response', response);
        })
        .catch(function (error) {
          console.error(error);
        })
  };

  addGroup = groupData => {
    let temp = this.groups.groups;
    const isExist = temp.filter(group => group.id == groupData.id);
    if (isExist.length == 0) {
      const newGroups = {
        groups: [...temp, groupData],
        count: this.groups.count + 1
      };
      this.setGroups(newGroups);
    }
  }

  getSingleGroupData = (id, cancelTokenSource, type) => {
    // const addGroup = this.addGroup;
    const setLastGroup = this.setLastGroup;
    const setSessionGroup = this.setSessionGroup;
    const setGroupError = this.setGroupError;
    const getGroupParticipants = this.getGroupParticipants;
    const  setGroupNotFound = this.setGroupNotFound;
    setGroupError(false);

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      },
      cancelToken: cancelTokenSource?.token
    };
    axios.get(`${baseAPI}groups/${id}`, headers)
        .then(function (response) {
          // addGroup(response.data); // [AW-1605]
          if(type !== 'sessions'){
            setLastGroup(response.data);
            getGroupParticipants(id);
            searchModel.updateSingleGroup(response.data)
          } else {
            setSessionGroup(response.data);
          }

        })
        .catch(function (error) {
          if(axios.isCancel(error)){
            return console.log('Request is Canceled')
          }
          // handle error
          setGroupError(true);
          if(error?.response?.status == 404){
            setGroupNotFound(true)
          }
          console.log('error', error.response.status);

        })
  }

  buyGroup = (id, pay_id, type, promo = null, handleSuccess = () => {}, purchase_type = 'group_purchase', item = {}) => {
    if (this.canBuy) {
      this.setCanBuy(false);
      const changeResultMessage = this.changeResultMessage;
      const getGroupsData = this.getGroupsData;
      const getRecordsGroups = this.getRecordsGroups;
      const getSingleGroupData = this.getSingleGroupData;
      const headers = {
        "headers": {
          "accept": "application/json, text/plain, */*",
          "session-token": authModel.session_token
        }
      };
      const deta = {
        payment_source_id: pay_id,
        payment_source_type: type,
        promo_code: promo,
        purchase_type,
        invited: item?.invited
      }
      axios.post(`${baseAPI}groups/${id}/buy`, deta, headers)
          .then(function (response) {
            changeResultMessage('Success');
            mmContextModel.mmContextGroupsData(userModel?.user?.id);
            mediaModel.getMediaGroups(null, id);
            getSingleGroupData(id)
            getGroupsData();
            getRecordsGroups(null, id);
            handleSuccess();
          })
          .catch(function (error) {
            changeResultMessage(error.response.data ? error.response.data.errors[0] : 'Error');
          }).finally(r => {
        this.setCanBuy(true);
      })
    }
  }

  buyIndividualGroup = (group, id, pay_id, type, closeTwoPopups, purchase_type='booking_purchase', handleSuccess = () => {}) => {
    if (this.canBuy) {
      this.setCanBuy(false);
      const changeResultMessage = this.changeResultMessage;
      const getGroupsData = this.getGroupsData;
      const getRecordsGroups = this.getRecordsGroups;
      const getSingleGroupData = this.getSingleGroupData;
      const setGroupChooseSessions = this.setGroupChooseSessions;
      const setSuccessfulPurchase = this.setSuccessfulPurchase;
      const setGroupChooseSessionsError = this.setGroupChooseSessionsError;
      const headers = {
        "headers": {
          "accept": "application/json, text/plain, */*",
          "session-token": authModel.session_token
        }
      };

      const form = new FormData();

      pay_id && form.append(`[payment_source_id]`, pay_id);
      type && form.append(`[payment_source_type]`, type);
      form.append(`[purchase_type]`, purchase_type);

      setGroupChooseSessions(group);
      axios.post(`${baseAPI}groups/${id}/buy`, form, headers)
          .then(function (response) {
            changeResultMessage('Success');
            mmContextModel.mmContextGroupsData(userModel?.user?.id);
            mediaModel.getMediaGroups(null, id);
            getSingleGroupData(id)
            getGroupsData();
            getRecordsGroups(null, id);
            handleSuccess();

            if(closeTwoPopups){
              closeTwoPopups()
            }

            setSuccessfulPurchase(true);
          })
          .catch(function (error) {
            changeResultMessage(error?.response?.data ? error?.response?.data?.errors[0] : 'Error');
            setGroupChooseSessionsError(error?.response?.data?.errors);
          }).finally(r => {
        this.setCanBuy(true);
      })
    }
  }

  bookingsSelectSlots = (id, slots, closeTwoPopups) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };

    const form = new FormData();
    slots.forEach((slot) => {
      form.append(`[slots][]`, slot);
    });

    const getSingleGroupData = this.getSingleGroupData;
    const setSuccessfulPurchase = this.setSuccessfulPurchase;
    const setGroupChooseSlotsError = this.setGroupChooseSlotsError;

    axios.post(`${baseAPI}bookings/${id}/select_slots`, form, headers)
        .then(function (response) {
          setSuccessfulPurchase(false);
          getSingleGroupData(id, undefined, 'sessions')
          if(closeTwoPopups) {
            closeTwoPopups();
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setGroupChooseSlotsError(error?.response?.data?.errors[0]);
        })
  };

  getBookingsSchedule = () => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };

    const setScheduleToday = this.setScheduleToday;
    axios.get(`${baseAPI}bookings?[per_page]=1000&`, headers)
        .then(function (response) {
          setScheduleToday(response.data);
        })
        .catch(function (error) {
          console.log("error", error);
        })
  };

  markAsViewedSlots = (id) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };

    axios.post(`${baseAPI}bookings/${id}/view`, undefined, headers)
        .then(function (response) {
          console.log("response.data", response.data);
        })
        .catch(function (error) {
          console.log("error", error);
        })
  };

  checkPromokode = code => {
    const setPromokode = this.setPromokode;
    setPromokode('');
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.get(`${baseAPI}promo_codes/${code}`, headers)
        .then(function (response) {
          setPromokode(response.data);
        })
        .catch(function (error) {
          if (code) {
            setPromokode('error');
          }
        })
  }

  leaveGroup = (data, onSuccess = () =>{}) => {
    const {
      id,
      ...rest
    } = data;
    const changeResultMessage = this.changeResultMessage;
    const getGroupsData = this.getGroupsData;
    const getSingleGroupData = this.getSingleGroupData;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}groups/${id}/leave`, rest, headers)
        .then(function (response) {
          changeResultMessage('You Request send');
          purchasesModel.getPurchases();
          getSingleGroupData(id, undefined, 'sessions')
          getGroupsData();
          onSuccess();
        })
        .catch(function (error) {
          // handle error
          const errorText = error.response.data ? error.response.data.errors[0] : 'Error';
          changeResultMessage(errorText);
          messagesModel.setMessages(errorText);
          messagesModel.setStatus(false);
          messagesModel.setShowMessages(true);
        })
  }

  cancelGroup = data => {
    const { id,  errorCb, successCb, ...rest } = data;
    const getGroupsData = this.getGroupsData;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}groups/${id}/cancel`, rest, headers)
        .then(function (response) {
          getGroupsData();
          successCb()
        })
        .catch(function (error) {
          // handle error
          errorCb(error.response)
          console.log('error', error.response);
        })
  }

  cancelNextMeeting = data => {
    const { id,  errorCb, successCb, ...rest } = data;
    const getGroupsData = this.getGroupsData;
    const getSingleGroupData = this.getSingleGroupData;

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      },
      params: rest
    };
    axios.delete(`${baseAPI}groups/${id}/cancel_next_meeting`, headers)
        .then(function (response) {
          getGroupsData();
          getSingleGroupData(id)
          successCb()
        })
        .catch(function (error) {
          // handle error
          console.log('error', error.response);
          errorCb(error.response)
        })
  }

  resheduleNextMeeting = data => {
    const { id, errorCb, successCb, ...rest } = data;
    const getGroupsData = this.getGroupsData;
    const getSingleGroupData = this.getSingleGroupData;

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}groups/${id}/reshedule_next_meeting`, rest, headers)
        .then(function (response) {
          getGroupsData();
          getSingleGroupData(id)
          successCb()
        })
        .catch(function (error) {
          console.log('error', error.response);
          errorCb(error.response)
        })
  }

  getSingleGroup = id => {
    const group = this.groups.groups.find(group => group.id === id);
    if (!group) {
      this.getSingleGroupData(id);
    } else {
      this.setLastGroup(group);
      return group;
    }

  }

  updateSingleGroup = data => {

    let currIndex = findIndexInArrOfObj(this.groups.groups, data.id);
    if (~currIndex){
      this.groups.groups = Object.assign([], this.groups.groups, {[currIndex]: data})
    }

    currIndex = findIndexInArrOfObj(this.filteredGroups, data.id);
    if (~currIndex){
      this.setFilteredGroup(Object.assign([], this.filteredGroups, {[currIndex]: data}))
    }
  }

  removeGroup = (id, type = null) => {
    const getRecordsGroups = this.getRecordsGroups;
    const getGroupsData = this.getGroupsData;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.delete(`${baseAPI}groups/${id}/delete`, headers)
        .then(function (response) {
          getGroupsData();
          getRecordsGroups(null, id);
          mediaModel.getMediaGroups(null, id);
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  deleteGroup = (id) => {
    const getGroupsData = this.getGroupsData;
    const getRecordsGroups = this.getRecordsGroups;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.delete(`${baseAPI}groups/${id}`, headers)
        .then(function (response) {
          getRecordsGroups();
          getGroupsData();
          mediaModel.getMediaGroups();
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  restoreGroup = (id, type = null) => {
    const getRecordsGroups= this.getRecordsGroups;
    const getGroupsData = this.getGroupsData;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}groups/${id}/restore`, '',headers)
        .then(function (response) {
          getGroupsData()
          if (type === 'record') {
            mediaModel.getMediaGroups(null, id);
          } else {
            getRecordsGroups(null, id);
          }
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  getRecordsGroups = (session_token = false, id) => {
    const setRecords = this.setRecords;
    const setRecordsIsLoading = this.setRecordsIsLoading;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": session_token ? session_token : authModel.session_token,
      },
      params: {
        per_page: 10000,
        visited: false,
        records: "all",
        status: "all",
        group_type: "webinar",
        any_records: true,
        communities_ids: getAllCommunitiesForReqeust()
      }
    };

    setRecordsIsLoading(true)
    axios.get(
        `${baseAPI}groups`, headers
    )
        .then(function (response) {
          const groupsIds = response.data.groups.map(item => item.id);
          setRecords({ groups: response.data.groups, count: response.data.count, groupsIds });
          if (id){
            const record = response?.data?.groups?.find(record => {
              return record.id === id
            })
            record && searchModel.updateSingleRecord(record)
          }
        })
        .catch(function (error) {
          // handle error
          setRecordsIsLoading(false)
          console.log('error', error)
        })
        .finally(() => this.setCanBuy(true))
  }

  setGroupsVimeoDurations = (data) => {
    for (let i = 0; i < data.count; i++) {
      const currentGroup = data.groups[i];
      if (currentGroup.group_type === gtWebinar && currentGroup.is_owner) {
        currentGroup.meetings_records.forEach(record => {
          if (record.url && record.ended && record.duration === 0) {
            vimeoModel.getVimeoData(record.id, record.url.split('/')[3]);
          }
        })
      }
    }
  }

  setCategoriesList = (data) => {
    this.categoryList = data;
  }

  setGroupTemplate = (data) => {
    this.templateGroup = data;
  };

  getGroupParticipants = id => {
    const setParticipants = this.setParticipants;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.get(`${baseAPI}groups/${id}/participants`, headers)
        .then(function (response) {
          setParticipants(response.data, id);
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  getGroupAttachments = id => {
    const setAttachments = this.setAttachments;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.get(`${baseAPI}attachments?group_id=${id}`, headers)
        .then(function (response) {
          setAttachments(response.data.attachments);
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  getCategoriesList = () => {
    const setCategoriesList = this.setCategoriesList;
    const headers = {
      "headers": {
        "session-token": authModel.session_token
      }
    };
    axios.get(`${baseAPI}categories`, headers)
        .then(function (response) {
          setCategoriesList(response.data.categories);
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  saveAttachment = (data) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}attachments`, data, headers)
        .then(response => {
          this.getGroupAttachments(data.group_id);
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  removeAttachment = (attachment_id, group_id) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.delete(`${baseAPI}attachments/${attachment_id}`,headers)
        .then(response => {
          this.getGroupAttachments(group_id);
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  reportGroup = (groupId, content) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}groups/${groupId}/report`, { content: content }, headers)
        .then(response => {
          //console.log(response)
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  closeGroup = (id) => {
    const getGroupsData = this.getGroupsData;
    const getRecordsGroups = this.getRecordsGroups;
    const getSingleGroupData = this.getSingleGroupData;

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}groups/${id}/private`, {}, headers)
        .then(function (response) {
          getSingleGroupData(id)
          getRecordsGroups();
          getGroupsData();
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  openGroup = (id) => {
    const getGroupsData = this.getGroupsData;
    const getRecordsGroups = this.getRecordsGroups;
    const getSingleGroupData = this.getSingleGroupData;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}groups/${id}/public`, {}, headers)
        .then(function (response) {
          getSingleGroupData(id)
          getRecordsGroups();
          getGroupsData(authModel.session_token);
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }

  shareGroup = (params) =>{
    const {group_id, sharing_type = 'external'} = params;
    const getSingleGroupData = this.getSingleGroupData;
    const getRecordsGroups = this.getRecordsGroups;

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}groups/${group_id}/sharings_increment`, {sharing_type}, headers)
        .then(function (response) {
          getSingleGroupData(group_id)
          mediaModel.getMediaGroups()
          getRecordsGroups()
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }
  likeGroup = (params) =>{
    const {group_id} = params;
    const getSingleGroupData = this.getSingleGroupData;
    const getRecordsGroups = this.getRecordsGroups;

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.post(`${baseAPI}groups/${group_id}/like`, {}, headers)
        .then(function (response) {
          getSingleGroupData(group_id)
          mediaModel.getMediaGroups()
          getRecordsGroups()
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }
  unlikeGroup = (params) =>{
    const {group_id} = params;
    const getSingleGroupData = this.getSingleGroupData;
    const getRecordsGroups = this.getRecordsGroups;

    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }
    };
    axios.delete(`${baseAPI}groups/${group_id}/unlike`, headers)
        .then(function (response) {
          getSingleGroupData(group_id)
          mediaModel.getMediaGroups()
          getRecordsGroups()
        })
        .catch(function (error) {
          console.log('error', error);
        })
  }
}

decorate(GroupsModel, {
  groups: observable,
  state: observable,
  scheduleToday: observable,
  successfulPurchase: observable,
  groupChooseSessions: observable,
  groupChooseSessionsError: observable,
  groupChooseSlotsError: observable,
  templateGroup: observable,
  userGroups: observable,
  filteredGroups: observable,
  resualtMessage: observable,
  promocode: observable,
  lastGroup: observable,
  createGroupError: observable,
  getGroupsData: action,
  createGroupTemplates: action,
  getTags: action,
  tags: observable,
  getGroupError: observable,
  recordsGroups: observable,
  groupNotFound: observable,
  sessionGroup:  observable,
  filteredRecordsGroups: observable,
  uploadProgress: observable,
  setGroupNotFound: action,
  setScheduleToday: action,
  setCreateGroupError: action,
  setGroupChooseSessions: action,
  setGroupChooseSessionsError: action,
  setGroupChooseSlotsError: action,
  setSuccessfulPurchase: action,
  groupParticipants: observable,
  saveAttachment: action,
  getGroupAttachments: action,
  attachments: observable,
  removeAttachment: action,
  editGroup: observable,
  createGroupStatus: observable,
  openGroupPopupAfterCreate: observable,
  categoryList: observable,
  setCanChangeGroupNotFound: action,
  canSetGroupNotFound: observable,
  openJoinLSPopup: observable,
  setOpenJoinLSPopup: action,
  shareGroup: action,
  likeGroup: action,
  getSingleGroupData: action,
  setSessionGroup: action,
  profileActiveLink: observable,
  setProfileActiveLink: action,
})

export const groupsModel = new GroupsModel();