import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import RightBlock from '../Dashboard/Right';
import MainPage from '../MainPage';

import FilterContent from '../../components/FilterContent'
import Empty from '../../components/Empty';
import LivestreamsRecords from './lsRecords';

import './LSRGroup.scss';
import LoadingBlock from "../../components/LoadingBlock";

const LsRecords = props => {
  const {
    groupsModel: { recordsGroups, changeResultMessage, lastGroup, setSimpleId, clearSimpleId }, // filteredRecordsGroups
    // tab,
    userModel: { user },
    masterMindsModel: { randomItems },
    // mmContextModel: { mmcontext },
    match,
  } = props;

  useEffect(() => {
    setSimpleId(match?.params?.id);
    changeResultMessage('');
    randomItems(2);
  });

  return(
    <MainPage>
      <div className="dashboardBlock">
        <div className="dailyBlock">
          {(recordsGroups?.groups && user?.role !== undefined) ? // filteredRecordsGroups
            <FilterContent
              userId={user?.id}
              role={user?.role}
              type={'webinar'}
              title={'Livestreams'}
              data={recordsGroups.groups.filter(group => {
                return group?.past_meetings_count && group?.meetings_records && group?.meetings_records.length > 0
              })} // filteredRecordsGroups
            >
              <LivestreamsRecords
                key="daily"
                match={match}
                lastGroup={lastGroup}
                clearSimpleId={clearSimpleId}
                isLoading={recordsGroups.isLoading || recordsGroups.firstLoading}
              />
            </FilterContent>
            : (recordsGroups.isLoading || recordsGroups.firstLoading) ?
              <LoadingBlock/> : <Empty/>
          }
        </div>
      </div>
      {/*<RightBlock />*/}
      {/* <Chat /> */}
    </MainPage>
  )
}

export default inject('groupsModel', 'masterMindsModel', 'userModel', 'mmContextModel')(observer(LsRecords)); // 'mmContextModel'
