import {gtRecurring, gtRecurringWebinar, gtSupport, gtWebinar, meditation, podcast, video_record} from "./general";
import {collaborations, livestreams, meditations, podcasts, records, videos, workshops} from "./urlKeys";

export const typeList = [{
        id: "record",
        name: 'My Livestreams',
    },
    {
        id: "video_record",
        name: 'Videos',
    },
    {
        id: "meditation",
        name: 'Meditations',
    },
    {
        id: "podcast",
        name: 'Podcasts',
    },
];

export const typeToUrl = {
  [video_record]: videos,
  [meditation]: meditations,
  [podcast]: podcasts,
  [gtWebinar]:{
    ended: records,
    DEFAULT: livestreams
  },
  [gtRecurringWebinar]:{
    ended: records,
    DEFAULT: livestreams
  },
  [gtSupport]: collaborations,
  [gtRecurring]: workshops,
  [gtRecurringWebinar]: workshops
}
