import { observable, decorate } from 'mobx';
import axios from 'axios';
import { authModel } from './auth';
import { baseAPI } from '../const/settings';
import {communityModel} from "./community";
import {gtRecurringWebinar, gtWebinar} from "../const/general";
import {userModel} from "./user";

const getAllCommunitiesForReqeust = () => {
  if (communityModel.communityList.selected.includes(userModel.user.community_id)){
    return communityModel.communityList.selected.join(',')
  }
  const all = communityModel.communityList.selected.concat([userModel.user.community_id])
  return all.join(',')
}
export class SearchModel {
  constructor() {
    this.searchGroups = {
      groups: [],
      count: 0
    };
    this.searchRecords = {
      groups: [],
      count: 0
    };
    this.searchMasterMinds = {
      users: [],
      count: 0
    };
  }

  setGroups = data => this.searchGroups = data;
  setRecords = data => this.searchRecords = data;
  setMasterMinds = data => this.searchMasterMinds = data;

  extendRecords = data => {
    this.searchRecords = Object.assign({}, this.searchRecords, {
      groups: [...this.searchRecords.groups, ...data.groups],
      count: this.searchRecords.count + data.count
    })
  }

  getGroupsData = (per_page, groupTypes = '', name = '', session_token = false) => {
    const setGroups = this.setGroups;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": session_token ? session_token : authModel.session_token,
      },
      params: {
        per_page,
        name,
        group_type: groupTypes,
        sort_column: "start_time",
        // communities_ids: getAllCommunitiesForReqeust()
      }
    };

    axios.get(`${baseAPI}groups`, headers)
        .then(response => setGroups(response.data))
        .catch(function (error) {
          // handle error
          console.log('error', error)
        })
  }

  updateSingleRecord = data => {
    const setRecords = this.setRecords;
    if (!this.searchRecords?.groups?.length) return;
    let recordIndex = -1;
    this.searchRecords.groups.find((group, index) => {
      if (group.id === data.id){
        recordIndex = index
      }
      return group.id === data.id
    })
    if (~recordIndex){
      const newGroups = Object.assign([],this.searchRecords.groups, {[recordIndex]: data})
      setRecords(Object.assign({},this.searchRecords, {groups: newGroups}))
    }

  }

  updateSingleGroup = data => {
    const setGroups = this.setGroups;
    if (!this.searchGroups?.groups?.length) return;
    let groupIndex = -1;
    this.searchGroups.groups.find((group, index) => {
      if (group.id === data.id){
        groupIndex = index
      }
      return group.id === data.id
    })
    if (~groupIndex){
      const newGroups = Object.assign([],this.searchGroups.groups, {[groupIndex]: data})
      setGroups(Object.assign({},this.searchGroups, {groups: newGroups}))
    }
  }

  getRecordsData = (per_page, records = '', name = '', session_token = false) => {
    let needExtend = false;
    const setRecords = this.setRecords;
    const extendRecords = this.extendRecords;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": session_token ? session_token : authModel.session_token,
      },
      params: {
        per_page,
        name,
        sort_column: 'start_time',
        visited: false,
        records: 'all',
        status: 'all',
        group_type: `${gtWebinar},${gtRecurringWebinar}`,
        any_records: true,
        //communities_ids: getAllCommunitiesForReqeust()
      }
    };
    axios.get(`${baseAPI}groups`, headers)
        .then(response => {
          if (needExtend){
            extendRecords(response.data)
          } else {
            setRecords(response.data)
            needExtend = true;
          }
        })
        .catch(function (error) {
          // handle error
          console.log('error', error)
        })

    headers.params = {
      per_page,
      name,
      record_type: records,
      // communities_ids: getAllCommunitiesForReqeust()
    }

    axios.get(`${baseAPI}records`, headers)
        .then(response => {
          if (needExtend){
            extendRecords(response.data)
          } else {
            setRecords(response.data)
            needExtend = true;
          }
        })
        .catch(function (error) {
          console.log('error', error)
        })
  }

  getMasterMinds = (per_page, query, session_token = false) => {
    const setMasterMinds = this.setMasterMinds;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": session_token ? session_token : authModel.session_token,
      },
      params: {
        per_page,
        full_name: query,
        email: query,
        phone_number: query
        // communities_ids: [userModel.community].join()
      }
    };

    axios.get(`${baseAPI}users`, headers)
        .then(response => setMasterMinds(response.data))
        .catch(function (error) {
          console.log('error', error)
        })
  }
}

decorate(SearchModel, {
  searchGroups: observable,
  searchRecords: observable,
  searchMasterMinds: observable
})

export const searchModel = new SearchModel();
