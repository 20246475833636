import React  from 'react';
import { inject, observer } from 'mobx-react';
import Button from '../../../components/Button';
import CustomSneckbar from '../../../components/CustomSnackbar';

import './message.scss';

const Message = props => {
  const { close, onSuccess, subscribeModel: { resualtMessage, changeResultMessage } } = props;
  return (
    <div className="messageBlock">
      <div className="messageTitle">Unsubscribe</div>
      <div className="messageText">
        Are you sure you want to unsubscribe?<br />
        By taking this action, you will no longer have access to create groups.
      </div>
      <div className="messageActions">
        <Button label="Cancel" className="MDCancel" onClick={() => { changeResultMessage(''); close(); }} />
        <Button label="Unsubscribe" className="MDDelete" onClick={() => onSuccess()} />
      </div>
      <CustomSneckbar 
        message={resualtMessage}
        onClose={() => { 
          changeResultMessage('');
          if (resualtMessage === 'Success') { close(); } }}
        severity={resualtMessage === 'Success' ? "success" : 'error'}
      />
    </div>
  )
}

export default inject('subscribeModel')(observer(Message));
