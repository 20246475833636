import { decorate, action } from 'mobx';
import axios from 'axios';

import { baseAPI } from '../const/settings';

import { authModel } from './auth';
import { userModel } from './user';

export class AnswersModel {
  setAnswers = data => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.post(`${baseAPI}answers`, data, headers)
      .then(function (response) {
        // handle success
        userModel.getCurentUser(authModel.session_token);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }
}

decorate(AnswersModel, {
  setAnswers: action,
})

export const answersModel = new AnswersModel();
