import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik';
import ActionIcon from '../ActionIcon'
import InputWithIcon from '../../Input/inputWithIcon';
import CustomInput from '../../Input/customInput';
import create_group_icons from '../../../res/icons/create_group_icons';

const RenderThemeInputs = ({ isEdit = false, rest, amountOfSchedules }) => {
  const { values, setFieldValue } = useFormikContext()
  const numberOfMeetings = values['meetings_count']
  const numberOfThemes = values['themes'].length

  const handleRemoveTheme = (index) => {
    const newArr = values['themes']
    newArr.splice(index, 1)
    setFieldValue('themes', newArr)
    setFieldValue('meetings_count', newArr.length)
  }

  useEffect(() => {
    const diff = numberOfMeetings - numberOfThemes;
    const themesArr = values['themes'] || [];

    if (diff >= 0) {
      setFieldValue('themes', themesArr.concat(new Array(diff).fill('')));
    }else{
      setFieldValue('themes', themesArr.splice(numberOfMeetings-1, diff))
    }
  }, [numberOfMeetings, numberOfThemes])

  const handleThemeChange = (e, i) => {
    const cloneArr = values['themes']
    const val = e.target.value.length < 33 ? e.target.value : cloneArr[i]
    cloneArr[i] = val
    setFieldValue('themes', cloneArr)
  }
  const isThemes = values['themes'] !== ""
  return (
    <>
      {isThemes && values['themes'].map((item, key) => {
        const isFinished = isEdit && values?.past_meetings_count && key + 1 <= values?.past_meetings_count; 

        return (
          <>
            {values['themes'][key] === '' && <div className="requiredNotification themesInput">required</div>}
            <div style={{ position: 'relative', left: 30 }} key={key}>
              <InputWithIcon className="width320" icon={create_group_icons.red.fire} key={rest.name}>
                <CustomInput
                  showError = {values['themes'][key].length < 2}
                  {...rest}
                  id={rest.name}
                  disabled={isFinished}
                  value={values['themes'][key]}
                  onChange={(e) => handleThemeChange(e, key)}
                  themeKey = {key}
                />
                {numberOfThemes > amountOfSchedules && !isFinished ? <ActionIcon
                  type='remove'
                  offset={{ left: 5 }}
                  onClick={() => handleRemoveTheme(key)}
                  className = "removeThemesIcon"
                />: null}
              </InputWithIcon>
            </div>
          </>
        )
      })}
    </>
  )
}

export default RenderThemeInputs


