
import React from "react";
import {Route, Redirect, useHistory} from "react-router-dom";
import { inject, observer } from 'mobx-react';

import { home } from '../../const/urlKeys';
import {groupsModel} from "../../models/groups";
import {actionPay} from "../../const/general";

function PrivateRoute({ component: Component, authModel, ...rest }) {
  const { session_token } = authModel;
  const { location: { pathname, state }} = rest;
  const history = useHistory();

  const query =  new URLSearchParams(window.location.search);
  const recivedToken = query?.get('token');
  const receivedCommunityInviteId = query?.get('invite')
  if(receivedCommunityInviteId){
    // localStorage.setItem('community_invite_id', receivedCommunityInviteId);
  }
  if (recivedToken) {
    const realToken = recivedToken.split('').reverse().join('')
    query.delete('token')
    const newSearch = query.toString()
    const redirectObj = {pathname, search: newSearch, state: {...state, action: query.get('action') }}

    const redirect = () => {
      history.push(redirectObj)
    }

    const getGroupData = () => {
      if (query.get('id') && query.get('action') === actionPay){
        groupsModel.getSingleGroupData(query.get('id'))
      }
    }

    const loginUser = () => {
      authModel.setSesionToken(realToken)
      authModel.changeLoading(true)
      getGroupData()
      authModel.checkAuthToken()
      session_token && redirect()
    }
    const logoutUser = async () => await authModel.logout()
    session_token? logoutUser().then(loginUser) : loginUser()

    return (<Redirect to={session_token? home : redirectObj}/>)
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (session_token || pathname === home) {
          return <Component {...props} />
        }
        localStorage.setItem('pathname', pathname);
        return <Redirect to={home} />
      }}
    />
  );
}

export default inject('authModel')(observer(PrivateRoute));
