import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import MMPayments from './MMPayments';
import UserPayments from './UserPayments';

import { userRole, mmRole } from '../../../const/general';

import './payments.scss';

const Payments = props => {
  const { 
    userModel: { user: { role } }, 
    purchasesModel: { getPurchases }, 
    subscribeModel: { getSubscriptions },
    transactionsModel: { getBalance, getEarnings } // getTransactions
  } = props;

  useEffect(() => {
    getPurchases();
    if (role === mmRole ) {
      getBalance();
      // getTransactions();
      getEarnings();
    } else {
      getSubscriptions();
    }
  }, [getPurchases, getSubscriptions, getBalance, getEarnings, role]); // getTransactions

  const defualtState = role === userRole ? 'Payments' : 'Earnings';
  const [viewBlock, setViewBlock] = useState(defualtState);

  const returnButton = key => <Button
    classes={{ 
      root: viewBlock === key ? 'activeButton' : 'groupButton', 
      label: viewBlock === key ? 'activeButtonText' : 'buttonText',
    }}
    onClick={() => setViewBlock(key)}
  >
    {key}
  </Button>;

  return (
    <>
      <ButtonGroup color="primary" aria-label="outlined primary button group" className="buttonGroup">
        {role === mmRole && returnButton('Earnings')}
        {returnButton('Payments')}
        {returnButton('Subscriptions')}
      </ButtonGroup>
      {role === mmRole && <MMPayments viewBlock={viewBlock} />}
      {role === userRole && <UserPayments viewBlock={viewBlock} />}
    </>
  )
}

export default inject('userModel', 'purchasesModel', 'subscribeModel', 'transactionsModel')(observer(Payments));
