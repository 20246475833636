import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

const ClearButton = (props) => {
  const { onClick , item } = props;

  return (
    <button onClick={() => onClick(item)} className="clearButton" type="button">
      <CancelIcon />
    </button>
  )
};

export default ClearButton;
