import React, {useEffect, useState} from "react";
import { inject, observer } from 'mobx-react';

import ModeratorItem from "./ModeratorItem";
import MemberItem from "./MemberItem";
import CustomModal from "../Modals/Modal";
import InviteModerators from "../InviteModerators";

import './styles.scss'
import {ctOrganization, InfluencerLabel, OrganizationLabel, PrivateLabel, PublicLabel} from "../../const/general";
import {toJS} from "mobx";
import {returnName} from "../../units/func";
import categoryNameIcon from "../../res/icons/categoryName.svg";
import membersIcon from "../../res/icons/members.svg";

const MyOrganizationBlock = (props) => {
  const {
    userModel: {user},
    communityModel: { myOrganizationID, communityList, communityMembersList, communityModeratorsList, getModeratorsList, getMembersList, inviteUsersToCommunity, removeModeratorFromCommunity }
  } = props;
  const getMyCommunity = () => communityList.mappedCommunities[myOrganizationID]
  const [myCommunity, setMyCommunity] = useState(getMyCommunity)

  useEffect(() => {
    getMembersList({id:myOrganizationID})
    getModeratorsList({id:myOrganizationID})
  },[myCommunity])

  useEffect(() => {
    setMyCommunity(getMyCommunity())
  }, [myOrganizationID])


  const [moderatorDialogOpened, setModeratorDialogOpened] = useState(false)
  const toggleModeratorDialog = ( bool ) => setModeratorDialogOpened(bool)

  const handleSetModerators = userObj => {
    if (!userObj || !userObj.id) return console.error('need user_id')
    inviteUsersToCommunity({
      id: myOrganizationID,
      users_ids: [userObj.id]
    })
  }

  const removeModerator = userObj => {
    if (!userObj || !userObj.id) return console.error('need user_id')
    removeModeratorFromCommunity({
      id: myOrganizationID,
      users_ids: [userObj.id]
    })
  }

  const getLabel = () => myCommunity? `${myCommunity.private? PrivateLabel: PublicLabel} ${myCommunity.community_type === ctOrganization? OrganizationLabel: InfluencerLabel}` : ''

  return <div className={'myOrganization'}>
    <div className="myOrganization_wrapper">
      <div className="myOrganization_title">
        {myCommunity?.title}
      </div>
      <div className="myOrganization_label">{getLabel()}</div>
      <div className="myOrganization_members">
        {myCommunity?.category?.name && <div className="myOrganizationMembersBlock">
          <img src={categoryNameIcon} alt="categoryName"  className="img"/>
          <div className="membersBlockText">{myCommunity?.category?.name}</div>
        </div>}
        <div className="myOrganizationMembersBlock">
          <img className="img" src={membersIcon} alt="usersCount"/>
          <div className="membersBlockText">{myCommunity?.users_count} members</div>
        </div>
      </div>
      <div className="myOrganization_description">{myCommunity?.description}</div>
      <div className="myOrganization_moderators moderatorsBlock">
        <div className="moderatorsBlock_header">
          <div className="moderatorsBlock_title">Moderators</div>
          <div className="moderatorsBlock_addBtn" onClick={(e) => {
            e.stopPropagation();
            toggleModeratorDialog(true)
          }}>Add</div>
        </div>
        <div className="moderatorsBlock_list">
          {communityModeratorsList.users?.slice().filter((mm)=> mm.id !== user.id).map((mm) => <ModeratorItem user={mm} removeModerator = {removeModerator}/>)}
        </div>

      </div>
      <div className="myOrganization_members membersBlock" style={{flexDirection:"column"}}>
        <div className="membersBlock_title">Members</div>
        <div className="membersBlock_list">
          {communityMembersList.users?.filter( m => m.id !== user.id)
            .sort((next, prev) => returnName(next) > returnName(prev)? -1: 1)
            .sort((next, prev) => prev.hasOwnProperty('requested')? 1 : -1)
            .map((mm) => <MemberItem user={mm} community={myCommunity}/>)}
        </div>
      </div>
      {moderatorDialogOpened &&
      <CustomModal
        isOpen={moderatorDialogOpened}
        handleClose={toggleModeratorDialog}
        closeColor={'#43425D'}
      >
        <InviteModerators moderators={communityModeratorsList.users} handleClose={toggleModeratorDialog} setModerators={handleSetModerators} removeModerator={removeModerator}/>
      </CustomModal>
      }
    </div>
  </div>
}

export default inject( 'communityModel', "userModel")(observer(MyOrganizationBlock))