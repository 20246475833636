import React, {useEffect, useState, useRef} from 'react';
import LSRGroup from '../../components/LsRecordGroup';

import {actionPay, added} from '../../const/general';
import Dialog from "../../components/Dialog";
import AttendGroup from "../AttendGroup";
import ContentDetailsPopup from '../../components/ContentDetailsPopup'
import {useHistory} from "react-router";
import {inject, observer} from "mobx-react";
import LoadingBlock from "../../components/LoadingBlock";


const VideosGroups = props => {
  const { tab, data, viewBlock, restore, match, userId, title, id, setCurrentGroupId, currentGroupData, mediaModel, isLoading } = props;

  const [isAttendOpen, setAttendOpen] = useState(false);
  const [isDetailsOpen, setDetailsOpen] = useState(false)
  const [openReport, setOpenReport] = useState(false);
  const [postWidth, setPostWidth] = useState("auto")

  const history = useHistory()
  const query =  new URLSearchParams(window.location.search);
  const [defaultOpen, setDefaultOpen] = useState((match && match.params && match?.params?.id) || query?.get('id') || null)
  const postListRef = useRef(null)

  const  getItemPath = id => {
    return match?.params && !Object.keys(match?.params).length && {
      forSet: `${match.url}?id=${id}`,
      forBack: match.url
    }
  }

  if (defaultOpen && data.length && data.findIndex(group => group?.id == defaultOpen) == -1){
    const group = mediaModel.extendFilterByGroup(defaultOpen)
    if (group){
      setDefaultOpen(false)
      setDetailsOpen(group)
    }
  }

  const attendDialog = () => (
    <Dialog
      openButton={null}
      className="detailGroup"
      closeColor="#43425D"
      onClose={ _ => {
        setAttendOpen(false)
      }}
      defaultOpen={isAttendOpen}
    >
      <AttendGroup item={isAttendOpen} />
    </Dialog>
  );

  useEffect(_ => {
    if (isDetailsOpen){
      const path = getItemPath(isDetailsOpen.id)
      path && query?.get('action') !== actionPay && history.push(path.forSet)
    }
  }, [isDetailsOpen])

  let currentData = data;
  if (viewBlock) {
    currentData = data?.filter(item => {
      if (viewBlock === added) {
        return item.owner.id !== userId
      }
      return item.owner.id === userId && !item.removed
    });
  }
  
  useEffect(() => {
    if(postListRef?.current?.children && postListRef.current.children.length){
      postListRef.current.children[0].clientWidth &&
      postListRef.current.children[0].clientWidth !== 250 && 
      setPostWidth(postListRef.current.children[0].clientWidth)
    }
  }, [postListRef, data])

  return (
    <div className={`listBlock personListBlockVideos ${!!currentData?.length ? "" : "emptyListBlock"}`} ref = {postListRef}>
      {currentData?.length > 0 ?
        <>
          {
            currentData.map(item => {
              return (
                <div className="groupLsRecords" key={item.id} style = {{maxWidth: `${postWidth < 451 ? (postWidth + "px") : "450px"}`}}>
                  <LSRGroup
                    id={id}
                    item={item}
                    tab={tab}
                    restore={restore}
                    showButton={false}
                    is_owner={item.owner.id == userId}
                    handleOpenDialog={setDetailsOpen}
                    showReport={_ => {
                      setDetailsOpen(item)
                      setOpenReport(true)
                    }}
                    defaultOpen={defaultOpen}
                    postWidth = {postWidth}
                  />
                </div>
              )
            })
          }
          {isAttendOpen && (attendDialog())}
        </>
        :
        isLoading ?
          <LoadingBlock/>
          :
          <div className="emptyBlock">
            <div className="empty">
              <div className="title">
                Currently no {title.toLowerCase()} are available
              </div>
            </div>
          </div>
      }
      {isDetailsOpen && (<ContentDetailsPopup
        item={isDetailsOpen}
        tab={tab}
        handleClose={ bool => {
          setDetailsOpen(bool);
          setDefaultOpen(bool)
        }}
        setAttendOpen={setAttendOpen}
        reportState={[openReport, setOpenReport]}
        currentGroupData={currentGroupData}
        setCurrentGroupId={setCurrentGroupId}
        preview={true}
        itemPath={getItemPath(isDetailsOpen.id)}
      />)}
    </div>
  );
}

export default inject('mediaModel')(observer(VideosGroups));
