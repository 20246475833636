import { Link } from 'react-router-dom';
// import { toJS } from 'mobx'
import { useHistory } from "react-router-dom";
import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import Button from '../../components/Button';
import { inject, observer } from 'mobx-react';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '../../res/iconComp/CloseIcon';

import RightBlock from '../Dashboard/Right';
import MainPage from '../MainPage';

import { returnName } from '../../units/func';

import './notifications.scss';
import {
  gtMasterMind,
  gtRecurring, gtRecurringWebinar,
  gtSupport,
  gtWebinar,
  statusCanceled,
  statusEnded
} from "../../const/general";
import {collaborations, home, livestreams, myOrganizationPage, records, workshops} from "../../const/urlKeys";
// import { classNames } from 'classnames';

const renderButton = (group_type, group_id) => {
  const link = `/group?id=${group_id}`;

  switch (group_type) {
    case 'meeting_started':
    case 'ten_minutes_to_start_meeting':
      return (
        <Link to={link}>
          <Button
            label='Join to discussion'
            yellowGradient
            style={{ padding: "8px 10px" }}
          />
        </Link>
      );

    case 'new_groups':
    case 'invite_to_a_meeting':
    case 'start_time_changed':
    case 'invite_to_a_webinar':
      return (
        <Link to={link}>
          <Button
            label='Attend'
            violetGradien
            style={{ padding: "8px 10px" }}
          />
        </Link>
      );
  
    default:
      return null;
  }
}

const Notifications = props => {
  const {
    notificationsModel: { getNotifications, notifications, deleteNotification, unreadCount },
    // mmContextModel: { filteredMMCotextGroups },
    // match,
    groupsModel
  } = props;
  const history = useHistory();
  const [moreNotifications, setMoreNotifications] = useState(10);

  useEffect(() => {
    if (unreadCount) {
      getNotifications(moreNotifications);
    }
  }, [notifications]);

  useEffect(() => {
    getNotifications(moreNotifications);
  }, [moreNotifications]);

  const currentNotifications = notifications?.notifications || [];
  
  const handleDeleteNotification = (e, id) => {
    e.stopPropagation()
    deleteNotification(id)
  }

  const renderType = (type, item) => {
    switch (type) {
      case 'meeting_started':
        return 'Meeting Started'
      case 'new_groups':
        return 'New group' 
      case 'invite_to_a_meeting':
        return 'Invite to meetings'
      case 'removed_from_group':
        return <span style={{ color: 'red' }}>Removed from group</span>
      case 'ten_minutes_to_start_meeting':
        return 'Ten minutes to start meeting'
      case 'group_cancelled':
        return <span style={{ color: 'red' }}>Group cancelled</span>
      case 'start_time_changed':
        return 'Start time changed'
      case 'invite_to_a_webinar':
        return 'Invite to a livestream'
      case 'invite_to_a_channel':
        return 'Invite to a channel'
      case 'post_commented':
        return 'Post commented'
      case 'post_liked':
        return 'Post liked'
      case 'meeting_cancelled' :
      case 'meeting_resheduled' :
      case 'community_access_request':
        return item.title
      case 'subscribed_to_package':
        return item.title
      case 'unsubscribed_from_package':
        return item.title
      default:
        break;
    }
  }
  const getPath = group =>{
    if (group?.notification_type === "community_access_request"){
      return myOrganizationPage
    }
    let path;
    switch (group?.entity?.group_type) {
      case gtMasterMind:
      case gtRecurring:
        path = workshops;
        break;
      case gtWebinar:
      case gtRecurringWebinar:
        path = (group?.entity?.status === statusEnded || group?.entity?.status === statusCanceled) && group?.entity?.any_records ? records : livestreams
        break;
      case gtSupport:
        path = collaborations;
        break;
      default:
        path = home
    }

    let groupId;
    switch (group?.notification_type) {
      case 'meeting_cancelled' :
      case 'meeting_resheduled' :
        groupId = group.entity.group_id
        break;
      default:
        groupId = group.entity_id
    }
    return path && `${path}?id=${groupId}`
  }

  const redirectToGroup = (group) => {
    let path = getPath(group);

    if ((groupsModel.lastGroup === null || groupsModel.lastGroup?.id !== group.entity_id) && path !== '/my_organization') {
      groupsModel.getSingleGroup(group.entity.group_id || group.entity_id)
    }
    path && history.push(`${path}`)
  }
  const currentNotificationsFiltered = currentNotifications.filter(item => item.entity.group_type !== "support")
  return (
    <MainPage>
      <div className="dashboardBlock">
        <div className="dailyBlock" style={{ minHeight: window.innerHeight*0.8 }}>
          <div className="titleBlock workshops lsrecordsList">
            <h3>Notifications</h3>
          </div>
          <div className="notifications">
            {currentNotificationsFiltered.length > 0 ?
              currentNotificationsFiltered.map((item, i,arr) => (
                <Fragment key={item.id}>
                  <div
                    className="notificationItem"
                    style={{ cursor: 'pointer', display: 'flex' }}
                    onClick={() => !(item.notification_type === 'subscribed_to_package' || item.notification_type === 'unsubscribed_from_package') && redirectToGroup(item)}
                    key={item.id}
                  >
                    {item?.entity?.image?.url && <img className="preview" src={item?.entity?.image?.url} alt="preview" />}
                    <div className="content">

                      <div className="type">{renderType(item?.notification_type, item)}</div>
                      <div className="title">{item?.entity?.name}</div>
                      {item.entity?.reason && <div className={'reason'}>Reason: {item.entity?.reason}</div>}
                      <div className="owner">by {returnName(item.owner)}</div>
                      <div className="time">{moment(item?.created_at).format('dddd [at] h:mm a')}</div>
                      {/* <div className="actions">
                        {renderButton(item?.notification_type, item?.entity_id)}
                        &nbsp;
                        &nbsp;
                        <Button
                          greyGradient
                          label='Delete'
                          style={{ padding: "8px 10px" }}
                          onClick={(e) => handleDeleteNotification(e, item?.id)}
                        />
                      </div> */}
                    </div>
                    <div className="closeIcon" onClick={(e) => handleDeleteNotification(e, item?.id)}>
                      <CloseIcon color={"#E9E9F0"} />
                    </div>
                  </div>
                  {
                    i !== arr.length -1 &&
                    <div className="blockDivider"><Divider /></div>
                  }
                </Fragment>
              )) : <div className={"notifications_empty"}>There are no notifications yet</div>}

              {moreNotifications <  notifications?.count && (
                <div className="moreButtonBlock">
                  <Button
                    label="Show More"
                    big60 grey width100
                    className="moreButton"
                    onClick={() => setMoreNotifications(moreNotifications + 10)}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
      {/*<RightBlock />*/}
      {/* <Chat /> */}
    </MainPage>
  )
}

export default inject('notificationsModel', 'groupsModel')(observer(Notifications));
