import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Redirect } from "react-router-dom";

import { home, profile } from '../../const/urlKeys';

import './twitter.scss';

const Twitter = props => {
  const { authModel: { twitter, session_token }, location: { search } } = props;

  useEffect(() => {
    if(!session_token) {
      twitter(search);
    } else {
      window.close();
    }
  });

  if (session_token && session_token !== 'undefined' ) {
    const pathname = localStorage.getItem('pathname') || null;

    if (pathname && pathname !== profile) {
      return <Redirect to={pathname} />
    }

    return <Redirect to={home} />
  }

  return (
    <TwitterIcon className="twitterIcon" />
  )
} 

export default inject('authModel')(observer(Twitter));
