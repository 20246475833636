import React, { memo, useState } from 'react';
import Files from "react-butterfiles";
import ImgBuilder from '../../res/builders/ImgBuilder';
import CloudIcon from '../../res/iconComp/CloudIcon';

import './style.scss';

const UploadImage = props => {
  const { 
    image, 
    setImage, 
    image_url = null,
    title = "Upload Cover Image",
    color = "#43425D",
    disabled = false
  } = props;

  const [errors, setErrors] = useState([]);
  const [isDragging, setDragging] = useState(false);

  const emptyFiles =
    <div className="upload_image_content">
      <CloudIcon color = {color} />
      <div className="text" style = {{color: color}}>{title}</div>
    </div>

  const uploadedFile =
    <div className={`upload_image_content_succes ${isDragging ? "hover" : ""}`}>
      {!disabled && <div className="edit_pencil">
        <img className="pencil" src={ImgBuilder.pencil} alt="pencil"/>
      </div>}
      <img className="loaded_image" src={image?.src?.base64 || image_url} alt={image?.name} />
    </div>

  return (
    <div className="upload_image">
      {!image && <div className="requiredNotification imageInput">required</div>}
      <Files maxSize="30mb" accept={["image/jpg", "image/png", "image/jpeg"]}
        multiple={false}
        convertToBase64
        onSuccess={files => setImage(files[0])}
        onError={errors => setErrors(errors)}
      >
        {({ browseFiles, getDropZoneProps }) => {
          return (
            <div>
              <div 
                style = {{borderColor: color}}
                onClick={e => {!disabled && browseFiles(e)}}
                {...getDropZoneProps({
                  className: "drop_zone" + (isDragging ? " dragging" : "") + (!image ?  ' hover' : ""),
                  onDragEnter: () => setDragging(true),
                  onDragLeave: () => setDragging(false),
                  onDrop: () => setDragging(false)
                })}
              >
                {image ? uploadedFile : emptyFiles}
              </div>
            </div>
          );
        }}
      </Files>
    </div>
  )
}

export default memo(UploadImage);
