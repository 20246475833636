import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import SubscriptionsIcon from '../../../res/iconComp/SubsctiptionsIcon';
import ProfileIcon from '../../../res/iconComp/ProfileIcon';
import URLValetIcon from '../../../res/img/icons/valetIcon.png';
import URLValetIconActive from '../../../res/img/icons/valetIcon-active.png';
// import SubscribtionsIcon from '../../../res/img/icons/subscribtions.png';

// import { mmRole } from '../../../const/general';

import '../profile.scss';
import {
  myOrganization,
  MyProfile,
  mySubscriptions,
  paymentPortal,
  userRole,
} from '../../../const/general';
import MyOrganizationIcon from '../../../res/iconComp/MyOrganizationIcon';

const RightProfile = (props) => {
  const {
    authModel: { logout },
    userModel: { user },
    communityModel: { myOrganizationID },
    onClick,
    viewProfile, // userModel: { user }
  } = props;
  return (
    <div>
      <div className="rightBlockProfile">
        <List className="rightActionList">
          <ListItem
            button
            key="profileLink"
            onClick={() => onClick(MyProfile)}
            className={classNames({ active: viewProfile === MyProfile })}
          >
            <ListItemIcon>
              <ProfileIcon
                color={viewProfile === MyProfile ? '#43425D' : '#A8A7BC'}
              />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItem>
          <ListItem
            button
            key="paymentLink"
            onClick={() => onClick(paymentPortal)}
            className={classNames({ active: viewProfile === paymentPortal })}
          >
            <ListItemIcon>
              {viewProfile === paymentPortal ? (
                <img src={URLValetIconActive} alt="payment icon" />
              ) : (
                <img src={URLValetIcon} alt="payment icon" />
              )}
            </ListItemIcon>
            <ListItemText primary="Payment Portal" />
          </ListItem>
          {user?.role && user.role !== userRole && (
            <ListItem
              button
              key="subscribtionsLink"
              onClick={() => onClick(mySubscriptions)}
              className={classNames({
                active: viewProfile === mySubscriptions,
              })}
            >
              <ListItemIcon>
                <SubscriptionsIcon
                  color={
                    viewProfile === mySubscriptions ? '#43425D' : '#A8A7BC'
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Subscriptions" />
            </ListItem>
          )}
          {user?.role !== userRole && myOrganizationID && (

            <ListItem
              button
              key="subscribtionsLink"
              onClick={() => onClick(myOrganization)}
              className={classNames({ active: viewProfile === myOrganization })}
            >
              <ListItemIcon>
                <MyOrganizationIcon
                  color={viewProfile === myOrganization ? '#43425D' : '#A8A7BC'}
                />
              </ListItemIcon>
              <ListItemText primary="My Organization" />
            </ListItem>
          )}
        </List>
        <Divider />
        <List className="rightActionList rightActionLogout">
          <ListItem button key="profileLink" onClick={() => logout()}>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default inject(
  'authModel',
  'userModel',
  'communityModel',
)(observer(RightProfile));
