import React from 'react';

const ArrowBackIcon = props => {
  const { color = "white" } = props;

  return (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.55761 0.319256L0.306389 7.28097C-0.10213 7.6726 -0.10213 8.32639 0.306389 8.71963L7.55761 15.6797C8.00231 16.1068 8.70435 16.1068 9.14906 15.6797C9.6195 15.2278 9.6195 14.4751 9.14906 14.0223L3.07596 8.1921C2.96579 8.08675 2.96579 7.91225 3.07596 7.8069L9.14906 1.97665C9.6195 1.52471 9.6195 0.772005 9.14906 0.319256C8.92631 0.106955 8.64002 0 8.35373 0C8.06665 0 7.78036 0.106955 7.55761 0.319256Z" fill="#7E7D8F"/>
    </svg>
  )
}

export default ArrowBackIcon;
