import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import './styles.scss';
import classNames from "classnames";
import CloseIcon from "../../../res/iconComp/CloseIcon";
import IconButton from "@material-ui/core/IconButton";

const CustomModal = (props) =>{
  function getModalStyle() {
    return {
      borderRadius: '5px',
      overflowY: "scroll",
      maxHeight:'96%'
    };
  }

  const modalWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      position: 'relative',
      width: props.customWidth || 490,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
    },
    close: {
      right: '10px',
      top: '10px',
      position: 'absolute',
      background: 'transparent',
      border: 'none',
    },
    custom: {
      width: '500px'
    }
  }));

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const { handleClose, isOpen, children } = props;
  const onClose = e => {
    e.stopPropagation()
    handleClose()
  }

  return(
    <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={onClose}
        style={modalWrapperStyle}
    >
      <div style={modalStyle} className={classes.paper}>
        <IconButton aria-label="close" onClick={onClose} className={classNames("closeModalIcon", 'roundIcon')}>
          <CloseIcon color={props.closeColor}/>
        </IconButton>
        {children}
      </div>
    </Modal>
  )
}

export default CustomModal
