import React from "react";
import Dialog from "../Dialog";
import {inject, observer} from "mobx-react";
import './styles.scss';

const WebJoinLiveStreamDialog = (props) => {
  const {groupsModel:{
    openJoinLSPopup, setOpenJoinLSPopup
  }} = props;
  return (
    <div className="join_ls_popup_wrapper">
      <Dialog
        className="join_ls"
        closeColor="#43425D"
        defaultOpen={openJoinLSPopup}
        onClose={() => {
          setOpenJoinLSPopup(false)
        }}
      >
        <div className="join_ls_content">
          <div className="content">
            <h1>Join Livestream</h1>
            <span>Currently, you can only join a live stream via the mobile app</span>
          </div>
        </div>

      </Dialog>
    </div>
  )
}

export default inject('groupsModel')(observer(WebJoinLiveStreamDialog));