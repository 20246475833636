import React, { useState, memo } from 'react';
import Logo from '../../res/img/logo_logined.png';
import LinkStores from '../LinkStores/linkStores';
import './Footer.scss';
import Policy from './Policy';

import { terms_conditions, privacy_policy } from '../../const/urlKeys';

const Footer = () => {
  const [showPolicy, setShowPolicy] = useState(!localStorage.getItem('policy'));

  const setAcceptPolicy = () => {
    localStorage.setItem('policy', 'accept');
    setShowPolicy(false);
  }

  const goTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return(
    <div className="footer">
      {showPolicy && <Policy setAccept={setAcceptPolicy} />}
      <img className="logo" alt="logo" src={Logo} onClick = {goTop} />
      <div className="links">
        <a className="item" href={terms_conditions}>Terms & Conditions</a>
        <a className="item" href={privacy_policy}>Privacy Policy</a>
        <a className="item" href="mailto:support@ascend2u.com">Contact Support</a>
      </div>

      <LinkStores name="footer" />
    </div>
  )
};

export default memo(Footer);
