import React, { memo } from 'react';
import classNames from 'classnames';
import SimplePage from '../SimplePage';
import MainMenu from './Components/MainMenu';
import MessagesGlobal from '../../components/MessagesGlobal';

import './page.scss';

const MainPage = props => {
  const { children , hideMenu , className } = props;

  return (
    <SimplePage className={classNames('mainPage', className)}>
      {!hideMenu &&  <MainMenu />}
      <main className="contentCenter">
        {children}
      </main>
      <MessagesGlobal />
    </SimplePage>
  )
};

export default memo(MainPage);
