import React from "react";
import CurrentAvatar from "../CurrentAvatar";
import {returnName} from "../../units/func";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";

const ModeratorItem = (props) => {
  const { user, removeModerator = () =>{} } = props
  const history = useHistory()

  return (
    <div className={'moderatorItem'}>
      <div className="moderatorItem_avatar">
        <CurrentAvatar
          alt={returnName(user)}
          src={user?.image?.url}
          className='moderatorAvatar'
        />
      </div>
      <div className="moderatorItem_content">
        <Link className = "moderatorItem_profile_name" to={`/user/${user?.id}`}>{returnName(user)}</Link>
        <div className="moderatorItem_btnWrapper">
          <div 
            className="moderatorItem_remove"
            onClick={() => removeModerator(user)}
          >
            Remove from moderators
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModeratorItem