import React, {useEffect, useState} from 'react';
import Divider from '@material-ui/core/Divider';
import { inject, observer } from 'mobx-react';
import CustomSneckbar from '../../components/CustomSnackbar';
import CardList from '../PaymentMethods/Cards';
import AddPaymentMethod from '../AddPaymentMethod'
import Button from '../Button';

import './buyVideo.scss';

const BuyVideoContent = props => {
  const {
    close,
    cardsModel: { defaultCard, cards },
    record,
    recordPrice,
    groupID,
    groupsModel: { resualtMessage, changeResultMessage },
    meetingsModel: { buyRecord, setLastData },
    supportModel: {getStripeFeeForGroup, commissions: {group_fee: AscendFee}}
  } = props;
  const [cardId, setCardId] = useState(defaultCard?.id || (cards?.count && cards?.cards[0].id) || null);

  useEffect(() => {
    !cardId && setCardId(defaultCard?.id || (cards?.count && cards?.cards[0].id) || null)
  },[cards, defaultCard, cards?.count])

  let totalPrice = recordPrice / 100;
  const stripeFee = getStripeFeeForGroup(recordPrice);
  return (
    <div className="buy_video_content">
      <div className="title">Buy Video</div>

      <div className="buy_video_content_price_title">
        <div className="buy_video_content_price_name">Title:<b> {record.theme}</b></div>
        <div className="buy_video_content_price_amount"><b>$ {(recordPrice / 100).toFixed(2)}</b></div>
      </div>
      <div className="select_payment_method">
        <div className="payment_method">
          Select Payment Method
          </div>
        <div className="card_list">
          <Divider />
          {cardId && <CardList onClick={setCardId} cardId={cardId} />}
        </div>

        <div className="add_payment_method">
          <Divider />
          <AddPaymentMethod  title = "Add Payment Method"/>
          <Divider />
        </div>
      </div>

      <div className="buy_record_pricing">
        <div className="buy_record_pricing_amount">
          <div>Amount</div>
          <div> $ <b>{(recordPrice / 100).toFixed(2)}</b> </div>
        </div>
        <div className="buy_record_pricing_stripe">
          <div>Stripe Fee</div>
          <div className="opacity">$ {(stripeFee / 100).toFixed(2)}</div>
        </div>
        <div className="buy_record_pricing_service">
          <div>Service Fee</div>
          <div className="opacity">$ {(totalPrice * AscendFee).toFixed(2)}</div>
        </div>
      </div>
      <div className="buy_record_total">
        <div className="">Total:</div>
        <div>$ {((totalPrice * (1 + AscendFee)) + stripeFee / 100).toFixed(2)}</div>
      </div>

      <div className="buy_video_buttons">
        <Button label="Cancel" className="buy_video_cancel" onClick={close} />
        <Button
          label="Buy"
          className="buy_video_accept"
          onClick={() => {
            buyRecord(record.id, cardId, 'card', null);
            setLastData(groupID, record.id);
          }}
          disabled={!cardId}
        />
      </div>
      <CustomSneckbar
        message={resualtMessage}
        onClose={() => {
          changeResultMessage('');
          if (resualtMessage === 'Success') { close(); }
        }}
        severity={resualtMessage === 'Success' ? "success" : 'error'}
      />

    </div>

  );
}

export default inject('cardsModel', 'groupsModel', 'meetingsModel', 'supportModel')(observer(BuyVideoContent));
