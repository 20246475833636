import React from 'react';
// import { inject, observer } from 'mobx-react';
import LinkLinkIcon from '../../../res/iconComp/linlLink';

import './Bio.scss';
const Bio = ({ bio, insta = false }) => {
  return (
    <div className="mm_bio">
      <div className="bio_text">
        {bio}
      </div>
      {insta && <div className="linkedin">
        <LinkLinkIcon /><span>My Instagram</span>
      </div>}
    </div>
  )
}

export default Bio; //inject("masterMindsModel")(observer(Bio));
