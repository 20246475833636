import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Dialog from '../../../components/Dialog';
import Button from '../../../components/Button';

import Refund from './Refund';
import MessageSebscription from './MessageSubscription';
import { getMeettingsName } from '../../../units/modelsFuncs';
import {observer, inject} from "mobx-react";

const PaymentItem = props => {
  const { item, onActive, className, onClick = () => {}, supportModel } = props;
  const { commissions: { group_fee: AscendFee } } = supportModel
  const format = 'DD MMM YYYY h:mm a';

  const paymentDialog = item => {
    switch (true) {
      case item.active_to && item.status === "active":
        return <Dialog 
          openButton={<Button 
            label="Unsubscribe" // Cancel Subscription
            className="refundButton"
            disabled={item.status !== 'active'}
          />}
        >
          <MessageSebscription onSuccess={() => onActive(item.id)} />
        </Dialog>;

      case item.allow_refund && item.amount > 0:
        return <Dialog 
          openButton={<Button label="Request a refund" className="refundButton" />}
          className="refundBlock"
        >
          <Refund group_id={item.group_id} />
        </Dialog>

      case !item.active_to && item.status === "cancelled" && item.amount > 0:
        return <Button label="Request a refund" className="refundButton" disabled={true} />
    
      default:
        return null;
    }
  }

  const duration = () => {
    const activeTo = moment(item.active_to);
    const createdAt = moment(item.created_at);
    const duration = activeTo.diff(createdAt, 'days') + 1;
    return duration
  }

  const buildTitle = () => {
    const meetingName = getMeettingsName(item.group_id, item.meeting_id);
    if (item.group_name) {
      if (item.meeting_id) {
        return `${item.group_name}` 
      }
      return item.group_name
    }
    if (item.price) {
      return 'Monthly Subscription'
    }
    return 'Trial Subscription'
  }

  return (
    <div className={classNames("paymentItem", className)}>
      <div className="paymentData" onClick={() => onClick()} >
        <div className="paymentTitle">{buildTitle()}</div>
        <div className="paymentSubTitle">
          {moment(item.created_at).format(format)} 
          {item.payment_source && `| ${item.payment_source.brand}-${item.payment_source.last4}`}
        </div>
      </div>
      <div className="paymentFooter" onClick={() => onClick()} >
        <span className="paymentText">
          {item.group_name ? `Participation fee` : (item.price ? 'Monthly Subscription' : `${duration()}-days Subscription`)}
        </span>
        <span className="paymentPrice">
          {item.amount !== undefined ? 
          (item.amount ? `$${((item.amount*(1 + AscendFee) + supportModel.getStripeFeeForGroup(item.amount))/100).toFixed(2)}` : 'FREE') :
          (item.price ? `$${(item.price/100).toFixed(2)}` : 'FREE')}
        </span>
      </div>
      {paymentDialog(item)}
    </div>
  )
}

export default inject('supportModel')(observer(PaymentItem));
