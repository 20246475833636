import React, {useEffect, useState, useRef} from 'react';
import LSRGroup from '../../components/LsRecordGroup';
// import Dialog from '../../components/Dialog';

import {actionPay, added} from '../../const/general';

import './LSRGroup.scss';
import ContentDetailsPopup from "../../components/ContentDetailsPopup";
import Dialog from "../../components/Dialog";
import AttendGroup from "../AttendGroup";
import {useHistory} from "react-router";
import {inject, observer} from "mobx-react";
import LoadingBlock from "../../components/LoadingBlock";

const LivestreamsRecords = props => {
  const {
    tab, 
    data, 
    restore, 
    viewBlock, 
    lastGroup, 
    match, 
    setCurrentGroupId, 
    currentGroupData, 
    groupsModel, 
    isLoading,
    masterMindsModel: {
      masterMindProfile
    }
  } = props; // clearSimpleId

  const [isAttendOpen, setAttendOpen] = useState(false);
  const [isDetailsOpen, setDetailsOpen] = useState(false)
  const [openReport, setOpenReport] = useState(false);

  const history = useHistory()
  const query =  new URLSearchParams(window.location.search);
  const [defaultOpen, setDefaultOpen] = useState((match && match.params && match?.params?.id) || query?.get('id') || null)
  const [postWidth, setPostWidth] = useState("auto")

  const postListRef = useRef(null)

  useEffect(() => {
    if(postListRef?.current?.children && postListRef.current.children.length){
      postListRef.current.children[0].clientWidth &&
      postListRef.current.children[0].clientWidth !== 250 && 
      setPostWidth(postListRef.current.children[0].clientWidth)
    }
  }, [postListRef, data])

  useEffect(()=>{
    setPostWidth("auto")
  },[])


  const  getItemPath = id => {
    return match?.params && !Object.keys(match?.params).length && {
      forSet: `${match.url}?id=${id}`,
      forBack: match.url
    }
  }

  if (defaultOpen && data.length && data.findIndex(group => group?.id == defaultOpen) === -1){
    const group = groupsModel.extendFilterByGroup(defaultOpen)
    if (group){
      setDefaultOpen(false)
      setDetailsOpen(group)
    }
  }

  const attendDialog = (item) => {
    return <Dialog
      openButton={null}
      className="detailGroup"
      closeColor="#43425D"
      onClose={_ => {
        setAttendOpen(false)
      }}
      defaultOpen={isAttendOpen}
    >
      <AttendGroup item={item}/>
    </Dialog>
  };

  useEffect(_ => {
    if (isDetailsOpen){
      const path = getItemPath(isDetailsOpen.id)
      path && query?.get('action') !== actionPay &&  history.push(path.forSet)
    }
  }, [isDetailsOpen])

  // let currentData = lastGroup ? [...data, lastGroup] : data;
  let currentData = data;
  if (lastGroup && data.findIndex(group => group?.id == lastGroup?.id) == -1) {
    currentData = [ ...data, lastGroup];
  }

  if (viewBlock) {
    currentData = data?.filter(item => {
      if (viewBlock === added) {
        return !item.is_owner
      } 
      return item.is_owner
    });
  }
  
  return (
    <div className={`listBlock ${!!currentData?.length ? "" : "emptyListBlock"}`} ref = {postListRef}>
      {currentData?.length > 0 ?
        <>
          {currentData.map(item => {
            return (
              <div className="groupLsRecords" key={item.id} style = {{maxWidth: `${postWidth < 451 ? (postWidth + "px") : "450px"}`}}>
                <LSRGroup
                  id={match?.params?.id}
                  item={item}
                  tab={tab}
                  is_owner = {item.is_owner}
                  restore={restore}
                  handleOpenDialog={setDetailsOpen}
                  showReport={_ => {
                    setDetailsOpen(item)
                    setOpenReport(true)
                  }}
                  defaultOpen={defaultOpen}
                  postWidth = {postWidth}
                />
              </div>
            )
          })}
          {isAttendOpen && (attendDialog(isAttendOpen))}
        </>
        : isLoading ?
          <LoadingBlock/>
          :
          <div className="emptyBlock">
            <div className="empty">
              <div className="title">Currently no livestream records are available</div>
            </div>
          </div>
      }
      {isDetailsOpen && (<ContentDetailsPopup
        item={isDetailsOpen}
        tab={tab}
        handleClose={ bool => {
          setDetailsOpen(bool);
          setDefaultOpen(bool)
        }}
        setAttendOpen={setAttendOpen}
        reportState={[openReport, setOpenReport]}
        currentGroupData={currentGroupData}
        setCurrentGroupId={setCurrentGroupId}
        itemPath={getItemPath(isDetailsOpen.id)}
      />)}
      {/* {!!lastGroup && <Dialog
        // openButton={renderData}
        className="detailGroup"
        closeColor="#43425D"
        defaultOpen={!!lastGroup}
        onClose={() => clearSimpleId()}
      >
        <LSRGroup id={match?.params?.id} tab={tab} item={lastGroup} restore={restore} defaultHide={true} />
      </Dialog>} */}
    </div>
  );
}

export default inject('groupsModel', 'masterMindsModel')(observer(LivestreamsRecords));
