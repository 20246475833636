import React from "react";
import './styles.scss'
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingBlock = () => {
  return (
    <div className='loadingWrapper'>
      <CircularProgress/>
    </div>
  )

}

export default LoadingBlock