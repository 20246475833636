import {observable, decorate, action } from 'mobx';
import axios from 'axios';

import { baseAPI } from '../const/settings';

import { authModel } from './auth';
import { userModel } from './user';
import { messagesModel } from './messages';
import { fileUploadingModel } from './fileUploading';
import { groupsModel } from './groups';
import {searchModel} from "./search";
import {communityModel} from "./community";
import {checkCommunityIncludeInFilters} from "../units/func";
// import { repeatOnErrorReq } from '../units/func';

const getAllCommunitiesForReqeust = () => {
  if (communityModel.communityList.selected.includes(userModel.user.community_id)){
    return communityModel.communityList.selected.join(',')
  }
  const all = communityModel.communityList.selected.concat([userModel.user.community_id])
  return all.join(',')
}
export class MediaModel {
  constructor() {
    this.mediaGroups = []
    this.mediaGroupsIsLoading = false;
    this.mediaGroupsFirstLoading = true;
    this.uploadProgress = 0;
    this.loading = false;
    this.createStatus = null;
    this.filteredMediaGroups = [];
    this.selectRecord = null;
  }

  setMediaGroups = data => {
    this.mediaGroups = data;
    this.setMediaGroupsIsLoading(false)
  }
  setMediaGroupsIsLoading = bool => {
    this.mediaGroupsIsLoading = bool;
    this.setMediaGroupsFirstLoading(false)
  }
  setMediaGroupsFirstLoading = bool => this.mediaGroupsFirstLoading = bool;
  updateUploadProgress = progress => this.uploadProgress = progress;
  setLoading = value => this.loading = value;
  setCreateStatus = value => this.createStatus = value;
  setSelectRecord = group => this.selectRecord = group;


  extendFilterByGroup = (id) =>{
    const group = this.mediaGroups.find(group => String(group.id) === String(id))

    if (group){
      group.communities.forEach((c_id) =>  groupsModel.changeFilterCommunity(+c_id))
      return group
    }
    return false
  }


  getCommunitieswithdistGroup = group =>{
    let communitiesId = []
    let iterableCommunities = group?.communities ? group?.communities : [];
    if(communityModel?.distribution_groups?.distribution_groups){
      group?.distribution_group && communityModel.distribution_groups.distribution_groups.map(grp => grp.id === group?.distribution_group.id &&
        grp.communities.map(comm =>
          !communitiesId.includes(comm.id) && communitiesId.push(comm.id) &&
          iterableCommunities.push(comm)
        ))
      let copyGroup = group;
      copyGroup.communities = iterableCommunities;
      return copyGroup;
    }else if(group?.communities){
      return group
    }else{
      let copyGroup = group;
      copyGroup.communities = ["none"]
      return copyGroup
    }
  }

  runMediaCommunityFilter = () => {
    const { filters } = groupsModel.state;
    this.filteredMediaGroups = this.mediaGroups
      // .map(group => ({ ...group, timestamp: new Date()}))
      .filter(group => {
        if(filters.communities && filters.communities.length) {
          let modifiedGroups = this.getCommunitieswithdistGroup(group)
          return checkCommunityIncludeInFilters(modifiedGroups, filters.communities)
        }
        return true
      });
  }

  getMediaGroups = (session_token = null, id) => {
    const setMediaGroups = this.setMediaGroups;
    const setMediaGroupsIsLoading = this.setMediaGroupsIsLoading;
    const runMediaCommunityFilter = this.runMediaCommunityFilter;
    const headers = {
      "headers": {
        "accept": "multipart/form-data, application/json, text/plain, */*",
        "session-token": session_token ? session_token : authModel.session_token,
        'content-type': 'multipart/form-data'
      },
      params: {
        per_page: 10000,
        sort_column: "created_at",
        sort_type: 'desc',
        communities_ids: getAllCommunitiesForReqeust()
      }
    };

    setMediaGroupsIsLoading(true)

    axios.get(
      `${baseAPI}records`,
      headers
    )
      .then(function (response) {
        setMediaGroups(response.data.groups);
        if (id){
          const record = response?.data?.groups?.find(record => {
            return record.id === id
          })
          record && searchModel.updateSingleRecord(record)
        }
        runMediaCommunityFilter();
      })
      .catch(function (error) {
        setMediaGroupsIsLoading(false)
        console.log('error', error)
      })
  };

  getSingleRecord = (id) => {
    const setSelectRecord = this.setSelectRecord;
    const headers = {
      "headers": {
        "accept": "multipart/form-data, application/json, text/plain, */*",
        "session-token": authModel.session_token,
        'content-type': 'multipart/form-data'
      }
    };

    axios.get(`${baseAPI}records/${id}`, headers)
      .then(function (response) {
        setSelectRecord(response.data);
      })
      .catch(function (error) {
        console.log('error', error)
      })
  }

  takeSingleRecord = (id) => {
    const currentRecord = this.mediaGroups.filter(group => group.id == id);
    if (currentRecord?.id) {
      this.setSelectRecord(currentRecord);
    } else {
      this.getSingleRecord(id);
    }
  }

  createVideoRecordGroup = ({ setFieldError, ...data }) => {
    const setLoading = this.setLoading;
    const setCreateStatus = this.setCreateStatus;
    const getMediaGroups = this.getMediaGroups;
    messagesModel.setMessages("Your video is uploading, it will be available after processing");
    messagesModel.setStatus(true);
    messagesModel.setShowMessages(true);
    setLoading(true);
    const headers = {
      "headers": {
        "session-token": authModel.session_token,
        "Access-Control-Allow-Origin": "*",
      }
    };

    let form = new FormData();
    Object.keys(data).map(key => {
      if (key=== 'community_id'){
        const arr = data[key];
        for (let i = 0; i < arr.length; i++) {
          form.append("communities[]", arr[i]);
        }
      }else if(key==='image') {
        form.append(key , data[key].src.file);
        form.append('image_preview_url' , data[key].src.base64);
      }else if(key === 'communities'){
      }else if(key!=='files'){
        form.append(key, data[key]);
      }
    });

    //form.append('communities', data.communities ?  (data.communities) : userModel.community_id);


    const saveRecord = (formData) => {
      axios.post(`${baseAPI}records`, formData, headers).then((response) =>  {
        getMediaGroups();
        messagesModel.setMessages(`Your video ${response?.data?.name} uploaded`);
        messagesModel.setStatus(true);
        messagesModel.setShowMessages(true);
      }).catch((error) =>  {
        setCreateStatus('error');
        if (error.response && error.response.data && error.response.data.errors) {
          error.response.data.errors.forEach(element => {
            switch (true) {
              case element.includes('price'):
                setFieldError('price', `The price of the meeting records can’t be less than $${data.files.length}.`);
                break;
              case element.includes('file'):
                setFieldError('files', "Something went wrong. The video size can't be more than 10 Gb in a .mp4, .mov format");
                break;

              default:
                setFieldError('files', element);
                messagesModel.setMessages("Your video didn't upload");
                messagesModel.setStatus(false);
                messagesModel.setShowMessages(true);
                break;
            }
          });
        }
      }).finally(() => setLoading(false))
    }
    fileUploadingModel.handleFilesUpload(data.files, form, saveRecord);
  }

  updateVideoRecordGroup = ({ setFieldError, id, ...data }) => {
    const setCreateStatus = this.setCreateStatus;
    const getMediaGroups = this.getMediaGroups;

    const headers = {
      "headers": {
        "session-token": authModel.session_token,
        "Access-Control-Allow-Origin": "*",
      }
    };

    let form = new FormData();
    Object.keys(data).map(key => {
      console.log("key", key)
      if(key ==='files'){
        let files = data[key]
        files.forEach( (file, index) => {
          Object.keys(file).forEach( fileFild => {
            form.append(`${key}[${index}][${fileFild}]`, file[fileFild]);
          })
        })
      }
      else if (key=== 'community_id'){
        const arr = data[key];
        for (let i = 0; i < arr.length; i++) {
          form.append("communities[]", arr[i]);
        }
      }
      else {
        form.append(key, data[key]);
      }
    });

    axios.put( `${baseAPI}records/${id}`, form, headers)
      .then((response) => {
        getMediaGroups();
        messagesModel.setMessages(`Your video ${response?.data?.name} updated`);
        messagesModel.setStatus(true);
        messagesModel.setShowMessages(true);
      }).catch((error) => {
      setCreateStatus('error');
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.forEach(element => {
          setFieldError('files', element);
          messagesModel.setMessages("Your video didn't updated");
          messagesModel.setStatus(false);
          messagesModel.setShowMessages(true);
        });
      }
    })


  }
}

decorate(MediaModel, {
  mediaGroups: observable,
  getMediaGroups: action,
  uploadProgress: observable,
  createStatus: observable,
  loading: observable,
  filteredMediaGroups: observable,
  selectRecord: observable,
  mediaGroupsIsLoading: observable,
  mediaGroupsFirstLoading: observable,
})

export const mediaModel = new MediaModel();
