import React from 'react';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer'
import CircularProgress from '@material-ui/core/CircularProgress';

import MyDoc from './MyPDF';
import MyEarnings from './MyErnings';

import DownloadIcon from '../../../res/iconComp/DownloadIcon';

import './earnings.scss';
import {PAYMENT_TYPE} from "../../../const/general";
import {observer, inject} from "mobx-react";

const TransactionDetails = props => {
  const { item, groupError, showMettingsLine = true, supportModel } = props;
  const {getCommissionByType, getFeeByType} = supportModel;

  if (!item || !item.id) return null;
  let gross = item.group?.price || item.amount;
  const feeCount = getCommissionByType(item.type)
  const stripeFee = item.amount? getFeeByType(item.type, item.amount) : 0;


  let fee = item.group?.price ? item.group.price - item.amount : gross * feeCount;
  const feeTitle = item.type === PAYMENT_TYPE ? 'Service fee' : 'Technology Fee';
  let total = (item.amount/100).toFixed(2);

  if (item.fee) {
    fee = item.fee;
    gross = item.group?.price || item.amount;
    total = ((stripeFee + gross + (fee || 0)) / 100).toFixed(2);
  } else if (item.hasOwnProperty('source_type')) {
    gross = item.source?.price || item.amount;
    fee = gross - item.amount
  }

  let amount = <span>FREE</span>;
  if (item.amount) {
    amount = <>$ <span>{(gross / 100).toFixed(2)}</span></>
  }

  const buildTitle = () => {
    if (item.group || item.source) {
      const data = item.group || item.source
      if (item.meeting_id) {
        return `${data.name} - ${data.meetings_records.length > 0 &&
        data.meetings_records.filter(meeting => meeting.id === item.meeting_id)[0]?.theme}`
      }
      return data.name
    }
    return <CircularProgress /> 
  }

  return (
    <div className="TDBlock" key={item.id}>
      <div className="TDTitle">Transaction Details</div>
      <div className="TDDetails">
        <div className="TDDownload">
          {groupError && 'You cannot create an invoice because the group has been deleted'}
          {!groupError && buildTitle()}
          {!groupError && (item.group || item.source) && (item.type === PAYMENT_TYPE ?
            <PDFDownloadLink document={<MyDoc item={item} />} fileName="payment.pdf">
              {({ blob, url, loading, error }) => (
                loading
                  ? <div className="TDDButton"><DownloadIcon /><span>Loading document...</span></div>
                  : <div className="TDDButton"><DownloadIcon /><span>Download</span></div>
              )}
            </PDFDownloadLink> :
            <PDFDownloadLink
              document={<MyEarnings item={item} showMettingsLine={showMettingsLine} />}
              fileName="earning.pdf"
            >
              {({ blob, url, loading, error }) => (
                loading
                  ? <div className="TDDButton"><DownloadIcon /><span>Loading document...</span></div>
                  : <div className="TDDButton"><DownloadIcon /><span>Download</span></div>
              )}
            </PDFDownloadLink>)
          }
        </div>
        <Divider />
        <div className="TDAmount">
          Amount {amount}
        </div>
        <Divider />
        {item.type !== 'PAYMENT' && <>
          <div className="TDDepositDate">
            Deposit Date <span>{moment(item.created_at).format('MM/DD/YY')}</span>
          </div>
          <Divider />
        </>}
        {item.payout_destination && <><div className="TDDestination">
          Destination <span>{item.payout_destination.brand}-{item.payout_destination.last4}</span>
        </div>
          <Divider /></>}
        <div className="TDOtherData">
          <div className="TDOtheItem">
            Type <span>{item.type ? item.type : 'PAYOUT'}</span>
          </div>
          {/* <div className="TDOtheItem">
            Gross <span>$ {(gross / 100).toFixed(2)}</span>
          </div> */}

          {item.amount && item.type === 'PAYMENT' ? <div className="TDOtheItem">
            Stripe Fee <span>$ {(stripeFee / 100).toFixed(2)}</span>
          </div> : null
          }

          <div className="TDOtheItem">
            {feeTitle} <span>$ {(fee / 100).toFixed(2)}</span>
          </div>
        </div>
      </div>

      <div className="TDNetTotal">
        Net Total $ <span>{total}</span>
      </div>
    </div>
  );
}

export default inject('supportModel')(observer(TransactionDetails));
