import React from 'react';
import { inject, observer } from 'mobx-react';

import ForgotForm from './Components/forgotForm';
import ResetForm from './Components/resetForm';
import Dialog from '../../components/Dialog';
import LoginForm from '../Login/Components/LoginForm';

import CloseRoundIcon from '../../res/iconComp/CloseRound';

import './forgot.scss';

const Forgot = props => {
  const { userModel } = props;
  const { resetData, resetFinish } = userModel;

  if (resetFinish) return(
    <Dialog 
      openButton={<span />}
      defaultOpen
      closeIcon={window.innerWidth < 720 && <CloseRoundIcon />}
    >
      <LoginForm />
    </Dialog>
  )

  if (resetData) return <ResetForm />

  return (
    <ForgotForm />
  )
}

export default inject('userModel')(observer(Forgot));

