import React, { useEffect,useState } from 'react';
import classNames from 'classnames';
import HeadRoom from 'react-headroom'
import { stack as Menu } from 'react-burger-menu'
import { useHistory } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import Button from '../Button';
// import Dialog from '../Dialog';
// import LoginForm from '../../pages/Login/Components/LoginForm';
import LinkImg from '../LinkImg/linkImg';

import Logo from '../../res/img/logo.png';
import CloseRoundIcon from '../../res/iconComp/CloseRound';
import {disableBodyScroll} from '../../units/func';

import { home, register } from '../../const/urlKeys';

import './Header.scss';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden
  }

  .headroom {
    transform: inherit !important;
    z-index: 3 !important;
  }
`

const UnloginHeader = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const isMenuOpen = function(state) {
    disableBodyScroll(state.isOpen);
    setOpen(state.isOpen);
    return state.isOpen;
  };

  useEffect(()=>{
    // enable window scroll by default
    disableBodyScroll(false);
  }, []);
  
  return (
    <div className="sticky">
      <HeadRoom>
        <div className="header">
          <LinkImg alt="logo" src={Logo} url={home} className="logoMain" />
          <div className="buttons">
            <Button
              onClick={() => history.push(register)}
              label="Sign Up"
              className={classNames("buttonSpace", 'registerButton')}
            />
            <Button label="Sign In" violetGradien onClick={() => history.push(home)} />
            {/* <Dialog 
              openButton={<Button label="Sign In" violetGradien />}
              className="login"
              closeIcon={window.innerWidth < 720 && <CloseRoundIcon />}
            >
              <LoginForm />
            </Dialog> */}
          </div> 
          <Menu
            className="burger"
            right={true}
            width={'100%'}
            onStateChange={ isMenuOpen }
            customCrossIcon={<CloseRoundIcon />}
          >
            <Button onClick={() => history.push(register)} label="Sign Up" className="buttonSpace" />
            <Button label="Sign In" violetGradien onClick={() => history.push(home)} />
            {/* <Dialog 
              openButton={<Button label="Sign In" violetGradien />}
              closeIcon={window.innerWidth < 720 && <CloseRoundIcon />}
            >
              <LoginForm />
            </Dialog> */}
            {open && <GlobalStyle />}
          </Menu>
        </div>   
      </HeadRoom>
    </div>
  )
}

export default UnloginHeader;
