import React from 'react';
import classNames from 'classnames';

import './schedule.scss';
import SpecificDate from "./SpecificDate";

const SheduleSelect = props => {
  const {
    title = "Choose Schedule",
      close,
    onChange,
    onSelect,
    value,
    defaultDay = null,
    oneDayMode = false,
    selected = [], 
    id,
    webinars,
      editGroup,
    scheduleItem,
  } = props;

  return (
    <div className={classNames("calendarBlock", "sheduleSelectBlock")}>
      <div className="title">{title}</div>
      <SpecificDate
          oneDayMode={oneDayMode}
          defaultDay={defaultDay}
          close={close}
          selected={selected}
          scheduleItem={scheduleItem}
          editGroup={editGroup}
          onSelect={(e) => {
            onSelect(e);
            onChange(e.date);
          }}
          id={id}
          webinars={webinars}
          value={value}/>
    </div>
  );
}

export default SheduleSelect;
