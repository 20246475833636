import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import GroupOwner from '../../components/EventItem/eventOwner';
import DeleteButton from '../DeleteButton';
import VideoLabel from '../../res/iconComp/VideoLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import CustomVideoPlayer from '../CustomVideoPlayer';
import PlayVideoIcon from '../../res/iconComp/PlayVideoIcon';
import classNames from 'classnames';
import upload_success from '../../res/icons/upload_success.svg';
import bubble_loading from '../../res/icons/bubble_loading.svg';
import warning_ic from '../../res/icons/warning_ic.svg';
import processing_ic from '../../res/icons/processing_ic.svg';


import moment from 'moment';

import "./style.scss";



const ContentUploadingModalContent = props => {
  const {
    fileUploadingModel: {
      formData,
      filesToUpload,
      info,
      abortUploading
    },
    userModel : { user }

  } = props;

  const recordTime = (minutes) => {
    return moment.utc().startOf('day').add({minutes}).format('HH:mm');
  }

  const playVideo = (item) => {
    return (
      <CustomVideoPlayer url={item.url} fileType={item?.file_data?.file?.type} record_preview={formData.get('image_preview_url')}>
         <div className="uploading_item_play" >
          {item.status === 'uploaded' && <PlayVideoIcon disable={false} />}
          <div className="uploading_item_play_total_time">{recordTime(item.file_data.meta.duration)}</div>
        </div>
      </CustomVideoPlayer>
    )
  }
  const renderPlay = (item, key) => (
    <div className={classNames("uploading_item_data")}>
      <div className="uploading_item_info">
        <div className="uploading_item_number">{key + 1}.</div>
        <div className="uploading_item_info_title">{item.file_data.meta.name}</div>
      </div>
      {playVideo(item)}
    </div>
  )

  const renderStatus = (item) => {
    let text = null;
    let iconSrc = null;
    let className = item.status || 'processing';

    switch(item.status){
      case 'uploading': 
        iconSrc = bubble_loading;
        text = `Uploading... ${Math.round(item.progress)}%`;
        break;
      case 'uploaded':
        iconSrc = upload_success;
        text = 'Uploaded';
        break;
      case 'failed': 
        text = "Uploading Error"
        iconSrc = warning_ic;
        break;
      default: 
        text = "Processing...";
        iconSrc = processing_ic;
        break;
    }

    return (
      <div className={classNames("uploading_status_wrapper", className)}>
          <img src = {iconSrc} className="icon" alt = "uploading_progress_status_icon"/>
          <div className="text">{text}</div>
      </div>
    )
  }


  return (
    <div className="content_uploading_content">
        <div className="contentUploadingBGImage">
          <img src={formData.get('image_preview_url')} alt=""/>
        <div className="contentUploadingName">{formData.get('name')}</div>
        <div className="contentGroupInfo">
          <GroupOwner owner={user} showLink={false} />
          <DeleteButton color = '#FFFFFF' onClick = {abortUploading} />
        </div>
        <div className="imageBackground" />
	  	</div>
      <div className="contentUploadingPrice">$ {(parseInt(formData.get('price')) / 100).toFixed(2)}</div>
      <div className="contentUploadingBody">
        <h3 className = "content_uploading_amount">{`${filesToUpload.length} files`}</h3>
        <div className = "contentUploadingItems">
          {filesToUpload.map((item,key) => (
            <div className="uploading_item">
              <VideoLabel />
              <div className="uploading_item_block">
                {renderPlay(item, key)}
                <div className="uploading_item_status">
                  <div className="uploading_item_info_progress">
                    <LinearProgress variant="determinate" value={item.progress} />
                    {renderStatus(item)}
                  </div>
                  {/* <div className="uploading_item_info_label">{recordLabel(record)}</div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
     
    </div>
  );
};

export default inject('fileUploadingModel', 'userModel')(observer(ContentUploadingModalContent));;
