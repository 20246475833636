import React from "react";
import Button from "../Button";
import moment from "moment";
import {inject, observer} from "mobx-react";
import './styles.scss'

const ChangeSubscriptionPopup = (props) => {
  const {
    current,
    packageData,
    setConfirmedChange = () => {},
    close,
    setAdditionalPrice = () =>{},
    subscribePackageModel:{buySubscriptionPackage},
    mmData
  } = props;

  const usedDays = moment().diff( moment(current.active_to, 'YYYY-MM-DD').subtract(30, 'days'), 'days' ) + 1
  const usedCosts = parseInt(Math.ceil(current.price / 30) * usedDays)
  const realPrice = packageData?.price - (current?.price - usedCosts)
  const correctedPrice = realPrice > 0 && realPrice < 100 ? 100 : realPrice
  const addPrice = Math.max(correctedPrice, 0)

  const change = () => {
    if (addPrice > 0){
      setAdditionalPrice(addPrice)
      setConfirmedChange(true)
    } else {
      buySubscriptionPackage({id: packageData.id, payment_source_id: null, payment_source_type: null, user_id: mmData?.id})
      close()
    }
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setConfirmedChange(false)
    close()
  }

  return <div className={'changeSubscriptionPopup'}>
    <div className="changeSubscriptionPopup_title">Change Subscription?</div>
    <div className="changeSubscriptionPopup_text">
      To switch to a new subscription, you need to pay $ {parseFloat(addPrice / 100).toFixed(2)}. After payment, the old
      subscription will be canceled and the new one will take effect.
    </div>
    <div className="changeSubscriptionPopup_btns">
      <Button violetGradien className="changeSubscriptionPopup_yesBtn" label={'Yes, Change Now'} onClick={change}/>
      <Button whiteViolet label={'Cancel'} onClick={handleClose}/>
    </div>
  </div>
}

export default inject("subscribePackageModel")(observer(ChangeSubscriptionPopup))