import React from "react";
import CommunityFilter from "./filters/CommunityFilter";
import WeekDayFilter from "./filters/WeekDayFilter";
import Divider from '@material-ui/core/Divider';
import TimeFilter from "./filters/TimeFilter";
import PrivateFilter from "./filters/PrivateFilter";
import TagsFilter from "./filters/TagsFilter";

const RightMenuFilters = (props) => {
    const {
        coaching = false,
        mediaLibrary = false
    } = props
    return(
        <>
            {coaching ? 
                <>
                    {/*<TagsFilter />*/}
                    <CommunityFilter filterByButtons = {true} />
                    <Divider style = {{margin: "15px 0"}}/>
                    <PrivateFilter />
                    <Divider style = {{margin: "15px 0"}}/>
                </> : 
                <>
                    <CommunityFilter filterByButtons = {true} />
                    {!mediaLibrary ? <Divider style = {{margin: "15px 0"}}/> : null}
                </>
            }
            {!mediaLibrary ? <><div className="weekDaysRightfilterBlock"><WeekDayFilter /></div>
            <Divider style = {{margin: "15px 0"}}/>
            <div className="timeFilterWrapperContainer">
                <TimeFilter/>
            </div></> : null}
        </>
    )
}

export default RightMenuFilters