import React, { memo } from 'react';
import VisaIcon from '../../res/iconComp/VisaIcon';
import MasterCardIcon from '../../res/iconComp/MasterCardIcon';

const CardBrand = brand => {
  switch (brand.brand && brand.brand.toLowerCase()) {
    case 'visa':
      return <VisaIcon />

    case 'mastercard':
      return <MasterCardIcon />
  
    default:
      return null;
  }
}

export default memo(CardBrand);
