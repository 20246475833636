import React, {useEffect, useState} from 'react';
// import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react';
import PlayVideo from '../PlayRecord';
import Dialog from '../Dialog';
import Button from '../Button';
import AttendGroup from '../../pages/AttendGroup';
import {gtRecurringWebinar, gtWebinar, statusEnded} from '../../const/general';
// import EventButton from '../EventItem/eventButton';
import '../EventItem/eventItem.scss';
import './lsRecordGroup.scss';
import {createAudioContent, createMediaContent, createVideoContent} from "../../const/urlKeys";
import {Link} from "react-router-dom";
import ImgBuilder from '../../res/builders/ImgBuilder';

const LsRecordGroupItems = (props) => {
  const {
    item,
    showButton = false,
    is_owner,
    groupsModel: { buyGroup },
    mediaModel: { setSelectRecord }, // filteredMediaGroups
    // defaultHide = false,
    handleOpen,
  } = props;
  const [buttons, setButtons] = useState([]);
  const renderButton = () => {
    const isFile =
        item?.meetings_records &&
        item?.meetings_records?.length > 0 &&
        !!item?.meetings_records[0].file;
    switch (true) {
        // case (showButton || is_owner) && item.purchase:
      case (showButton || is_owner) && isFile && !item.subscribed && item.price > 0:
        return (
            <button className="buyMediaGroups" disabled>
              {/*$ {(item.price / 100).toFixed(2)}*/}
              <img src = {ImgBuilder.subscription_premium_text}/>
            </button>
        );
        // USD for Full Pack
      case (showButton || is_owner) && !item.purchase && !item.subscribed && item.price > 0:
        return (
            <Dialog
                openButton={<button className="buyMediaGroups">
                  <img src = {ImgBuilder.subscription_premium_text}/>
                </button>}
                className="eventGroupDetails"
                closeColor="#43425D"
                disabled={is_owner}
            >
              <AttendGroup item={item} />
            </Dialog>
        );
        //  USD for Full Pack
        // case (showButton || is_owner) && !item.subscribed && !item.purchase && item.price == 0:
        //   return <button className="buyMediaGroups" onClick={() => buyGroup(item.id, null, null)} >
        //     Add to your content list
        //   </button>
      case !item.purchase &&
      item.price > 0 &&
      item?.meetings_records &&
      !item.have_subscription &&
      !item.subscribed &&
      item?.meetings_records?.length !== 0:
        return (
            <button className="buyMediaGroups" disabled>
              {/*$ {(item.price / 100).toFixed(2)} {item?.meetings_records?.length > 1 && 'for Full Pack'}*/}
              <img src = {ImgBuilder.subscription_premium_text}/>
            </button>
        );
      default:
        return null;
        // return <button className="buyMediaGroups" disabled>$ {item.price / 100} for Full Pack</button>
    }
  };
  const isSingleRecord = item?.meetings_records && item?.meetings_records.length === 1;
  const isMultipleRecords = item?.meetings_records && item?.meetings_records.length > 1;
  const record = isSingleRecord ? item?.meetings_records[0] : null;
  const haveAccessToRecordLiveStreams = () => {
    if(is_owner){
      return true
    }else if((item?.status ? (item.status === statusEnded) : true)){
      if(!item.available_for_subscribers && !item.price){
        return true
      }else if(!!item.price && (item.purchase || (item.available_for_subscribers && item.have_subscription))){
        return true
      }else if(item.available_for_subscribers && item.have_subscription){
        return true
      }else if(item.invited){
        return true
      }
    }
    return false
  }
  const haveAccessToRecord = !item.subscribed && !is_owner && item.price === 0;

  const videoParams = record
      ? {
        record:
            item?.meetings_records && item?.meetings_records?.length === 1
                ? {...item.meetings_records[0], image: item.image}
                : {},
        groupID: item.id,
        recordPrice: item.price / item.meetings_count,
        owner: item.owner
      }
      : null;
  let title, path;
  switch (item.record_type) {
    case 'video_record':
      title = 'Play Video';
      path = createVideoContent;
      break;
    case 'meditation':
      title = 'Play Meditation';
      path = createMediaContent
      break;
    case 'podcast':
      title = 'Play Podcast';
      path = createAudioContent
      break;
    default:
      title = 'Play Livestream';
      break;
  }
  const editHandleClick = e => {
    e.stopPropagation()
    setSelectRecord(item)
  }

  useEffect(() => {
    let added;

    if(title === 'Play Livestream' && isSingleRecord && haveAccessToRecordLiveStreams()){
      const {record, recordPrice} = videoParams;
      added = (record?.ended && record?.video_file && record?.record_access)
          || (record?.video_file && !record?.record_access && recordPrice === 0)
          || (record?.video_file && !record?.record_access);

      if(added){
        setButtons([
          <PlayVideo {...videoParams}>
            <Button label={title} violetGradien />
          </PlayVideo>
        ])
      }
    }

    if((title !== 'Play Livestream' && isSingleRecord && (haveAccessToRecord && haveAccessToRecordLiveStreams()))){
      const {record, recordPrice} = videoParams;
      added = (record?.ended && record?.video_file && record?.record_access)
          || (record?.video_file && !record?.record_access && recordPrice === 0)
          || (record?.video_file && !record?.record_access);

      if(added){
        setButtons([
          <PlayVideo {...videoParams}>
            <Button label={title} violetGradien/>
          </PlayVideo>
        ])
      }
    }

    if(!added){
      setButtons([
        <div className={'content_item_btns_wrapper'}>
          {(isMultipleRecords || (isSingleRecord && !is_owner))
              && (<Button violetGradien label={title} onClick={() => handleOpen(item)} />
              )}
          {is_owner && !(item.group_type === gtWebinar || item.group_type === gtRecurringWebinar) &&
              <Link
                  to={location => ({ ...location, pathname: path, state: { action: 'edit' }})}
                  onClick={editHandleClick}
              >
                <Button blueGradient label={'Edit'}/>
              </Link>
          }
        </div>
      ])
    }
  }, []);

  return (
      <>
        <>
          {renderButton()}
          {buttons.map((item, index) => <div key={index}>{item}</div>)}
        </>
      </>
  );
};
export default inject('groupsModel','mediaModel')(observer(LsRecordGroupItems));