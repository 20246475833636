import React, {useEffect, useState, useReducer, useRef} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { inject, observer } from "mobx-react";
import { Field } from 'formik';


import './style.scss';
import { set } from 'lodash-es';

const DropdownCheckboxes = props => {
  //const { name, data, className = '', label, customChange, disabled = false, isShrink, style=null} = props;
  const {visibleDrop, 
    setVisibleDrop, 
    showDrop, 
    data, 
    customChange, 
    editGroup, 
    setSubscriptionPackEditGroup, 
    actualGroupType, 
    selectedCommunities, 
    setErrorsStatePack, 
    editedData,
    setEditedData,
    editDrop,
    setEditDrop,
    subscribePackageModel: {subscriptionPackagesList},
    userModel: {user}
  } = props;
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);  
  const placeholder = "Subscription Package";
  const name = "subscription_packages";
  const [selectedPackages, setSelectedPackages] = useState([]);
  

  const inputRef = useRef(null);

  useEffect(()=>{
    var hasTiers = [];
    if(editGroup && editGroup.group_type === "recurring_webinar" && actualGroupType !== "webinar"){
      hasTiers = subscriptionPackagesList?.subscription_packages?.filter(
        (pack) => pack?.tiers?.includes(editGroup.group_type) && 
        pack.communities.some(comm => editGroup.communities.some(editComm => editComm.id === parseInt(comm)))
      );      

      setEditedData(hasTiers);
      forceUpdate();
      let preSelectedPackages = [];
      hasTiers.map(pack => editGroup.subscription_packages_ids.includes(pack.id + "") &&
      preSelectedPackages.push(pack));
      setSelectedPackages(preSelectedPackages);
      setSubscriptionPackEditGroup(true)
    }else if(editGroup && ["webinar", "master_mind"].includes(editGroup.group_type)){
      hasTiers = subscriptionPackagesList?.subscription_packages?.filter(
        (pack) => pack?.tiers?.includes(editGroup.group_type) && 
        pack.communities.some(comm => editGroup.communities.some(editComm => editComm.id === parseInt(comm)))
      );      
      setEditedData(hasTiers);
      setSubscriptionPackEditGroup(true)
    }
    if(editGroup){
      setErrorsStatePack(null)
      let selectedFromEdit = editGroup?.subscription_packages_ids ? subscriptionPackagesList.subscription_packages.filter(pack => 
        editGroup.subscription_packages_ids.some(id => parseInt(id) === pack.id)
      ) : []
      setSelectedPackages(selectedFromEdit)
      console.log("selectedFromEdit", selectedFromEdit)
    }
  },[])

  useEffect(() =>{
    inputRef.current.children[0].autocomplete = "off";
  }, [inputRef])

  useEffect(()=>{
    if(!visibleDrop && !editGroup){
      setEditedData(false)
      selectedPackages.length ? setErrorsStatePack(null) : setErrorsStatePack("This field is required")
    }
    
  },[visibleDrop])

  const handleChange = (item) => {
    var copySelectedPackages = [...selectedPackages];
    if(selectedPackages.some(itemId => itemId.id === item.id)){
      copySelectedPackages = selectedPackages.filter(itemId => itemId.id !== item.id);
    }else{
      copySelectedPackages.push(item);
    }
    !copySelectedPackages.length ? setErrorsStatePack("This field is required") : setErrorsStatePack(null)
    setSelectedPackages(copySelectedPackages);
    customChange(copySelectedPackages.map(pack => pack.id + ""));
  }

  const displayValueEdit = (value) => {
    let packTitles = value;
    
    let packValueFinal = [];
    let genString = ""
    packTitles.map((title, id)=>{
      if(id){
        let tempGenString = genString + title;
        if(tempGenString.length > 20){
          let countOfNotdisplayedpacks = packTitles.length - id
          packValueFinal.push(" +" + countOfNotdisplayedpacks);
          packValueFinal.push(0);
        }else{
          packValueFinal.push(title)
        }
      }else{
        packValueFinal.push(title)
      }
      genString += title
    })
    let editedValue = [];
    let key = 0;
    while(key < packValueFinal.length && packValueFinal[key] !== 0){
      key !== 0 ? editedValue.push(" " + packValueFinal[key]) : editedValue.push(packValueFinal[key]);
      key += 1;
    }
    return editedValue
  }

  const formatPrice = (priceInCents) =>{
    let price = priceInCents/100
    var strPrice = "" + price;
    let strPriceArr  = strPrice.split(".");
    if(strPriceArr.length === 1){
      strPrice += ".00" 
    }else if(strPriceArr[1].length === 1){
      strPrice += "0"
    }
    return strPrice;
  }

  const showEditDrop = () =>{
    if(editDrop){
      setEditDrop(false);
      showDrop();
      forceUpdate()
    }else{
      setEditDrop(true);
      showDrop();
      forceUpdate()
    }
  }
  let currentData = editedData ? editedData : data;
  
  return (
    <Field name={name}>
      {({
          field: { name, value, onChange, onBlur },
          form: { touched, errors, isValid },
          meta,
        }) => {
          return(
         
          <FormControl error={meta.touched && errors[name]} className = "simpleInput disableLikeActive">
            <InputLabel htmlFor="custom-input" >{placeholder}</InputLabel>
            <Input
              id = "dropdown-input"
              type = "text"
              name = {name}
              autocomplete="off"              
              value = {displayValueEdit(selectedPackages.map(packag => packag.name))}
              onClick = {() => {
                if(!editedData){
                  showDrop(); 
                }else{
                  showEditDrop()
                }
              }}   
              ref = {inputRef}   
              onBlur={onBlur}      
            />
            {(editedData ? editDrop : visibleDrop) && 
              <div className = "dropdownCheckboxes_dropContainer">
                <List>
                    {currentData.map(item => (
                    <ListItem key={item.id} className = "dropdownCheckboxes_item">
                        <ListItemIcon>
                        <Checkbox
                            size="medium"
                            checked={selectedPackages.some(itemId => itemId.id === item.id)}
                            onChange={() => handleChange(item)}
                        />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                        <ListItemText primary={`$ ${formatPrice(item.price)}`} className = "dropdownCheckboxes_price"/>
                    </ListItem>
                    ))}
                </List>
              </div>
            }
          </FormControl>
        )}
      }
    </Field>
  )
}

export default inject("subscribePackageModel", "userModel")(observer (DropdownCheckboxes));
