import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    padding: '35px 18%',
    background: '#8ADBC2',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: '60vh !important',
      padding: '0px 25px !important'
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10
  },
  title: {
    fontSize: 27,
    marginBottom: 20,
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
  },
  subTitle: {
    fontSize: 22,
    color: '#fff',
    fontWeight: 300,
    marginBottom: 20,
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
  }
}));

const SubmitButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    borderRadius: 4,
    color: '#8ADBC2',
    width: '48%',
    boxShadow: 'none',
    background: '#fff',
    fontFamily: 'Red Hat Display',
    fontWeight: 'bold'
  }
})(Button);

const CancelButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    borderRadius: 4,
    color: '#fff',
    width: '48%',
    boxShadow: 'none',
    border: '1px solid #fff',
    background: 'transparent',
    fontFamily: 'Red Hat Display',
    fontWeight: 'bold',
  }
})(Button);

const noOutlined = { outline: "none" }

const CloseGroupForm = ({ toggleGroup, hideClosed, groupClosed }) => {
  const classes = useStyles();

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <Typography className={classes.title}>
          Make this group {groupClosed ? 'opened' : 'closed'}?
        </Typography>
        <Typography className={classes.subTitle}>
          If you {groupClosed ? 'open': 'close'} the group, new users will {!groupClosed && 'not'} be able to find it in the group list.
        </Typography>
        <div className={classes.buttons}>
          <CancelButton style={noOutlined} onClick={hideClosed}>Cancel</CancelButton>
          <SubmitButton style={noOutlined} onClick={toggleGroup} variant="contained">
            Yes, {groupClosed ? 'Open' : 'Close'}
          </SubmitButton>
        </div>
      </form>
    </>
  )
}

export default CloseGroupForm