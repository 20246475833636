import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import './styles.scss'

const GlobalSplashLoader = () => {
  return <div className={'globalLoader'}>
    <CircularProgress className="loader" />
  </div>
}

export default GlobalSplashLoader