import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import RightBlock from '../Dashboard/Right';
import MainPage from '../MainPage';
import Playlists from '../../components/Playlists';

import './style.scss';

const PlaylistsPage = props => {
  const {
    playlistModel: { 
      myPlaylists,
      getMyPlaylists
     },
  } = props;

  useEffect(() => {
    getMyPlaylists();
  }, [])
  
  return (
    <MainPage>
      <div className="dashboardBlock">
        <div className="dailyBlock playlists_page">
          <h3 className = "title">Playlists</h3>
          <Playlists playlists = {myPlaylists}/>
        </div>
      </div>
      {/*<RightBlock />*/}
    </MainPage>
  )
}

export default inject('playlistModel')(observer(PlaylistsPage));
