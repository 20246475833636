import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
import ReactDom from 'react-dom'
import './page.scss';
import GlobalSplashLoader from "../../components/GlobalSplashLoader";
import {observer, inject} from "mobx-react";

const SimplePage = props => {
  const { content, children, className,
    LoaderControllerModel:{loaderNeed}
  } = props;

  const [state, setState] = useState({ height: 0, width: 0 });

  useEffect(() => {
    function handleResize() {
      setState({ width: window.innerWidth, height: window.innerHeight })
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return _ =>{
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  let headerHeight = 180;
  if (state.width < 720) {
    headerHeight = 127;
  }
  if (state.width >= 720 && state.width < 960) {
    headerHeight = 220;
  }

  return (
    <>
      <Header />
        <div className={classNames("pageContent", className)} style={{ minHeight: state.height - headerHeight }}>
          {children}
          {!children && <div>Content - {content}</div>}
        </div>
      {loaderNeed && ReactDom.createPortal(<GlobalSplashLoader/>, document.body)}
      <Footer />
    </>
  )
};

export default inject('LoaderControllerModel')(observer(SimplePage));
