import {action, decorate, observable} from "mobx";

export class LoaderController {
  constructor() {
    this.loaderNeed = false
  }

  setIsGlobalLoaderNeed = bool => this.loaderNeed = bool

}

decorate(LoaderController, {
  loaderNeed: observable,
  setIsGlobalLoaderNeed: action
})

export const LoaderControllerModel = new LoaderController()