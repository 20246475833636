import React, {useEffect, useState} from 'react';
import { inject, observer } from 'mobx-react';

import PaymentItem from '../PaymentItem';
import moment from "moment";

import '../payments.scss';
import SubscribedCardItem from "./SubscribedCardItem";
import PackageBlock from "./PackageBlock";
import {statusActive, statusDeactivated} from "../../../../const/general";
import CustomModal from "../../../../components/Modals/Modal";
import CurrentAvatar from "../../../../components/CurrentAvatar";
import {returnName} from "../../../../units/func";

const subscriptionPackage = 'subscription_package';
const subscription = 'subscription';
const Subscriptions = props => {
  const {
    subscribeModel: { subscriptions, deleteSubscriptions, subscribeTypes },
    subscribePackageModel: {subscribedPackagesList, subscriptionPackagesList, getSubscribedPackagesList, getSubscriptionPackagesList }
  } = props;
  const [data, setData] = useState([])
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false)

  const toggleSubscribeModal = (data) => {
    data?.owner.id && getSubscriptionPackagesList({user_id:data?.owner.id})
    setSubscribeModalOpen(data)
  }

  useEffect(() => {
    getSubscribedPackagesList()
  },[subscriptionPackagesList])


  useEffect(() => {
    const packs = subscribedPackagesList?.subscription_packages.map( (pack) => {
      pack['data_type'] = subscriptionPackage
      return pack
    } ) || []
    const subscribes = subscriptions?.subscriptions?.map( (pack) => {
      pack['data_type'] = subscription
      return pack
    } ) || []
    const data = subscribes.concat(packs)
    const sortData = data.sort(function (a, b) {
      return ( moment(a.created_at) - moment(b.created_at)) * -1
    })
    setData(sortData)
  },[subscriptions, subscribedPackagesList])

  const getCardByType = (data) => {
    switch (data.data_type) {
      case subscriptionPackage:
        return <SubscribedCardItem item={data} subscribeSettings={toggleSubscribeModal} key={`${data.data_type}_${data.id}`}/>
      case subscription:
        let subFree = null;
        if (data.price === 0) {
          subFree = subscribeTypes.subscriptions.filter(item => item.price === 0)[0];
        }
        return <PaymentItem item={data} key={`${data.data_type}_${data.id}`} onActive={deleteSubscriptions} subFree={subFree} />

    }
  }
  return (
  <>
    {data?.map(pay => {
      return getCardByType(pay)
    })}
    <CustomModal customWidth='660px' isOpen={subscribeModalOpen} handleClose={toggleSubscribeModal}>
      <div className="subscribedCardData_ownerData">
        <div className="subscribedCardData_ownerAvatar">
          <CurrentAvatar
            alt={returnName(subscribeModalOpen?.owner)}
            src={subscribeModalOpen?.owner?.image?.url}
          />
        </div>
        <div className={'subscribedCardData_ownerWrapper'}>
          <span>package of</span>
          <div className="subscribedCardData_ownerName">{returnName(subscribeModalOpen?.owner)}</div>
        </div>
      </div>
      {<PackageBlock className='modalContent' classNameCard='modalHeight ' customWidth mmData={subscribeModalOpen?.owner} data={subscriptionPackagesList.subscription_packages.filter( (pack) => (pack.status === statusActive || pack.status === statusDeactivated && pack.current))}/>}
    </CustomModal>
  </>
  );
}

export default inject('cardsModel', 'subscribeModel', 'subscribePackageModel')(observer(Subscriptions));
