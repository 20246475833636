import React, {useEffect, useMemo, useState} from "react";

import {inject, observer} from "mobx-react";
import CustomButtonGroup from "../UI/ButtonGroups";
import {tabTitleAll, tabTitleFollowing} from "../../const/general";
import './styles.scss';
import Button from "../Button";
import RightPerson from "../RightPerson";

const AllCreatorsBlock = props => {
  const {
    userModel: { user },
    masterMindsModel: { sortedMasterMinds },
    max = 10,
    step = 10,
  } = props;

  const buttonsList = [tabTitleAll, tabTitleFollowing];
  const [masterMinds, setMasterMinds] = useState();
  const [displayedAllMMCount, setDisplayedAllMMCount] = useState(max)
  const [displayedFollowingMMCount, setDisplayedFollowingMMCount] = useState(max)
  const [lastIndex, setLastIndex] = useState(0)

  const FollowingMMArray = useMemo(_ => sortedMasterMinds?.filter( mm => mm.followed && mm.id !== user.id), [sortedMasterMinds])
  const AllMasterMindsArray = useMemo(_ => sortedMasterMinds?.filter( mm => mm.community_id === user.community_id), [sortedMasterMinds])

  const [viewBlock, setViewBlock] = useState( tabTitleAll );
  const [needShowMore, setNeedShowMore] = useState(checkShowMoreBtn())
  function handleDisplayMore () {
    viewBlock === tabTitleAll ? setDisplayedAllMMCount(prev => prev + step): setDisplayedFollowingMMCount(prev => prev + step)
  }

  function checkShowMoreBtn () {
    let currArr = viewBlock === tabTitleAll? AllMasterMindsArray: FollowingMMArray,
      currCount = viewBlock === tabTitleAll? displayedAllMMCount: displayedFollowingMMCount
    return currArr && currCount < currArr?.length
  }


  useEffect(_=>{
    setNeedShowMore(checkShowMoreBtn())
    setLastIndex(_ => {
      if (viewBlock === tabTitleAll) {
        return (AllMasterMindsArray && AllMasterMindsArray.length - 1) || 0
      } else {
        return (FollowingMMArray && FollowingMMArray.length - 1) || 0
      }
    })
    setMasterMinds( _=> {
      if (viewBlock === tabTitleAll) return AllMasterMindsArray?.slice(0, displayedAllMMCount) || null;
      return AllMasterMindsArray? FollowingMMArray?.slice(0, displayedFollowingMMCount) : null
    })
  },[AllMasterMindsArray, displayedAllMMCount, displayedFollowingMMCount, viewBlock])


  return <div className={'allCreators'}>
    <div className={'allCreators_title'}>Coaches</div>
    <div className={'allCreators_tabs'}><CustomButtonGroup buttonsList={buttonsList} active={viewBlock} changeActive={setViewBlock} /></div>
    {masterMinds?.length && masterMinds.map( (mm, i) =><RightPerson
      key={mm?.id}
      person={mm}
      connectButtons
      isLastItem={i === lastIndex}
    />)}
    {
      needShowMore && <div className="allCreators_bnts">
        <Button label="Show More" grey className="moreButton" onClick={handleDisplayMore} />
      </div>
    }

  </div>
}

export default inject('masterMindsModel', 'userModel')(observer(AllCreatorsBlock))