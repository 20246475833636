import React, {memo, useEffect, useRef} from 'react';

//builders
import ImgBuilder from '../../../res/builders/ImgBuilder';

//style
import './sharePopupContent.scss';

const SharePopupBuilder = props => {
  const {
    toggleNotification, mailData, showShareWithEmail, position, mailToText,
    handleHide, onShareAction = () => {}
  } = props;

 const el =  useRef(null)

  useEffect(() => {
    const cb = (e) => handleHide && handleHide();
    const globalCb = (e) => e.target !== el.current && cb()
    el.current.onmouseleave  = cb;
    window.addEventListener('click', globalCb)
    return () => {
      el.current.onmouseleave  = cb;
      window.removeEventListener('click', globalCb)
    }
  })

  let elementPosition = { left: 0};
  if (position === 'right') {
    elementPosition =  { right: 0 };
  }

  const copyLink = () => {
    navigator.clipboard.writeText(mailData.link);
    toggleNotification("linkCopied");
    onShareAction()
  }

  const openMailer =  () => {
    let mailer = null;
    try {
      mailer = window.open(mailToText);
      onShareAction()
    } catch (error) {
      mailer.close();
      toggleNotification("mailerNotFound");
    }
  }

  return (
    <div
      className="share-popup-content"
      style={elementPosition}
      ref={el}
    >
      {!showShareWithEmail ? null :
        <a className="share-popup-content-item" target="_blank" rel="noopener noreferrer" onClick = {openMailer}>
          <img className="share-icon" src={ImgBuilder.envelope} alt="share-email"/>
          <span className = "share-popup-content-item-text">Share via Email</span>
        </a>
      }
      <div className="share-popup-content-item" onClick={copyLink}>
        <img className="share-icon" src={ImgBuilder.linkChain} alt="copy-link"/>
        <span className = "share-popup-content-item-text">Copy Link</span>
      </div>
    </div>
  )
};

export default memo(SharePopupBuilder);
