import React, {useEffect, useState} from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Rating from '@material-ui/lab/Rating';
import Divider from '@material-ui/core/Divider';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import classNames from 'classnames';

import Button from '../../components/Button';
import CurrentAvatar from '../../components/CurrentAvatar';
import { returnName } from '../../units/func';

import './person.scss';

const RightPerson = (props) => {
  const history = useHistory();
  const {
    person: initPersonData,
    followButton = false,
    connectButtons = false,
    showViewProfile = true,
    onFollow = () => {},
    className,
    bottomButton = false,
    userModel:{user},
    masterMindsModel: { followMasterMind, unfollowMasterMind, masterMinds },
    isLastItem,
  } = props;
  const [person , updatePerson] = useState(initPersonData)


  const avatarUrl = person?.image?.thumbnail?.url;
  const userName = returnName(person);

  const handleFollow = () => {
    if (person?.followed) {
      unfollowMasterMind(person?.id);
    } else {
      followMasterMind(person?.id, onFollow);
    }
  };

  useEffect(() => {
    const newMMData = masterMinds?.users.find( u=> u.id === person.id)
    newMMData && updatePerson(newMMData)
  },[masterMinds])



  const renderButton = () => {
    switch (true) {
      case followButton:
        return (
          <Button
            label={!person?.followed ? 'Follow' : 'Unfollow'}
            onClick={handleFollow}
            green
            small
          />
        );
      case connectButtons:
        return (
          <div className="connectButtons">
            {showViewProfile && (
              <Button
                label="View Profile"
                small
                className="profile"
                onClick={() => history.push(`/user/${person?.id}`)}
              />
            )}
            {person?.followed ? (
              <Button label="Send Message" small className="message" />
            ) : (
              user?.id && person?.id !== user.id && <Button label="Follow" onClick={handleFollow} green small />
            )}
          </div>
        );
      default:
        return <Button label="Please provide button type" onClick={handleFollow} green small />;
    }
  };

  return (
    <div className={classNames('personBlock', className)} key={person?.id}>
      <div className="personInfo">
        <Link to={`/user/${person?.id}`}>
          <CurrentAvatar alt={userName} src={avatarUrl} className='personInfo-avatar' />
        </Link>
        <div className="textInfo">
          <div className="titleAndConnect">
            <Link to={`/user/${person?.id}`}>
              <div className="title">{userName}</div>
            </Link>
            {!bottomButton && renderButton()}
          </div>
          {person?.location && <div className="location">{person?.location}</div>}
          {person?.description && <div className="position">{person?.description}</div>}
          {/* <Rating 
            name="half-rating-read"
            defaultValue={person?.rating}
            precision={0.5}
            readOnly 
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
          /> */}
          {bottomButton && renderButton()}
        </div>
      </div>
      {!isLastItem && <Divider />}
    </div>
  );
};

export default inject('masterMindsModel', 'userModel')(observer(RightPerson));
