import React from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import CustomSneckbar from '../../../components/CustomSnackbar';

import Button from '../../../components/Button';
import Textarea from '../../../components/UI/Textarea';

import './payments.scss';

const SignupSchema = Yup.object().shape({
  reasone: Yup.string()
    .required("Reasone can't be blank")
    .min(2, "Reason must include not less than 2 characters")
    .max(200, "The maximum number of characters in the field is 200"),
})

const Refund = props => {
  const { close, group_id, groupsModel: { leaveGroup, resualtMessage, changeResultMessage } } = props;

  return (
    <>
      <Formik
        initialValues={{ reasone: '' }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          leaveGroup({ id: group_id, reasone: values.reasone, refund: true })
          // changeResultMessage('You Request send');
        }}
      >
        {formikProps => {
          const { setFieldValue, isValid, values: { reasone } } = formikProps;
          const disabledButton = !(isValid && reasone !== "");
          return (
            <Form className="refundBlock">
              <div className="refundTitle">Request a Refund</div>
              <label className="refundText">
                <div className="refundFieldName">Enter Reason</div>
                <Textarea
                  name="reasone"
                  placeholder="The amount of money to return and the reason for the refund."
                  className="refundTextarea"
                  customChange={e => setFieldValue('reasone', e.target.value.replace(/\s\s+/g, ' '))}
                />
              </label>
              <Button type="submit" label="Submit" disabled={disabledButton} width100 violetGradien big60 />
            </Form>
          );
        }}
      </Formik>
      <CustomSneckbar 
        message={resualtMessage}
        onClose={() => { changeResultMessage(''); close(); }}
        severity={resualtMessage === 'You Request send' ? "success" : 'error'}
      />
    </>
  );
}

export default inject('groupsModel')(observer(Refund));
