import React, { memo }  from 'react';
import Button from '../../components/Button';

const Message = props => {
  const { close } = props;
  return (
    <div className="messageDeleteBlock">
      <div className="messageDeleteTitle">On Review</div>
      <div className="messageDeleteText">Your data is currently being reviewed, please wait. The verification process can take several hours.</div>
      <div className="messageDeleteActions">
        <Button label="Ok" className="MDDelete" onClick={() => close()} style={{ width: '320px' }} />
        
        
        {/* <Button label="Delete" className="MDDelete" onClick={() => onDelete()} /> */}
      </div>
    </div>
  )
}

export default memo(Message);
