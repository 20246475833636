import React, { memo } from 'react';

const ShareArrowIcon = props => {
  const { color = '#43425D' } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M16 7.99438L9.35071 0.703125V5.05322H7.93701C3.55347 5.05322 0 8.60669 0 12.9902V15.2955L0.62793 14.6074C2.76282 12.2683 5.78357 10.9355 8.95056 10.9355H9.35071V15.2856L16 7.99438Z" 
      fill={color} 
    />
    </svg>
  )
}

export default memo(ShareArrowIcon);
