import React from "react";
import CurrentAvatar from "../CurrentAvatar";
import {returnName} from "../../units/func";
import {inject, observer} from "mobx-react";

const MemberItem = (props) => {
  const {
    user,
    community: {id, private: isPrivate},
    communityModel: {acceptRequestToJoinToCommunity, declineRequestToJoinToCommunity, removeMemberFromCommunity}
  } = props
  const handleAccept = (e) => {
    e.stopPropagation()
    acceptRequestToJoinToCommunity({
      id,
      user_id: user.id
    })
  }
  const handleDecline = (e) => {
    e.stopPropagation()
    declineRequestToJoinToCommunity({
      id,
      user_id: user.id
    })
  }
  const handleRemove = (e) => {
    e.stopPropagation()
    removeMemberFromCommunity({
      id,
      users_ids: [user.id]
    })
  }

  const btns = !user.requested?
    <div className="memberItem_removeBtn" onClick={handleRemove}>Remove</div>
    :
    <>
      <div className="memberItem_acceptBtn" onClick={handleAccept}>Accept</div>
      <div className="memberItem_declineBtn" onClick={handleDecline}>Decline</div>
    </>

  return (
    <div className={'memberItem'}>
      <div className="memberItem_avatar">
        <CurrentAvatar
          alt={returnName(user)}
          src={user?.image?.url}
          className='memberItemAvatar'
        />
      </div>
      <div className="memberItem_content">
        <div className="memberItem_name">{returnName(user)}</div>
        {isPrivate &&
            <div className="memberItem_btnWrapper">
          {btns}
        </div>}
      </div>
    </div>
  )
}

export default inject('communityModel')(observer(MemberItem))