import { decorate, observable, action } from 'mobx';
import axios from 'axios';

import { baseAPI } from '../const/settings';

import { authModel } from './auth';
import { userModel } from './user';

export class SubscribeModel {
  constructor() {
    this.subscriptions = null;
    this.resualtMessage = '';
    this.subscribeStatus = false;
    this.subscribeTypes = null;
  }

  setSubscribeData = data => this.subscriptions = data;
  changeResultMessage = text => this.resualtMessage = text;
  setSubscribeStatus = value => this.subscribeStatus = value;
  setSubscribeTypes = type => this.subscribeTypes = type;

  getSubscriptions = () => {
    const setSubscribeData = this.setSubscribeData;
    const config = {
      "params":{"subscription_type": "ascend"},
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.get(`${baseAPI}subscriptions`,  config)
      .then(function (response) {
        // handle success
        setSubscribeData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }

  getSubscriptionsType = () => {
    const setSubscribeTypes = this.setSubscribeTypes;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.get(`${baseAPI}subscriptions/available`,  headers)
      .then(function (response) {
        // handle success
        setSubscribeTypes(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }

  buySubscriptions = (pay_id, type) => {
    const changeResultMessage = this.changeResultMessage;
    const setSubscribeStatus = this.setSubscribeStatus;
    const getSubscriptions = this.getSubscriptions;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    const deta = {
      payment_source_id: pay_id,
      payment_source_type: type,
    }
    axios.post(`${baseAPI}subscriptions/buy`, deta, headers)
    .then(function (response) {
      changeResultMessage('Success');
      setSubscribeStatus(true);
      getSubscriptions();
      userModel.setCurrentUser({...userModel.user, have_subscription: true, });
    })
    .catch(function (error) {
      changeResultMessage(error.response.data ? error.response.data.errors[0] : 'Error');
    })
    .finally()
  }

  deleteSubscriptions = () => {
    const changeResultMessage = this.changeResultMessage;
    const getSubscriptions = this.getSubscriptions;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token
      }};
    axios.delete(`${baseAPI}subscriptions/cancel`, headers)
      .then(function (response) {
        changeResultMessage('Success');
        getSubscriptions();
        userModel.setCurrentUser({...userModel.user, have_subscription: false, });
      })
      .catch(function (error) {
        changeResultMessage(error.response.data ? error.response.data.errors[0] : 'Delete have some problems');
      })
  }
}

decorate(SubscribeModel, {
  subscriptions: observable,
  resualtMessage: observable,
  subscribeStatus: observable,
  subscribeTypes: observable,
  setSubscribeStatus: action,
})

export const subscribeModel = new SubscribeModel();
