import React, {useEffect, useReducer, useState} from "react";
import { inject, observer } from "mobx-react";
import CardList from "../../components/PaymentMethods/Cards";
import BankAccountsList from "../../components/PaymentMethods/BankAccounts";
import Payments from "./Components/Payments";
import Dialog from "../../components/Dialog";
import Button from "../../components/Button";
import MainPage from "../MainPage";
import Empty from "../../components/Empty";
import RightProfile from "./Components/rightProfile";
import RightProfileMobile from "./Components/rightProfileMobile";
import {AddPaymentMethodForm} from '../../components/AddPaymentMethod';
import "./profile.scss";
import {
  mySubscriptions,
  paymentPortal,
  MyProfile,
  maxSubscriptionsCount, myOrganization
} from "../../const/general";
import PackageBlock from "./Components/Subscriptions/PackageBlock";
import StripeVerification from "../StripeVerification";
import MyOrganizationBlock from "../../components/MyOrganizationBlock";

const Profile = (props) => {
  const {
    location,
    subscribePackageModel:{subscriptionPackagesList, getSubscriptionPackagesList},
    userModel: { user },
    communityModel: { communityList }
  } = props;

  const checkIsUserVerified = () => {
    if (!Object.keys(user).length) return true;
    return (user?.payouts_enabled && communityList.communities.some( c => c.moderator))
  }
  const [stripeVerifyNeed, setStripeVerifyNeed ] = useState(false)
  const [userVerified, setUserVerified] = useState(checkIsUserVerified())

  const [view, setView] = useState(
    () => {
      switch (location?.state?.openPage){
        case "payment":
          return paymentPortal
        case mySubscriptions:
          if (!userVerified){
            setStripeVerifyNeed(true)
            return MyProfile
          }
          return mySubscriptions
        case myOrganization:
          return myOrganization
        default:
          return MyProfile
      }
    }
  );

  useEffect(() => {
    const res = checkIsUserVerified()
    setUserVerified(res)
    if (!res && view === mySubscriptions){
      setStripeVerifyNeed(!res)
      dispatch({type: MyProfile})
    }
  },[user, communityList])

  useEffect(() => {
    getSubscriptionPackagesList()
  }, [])

  function getView(state, action){
    switch (action.type) {
      case MyProfile:
        return <Empty/>;
      case paymentPortal:
        return <div className="paymentBlock">
          <div className="methodsBlock">
            <h3>Payment Methods</h3>
            <div className="cardsList">
              <CardList />
              <BankAccountsList />
              <Dialog
                openButton={
                  <Button
                    label="Add Payment Method"
                    violetGradien
                    big60
                    width100
                  />
                }
                className="payment"
              >
                <AddPaymentMethodForm />
              </Dialog>
            </div>
          </div>
          <div className="transactionsBlock">
            <Payments />
          </div>
        </div>
      case mySubscriptions:
      case 'subscriptionPackagesListUpdate':
        return <div className="subscriptionsPackagesBlock">
          <div className="subscriptionsBlock">
            <div className="subcriptionTitle d-flex justify-content-left">
              <h3 className="subcriptionTitleText">
                Subscription Packages
              </h3>
              <span className="subcriptionTitleCount">{subscriptionPackagesList.count} of {maxSubscriptionsCount}</span>
            </div>
            <PackageBlock className='pageHeight' subscriptionPage data={subscriptionPackagesList.subscription_packages}/>
          </div>
        </div>

      case myOrganization:
        return <MyOrganizationBlock/>
    }
  }

  const [renderView, dispatch] = useReducer(getView, getView(null, {type: view}))

  useEffect(() => {
    if (view === mySubscriptions){
      userVerified?  dispatch({type: 'subscriptionPackagesListUpdate'}) : dispatch({type: MyProfile})
    }
  },[subscriptionPackagesList])

  const changeView = (newView) => {
    if(!userVerified && newView === mySubscriptions){
      return setStripeVerifyNeed(true)
    }
    setView(newView)
    dispatch({type: newView})
  }
  return (
    <MainPage>
      {window.innerWidth < 720 && (
        <RightProfileMobile onClick={changeView} viewProfile={view} />
      )}
      <div className="profileBlock">
        {subscriptionPackagesList && renderView}
      </div>
      {window.innerWidth >= 720 && (
        <RightProfile onClick={changeView} viewProfile={view} />
      )}
      <Dialog
        className="withdrawDialog"
        defaultOpen={stripeVerifyNeed}
        onClose={()=>setStripeVerifyNeed(false)}
      >
        <StripeVerification />
      </Dialog>
    </MainPage>
  );
};

export default inject("userModel", 'subscribePackageModel', 'communityModel')(observer(Profile));
