import React, { useState } from 'react'; // useEffect
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import classNames from 'classnames';
import orderBy from 'lodash/orderBy';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import TransactionDetails from './TrasactionDetails';
import CustomAccordion from '../../../components/CustomAccordion';
import Dialog from '../../../components/Dialog';
import Button from '../../../components/Button';
import StripeVerification from '../../StripeVerification';

import './earnings.scss';

const Earnings = props => {
  const {
    transactionsModel: { transactionsEarnings }, // balance
    userModel: { user },
    groupsModel: { getGroupError, lastGroup } // getSingleGroup
  } = props;

  const [dsetails, setDetails] = useState(null);
  // const [group, setGroup] = useState(null);

  // useEffect(() => {
  //   const groupId = dsetails?.group_id || dsetails?.group.id;
  //   if (groupId) {
  //     const currentGroup = getSingleGroup(groupId);
  //     setGroup(currentGroup);
  //   }
  // }, [dsetails]);

  let balance = 0;
  if (transactionsEarnings) {
    Object.values(transactionsEarnings).forEach(month => {
      balance = balance + month.balance;
    });
  }

  const months = transactionsEarnings ? 
    orderBy(Object.keys(transactionsEarnings), [function(o) { return moment(o) }], ['desc']) : [];

  // if (!balance) { return null };

  const item = { ...dsetails, user };

  const renderItem = item => {
    const isMinus = item.type === 'withdrawn';
    return (
      <>
        <div className={classNames("payTytle", { activePay: isMinus })}>
          <span className="payName">
            {item.group?.name}
          </span>
          <span>
            $ {isMinus ? '-' : '+'}{(item.amount/100).toFixed(2)}
          </span>
        </div>
        <div className="paySubData">
          {moment(item.created_at).format('DD MMM YYYY h:mm a')} 
          <span>
            {item.payout_destination && `${item.payout_destination.brand}-${item.payout_destination.last4}`}
          </span>
        </div>
      </>
    )
  };

  return (
    <>
      <div className="totalBlock">
        <div className="totalTitle">Total Earned: $ <span>{(balance / 100).toFixed(2)}</span></div>
        {(
          !(user?.stripe_required_fields_filled) 
          || (!user?.payouts_enabled && user?.stripe_fields_needed.length > 0)
          ) && <>
          <Divider />
          <div className="withdrawnBlock">
            <Dialog 
              openButton={<Button label="Verify" small width100 className="withdrawButton" />}
              className="withdrawDialog"
            >
              <StripeVerification />
            </Dialog>
          </div>
        </>}
      </div>
      {months.map(month => {
        return (
          <CustomAccordion 
            key={month}
            mainRoot="transactionsDetails"
            contentSummary="transactionsSummary"
            rootDetails="transactionsData"
            summary={<>
              {transactionsEarnings[month].name} 
              <span>$ {(transactionsEarnings[month].balance/100).toFixed(2)}</span>
            </>}
          >
            <List>
              {transactionsEarnings[month].transactions.map(pay => (
                <ListItem
                  button
                  key={pay.id}
                  onClick={() => setDetails(pay)}
                  className="payBlock"
                >
                  <ListItemText secondary={renderItem(pay)} disableTypography={true} />
                </ListItem>
              ))}
            </List>
          </CustomAccordion>
        )
      })}
      <Dialog 
        className="transactionDetailsDialog"
        onClose={() => setDetails(null)}
        defaultOpen={!!dsetails}
      >
        <TransactionDetails
          item={item}
          groupError={getGroupError}
          showMettingsLine={!!lastGroup?.wdays} 
        />
      </Dialog>
    </>
  );
}

export default inject('transactionsModel', 'userModel', 'groupsModel')(observer(Earnings));
