import React, { memo } from 'react';

const PromoCheckIcon = props => {
  // const { color = 'white' } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="2" fill="#43425D"/>
    <path d="M7 12.36L10.2 15.72L16.6 9" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default memo(PromoCheckIcon);
