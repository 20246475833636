import { observable, decorate, action } from 'mobx';
import axios from 'axios';

import { baseAPI } from '../const/settings';
import { authModel } from './auth';
import { groupsModel } from './groups';

export class InviteMembersModel {
  constructor() {
    this.members = null;
    this.usersList = {
      count: 0,
      users: []
    }
  }

  setMembers = members => {
    if (this.members?.count !== members?.count) {
      this.members = members;
    }
  };

  setUsers = users => {
    if (!users) return false;
    this.usersList = users
  }
  
  geMembers = (key = 'full_name', text, role = null) => {
    const setMembers = this.setMembers;
    const recusyGetMembers = this.geMembers;
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      }
    };
    axios.get(`${baseAPI}users/search?[${key}]=${text}&[per_page]=1000${role ? '&role=master_mind': ''}`, headers)
      .then(function (response) {
        if (!response.data?.count && key === 'full_name') {
          recusyGetMembers('email', text);
        } else {
          setMembers(response.data);
        }
      })
      .catch(function (error) {
        console.log(error.response);
      })
  }
  getUsers = ({full_name}) => {
    const setUsers = this.setUsers;

    const configs = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      },
      "params":{
        full_name,
        sort_column: "full_name",
        per_page: 100000
      }
    };

    axios.get(`${baseAPI}users`, configs)
      .then(function (response) {
        setUsers(response.data)
      })
      .catch(function (error) {
        console.log(error.response);
      })
  }

  setInvites = (group_id, inviteList, removedList) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      }
    };

    const participants = JSON.stringify(inviteList.map(user => {return {email: user.email, speaker:user.speaker}}));
    const removed_participants = JSON.stringify(removedList);
    axios.post(`${baseAPI}groups/${group_id}/invite`, {participants, removed_participants},  headers)
      .then(function (response) {
        groupsModel.getSingleGroupData(group_id);
      })
      .catch(function (error) {
        console.log(error.response);
      })
  }

  deleteInvites = (group_id, inviteList) => {
    const headers = {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "session-token": authModel.session_token,
      }
    };
    const removeRequest = (invite_id) => axios.delete(`${baseAPI}groups/${group_id}/invite/${invite_id}`, headers)
      .catch(function (error) {
        console.log(error.response);
      })

    Promise.all(inviteList.map((id) => removeRequest(id)))
      .finally(() => {
        groupsModel.getSingleGroupData(group_id);
      })
  }
}

decorate(InviteMembersModel, {
  members: observable,
  usersList: observable,
  geMembers: action,
  getUsers: action,
})

export const inviteMembersModel = new InviteMembersModel();
