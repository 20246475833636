import React, { cloneElement, useState } from 'react';
import Popover from '@material-ui/core/Popover';

const CustomPopover = props => {
  const { children, myBuyyon } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
    {cloneElement(myBuyyon, { onClick: handleClick, 'aria-describedby': id })}
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {cloneElement(children, { close: handleClose })}
    </Popover>
    </>
  )
}

export default CustomPopover;
