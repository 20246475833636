import React , {useState, useRef, useEffect, useReducer} from 'react';
import { Field } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './select.scss';

const CustomSelectMultiple = props => {
  const { 
    name, 
    data, 
    action, 
    distribution_groups, 
    className = '', 
    label, 
    customChange, 
    disabled = false, 
    isShrink, 
    style=null, 
    defValue, 
    setFieldValue, 
    defDistGroupId, 
    distributionGroupSelected, 
    setSelectedCommunities, 
    setDistributionGroupSelected, 
    setAllCommunityValues = () => {}
  } = props;
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0)
  const editedDefValue = () => {
    if(defDistGroupId){
      let defDistGroupKey = "distribution_groups_" + defDistGroupId;
      let copyDefValue = defValue.map(comm => comm.id + "");
      copyDefValue.unshift(defDistGroupKey);
      return copyDefValue
    }else{
      let result = defValue.map(comm => comm.id + "")
      return result;
    }
  }
  const [valList, setValList] = useState(defValue ? editedDefValue() : []);
  const selectRef = useRef(null);

  
  useEffect(()=>{
    setAllCommunityValues(valList)
    forceUpdate()
  },[valList])

  useEffect(() =>{
    if(!(action === "edit") && defValue){
      setFieldValue("communities", [defValue[0].id + ""])
      setSelectedCommunities(defValue[0].id + "")
    }
  },[])
  

  const getValList = (event) =>{
    let filteredData = event.target.value.filter(item => !item.includes("_"));
    if(filteredData.length !== event.target.value.length){
      let chosenDistributionGroups = event.target.value.filter(item => item.includes("_"));
      let chosenDistributionGroupId = parseInt(chosenDistributionGroups[0].split("_")[2]);
      setFieldValue("distribution_group_id", chosenDistributionGroupId);
      setDistributionGroupSelected(true)
    }else{
      setFieldValue("distribution_group_id", 0);
      setDistributionGroupSelected(false)
    }
    customChange(filteredData);
    setValList(event.target.value);
  }
  
  
  const dataWithDistributionGroups = () => {
    let distributionGroupsData = distribution_groups.map(group => ({label:group.title, value: `distribution_groups_${group.id}`, distribution_group : true}));
    let copyData = data;
    distributionGroupsData.map(group => !copyData.some(comm => comm.value === group.value) && copyData.unshift(group))
    return copyData;
  }
  let editedData = !distribution_groups ? data : dataWithDistributionGroups();
  return (
    <Field name={name}>
      {({
          field: { name, value, onChange, onBlur },
          form: { touched, errors, isValid },
          meta,
        }) => {
        value = valList;
        return (
            <FormControl error={meta.touched && !!errors[name]} className={className} style={style}>
              <InputLabel id="select-label" shrink={isShrink}>{label}</InputLabel>
              <Select
                multiple
                labelId="select-label"
                id="select"
                value={valList}
                onChange={e => {getValList(e)}}
                onBlur={onBlur}
                disabled={disabled}
                ref = {selectRef}
              >
                
                  {/*distribution_groups && 
                    distribution_groups.map(item => (
                      <MenuItem 
                        value={`distribution_groups_${item.id}`} 
                        key={`distribution_groups_${item.id}`}
                        className = "select_distribution_groups_item"
                      >
                          {item.title}
                      </MenuItem>
                    ))
                    */}
                  {editedData.map(item => (
                    <MenuItem 
                      value={item.value} 
                      key={`${item.value}-${item.label}`}
                      className = {item?.distribution_group ? "select_distribution_groups_item" : ""}
                      disabled = {item.value.includes("_") && valList.some(commId => commId.includes("_")) && !valList.includes(item.value)}>
                      {item.label}
                    </MenuItem>
                  ))}
                
              </Select>
              <FormHelperText>{meta.touched && errors[name]}</FormHelperText>
            </FormControl>
          );
        }
      }
    </Field>
  )
};

export default CustomSelectMultiple;
