import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import Button from '../../../../components/Button';

const PrivateFilter = props => {
  const {
    groupsModel: {
      state: { filters },
      changeFilterPrivate,
    },
  } = props;
  
  return (
    <div className="group tags">
      <Button
        label="Public Group" // Group
        className={classNames('privateButton', { active: !filters.private })}
        name="private"
        onClick={() => changeFilterPrivate(false)}
      />
      <Button
        label="Private Group" // Individual
        className={classNames('privateButton', { active: filters.private })}
        name="private"
        onClick={() => changeFilterPrivate(true)}
      />
    </div>
  )
}

export default inject('groupsModel')(observer(PrivateFilter));
